import { Input, Radio } from "antd";
import { ContentState, convertFromHTML, EditorState, Modifier } from "draft-js";
import React, { useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import TextEditor from "../../../../../components/TextEditor";

const EmailTemplateContainer = styled.div`
  .ant-radio-wrapper-checked span {
    color: #e08036;
  }
`;
const TagContainer = styled.div`
  border-radius: 12px;
  padding: 3px 12px;
  cursor: pointer;
  &.red {
    background: #fee2e2;
    color: #991b1b;
  }
  &.yellow {
    background: #fef3c7;
    color: #92400e;
  }
  &.green {
    background: #d1fae5;
    color: #065f46;
  }
  &.blue {
    color: #1e40af;
    background: #dbeafe;
  }
  &.purple {
    color: #5b21b6;
    background: #ede9fe;
  }
  &.pink {
    color: #9d174d;
    background: #fce7f3;
  }
`;
const Tag = ({ title, onClick, className }) => {
  return (
    <TagContainer className={`${className || ""} mr-4 `} onClick={onClick}>
      {title}
    </TagContainer>
  );
};

export default function CreateEmailTemplate(props) {
  const [buttonText, setButtonText] = useState("");
  const [placement, setPlacement] = useState("");
  const [belowContent, setBelowContent] = useState("");
  const [activeEditor, setActiveEditor] = useState(1);
  const [aboveContent, setAboveContent] = useState("");
  const location = useLocation();

  React.useEffect(() => {
    const emailData = props.data;
    setPlacement(emailData.placement);
    setButtonText(emailData.buttonText);
    setBelowContent(emailData.belowContent);
    setAboveContent(emailData.aboveContent);
    const contentState = ContentState.createFromBlockArray(
      convertFromHTML(`${emailData.aboveContent}`)
    );

    setEditorState(EditorState.createWithContent(contentState));
    const BeforeContentState = ContentState.createFromBlockArray(
      convertFromHTML(`${emailData.belowContent}`)
    );
    setBeforeEditorState(EditorState.createWithContent(BeforeContentState));
    // eslint-disable-next-line
  }, [location.pathname]);

  React.useEffect(() => {
    if (
      props.onTemplateChange &&
      buttonText &&
      placement &&
      belowContent &&
      aboveContent
    ) {
      props.onTemplateChange(
        {
          buttonText: buttonText,
          placement: placement,
          belowContent: belowContent,
          aboveContent: aboveContent,
        },
        generateEmail()
      );
    }
    // eslint-disable-next-line
  }, [placement, buttonText, belowContent, aboveContent]);

  const contentState = ContentState.createFromBlockArray(
    convertFromHTML(`${aboveContent}`)
  );
  const BeforeContentState = ContentState.createFromBlockArray(
    convertFromHTML(`${belowContent}`)
  );
  const [beforeEditorState, setBeforeEditorState] = useState(
    EditorState.createWithContent(BeforeContentState)
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );

  const charCount = (txtLength, content) => {
    setAboveContent(content);
  };

  const sendTextToBeforeEditor = (text) => {
    setBeforeEditorState(insertText(text, beforeEditorState));
  };
  const sendTextToEditor = (text) => {
    setEditorState(insertText(text, editorState));
  };

  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();
    // const htmlText = convertFromHTML(text);

    // const contentState = ContentState.createFromBlockArray(
    //   htmlText,
    //   htmlText.entityMap
    // );

    //const createdContent = EditorState.createWithContent(contentState);
    // return createdContent;
    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text //contentState.getBlockMap()
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-character"
    );
    // return newEditorState;
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };
  const generateEmail = () => {
    let mailData = ``;
    if (placement === "top") {
      mailData = `
      <div class="mail-text">
      <div class="bg-secondary">
        <a href="URL">
          <input type="button" class="btn-primary" value="${buttonText}" />
        </a>
      </div>
        <p>
          ${aboveContent}
        </p>
      </div>
    `;
    }
    if (placement === "middle") {
      mailData = `
      <div class="mail-text">
        <p>
          ${aboveContent}
        </p>
        <div class="bg-secondary">
          <a href="URL">
            <input type="button" class="btn-primary" value="${buttonText}" />
          </a>
        </div>
        <p class="mail-text">
          ${belowContent}
        </p>
      </div>
    `;
    }
    if (placement === "bottom") {
      mailData = `
      <div class="mail-text">
      <p class="mail-text">
      ${aboveContent}
      </p>
      <div class="bg-secondary">
        <a href="URL">
          <input type="button" class="btn-primary" value="${buttonText}" />
        </a>
      </div>
      </div>
    `;
    }
    return mailData;
  };
  return (
    <EmailTemplateContainer className="pl-8 pr-8">
      <div className="text-sm font-medium">
        Hi <span className=" text-textLight "> {`<First name>`}</span>
      </div>
      <div className="mt-6 grid grid-cols-2 gap-28 pb-5 border-b border-bgLite mb-5 ">
        <div>
          <span className="font-medium text-sm text-textLight">
            Button placement
          </span>
          <Radio.Group
            value={placement}
            onChange={(e) => {
              setPlacement(e.target.value);
            }}
            buttonStyle="solid"
            className="grid grid-cols-3 gap-8 pt-4"
          >
            <Radio value="top">Top</Radio>
            <Radio value="middle">Middle</Radio>
            <Radio value="bottom">Bottom</Radio>
          </Radio.Group>
        </div>
        <div>
          <label className="font-medium text-sm text-textLight">
            Button text
          </label>
          <Input
            size="large"
            className="rounded mt-4"
            value={buttonText}
            onChange={(e) => {
              setButtonText(e.target.value);
            }}
          />
        </div>
      </div>
      <div>
        <div className="font-medium text-sm text-textLight mb-4">Body</div>
        <div className="flex ">
          <Tag
            title="Survey title"
            className="red"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor("'SURVEY_TITLE'");
              } else {
                sendTextToBeforeEditor("'SURVEY_TITLE'");
              }
            }}
          />
          <Tag
            title="Survey duration"
            className="yellow"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor(`'SURVEY_DURATION'`);
              } else {
                sendTextToBeforeEditor(`'SURVEY_DURATION'`);
              }
            }}
          />
          <Tag
            title="Reward"
            className="green"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor(`'REWARDS'`);
              } else {
                sendTextToBeforeEditor(`'REWARDS'`);
              }
            }}
          />
          <Tag
            title="Survey description"
            className="blue"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor("'SURVEY_DESCRIPTION'");
              } else {
                sendTextToBeforeEditor(`'SURVEY_DESCRIPTION'`);
              }
            }}
          />
          <Tag
            title="Survey end date"
            className="purple"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor("'SURVEY_END_DATE'");
              } else {
                sendTextToBeforeEditor("'SURVEY_END_DATE'");
              }
            }}
          />
          <Tag
            title="Project description"
            className="pink"
            onClick={() => {
              if (activeEditor === 1) {
                sendTextToEditor("'PROJECT_DESCRIPTION'");
              } else {
                sendTextToBeforeEditor("'PROJECT_DESCRIPTION'");
              }
            }}
          />
        </div>
      </div>
      <div className="mt-5 text-black">
        {placement === "middle" && (
          <div className="mb-4 text-sm text-textLight">Copy above button</div>
        )}
        <TextEditor
          charCount={charCount}
          editorState={editorState}
          setEditorState={setEditorState}
          placeholder="Enter Description"
          contentState={contentState}
          content={aboveContent}
          onFocus={() => {
            setActiveEditor(1);
          }}
        />
      </div>
      {placement === "middle" && (
        <div className="mt-6 text-black">
          <div className="mb-4 text-sm text-textLight">Copy below button</div>

          <TextEditor
            charCount={(count, content) => {
              setBelowContent(content);
            }}
            onFocus={() => {
              setActiveEditor(2);
            }}
            editorState={beforeEditorState}
            setEditorState={setBeforeEditorState}
            placeholder="Enter Description"
            contentState={BeforeContentState}
            content={belowContent}
          />
        </div>
      )}
    </EmailTemplateContainer>
  );
}
