import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GET_USERS {
    user(order_by: { id: asc }, where: { role_id: { _eq: 6 } }) {
      id
      email
      f_name
      l_name
      status
      phone_number
      title
      dnc_count
      respondent_id
      additional_data
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty {
      id
      name
    }
      role {
        id
        name
        description
      }
      is_active
      member_type_id
      year_of_birth
      gender
      meta_date
      member_type_id
      is_verified
      state
      city
      pincode
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
    }
  }
`;
export const GET_USERS_EXPORTS = gql`
  query GET_USERS_EXPORTS($status: String) {
    user(order_by: {id: asc}, where: { role_id: { _eq: 6 }, status: { _neq: $status }, is_verified: { _eq: true } }
) {
      id
      email
      f_name
      l_name
      status
      phone_number
      title
      dnc_count
      respondent_id
      additional_data
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty {
      id
      name
    }
      role {
        id
        name
        description
      }
      is_active
      member_type_id
      year_of_birth
      gender
      meta_date
      member_type_id
      is_verified
      state
      city
      pincode
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
    }
  }
`;
export const GET_USERS_VERIFY = gql`
  query GET_USERS_VERIFY($createId: Int!) {
    user(order_by: { id: asc }, where: { role_id: { _eq: 6 }, is_verified: {_eq: false}, 
    created_by_user: {role_id: {_neq: $createId }}
    }) {
      id
      email
      f_name
      l_name
      status
      phone_number
      title
      dnc_count
      respondent_id
      additional_data
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty {
      id
      name
    }
      role {
        id
        name
        description
      }
      is_active
      member_type_id
      year_of_birth
      gender
      meta_date
      member_type_id
      is_verified
      state
      city
      pincode
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
    }
  }
`;
export const GET_ONE_USER = gql`
  query GET_USER_BY_PK($id: Int!) {
    user_by_pk(id: $id) {
      additional_data
      respondent_id
      email
      f_name
      dnc_count
      status
      gender
      id
      is_active
      is_verified
      l_name
      phone_number
      created_by
      role_id
      primary_specialty
      secondary_specialty
      super_specialty_id
      title
      state
      city
      year_of_birth
      pincode
      meta_date
      export_count
      exported_on
      created_by_user {
        role_id
      }
      member_type {
        name
        description
      }
      primary_specialties {
        speciality_name
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty{
        name
      }
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
      survey_x_members_aggregate {
        aggregate {
          count
        }
      }
      completed: survey_x_members_aggregate(
        where: { _and: { state: { _eq: "completed" } } }
      ) {
        aggregate {
          count
        }
      }
      inprogress: survey_x_members_aggregate(
        where: { _and: { state: { _eq: "in-progress" } } }
      ) {
        aggregate {
          count
        }
      }
      screened: survey_x_members_aggregate(
        where: { _and: { state: { _eq: "screened" } } }
      ) {
        aggregate {
          count
        }
      }
    }
    project_aggregate(where: { surveys: { survey_x_members: { member_id: { _eq: $id } } } }
      ) {
        aggregate{
          count
        }
      }
  }
`;

export const GET_ASSIGNED_PROJECTS = gql`
  query GET_ASSIGNED_PROJECTS($id: Int) {
    project(
      where: { surveys: { survey_x_members: { member_id: { _eq: $id } } } }
    ) {
      member_types
      status
      survey_type
      is_online_or_assisted
      surveys {
        id
        name
        start_date
        end_date
        status
        links_status
        survey_x_members(where: { member_id: { _eq: $id } }) {
          member_id
          survey_url
          state
          survey_x_member_x_interviewers(
            limit: 1
            order_by: { priority: asc }
          ) {
            interviewer {
              f_name
              l_name
            }
            status
            comments
          }
        }
      }
      client {
        id
        f_name
        l_name
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          id
          f_name
          l_name
        }
      }
      projectTypeByProjectType {
        id
        name
      }
    }
  }
`;

export const EDIT_USERS = gql`
  mutation EDIT_USERS($object: user_set_input, $id: Int!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      is_active
      respondent_id
      status
      dnc_count
      meta_date
      ass_interviewer_status
      ass_interviewer_id
    }
  }
`;

export const DEACTIVATE_ACTIVATE_USERS = gql`
  mutation DEACTIVATE_ACTIVATE_USERS($id: Int!, $status: Boolean) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_active: $status }) {
      id
      is_active
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VERIFY_USER($id: Int!, $status: Boolean) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_verified: $status }) {
      id
      is_verified
    }
  }
`;

export const REJECT_USER = gql`
  mutation REMOVE_USER($id: Int) {
    delete_user(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const VERIFY_USER_BULK = gql`
  mutation VERIFY_USER_BULK($id: [Int!]) {
    update_user(where: {id: {_in: $id}}, _set: {is_verified: true}) {
      returning {
        id
        f_name
        email
        is_verified
      }
    }
  }
`;

export const REJECT_USER_BULK = gql`
mutation REJECT_USER_BULK($id: [Int!]) {
  delete_user(where: { id: { _in: $id } }) {
      affected_rows
  }
}
`;

export const GET_EMAIL_PHONE = gql`
  query GET_EMAIL_PHONE {
    user {
      email
      respondent_id
      phone_number
      meta_date
    }
  }
`;

export const ONBOARD_USER = gql`
  mutation onbord_user($user_id: Int!) {
    onbord_user(user_id: $user_id) {
      message
    }
  }
`;

export const ONBOARD_USER_RES_ID = gql`
  mutation ONBOARD_USER_RES_ID($res_id: bigint!) {
    onbord_user(res_id: $res_id) {
      message
    }
  }
`;

export const REWARDS_TAB = gql`
  query REWARDS_TAB($member_id: Int) {
    survey(
      where: {
        _and: [
          { survey_x_members: { member_id: { _eq: $member_id } } }
          { survey_x_members: { state: { _eq: "completed" } } }
        ]
      }
    ) {
      id
      name
      survey_x_members(
        where: {
          _and: [
            { member_id: { _eq: $member_id } }
            { state: { _eq: "completed" } }
          ]
        }
      ) {
        id
        member_id
        user {
          id
          f_name
          l_name
          member_type {
            name
          }
        }
        quota
        updated_at
        rewards {
          comments
          created_at
          created_by
          created_user {
            f_name
            l_name
          }
          file_url
          id
          status
          survey_member_id
          updated_at
          survey_id
        }
      }
    }
    survey_x_member_aggregate(
      where: {
        _and: [
          { member_id: { _eq: $member_id } }
          { state: { _eq: "completed" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    get_view_url(file_name: $name) {
      message
      url
    }
  }
`;

// export const CREATE_USERS_BULK = gql`
//   mutation CREATE_USERS_BULK($object: [user_insert_input!]!) {
//     insert_user(
//       objects: $object
//       # on_conflict: { constraint: user_respondent_id_key, update_columns: [email] }
//     ) {
//       affected_rows
//     }
//   }
// `;
export const CREATE_USERS_BULK = gql`
  mutation CREATE_USERS_BULK($object: [user_insert_input!]!, 
  $update_columns: [user_update_column!] = [
    title, 
    f_name,
    l_name, 
    gender, 
    email,
    is_active,
    primary_specialty,
    secondary_specialty,
    additional_data,
    project_compatibility,
    state,
    city,
    pincode,
    meta_date,
    is_verified,
    year_of_birth,
    project_compatibilitys,
    status,
    meta_date,
    # super_specialty_id,
    # primary_specialties,
    # super_specialty,
    year_of_birth, 
    phone_number
    ]) {
  insert_user(objects: $object, on_conflict: {constraint: user_respondent_id_key, update_columns: $update_columns}) {
    affected_rows
    returning {
      f_name
      id
      respondent_id
      email
      phone_number
      status
    }
  }
}
`;

export const REJECTION_DATA = gql`
 mutation REJECTION_DATA($meta_data: jsonb, $member_type:String, $rejected_by: Int) {
  insert_user_rejected(objects: { meta_data: $meta_data, member_type: $member_type, rejected_by: $rejected_by }) {
    affected_rows
    returning {
      id
      created_at
      meta_data
    }
  }
}
`;

export const GET_ACTIVITY = gql`
  query GET_ACTIVITY($id: Int) {
    approval(where: { user_id: { _eq: $id } }) {
      id
      old_info
      status
      updated_by
      updated_info
    }
  }
`;
export const UPDATE_DNC_COUNT = gql`  
  mutation UpdateDncCount($userId: bigint!, $count: Int!, $status: String!) {
    update_user(where: {respondent_id: {_eq: $userId}}, _set: {dnc_count: $count, status: $status}) {
      affected_rows
    }
  }
`;

export const UPDATE_EXPORT_INFO = gql` 
  mutation UpdateExportInfo($id: Int, $export_count: Int, $exported_on: timestamptz){
    update_user(where: {id: {_eq: $id}}, _set: {export_count: $export_count, exported_on:  $exported_on}) {
      affected_rows
      returning {
        export_count
        exported_on
      }
    }
  }
`;

export const EXPORT_EMAIL_TRIGGER = gql`
  mutation ExportEmailTrigger(
    $m_name: String!,
    $not_validated: Int!,
    $raw_count: Int!,
    $volume_of_upload: Int!,
    $dnc_count: Int!,
    $invalid_count: Int!,
    $partial_e_count: Int!,
    $partial_p_count: Int!,
    $prohibited_count: Int!
  ) {
    notify_a_sa(
      m_name: $m_name
      not_validated: $not_validated
      raw_count: $raw_count
      volume_of_upload: $volume_of_upload
      dnc_count: $dnc_count
      invalid_count: $invalid_count
      partial_e_count: $partial_e_count
      partial_p_count: $partial_p_count
      prohibited_count: $prohibited_count
    ) {
      message
    }
  }
`;
export const IMPORT_EMAIL_TRIGGER = gql`
  mutation ImportEmailTrigger(
    $m_name: String!,
    $not_validated: Int!,
    $raw_count: Int!,
    $volume_of_upload: Int!,
    $dnc_count: Int!,
    $invalid_count: Int!,
    $partial_e_count: Int!,
    $partial_p_count: Int!,
    $prohibited_count: Int!,
    $valid_count: Int!,
  ) {
    import_notify(
      m_name: $m_name
      not_validated: $not_validated
      raw_count: $raw_count
      volume_of_upload: $volume_of_upload
      dnc_count: $dnc_count
      invalid_count: $invalid_count
      partial_e_count: $partial_e_count
      partial_p_count: $partial_p_count
      prohibited_count: $prohibited_count
      valid_count: $valid_count
    ) {
      message
    }
  }
`;

export const GET_REJECTION_LIST = gql`
  query GET_REJECTION_LIST($rejected_by: Int) {
    user_rejected(where: { rejected_by: { _eq: $rejected_by } }, order_by: { id: desc }) {
      id
      member_type
      created_at
      meta_data
    }
  }
`;