import { gql } from "@apollo/client";

export const CREATE_CONSUMER = gql`
  mutation CONSUMER(
    $respondent_id: bigint
    $firstName: String
    $lastName: String
    $status: String
    $title: String
    $phone: String
    $email: String
    $password: String
    $additional_fields: jsonb
    $metadata: jsonb
    $state: String
    $city: String
    $pincode: Int
    $gender: String
    $year_of_birth: timestamptz
    $is_verified: Boolean
  ) {
    insert_user_one(
      object: {
        respondent_id:$respondent_id
        f_name: $firstName
        l_name: $lastName
        status: $status
        title: $title
        phone_number: $phone
        email: $email
        password: $password
        role_id: 6
        additional_data: $additional_fields
        meta_date: $metadata
        gender: $gender
        pincode: $pincode
        year_of_birth: $year_of_birth
        city: $city
        state: $state
        member_type_id: 4
        is_verified: $is_verified
      }
    ) {
      id
      meta_date
    }
  }
`;

export const ADD_APPROVAL = gql`
  mutation MyMutation(
    $old_info: jsonb
    $updated_info: jsonb
    $updated_by: Int
    $user_id: Int
  ) {
    insert_approval(
      objects: {
        old_info: $old_info
        updated_info: $updated_info
        updated_by: $updated_by
        user_id: $user_id
      }
    ) {
      affected_rows
    }
  }
`;
