import { SearchOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Dropdown,
  Input,
  Menu,
  notification,
  Space,
  Switch,
  Table,
} from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import verticalDots from "../../assets/verticalDots.svg";
import ColumnEditor from "../../components/ColumnEditor";
import Loading from "../../components/Loading";
import { GET_CLIENTS, SET_CLIENT_ACTIVE } from "../viewProject/graphql";

let isChecked = true;

function ClientList() {
  let searchInput;
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const savedClient = localStorage.getItem('clientColumn');
  const clientinitial = savedClient
    ? JSON.parse(savedClient)
    : {
      "email": { enabled: true, label: "Email" },
      "user": { enabled: true, label: "Phone Number" },
      "category": { enabled: true, label: "Category" },
      "country": { enabled: true, label: "Country" },
      "billing_cycle": { enabled: true, label: "Billing Cycle" },
      "survey": { enabled: true, label: "Surveys Count" },
      "address": { enabled: true, label: "Address" }
    }
  const [columnStatus, setStatus] = useState(clientinitial);
  useEffect(() => {
    localStorage.setItem('clientColumn', JSON.stringify(columnStatus));
  }, [columnStatus]);

  const {
    data: clientData,
    loading: clientLoading,
    error: clientError,
  } = useQuery(GET_CLIENTS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (clientData) {
        let object = JSON.parse(JSON.stringify(clientData.client));
        object.forEach((item) => {
          item.country = item?.countryByCountry?.name;
          item.billingCycle = item?.billingCycleByBillingCycle?.value;
          item.category = item?.client_category.name;
          let project = item.user.projects;
          let count = 0;
          project?.forEach((val) => {
            count = count + val.surveys_aggregate.aggregate.count;
          });
          item.surveyCount = count;
        });
        // country = new Map(object.country.map((key) => [key.id, key.name]));
        // billingCycle = new Map(
        //   object.billing_cycle.map((key) => [key.id, key.values])
        // );
        // category = new Map(
        //   object.client_category.map((key) => [key.id, key.name])
        // );
        setTableData(object);
      }
      if (clientError) {
        console.log(clientError);
      }
    },
  });

  const [getClient] = useLazyQuery(GET_CLIENTS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data) {
        let object = JSON.parse(JSON.stringify(data?.client));
        let filteredObject = [];
        // eslint-disable-next-line

        object.forEach((item) => {
          item.country = item?.countryByCountry?.name;
          item.billingCycle = item?.billingCycleByBillingCycle?.value;
          item.category = item?.client_category.name;
          let project = item.user.projects;
          let count = 0;
          project?.forEach((val) => {
            count = count + val.surveys_aggregate.aggregate.count;
          });
          item.surveyCount = count;
          if (item.user.is_active === true) {
            filteredObject.push(item);
          }
        });
        if (isChecked === false) {
          setTableData(filteredObject);
        } else {
          setTableData(object)
        }
      }
      if (clientError) {
        console.log(clientError);
      }
    },
  });

  const [submitActive, { loading: loadingClientInactive }] = useMutation(
    SET_CLIENT_ACTIVE,
    {
      onError(error) {
        error.graphQLErrors?.forEach((error) => {
          notification.error({
            message: "Error!",
            description: "Something went wrong",
          });
        });
      },
      onCompleted(data) {
        getClient();
      },
    }
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex, keyword) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${keyword}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "CLIENT",
      dataIndex: "company_name",
      key: "company_name",
      render: (record) => {
        return (
          <div>
            <span>{record}</span>
          </div>
        );
      },
      ...getColumnSearchProps("company_name", "client"),
    },
    {
      title: "EMAIL",
      dataIndex: "user",
      key: "email",
      ...getColumnSearchProps("user.email", "email"),
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      render: (record) => {
        return (
          <div>
            <span>{record.email}</span>
          </div>
        );
      },
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "user.phone_number",
      key: "user.phone_number",
      render: (text, record) => {
        return (
          <div>
            <span>{record.user.phone_number}</span>
          </div>
        );
      },
      ...getColumnSearchProps("user.phone_number", "phone number"),
      className: columnStatus?.user?.enabled ? "table-cell" : "hidden",
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      ...getColumnSearchProps("category", "category"),
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      render: (text) => {
        return (
          <div>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: "COUNTRY",
      dataIndex: "country",
      key: "country",
      ...getColumnSearchProps("country", "country"),
      className: columnStatus?.country?.enabled ? "table-cell" : "hidden",
      render: (text) => {
        return (
          <div>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: "BILLING CYCLE",
      dataIndex: "billing_cycle",
      key: "billing_cycle",
      ...getColumnSearchProps("billing_cycle", "billing_cycle"),
      className: columnStatus?.billing_cycle?.enabled ? "table-cell" : "hidden",
      render: (text) => {
        return (
          <div>
            <span className="float-right">{text}</span>
          </div>
        );
      },
    },
    {
      title: "SURVEYS CREATED",
      dataIndex: "surveyCount",
      key: "survey",
      ...getColumnSearchProps("surveyCount", "survey count"),
      className: columnStatus?.survey?.enabled ? "table-cell" : "hidden",
      render: (text) => {
        return (
          <div>
            <span className="float-right">{text}</span>
          </div>
        );
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      ...getColumnSearchProps("address"),
      className: columnStatus?.address?.enabled ? "table-cell" : "hidden",
      render: (text) => {
        return (
          <div>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: "ACTION",
      //dataIndex: "id",
      key: "action",
      render: (user) => {
        return (
          <Dropdown overlay={menu(user.user, user.id)} placement="bottomRight">
            <Button type="link" size="large">
              <img src={verticalDots} alt="vertical_Dots" />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const menu = (user, clientId) => (
    <Menu className="rounded-md bg-gray">
      {user?.is_verified && (
        <Menu.Item
          key="1"
          className="text-textLight hover:text-orange"
          onClick={() => {
            history.push({
              pathname: `/edit-client/${clientId}/client-details`,
            });
          }}
        >
          <div>Edit client</div>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item
        key="2"
        className="text-textLight hover:text-orange"
        onClick={() => {
          history.push(`/viewClient/client-details/client/${clientId}`);
        }}
      >
        <div>View</div>
      </Menu.Item>
      <Menu.Divider />
      {user?.is_verified && (
        <Menu.Item
          key="3"
          className="text-textLight hover:text-orange"
          onClick={() => onSubmitActive(user)}
        >
          <div>{user?.is_active ? "Deactivate" : "Activate"}</div>
        </Menu.Item>
      )}
    </Menu>
  );

  const onChange = (checked) => {
    isChecked = checked;
    getClient();
  };

  const onSubmitActive = (user) => {
    submitActive({
      variables: {
        user_id: user.id,
        is_active: !user.is_active,
      },
    }).then(() => { });
  };

  return (
    <div className="projectList w-full px-10 py-4">
      {loadingClientInactive && <Loading />}
      <div className="w-full flex justify-between items-center mb-6">
        <p className="text-textMedium text-2xl mb-0">Clients</p>
        <div className="flex items-center">
          <div className="text-textMedium pr-6">
            <span className="pr-2">Show inactive clients</span>
            <Switch defaultChecked onChange={onChange} />
          </div>
          <Button
            type="primary"
            htmlType="submit"
            className=" text-base rounded-md mr-6"
            onClick={() => history.push("/create-client/client-details")}
          >
            + Add a new client
          </Button>
          <ColumnEditor columns={columnStatus} onSave={(column) => {
            setStatus(column)
          }} />
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        className="w-full bg-white"
        dataSource={tableData}
        columns={columns}
        loading={clientLoading}
        rowClassName={(record) => {
          return (record.user.is_verified ||
            record.user.is_verified === null) &&
            record.user.is_active
            ? "text-sm text-textMedium p-0 m-0 "
            : !record.user.is_verified
              ? " text-sm text-yellow-500 p-0 m-0"
              : " text-sm text-textExtraLight p-0 m-0";
        }}
        pagination={
          clientLoading
            ? false
            : {
              showTotal: (total, range) => {
                return (
                  <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                );
              },
            }
        }
      />
    </div>
  );
}
export default ClientList;
