import React, { useState } from "react";
import { Button, Form, Spin } from "antd";
import moment from "moment";
import { useMutation, useQuery } from "@apollo/client";
import {
  COMPLETE_SURVEY,
  GET_MEMBER,
  GET_SURVEY_ONLINE,
  UPDATE_MEMBER,
} from "../../../pages/survey/graphql";
import { useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { surveyState } from "../../../atoms";
import PhysiciansForm from "./PhysiciansForm";
import TechniciansForm from "./TechniciansForm";
import PatientForm from "./PatientForm";
import ConsumersForm from "./ConsumersForm";

const ProfilerQuestions = () => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [formData, setFormData] = useState({
    ailments: [],
    specialties: [],
    project_compatibility: [],
    hospitalType: ["Government", "Private", "Non-profit", "Others"],
  });

  const [physiciansForm] = Form.useForm();
  const [techniciansForm] = Form.useForm();
  const [patientForm] = Form.useForm();
  const [consumersForm] = Form.useForm();

  const { data: memberdetails, loading } = useQuery(GET_MEMBER, {
    variables: {
      user_id: Number(params.memberID),
    },
    onCompleted: (data) => {
      setFormData({
        ...formData,
        ailments: data.ailments,
        specialties: data.specialties,
        project_compatibility: data.project_compatibility,
      });
      switch (surveyDetails.member_type) {
        case 1:
          physiciansForm.setFieldsValue({
            respondent: data?.user_by_pk?.respondent_id || "",
            f_name: data?.user_by_pk?.f_name || "",
            l_name: data?.user_by_pk?.l_name || "",
            primary_speciality: data?.user_by_pk?.primary_specialty || "",
            secondary_speciality: data?.user_by_pk?.secondary_specialty || "",
            email: data?.user_by_pk?.email || "",
            phone_number: data?.user_by_pk?.phone_number || "",
            mobile1: data?.user_by_pk?.meta_date?.mobile1 || "",
            mobile2: data?.user_by_pk?.meta_date?.mobile2 || "",
            state: data?.user_by_pk?.state || "",
            city: data?.user_by_pk?.city || "",
            pincode: data?.user_by_pk?.pincode || "",
            gender: data?.user_by_pk?.gender || "",
            year_of_birth: data?.user_by_pk?.year_of_birth
              ? moment(data.user_by_pk.year_of_birth)
              : "",
            hospitalType: data?.user_by_pk?.meta_date?.hospitalType || "",
            hospitalName: data?.user_by_pk?.meta_date?.hospitalName || "",
            hospital1: data?.user_by_pk?.meta_date?.hospital1 || "",
            hospital2: data?.user_by_pk?.meta_date?.hospital2 || "",
            project_compatibility:
              data?.user_by_pk?.project_compatibilitys || "",
          });
          break;
        case 2:
          techniciansForm.setFieldsValue({
            title: data?.user_by_pk?.title || "",
            f_name: data?.user_by_pk?.f_name || "",
            email: data?.user_by_pk?.email || "",
            phone_number: data?.user_by_pk?.phone_number || "",
            profession: data?.user_by_pk?.meta_date?.profession || "",
            sub: data?.user_by_pk?.meta_date?.sub || "",
            organisation: data?.user_by_pk?.meta_date?.organisation || "",
            state: data?.user_by_pk?.state || "",
            city: data?.user_by_pk?.city || "",
            pincode: data?.user_by_pk?.pincode || "",
          });
          break;
        case 3:
          patientForm.setFieldsValue({
            title: data?.user_by_pk?.title || "",
            f_name: data?.user_by_pk?.f_name || "",
            email: data?.user_by_pk?.email || "",
            phone_number: data?.user_by_pk?.phone_number || "",
            state: data?.user_by_pk?.state || "",
            city: data?.user_by_pk?.city || "",
            pincode: data?.user_by_pk?.pincode || "",
            gender: data?.user_by_pk?.gender || "",
            year_of_birth: data?.user_by_pk?.year_of_birth
              ? moment(data.user_by_pk.year_of_birth)
              : "",
            ailment_list: data?.user_by_pk?.meta_date?.ailments || [],
          });
          break;
        case 4:
          consumersForm.setFieldsValue({
            title: data?.user_by_pk?.title || "",
            f_name: data?.user_by_pk?.f_name || "",
            email: data?.user_by_pk?.email || "",
            phone_number: data?.user_by_pk?.phone_number || "",
            state: data?.user_by_pk?.state || "",
            city: data?.user_by_pk?.city || "",
            pincode: data?.user_by_pk?.pincode || "",
            gender: data?.user_by_pk?.gender || "",
            year_of_birth: data?.user_by_pk?.year_of_birth
              ? moment(data.user_by_pk.year_of_birth)
              : "",
          });
          break;
        default:
          break;
      }
    },
  });

  const [changeSurveyStatusToComplete] = useMutation(COMPLETE_SURVEY, {
    refetchQueries: [
      {
        query: GET_SURVEY_ONLINE,
        variables: {
          survey_id: Number(params.surveyID),
          member_id: Number(params.memberID),
        },
      },
    ],
  });

  const [updateMemberDetails] = useMutation(UPDATE_MEMBER, {
    onCompleted: (data) => {},
  });

  const updateMember = (object) => {
    updateMemberDetails({
      variables: {
        user_id: Number(params.memberID),
        object: { ...object },
      },
    }).then(() => {
      completeSurvey();
    });
  };

  const submitForm = () => {
    switch (surveyDetails.member_type) {
      case 1:
        physiciansForm.submit();
        break;
      case 2:
        techniciansForm.submit();
        break;
      case 3:
        patientForm.submit();
        break;
      case 4:
        consumersForm.submit();
        break;
      default:
        break;
    }
  };

  const physiciansFormSubmit = (values) => {
    let object = {
      f_name: values.f_name,
      l_name: values.l_name,
      primary_specialty: values.primary_speciality,
      secondary_specialty: values.secondary_speciality,
      email: values.email,
      state: values.state,
      city: values.city,
      pincode: parseInt(values.pincode),
      gender: values.gender,
      year_of_birth: values.year_of_birth,
      phone_number: values.phone_number,
      meta_date: {
        respondent: values.respondent,
        mobile1: values.mobile1,
        mobile2: values.mobile2,
        hospitalType: values.hospitalType,
        hospitalName: values.hospitalName,
        hospital1: values.hospital1,
        hospital2: values.hospital2,
      },
      project_compatibilitys: values.project_compatibility,
    };
    updateMember(object);
  };

  const techniciansFormSubmit = (values) => {
    let object = {
      title: values.title,
      f_name: values.f_name,
      phone_number: values.phone_number,
      email: values.email,
      state: values.state,
      city: values.city,
      pincode: parseInt(values.pincode),
      meta_date: {
        profession: values.profession,
        sub: values.sub,
        organisation: values.organisation,
      },
    };
    updateMember(object);
  };

  const patientFormSubmit = (values) => {
    let object = {
      title: values.title,
      f_name: values.f_name,
      phone_number: values.phone_number,
      email: values.email,
      gender: values.gender,
      year_of_birth: values.year_of_birth,
      state: values.state,
      city: values.city,
      pincode: parseInt(values.pincode),
      meta_date: {
        ailments: values.ailment_list,
      },
    };
    updateMember(object);
  };

  const consumersFormSubmit = (values) => {
    let object = {
      title: values.title,
      f_name: values.f_name,
      phone_number: values.phone_number,
      email: values.email,
      gender: values.gender,
      year_of_birth: values.year_of_birth,
      state: values.state,
      city: values.city,
      pincode: parseInt(values.pincode),
    };
    updateMember(object);
  };

  const completeSurvey = () => {
    changeSurveyStatusToComplete({
      variables: {
        survey_id: Number(params.surveyID),
        member_id: Number(params.memberID),
      },
    }).then((res) => {
      setSurveyState((oldSurveyState) => ({
        ...oldSurveyState,
        survey_finished: true,
      }));
      // window.location.reload();
    });
  };

  const previous = () => {
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_question:
        surveyDetails.questions[surveyDetails.question_index - 1],
      question_index: surveyDetails.question_index - 1,
      current_answer: [],
      file_url: [],
      answer: "",
      current_answer_type:
        surveyDetails.questions[surveyDetails.question_index - 1].question_type
          .name,
      survey_completed: false,
    }));
  };

  return (
    <Spin spinning={loading}>
      <div className="pt-4 lg:pt-10 flex flex-col items-center justify-start bg-white shadow relative">
        <div className="fixed lg:absolute bottom-0 left-0 right-0 pt-2 lg:pt-0 h-32 lg:h-20 grid grid-cols-2 gap-4 lg:flex lg:items-center lg:justify-end border-t-2 border-bgLite bg-white px-4 lg:px-12 z-50">
          <Button
            className="rounded px-8 w-full lg:w-auto"
            size="large"
            onClick={() => completeSurvey()}
            type="link"
          >
            Skip
          </Button>
          <Button
            className="rounded px-8 mr-4 text-orange w-full lg:w-auto"
            size="large"
            onClick={() => previous()}
          >
            Previous
          </Button>
          <Button
            className="rounded col-span-2 px-8 w-full lg:w-auto"
            size="large"
            onClick={() => submitForm()}
            type="primary"
          >
            Submit Survey
          </Button>
        </div>
        <div className="w-full px-4 lg:w-2/3 lg:px-0 pb-32 bg-white shadow lg:pb-20 lg:bg-transparent lg:shadow-none">
          <p className="text-h6 font-semibold text-textMedium">
            Profiler questions
          </p>
          {!loading && (
            <React.Fragment>
              {surveyDetails.member_type === 1 && (
                <PhysiciansForm
                  formData={formData}
                  form={physiciansForm}
                  email={memberdetails?.user_by_pk?.email}
                  onSubmit={physiciansFormSubmit}
                />
              )}
              {surveyDetails.member_type === 2 && (
                <TechniciansForm
                  formData={formData}
                  form={techniciansForm}
                  email={memberdetails?.user_by_pk?.email}
                  onSubmit={techniciansFormSubmit}
                />
              )}
              {surveyDetails.member_type === 3 && (
                <PatientForm
                  formData={formData}
                  form={patientForm}
                  email={memberdetails?.user_by_pk?.email}
                  onSubmit={patientFormSubmit}
                />
              )}
              {surveyDetails.member_type === 4 && (
                <ConsumersForm
                  formData={formData}
                  form={consumersForm}
                  email={memberdetails?.user_by_pk?.email}
                  onSubmit={consumersFormSubmit}
                />
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default ProfilerQuestions;
