import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Button,
  Table,
  Form,
  Space,
  Input,
  Divider,
  Switch,
  DatePicker,
  InputNumber,
  notification,
} from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./selectMembers.css";
import moment from "moment";
import { formatDateTime } from "../../../../utils/dateTime";
import {
  // SURVEYxMEMBERS,
  UPDATE_SURVEY_DETAILS,
  DELETE_SURVEY_X_MEMBERS,
  PROJECT_DATES,
  GET_ASSIGNED_MEMBERS,
  GET_MEMBERS,
} from "../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import ColumnEditor from "../../../../components/ColumnEditor";
import { RoleContext } from "../../../../context/RoleContext";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../../../components/memberColumns";
import MemberTable from "../../../../components/memberTable";
import SpecialityModalPop from "../../../../utils/specialitySearch";

function MemberList(props) {
  const { surveyID, projectID } = useParams();
  // const memberListDetails = useRecoilValue(projectForm);
  const [isEnabled, setIsEnabled] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [quotaInput, setQuotaInput] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  // const [projectStartDate, setProjectStartDate] = useState(null);
  // const [projectEndDate, setProjectEndDate] = useState(null);
  let searchInput;
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [quota, setQuota] = useState([]);
  // const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  // const [insertmembers] = useMutation(SURVEYxMEMBERS);
  const [deleteMembers] = useMutation(DELETE_SURVEY_X_MEMBERS);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_DETAILS);
  const savedprf = localStorage.getItem('prf');
  const prfinitial = savedprf
    ? JSON.parse(savedprf)
    : {
      ...memberColumnStatus,
      quota: { enabled: true, label: "Quota" }
    }
  const [columnStatus, setStatus] = useState(prfinitial);
  const { role } = useContext(RoleContext);

  const { data: assignedMembers, refetch } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "no-cache",
  });
  const { data: projectDates } = useQuery(PROJECT_DATES, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    localStorage.setItem('prf', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    refetch();
    if (assignedMembers?.survey && assignedMembers?.survey?.length !== 0) {
      let time =
        moment().isSameOrAfter(
          formatDateTime(assignedMembers?.survey[0]?.start_date)
        ) && props.status !== "draft";
      setIsEnabled(time);
      setStartDate(
        assignedMembers?.survey[0]?.start_date !== null
          ? moment(assignedMembers?.survey[0]?.start_date)
          : null
      );
      setDueDate(
        assignedMembers?.survey[0]?.end_date !== null
          ? moment(assignedMembers?.survey[0]?.end_date)
          : null
      );
      setSwitchChecked(
        assignedMembers?.survey[0]?.display_questions_on_survey_cards
      );
    }
    if (
      assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member?.length > 0
    ) {
      let object,
        newObject = [];
      let quotaCopy = [];
      let completedCopy = [];
      object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      object.forEach((item, index) => {
        item.user.genderNew =
          item?.user?.gender === null ? "Not updated" : item?.user?.gender;
        item.user.category = item.user.member_type.name;
        item.user.memberName =
          item?.user?.l_name !== null
            ? item?.user?.f_name + " " + item?.user?.l_name
            : item?.user?.f_name;
        item.user.quota = item?.quota || 1;
        item.user.completed = item?.completed || 0;
        quotaCopy[index] = item?.quota || 1;
        completedCopy[index] = item?.completed || 0;
      });
      object.forEach((item) => {
        newObject.push(userMemberData(item?.user));
      });
      setTableData(newObject);
      setQuota(quotaCopy);
      setCompleted(completedCopy);
    }
    // eslint-disable-next-line
  }, [assignedMembers, projectDates, location]);

  const handleClick = () => {
    props.handleToggle(false);
    history.goBack();
  };

  const switchOnChange = (checked) => {
    setSwitchChecked(checked);
  };

  const quotaChange = (e) => {
    setQuotaInput(e);
  };
  const applyAll = (e) => {
    e.preventDefault();
    let copy = new Array(assignedMembers?.survey_x_member?.length).fill(
      quotaInput
    );
    setQuota(copy);
  };
  // eslint-disable-next-line
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const handleFormValues = (buttonAction) => {
    let payload = [];
    let surveymemberID = [];

    let quotaError = quota.some((el) => {
      return el === "undefined" || el === null;
    });
    if (!startDate) {
      setErrorMessage("Please select the starting date of the survey");
    } else if (!dueDate) {
      setErrorMessage("Please select the due date of the survey");
    } else if (quota.length <= 0 || quotaError) {
      setErrorMessage("Please set quota for all members");
    } else if (formatDateTime(startDate) === formatDateTime(dueDate)) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (moment(startDate).isSameOrAfter(moment(dueDate))) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (
      !isEnabled &&
      moment(startDate).isSameOrBefore(moment()) &&
      moment(startDate).date() === moment().date()
    ) {
      setErrorMessage("Select a Go-live time greater than current time");
    } else {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member?.forEach((key, index) => {
          payload.push({
            member_id: key.user.id,
            quota: quota[index],
            completed: completed[index],
            state: key.user.survey_x_members[0].state,
            survey_id: parseInt(surveyID),
          });
        });
      deleteMembers({
        variables: {
          array: surveymemberID,
          object: payload,
        },
      }).then(() => {
        updateSurvey({
          variables: {
            id: parseInt(surveyID),
            startDate: startDate,
            endDate: dueDate,
            show: switchChecked,
            status: buttonAction,
          },
        }).then(() => {
          if (buttonAction === "active") {
            notification.success({
              message: "Success!",
              description: "Survey created!",
            });
            history.push("/projectList/active");
          } else {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          }
        });
      });
    }
  };
  const onComplete = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });
  // eslint-disable-next-line
  /*const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "Category",
      dataIndex: "category",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "Gender",
      dataIndex: "genderNew",
      filters: [
        {
          text: "Male",
          value: "M",
        },
        {
          text: "Female",
          value: "F",
        },
        {
          text: "Not updated",
          value: "Not updated",
        },
      ],

      onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "Quota",
      dataIndex: "quota",
      render: (value, item, index) => (
        <>
          <InputNumber
            className="w-20 rounded-md"
            placeholder="0000"
            min={1}
            max={100}
            value={quota[index]}
            onChange={(e) => {
              let copy = [...quota];
              copy[index] = e;
              setQuota(copy);
            }}
          />
        </>
      ),
    },
  ];*/


  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus, handleIconClick), ...roleBasedColumns(role, columnStatus),
    ...[{
      title: "Quota",
      dataIndex: "quota",
      isShow: columnStatus?.quota?.enabled,
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      render: (value, item, index) => (
        <>
          <InputNumber
            className="w-20 rounded-md"
            placeholder="0000"
            min={1}
            max={100}
            value={quota[index]}
            onChange={(e) => {
              let copy = [...quota];
              copy[index] = e;
              setQuota(copy);
            }}
          />
        </>
      ),
    }]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus, quota])

  function disabledDate(current) {
    if (moment().isSameOrAfter(moment(projectDates?.project[0]?.start_date))) {
      return (
        current < moment().startOf("day") ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    } else {
      return (
        current < moment(projectDates?.project[0]?.start_date) ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    }
  }

  function disabledDuebyDate(current) {
    return (
      current < moment(projectDates?.project[0]?.start_date) ||
      current > moment(projectDates?.project[0]?.end_date)
    );
  }

  return (
    <>
      <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div>
      <Form>
        <div className="selectMembers px-10 py-4 w-full">
          <p className="text-textMedium text-lg float-left w-1/2">
            Select members
          </p>
          <Form.Item className="float-right">
            <InputNumber
              className="w-20 rounded-md"
              placeholder="0000"
              min={1}
              max={100}
              onChange={(e) => {
                quotaChange(e);
              }}
            />
            <button
              className="text-orange text-base ml-3"
              size="middle"
              type="link"
              onClick={applyAll}
              disabled={!quotaInput}
            >
              Apply quota to all
            </button>
            <Divider type="vertical" />
            <button
              className="text-orange text-base"
              size="middle"
              type="link"
              onClick={handleClick}
            >
              Edit list
            </button>
            <span>
              <ColumnEditor columns={columnStatus} onSave={(column) => {
                setStatus(column)
              }} />
            </span>
          </Form.Item>
          <Form.Item className="block" name="members">
            {/*<Table
              columns={columns}
              dataSource={tableData}
              rowClassName={(record, index) => "text-sm text-textMedium"}
              pagination={false}
              rowKey={(record) => record.id}
            />*/}
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              pagination={false}
            />

            <SpecialityModalPop
              titleSpec={`Selected ${selectedColumnNameSpec}`}
              visibleSpec={isModalVisibleSpec}
              onOkSpec={handleModalOkSpec}
              onCancelSpec={handleModalCloseSpec}
              searchQuerySpec={searchQuerySpec}
              handleSearchSpec={handleSearchSpec}
              handleCheckboxChangeSpec={handleCheckboxChangeSpec}
              filteredCheckboxDataSpec={filteredCheckboxDataSpec}
              selectedDataSpec={selectedDataSpec}
              initialSelectedDataSpec={initialSelectedDataSpec}
              isSubmitDisabledSpec={isSubmitDisabledSpec}
              handleResetSpec={handleResetSpec}
            />
          </Form.Item>
          <Form.Item>
            <span className="text-lg text-textDark">
              Display the number of questions on survey card
            </span>
            <Switch
              checked={switchChecked}
              onChange={switchOnChange}
              className="float-right ml-4"
            ></Switch>
            <span className="text-base text-textLight float-right">
              {switchChecked === true ? "YES" : "NO"}
            </span>
          </Form.Item>
          <Divider />
          <p className="text-lg text-textDark">Schedule Survey</p>
          <Form.Item className="text-textLight text-sm w-3/5 float-left">
            <span>GO-LIVE</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                showNow={false}
                disabled={isEnabled}
                value={startDate}
                showTime
                className="w-40"
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </span>
            <span className="ml-4">DUE BY</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDuebyDate}
                showNow={false}
                disabled={
                  startDate === null || startDate === undefined ? true : false
                }
                value={dueDate}
                showTime
                onChange={(date) => {
                  setDueDate(date);
                }}
              />
            </span>
          </Form.Item>
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={onComplete}
            >
              Schedule
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
export default MemberList;
