import { Button, Checkbox, Col, Popover, Row } from "antd";
import React, { useEffect, useState } from "react";
import customColumn from "../assets/customColumn.png";

export default function ColumnEditor({ onSave, columns }) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [columnList, setColumnList] = useState(columns);
  useEffect(() => {
    setColumnList(columns);
  }, [columns]);

  const onChange = (checkedValues) => {
    let copy = JSON.parse(JSON.stringify(columnList));

    if (checkedValues.length !== 0) {
      for (let key in copy) {
        checkedValues.find((el) => {
          if (el === key) {
            return (copy[key].enabled = true);
          } else {
            return (copy[key].enabled = false);
          }
        });
      }
    } else {
      for (let key in copy) {
        copy[key].enabled = false;
      }
    }
    setColumnList(copy);
  };
  return (
    <Popover
      trigger={["click"]}
      title=""
      placement="bottomRight"
      visible={popoverOpen}
      onVisibleChange={setPopoverOpen}
      content={
        <div className=" w-60">
          <p className="text-h8 text-textDark font-semibold px-3 mb-0 py-2">
            Columns
          </p>
          <div key="1">
            <Row>
              <Col span={24} className="column_Editor_body">
                <Checkbox.Group
                  onChange={onChange}
                  className="w-full"
                  value={Object.keys(columnList)
                    .map((column, columnIndex) => {
                      if (columnList[column].enabled) return column;
                    })
                    .filter((column) => column)}
                >
                  {Object.keys(columnList).map((column, columnIndex) => {
                    return (
                      <Row className="mb-2" key={column + columnIndex}>
                        <Col span={24}>
                          <Checkbox
                            value={column}
                            checked={columnList[column].enabled}
                          >
                            {columnList[column].label}
                          </Checkbox>
                        </Col>
                      </Row>
                    );
                  })}
                </Checkbox.Group>
              </Col>
              <Col span={24} className=" text-right mt-4">
                <Button
                  className=""
                  type="link"
                  size="small"
                  onClick={() => {
                    setPopoverOpen(false);
                    setColumnList(columns);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="ml-2"
                  type="primary"
                  size="small"
                  onClick={() => {
                    setPopoverOpen(false);
                    onSave(columnList);
                  }}
                >
                  OK
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      }
    >
      <img
        src={customColumn}
        className="float-right ml-4"
        style={{ width: "2rem", cursor: "pointer" }}
        alt="customcolumn"
      />
    </Popover>
  );
}
