import React, { useState, useEffect } from "react";
import {
  Row,
  Button,
  Switch,
  Menu,
  Col,
  Dropdown,
  Spin,
  Table,
  Card,
  notification,
} from "antd";
import {
  DownOutlined,
  PlusOutlined,
  MoreOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import AdminForm from "./adminForm";
import ManagerForm from "./managerForm";
import EditAdminForm from "./editAdminForm";
import EditManagerForm from "./editManagerForm";
import InterviewerForm from "./interviewerForm";
import EditInterviewerForm from "./editInterviewerForm";
import ViewInterviewerForm from "./viewInterviewerForm";
import ViewManagerForm from "./viewManagerForm";
import {
  GET_USERS,
  DEACTIVATE_ACTIVATE_USERS,
  CREATE_ADMIN,
  CREATE_MANAGER,
  CREATE_INTERVIEWER,
  GET_EMAIL_PHONE,
  EDIT_USERS,
} from "./graphql";
import { useQuery, useMutation } from "@apollo/client";
import { getColumnSearchProps } from "../../utils/table";
import { ONBOARD_USER } from "../createClient/graphql";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../components/ColumnEditor";

const UsersList = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [isAdminVisible, setIsAdminVisible] = useState(false);
  const [adminData, setAdminData] = useState(null);
  const [isManagerVisible, setIsManagerVisible] = useState(false);
  const [managereData, setManagerData] = useState(null);
  const [isInterviewerVisible, setIsInterviewerVisible] = useState(false);
  const [interviewerData, setInterviewerData] = useState(null);
  const [isEditAdminVisible, setIsEditAdminVisible] = useState(false);
  const [isEditManagerVisible, setIsEditManagerVisible] = useState(false);
  const [isEditInterviewerVisible, setIsEditInterviewerVisible] =
    useState(false);

  const [isViewManagerVisible, setIsViewManagerVisible] = useState(false);
  const [isViewInterviewerVisible, setIsViewInterviewerVisible] =
    useState(false);
  const [adminEditData, setAdminEditData] = useState(null);
  const [managerEditData, setManagerEditData] = useState(null);
  const [interviewerEditData, setInterviewerEditData] = useState(null);
  const [showInactive, setShowInactive] = useState(true);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [tableData, setTableData] = useState([]);
  const { data, loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      // setEmail(d.user.map((user) => user.email));
      // setPhone(d.user.map((user) => user.phone_number));
    },
  });
  // eslint-disable-next-line
  const { data: emailPhone, loading: emailPhoneLoading } = useQuery(
    GET_EMAIL_PHONE,
    {
      onCompleted: (res) => {
        let emailCopy = [],
          phoneCopy = [];
        res?.user.forEach((user) => {
          emailCopy.push(user.email);
          phoneCopy.push(user.phone_number);
        });
        setEmail(emailCopy);
        setPhone(phoneCopy);
      },
    }
  );
  const [onboardUser] = useMutation(ONBOARD_USER);
  const [deactivateUsers] = useMutation(DEACTIVATE_ACTIVATE_USERS);
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [createManager] = useMutation(CREATE_MANAGER);
  const [createInterviewer] = useMutation(CREATE_INTERVIEWER);
  const [editUsers] = useMutation(EDIT_USERS);
  const [propData, setPropData] = useState(null);
  const [interviewerPropData, setInterviewerPropData] = useState(null);
  const [role, setRole] = useState(null);
  const savedusersTable = localStorage.getItem('usersTable');
  const usersTableinitial = savedusersTable
    ? JSON.parse(savedusersTable)
    : {
      "name": { enabled: true, label: "User Name" },
      "email": { enabled: true, label: "Email" },
      "phone_number": { enabled: true, label: "Phone Number" },
      "roleName": { enabled: true, label: "Role" },
    }
  const [columnStatus, setStatus] = useState(usersTableinitial);

  useEffect(() => {
    localStorage.setItem('usersTable', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (data) {
      if (role === "sub_admin") {
        let copy = JSON.parse(JSON.stringify(data));
        let userArr = [],
          userObj = {};
        copy.user.forEach((user) => {
          if (user.role.id !== 2) {
            userObj = user;
            userObj.name = userObj?.f_name + " " + userObj?.l_name;
            userObj.roleName = userObj?.role?.description;
            // userObj.is_verified = userObj?.is_verified;
            if (queryParams.get('role')) {
              if (userObj?.role?.name === queryParams.get("role"))
                userArr.push(userObj);
            } else {
              userArr.push(userObj);
            }
          }
        });
        setTableData(userArr);
      } else {
        let userArr = [];
        let copy = JSON.parse(JSON.stringify(data));
        copy.user.forEach((user) => {
          user.name = user?.f_name + " " + user?.l_name;
          user.roleName = user?.role?.description;
          console.log(user?.role?.name)
          if (queryParams.get('role')) {
            if (user?.role?.name === queryParams.get("role"))
              userArr.push(user);
          } else {
            userArr.push(user);
          }
        });
        setTableData(userArr);
      }
    }
  }, [data, role]);

  useEffect(() => {
    if (showInactive) {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        let tableCopy = [];
        copy.user.forEach((user) => {
          user.name = user?.f_name + " " + user?.l_name;
          user.roleName = user?.role?.description;
          if (queryParams.get('role')) {
            if (user?.role?.name === queryParams.get("role"))
              tableCopy.push(user);
          } else {
            tableCopy.push(user);
          }
        });
        setTableData(tableCopy);
      }
    } else {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        let tableCopy = [];
        copy.user.forEach((user) => {
          if (user.is_active) {
            user.name = user?.f_name + " " + user?.l_name;
            user.roleName = user?.role?.description;
            if (queryParams.get('role')) {
              if (user?.role?.name === queryParams.get("role"))
                tableCopy.push(user);
            } else {
              tableCopy.push(user);
            }
          }
        });
        setTableData(tableCopy);
      }
    } //eslint-disable-next-line
  }, [showInactive]);

  useEffect(() => {
    if (adminData) {
      createAdmin({
        variables: {
          ...adminData,
        },
      })
        .then((data) => {
          onboardUser({
            variables: {
              user_id: data?.data?.insert_user_one?.id,
            },
            // refetchQueries: [GET_USERS],
          });
        })
        .then(() => {
          notification.success({
            message: "Email sent",
          });
          refetch();
        });
    } //eslint-disable-next-line
  }, [adminData]);

  useEffect(() => {
    if (managereData) {
      createManager({
        variables: {
          ...managereData,
        },
      })
        .then((data) => {
          onboardUser({
            variables: {
              user_id: data?.data?.insert_user_one?.id,
            },
            // refetchQueries: [GET_USERS],
          });
        })
        .then(() => {
          notification.success({
            message: "Email sent",
          });
          refetch();
        });
    } //eslint-disable-next-line
  }, [managereData]);

  useEffect(() => {
    if (interviewerData) {
      createInterviewer({
        variables: {
          ...interviewerData,
        },
      })
        .then((data) => {
          onboardUser({
            variables: {
              user_id: data?.data?.insert_user_one?.id,
            },
            // refetchQueries: [GET_USERS],
          });
        })
        .then(() => {
          notification.success({
            message: "Email sent",
          });
          refetch();
        });
    } //eslint-disable-next-line
  }, [interviewerData]);

  useEffect(() => {
    if (adminEditData) {
      editUsers({
        variables: {
          object: {
            ...adminEditData,
          },
          id: propData?.id,
        },
        // refetchQueries: [GET_USERS],
      }).then(() => {
        refetch();
        setPropData(null);
      });
    } else if (managerEditData) {
      editUsers({
        variables: {
          object: {
            ...managerEditData,
          },
          id: propData?.id,
        },
        // refetchQueries: [GET_USERS],
      }).then(() => {
        refetch();
        setPropData(null);
      });
    } else if (interviewerEditData) {
      editUsers({
        variables: {
          object: {
            ...interviewerEditData,
          },
          id: interviewerPropData?.id,
        },
        // refetchQueries: [GET_USERS],
      }).then(() => {
        refetch();
        setInterviewerPropData(null);
      });
    } //eslint-disable-next-line
  }, [adminEditData, managerEditData, interviewerEditData]);

  const menu = (
    <Menu>
      {role === "super_admin" && (
        <Menu.Item
          key="1"
          onClick={() => {
            setIsAdminVisible(true);
          }}
        >
          Admin
        </Menu.Item>
      )}
      <Menu.Item
        key="2"
        onClick={() => {
          setIsManagerVisible(true);
        }}
      >
        Manager
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          setIsInterviewerVisible(true);
        }}
      >
        Interviewer
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "USERNAME",
      dataIndex: "name",
      className: columnStatus?.name?.enabled ? "table-cell" : "hidden",
      key: "name",
      ...getColumnSearchProps("name", "name"),
      width: 200,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
    },
    {
      title: "ROLE",
      dataIndex: "roleName",
      className: columnStatus?.roleName?.enabled ? "table-cell" : "hidden",
      key: "role",
      filters:
        role === "sub_admin"
          ? [
            {
              text: "Manager",
              value: "Manager",
            },
            {
              text: "Interviewer",
              value: "Interviewer",
            },
          ]
          : [
            {
              text: "Admin",
              value: "Admin",
            },
            {
              text: "Manager",
              value: "Manager",
            },
            {
              text: "Interviewer",
              value: "Interviewer",
            },
          ],
      onFilter: (value, record) => record.roleName.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      width: 200,
    },
    {
      title: "ACTION",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              {record?.is_verified && (
                <Menu.Item
                  onClick={() => {
                    console.log(record?.roleName);
                    if (record?.roleName === "Manager") {
                      setPropData(record);
                      setIsEditManagerVisible(true);
                    } else if (record?.roleName === "Admin") {
                      setPropData(record);
                      setIsEditAdminVisible(true);
                    } else if (record?.roleName === "Interviewer") {
                      setInterviewerPropData(record);
                      setIsEditInterviewerVisible(true);
                    }
                  }}
                  key={1}
                >
                  Edit user
                </Menu.Item>
              )}
              {record?.is_verified ? (
                record?.is_active ? (
                  <Menu.Item
                    onClick={() =>
                      deactivateUsers({
                        variables: {
                          id: record.id,
                          status: false,
                        },
                      }).then(() => {
                        refetch();
                      })
                    }
                    key={2}
                  >
                    Deactivate
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    onClick={() =>
                      deactivateUsers({
                        variables: {
                          id: record.id,
                          status: true,
                        },
                      }).then(() => {
                        refetch();
                      })
                    }
                    key={2}
                  >
                    Activate
                  </Menu.Item>
                )
              ) : null}
              {!record?.is_verified && (
                <Menu.Item
                  key={3}
                  onClick={() => {
                    if (record?.roleName === "Manager") {
                      setPropData(record);
                      setIsViewManagerVisible(true);
                    }
                    // else if (record?.roleName === "Admin") {
                    //   setPropData(record);
                    //   setIsEditAdminVisible(true);
                    // }
                    else if (record?.roleName === "Interviewer") {
                      setInterviewerPropData(record);
                      setIsViewInterviewerVisible(true);
                    }
                  }}
                >
                  View
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold cursor-pointer" />
        </Dropdown>
      ),
      width: 80,
      align: "center",
    },
  ];

  return (
    <Spin spinning={loading}>
      <Row className="px-10 py-4">
        <div className="w-full h-16 flex justify-between">
          <p className="text-textMedium text-2xl w-40">
            User details
          </p>
          <div className=" flex">
            <div className="text-textMedium pt-1 pr-6">
              <span className="pr-2">Show inactive users</span>
              <Switch
                defaultChecked
                onChange={(e) => {
                  setShowInactive(e);
                }}
              />
            </div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="primary"
                htmlType="submit"
                className="text-base rounded-md"
              >
                <Row justify="space-between" align="middle">
                  <Col className="mr-4 -mt-1.5">
                    <PlusOutlined />
                  </Col>
                  <Col>Add a new user</Col>
                  <Col className="ml-4 -mt-1.5">
                    <DownOutlined />
                  </Col>
                </Row>
              </Button>
            </Dropdown>
            <div className=" ml-5 float-right">
              <ColumnEditor columns={columnStatus} onSave={(column) => {
                setStatus(column)
              }} />
            </div>
          </div>
        </div>
        <AdminForm
          isVisible={isAdminVisible}
          setIsVisible={setIsAdminVisible}
          users={email}
          phone={phone}
          setData={setAdminData}
        />
        <EditAdminForm
          isVisible={isEditAdminVisible}
          setIsVisible={setIsEditAdminVisible}
          setData={setAdminEditData}
          data={propData}
          clearData={setPropData}
        />
        <ManagerForm
          isVisible={isManagerVisible}
          setIsVisible={setIsManagerVisible}
          users={email}
          phone={phone}
          setData={setManagerData}
          role={role}
        />
        <EditManagerForm
          isVisible={isEditManagerVisible}
          setIsVisible={setIsEditManagerVisible}
          setData={setManagerEditData}
          data={propData}
          clearData={setPropData}
        />
        <InterviewerForm
          isVisible={isInterviewerVisible}
          setIsVisible={setIsInterviewerVisible}
          users={email}
          phone={phone}
          setData={setInterviewerData}
          role={role}
        />
        <EditInterviewerForm
          isVisible={isEditInterviewerVisible}
          setIsVisible={setIsEditInterviewerVisible}
          setData={setInterviewerEditData}
          data={interviewerPropData}
          clearData={setInterviewerPropData}
        />
        <ViewInterviewerForm
          isVisible={isViewInterviewerVisible}
          setIsVisible={setIsViewInterviewerVisible}
          setData={setInterviewerEditData}
          data={interviewerPropData}
          clearData={setInterviewerPropData}
          role={role}
        />
        <ViewManagerForm
          isVisible={isViewManagerVisible}
          setIsVisible={setIsViewManagerVisible}
          setData={setManagerEditData}
          data={propData}
          clearData={setPropData}
          role={role}
        />
      </Row>
      <Row className="px-10 py-4">
        <Col span={24}>
          <Card className="py-0" bodyStyle={{ padding: 0 }}>
            <Table
              columns={columns}
              dataSource={tableData}
              rowKey={(record) => record.id}
              rowClassName={(record) => {
                return (record.is_verified || record.is_verified === null) &&
                  record.is_active
                  ? "text-sm text-textMedium p-0 m-0 "
                  : !record.is_verified
                    ? " text-sm text-yellow-500 p-0 m-0"
                    : " text-sm text-textExtraLight p-0 m-0";
              }}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default UsersList;
