import { gql } from "graphql-tag";

export const GET_USER_DETAIL = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const GET_USER_INFO = gql`
  query getUserById($passkey: String) {
    user(where: { pass_key: { _eq: $passkey } }) {
      id
      email
      is_active
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotpassword($email: String!) {
    forgotpassword(email: $email) {
      message
    }
  }
`;

export const CREATE_PASSWORD = gql`
  mutation add_password($pass_key: String!, $password: String!) {
    add_password(pass_key: $pass_key, password: $password) {
      message
      role_id
    }
  }
`;

export const GET_EMAILS = gql`
  query GET_EMAILS {
    user {
      email
    }
  }
`;

export const GET_SPECIALITIES = gql`
  query GET_SPECIALITIES {
    specialties {
      id
      speciality_name
    }
  }
`;

export const GET_ROLES = gql`
  query GET_ROLES {
    member_type {
      id
      name
    }
  }
`;

export const SIGNUP = gql`
  mutation SIGNUP(
    $email: String
    $firstName: String
    $lastName: String
    $phone: String
    $role: Int
    $speciality: Int
  ) {
    insert_user(
      objects: {
        email: $email
        f_name: $firstName
        l_name: $lastName
        member_type_id: $role
        password: ""
        phone_number: $phone
        primary_specialty: $speciality
        role_id: 6
      }
    ) {
      affected_rows
    }
  }
`;
