import React, { useEffect } from "react";
import QuestionCreation from "./questionnaire";
import Preview from "./preview";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { projectForm } from "../../../../atoms";
import { useRecoilValue } from "recoil";

function Questionnaire(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const surveyDetails = useRecoilValue(projectForm);
  const currentTime = moment().format();
  const startDate =
    surveyDetails.surveyDetails && surveyDetails.surveyDetails.start_date;
  const location = useLocation();

  useEffect(() => {
    if (
      startDate !== null &&
      !moment(currentTime).isSameOrBefore(startDate) &&
      props.status === "active"
    ) {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
          );
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {((props.status === "draft" &&
        location.pathname.split("/")[8] === undefined) ||
        location.pathname.split("/")[8] !== "preview") && (
        <QuestionCreation
          handleEnable={props.handleEnable}
          startDate={props.startDate}
          status={props.status}
        />
      )}
      {location.pathname.split("/")[8] === "preview" && (
        <Preview
          enableButton={true}
          viewcollapse={true}
          isStarted={moment(startDate).isSameOrAfter(currentTime)}
          startDate={props.startDate}
          status={props.status}
        />
      )}
    </div>
  );
}

export default Questionnaire;
