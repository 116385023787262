import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Divider, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { user } from "../../../atoms";
import Loading from "../../../components/Loading";
import {
  DELETE_POC_BY_ID,
  GET_POC_BY_CLIENT,
  INSERT_POC1,
  ONBOARD_USER,
  UPDATE_POC,
  VALIDATE_POC_EMAIL,
} from "../graphql.js";

function AddPoc(props) {
  const clientID = parseInt(props.clientId); //useRecoilValue(client);
  const userIdFromRecoil = useRecoilValue(user);
  const location = useLocation();
  const history = useHistory();
  const [initialData, setInitialData] = useState([]);
  const [pocData, setPocData] = useState({});
  const [userID, setUserID] = useState(null);

  const [form] = Form.useForm();
  let mobile = /^[0-9()+-\s]{0,}$/;

  const initialValues = {
    selectPOC: [""],
  };

  const onFinish = async (values) => {
    let emailList = [];
    let pocIds = [];
    let user_id_final = parseInt(userID || userIdFromRecoil);
    let poc_user_ids = [];
    let createList = [];
    let updateList = [];
    let deleteList = [];

    let initialPocIds = initialData.reduce(
      (ids, poc) => ids.concat(poc.id),
      []
    );
    for (let poc of values.data) {
      if (emailList.includes(poc.email)) {
        notification.error({
          message: "Duplicate email found between poc(s). Email: " + poc.email,
        });
        return;
      }
      poc.phone_number = poc.phone_number + "";
      poc.client_user = clientID;
      poc.client_id = user_id_final;
      if (poc.id) {
        pocIds.push(poc.id);
        poc_user_ids.push(poc.user_id);
        updateList.push(poc);
      } else {
        poc.user = {
          data: {
            f_name: poc.first_name,
            l_name: poc.last_name,
            email: poc.email,
            phone_number: poc.phone_number,
            role_id: 7,
            password: "",
            is_verified: props.role === "super_admin" ? true : false,
          },
        };
        createList.push(poc);
      }
      emailList.push(poc.email);
    }
    let deleteIds = initialPocIds.filter((id) => !pocIds.includes(id));
    if (deleteIds) {
      deleteList = deleteIds;
    }
    setPocData({
      create: createList,
      update: updateList,
      delete: deleteList,
    });
    if (emailList.length) {
      validateEmailsAndCreatePOC({
        variables: {
          emailIds: emailList,
          pocIds: pocIds,
          userIds: poc_user_ids,
        },
      });
    }
  };

  const {
    // eslint-disable-next-line
    data: clientMetaData,
    loading: loadingClient,
  } = useQuery(GET_POC_BY_CLIENT, {
    //skip: !props.isEdit,
    fetchPolicy: "network-only",
    variables: {
      client_id: clientID,
    },
    onCompleted: (data) => {
      if (data.poc) {
        if (data.poc.length) {
          let pocData = [...data.poc];
          let user_id = null;
          pocData = pocData
            // eslint-disable-next-line
            .map((poc) => {
              user_id = poc.clientByClientUser.user_id;
              return {
                designation: poc.designation,
                email: poc.email,
                first_name: poc.first_name,
                id: poc.id,
                user_id: poc.user_id,
                last_name: poc.last_name,
                phone_number: poc.phone_number,
                client_user: clientID,
                client_id: poc.clientByClientUser.user_id,
              };
            });
          setUserID(user_id);
          setInitialData(pocData);
          form.setFieldsValue({
            data: pocData,
          });
        }
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const [onboardUser] = useMutation(ONBOARD_USER, {
    onCompleted: (data) => {
      if (data.onbord_user) {
        notification.success({ message: "Email sent" });
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const [createPoc, { loading: insertPOCLoading }] = useMutation(INSERT_POC1, {
    onError(error) {
      error.graphQLErrors?.forEach((error) => {
        notification.error({
          message: "Error!",
          description: "Something went wrong",
        });
      });
    },
    onCompleted(data) {
      if (data.insert_poc.affected_rows) {
        for (let newpoc of data.insert_poc.returning) {
          onboardUser({
            variables: {
              user_id: newpoc.user_id,
            },
          });
        }
      }
      //history.goBack();
      // history.push(`/viewClient/client-details/client/${clientID}`);
    },
  });

  const upsertAllPocs = async () => {
    let data = { ...pocData };
    let redirect = true;
    if (data.create.length) {
      let createResp = await createPoc({
        variables: {
          data: data.create,
        },
      });
      if (!createResp?.data?.insert_poc?.affected_rows) {
        redirect = false;
      }
    }
    for (let poc of data.update) {
      let updateResp = await updatePoc({
        variables: {
          poc_id: poc.id,
          poc_user_id: poc.user_id,
          first_name: poc.first_name,
          last_name: poc.last_name,
          email: poc.email,
          phone_number: poc.phone_number,
          designation: poc.designation,
        },
      });
      if (!updateResp?.data?.update_user?.affected_rows) {
        redirect = false;
      }
    }
    if (data.delete.length) {
      let deleteUserIds = initialData.reduce((prev, record) => {
        if (data.delete.includes(record.id)) {
          return prev.concat(record.user_id);
        }
        return prev;
      }, []);
      let deleteResp = await deletePoc({
        variables: {
          pocIds: data.delete,
          pocUserIds: deleteUserIds,
        },
      });
      if (!deleteResp?.data?.delete_poc?.affected_rows) {
        redirect = false;
      }
    }
    if (redirect) {
      history.push(`/viewClient/client-details/client/${clientID}`);
    }
  };

  const [updatePoc, { loading: loadingUpdatePoc }] = useMutation(UPDATE_POC, {
    onCompleted: (data) => {
      if (!data.update_user.affected_rows) {
        notification.error({ message: "Update failed." });
      }
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const [deletePoc] = useMutation(DELETE_POC_BY_ID, {
    onCompleted: (data) => {
      if (!data.delete_poc) {
        notification.error({ message: "Some Poc(s) not removed" });
      }
    },
  });

  const [
    validateEmailsAndCreatePOC,
    // eslint-disable-next-line
    { data: emailData, loading: loadingValidatePoc },
  ] = useLazyQuery(VALIDATE_POC_EMAIL, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if ((data.poc && data.poc.length) || (data.user && data.user.length)) {
        let duplicateEmails = new Set(
          [...(data.poc || []), ...(data.user || [])].map((poc) => poc.email)
        );
        notification.error({
          message: `Email Ids (${Array.from(duplicateEmails).join(
            ","
          )}) exists already`,
        });
        return;
      }
      //creating or updating poc
      upsertAllPocs();
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  const triggerCSVDownload = () => {
    document.getElementById("addCustomVal12").click();
  };

  useEffect(() => {
    document.getElementById("addCustomVal12").click();
  }, []);

  return (
    <div>
      {(loadingClient ||
        insertPOCLoading ||
        loadingUpdatePoc ||
        loadingValidatePoc) && <Loading />}
      <Form
        name="projectDetails_form"
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.List name="data" initialValue="">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} style={{ display: "block" }}>
                  <span>POC {name + 1}</span>

                  {fields.length > 1 ? (
                    <span
                      className="float-right text-red cursor-pointer"
                      onClick={() => remove(name)}
                    >
                      Remove
                    </span>
                  ) : (
                    <div />
                  )}

                  <Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">
                          First name
                        </span>
                      }
                      name={[name, "first_name"]}
                      fieldKey={[fieldKey, "first_name"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter First name",
                        },
                      ]}
                      className="float-left "
                      style={{ width: "49%" }}
                    >
                      <Input type="text" />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">
                          Last name
                        </span>
                      }
                      name={[name, "last_name"]}
                      fieldKey={[fieldKey, "last_name"]}
                      rules={[
                        {
                          required: true,
                          message: "Please enter last name",
                        },
                      ]}
                      className="float-left ml-4"
                      style={{ width: "49%" }}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">Phone</span>
                      }
                      name={[name, "phone_number"]}
                      fieldKey={[fieldKey, "phone_number"]}
                      rules={[
                        { required: true, message: "Required" },
                        () => ({
                          validator(_, value) {
                            if (!mobile.test(value)) {
                              return Promise.reject(
                                "Please enter a valid phone number"
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      className="float-left "
                      style={{ width: "49%" }}
                    >
                      <Input style={{ width: "100%" }} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">Email</span>
                      }
                      name={[name, "email"]}
                      fieldKey={[fieldKey, "email"]}
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                      className="float-left ml-4"
                      style={{ width: "49%" }}
                    >
                      <Input type="text" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    className="inline-block"
                    {...restField}
                    label={
                      <span className="text-base text-textLight">
                        Designation
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please enter the designation",
                      },
                    ]}
                    name={[name, "designation"]}
                    fieldKey={[fieldKey, "designation"]}
                    style={{ width: "49%" }}
                  >
                    <Input type="text" />
                  </Form.Item>

                  {name + 1 === fields.length ? (
                    <Divider orientation="right">
                      {" "}
                      <Button
                        htmlType="button"
                        ghost
                        type="primary"
                        className="text-base mx-5 rounded-sm float-right"
                        onClick={() => add()}
                      >
                        Add a new poc
                      </Button>{" "}
                    </Divider>
                  ) : (
                    <Divider />
                  )}

                  <MinusCircleOutlined
                    className="invisible"
                    onClick={() => remove(name)}
                  />
                </div>
              ))}
              <Form.Item className="invisible">
                <Button
                  id="addCustomVal12"
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button
            type="link"
            className="text-base float-left invisible"
            htmlType="button"
            onClick={() => triggerCSVDownload()}
          >
            + Add a poc
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-sm w-28 float-right"
            onClick={() => {
              //  addValues();
              //  history.push("/create_project/assign_members");
            }}
          >
            Submit
          </Button>
          {/* <Button
          htmlType="button"
          className="text-base mx-5 rounded-md text-orange"
        >
          Save as draft
        </Button> */}
          <Button
            type="link"
            className="text-base float-right"
            htmlType="button"
            onClick={() => {
              history.push(
                `${
                  location.pathname.includes("/edit-client")
                    ? "/edit-client"
                    : "/create-client"
                }/${props.clientId}/client-details`
              );
              props.onBack();
            }}
          >
            Back
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
export default AddPoc;
