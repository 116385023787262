import React, { useEffect, useState, useMemo } from "react";
import {
  Row,
  Col,
  Divider,
  Table,
  Button,
  Drawer,
  Form,
  Input,
  Upload,
  message,
  notification,
} from "antd";
import { DownloadOutlined, FilterOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import {
  REWARDS_TAB,
  GET_UPLOAD_URL,
  CREATE_REWARD,
  GET_DOWNLOAD_URL,
  UPDATE_REWARDS,
} from "./graphql";
import { useParams } from "react-router";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";
import jwtDecode from "jwt-decode";
import { getColumnSearchProps } from "../../utils/table";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import ColumnEditor from "../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../components/memberColumns"
import MemberTable from "../../components/memberTable";

const RewardsTab = () => {
  const { surveyID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [role, setRole] = useState(null);
  const [id, setId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [dataObject, setDataObject] = useState(null);
  const { data } = useQuery(REWARDS_TAB, {
    variables: {
      survey_id: parseInt(surveyID),
    },
  });
  const savedrewardsViewPage = localStorage.getItem('rewardsViewPage');
  const rewardsViewPageinitial = savedrewardsViewPage
    ? JSON.parse(savedrewardsViewPage)
    : {
      ...memberColumnStatus,
      "completedOn": { enabled: true, label: "Completed On" },
      "quota": { enabled: true, label: "Quota" },
      "rewardStatus": { enabled: true, label: "Reward Status" },
      "rewardedBy": { enabled: true, label: "Rewarded By" },
      "rewardedOn": { enabled: true, label: "Rewarded On" },
    }
  const [columnStatus, setStatus] = useState(rewardsViewPageinitial);

  useEffect(() => {
    localStorage.setItem('rewardsViewPage', JSON.stringify(columnStatus));
  }, [columnStatus]);

  const [form] = useForm();
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [createReward] = useMutation(CREATE_REWARD, {
    refetchQueries: [
      {
        query: REWARDS_TAB,
        variables: {
          survey_id: parseInt(surveyID),
        },
      },
    ],
  });
  const [updateReward] = useMutation(UPDATE_REWARDS, {
    refetchQueries: [
      {
        query: REWARDS_TAB,
        variables: {
          survey_id: parseInt(surveyID),
        },
      },
    ],
  });
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  /*useEffect(() => {
    let tempData = [],
      tempObject = {};
    if (data) {
      let copyData = JSON.parse(
        JSON.stringify(data?.survey[0]?.survey_x_members)
      );
      copyData?.forEach((record) => {
        tempObject.id = record?.member_id;
        tempObject.survey_x_member = record?.id;
        tempObject.member = record?.user?.f_name;
        tempObject.email = record?.user?.email;
        tempObject.phone_number = record?.user?.phone_number;
        tempObject.completedOn = moment(record?.updated_at).format(
          "DD,MMM,YYYY h:mm a"
        );
        tempObject.memberType = record?.user?.member_type?.name;
        tempObject.quota = record?.quota;
        tempObject.rewardStatus =
          record?.rewards?.length > 0
            ? record?.rewards[0]?.status
            : "Yet to reward";
        tempObject.rewardedBy =
          record?.rewards?.length > 0
            ? record?.rewards[0]?.created_user?.f_name +
            " " +
            record?.rewards[0]?.created_user?.l_name
            : "";
        tempObject.rewardedOn =
          record?.rewards?.length > 0
            ? moment(record?.rewards[0]?.created_at).format(
              "DD,MMM,YYYY h:mm a"
            )
            : "";
        tempObject.note =
          record?.rewards?.length > 0 ? record?.rewards[0]?.comments : "";
        tempObject.file =
          record?.rewards?.length > 0 ? record?.rewards[0]?.file_url : "";
        tempObject.rewardId =
          record?.rewards?.length > 0 ? record?.rewards[0]?.id : 0;
        tempObject.rewardedByRole =
          record?.rewards?.length > 0
            ? record?.rewards[0]?.created_user?.role?.name
            : "";
        tempData.push(tempObject);
        tempObject = {};
      });
      setTableData(tempData);
    }
  }, [data]);*/

  useEffect(() => {
    let newObject = [];
    if (data) {
      let copyData = JSON.parse(
        JSON.stringify(data?.survey[0]?.survey_x_members)
      );
      copyData.forEach((record) => {
        newObject.push(userMemberData(record?.user, (data) => {
          data.id = record?.member_id;
          data.survey_x_member = record?.id;
          data.completedOn = moment(record?.updated_at).format(
            "DD,MMM,YYYY h:mm a"
          );
          data.memberType = record?.user?.member_type?.name;
          data.quota = record?.quota;
          data.rewardStatus = record?.rewards?.length > 0 ? record?.rewards[0]?.status : "Yet to reward";
          data.rewardedBy = record?.rewards?.length > 0 ? record?.rewards[0]?.created_user?.f_name +
            " " +
            record?.rewards[0]?.created_user?.l_name
            : "";
          data.rewardedOn = record?.rewards?.length > 0
            ? moment(record?.rewards[0]?.created_at).format(
              "DD,MMM,YYYY h:mm a"
            )
            : "";
          data.note = record?.rewards?.length > 0 ? record?.rewards[0]?.comments : "";
          data.file = record?.rewards?.length > 0 ? record?.rewards[0]?.file_url : "";
          data.rewardId = record?.rewards?.length > 0 ? record?.rewards[0]?.id : 0;
          data.rewardedByRole = record?.rewards?.length > 0
            ? record?.rewards[0]?.created_user?.role?.name
            : "";
        }));
      });
      setTableData(newObject);
    }
  }, [data]);

  const footer = (
    <Row>
      <div className="w-full h-8 my-2">
        <Button
          type="primary"
          htmlType="submit"
          className="float-right text-base rounded-md"
          onClick={() => {
            form.submit();
          }}
        >
          Submit
        </Button>

        <div className="text-textMedium float-right pt-1 pr-6">
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              setVisible(false);
              form.resetFields();
              setId(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );

  const detailFooter = (
    <Row>
      <div className="w-full h-8 my-2">
        {role === "super_admin" &&
          (dataObject?.rewardedByRole === "manager" ||
            dataObject?.rewardedByRole === "sub_admin") &&
          dataObject?.rewardStatus === "Rewarded" && (
            <Button
              type="primary"
              htmlType="submit"
              className="float-right text-base rounded-md"
              onClick={() => {
                updateReward({
                  variables: {
                    id: dataObject?.rewardId,
                  },
                });
                setDataObject(null);
                setDetailsVisible(false);
              }}
            >
              Approve
            </Button>
          )}
        {role === "admin" &&
          dataObject?.rewardedByRole === "manager" &&
          dataObject?.rewardStatus === "Rewarded" && (
            <Button
              type="primary"
              htmlType="submit"
              className="float-right text-base rounded-md"
              onClick={() => {
                updateReward({
                  variables: {
                    id: dataObject?.rewardId,
                  },
                });
                setDataObject(null);
                setDetailsVisible(false);
              }}
            >
              Approve
            </Button>
          )}

        <div className="text-textMedium float-right pt-1 pr-6">
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              setDetailsVisible(false);
              setDataObject(null);
              setId(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );

  /*const columns = [
    {
      title: "NAME",
      key: "name",
      dataIndex: "member",
      ...getColumnSearchProps("member", "Project Name"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
            : text}
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? text === "Not updated"
              ? "Not updated"
              : `${text.substring(0, 3)}XXXXXXX`
            : text}
        </div>
      ),
    },
    {
      title: "COMPLETED ON",
      key: "date",
      dataIndex: "completedOn",
      className: columnStatus?.completedOn?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("completedOn"),
    },
    {
      title: "QUOTA",
      key: "quota",
      dataIndex: "quota",
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("quota", "Quota"),
    },
    {
      title: "REWARD STATUS",
      key: "rewardStatus",
      dataIndex: "rewardStatus",
      className: columnStatus?.rewardStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Yet to reward",
          value: "Yet to reward",
        },
        {
          text: "Rewarded",
          value: "Rewarded",
        },
        {
          text: "Approved",
          value: "Approved",
        },
      ],
      onFilter: (value, record) => record.rewardStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => {
        return text === "Rewarded" ? (
          <p style={{ color: "#6BC342" }}>{text}</p>
        ) : text === "Yet to reward" ? (
          <p style={{ color: "#FFA63E" }}>{text}</p>
        ) : (
          <p style={{ color: "#C74B5E" }}>{text}</p>
        );
      },
    },
    {
      title: "REWARDED BY",
      key: "by",
      dataIndex: "rewardedBy",
      className: columnStatus?.rewardedBy?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("rewardedBy", "Rewarded By"),
    },
    {
      title: "REWARDED ON",
      key: "on",
      dataIndex: "rewardedOn",
      className: columnStatus?.rewardedOn?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("rewarded On", "Rewarded On"),
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => {
        // eslint-disable-next-line
        {
          // eslint-disable-next-line
          return record.rewardStatus === "Yet to reward" ? (
            <Button
              type="link"
              onClick={() => {
                setVisible(true);
                setId(record.survey_x_member);
              }}
            >
              Reward now
            </Button>
          ) : (
            <Button
              type="link"
              onClick={() => {
                setDetailsVisible(true);
                setDataObject(record);
                setId(record.survey_x_member);
              }}
            >
              View details
            </Button>
          );
        }
      },
    },
  ];*/

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),
    ...[
      {
        title: "COMPLETED ON",
        key: "date",
        dataIndex: "completedOn",
        isShow: columnStatus?.completedOn?.enabled,
        className: columnStatus?.completedOn?.enabled ? "table-cell" : "hidden",
        ...getColumnDateSearchProps("completedOn"),
      },
      {
        title: "QUOTA",
        key: "quota",
        dataIndex: "quota",
        isShow: columnStatus?.quota?.enabled,
        className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("quota", "Quota"),
      },
      {
        title: "REWARD STATUS",
        key: "rewardStatus",
        dataIndex: "rewardStatus",
        isShow: columnStatus?.rewardStatus?.enabled,
        className: columnStatus?.rewardStatus?.enabled ? "table-cell" : "hidden",
        filters: [
          {
            text: "Yet to reward",
            value: "Yet to reward",
          },
          {
            text: "Rewarded",
            value: "Rewarded",
          },
          {
            text: "Approved",
            value: "Approved",
          },
        ],
        onFilter: (value, record) => record.rewardStatus.indexOf(value) === 0,
        filterIcon: () => <FilterOutlined />,
        render: (text) => {
          return text === "Rewarded" ? (
            <p style={{ color: "#6BC342" }}>{text}</p>
          ) : text === "Yet to reward" ? (
            <p style={{ color: "#FFA63E" }}>{text}</p>
          ) : (
            <p style={{ color: "#C74B5E" }}>{text}</p>
          );
        },
      },
      {
        title: "REWARDED BY",
        key: "by",
        dataIndex: "rewardedBy",
        isShow: columnStatus?.rewardedBy?.enabled,
        className: columnStatus?.rewardedBy?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("rewardedBy", "Rewarded By"),
      },
      {
        title: "REWARDED ON",
        key: "on",
        dataIndex: "rewardedOn",
        isShow: columnStatus?.rewardedOn?.enabled,
        className: columnStatus?.rewardedOn?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("rewarded On", "Rewarded On"),
      },
      {
        title: "ACTION",
        key: "action",
        render: (_, record) => {
          // eslint-disable-next-line
          {
            // eslint-disable-next-line
            return record.rewardStatus === "Yet to reward" ? (
              <Button
                type="link"
                onClick={() => {
                  setVisible(true);
                  setId(record.survey_x_member);
                }}
              >
                Reward now
              </Button>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setDetailsVisible(true);
                  setDataObject(record);
                  setId(record.survey_x_member);
                }}
              >
                View details
              </Button>
            );
          }
        },
      },
    ]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus])

  const onSubmit = (values) => {
    let file = "";
    values.file.fileList.forEach((item, index) => {
      file = file + item.xhr.responseURL.split("?")[0];
      if (index + 1 < values.file.fileList.length) {
        file = file + ",";
      }
    });
    createReward({
      variables: {
        file: file,
        comment: values.note,
        survey: parseInt(surveyID),
        survey_x_member: id,
        status: role === "super_admin" ? "Approved" : "Rewarded",
      },
    }).then((res) => {
      if (res.data.insert_rewards.affected_rows > 0) {
        notification.success({
          message: "Reward uploaded",
        });
        setId(null);
        form.resetFields();
        setVisible(false);
      } else {
        notification.error({
          message: "An error occurred",
        });
      }
    });
  };

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col
              span={12}
              className="px-6 text-xl font-semibold"
              style={{ color: "#4F4F4F" }}
            >
              Survey completed members -{" "}
              {data?.survey[0]?.survey_x_members_aggregate?.aggregate?.count ||
                0}
            </Col>
            <div className=" float-right mb-5">
              <ColumnEditor columns={columnStatus} onSave={(column) => {
                setStatus(column)
              }} />
            </div>
            {/* <Col span={12}>
              <Button
                size="large"
                className="rounded float-right"
                style={{ color: "#E08036", borderColor: "#E08036" }}
              >
                <DownloadOutlined style={{ fontSize: "1.5rem" }} />
              </Button>
            </Col> */}
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <MemberTable
            columns={memColumns}
            tableData={tableData}
            pagination={false}
          />
        </Col>
      </Row>
      <Drawer
        title="Rewards"
        footer={footer}
        width={600}
        visible={visible}
        onClose={() => {
          setVisible(false);
          form.resetFields();
          setId(null);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          colon={false}
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item label="Disbursal note" name="note">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item label="File" name="file">
            <Upload
              name="file"
              multiple={true}
              method="PUT"
              action={(file) => {
                return new Promise((resolve) => {
                  uploadURL({
                    variables: {
                      fileName: file.name,
                      contentType: file.type,
                    },
                  }).then((res) => {
                    if (res.data.get_upload_url.message === "Success") {
                      resolve(res.data.get_upload_url.url);
                    }
                  });
                });
              }}
              onChange={(info) => {
                const { status } = info.file;

                if (status === "done") {
                  message.success(
                    `${info.file.name} file uploaded successfully.`
                  );
                } else if (status === "error") {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
            >
              <Button className="rounded">Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title="Rewards"
        footer={detailFooter}
        width={600}
        visible={detailsVisible}
        onClose={() => {
          setDetailsVisible(false);
          setId(null);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          colon={false}
        >
          <Form.Item label="Disbursal note">{dataObject?.note}</Form.Item>
          <Form.Item label="File">
            {dataObject?.file?.split(",")?.map((file) => {
              return (
                <Row
                  span={24}
                  align="middle"
                  justify="space-between"
                  className="mb-3"
                >
                  <Col>{file.split("/")[file.split("/").length - 1]}</Col>
                  <Col>
                    <Button
                      size="large"
                      className="rounded float-right"
                      style={{ color: "#E08036", borderColor: "#E08036" }}
                      onClick={() => handleDownload(file)}
                    >
                      <DownloadOutlined style={{ fontSize: "1.5rem" }} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default RewardsTab;
