import { gql } from "graphql-tag";

export const GET_PROJECT_INFO = gql`
  query GET_PROJECT_INFO($id: Int!, $survey_id: Int) {
    project_by_pk(id: $id) {
      name
      survey_type
      client {
        id
        f_name
        l_name
        member_type {
          name
          id
        }
      }
      projectTypeByProjectType {
        name
      }
      sample
      start_date
      end_date
      description
      total_budget
      currency
      is_online_or_assisted
      surveys {
        id
        start_date
        end_date
        description
        consent
        name
        status
        questions {
          answers {
            answer
            file_url
          }
        }
        survey_x_members {
          user {
            email
            phone_number
            f_name
            l_name
            id
            primary_specialties {
              speciality_name
            }
          }
          survey_x_member_x_interviewers {
            status
            interviewer_id
            appointment_date
          }
        }
        completed: survey_x_members_aggregate(
          where: {
            _and: {
              _and: {
                state: { _in: ["completed", "approved", "Approved"] }
                survey_id: { _eq: $survey_id }
              }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        screened: survey_x_members_aggregate(
          where: {
            _and: { state: { _eq: "screened" }, survey_id: { _eq: $survey_id } }
          }
        ) {
          aggregate {
            count
          }
        }
        quotaFull: survey_x_members_aggregate(
          where: {
            _and: {
              state: { _eq: "quotaFull" }
              survey_id: { _eq: $survey_id }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
      member_type {
        id
        name
      }
    }
  }
`;
