import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Row,
  Button,
  Switch,
  Menu,
  Col,
  Dropdown,
  Spin,
  Table,
  Card,
  Tabs,
  Modal,
  Form,
  Upload,
  notification
} from "antd";
import {
  DownOutlined,
  PlusOutlined,
  MoreOutlined,
  FilterOutlined,
  SearchOutlined,
  DownloadOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { GET_USERS, DEACTIVATE_ACTIVATE_USERS, CREATE_USERS_BULK, GET_EMAIL_PHONE, IMPORT_EMAIL_TRIGGER, GET_ONE_USER, REJECTION_DATA, ONBOARD_USER_RES_ID } from "./graphql";
import { useQuery, useMutation } from "@apollo/client";
import { getColumnSearchProps } from "../../utils/table";
import { useHistory, useLocation } from "react-router";
import moment from "moment";
import jwtDecode from "jwt-decode";
import ColumnEditor from "../../components/ColumnEditor";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import { useCallback } from "react";
import SpecialityModalPop from "../../utils/specialitySearch";
import MemberStatusCount from "./memberStatusCount";
import MemberTable from "../../components/memberTable";
import { downloadCSV, validateCSV } from "../../utils/processCSV";
import { consumer, patient, physician, technician } from "../../constants/csvData";
import { StatusMismatchModal, WarningMismatchPopup, consumerRowData, executeBulkInsertUserFunction, handleCheckboxChangeCommon, handleExecution, handleProceedCommon, patientRowData, physicianRowData, processStatusMismatch, rejectionProceedCommon, technicianRowData } from "./bulkimport";
import { ADD_APPROVAL, GET_COMPATIBILITY, GET_SPECIALITIES } from "./Physician/graphql";
import { GET_AILMENTS } from "./Patients/graphql";
import { GET_TECHPROFESSION } from "./Technician/graphql";

const { TabPane } = Tabs;
const { Dragger } = Upload;
const AMembersList = () => {
  const history = useHistory();
  const location = useLocation();
  const [showInactive, setShowInactive] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [tableData, setTableData] = useState([]);
  const [role, setRole] = useState(null);
  const tabPane = ["physician", "technician", "patient", "consumer"];
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState("");
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredValue, setFilteredValue] = useState(null);
  const [id, setId] = useState(null);
  const [current, setCurrent] = useState(
    parseInt(location.pathname.split("/")[3])
  );
  const [additionalColumnList, setAdditionalColumnList] = useState({
    physician: {},
    technician: {},
    patient: {},
    consumer: {},
  });
  const savedmembers = localStorage.getItem('members');
  const membersInitialColumnStatus = savedmembers
    ? JSON.parse(savedmembers)
    : {
      physician: {
        respondent_id: { enabled: true, label: "Respondent ID" },
        status: { enabled: true, label: "Status" },
        name: { enabled: true, label: "Name" },
        title: { enabled: true, label: "Title" },
        email: { enabled: true, label: "Primary Email" },
        phone_number: { enabled: true, label: "Phone Number" },
        hospital: { enabled: true, label: "Hospital" },
        speciality: { enabled: true, label: "Primary speciality" },
        year_of_birth: { enabled: false, label: "Year of birth" },
        mobile1: { enabled: false, label: "Mobile Number1" },
        mobile2: { enabled: false, label: "Mobile Number2" },
        secondaryEmail: { enabled: false, label: "Secondary Email" },
        genderNew: { enabled: false, label: "Gender" },
        state: { enabled: false, label: "State" },
        city: { enabled: false, label: "City" },
        zone: { enabled: false, label: "Zone" },
        cityCategory: { enabled: false, label: "City Category" },
        pincode: { enabled: false, label: "Pincode" },
        secondarySpeciality: { enabled: false, label: "Secondary speciality" },
        superSpeciality: { enabled: false, label: "Super speciality" },
        consultingHospital1: { enabled: false, label: "Consulting Hospital 1" },
        consultingHospital2: { enabled: false, label: "Consulting Hospital 2" },
        projectCompatibility: { enabled: false, label: "Project compatibility" },
        hospitalType: { enabled: false, label: "Hospital Type" },
        noOfBeds: { enabled: false, label: "No. of beds" },
        access: { enabled: false, label: "Access to mobile" },
        startedPracticing: {
          enabled: false,
          label: "Started practicing (post Residency)",
        },
        totalIncentivesProcessed: {
          enabled: false,
          label: "Total incentives processed",
        },
        previouslyParticipatedStudies: {
          enabled: false,
          label: "Previously participated studies",
        },
        modeOfPayment: { enabled: false, label: "Mode of payment" },
        validatedDate: { enabled: false, label: "Validated date" },
        experience: { enabled: false, label: "Years of experience" },
      },
      technician: {
        respondent_id: { enabled: true, label: "Respondent ID" },
        status: { enabled: true, label: "Status" },
        name: { enabled: true, label: "Name" },
        title: { enabled: true, label: "Title" },
        email: { enabled: true, label: "Primary Email" },
        year_of_birth: { enabled: true, label: "Year of birth" },
        phone_number: { enabled: true, label: "Phone Number" },
        mobile1: { enabled: true, label: "Mobile Number1" },
        mobile2: { enabled: true, label: "Mobile Number2" },
        profession: { enabled: true, label: "Profession" },
        organisation: { enabled: true, label: "Organisation" },
        secondaryEmail: { enabled: false, label: "Secondary Email" },
        genderNew: { enabled: false, label: "Gender" },
        state: { enabled: false, label: "State" },
        city: { enabled: false, label: "City" },
        zone: { enabled: false, label: "Zone" },
        cityCategory: { enabled: false, label: "City Category" },
        pincode: { enabled: false, label: "Pincode" },
        experience: { enabled: false, label: "Years of experience" },
        sub: { enabled: false, label: "Sub-category" },
        sub2: { enabled: false, label: "Sub-category 2" },
        hospitalType: { enabled: false, label: "Hospital Type" },
        noOfBeds: { enabled: false, label: "No. of beds" },
        startedPracticing: {
          enabled: false,
          label: "Started practicing (post Residency)",
        },
        totalIncentivesProcessed: {
          enabled: false,
          label: "Total incentives processed",
        },
        previouslyParticipatedStudies: {
          enabled: false,
          label: "Previously participated studies",
        },
        modeOfPayment: { enabled: false, label: "Mode of payment" },
        validatedDate: { enabled: false, label: "Validated date" },
        access: { enabled: false, label: "Access to mobile" },
      },
      patient: {
        respondent_id: { enabled: true, label: "Respondent ID" },
        status: { enabled: true, label: "Status" },
        name: { enabled: true, label: "Name" },
        title: { enabled: true, label: "Title" },
        email: { enabled: true, label: "Primary Email" },
        phone_number: { enabled: true, label: "Phone Number" },
        genderNew: { enabled: false, label: "Gender" },
        year_of_birth: { enabled: false, label: "Year of birth" },
        secondaryEmail: { enabled: false, label: "Secondary Email" },
        mobile1: { enabled: true, label: "Mobile Number1" },
        mobile2: { enabled: true, label: "Mobile Number2" },
        state: { enabled: false, label: "State" },
        city: { enabled: false, label: "City" },
        zone: { enabled: false, label: "Zone" },
        cityCategory: { enabled: false, label: "City Category" },
        pincode: { enabled: false, label: "Pincode" },
        hospital: { enabled: false, label: "Hospital" },
        hospitalType: { enabled: false, label: "Hospital Type" },
        noOfBeds: { enabled: false, label: "No. of beds" },
        ailments: { enabled: false, label: "Ailment list" },
        startedPracticing: {
          enabled: false,
          label: "Started practicing (post Residency)",
        },
        totalIncentivesProcessed: {
          enabled: false,
          label: "Total incentives processed",
        },
        previouslyParticipatedStudies: {
          enabled: false,
          label: "Previously participated studies",
        },
        modeOfPayment: { enabled: false, label: "Mode of payment" },
        validatedDate: { enabled: false, label: "Validated date" },
        access: { enabled: false, label: "Access to mobile" },
      },
      consumer: {
        respondent_id: { enabled: true, label: "Respondent ID" },
        status: { enabled: true, label: "Status" },
        name: { enabled: true, label: "Name" },
        title: { enabled: true, label: "Title" },
        email: { enabled: true, label: "Primary Email" },
        phone_number: { enabled: true, label: "Phone Number" },
        genderNew: { enabled: true, label: "Gender" },
        year_of_birth: { enabled: true, label: "Year of birth" },
        secondaryEmail: { enabled: false, label: "Secondary Email" },
        mobile1: { enabled: true, label: "Mobile Number1" },
        mobile2: { enabled: true, label: "Mobile Number2" },
        state: { enabled: false, label: "State" },
        city: { enabled: false, label: "City" },
        zone: { enabled: false, label: "Zone" },
        cityCategory: { enabled: false, label: "City Category" },
        pincode: { enabled: false, label: "Pincode" },
        noOfBeds: { enabled: false, label: "No. of beds" },
        hospital: { enabled: true, label: "Hospital" },
        hospitalType: { enabled: false, label: "Hospital Type" },
        startedPracticing: {
          enabled: false,
          label: "Started practicing (post Residency)",
        },
        totalIncentivesProcessed: {
          enabled: false,
          label: "Total incentives processed",
        },
        previouslyParticipatedStudies: {
          enabled: false,
          label: "Previously participated studies",
        },
        modeOfPayment: { enabled: false, label: "Mode of payment" },
        validatedDate: { enabled: false, label: "Validated date" },
        experience: { enabled: false, label: "Years of experience" },
        access: { enabled: false, label: "Access to mobile" },
      },
    }
  const [columnStatus, setStatus] = useState(membersInitialColumnStatus);
  const [statusVisible, setStatusVisible] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [trackStatus, setTrackStatus] = useState([]);
  const [errorTrackStatus, setErrorTrackStatus] = useState([]);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [rejectionData, setRejectionData] = useState();
  const [trackTempData, setTrackTempData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [statusCorrectedValue, setStatusCorrectedValue] = useState([]);
  const [ImportEmailTrigger] = useMutation(IMPORT_EMAIL_TRIGGER);
  const [compatibilities, setCompatibilities] = useState([]);
  const [specialityData, setSpecialityData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    localStorage.setItem('members', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      let decodedId = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["X-Hasura-User-Id"];
      setId(decodedId);
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const { data, loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });
  const { dataOneUser } = useQuery(GET_ONE_USER, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  const managerName = dataOneUser?.user_by_pk?.f_name + " " + dataOneUser?.user_by_pk?.l_name;

  const [bulkInsertUser] = useMutation(CREATE_USERS_BULK);
  const [rejectionDataList] = useMutation(REJECTION_DATA);
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [respondentDB, setRespondentDB] = useState([]);
  const [specialityValues, setSpecialityValues] = useState({});
  const [superSpecialityValues, setSuperSpecialityValues] = useState({});
  const [superSpecialityData, setSuperSpecialityData] = useState({});
  const [ailmentList, setAilmentList] = useState([]);
  const [statusmismatch, setStatusmismatch] = useState();
  const [uncheckedStatus, setUncheckedStatus] = useState();
  const [othersValue, setOthersValue] = useState({
    primary: -1,
    superSpeciality: -1,
    secondary: -1
  })
  const {
    data: emailPhone,
    loading: emailPhoneLoading,
    refetch: refetchemailPhone,
  } = useQuery(GET_EMAIL_PHONE);
  useQuery(GET_COMPATIBILITY, {
    fetchPolicy: "network-only",
    onCompleted: (resp) => {
      if (resp.project_compatibility) {
        let compatibilityArr = [];
        resp.project_compatibility.map((compatibility) => {
          compatibilityArr.push(compatibility.project_compatibility_text);
        });
        setCompatibilities(compatibilityArr);
      }
    },
  });

  useQuery(GET_SPECIALITIES, {
    fetchPolicy: "network-only",
    onCompleted: (resp) => {
      if (resp.specialties) {
        let specialityObj = {};
        let specialityArr = [];
        let superSpecialityObj = {};
        resp.specialties.map((speciality) => {
          specialityArr.push(speciality.speciality_name);
          specialityObj[speciality.id] = {
            name: speciality.speciality_name,
          };
          if (speciality.speciality_name === 'Others') {
            setOthersValue({
              ...othersValue,
              primary: speciality.id,
              secondary: speciality.id
            });
          }
          speciality.super_specialties.map((superSpeciality) => {
            if (superSpecialityObj[speciality.speciality_name]) {
              superSpecialityObj[speciality.speciality_name] = [...superSpecialityObj[speciality.speciality_name], superSpeciality.name];
            } else {
              superSpecialityObj[speciality.speciality_name] = [superSpeciality.name];
            }
          });
        });
        setSuperSpecialityValues(superSpecialityObj);
        setSpecialityData(specialityArr);
        setSpecialityValues(specialityObj);
      }
    },
  });

  useQuery(GET_AILMENTS, {
    fetchPolicy: "network-only",
    onCompleted: (resp) => {
      if (resp.ailments) {
        let ailmentArr = [];
        resp.ailments.map((ailment) => {
          ailmentArr.push(ailment.ailment);
        });
        setAilmentList(ailmentArr);
      }
    },
  });

  useEffect(() => {
    if (emailPhone) {
      let emailCopy = [],
        respondentCopy = [],
        phoneCopy = [];
      emailPhone?.user.forEach((user) => {
        emailCopy.push(user.email);
        emailCopy.push(user.meta_date?.email2);
        phoneCopy.push(user.phone_number);
        phoneCopy.push(user.meta_date?.mobile2);
        phoneCopy.push(user.meta_date?.phone);
        if (user.meta_date?.respondent)
          respondentCopy.push(user.meta_date?.respondent);
      });
      setEmail(emailCopy);
      setPhone(phoneCopy);
      setRespondentDB(respondentCopy);
    }
  }, [emailPhone]);

  useEffect(() => {
    refetchemailPhone();
  }, []);

  const [professionData, setProfessionData] = useState();
  useQuery(GET_TECHPROFESSION, {
    onCompleted: (resp) => {
      if (resp.tech_profession) {
        const profession = resp.tech_profession.map((profession) => profession);
        setProfessionData(profession);
      }
    },
  });

  let successRecords = [];
  const errorRecords1 = [];
  const tempErrorRecords = [];
  const handleShowWarnings = (errors) => {
    setErrorRecords(errors);
    // setStatusVisible(true);
  };

  const rejectionModalClose = () => {
    setRejectionModal(false);
  }

  useEffect(() => {
    if (location.pathname.split("/")[2] === "physician") {
      setActiveTab("1");
    } else if (location.pathname.split("/")[2] === "technician") {
      setActiveTab("2");
    } else if (location.pathname.split("/")[2] === "patient") {
      setActiveTab("3");
    } else {
      setActiveTab("4");
    }
  }, [location.pathname]);

  const [deactivateUsers] = useMutation(DEACTIVATE_ACTIVATE_USERS);

  useEffect(() => {
    if (data) {
      let copy = JSON.parse(JSON.stringify(data));
      let ColList = { ...additionalColumnList };
      let colStatus = { ...columnStatus };
      copy.user.forEach((user) => {
        user.name =
          user?.l_name !== null
            ? user?.f_name + " " + user?.l_name
            : user?.f_name;
        user.roleName = user?.role?.description;
        user.speciality =
          user?.primary_specialties?.speciality_name === undefined
            ? "Not updated"
            : user?.primary_specialties?.speciality_name;
        user.phone_number =
          user.phone_number === "" ? "Not updated" : user.phone_number;
        user.dncCount = user?.dnc_count;
        user.genderNew = user?.gender !== null ? user?.gender : "Not updated";
      });
      if (activeTab === "1") {
        copy = copy.user.filter((item) => item.member_type_id === 1);
        copy.forEach((user) => {
          (
            user?.additional_data ||
            user?.meta_date?.additional_fields ||
            []
          ).map((user_additional_data) => {
            colStatus["physician"][user_additional_data.label] = {
              enabled: false,
              label: user_additional_data.label,
            };

            ColList["physician"][user_additional_data.label] = true;
            user[user_additional_data.label] = user_additional_data.info;
          });
          let phone_number = user.phone_number;
          user.name =
            user?.l_name !== null
              ? user?.f_name + " " + user?.l_name
              : user?.f_name;
          user.roleName = user?.role?.description;
          user.speciality =
            user?.primary_specialties?.speciality_name === undefined
              ? "Not updated"
              : user?.primary_specialties?.speciality_name;
          user.phone_number =
            user.phone_number === "" ? "Not updated" : user.phone_number;

          user.profession = user?.meta_date?.profession;
          user.sub = user?.meta_date?.sub;
          user.sub2 = user?.meta_date?.sub2;
          user.organisation = user?.meta_date?.organisation;
          user.secondaryEmail = user.meta_date?.email2;

          user.year_of_birth = user?.year_of_birth
            ? moment(user?.year_of_birth).format("yyyy")
            : "Not Updated";
          user.speciality =
            user?.primary_specialties?.speciality_name === undefined
              ? "Not updated"
              : user?.primary_specialties?.speciality_name;
          user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

          user.mobile2 =
            user.meta_date?.mobile2 === ""
              ? "Not updated"
              : user.meta_date?.mobile2;
          user.phone_number =
            user?.meta_date?.phone === ""
              ? "Not updated"
              : user?.meta_date?.phone;
          user.respondent_id =
            user?.respondent_id === "" ? "Not updated" : user?.respondent_id;
          user.secondarySpeciality =
            user?.secondary_specialties?.speciality_name === ""
              ? "Not updated"
              : user?.secondary_specialties?.speciality_name;
          user.superSpeciality =
            user?.super_specialty?.name === ""
              ? "Not updated"
              : user?.super_specialty?.name;
          user.noOfBeds = user?.meta_date?.noOfBeds;
          user.zone = user?.meta_date?.zone;
          user.cityCategory = user?.meta_date?.cityCategory;
          user.hospitalType = user?.meta_date?.hospitalType;
          user.consultingHospital1 = user?.meta_date?.hospital1;
          user.consultingHospital2 = user?.meta_date?.hospital2;
          user.projectCompatibility =
            user?.project_compatibilitys?.length > 0
              ? user?.project_compatibilitys?.join(",")
              : "Not updated";
          user.startedPracticing = user?.meta_date?.yearOfPractice
            ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
            : "Not updated";
          user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
            ? user?.meta_date?.totalIncentive
            : "Not updated";
          user.previouslyParticipatedStudies = user?.meta_date?.previousStudies
            ? user?.meta_date?.previousStudies
            : "Not updated";
          user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
            ? user?.meta_date?.payment?.length
              ? user?.meta_date?.payment
                ?.map((info) => {
                  return info.label;
                })
                .join(",")
              : "Not Updated"
            : user?.meta_date?.payment || "Not Updated";
          // user.modeOfPayment = Array.isArray(user?.meta_date?.payment) ? user?.meta_date?.payment?.length > 0 ? (user?.meta_date?.payment || [])?.join(",") : "Not updated" : user?.meta_date?.payment || "Not Updated";
          user.validatedDate = user?.meta_date?.validatedDate
            ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
            : "Not updated";
          user.status = user?.status ? user?.status : "Not updated";
          user.access = user?.meta_date?.access ? "Yes" : "No";
          user.experience =
            moment().format("yyyy") -
            moment(user?.meta_date?.yearOfPractice).format("yyyy");
        });

        setTableData(copy);
        setStatus(colStatus);
        setAdditionalColumnList(ColList);
      } else if (activeTab === "2") {
        copy = copy.user.filter((item) => item.member_type_id === 2);
        copy.forEach((user) => {
          let phone_number = user.phone_number;
          (
            user?.additional_data ||
            user?.meta_date?.additional_fields ||
            []
          ).map((user_additional_data) => {
            ColList["technician"][user_additional_data.label] = true;
            colStatus["technician"][user_additional_data.label] = {
              enabled: false,
              label: user_additional_data.label,
            };

            user[user_additional_data.label] = user_additional_data.info;
          });
          user.name =
            user?.l_name !== null
              ? user?.f_name + " " + user?.l_name
              : user?.f_name;
          user.genderNew = user?.gender !== null ? user?.gender : "Not updated";
          user.respondent_id = user?.respondent_id || "Not updated";
          user.roleName = user?.role?.description;
          user.profession = user?.meta_date?.profession || "Not updated";
          user.sub = user?.meta_date?.sub || "Not updated";
          user.sub2 = user?.meta_date?.sub2 || "Not updated";
          user.organisation = user?.meta_date?.organisation || "Not updated";
          user.experience = user?.meta_date?.experience || "Not updated";
          user.title = user.title === "" ? "Not updated" : user.title;
          user.secondaryEmail = user.meta_date?.email2 || "Not updated";
          user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
          user.phone_number = user.meta_date?.phone || "Not updated";

          user.mobile2 = user.meta_date?.mobile2 || "Not updated";
          //user.subCategory = user?.meta_date?.sub || "Not updated";
          user.noOfBeds = user?.meta_date?.noOfBeds;
          user.hospitalType = user?.meta_date?.hospitalType || "Not updated";
          user.zone = user?.meta_date?.zone || "Not updated";
          user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
          user.year_of_birth = user?.year_of_birth
            ? moment(user?.year_of_birth).format("yyyy")
            : "Not Updated";
          user.startedPracticing = user?.meta_date?.yearOfPractice
            ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
            : "Not updated";
          user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
            ? user?.meta_date?.totalIncentive
            : "Not updated";
          user.previouslyParticipatedStudies = user?.meta_date?.previousStudies
            ? user?.meta_date?.previousStudies
            : "Not updated";
          user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
            ? user?.meta_date?.payment?.length
              ? user?.meta_date?.payment
                ?.map((info) => {
                  return info.label;
                })
                .join(",")
              : "Not Updated"
            : user?.meta_date?.payment || "Not Updated";
          user.validatedDate = user?.meta_date?.validatedDate
            ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
            : "Not updated";
          user.status = user?.status ? user?.status : "Not updated";
          user.access = user?.meta_date?.access ? "Yes" : "No";
          user.experience =
            moment().format("yyyy") -
            moment(user?.meta_date?.yearOfPractice).format("yyyy");
        });
        setTableData(copy);
        setStatus(colStatus);
        setAdditionalColumnList(ColList);
      } else if (activeTab === "3") {
        copy = copy.user.filter((item) => item.member_type_id === 3);
        copy.forEach((user) => {
          let phone_number = user.phone_number;
          (
            user?.additional_data ||
            user?.meta_date?.additional_fields ||
            []
          ).map((user_additional_data) => {
            ColList["patient"][user_additional_data.label] = true;
            colStatus["patient"][user_additional_data.label] = {
              enabled: false,
              label: user_additional_data.label,
            };

            user[user_additional_data.label] = user_additional_data.info;
          });

          user.name =
            user?.l_name !== null
              ? user?.f_name + " " + user?.l_name
              : user?.f_name;
          user.roleName = user?.role?.description;
          user.genderNew = user?.gender !== null ? user?.gender : "Not updated";
          user.year_of_birth = user?.year_of_birth
            ? moment(user?.year_of_birth).format("yyyy")
            : "Not Updated";
          //user.sub = user?.meta_date?.sub;

          user.title = user.title === "" ? "Not updated" : user.title;
          user.secondaryEmail = user.meta_date?.email2
            ? user.meta_date?.email2
            : "Not updated";
          //user.subCategory = user?.meta_date?.sub;
          user.respondent_id = user?.respondent_id || "Not updated";
          user.noOfBeds = user?.meta_date?.noOfBeds || "Not updated";
          user.hospital = user?.meta_date?.hospital || "Not updated";
          user.hospitalType = user?.meta_date?.hospitalType || "Not updated";
          user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
          user.phone_number = user.meta_date?.phone || "Not updated";
          user.mobile2 = user.meta_date?.mobile2 || "Not updated";
          //user.organisation = user?.meta_date?.organisation;
          user.zone = user?.meta_date?.zone || "Not updated";
          user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
          user.ailments =
            user?.meta_date?.ailments?.length > 0
              ? user?.meta_date?.ailments.join()
              : "Not Furnished";
          user.startedPracticing = user?.meta_date?.yearOfPractice
            ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
            : "Not updated";
          user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
            ? user?.meta_date?.totalIncentive
            : "Not updated";
          user.previouslyParticipatedStudies = user?.meta_date?.previousStudies
            ? user?.meta_date?.previousStudies
            : "Not updated";
          user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
            ? user?.meta_date?.payment?.length
              ? user?.meta_date?.payment
                ?.map((info) => {
                  return info.label;
                })
                .join(",")
              : "Not Updated"
            : user?.meta_date?.payment || "Not Updated";
          user.validatedDate = user?.meta_date?.validatedDate
            ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
            : "Not updated";
          user.status = user?.status ? user?.status : "Not updated";
          user.access = user?.meta_date?.access ? "Yes" : "No";
        });
        setTableData(copy);
        setStatus(colStatus);
        setAdditionalColumnList(ColList);
      } else if (activeTab === "4") {
        copy = copy.user.filter((item) => item.member_type_id === 4);
        copy.forEach((user) => {
          let phone_number = user.phone_number;
          (
            user?.additional_data ||
            user?.meta_date?.additional_fields ||
            []
          ).map((user_additional_data) => {
            ColList["consumer"][user_additional_data.label] = true;
            colStatus["consumer"][user_additional_data.label] = {
              enabled: false,
              label: user_additional_data.label,
            };

            user[user_additional_data.label] = user_additional_data.info;
          });
          user.name =
            user?.l_name !== null
              ? user?.f_name + " " + user?.l_name
              : user?.f_name;
          user.roleName = user?.role?.description;
          user.genderNew = user?.gender !== null ? user?.gender : "Not updated";
          user.year_of_birth = user?.year_of_birth
            ? moment(user?.year_of_birth).format("yyyy")
            : "Not Updated";
          user.respondent_id = user?.respondent_id || "Not updated";
          user.sub = user?.meta_date?.sub;
          user.sub2 = user?.meta_date?.sub2;
          user.title = user.title === "" ? "Not updated" : user.title;
          user.secondaryEmail = user.meta_date?.email2 || "Not updated";
          user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
          user.phone_number = user.meta_date?.phone || "Not updated";

          user.mobile2 = user.meta_date?.mobile2 || "Not updated";
          user.subCategory = user?.meta_date?.sub;
          user.noOfBeds = user?.meta_date?.noOfBeds;
          user.zone = user?.meta_date?.zone || "Not updated";
          user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
          user.startedPracticing = user?.meta_date?.yearOfPractice
            ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
            : "Not updated";
          user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
            ? user?.meta_date?.totalIncentive
            : "Not updated";
          user.previouslyParticipatedStudies = user?.meta_date?.previousStudies
            ? user?.meta_date?.previousStudies
            : "Not updated";
          user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
            ? user?.meta_date?.payment?.length
              ? user?.meta_date?.payment
                ?.map((info) => {
                  return info.label;
                })
                .join(",")
              : "Not Updated"
            : user?.meta_date?.payment || "Not Updated";
          user.validatedDate = user?.meta_date?.validatedDate
            ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
            : "Not updated";
          user.status = user?.status ? user?.status : "Not updated";
          user.access = user?.meta_date?.access ? "Yes" : "No";
        });
        setTableData(copy);
        setStatus(colStatus);
        setAdditionalColumnList(ColList);
      }

      history.push(`${location.pathname}`);
    }
    // eslint-disable-next-line
  }, [data, activeTab]);

  useEffect(() => {
    if (showInactive) {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        let ColList = { ...additionalColumnList };
        let colStatus = { ...columnStatus };

        copy.user.forEach((user) => {
          user.name =
            user?.l_name !== null
              ? user?.f_name + " " + user?.l_name
              : user?.f_name;
          user.roleName = user?.role?.description;
          user.speciality =
            user?.primary_specialties?.speciality_name === undefined
              ? "Not updated"
              : user?.primary_specialties?.speciality_name;
          user.phone_number =
            user.phone_number === "" ? "Not updated" : user.phone_number;
          user.dncCount = user?.dnc_count;
        });
        if (activeTab === "1") {
          copy = copy.user.filter((item) => item.member_type_id === 1);

          copy.forEach((user) => {
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              colStatus["physician"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };
              ColList["physician"][user_additional_data.label] = true;
              user[user_additional_data.label] = user_additional_data.info;
            });
            let phone_number = user.phone_number;
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.speciality =
              user?.primary_specialties?.speciality_name === undefined
                ? "Not updated"
                : user?.primary_specialties?.speciality_name;
            user.phone_number =
              user.phone_number === "" ? "Not updated" : user.phone_number;

            user.profession = user?.meta_date?.profession;
            user.sub = user?.meta_date?.sub;
            user.sub2 = user?.meta_date?.sub2;
            user.organisation = user?.meta_date?.organisation;
            user.secondaryEmail = user.meta_date?.email2;

            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.speciality =
              user?.primary_specialties?.speciality_name === undefined
                ? "Not updated"
                : user?.primary_specialties?.speciality_name;
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

            user.mobile2 =
              user.meta_date?.mobile2 === ""
                ? "Not updated"
                : user.meta_date?.mobile2;
            user.phone_number =
              user?.meta_date?.phone === ""
                ? "Not updated"
                : user?.meta_date?.phone;
            user.respondent_id =
              user?.respondent_id === "" ? "Not updated" : user?.respondent_id;
            user.secondarySpeciality =
              user?.secondary_specialties?.speciality_name === ""
                ? "Not updated"
                : user?.secondary_specialties?.speciality_name;
            user.superSpeciality =
              user?.super_specialty?.name === ""
                ? "Not updated"
                : user?.super_specialty?.name;
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.zone = user?.meta_date?.zone;
            user.cityCategory = user?.meta_date?.cityCategory;
            user.hospitalType = user?.meta_date?.hospitalType || "Not Updated";
            user.consultingHospital1 = user?.meta_date?.hospital1;
            user.consultingHospital2 = user?.meta_date?.hospital2;
            user.projectCompatibility =
              user?.project_compatibilitys?.length > 0
                ? user?.project_compatibilitys?.join(",")
                : "Not updated";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            // user.modeOfPayment = Array.isArray(user?.meta_date?.payment) ? user?.meta_date?.payment?.length > 0 ? (user?.meta_date?.payment || [])?.join(",") : "Not updated" : user?.meta_date?.payment || "Not updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "2") {
          copy = copy.user.filter((item) => item.member_type_id === 2);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["technician"][user_additional_data.label] = true;
              colStatus["technician"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.respondent_id = user?.respondent_id || "Not updated";
            user.roleName = user?.role?.description;
            user.profession = user?.meta_date?.profession || "Not updated";
            user.sub = user?.meta_date?.sub || "Not updated";
            user.sub2 = user?.meta_date?.sub2 || "Not updated";
            user.organisation = user?.meta_date?.organisation || "Not updated";
            user.title = user.title === "" ? "Not updated" : user.title;
            user.secondaryEmail = user.meta_date?.email2 || "Not updated";
            user.experience = user?.meta_date?.experience || "Not updated";
            //user.subCategory = user?.meta_date?.sub || "Not updated";
            user.organisation = user?.meta_date?.organisation;
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.hospitalType = user?.meta_date?.hospitalType || "Not updated";
            user.zone = user?.meta_date?.zone || "Not updated";
            user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

            user.mobile2 = user.meta_date?.mobile2 || "Not updated";
            user.phone_number = user?.meta_date?.phone || "Not updated";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
            user.experience =
              moment().format("yyyy") -
              moment(user?.meta_date?.yearOfPractice).format("yyyy");
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "3") {
          copy = copy.user.filter((item) => item.member_type_id === 3);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["patient"][user_additional_data.label] = true;
              colStatus["patient"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.genderNew =
              user?.gender !== null ? user?.gender : "Not updated";
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.sub = user?.meta_date?.sub;
            user.sub2 = user?.meta_date?.sub2;
            user.respondent_id = user?.respondent_id || "Not updated";
            user.title = user.title === "" ? "Not updated" : user.title;
            user.secondaryEmail = user.meta_date?.email2 || "Not updated";
            //user.subCategory = user?.meta_date?.sub;
            user.noOfBeds = user?.meta_date?.noOfBeds || "Not Updated";
            user.hospital = user?.meta_date?.hospital || "Not Updated";
            user.hospitalType = user?.meta_date?.hospitalType || "Not Updated";
            //user.organisation = user?.meta_date?.organisation;
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

            user.phone_number = user.meta_date?.phone || "Not updated";
            user.mobile2 = user.meta_date?.mobile2 || "Not updated";
            user.zone = user?.meta_date?.zone || "Not updated";
            user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
            user.ailments =
              user?.meta_date?.ailments?.length > 0
                ? user?.meta_date?.ailments.join()
                : "Not Furnished";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "4") {
          copy = copy.user.filter((item) => item.member_type_id === 4);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["consumer"][user_additional_data.label] = true;
              colStatus["consumer"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.respondent_id = user?.respondent_id || "Not updated";
            user.roleName = user?.role?.description;
            user.genderNew =
              user?.gender !== null ? user?.gender : "Not updated";
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

            user.mobile2 = user.meta_date?.mobile2 || "Not updated";
            user.phone_number = user?.meta_date?.phone || "Not updated";
            user.sub = user?.meta_date?.sub;
            user.sub2 = user?.meta_date?.sub2;
            user.title = user.title === "" ? "Not updated" : user.title;
            user.secondaryEmail = user.meta_date?.email2 || "Not updated";
            user.subCategory = user?.meta_date?.sub;
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.zone = user?.meta_date?.zone || "Not updated";
            user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
            user.organisation = user?.meta_date?.organisation;
            user.hospitalType = user?.meta_date?.hospitalType;
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        }
        history.push(`/members/${tabPane[parseInt(activeTab) - 1]}/1`);
      }
    } else {
      if (data) {
        let copy = JSON.parse(JSON.stringify(data));
        let tableCopy = [];
        let ColList = { ...additionalColumnList };
        let colStatus = { ...columnStatus };
        copy.user.forEach((user) => {
          if (user.is_active) {
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.speciality =
              user?.primary_specialties?.speciality_name === undefined
                ? "Not updated"
                : user?.primary_specialties?.speciality_name;
            user.phone_number =
              user.phone_number === "" ? "Not updated" : user.phone_number;
            tableCopy.push(user);
          }
        });
        if (activeTab === "1") {
          copy = tableCopy.filter((item) => item.member_type_id === 1);
          copy.forEach((user) => {
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              colStatus["physician"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };
              ColList["physician"][user_additional_data.label] = true;
              user[user_additional_data.label] = user_additional_data.info;
            });
            let phone_number = user.phone_number;
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.speciality =
              user?.primary_specialties?.speciality_name === undefined
                ? "Not updated"
                : user?.primary_specialties?.speciality_name;
            user.phone_number =
              user.phone_number === "" ? "Not updated" : user.phone_number;

            user.profession = user?.meta_date?.profession;
            user.sub = user?.meta_date?.sub;
            user.sub2 = user?.meta_date?.sub2;
            user.organisation = user?.meta_date?.organisation;
            user.secondaryEmail = user.meta_date?.email2;

            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.speciality =
              user?.primary_specialties?.speciality_name === undefined
                ? "Not updated"
                : user?.primary_specialties?.speciality_name;
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

            user.mobile2 =
              user.meta_date?.mobile2 === ""
                ? "Not updated"
                : user.meta_date?.mobile2;
            user.phone_number =
              user?.meta_date?.phone === ""
                ? "Not updated"
                : user?.meta_date?.phone;
            user.respondent_id =
              user?.respondent_id === "" ? "Not updated" : user?.respondent_id;
            user.secondarySpeciality =
              user?.secondary_specialties?.speciality_name === ""
                ? "Not updated"
                : user?.secondary_specialties?.speciality_name;
            user.superSpeciality =
              user?.super_specialty?.name === ""
                ? "Not updated"
                : user?.super_specialty?.name;
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.zone = user?.meta_date?.zone;
            user.cityCategory = user?.meta_date?.cityCategory;
            user.hospitalType = user?.meta_date?.hospitalType || "Not Updated";
            user.consultingHospital1 = user?.meta_date?.hospital1;
            user.consultingHospital2 = user?.meta_date?.hospital2;
            user.projectCompatibility =
              user?.project_compatibilitys?.length > 0
                ? user?.project_compatibilitys?.join(",")
                : "Not updated";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            // user.modeOfPayment = Array.isArray(user?.meta_date?.payment) ? user?.meta_date?.payment?.length > 0 ? (user?.meta_date?.payment || [])?.join(",") : "Not updated" : user?.meta_date?.payment || "Not updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "2") {
          copy = tableCopy.filter((item) => item.member_type_id === 2);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["technician"][user_additional_data.label] = true;
              colStatus["technician"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });

            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.profession = user?.meta_date?.profession || "Not updated";
            user.sub = user?.meta_date?.sub || "Not updated";
            user.sub2 = user?.meta_date?.sub2 || "Not updated";
            user.organisation = user?.meta_date?.organisation || "Not updated";
            user.respondent_id = user?.respondent_id || "Not updated";
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.mobile2 = user.meta_date?.mobile2 || "Not updated";
            user.phone_number = user?.meta_date?.phone || "Not updated";
            user.secondaryEmail = user.meta_date?.email2 || "Not updated";
            user.experience = user?.meta_date?.experience || "Not updated";
            //user.subCategory = user?.meta_date?.sub || "Not updated";
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.zone = user?.meta_date?.zone || "Not updated";
            user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
            user.hospitalType = user?.meta_date?.hospitalType || "Not updated";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
            user.experience =
              moment().format("yyyy") -
              moment(user?.meta_date?.yearOfPractice).format("yyyy");
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "3") {
          copy = tableCopy.filter((item) => item.member_type_id === 3);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["patient"][user_additional_data.label] = true;
              colStatus["patient"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.genderNew =
              user?.gender !== null ? user?.gender : "Not updated";
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            //user.sub = user?.meta_date?.sub;
            user.respondent_id = user?.respondent_id || "Not updated";
            user.title = user.title === "" ? "Not updated" : user.title;
            user.secondaryEmail = user.meta_date?.email2 || "Not updated";
            //user.subCategory = user?.meta_date?.sub;
            user.noOfBeds = user?.meta_date?.noOfBeds || "Not Updated";
            user.hospital = user?.meta_date?.hospital || "Not Updated";
            user.hospitalType = user?.meta_date?.hospitalType || "Not Updated";
            //user.organisation = user?.meta_date?.organisation;
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
            user.phone_number = user.meta_date?.phone || "Not updated";
            user.mobile2 = user.meta_date?.mobile2 || "Not updated";
            user.zone = user?.meta_date?.zone || "Not updated";
            user.cityCategory = user?.meta_date?.cityCategory || "Not updated";
            user.ailments =
              user?.meta_date?.ailments?.length > 0
                ? user?.meta_date?.ailments.join()
                : "Not Furnished";
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        } else if (activeTab === "4") {
          copy = tableCopy.filter((item) => item.member_type_id === 4);
          copy.forEach((user) => {
            let phone_number = user.phone_number;
            (
              user?.additional_data ||
              user?.meta_date?.additional_fields ||
              []
            ).map((user_additional_data) => {
              ColList["consumer"][user_additional_data.label] = true;
              colStatus["consumer"][user_additional_data.label] = {
                enabled: false,
                label: user_additional_data.label,
              };

              user[user_additional_data.label] = user_additional_data.info;
            });
            user.name =
              user?.l_name !== null
                ? user?.f_name + " " + user?.l_name
                : user?.f_name;
            user.roleName = user?.role?.description;
            user.genderNew =
              user?.gender !== null ? user?.gender : "Not updated";
            user.year_of_birth = user?.year_of_birth
              ? moment(user?.year_of_birth).format("yyyy")
              : "Not Updated";
            user.mobile1 = phone_number === "" ? "Not updated" : phone_number;
            user.respondent_id = user?.respondent_id || "Not updated";
            user.mobile2 =
              user.meta_date?.mobile2 === ""
                ? "Not updated"
                : user.meta_date?.mobile2;
            user.phone_number =
              user?.meta_date?.phone === ""
                ? "Not updated"
                : user?.meta_date?.phone;

            user.sub = user?.meta_date?.sub;
            user.sub2 = user?.meta_date?.sub2;
            user.title = user.title === "" ? "Not updated" : user.title;
            user.secondaryEmail = user.meta_date?.email2;
            user.subCategory = user?.meta_date?.sub;
            user.noOfBeds = user?.meta_date?.noOfBeds;
            user.zone = user?.meta_date?.zone;
            user.cityCategory = user?.meta_date?.cityCategory;
            user.startedPracticing = user?.meta_date?.yearOfPractice
              ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
              : "Not updated";
            user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
              ? user?.meta_date?.totalIncentive
              : "Not updated";
            user.previouslyParticipatedStudies = user?.meta_date
              ?.previousStudies
              ? user?.meta_date?.previousStudies
              : "Not updated";
            user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
              ? user?.meta_date?.payment?.length
                ? user?.meta_date?.payment
                  ?.map((info) => {
                    return info.label;
                  })
                  .join(",")
                : "Not Updated"
              : user?.meta_date?.payment || "Not Updated";
            user.validatedDate = user?.meta_date?.validatedDate
              ? moment(user?.meta_date?.validatedDate).format("DD-MM-YYYY")
              : "Not updated";
            user.status = user?.status ? user?.status : "Not updated";
            user.access = user?.meta_date?.access ? "Yes" : "No";
          });
          setTableData(copy);
          setStatus(colStatus);
          setAdditionalColumnList(ColList);
        }
        history.push(`/members/${tabPane[parseInt(activeTab) - 1]}/1`);
      }
    } //eslint-disable-next-line
  }, [showInactive]);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          history.push("/members/create/physician");
        }}
      >
        Physician
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          history.push("/members/create/technician");
        }}
      >
        Technician
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          history.push("/members/create/patient");
        }}
      >
        Patient
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() => {
          history.push("/members/create/consumer");
        }}
      >
        Consumer
      </Menu.Item>
    </Menu>
  );

  const additionalColumns = useCallback(
    (data, isShowData, type) => {
      let colList = Object.keys(data || {}).length
        ? Object.keys(data || {}).map((additionalData, additionalIndex) => {
          return {
            title: additionalData?.toUpperCase(),
            dataIndex: additionalData,
            key: additionalData,
            ...getColumnSearchProps(additionalData, additionalData),
            isShow: isShowData[additionalData]?.enabled,
            className: isShowData[additionalData]?.enabled
              ? "table-cell"
              : "hidden",
            width: 300,
            render: (text, record) => {
              return (
                <div className="flex flex-col items-start justify-start">
                  {text === "Not updated" || !text ? "Not updated" : text}
                </div>
              );
            },
          };
        })
        : [];
      // setStatus(...colStatus)
      return colList;
    },
    [columnStatus]
  );

  useEffect(() => {
    setFilteredValue(selectedStatus ? [selectedStatus] : null);
  }, [selectedStatus]);

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setFilteredValue([status]);
  };

  const handleFilterReset = () => {
    setSelectedStatus(null);
    setFilteredValue(null);
  };

  const sortStatus = (statusData, selectedStatus) => {
    if (selectedStatus === "raw") {
      return statusData.filter((data) => data.status === "raw");
    } else if (selectedStatus === "partial-email") {
      return statusData.filter((data) => data.status === "partial-email");
    } else if (selectedStatus === "partial-phone") {
      return statusData.filter((data) => data.status === "partial-phone");
    } else if (selectedStatus === "invalid") {
      return statusData.filter((data) => data.status === "invalid");
    } else if (selectedStatus === "not-validated") {
      return statusData.filter((data) => data.status === "not-validated");
    } else if (selectedStatus === "valid") {
      return statusData.filter((data) => data.status === "Valid");
    } else if (selectedStatus === "dnc") {
      return statusData.filter((data) => data.status === "DNC");
    } else if (selectedStatus === "prohibited") {
      return statusData.filter((data) => data.status === "Prohibited");
    }
    return statusData;
  };

  const physicianColumns = useMemo(
    () =>
      [
        {
          title: "Respondent ID",
          dataIndex: "respondent_id",
          key: "respondent_id",
          ...getColumnSearchProps("respondent_id", "respondent_id"),
          isShow: columnStatus?.technician?.respondent_id?.enabled,
          className: columnStatus?.technician?.respondent_id?.enabled
            ? "table-cell whitespace-nowrap"
            : "hidden whitespace-nowrap",
          width: 250,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.physician?.status?.enabled,
          className: columnStatus?.physician?.status?.enabled
            ? "table-cell"
            : "hidden",
          key: "status",
          width: 200,
          filters: [
            {
              text: "Raw",
              value: "raw",
            },
            {
              text: "Partial Email",
              value: "partial-email",
            },
            {
              text: "Partial Phone",
              value: "partial-phone",
            },
            {
              text: "Valid",
              value: "Valid",
            },
            {
              text: "Not Validated",
              value: "not-validated",
            },
            {
              text: "Invalid",
              value: "invalid",
            },
            {
              text: "DNC",
              value: "DNC",
            },
            {
              text: "Prohibited",
              value: "Prohibited",
            },
          ],
          // filteredValue: filteredValue ? [filteredValue] : undefined,
          onFilter: (value, record) => {
            const result = record.status.indexOf(value) === 0;
            // setSelectedStatus(value);
            return result;
          },
          // filterMultiple: false,
          filterIcon: () => <FilterOutlined />,
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (!visible) {
          //     setFilteredValue(null);
          //     handleFilterReset();
          //   }
          // },
        },
        {
          title: "TITLE",
          dataIndex: "title",
          key: "title",
          width: 100,
          ...getColumnSearchProps("title", "title"),
          isShow: columnStatus?.physician?.title?.enabled,
          className: columnStatus?.physician?.title?.enabled
            ? "table-cell"
            : "hidden",
        },
        {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          ...getColumnSearchProps("name", "name"),
          isShow: columnStatus?.physician?.name?.enabled,
          className: columnStatus?.physician?.name?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "PRIMARY EMAIL",
          dataIndex: "email",
          key: "email",
          width: 280,
          ellipsis: true,
          ...getColumnSearchProps("email", "email"),
          isShow: columnStatus?.physician?.email?.enabled,
          className: columnStatus?.physician?.email?.enabled
            ? "table-cell"
            : "hidden",
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "SECONDARY EMAIL",
          dataIndex: "secondaryEmail",
          key: "email2",
          width: 200,
          ...getColumnSearchProps("secondaryEmail", "secondaryEmail"),
          isShow: columnStatus?.physician?.secondaryEmail?.enabled,
          className: `${columnStatus?.physician?.secondaryEmail?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,

          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: (
            <div
              onClick={() => handleIconClick("speciality")}
              className="flex justify-between items-center"
            >
              <div>SPECIALITY</div>
              <div>
                <SearchOutlined
                  className="cursor-pointer column-custom-searchicon"
                  style={{ color: "#9CA3AF" }}
                />
              </div>
            </div>
          ),
          dataIndex: "speciality",
          key: "speciality",
          isShow: columnStatus?.physician?.speciality?.enabled,
          className: columnStatus?.physician?.speciality?.enabled
            ? "table-cell"
            : "hidden",
          width: 300,
          onHeaderCell: () => ({
            onClick: () => handleIconClick("speciality"),
          }),
        },
        {
          title: (
            <div
              onClick={() => handleIconClick("secondarySpeciality")}
              className="flex justify-between items-center"
            >
              <div>SECONDARY SPECIALITY</div>
              <div>
                <SearchOutlined
                  className="cursor-pointer column-custom-searchicon"
                  style={{ color: "#9CA3AF" }}
                />
              </div>
            </div>
          ),
          dataIndex: "secondarySpeciality",
          key: "secondarySpeciality",
          isShow: columnStatus?.physician?.secondarySpeciality?.enabled,
          className: columnStatus?.physician?.secondarySpeciality?.enabled
            ? "table-cell"
            : "hidden",
          width: 300,
          onHeaderCell: () => ({
            onClick: () => handleIconClick("secondarySpeciality"),
          }),
        },
        {
          title: "SUPER SPECIALITY",
          dataIndex: "superSpeciality",
          key: "superSpeciality",
          ...getColumnSearchProps("superSpeciality", "superSpeciality"),
          isShow: columnStatus?.physician?.superSpeciality?.enabled,
          className: columnStatus?.physician?.superSpeciality?.enabled
            ? "table-cell"
            : "hidden",
          width: 250,
        },
        {
          title: "HOSPITAL",
          dataIndex: "meta_date",
          key: "hospital",
          width: 300,
          ...getColumnSearchProps("meta_date.hospital", "hospital name"),
          isShow: columnStatus?.physician?.hospital?.enabled,
          className: columnStatus?.physician?.hospital?.enabled
            ? "table-cell"
            : "hidden",
          render: (_, record) => (
            <div className="flex flex-col items-start justify-start">
              <div className="text-h8 text-textMedium mb-1 font-semibold">
                {record?.meta_date?.hospital}
              </div>
              <div className="text-h8 text-textLight">
                {record?.meta_date?.hospitalType}
              </div>
            </div>
          ),
        },
        {
          title: "GENDER",
          dataIndex: "genderNew",
          key: "gender",
          isShow: columnStatus?.physician?.genderNew?.enabled,
          className: columnStatus?.physician?.genderNew?.enabled
            ? "table-cell"
            : "hidden",
          filters: [
            {
              text: "Male",
              value: "M",
            },
            {
              text: "Female",
              value: "F",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) => {
            return text === "M" ? (
              <>Male</>
            ) : text === "F" ? (
              <>Female</>
            ) : (
              <>Not updated</>
            );
          },
          width: 300,
        },
        {
          title: "YEAR OF BIRTH",
          dataIndex: "year_of_birth",
          key: "year",
          width: 200,
          isShow: columnStatus?.physician?.year_of_birth?.enabled,
          className: `${columnStatus?.physician?.year_of_birth?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          ...getColumnDateSearchProps("year_of_birth", "year"),
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phone_number",
          key: "phone",
          ...getColumnSearchProps("phone_number", "phone"),
          isShow: columnStatus?.physician?.phone_number?.enabled,
          className: `${columnStatus?.physician?.phone_number?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 1",
          dataIndex: "mobile1",
          key: "mobile1",
          ...getColumnSearchProps("mobile1", "phone"),
          isShow: columnStatus?.physician?.mobile1?.enabled,
          className: `${columnStatus?.physician?.mobile1?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 2",
          dataIndex: "mobile2",
          key: "mobile2",
          ...getColumnSearchProps("mobile2", "phone"),
          isShow: columnStatus?.physician?.mobile2?.enabled,
          className: `${columnStatus?.physician?.mobile2?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "STATE",
          dataIndex: "state",
          key: "state",
          ...getColumnSearchProps("state", "state"),
          isShow: columnStatus?.physician?.state?.enabled,
          className: columnStatus?.physician?.state?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY",
          dataIndex: "city",
          key: "city",
          ...getColumnSearchProps("city", "city"),
          isShow: columnStatus?.physician?.city?.enabled,
          className: columnStatus?.physician?.city?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "ZONE",
          dataIndex: "zone",
          key: "zone",
          ...getColumnSearchProps("zone", "zone"),
          isShow: columnStatus?.physician?.zone?.enabled,
          className: columnStatus?.physician?.zone?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY CATEGORY",
          dataIndex: "cityCategory",
          key: "cityCategory",
          ...getColumnSearchProps("cityCategory", "cityCategory"),
          isShow: columnStatus?.physician?.cityCategory?.enabled,
          className: `${columnStatus?.physician?.cityCategory?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "PINCODE",
          dataIndex: "pincode",
          key: "pincode",
          ...getColumnSearchProps("pincode", "pincode"),
          isShow: columnStatus?.physician?.pincode?.enabled,
          className: columnStatus?.physician?.pincode?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "HOSPITAL TYPE",
          dataIndex: "hospitalType",
          // ...getColumnSearchProps("hospitalType", "hospitalType"),
          isShow: columnStatus?.physician?.hospitalType?.enabled,
          className: `${columnStatus?.physician?.hospitalType?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          filters: [
            {
              text: "Government",
              value: "Government",
            },
            {
              text: "Private",
              value: "Private",
            },
            {
              text: "Non-Profit",
              value: "Non-Profit",
            },
            {
              text: "Others",
              value: "Others",
            },
          ],
          onFilter: (value, record) =>
            (record.hospitalType || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          key: "hospitalType",
          width: 200,
        },
        {
          title: "NO. OF BEDS",
          dataIndex: "noOfBeds",
          // ...getColumnSearchProps("noOfBeds", "noOfBeds"),
          isShow: columnStatus?.physician?.noOfBeds?.enabled,
          className: `${columnStatus?.physician?.noOfBeds?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          key: "noOfBeds",
          filters: [
            {
              value: "NA",
              text: "NA",
            },
            {
              value: "1 to 10",
              text: "1 to 10",
            },
            {
              value: "11 to 25",
              text: "11 to 25",
            },
            {
              value: "26 to 50",
              text: "26 to 50",
            },
            {
              value: "51 to 75",
              text: "51 to 75",
            },
            {
              value: "76 to 100",
              text: "76 to 100",
            },
            {
              value: "101 to 150",
              text: "101 to 150",
            },
            {
              value: "151 to 200",
              text: "151 to 200",
            },
            {
              value: "200+",
              text: "200+",
            },
          ],
          onFilter: (value, record) =>
            (record.noOfBeds || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {text === "Not updated" || !text ? "Not updated" : text}
            </div>
          ),
          width: 200,
        },
        {
          title: "CONSULTING HOSPITAL 1",
          dataIndex: "consultingHospital1",
          ...getColumnSearchProps("consultingHospital1", "consultingHospital1"),
          isShow: columnStatus?.physician?.consultingHospital1?.enabled,
          className: columnStatus?.physician?.consultingHospital1?.enabled
            ? "table-cell"
            : "hidden",
          key: "consultingHospital1",
          width: 200,
        },
        {
          title: "CONSULTING HOSPITAL 2",
          dataIndex: "consultingHospital2",
          ...getColumnSearchProps("consultingHospital2", "consultingHospital2"),
          isShow: columnStatus?.physician?.consultingHospital2?.enabled,
          className: columnStatus?.physician?.consultingHospital2?.enabled
            ? "table-cell"
            : "hidden",
          key: "consultingHospital2",
          width: 200,
        },
        {
          title: "STARTED PRACTICING (POST RESIDENCY)",
          dataIndex: "startedPracticing",
          ...getColumnSearchProps("startedPracticing", "startedPracticing"),
          isShow: columnStatus?.physician?.startedPracticing?.enabled,
          className: columnStatus?.physician?.startedPracticing?.enabled
            ? "table-cell"
            : "hidden",
          key: "startedPracticing",
          width: 200,
        },
        {
          title: "PROJECT COMPATIBILITY",
          dataIndex: "projectCompatibility",
          ...getColumnSearchProps(
            "projectCompatibility",
            "projectCompatibility"
          ),
          isShow: columnStatus?.physician?.projectCompatibility?.enabled,
          className: columnStatus?.physician?.projectCompatibility?.enabled
            ? "table-cell"
            : "hidden",
          key: "projectCompatibility",
          width: 200,
        },
        {
          title: "TOTAL INCENTIVES PROCESSED",
          dataIndex: "totalIncentivesProcessed",
          ...getColumnSearchProps(
            "totalIncentivesProcessed",
            "totalIncentivesProcessed"
          ),
          isShow: columnStatus?.physician?.totalIncentivesProcessed?.enabled,
          className: columnStatus?.physician?.totalIncentivesProcessed?.enabled
            ? "table-cell"
            : "hidden",
          key: "totalIncentivesProcessed",
          width: 200,
        },
        {
          title: "PREVIOUSLY PARTICIPATED STUDIES",
          dataIndex: "previouslyParticipatedStudies",
          ...getColumnSearchProps(
            "previouslyParticipatedStudies",
            "previouslyParticipatedStudies"
          ),
          isShow:
            columnStatus?.physician?.previouslyParticipatedStudies?.enabled,
          className: columnStatus?.physician?.previouslyParticipatedStudies
            ?.enabled
            ? "table-cell"
            : "hidden",
          key: "previouslyParticipatedStudies",
          width: 200,
        },
        {
          title: "MODE OF PAYMENT",
          dataIndex: "modeOfPayment",
          ...getColumnSearchProps("modeOfPayment", "modeOfPayment"),
          isShow: columnStatus?.physician?.modeOfPayment?.enabled,
          className: columnStatus?.physician?.modeOfPayment?.enabled
            ? "table-cell"
            : "hidden",
          key: "modeOfPayment",
          width: 200,
        },
        {
          title: "VALIDATED DATE",
          dataIndex: "validatedDate",
          ...getColumnSearchProps("validatedDate", "validatedDate"),
          isShow: columnStatus?.physician?.validatedDate?.enabled,
          className: columnStatus?.physician?.validatedDate?.enabled
            ? "table-cell"
            : "hidden",
          key: "validatedDate",
          width: 200,
        },
        {
          title: "YEARS OF EXPERIENCE",
          dataIndex: "experience",
          ...getColumnSearchProps("experience", "experience"),
          isShow: columnStatus?.physician?.experience?.enabled,
          className: columnStatus?.physician?.experience?.enabled
            ? "table-cell"
            : "hidden",
          key: "experience",
          width: 200,
        },
        {
          title: "Access to Mobile App",
          dataIndex: "access",
          isShow: columnStatus?.physician?.access?.enabled,
          className: columnStatus?.physician?.access?.enabled
            ? "table-cell"
            : "hidden",
          key: "access",
          filters: [
            {
              value: "Yes",
              text: "Yes",
            },
            {
              value: "No",
              text: "No",
            },
          ],
          onFilter: (value, record) =>
            (record.access || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          width: 200,
        },
        ...additionalColumns(
          additionalColumnList?.physician,
          columnStatus?.physician,
          "physician"
        ),
        {
          title: "ACTION",
          dataIndex: "status",
          width: 100,
          fixed: "right",
          key: "status",
          render: (text, record) => {
            return (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={1}
                      onClick={() => {
                        history.push(`/members/${record?.id}/view`);
                      }}
                    >
                      View
                    </Menu.Item>
                    {record?.is_verified && (
                      <Menu.Item
                        key={2}
                        onClick={() => {
                          history.push(`/members/${record?.id}/edit`);
                        }}
                      >
                        Edit
                      </Menu.Item>
                    )}

                    {record?.is_verified ? (
                      record?.is_active ? (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: false,
                              },
                              // refetchQueries: [GET_USERS],
                            }).then(() => {
                              refetch();
                            })
                          }
                          key={2}
                        >
                          Deactivate
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: true,
                              },
                              // refetchQueries: [GET_USERS],
                            }).then(() => {
                              refetch();
                            })
                          }
                          key={2}
                        >
                          Activate
                        </Menu.Item>
                      )
                    ) : null}
                  </Menu>
                }
              >
                <MoreOutlined className="text-2xl font-bold cursor-pointer" />
              </Dropdown>
            );
          },
          width: 80,
          align: "center",
        },
      ].filter((data) => data.isShow === undefined || data.isShow),
    [
      columnStatus,
      deactivateUsers,
      history,
      refetch,
      role,
      additionalColumnList,
      additionalColumns,
      // filteredValue
    ]
  );
  const filteredData2 = selectedStatus
    ? sortStatus(tableData, selectedStatus)
    : tableData;

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(
      filteredDataSource.map((item) => item[columnName])
    );
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(
    new Set(tableData?.map((item) => item[selectedColumnNameSpec]))
  ).filter((data) =>
    data?.toLowerCase().includes(searchQuerySpec.toLowerCase())
  );

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const technicianColumns = useMemo(
    () =>
      [
        {
          title: "Respondent ID",
          dataIndex: "respondent_id",
          key: "respondent_id",
          ...getColumnSearchProps("respondent_id", "respondent_id"),
          isShow: columnStatus?.technician?.respondent_id?.enabled,
          className: columnStatus?.technician?.respondent_id?.enabled
            ? "table-cell whitespace-nowrap"
            : "hidden",
          width: 250,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.technician?.status?.enabled,
          className: columnStatus?.technician?.status?.enabled
            ? "table-cell"
            : "hidden",
          key: "status",
          width: 200,
          filters: [
            {
              text: "Raw",
              value: "raw",
            },
            {
              text: "Partial Email",
              value: "partial-email",
            },
            {
              text: "Partial Phone",
              value: "partial-phone",
            },
            {
              text: "Valid",
              value: "Valid",
            },
            {
              text: "Not Validated",
              value: "not-validated",
            },
            {
              text: "Invalid",
              value: "invalid",
            },
            {
              text: "DNC",
              value: "DNC",
            },
            {
              text: "Prohibited",
              value: "Prohibited",
            },
          ],
          // filteredValue: filteredValue ? [filteredValue] : undefined,
          onFilter: (value, record) => {
            const result = record.status.indexOf(value) === 0;
            // setSelectedStatus(value);
            return result;
          },
          // filterMultiple: false,
          filterIcon: () => <FilterOutlined />,
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (!visible) {
          //     setFilteredValue(null);
          //     handleFilterReset();
          //   }
          // },
        },
        {
          title: "TITLE",
          dataIndex: "title",
          key: "title",
          width: 100,
          ...getColumnSearchProps("title", "title"),
          isShow: columnStatus?.technician?.title?.enabled,
          className: columnStatus?.technician?.title?.enabled
            ? "table-cell"
            : "hidden",
        },
        {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          width: 150,
          ...getColumnSearchProps("name", "name"),
          isShow: columnStatus?.technician?.name?.enabled,
          className: columnStatus?.technician?.name?.enabled
            ? "table-cell"
            : "hidden",
        },
        {
          title: "PRIMARY EMAIL",
          dataIndex: "email",
          key: "email",
          width: 280,
          ellipsis: true,
          ...getColumnSearchProps("email", "email"),
          isShow: columnStatus?.technician?.email?.enabled,
          className: columnStatus?.technician?.email?.enabled
            ? "table-cell"
            : "hidden",
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "SECONDARY EMAIL",
          dataIndex: "secondaryEmail",
          key: "secondaryEmail",
          width: 200,
          ...getColumnSearchProps("secondaryEmail", "secondaryEmail"),
          isShow: columnStatus?.technician?.secondaryEmail?.enabled,
          className: `${columnStatus?.technician?.secondaryEmail?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,

          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "GENDER",
          dataIndex: "genderNew",
          key: "gender",
          isShow: columnStatus?.technician?.genderNew?.enabled,
          className: columnStatus?.technician?.genderNew?.enabled
            ? "table-cell"
            : "hidden",
          filters: [
            {
              text: "Male",
              value: "M",
            },
            {
              text: "Female",
              value: "F",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) =>
            text === "M" ? (
              <>Male</>
            ) : text === "F" ? (
              <>Female</>
            ) : (
              <>Not updated</>
            ),
          width: 300,
        },
        {
          title: "YEAR OF BIRTH",
          dataIndex: "year_of_birth",
          key: "year",
          width: 200,
          isShow: columnStatus?.technician?.year_of_birth?.enabled,
          className: `${columnStatus?.technician?.year_of_birth?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          ...getColumnDateSearchProps("year_of_birth", "year"),
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phone_number",
          key: "phone",
          ...getColumnSearchProps("phone_number", "phone"),
          isShow: columnStatus?.technician?.phone_number?.enabled,
          className: `${columnStatus?.technician?.phone_number?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 1",
          dataIndex: "mobile1",
          key: "mobile1",
          ...getColumnSearchProps("mobile1", "phone"),
          isShow: columnStatus?.technician?.mobile1?.enabled,
          className: `${columnStatus?.technician?.mobile1?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 2",
          dataIndex: "mobile2",
          key: "mobile2",
          ...getColumnSearchProps("mobile2", "phone"),
          isShow: columnStatus?.technician?.mobile2?.enabled,
          className: `${columnStatus?.technician?.mobile2?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "STATE",
          dataIndex: "state",
          key: "state",
          ...getColumnSearchProps("state", "state"),
          isShow: columnStatus?.technician?.state?.enabled,
          className: columnStatus?.technician?.state?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY",
          dataIndex: "city",
          key: "city",
          ...getColumnSearchProps("city", "city"),
          isShow: columnStatus?.technician?.city?.enabled,
          className: columnStatus?.technician?.city?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "ZONE",
          dataIndex: "zone",
          key: "zone",
          ...getColumnSearchProps("zone", "zone"),
          isShow: columnStatus?.technician?.zone?.enabled,
          className: columnStatus?.technician?.zone?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY CATEGORY",
          dataIndex: "cityCategory",
          key: "cityCategory",
          ...getColumnSearchProps("cityCategory", "cityCategory"),
          isShow: columnStatus?.technician?.cityCategory?.enabled,
          className: `${columnStatus?.technician?.cityCategory?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "PINCODE",
          dataIndex: "pincode",
          key: "pincode",
          ...getColumnSearchProps("pincode", "pincode"),
          isShow: columnStatus?.technician?.pincode?.enabled,
          className: columnStatus?.technician?.pincode?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "YEARS OF EXPERIENCE",
          dataIndex: "experience",
          key: "experience",
          ...getColumnSearchProps("experience", "experience"),
          isShow: columnStatus?.technician?.experience?.enabled,
          className: columnStatus?.technician?.experience?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "PROFESSION",
          dataIndex: "profession",
          key: "profession",
          ...getColumnSearchProps("profession", "profession"),
          isShow: columnStatus?.technician?.profession?.enabled,
          className: columnStatus?.technician?.profession?.enabled
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "SUB-CATEGORY",
          dataIndex: "sub",
          key: "sub",
          ...getColumnSearchProps("sub", "sub-category"),
          isShow: columnStatus?.technician?.sub?.enabled,
          className: `${columnStatus?.technician?.sub?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "SUB-CATEGORY 2",
          dataIndex: "sub2",
          key: "sub2",
          ...getColumnSearchProps("sub2", "sub2"),
          isShow: columnStatus?.technician?.sub2?.enabled,
          className: `${columnStatus?.technician?.sub2?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "ORGANISATION",
          dataIndex: "organisation",
          ...getColumnSearchProps("organisation", "organisation"),
          isShow: columnStatus?.technician?.organisation?.enabled,
          className: columnStatus?.technician?.organisation?.enabled
            ? "table-cell"
            : "hidden",
          key: "org",
          width: 200,
        },
        {
          title: "HOSPITAL TYPE",
          dataIndex: "hospitalType",
          // ...getColumnSearchProps("hospitalType", "hospitalType"),
          isShow: columnStatus?.technician?.hospitalType?.enabled,
          className: `${columnStatus?.technician?.hospitalType?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          filters: [
            {
              text: "Government",
              value: "Government",
            },
            {
              text: "Private",
              value: "Private",
            },
            {
              text: "Non-Profit",
              value: "Non-Profit",
            },
            {
              text: "Others",
              value: "Others",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) =>
            (record.hospitalType || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          key: "hospitalType",
          width: 200,
        },
        {
          title: "NO. OF BEDS",
          dataIndex: "noOfBeds",
          // ...getColumnSearchProps("noOfBeds", "noOfBeds"),
          isShow: columnStatus?.technician?.noOfBeds?.enabled,
          className: `${columnStatus?.technician?.noOfBeds?.enabled
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          key: "noOfBeds",
          filters: [
            {
              value: "NA",
              text: "NA",
            },
            {
              value: "1 to 10",
              text: "1 to 10",
            },
            {
              value: "11 to 25",
              text: "11 to 25",
            },
            {
              value: "26 to 50",
              text: "26 to 50",
            },
            {
              value: "51 to 75",
              text: "51 to 75",
            },
            {
              value: "76 to 100",
              text: "76 to 100",
            },
            {
              value: "101 to 150",
              text: "101 to 150",
            },
            {
              value: "151 to 200",
              text: "151 to 200",
            },
            {
              value: "200+",
              text: "200+",
            },
          ],
          onFilter: (value, record) =>
            (record.noOfBeds || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {text === "Not updated" || !text ? "Not updated" : text}
            </div>
          ),
          width: 200,
        },
        {
          title: "STARTED PRACTICING (POST RESIDENCY)",
          dataIndex: "startedPracticing",
          ...getColumnSearchProps("startedPracticing", "startedPracticing"),
          isShow: columnStatus?.technician?.startedPracticing?.enabled,
          className: columnStatus?.technician?.startedPracticing?.enabled
            ? "table-cell"
            : "hidden",
          key: "startedPracticing",
          width: 200,
        },
        {
          title: "TOTAL INCENTIVES PROCESSED",
          dataIndex: "totalIncentivesProcessed",
          ...getColumnSearchProps(
            "totalIncentivesProcessed",
            "totalIncentivesProcessed"
          ),
          isShow: columnStatus?.technician?.totalIncentivesProcessed?.enabled,
          className: columnStatus?.technician?.totalIncentivesProcessed?.enabled
            ? "table-cell"
            : "hidden",
          key: "totalIncentivesProcessed",
          width: 200,
        },
        {
          title: "PREVIOUSLY PARTICIPATED STUDIES",
          dataIndex: "previouslyParticipatedStudies",
          ...getColumnSearchProps(
            "previouslyParticipatedStudies",
            "previouslyParticipatedStudies"
          ),
          isShow:
            columnStatus?.technician?.previouslyParticipatedStudies?.enabled,
          className: columnStatus?.technician?.previouslyParticipatedStudies
            ?.enabled
            ? "table-cell"
            : "hidden",
          key: "previouslyParticipatedStudies",
          width: 200,
        },
        {
          title: "MODE OF PAYMENT",
          dataIndex: "modeOfPayment",
          ...getColumnSearchProps("modeOfPayment", "modeOfPayment"),
          isShow: columnStatus?.technician?.modeOfPayment?.enabled,
          className: columnStatus?.technician?.modeOfPayment?.enabled
            ? "table-cell"
            : "hidden",
          key: "modeOfPayment",
          width: 200,
        },
        {
          title: "VALIDATED DATE",
          dataIndex: "validatedDate",
          ...getColumnSearchProps("validatedDate", "validatedDate"),
          isShow: columnStatus?.technician?.validatedDate?.enabled,
          className: columnStatus?.technician?.validatedDate?.enabled
            ? "table-cell"
            : "hidden",
          key: "validatedDate",
          width: 200,
        },
        {
          title: "YEARS OF EXPERIENCE",
          dataIndex: "experience",
          ...getColumnSearchProps("experience", "experience"),
          isShow: columnStatus?.technician?.experience?.enabled,
          className: columnStatus?.technician?.experience?.enabled
            ? "table-cell"
            : "hidden",
          key: "experience",
          width: 200,
        },
        {
          title: "Access to Mobile App",
          dataIndex: "access",
          isShow: columnStatus?.technician?.access?.enabled,
          className: columnStatus?.technician?.access?.enabled
            ? "table-cell"
            : "hidden",
          key: "access",
          filters: [
            {
              value: "Yes",
              text: "Yes",
            },
            {
              value: "No",
              text: "No",
            },
          ],
          onFilter: (value, record) =>
            (record.access || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          width: 200,
        },
        ...additionalColumns(
          additionalColumnList?.technician,
          columnStatus?.technician,
          "technician"
        ),

        {
          title: "ACTION",
          dataIndex: "status",
          width: 100,
          fixed: "right",
          key: "status",
          render: (text, record) => {
            return (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={1}
                      onClick={() => {
                        history.push(`/members/${record?.id}/view`);
                      }}
                    >
                      View
                    </Menu.Item>
                    {record?.is_verified && (
                      <Menu.Item
                        key={2}
                        onClick={() => {
                          history.push(`/members/${record?.id}/edit`);
                        }}
                      >
                        Edit
                      </Menu.Item>
                    )}

                    {record?.is_verified ? (
                      record?.is_active ? (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: false,
                              },
                              // refetchQueries: [GET_USERS],
                            }).then(() => {
                              refetch();
                            })
                          }
                          key={2}
                        >
                          Deactivate
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: true,
                              },
                              // refetchQueries: [GET_USERS],
                            }).then(() => {
                              refetch();
                            })
                          }
                          key={2}
                        >
                          Activate
                        </Menu.Item>
                      )
                    ) : null}
                  </Menu>
                }
              >
                <MoreOutlined className="text-2xl font-bold cursor-pointer" />
              </Dropdown>
            );
          },
          width: 80,
          align: "center",
        },
      ].filter((data) => data.isShow === undefined || data.isShow),
    [
      columnStatus,
      deactivateUsers,
      history,
      refetch,
      role,
      additionalColumnList,
      additionalColumns,
      //  filteredValue
    ]
  );

  const consumerColumns = useMemo(
    () =>
      [
        {
          title: "Respondent ID",
          dataIndex: "respondent_id",
          key: "respondent_id",
          ...getColumnSearchProps("respondent_id", "respondent_id"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.respondent_id?.enabled
              : columnStatus?.consumer?.respondent_id?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.respondent_id?.enabled
              : columnStatus?.consumer?.respondent_id?.enabled
          )
            ? "table-cell whitespace-nowrap"
            : "hidden",
          width: 250,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.status?.enabled
              : columnStatus?.consumer?.status?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.status?.enabled
              : columnStatus?.consumer?.status?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "status",
          width: 200,
          filters: [
            {
              text: "Raw",
              value: "raw",
            },
            {
              text: "Partial Email",
              value: "partial-email",
            },
            {
              text: "Partial Phone",
              value: "partial-phone",
            },
            {
              text: "Valid",
              value: "Valid",
            },
            {
              text: "Not Validated",
              value: "not-validated",
            },
            {
              text: "Invalid",
              value: "invalid",
            },
            {
              text: "DNC",
              value: "DNC",
            },
            {
              text: "Prohibited",
              value: "Prohibited",
            },
          ],
          // filteredValue: filteredValue ? [filteredValue] : undefined,
          onFilter: (value, record) => {
            const result = record.status.indexOf(value) === 0;
            // setSelectedStatus(value);
            return result;
          },
          // filterMultiple: false,
          filterIcon: () => <FilterOutlined />,
          // onFilterDropdownVisibleChange: (visible) => {
          //   if (!visible) {
          //     setFilteredValue(null);
          //     handleFilterReset();
          //   }
          // },
        },
        {
          title: "TITLE",
          dataIndex: "title",
          key: "title",
          ...getColumnSearchProps("title", "title"),
          width: 100,
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.title?.enabled
              : columnStatus?.consumer?.title?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.title?.enabled
              : columnStatus?.consumer?.title?.enabled
          )
            ? "table-cell"
            : "hidden",
        },
        {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.name?.enabled
              : columnStatus?.consumer?.name?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.name?.enabled
              : columnStatus?.consumer?.name?.enabled
          )
            ? "table-cell"
            : "hidden",
          ...getColumnSearchProps("name", "name"),
          width: 200,
        },
        {
          title: "PRIMARY EMAIL",
          dataIndex: "email",
          key: "email",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.email?.enabled
              : columnStatus?.consumer?.email?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.email?.enabled
              : columnStatus?.consumer?.email?.enabled
          )
            ? "table-cell"
            : "hidden",
          width: 280,
          ellipsis: true,
          ...getColumnSearchProps("email", "email"),
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "SECONDARY EMAIL",
          dataIndex: "secondaryEmail",
          key: "secondaryEmail",
          width: 200,
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.secondaryEmail?.enabled
              : columnStatus?.consumer?.secondaryEmail?.enabled,
          ...getColumnSearchProps("secondaryEmail", "secondaryEmail"),
          className:
            activeTab === "3"
              ? `${columnStatus?.patient?.secondaryEmail?.enabled
                ? "table-cell"
                : "hidden"
              } whitespace-nowrap`
              : `${columnStatus?.consumer?.secondaryEmail?.enabled
                ? "table-cell"
                : "hidden"
              } whitespace-nowrap`,

          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "GENDER",
          dataIndex: "genderNew",
          key: "gender",
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.genderNew?.enabled
              : columnStatus?.consumer?.genderNew?.enabled
          )
            ? "table-cell"
            : "hidden",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.genderNew?.enabled
              : columnStatus?.consumer?.genderNew?.enabled,
          filters: [
            {
              text: "Male",
              value: "M",
            },
            {
              text: "Female",
              value: "F",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) =>
            text === "M" ? (
              <>Male</>
            ) : text === "F" ? (
              <>Female</>
            ) : (
              <>Not updated</>
            ),
          width: 200,
        },
        {
          title: "YEAR OF BIRTH",
          dataIndex: "year_of_birth",
          key: "year",
          width: 200,
          className:
            activeTab === "3"
              ? `${columnStatus?.patient?.year_of_birth?.enabled
                ? "table-cell"
                : "hidden"
              } whitespace-nowrap`
              : `${columnStatus?.consumer?.year_of_birth?.enabled
                ? "table-cell"
                : "hidden"
              } whitespace-nowrap`,
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.year_of_birth?.enabled
              : columnStatus?.consumer?.year_of_birth?.enabled,
          ...getColumnDateSearchProps("year_of_birth", "year"),
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phone_number",
          key: "phone",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.phone_number?.enabled
              : columnStatus?.consumer?.phone_number?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.phone_number?.enabled
              : columnStatus?.consumer?.phone_number?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          ...getColumnSearchProps("phone_number", "phone"),
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 1",
          dataIndex: "mobile1",
          key: "mobile1",
          ...getColumnSearchProps("mobile1", "phone"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.mobile1?.enabled
              : columnStatus?.consumer?.mobile1?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.mobile1?.enabled
              : columnStatus?.consumer?.mobile1?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 2",
          dataIndex: "mobile2",
          key: "mobile2",
          ...getColumnSearchProps("mobile2", "phone"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.mobile2?.enabled
              : columnStatus?.consumer?.mobile2?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.mobile2?.enabled
              : columnStatus?.consumer?.mobile2?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "STATE",
          dataIndex: "state",
          key: "state",
          ...getColumnSearchProps("state", "state"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.state?.enabled
              : columnStatus?.consumer?.state?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.state?.enabled
              : columnStatus?.consumer?.state?.enabled
          )
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY",
          dataIndex: "city",
          key: "city",
          ...getColumnSearchProps("city", "city"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.city?.enabled
              : columnStatus?.consumer?.city?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.city?.enabled
              : columnStatus?.consumer?.city?.enabled
          )
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "ZONE",
          dataIndex: "zone",
          key: "zone",
          ...getColumnSearchProps("zone", "zone"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.zone?.enabled
              : columnStatus?.consumer?.zone?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.zone?.enabled
              : columnStatus?.consumer?.zone?.enabled
          )
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "CITY CATEGORY",
          dataIndex: "cityCategory",
          key: "cityCategory",
          ...getColumnSearchProps("cityCategory", "cityCategory"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.cityCategory?.enabled
              : columnStatus?.consumer?.cityCategory?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.cityCategory?.enabled
              : columnStatus?.consumer?.cityCategory?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "PINCODE",
          dataIndex: "pincode",
          key: "pincode",
          ...getColumnSearchProps("pincode", "pincode"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.pincode?.enabled
              : columnStatus?.consumer?.pincode?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.pincode?.enabled
              : columnStatus?.consumer?.pincode?.enabled
          )
            ? "table-cell"
            : "hidden",
          width: 200,
        },
        {
          title: "HOSPITAL",
          dataIndex: "hospital",
          ...getColumnSearchProps("hospital", "hospital"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.hospital?.enabled
              : columnStatus?.consumer?.hospital?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.hospital?.enabled
              : columnStatus?.consumer?.hospital?.enabled
          )
            ? "table-cell"
            : "hidden"
            }`,
          key: "hospital",
          width: 200,
        },
        {
          title: "HOSPITAL TYPE",
          dataIndex: "hospitalType",
          // ...getColumnSearchProps("hospitalType", "hospitalType"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.hospitalType?.enabled
              : columnStatus?.consumer?.hospitalType?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.hospitalType?.enabled
              : columnStatus?.consumer?.hospitalType?.enabled
          )
            ? "table-cell"
            : "hidden"
            }`,
          filters: [
            {
              text: "Government",
              value: "Government",
            },
            {
              text: "Private",
              value: "Private",
            },
            {
              text: "Non-Profit",
              value: "Non-Profit",
            },
            {
              text: "Others",
              value: "Others",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) =>
            (record.hospitalType || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          key: "hospitalType",
          width: 200,
        },
        {
          title: "NO. OF BEDS",
          dataIndex: "noOfBeds",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.noOfBeds?.enabled
              : columnStatus?.consumer?.noOfBeds?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.noOfBeds?.enabled
              : columnStatus?.consumer?.noOfBeds?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          key: "noOfBeds",
          filters: [
            {
              value: "NA",
              text: "NA",
            },
            {
              value: "1 to 10",
              text: "1 to 10",
            },
            {
              value: "11 to 25",
              text: "11 to 25",
            },
            {
              value: "26 to 50",
              text: "26 to 50",
            },
            {
              value: "51 to 75",
              text: "51 to 75",
            },
            {
              value: "76 to 100",
              text: "76 to 100",
            },
            {
              value: "101 to 150",
              text: "101 to 150",
            },
            {
              value: "151 to 200",
              text: "151 to 200",
            },
            {
              value: "200+",
              text: "200+",
            },
          ],
          onFilter: (value, record) =>
            (record.noOfBeds || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {text === "Not updated" || !text ? "Not updated" : text}
            </div>
          ),
          width: 200,
        },
        {
          title: "AILMENTS LIST",
          dataIndex: "ailments",
          key: "ailments",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.ailments?.enabled
              : columnStatus?.consumer?.ailments?.enabled || false,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.ailments?.enabled
              : columnStatus?.consumer?.ailments?.enabled
          )
            ? "table-cell"
            : "hidden"
            }`,
          filters: [
            {
              text: "Acne",
              value: "Acne",
            },
            {
              text: "ADHD",
              value: "ADHD",
            },
            {
              text: "Back pain",
              value: "Back pain",
            },
            {
              text: "Chikungunya",
              value: "Chikungunya",
            },
            {
              text: "Cancer",
              value: "Cancer",
            },
            {
              text: "Bronchitis",
              value: "Bronchitis",
            },
            {
              text: "Not Furnished",
              value: "Not Furnished",
            },
          ],
          onFilter: (value, record) => (record.ailments || "").includes(value),
          width: 200,
        },
        {
          title: "STARTED PRACTICING (POST RESIDENCY)",
          dataIndex: "startedPracticing",
          ...getColumnSearchProps("startedPracticing", "startedPracticing"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.startedPracticing?.enabled
              : columnStatus?.consumer?.startedPracticing?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.startedPracticing?.enabled
              : columnStatus?.consumer?.startedPracticing?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "startedPracticing",
          width: 200,
        },
        {
          title: "TOTAL INCENTIVES PROCESSED",
          dataIndex: "totalIncentivesProcessed",
          ...getColumnSearchProps(
            "totalIncentivesProcessed",
            "totalIncentivesProcessed"
          ),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.totalIncentivesProcessed?.enabled
              : columnStatus?.consumer?.totalIncentivesProcessed?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.totalIncentivesProcessed?.enabled
              : columnStatus?.consumer?.totalIncentivesProcessed?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "totalIncentivesProcessed",
          width: 200,
        },
        {
          title: "PREVIOUSLY PARTICIPATED STUDIES",
          dataIndex: "previouslyParticipatedStudies",
          ...getColumnSearchProps(
            "previouslyParticipatedStudies",
            "previouslyParticipatedStudies"
          ),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.previouslyParticipatedStudies?.enabled
              : columnStatus?.consumer?.previouslyParticipatedStudies?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.previouslyParticipatedStudies?.enabled
              : columnStatus?.consumer?.previouslyParticipatedStudies?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "previouslyParticipatedStudies",
          width: 200,
        },
        {
          title: "MODE OF PAYMENT",
          dataIndex: "modeOfPayment",
          ...getColumnSearchProps("modeOfPayment", "modeOfPayment"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.modeOfPayment?.enabled
              : columnStatus?.consumer?.modeOfPayment?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.modeOfPayment?.enabled
              : columnStatus?.consumer?.modeOfPayment?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "modeOfPayment",
          width: 200,
        },
        {
          title: "VALIDATED DATE",
          dataIndex: "validatedDate",
          ...getColumnSearchProps("validatedDate", "validatedDate"),
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.validatedDate?.enabled
              : columnStatus?.consumer?.validatedDate?.enabled,
          className: (
            activeTab === "3"
              ? columnStatus?.patient?.validatedDate?.enabled
              : columnStatus?.consumer?.validatedDate?.enabled
          )
            ? "table-cell"
            : "hidden",
          key: "validatedDate",
          width: 200,
        },
        {
          title: "Access to Mobile App",
          dataIndex: "access",
          isShow:
            activeTab === "3"
              ? columnStatus?.patient?.access?.enabled
              : columnStatus?.consumer?.access?.enabled,
          className: `${(
            activeTab === "3"
              ? columnStatus?.patient?.access?.enabled
              : columnStatus?.consumer?.access?.enabled
          )
            ? "table-cell"
            : "hidden"
            } whitespace-nowrap`,
          key: "access",
          filters: [
            {
              value: "Yes",
              text: "Yes",
            },
            {
              value: "No",
              text: "No",
            },
          ],
          onFilter: (value, record) =>
            (record.access || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          width: 200,
        },
        ...additionalColumns(
          additionalColumnList?.[activeTab === "3" ? "patient" : "consumer"],
          columnStatus?.[activeTab === "3" ? "patient" : "consumer"],
          activeTab === "3" ? "patient" : "consumer"
        ),
        {
          title: "ACTION",
          dataIndex: "status",
          width: 100,
          fixed: "right",
          key: "status",
          render: (text, record) => {
            return (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={1}
                      onClick={() => {
                        history.push(`/members/${record?.id}/view`);
                      }}
                    >
                      View
                    </Menu.Item>
                    {record?.is_verified && (
                      <Menu.Item
                        key={2}
                        onClick={() => {
                          history.push(`/members/${record?.id}/edit`);
                        }}
                      >
                        Edit
                      </Menu.Item>
                    )}

                    {record?.is_verified ? (
                      record?.is_active ? (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: false,
                              },
                              refetchQueries: [GET_USERS],
                            })
                          }
                          key={2}
                        >
                          Deactivate
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          onClick={() =>
                            deactivateUsers({
                              variables: {
                                id: record.id,
                                status: true,
                              },
                              refetchQueries: [GET_USERS],
                            })
                          }
                          key={2}
                        >
                          Activate
                        </Menu.Item>
                      )
                    ) : null}
                  </Menu>
                }
              >
                <MoreOutlined className="text-2xl font-bold cursor-pointer" />
              </Dropdown>
            );
          },
          width: 80,
          align: "center",
        },
      ].filter((data) => data.isShow === undefined || data.isShow),
    [
      columnStatus,
      deactivateUsers,
      history,
      role,
      activeTab,
      additionalColumnList,
      additionalColumns,
      // filteredValue
    ]
  );

  const onChange = (e) => {
    if (data) {
      setCurrent(1);
      setActiveTab(e);
      history.push(`/members/${tabPane[e - 1]}/1`);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onProcessFile = (e) => {
    setIsModalVisible(false);
    let tempData = [];
    let validData;
    let rejectedRecords;
    if (activeTab === '1') {
      setTrackTempData('');
      let processed_phy = physician.headers.map((physician) => {
        if (
          physician.title === "primary speciality" ||
          physician.title === "secondary speciality"
        ) {
          physician.allowedValues = specialityData;
        }
        if (physician.title === "project compatibility") {
          physician.allowedValues = compatibilities;
        }
        if (physician.title === 'super speciality') {
          physician.specialityObj = superSpecialityValues;
        }
        return physician;
      });
      physician.headers = processed_phy;
      const finalValidData = validateCSV(e.target.result, physician, phone, email, respondentDB);
      validData = finalValidData.finalValidData;
      rejectedRecords = finalValidData.rejectedRecords;
      const additionalColumns = finalValidData.additionalColumns;
      const toCheckStatusMismatch = finalValidData.toCheckStatusMismatch;
      setUncheckedStatus(toCheckStatusMismatch);
      setRejectionData(rejectedRecords);

      validData.map((rowData) => {
        if (rowData !== "") {
          rowData = rowData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          const processedData = physicianRowData(rowData, specialityValues, superSpecialityData, additionalColumns, role);
          tempData.push(processedData);
        }
      });
    } else if (activeTab === '2') {
      setTrackTempData('');
      let processed_tech = technician.headers.map((technician) => {
        return technician;
      });
      technician.headers = processed_tech;
      const finalValidData = validateCSV(e.target.result, technician, phone, email, respondentDB);
      validData = finalValidData.finalValidData;
      rejectedRecords = finalValidData.rejectedRecords;
      const additionalColumns = finalValidData.additionalColumns;
      const toCheckStatusMismatch = finalValidData.toCheckStatusMismatch;
      setUncheckedStatus(toCheckStatusMismatch);
      setRejectionData(rejectedRecords);
      validData.map((rowData) => {
        if (rowData !== "") {
          rowData = rowData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          const processedData = technicianRowData(rowData, professionData, additionalColumns, role);
          tempData.push(processedData);
        }
      });
    } else if (activeTab === '3') {
      setTrackTempData('');
      let processed_patient = patient.headers.map((patient) => {
        if (patient.title === "ailment list") {
          patient.allowedValues = ailmentList;
        }
        return patient;
      });
      patient.headers = processed_patient;
      const finalValidData = validateCSV(e.target.result, patient, phone, email, respondentDB);
      validData = finalValidData.finalValidData;
      rejectedRecords = finalValidData.rejectedRecords;
      const additionalColumns = finalValidData.additionalColumns;
      const toCheckStatusMismatch = finalValidData.toCheckStatusMismatch;
      setUncheckedStatus(toCheckStatusMismatch);
      setRejectionData(rejectedRecords);
      validData.map((rowData) => {
        if (rowData !== "") {
          rowData = rowData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          const processedData = patientRowData(rowData, additionalColumns, role);
          tempData.push(processedData);
        }
      });
    } else {
      setTrackTempData('');
      let processed_consumer = consumer.headers.map((consumer) => {
        return consumer;
      });
      consumer.headers = processed_consumer;
      const finalValidData = validateCSV(e.target.result, consumer, phone, email, respondentDB);
      validData = finalValidData.finalValidData;
      rejectedRecords = finalValidData.rejectedRecords;
      const additionalColumns = finalValidData.additionalColumns;
      const toCheckStatusMismatch = finalValidData.toCheckStatusMismatch;
      setUncheckedStatus(toCheckStatusMismatch);
      setRejectionData(rejectedRecords);
      validData.map((rowData) => {
        if (rowData !== "") {
          rowData = rowData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          const processedData = consumerRowData(rowData, additionalColumns, role);
          tempData.push(processedData);
        }
      });
    }
    setTrackTempData(tempData);

    tempData.forEach((data) => {
      processStatusMismatch(data, trackStatus, setTrackStatus, setErrorTrackStatus, successRecords, errorRecords1, tempErrorRecords, activeTab);
    });

    const updatedErrorRecords = handleExecution(rejectedRecords, tempErrorRecords, setRejectionModal, handleShowWarnings, executeBulkInsertUser, tempData, statusCorrectedValue, activeTab, setStatusVisible);

    setStatusCorrectedValue(updatedErrorRecords);
    setStatusmismatch(tempErrorRecords);
  }
  useEffect(() => {
  }, [uncheckedStatus]);

  useEffect(() => {
    // executeBulkInsertUser(trackTempData);
  }, [trackStatus]);

  let cleanedJsonData = trackStatus?.map(item => {
    const { errorRecords1, ...rest } = item;
    return rest;
  })
    .filter(item => Object.keys(item).length > 0);
  const statusCounts = {
    "Valid": 0,
    "raw": 0,
    "partial-email": 0,
    "partial-phone": 0,
    "not-validated": 0,
    "invalid": 0,
    "DNC": 0,
    "Prohibited": 0
  };
  cleanedJsonData.forEach(member => {
    const status = member.status;
    if (statusCounts.hasOwnProperty(status)) {
      statusCounts[status]++;
    }
  });
  // console.log(trackStatus, cleanedJsonData, 'cleanedJsonData')

  const { data: getUser } = useQuery(GET_USERS);

  const isRespondentIdInList = (respondentId) => {
    return respondentDB.includes(respondentId);
  };

  const editMemberData = [];
  const newUserCreateData = [];
  const getUserOldInfo = [];

  cleanedJsonData.forEach((entry) => {
    const respondentId = entry.respondent_id;
    if (isRespondentIdInList(respondentId)) {
      editMemberData.push(entry);
    } else {
      newUserCreateData.push(entry);
    }
  });

  editMemberData.forEach((entry) => {
    const respondentId = parseInt(entry.respondent_id, 10);
    const userWithMatchingId = getUser?.user.find((item) => {
      return item.respondent_id === respondentId;
    });

    if (userWithMatchingId) {
      getUserOldInfo.push(userWithMatchingId);
    }
  });

  const getUserOldInfoMap = {};
  getUserOldInfo.forEach((entry) => {
    getUserOldInfoMap[entry.respondent_id] = entry;
  });

  const editMemberDataMap = {};
  editMemberData.forEach((entry) => {
    editMemberDataMap[entry.respondent_id] = entry;
  });

  const getUserOldInfoArray = Object.values(getUserOldInfoMap);
  const editMemberDataArray = Object.values(editMemberDataMap);

  const destination =
    activeTab === '1'
      ? '/members/physician/1'
      : activeTab === '2'
        ? '/members/technician/1'
        : activeTab === '3'
          ? '/members/patient/1'
          : '/members/consumer/1';
  const [addApproval] = useMutation(ADD_APPROVAL);
  const member_type = activeTab === "1"
    ? "Physician"
    : activeTab === "2"
      ? "Technician"
      : activeTab === "3"
        ? "Patient"
        : "Consumer";
  const [onboardUserResID] = useMutation(ONBOARD_USER_RES_ID);

  const executeBulkInsertUser = (tempData) => {
    executeBulkInsertUserFunction(
      tempData,
      uncheckedStatus,
      rejectionData,
      trackStatus,
      cleanedJsonData,
      statusCounts,
      role,
      destination,
      getUserOldInfoArray,
      editMemberDataArray,
      id,
      history,
      newUserCreateData,
      rejectionDataList,
      addApproval,
      bulkInsertUser,
      ImportEmailTrigger,
      managerName,
      member_type,
      onboardUserResID
    );
  }

  const handleCheckboxChange = (record, respondentId, checked) => {
    handleCheckboxChangeCommon(record, respondentId, checked, setSelectedRecords, setTrackStatus);
  };

  const handleProceed = () => {
    handleProceedCommon(executeBulkInsertUser, trackTempData, setStatusVisible);
  };

  const rejectionProceed = () => {
    rejectionProceedCommon(setRejectionModal, statusmismatch, setStatusVisible, executeBulkInsertUser);
  };


  function getTitleForActiveTab(activeTab) {
    switch (activeTab) {
      case '1':
        return 'Import Physician';
      case '2':
        return 'Import Technician';
      case '3':
        return 'Import Patient';
      default:
        return 'Import Consumer';
    }
  }

  return (
    <Spin spinning={loading}>
      <WarningMismatchPopup
        rejectionModal={rejectionModal}
        rejectionModalClose={rejectionModalClose}
        rejectionProceed={rejectionProceed}
        rejectionData={rejectionData}
      />
      <StatusMismatchModal
        modalVisible={statusVisible}
        setModalVisible={setStatusVisible}
        errorRecords={errorRecords}
        statusCorrectedValue={statusCorrectedValue}
        handleCheckboxChange={handleCheckboxChange}
        handleProceed={handleProceed}
      />
      <Row className="px-10 py-4">
        <div className="w-full flex justify-between">
          <div className=" flex gap-4">
            <div className="text-textMedium text-2xl float-left mb-0">
              Member details
            </div>
            <div>
              <Button
                type="primary"
                className="flex items-center"
                icon={<DownloadOutlined style={{ fontSize: '14px', display: "flex" }} />}
                size={'medium'}
                ghost
                onClick={showModal}
              >
                Import
              </Button>
              <Modal
                title={getTitleForActiveTab(activeTab)}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={300}
                centered
                footer={false}
              >
                <div className="flex gap-4">
                  <Button
                    type="primary"
                    className="flex items-center"
                    icon={<DownloadOutlined style={{ fontSize: '14px', display: "flex" }} />}
                    size={'medium'}
                    ghost
                    onClick={(e) => {
                      e.stopPropagation();
                      let data, label;
                      if (activeTab === '1') {
                        data = physician;
                        label = 'physician';
                      } else if (activeTab === '2') {
                        data = technician;
                        label = 'technician';
                      } else if (activeTab === '3') {
                        data = patient;
                        label = 'patient';
                      } else {
                        data = consumer;
                        label = 'consumer';
                      }
                      downloadCSV(data, label);
                    }}
                  >
                    CSV Template
                  </Button>
                  <div className="import_dragger">
                    <Dragger
                      name="file"
                      showUploadList={false}
                      className="import_upload"
                      multiple={false}
                      action={(file) => {
                        var reader = new FileReader();
                        reader.readAsText(file);
                        reader.onload = function (e) {
                          onProcessFile(e);
                        };
                      }}
                    >
                      <Button type="primary" >
                        Upload
                      </Button>
                    </Dragger>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
          <div className=" flex">
            <div className="text-textMedium  pt-1 pr-6">
              <span className="pr-2">Show inactive members</span>
              <Switch
                defaultChecked
                onChange={(e) => {
                  setShowInactive(e);
                }}
              />
            </div>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button
                type="primary"
                htmlType="submit"
                className=" text-base rounded-md mr-6"
              >
                <Row justify="space-between" align="middle">
                  <Col className="mr-4 -mt-1.5">
                    <PlusOutlined />
                  </Col>
                  <Col>Add a new member</Col>
                  <Col className="ml-4 -mt-1.5">
                    <DownOutlined />
                  </Col>
                </Row>
              </Button>
            </Dropdown>
            <div>
              <ColumnEditor
                columns={
                  columnStatus[
                  activeTab === "1"
                    ? "physician"
                    : activeTab === "2"
                      ? "technician"
                      : activeTab === "3"
                        ? "patient"
                        : "consumer"
                  ]
                }
                onSave={(column) => {
                  setStatus({
                    ...columnStatus,
                    [activeTab === "1"
                      ? "physician"
                      : activeTab === "2"
                        ? "technician"
                        : activeTab === "3"
                          ? "patient"
                          : "consumer"]: column,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Row >
      <MemberStatusCount
        dataSource={tableData}
        handleStatusClick={handleStatusClick}
        selectedStatus={selectedStatus}
        handleFilterReset={handleFilterReset}
      />
      <Row className="px-10 py-2">
        <Col span={24}>
          <Card className="px-2 py-0" bodyStyle={{ padding: 0 }}>
            <Tabs
              size="large"
              tabBarGutter={24}
              activeKey={activeTab}
              onChange={onChange}
            >
              <TabPane tab="Physician" key="1">
                <MemberTable
                  columns={physicianColumns}
                  dataSource={filteredData2}
                  rowKey={(record) => record.id}
                  rowClassName={(record) => {
                    return record.is_verified && record.is_active
                      ? "text-sm text-textMedium p-0 m-0 "
                      : !record.is_verified
                        ? " text-sm text-yellow-500 p-0 m-0"
                        : " text-sm text-textExtraLight p-0 m-0";
                  }}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                        onChange: (page, pageSize) => {
                          setCurrent(page);
                          history.replace(
                            `/members/${location.pathname.split("/")[2]
                            }/${page}`
                          );
                        },
                        current: current,
                      }
                  }
                />
                <SpecialityModalPop
                  titleSpec={`Selected ${selectedColumnNameSpec}`}
                  visibleSpec={isModalVisibleSpec}
                  onOkSpec={handleModalOkSpec}
                  onCancelSpec={handleModalCloseSpec}
                  searchQuerySpec={searchQuerySpec}
                  handleSearchSpec={handleSearchSpec}
                  handleCheckboxChangeSpec={handleCheckboxChangeSpec}
                  filteredCheckboxDataSpec={filteredCheckboxDataSpec}
                  selectedDataSpec={selectedDataSpec}
                  initialSelectedDataSpec={initialSelectedDataSpec}
                  isSubmitDisabledSpec={isSubmitDisabledSpec}
                  handleResetSpec={handleResetSpec}
                />
              </TabPane>
              <TabPane tab="Technician" key="2">
                <MemberTable
                  columns={technicianColumns}
                  dataSource={filteredData2}
                  rowKey={(record) => record.id}
                  rowClassName={(record) => {
                    return record.is_verified && record.is_active
                      ? "text-sm text-textMedium p-0 m-0 "
                      : !record.is_verified
                        ? " text-sm text-yellow-500 p-0 m-0"
                        : " text-sm text-textExtraLight p-0 m-0";
                  }}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                        onChange: (page, pageSize) => {
                          setCurrent(page);
                          history.replace(
                            `/members/${location.pathname.split("/")[2]
                            }/${page}`
                          );
                        },
                        current: current,
                      }
                  }
                />
              </TabPane>
              <TabPane tab="Patient" key="3">
                <MemberTable
                  columns={consumerColumns}
                  dataSource={filteredData2}
                  rowKey={(record) => record.id}
                  rowClassName={(record) => {
                    return record.is_verified && record.is_active
                      ? "text-sm text-textMedium p-0 m-0 "
                      : !record.is_verified
                        ? " text-sm text-yellow-500 p-0 m-0"
                        : " text-sm text-textExtraLight p-0 m-0";
                  }}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                        onChange: (page, pageSize) => {
                          setCurrent(page);
                          history.replace(
                            `/members/${location.pathname.split("/")[2]
                            }/${page}`
                          );
                        },
                        current: current,
                      }
                  }
                />
              </TabPane>
              <TabPane tab="Consumer" key="4">
                <MemberTable
                  columns={consumerColumns}
                  dataSource={filteredData2}
                  rowKey={(record) => record.id}
                  rowClassName={(record) => {
                    return record.is_verified && record.is_active
                      ? "text-sm text-textMedium p-0 m-0 "
                      : !record.is_verified
                        ? " text-sm text-yellow-500 p-0 m-0"
                        : " text-sm text-textExtraLight p-0 m-0";
                  }}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                        onChange: (page, pageSize) => {
                          setCurrent(page);
                          history.replace(
                            `/members/${location.pathname.split("/")[2]
                            }/${page}`
                          );
                        },
                        current: current,
                      }
                  }
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Spin >
  );
};

export default AMembersList;
