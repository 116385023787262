export const textBoxValidation = (surveyDetails) => {
  let status = true;
  let message = "";
  // eslint-disable-next-line
  surveyDetails.current_question.questions_options.some((option, i) => {
    // let label = surveyDetails.current_answer[i];
    // let answer = label[option.label];
    let answer;
    let copy = surveyDetails.current_answer[i];
    for (let [key, value] of Object.entries(copy)) {
      if (key !== "type") {
        answer = value;
      }
    }
    if (answer.trim() === "") {
      status = false;
      message = `Please enter a text for ${option.label}`;
    }
  });
  return {
    status,
    message,
  };
};
