import { Form, Input, Button, Divider, notification } from "antd";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import dragIcon from "../../../../assets/dragIcon.svg";
import FileUpload from "./fileUpload";
import { useQuery, useMutation } from "@apollo/client";
import { GET_QUESTION_TYPES, GET_QUESTIONS, DELETE_QUESTION } from "./graphql";
import { useLocation } from "react-router-dom";
import { UPDATE_SURVEY_STATUS } from "../graphql";
import moment from "moment";

function QuestionEdit(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [questionData, setQuestionData] = useState([]);
  const [questionID, setQuestionID] = useState([]);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  const [delete_question] = useMutation(DELETE_QUESTION);
  // eslint-disable-next-line
  const { data: types } = useQuery(GET_QUESTION_TYPES, {
    fetchPolicy: "network-only",
  });
  // eslint-disable-next-line
  const { dataSource1 } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      let questionIDArray = [];
      // eslint-disable-next-line
      data.questions.map((question) => {
        questionIDArray.push(question.id);
      });
      setQuestionID(questionIDArray);
      setQuestionData(data.questions);
    },
  });

  const [form] = Form.useForm();

  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue();
      let optionValue = [];
      let questionValues = [];
      // eslint-disable-next-line
      formValues.questions.map((formValues, index) => {
        let fileUploadValue = {
          fileTypesAllowed: formValues.fileTypesAllowed
            ? formValues.fileTypesAllowed
            : null,
          fileSize: formValues.fileSize ? formValues.fileSize : null,
        };
        optionValue = {
          label: formValues.option ? formValues.option : null,
          index: index + 1,
          options: {
            fileUploadValue: fileUploadValue ? fileUploadValue : null,
          },
        };

        return questionValues.push({
          question: formValues.question,
          is_mandatory: true,
          index: index + 1,
          survey_id: parseInt(surveyID),
          questions_options: { data: optionValue },
        });
      });
      delete_question({
        variables: {
          array: questionID,
          object: questionValues,
        },
      }).then(() => {
        updateSurvey({
          variables: {
            id: parseInt(surveyID),
            status:
              buttonAction === "draft"
                ? "draft"
                : moment().isSameOrAfter(props.startDate)
                ? props.status === "draft"
                  ? "draft"
                  : "active"
                : props.status === "active"
                ? "active"
                : "draft",
          },
        }).then(() => {
          props.handleEnable(true);
          if (buttonAction === "active") {
            location.pathname.split("/")[3] === "create-survey"
              ? history.push(
                  `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                )
              : history.push(
                  `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                );
          } else {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push(`/projectList/active`);
          }
        });
      });
    });
  };

  const onFinish = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  let initialValue = [];

  useEffect(() => {
    questionData.length !== 0
      ? // eslint-disable-next-line
        questionData.map((data, index) => {
          let array = [];
          data.questions_options.length !== 0
            ? // eslint-disable-next-line
              data.questions_options.map((option, i) => {
                array[i] = {
                  option: option.label,
                  id: option.id,
                  fileSize: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileSize
                    : null,
                  fileTypesAllowed: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileTypesAllowed
                    : null,
                };
              })
            : // eslint-disable-next-line
              data.questions_options.map((option, i) => {
                array[i] = {
                  option: option.label,
                  id: option.id,
                };
              });
          initialValue.push({
            question: data.question,
            id: data.id,
            fileSize: array.length !== 0 && array[0].fileSize,
            fileTypesAllowed: array.length !== 0 && array[0].fileTypesAllowed,
          });
        })
      : initialValue.push({
          question: "",
          fileSize: "",
          fileTypesAllowed: "",
        });
    // eslint-disable-next-line
  }, [questionData]);

  const GetFields = () => {
    return (
      questionData.length !== 0 && (
        <>
          <Form
            name="dynamic_form_nest_item"
            form={form}
            autoComplete="off"
            // onFinish={onFinish}
            requiredMark={false}
            preserve={true}
          >
            <Form.List
              initialValue={initialValue ? initialValue : ["survey"]}
              name="questions"
            >
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields
                      // eslint-disable-next-line
                      .map((field, index) => (
                        <>
                          <Form.Item
                            className="text-base w-full mt-4 ml-0 mr-0 p-5 float-left border border-borderGray"
                            {...field}
                            name={[field.name, "survey"]}
                            fieldKey={[field.fieldKey, "survey"]}
                            key={index}
                          >
                            <Form.Item
                              name={[field.name, "dragDelete"]}
                              fieldKey={[field.fieldKey, "dragDelete"]}
                            >
                              <div className="h-12">
                                <img
                                  src={dragIcon}
                                  className="float-left"
                                  alt="drag"
                                />
                                {fields.length > 1 ? (
                                  <Button
                                    type="link"
                                    className="float-right text-red"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                ) : (
                                  <Button
                                    type="link"
                                    className="float-right text-red"
                                    disabled
                                  >
                                    Delete
                                  </Button>
                                )}
                              </div>
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "question"]}
                              fieldKey={[field.fieldKey, "question"]}
                              style={{ width: "65%" }}
                              className="float-left"
                              label={<span>Q{index + 1}</span>}
                              colon={false}
                              rules={[
                                {
                                  required: true,
                                  message: "Enter the question sentence",
                                },
                              ]}
                            >
                              <Input className="ml-4" />
                            </Form.Item>
                            <Form.Item
                              name={[field.name, "optionList"]}
                              fieldKey={[field.fieldKey, "optionList"]}
                              className=" w-11/12 block float-left"
                            >
                              <FileUpload option={field} />
                            </Form.Item>
                            <Divider />
                            <Form.Item className="h-10 mb-0 block">
                              <Button
                                className="text-base text-orange float-right "
                                onClick={() => {
                                  add(field.name, index + 1);
                                }}
                              >
                                Add question
                              </Button>
                            </Form.Item>
                          </Form.Item>
                        </>
                      ))}
                  </div>
                );
              }}
            </Form.List>
            <Form.Item className="float-right">
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={() => {
                  location.pathname.split("/")[3] === "create-survey"
                    ? history.push(
                        `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
                      )
                    : history.push(
                        `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
                      );
                }}
              >
                Back
              </Button>
              <Button
                htmlType="submit"
                className="text-base mx-5 rounded-md text-orange"
                onClick={handleDraft}
                disabled={
                  moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                    ? true
                    : false
                }
              >
                Save as draft
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="text-base rounded-md w-28"
                onClick={onFinish}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      )
    );
  };
  return (
    <div name="edit survey">
      <GetFields />
    </div>
  );
}
export default QuestionEdit;
