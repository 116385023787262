import { gql } from "@apollo/client";

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS($id: Int) {
    user(where: { id: { _eq: $id } }) {
      l_name
      f_name
      email
      phone_number
      meta_date
      is_active
      role_id
      role {
        name
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UPDATE_USER($object: user_set_input, $id: Int!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      f_name
      l_name
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UPDATE_PASSWORD($old: String!, $new: String!, $id: Int!) {
    update_password(new_password: $new, old_password: $old, user_id: $id) {
      message
    }
  }
`;

export const UPDATE_INTERVIEWER_STATUS = gql`
  mutation UPDATE_INTERVIEWER_STATUS($id: Int) {
    update_survey_x_member_x_interviewer(
      where: { interviewer_id: { _eq: $id } }
      _set: { interviewer_on_leave: false }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_MANAGER_LEAVE = gql`
  mutation UPDATE_MANAGER_LEAVE($id: Int) {
    update_manager_x_project(
      where: { manager_id: { _eq: $id } }
      _set: { manager_on_leave: false }
    ) {
      affected_rows
    }
  }
`;
