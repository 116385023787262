import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GET_USERS($interviewerID: Int!) {
    user(
    order_by: { id: asc }
    where: {
      role_id: { _eq: 6 }
      status: { _in: ["raw", "partial-email", "partial-phone", "invalid", "not-validated"] }
      ass_interviewer_id: { _eq: $interviewerID }
      # ass_interviewer_status: {_neq: true}
    }
  ) {
      id
      email
      f_name
      l_name
      status
      phone_number
      title
      respondent_id
      additional_data
      ass_interviewer_status
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      role {
        id
        name
        description
      }
      is_active
      member_type_id
      year_of_birth
      gender
      meta_date
      member_type {
        name
      }
      is_verified
      state
      city
      pincode
      ass_interviewer_id
      ass_interviewer_details {
      f_name
      id
      email
    }
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
    }
  }
`;

export const STATUS_COUNTS = gql`
  query STATUS_COUNTS($interviewerID: Int!) {
    total_count: user_aggregate(
      where: {
        role_id: { _eq: 6 }
        status: { _in: ["raw", "partial-email", "partial-phone", "invalid", "not-validated"] }
        ass_interviewer_id: { _eq: $interviewerID }
      }
    ) {
      aggregate {
        count(columns: status)
      }
    }
    raw_count: user_aggregate(
      where: { status: { _eq: "raw" }, _and: { role_id: { _eq: 6 }, ass_interviewer_id: { _eq: $interviewerID } } }
    ) {
      aggregate {
        count(columns: status)
      }
    }
    partial_email: user_aggregate(
      where: { status: { _eq: "partial-email" }, _and: { role_id: { _eq: 6 }, ass_interviewer_id: { _eq: $interviewerID } } }
    ) {
      aggregate {
        count(columns: status)
      }
    }
    partial_phone: user_aggregate(
      where: { status: { _eq: "partial-phone" }, _and: { role_id: { _eq: 6 }, ass_interviewer_id: { _eq: $interviewerID } } }
    ) {
      aggregate {
        count(columns: status)
      }
    }
    not_validated_count: user_aggregate(
      where: { status: { _eq: "not-validated" }, _and: { role_id: { _eq: 6 }, ass_interviewer_id: { _eq: $interviewerID } } }
    ) {
      aggregate {
        count(columns: status)
      }
    }
    invalid_count: user_aggregate(
      where: { status: { _eq: "invalid" }, _and: { role_id: { _eq: 6 }, ass_interviewer_id: { _eq: $interviewerID } } }
    ) {
      aggregate {
        count(columns: status)
      }
    }
  }
`;