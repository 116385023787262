import React, { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Dropdown, Menu, Spin, Table, Tabs } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../utils/table";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import ErrorPage from "../../components/errorPage";
import CountCard from "../../components/Cards/countCard";
import ColumnEditor from "../../components/ColumnEditor";
import { GET_ON_LEAVE_PROJECTS, GET_USER_DETAILS } from "./graphql";
import jwtDecode from "jwt-decode";

const InterviewerDashboard = (props) => {
  const history = useHistory();
  const [liveProjects, setLiveProjects] = React.useState([]);
  const [completedProjects, setCompletedProjects] = React.useState([]);
  const [membersCountstate, setMembersCountstate] = React.useState();
  const [pendingInterviews, setPendingInterviews] = React.useState();
  const [completedInterviews, setCompletedInterviews] = React.useState();
  // eslint-disable-next-line
  const [id, setId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
    "x-hasura-user-id"
    ]
  );
  const savedinterviewersDashboard = localStorage.getItem('interviewersDashboard');
  const interviewersDashboardinitial = savedinterviewersDashboard
    ? JSON.parse(savedinterviewersDashboard)
    : {
      "clientName": { enabled: true, label: "Client Name" },
      "managerName": { enabled: true, label: "Manager Name" },
      "type": { enabled: true, label: "Type" },
      "assignedMembers": { enabled: true, label: "Assigned Members" },
      "start_date": { enabled: true, label: "Start Date" },
      "end_date": { enabled: true, label: "End Date" },
    }
  const [columnStatus, setStatus] = useState(interviewersDashboardinitial);

  useEffect(() => {
    localStorage.setItem('interviewersDashboard', JSON.stringify(columnStatus));
  }, [columnStatus]);
  // const { data, loading, error } = useQuery(GET_INTERVIEWER_DASHBOARD, {
  //   fetchPolicy: "network-only",

  //   variables: {
  //     interviewer_id: parseInt(id),
  //   },
  //   // onCompleted: (data) => {
  //   //   setCompletedProjects(data?.completed_project_list || []);
  //   // },
  // });

  const {
    data: onLeaveProjects,
    loading,
    error,
  } = useQuery(GET_ON_LEAVE_PROJECTS, {
    fetchPolicy: "network-only",
  });
  const { data: userData } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (
      onLeaveProjects &&
      (userData?.user[0]?.meta_date?.availability === "Available" ||
        userData?.user[0]?.meta_date === null)
    ) {
      let membersCount = 0;
      let pendingcount = 0;
      let completedCount = 0;
      let priorityProjects = JSON.parse(
        JSON.stringify(onLeaveProjects?.priorityProjects)
      );
      let activeProjects = [];
      let pendingarray = [];
      let completedArray = [];
      priorityProjects.forEach((project) => {
        let filteredObject = project.active[0].survey_x_members.filter(
          (item) => {
            return (
              item.survey_x_member_x_interviewers.length !== 0 &&
              item.survey_x_member_x_interviewers[0].interviewer_id ===
              parseInt(id)
            );
          }
        );
        project.active[0].survey_x_members = [...filteredObject];

        project.length = project.active[0].survey_x_members.length;

        if (project.active[0].survey_x_members.length !== 0) {
          activeProjects.push(project);
          membersCount += project.active[0].survey_x_members.length;
        }
      });

      // eslint-disable-next-line
      activeProjects.map((active) => {
        let val =
          active?.survey_type !== null && active?.survey_type !== ""
            ? active?.survey_type === "Third_Party_survey"
              ? "- " + active?.third_party_survey_name
              : "- " + active?.survey_type
            : "";
        active.category = active?.projectTypeByProjectType?.name + " " + val;
        active.clientName =
          active?.client?.l_name !== null
            ? active?.client?.f_name + " " + active?.client?.l_name
            : active?.client?.f_name;
        active.managerName =
          active?.manager_x_projects[0]?.user?.l_name !== null
            ? active?.manager_x_projects[0]?.user?.f_name +
            " " +
            active?.manager_x_projects[0]?.user?.l_name
            : active?.manager_x_projects[0]?.user?.f_name;
        // eslint-disable-next-line
        // active.active[0].survey_x_members.filter((item) => {
        //   if (item.survey_x_member_x_interviewers[0].status === "active") {
        //     ++pendingcount;
        //   }
        // });
        // eslint-disable-next-line
        active.active[0].survey_x_members.filter((item) => {
          if (
            item.state === "completed" ||
            item.state === "Approved" ||
            item.state === "approved" ||
            item.state === "Completed"
          ) {
            ++completedCount;
          } else {
            ++pendingcount;
          }
        });
        if (active.status === "active") {
          pendingarray.push(active);
        }
      });
      // eslint-disable-next-line
      activeProjects.map((active) => {
        if (active.status === "completed" || active.status === "Completed") {
          completedArray.push(active);
        }
      });

      setMembersCountstate(membersCount);
      setCompletedProjects(completedArray);
      setPendingInterviews(pendingcount);
      setCompletedInterviews(completedCount);
      setLiveProjects(pendingarray);
    } else if (onLeaveProjects) {
      let membersCount = 0;
      let pendingcount = 0;
      let completedCount = 0;
      let priorityProjects = JSON.parse(
        JSON.stringify(onLeaveProjects?.priorityProjects)
      );
      let activeProjects = [];
      let pendingarray = [];
      let completedArray = [];
      priorityProjects.forEach((project) => {
        let filteredObject = project.leave[0].survey_x_members.filter(
          (item) => {
            return (
              item.survey_x_member_x_interviewers.length !== 0 &&
              item.survey_x_member_x_interviewers[0].interviewer_id ===
              parseInt(id)
            );
          }
        );
        project.leave[0].survey_x_members = [...filteredObject];
        const val = project.leave[0].survey_x_members.filter((item) => {
          return (
            item.survey_x_member_x_interviewers.length !== 0 &&
            item.survey_x_member_x_interviewers_aggregate.aggregate.count === 1
          );
        });
        project.length =
          project.status === "active"
            ? val.length
            : project.active[0].survey_x_members.length;
        // membersCount += project.leave[0].survey_x_members.length;

        if (project.leave[0].survey_x_members.length !== 0) {
          activeProjects.push(project);
        }
      });
      // eslint-disable-next-line
      activeProjects.map((active) => {
        let val =
          active?.survey_type !== null && active?.survey_type !== ""
            ? active?.survey_type === "Third_Party_survey"
              ? "- " + active?.third_party_survey_name
              : "- " + active?.survey_type
            : "";
        active.category = active?.projectTypeByProjectType?.name + " " + val;
        active.clientName =
          active?.client?.l_name !== null
            ? active?.client?.f_name + " " + active?.client?.l_name
            : active?.client?.f_name;
        active.managerName =
          active?.manager_x_projects[0]?.user?.l_name !== null
            ? active?.manager_x_projects[0]?.user?.f_name +
            " " +
            active?.manager_x_projects[0]?.user?.l_name
            : active?.manager_x_projects[0]?.user?.f_name;
        // eslint-disable-next-line
        active.leave[0].survey_x_members.filter((item) => {
          if (
            item.survey_x_member_x_interviewers[0].status === "active" &&
            item.survey_x_member_x_interviewers_aggregate.aggregate.count === 1
          ) {
            ++pendingcount;
          }
        });
        active.newList = active.leave[0].survey_x_members.filter((item) => {
          return (
            item.survey_x_member_x_interviewers[0].status === "active" &&
            item.survey_x_member_x_interviewers_aggregate.aggregate.count === 1
          );
        });
        // eslint-disable-next-line
        active.leave[0].survey_x_members.filter((item) => {
          if (
            item.survey_x_member_x_interviewers[0].status === "completed" ||
            item.survey_x_member_x_interviewers[0].status === "Approved" ||
            item.survey_x_member_x_interviewers[0].status === "approved" ||
            item.survey_x_member_x_interviewers[0].status === "Completed"
          ) {
            ++completedCount;
          }
        });
        if (active.status === "active" && active.newList.length !== 0) {
          pendingarray.push(active);
          membersCount += active.newList.length;
        }
      });
      // eslint-disable-next-line
      activeProjects.map((active) => {
        if (active.status === "completed" || active.status === "Completed") {
          completedArray.push(active);
          membersCount += active.leave[0].survey_x_members.length;
        }
      });

      setMembersCountstate(membersCount);
      setCompletedProjects(completedArray);
      setPendingInterviews(pendingcount);
      setCompletedInterviews(completedCount);
      setLiveProjects(pendingarray);
    }
    // eslint-disable-next-line
  }, [onLeaveProjects, userData]);
  const columns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
    },
    {
      title: "CLIENT",
      key: "client.f_name",
      dataIndex: "clientName",
      className: columnStatus?.clientName?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{`${text} `}</div>
      ),
      ...getColumnSearchProps("clientName", "Client Name"),
    },
    {
      title: "MANAGER",
      key: "manager_x_projects",
      dataIndex: "managerName",
      className: columnStatus?.managerName?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("managerName", "Manager Name"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "TYPE",
      key: "projectTypeByProjectType.name",
      dataIndex: "category",
      className: columnStatus?.type?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("category", "Category Name"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{`${text}`}</div>
      ),
    },
    {
      title: "ASSIGNED MEMBERS",
      key: "sample",
      dataIndex: "length",
      className: columnStatus?.assignedMembers?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("length", "Assigned members count"),
      render: (text, record, index) => (
        <div className="text-textMedium text-h8">{text}</div>
      ),
    },
    {
      title: "START DATE",
      key: "start_date",
      dataIndex: "start_date",
      className: columnStatus?.start_date?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      dataIndex: "end_date",
      className: columnStatus?.end_date?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => history.push(`/project/${record.id}/overview`)}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Spin spinning={loading}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-textMedium text-h4 font-semibold">
            Overview
          </span>
        </div>
        <div className="grid grid-cols-12 gap-4">
          <div className="flex justify-between col-span-6 rounded shadow">
            <CountCard
              count={liveProjects.length + completedProjects.length || 0}
              description="Total projects"
              className="flex-grow rounded-l-lg"
            />
            <CountCard
              count={liveProjects.length || 0}
              description="Pending projects"
              className="flex-grow"
            />
            <CountCard
              count={completedProjects.length || 0}
              description="Completed projects"
              className="flex-grow rounded-r-lg"
            />
          </div>
          <div className="flex justify-between col-span-6 rounded shadow">
            <CountCard
              count={membersCountstate || 0}
              description="Members assigned"
              className="flex-grow rounded-l-lg"
            />
            <CountCard
              count={pendingInterviews || 0}
              description="Pending interviews"
              className="flex-grow"
            />
            <CountCard
              count={completedInterviews || 0}
              description="Completed interviews"
              className="flex-grow rounded-r-lg"
            />
          </div>
        </div>
        <div className=" flex justify-between my-4">
          <div className=" text-h5 text-textMedium font-semibold">
            Assigned projects
          </div>
          <div>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </div>

        </div>
        <Tabs
          defaultActiveKey="1"
          className="shadow rounded bg-white"
          size="large"
          tabBarStyle={{ padding: "0 16px", marginBottom: 0 }}
        >
          <Tabs.TabPane tab="Pending" key="1">
            <Table
              columns={columns}
              dataSource={liveProjects}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Completed" key="2">
            <Table
              columns={columns}
              dataSource={completedProjects}
              pagination={false}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Spin>
  );
};

export default InterviewerDashboard;
