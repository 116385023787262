import { LeftOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Collapse, Layout, Tabs, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import ArrowIcon from "../../../assets/Icon/arrow.svg";
import Loading from "../../../components/Loading";
import { formatDateTime, formatDateTOString } from "../../../utils/dateTime";
import PreviewExternal from "../../createSurvey/external/questionnaire/preview";
import Preview from "../../createSurvey/prf/questionnaire/preview";
import { GET_PROJECT_INFO } from "./graphql";
const { Content } = Layout;
const { TabPane } = Tabs;
const { Panel } = Collapse;

const ViewContainer = ({ title, desc = "-" }) => {
  return (
    <div className="mb-4 flex">
      <div className="text-xs text-textLight w-28 mr-11 min-w-28">{title}</div>
      <div className="text-sm text-textMedium font-medium">{desc}</div>
    </div>
  );
};
const SurveyStat = ({ title, desc }) => {
  return (
    <div className="border border-bgLite rounded py-3 px-4">
      <div className="text-textMedium font-medium text-2xl">{title}</div>
      <div className="mt-2 text-sm">{desc}</div>
    </div>
  );
};

const convertHTML = (html) => {
  var temp = document.createElement("span");
  temp.innerHTML = html;
  return temp.textContent;
};

const ResponseCard = ({ count = 0, title, description }) => {
  return (
    <div className="border border-borderGray rounded py-5 px-4">
      <div className=" text-textMedium text-2xl font-medium ">{count}</div>
      <div className="mt-2 text-textLight font-medium ">{title}</div>
      <div className="mt-2 text-textLight text-sm">{description}</div>
    </div>
  );
};
export default function ViewProject() {
  const tabPane = [
    "project-details",
    "survey-overview",
    "questionnaire",
    "responses",
  ];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const location = useLocation();
  const history = useHistory();
  const { projectID, surveyID } = useParams();
  const [project, setProject] = useState(null);
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    if (location.pathname.split("/")[2] === "project-details") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[2] === "survey-overview") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[2] === "questionnaire") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[2] === "responses") {
      setActiveTabPane("4");
    }
  }, [location.pathname]);

  const handleActiveTabPane = (key) => {
    let surveyType = ``;
    if (project && project.projectTypeByProjectType) {
      surveyType = `${project?.projectTypeByProjectType?.name}${project?.survey_type ? `-${project?.survey_type}` : ""
        }`;
    }
    surveyType = surveyType.replace(/ /g, "");

    let pathFor = "clients";
    if (location.pathname.includes("/pocs/")) {
      pathFor = "pocs";
    }

    if (tabPane[key - 1] === "questionnaire") {
      history.push(
        `/${pathFor}/project/${projectID}/${tabPane[key - 1]}/survey/${survey?.id
        }/${surveyType}`
      );
    } else {
      if (survey?.id) {
        history.push(
          `/${pathFor}/project/${projectID}/${tabPane[key - 1]}/survey/${survey?.id
          }`
        );
      } else {
        history.push(`/${pathFor}/project/${projectID}/${tabPane[key - 1]}`);
      }
    }
    setActiveTabPane(key);
  };

  // eslint-disable-next-line
  const { data, loading: ProjectLoading } = useQuery(GET_PROJECT_INFO, {
    variables: {
      id: parseInt(projectID),
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.project_by_pk) {
        setProject(data.project_by_pk);
        setSurvey(data.project_by_pk.surveys[0]);
      }
    },
  });

  return (
    <div className="relative">
      {ProjectLoading && <Loading />}
      <Content className="ml-24 mb-5 mt-4 ">
        <LeftOutlined
          style={{ margin: "20px 5px", color: "orange" }}
          className="float-left"
          onClick={() => {
            history.push("/dashboard");
          }}
        />
        <div className=" text-sm text-textLight pt-4">
          <p>
            ID#{projectID}
            {project?.status && (
              <span className="ml-2">
                <Tag
                  color={project?.status === "active" ? `blue` : `red`}
                  className={`ml-2 capitalize font-medium text-sm px-3 rounded border-transparent pt-1 pb-1 ${project?.status === "active" ? "bg-water text-active" : ""
                    }`}
                >
                  {project?.status}
                </Tag>
              </span>
            )}
          </p>
          <div className="font-black text-textDark mb-2 flex items-center text-lg ">
            {project?.name}
          </div>
        </div>
        <div className="mt-7 bg-white rounded">
          <Tabs
            className="w-full bg-white mt-4"
            // defaultActiveKey="1"
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane
              tab={<span className="px-4">Project details</span>}
              key="1"
              className="px-8 py-4"
            >
              <ViewContainer
                title="Client"
                desc={[project?.client?.f_name, project?.client?.l_name]
                  .filter(String)
                  .join(" ")}
              />
              <ViewContainer
                title="Member type"
                desc={project?.member_type?.name}
              />
              <ViewContainer
                title="Project type"
                desc={(project?.projectTypeByProjectType.name === 'Assisted' || project?.projectTypeByProjectType.name === 'Online') && project?.is_online_or_assisted ? project?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : project?.projectTypeByProjectType?.name}
              />
              <ViewContainer
                title="Start date"
                desc={formatDateTOString(project?.start_date)}
              />
              <ViewContainer
                title="End date"
                desc={formatDateTOString(project?.end_date)}
              />
              <ViewContainer
                title="Description"
                desc={convertHTML(project?.description) || ""}
              />
              <div className="inline-block  bg-gray rounded p-4 -ml-4">
                <div className="flex">
                  <div className="text-sm text-textMedium w-28 mr-11 min-w-28">
                    Total budget
                  </div>
                  <div className="text-sm text-textMedium font-medium">
                    {project?.total_budget}{" "}
                    {project?.currency
                      ? String(project?.currency).toUpperCase()
                      : ``}
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane
              tab={<span className="px-4">Survey overview</span>}
              key="2"
              className="px-8 py-4"
              disabled={!survey}
            >
              <div className="flex">
                <div className="w-8/12 border-r border-borderGray">
                  <div className="font-medium text-lg text-textMedium mb-8">
                    {survey?.name}
                  </div>
                  <ViewContainer
                    title="Go live"
                    desc={
                      <div>
                        {survey?.start_date !== null
                          ? formatDateTime(survey?.start_date)
                          : "Not yet set"}
                      </div>
                    }
                  />
                  <ViewContainer
                    title="Due by"
                    desc={
                      <div>
                        {survey?.end_date !== null
                          ? formatDateTime(survey?.end_date)
                          : "Not yet set"}
                      </div>
                    }
                  />
                  <ViewContainer
                    title="Completion"
                    desc={
                      <div>
                        {survey?.completed?.aggregate?.count}/{project?.sample}{" "}
                        <span className="ml-3 text-sm text-textLight">
                          {survey?.completed?.aggregate?.count ===
                            project?.sample
                            ? "Reached survey limit"
                            : ""}
                        </span>
                      </div>
                    }
                  />
                  <div className="border-borderGray border-t  w-full mt-5 mb-1"></div>
                  <div className="viewProject">
                    <Collapse
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                      expandIcon={(props) => (
                        <img
                          src={ArrowIcon}
                          alt="arrowIcon"
                          className={`transform ${props.isActive ? "rotate-180" : ""
                            }  `}
                        />
                      )}
                    >
                      <Panel header="Description" key="1">
                        <div>{survey?.description || `-`}</div>
                      </Panel>
                    </Collapse>
                  </div>
                  <div className="viewProject">
                    <Collapse
                      defaultActiveKey={["1"]}
                      expandIconPosition="right"
                      expandIcon={(props) => (
                        <img
                          src={ArrowIcon}
                          alt="arrowIcon"
                          className={`transform ${props.isActive ? "rotate-180" : ""
                            }  `}
                        />
                      )}
                    >
                      <Panel header="Consent" key="1">
                        <div>
                          {survey?.consent ? convertHTML(survey?.consent) : `-`}
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
                <div className="w-4/12 pl-4">
                  <div className="text-textMedium font-medium mb-3">
                    Survey stats
                  </div>
                  <div className="grid grid-cols-1 gap-2">
                    <SurveyStat
                      title={survey?.completed?.aggregate?.count || 0}
                      desc={<span className="text-green">Completed</span>}
                    />
                    <SurveyStat
                      title={survey?.screened?.aggregate?.count || 0}
                      desc={<span className="text-blue">Screened</span>}
                    />
                    <SurveyStat
                      title={survey?.quotaFull?.aggregate?.count || 0}
                      desc={<span className=" text-Yellow">Quota Full</span>}
                    />
                  </div>
                </div>
              </div>
            </TabPane>
            {project?.survey_type !== "Client" &&
              project?.survey_type !== "Third_Party_survey" && (
                <TabPane
                  tab={<span className="px-4">Questionnaire</span>}
                  key="3"
                  className="px-8 py-4"
                  disabled={!survey}
                >
                  {(project?.projectTypeByProjectType?.name === "PRF" ||
                    project?.projectTypeByProjectType?.name === "Online" ||
                    project?.projectTypeByProjectType?.name === "Assisted") && (
                      <Preview viewcollapse={false} />
                    )}
                  {project?.projectTypeByProjectType?.name === "External" && (
                    <PreviewExternal />
                  )}
                  {/* <Preview viewcollapse={false} /> */}
                </TabPane>
              )}
            <TabPane
              tab={<span className="px-4">Responses</span>}
              key="4"
              className="px-8 py-4"
            >
              <div className="font-medium text-textLight flex border-b border-bgLite pb-5">
                <div className="mr-2 border-r border-bgDark pr-2">
                  Published on :{" "}
                  {moment(survey?.start_date).format("MMM DD, YYYY")}
                </div>
                <div>
                  Created by :{" "}
                  {project?.manager_x_projects &&
                    project?.manager_x_projects.length
                    ? project?.manager_x_projects[0]?.user?.name
                    : "-"}{" "}
                  (Manager)
                </div>
              </div>
              <div className="grid gap-5 grid-cols-3 mt-8 w-10/12">
                <ResponseCard
                  title="Members"
                  description="Total number of members invited"
                  count={survey?.members?.aggregate.count} // add external
                />
                <ResponseCard
                  title="Responses"
                  description="Total number of responses"
                  count={
                    project?.projectTypeByProjectType?.name === "PRF"
                      ? survey?.patient?.aggregate.count
                      : survey?.externalResponses?.reduce(
                        (prev, curr) =>
                          curr.priority.aggregate.count !== 0
                            ? prev + 1
                            : prev,
                        0 //initial value
                      )
                  }
                />
                <ResponseCard
                  title="Completed"
                  description="Total number of completions"
                  count={survey?.completed?.aggregate.count}
                />
                <ResponseCard
                  title="Screened"
                  description="Total number of members screened"
                  count={survey?.screened?.aggregate.count}
                />
                <ResponseCard
                  title="Quota Full"
                  description="Total number of members terminated"
                  count={survey?.quotaFull?.aggregate.count}
                />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </div>
  );
}
