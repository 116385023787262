import { useEffect, useState } from "react";
import { Button, Collapse, Modal, DatePicker, Card } from "antd";
import parse from "html-react-parser";
import { formatDateTime } from "../../utils/dateTime";
import moment from "moment";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { UPDATE_SCHEDULE, PROJECT_DATES } from "./graphql";
import { useMutation, useQuery } from "@apollo/client";

const { Panel } = Collapse;

function ViewSurveyTab(props) {
  const location = useLocation();
  const { surveyID, projectID } = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isEnabled, setEnabled] = useState(false);
  const [startDate, setStartDate] = useState(
    props?.surveyData[0]?.start_date !== null
      ? moment(props?.surveyData[0]?.start_date)
      : null
  );
  const [dueDate, setDueDate] = useState(
    props?.surveyData[0]?.end_date !== null
      ? moment(props?.surveyData[0]?.end_date)
      : null
  );

  const [updateSchedule] = useMutation(UPDATE_SCHEDULE, {
    onCompleted(data) {
      // window.location.reload();
    },
  });

  const { data: projectDates } = useQuery(PROJECT_DATES, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  function disabledDuebyDate(current) {
    return (
      current < moment(projectDates?.project[0]?.start_date) ||
      current > moment(projectDates?.project[0]?.end_date)
    );
  }

  const handleOk = () => {
    if (!startDate) {
      setErrorMessage("Please select the starting date of the survey");
    } else if (!dueDate) {
      setErrorMessage("Please select the due date of the survey");
    } else if (moment(startDate).isSameOrAfter(moment(dueDate))) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (formatDateTime(startDate) === formatDateTime(dueDate)) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (
      !isEnabled &&
      moment(startDate).isSameOrBefore(moment()) &&
      moment(startDate).date() === moment().date()
    ) {
      setErrorMessage("Select a Go-live time greater than current time");
    } else {
      updateSchedule({
        variables: {
          id: parseInt(surveyID),
          start: startDate,
          end: dueDate,
        },
      }).then(() => {
        window.location.reload();
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setStartDate(
      props?.surveyData[0]?.start_date !== null
        ? moment(props?.surveyData[0]?.start_date)
        : null
    );
    setDueDate(
      props?.surveyData[0]?.end_date !== null
        ? moment(props?.surveyData[0]?.end_date)
        : null
    );
  };

  useEffect(() => {
    let time = moment().isSameOrAfter(
      formatDateTime(props?.surveyData[0]?.start_date)
    );

    setEnabled(time);
  }, [props]);

  // function range(start, end) {
  //   const result = [];
  //   for (let i = start; i < end; i++) {
  //     result.push(i);
  //   }
  //   return result;
  // }

  // function disabledDate(current) {
  //   return current && current < moment().endOf("day") - 1;
  // }
  function disabledDate(current) {
    if (moment().isSameOrAfter(moment(projectDates?.project[0]?.start_date))) {
      return (
        current < moment().startOf("day") ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    } else {
      return (
        current < moment(projectDates?.project[0]?.start_date) ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    }
  }

  // function disabledDateTime() {
  //   return {
  //     disabledHours: () => range(0, 24).splice(20, 20),
  //     disabledMinutes: () => range(60, 60),
  //     disabledSeconds: () => [55, 56],
  //   };
  // }

  return (
    <>
      <div className="viewSurvey w-3/4 pl-6 float-left min-h-full ">
        {location.pathname.split("-")[1] !== "Third_Party_survey" && (
          <p className="text-lg text-textMedium">
            {props?.surveyData[0]?.name}
          </p>
        )}
        <div>
          <div className="flex justify-start items-center mb-5">
            <span className="text-sm text-black font-bold w-40 float-left">
              Survey schedule
            </span>
            <span className="text-base text-textDark">
              <Button type="link" className="p-0" onClick={showModal}>
                Edit
              </Button>
            </span>
            <Modal
              title="Schedule"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              width={400}
              okText="Update"
            >
              <div style={{ color: "red" }}>
                <p>{errorMessage}</p>
              </div>
              <div className="flex justify-start items-center mb-5">
                <span className="w-20">
                  <p className="text-gray-100">GO- LIVE</p>
                </span>
                <span className="ml-5">
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={disabledDate}
                    // disabledTime={disabledDateTime}
                    showTime
                    disabled={isEnabled}
                    value={startDate}
                    showNow={false}
                    className="w-40"
                    onChange={(e) => {
                      setStartDate(e);
                    }}
                  />
                </span>
              </div>
              <div className="flex justify-start items-center mb-5">
                <span className="w-20">
                  <p className="text-gray-100">DUE BY</p>
                </span>
                <span className="ml-5">
                  <DatePicker
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={disabledDuebyDate}
                    showTime
                    value={dueDate}
                    showNow={false}
                    className="w-40"
                    onChange={(e) => {
                      setDueDate(e);
                    }}
                  />
                </span>
              </div>
            </Modal>
          </div>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Go Live
            </span>
            <span className="text-base text-textDark">
              {props?.surveyData[0]?.start_date !== null
                ? formatDateTime(props?.surveyData[0]?.start_date)
                : "Not yet set"}
            </span>
          </p>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Due by
            </span>
            <span className="text-base text-textDark ">
              {props?.surveyData[0]?.end_date !== null
                ? formatDateTime(props?.surveyData[0]?.end_date)
                : "Not yet set"}
            </span>
          </p>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Completion
            </span>
            <span className="text-base text-textDark">
              {
                props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                  ?.count
              }
              /{props.sampleSize}
            </span>
            <span className="text-sm text-textLight ml-4">
              {props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                ?.count === parseInt(props.sampleSize) ||
              props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                ?.count > parseInt(props.sampleSize)
                ? "Reached survey limit"
                : ""}
            </span>
          </p>
        </div>
        {location.pathname.split("-")[1] !== "Third_Party_survey" && (
          <Collapse
            className="bg-white"
            bordered={false}
            defaultActiveKey={["1"]}
            expandIconPosition="right"
          >
            <Panel
              className="pl-0"
              header={
                <span className="text-base text-textDark">Description</span>
              }
              key="1"
            >
              {props?.surveyData[0]?.description}
            </Panel>
            <Panel
              header={<span className="text-base text-textDark">Consent</span>}
              key="2"
            >
              {props?.surveyData[0]?.consent &&
                parse(props?.surveyData[0]?.consent)}
            </Panel>
          </Collapse>
        )}
      </div>
      {location.pathname.split("-")[1] !== "Third_Party_survey" &&
        (props.projectType === "Online" ||
          props.projectType === "Assisted") && (
          <div className="surveyStats text-base text-textMedium float-left pl-4 border-borderColorGray border-l-2">
            <p>Survey stats</p>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {
                  props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                    ?.count
                }
              </p>
              <p className="text-green text-h8">Completed</p>
            </Card>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {props?.surveyData[0]?.screened?.aggregate?.count}
              </p>
              <p className="text-blue text-h8">Screened</p>
            </Card>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {props?.surveyData[0]?.quotaFull?.aggregate?.count}
              </p>
              <p className="text-orange text-h8">Quota full</p>
            </Card>
          </div>
        )}
    </>
  );
}
export default ViewSurveyTab;
