import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import SuperAdminDashboard from "./superAdminDashboard";
import AdminDashboard from "./AdminDashboard";
import ManagerDashboard from "./managerDashboard";
import InterviewerDashboard from "./InterviewerDashboard";
import ClientDashboard from "./ClientDashboard";
import PocDashboard from "./PocDashboard";

const Dashboard = () => {
  const [role, setRole] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ];//["x-hasura-default-role"];
      setUserId(decode["x-hasura-user-id"]);
      setRole(decode["x-hasura-default-role"]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {role === "super_admin" && <SuperAdminDashboard />}
      {role === "sub_admin" && <AdminDashboard />}
      {role === "manager" && <ManagerDashboard />}
      {role === "interviewer" && <InterviewerDashboard />}
      {role === "client" && <ClientDashboard id={userId} />}
      {role === "poc" && <PocDashboard id={userId} />}
    </>
  );
};
export default Dashboard;
