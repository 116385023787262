import { gql } from "@apollo/client";

export const PROJECTLIST = gql`
  query dashboard {
    live_projects: project(
      where: { status: { _in: ["active", "re-opened"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      currency
      cost_per_interview
      member_types
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      leaveManager: manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
    draft_projects: project(
      where: { status: { _in: ["draft"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      currency
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      member_types
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
    completed_projects: project(
      where: { status: { _eq: "completed" } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      currency
      member_types
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const MANGERLIST = gql`
  query dashboard {
    live_projects: project(
      where: {
        status: { _in: ["active"] }
        manager_x_projects: { manager_on_leave: { _eq: false } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      member_types
      currency
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }

    draft_projects: project(
      where: { status: { _in: ["active", "re-opened", "completed"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      member_types
      currency
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      leaveManager: manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
    completed_projects: project(
      where: { status: { _in: ["active", "re-opened", "completed"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      member_types
      currency
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      leaveManager: manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
    leave_projects: project(
      where: { status: { _in: ["active", "re-opened", "completed"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      member_types
      currency
      cost_per_interview
      incentive
      third_party_survey_name
      third_party_survey_url
      estimated_ir
      budget
      total_budget
      survey_type
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS($id: Int) {
    user(where: { id: { _eq: $id } }) {
      id
      meta_date
    }
  }
`;
