import React, { useState } from "react";
import { DatePicker } from "antd";
import { useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";
import moment from "moment";

const DateTime = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [answer, setAnswer] = useState([]);
  const [data, setData] = useState([]);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          value !== ""
            ? copy.push(moment(value, "DD/MM/YYYY hh:mm a"))
            : copy.push(null);
        }
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
    }));
    setAnswer(copy);
    // eslint-disable-next-line
  }, [answers]);

  const handleOnChange = (value, label, i, date) => {
    let current_answer_copy = [...surveyDetails.current_answer];
    let ans_copy = [...answer];
    ans_copy[i] = date;
    let copy = {};
    copy[label] = value;
    copy["type"] = props.question.question_type.name;
    current_answer_copy[i] = copy;
    setAnswer(ans_copy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer_copy,
    }));
  };

  return (
    <div className="mt-6">
      {data.map((option, i) => (
        <div className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4">
          {option.label && (
            <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
              {option.label}
            </span>
          )}
          <DatePicker
            // disabledDate={disabledDate}
            showTime={option?.options?.includeTime === true ? true : false}
            size="large"
            format={
              option?.options?.includeTime === true
                ? "DD/MM/YYYY hh:mm A"
                : "DD/MM/YYYY"
            }
            value={answer[i]}
            onChange={(value, dateString) =>
              handleOnChange(dateString, option.label, i, value)
            }
          />
          <p>
            {props?.question?.validation !== null && <span>Hint: </span>}
            {props?.question?.validation !== null &&
              props?.question?.validation?.dateAndTimeRange[i] &&
              props?.question?.validation?.dateAndTimeRange[i].dateRange &&
              props?.question?.validation?.dateAndTimeRange[i].dateRange !==
                null && (
                <span className="text-textLight">
                  Enter a date between{" "}
                  {moment(
                    props?.question?.validation?.dateAndTimeRange[i].dateRange
                      .dateRange[0]
                  ).format("DD/MM/YYYY")}{" "}
                  and{" "}
                  {moment(
                    props?.question?.validation?.dateAndTimeRange[i].dateRange
                      .dateRange[1]
                  ).format("DD/MM/YYYY")}
                  .
                </span>
              )}

            {props?.question?.validation !== null &&
              props?.question?.validation?.dateAndTimeRange[i] &&
              props?.question?.validation?.dateAndTimeRange[i].timeRange &&
              props?.question?.validation?.dateAndTimeRange[i].timeRange !==
                null &&
              props?.question?.validation?.dateAndTimeRange[i].timeRange
                .timeRange !== "" && (
                <span className="text-textLight">
                  Enter a time between{" "}
                  {
                    props?.question?.validation?.dateAndTimeRange[i]?.timeRange
                      ?.startTime
                  }{" "}
                  and{" "}
                  {
                    props?.question?.validation?.dateAndTimeRange[i]?.timeRange
                      ?.endTime
                  }
                </span>
              )}
          </p>
        </div>
      ))}
    </div>
  );
};

export default DateTime;
