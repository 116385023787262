import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";
import { Input } from "antd";

const MultipleChoice = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  // const [answer, setAnswer] = useState([]);
  // eslint-disable-next-line
  const [label, setLabel] = useState();

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  let copy = [];
  let optional = [];
  React.useEffect(() => {
    let val = 0;
    if (
      answers?.questions[0]?.answers[0]?.more_info !== null &&
      answers?.questions[0]?.answers[0]?.more_info !== undefined
    ) {
      answers.questions[0].answers[0].more_info.forEach((item, i) => {
        for (let [key, value] of Object.entries(item)) {
          if (key !== "type") {
            optional[i] = value;
            val++;
            copy[i] = true;
            return;
          } else {
            copy[i] = false;
          }
        }
      });
      setItems(
        data &&
          data.map((option, i) => ({
            id: option.id,
            label: option.label,
            selected: copy[i],
            more_info: option.more_info !== null,
            more_info_label: option.more_info,
          }))
      );
    } else {
      setItems(
        data &&
          data.map((option) => ({
            id: option.id,
            label: option.label,
            selected: false,
            more_info: option.more_info !== null,
            more_info_label: option.more_info,
          }))
      );
    }
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
      optionCount: val,
      exisitingAnswer: answers,
    }));
    setInfo(optional);
    // setAnswer(copy);
    // eslint-disable-next-line
  }, [data, answers]);

  const handleText = (value, j) => {
    let infoCopy = [...info];
    let items_copy = [...items];
    infoCopy[j] = value;
    let current_answer = [...surveyDetails.current_answer];
    let object = JSON.parse(JSON.stringify(current_answer));
    object[j][items_copy[j].label] = value;
    setInfo(infoCopy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: object,
    }));
  };

  const handleOnClick = (label, i) => {
    setLabel(label);

    let val = 0;
    let copyValue = [];
    let items_copy = [...items];
    let infoCopy = [...info];

    items_copy[i].selected = !items_copy[i].selected;
    setItems(items_copy);
    let current_answer = [...surveyDetails.current_answer];
    // eslint-disable-next-line
    items_copy.map((item, i) => {
      if (items_copy[i].selected) {
        let copy = {};
        val++;
        copy[items_copy[i].label] = info[i] === undefined ? "" : info[i];
        copy["type"] = props.question.question_type.name;
        current_answer[i] = copy;
      } else {
        let copy = {};
        infoCopy[i] = "";
        copy["type"] = props.question.question_type.name;
        current_answer[i] = copy;
      }
    });
    // eslint-disable-next-line
    current_answer.map((current_answer) => {
      // eslint-disable-next-line
      for (let [key, value] of Object.entries(current_answer)) {
        if (key !== "type") {
          copyValue.push(key);
        }
      }
    });
    setInfo(infoCopy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer,
      optionCount: val,
      answer: copyValue.join(","),
    }));
  };
  return (
    <div className="mt-6">
      {items &&
        items.map((option, i) => (
          <div className="block">
            <div
              className={`border rounded p-4 mb-4 text-h6 w-85 lg:w-96 ${
                option.selected
                  ? "border-orange text-orange"
                  : "border-bgLite text-textMedium"
              }`}
              key={i}
              onClick={() => handleOnClick(option.label, i)}
            >
              {option.label}
            </div>
            {option.selected && option.more_info && (
              <Input
                style={{ width: "85%" }}
                className="mb-4 lg:ml-14 ml-8"
                value={info[i]}
                placeholder={option.more_info_label}
                onChange={(e) => handleText(e.target.value, i)}
              />
            )}
          </div>
        ))}
    </div>
  );
};

export default MultipleChoice;
