import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import ReminderMail from "./contents/reminderMail";
import SurveyMail from "./contents/surveyMail";

const PreviewContainer = styled.div`
  max-height: calc(100vh - 180px);
  overflow: scroll;
`;
export default function Preview(props) {
  const location = useLocation();
  const [showInviteMail, setshowInviteMail] = React.useState(false);
  React.useEffect(() => {
    if (location.pathname.includes("inviteemail")) {
      setshowInviteMail(true);
    } else {
      setshowInviteMail(false);
    }
  }, [location.pathname]);
  return (
    <PreviewContainer>
      {showInviteMail && <SurveyMail template={props.template}/>}
      {!showInviteMail && <ReminderMail template={props.template}/>}
    </PreviewContainer>
  );
}
