import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Space, Input } from "antd";
import { useHistory, useParams } from "react-router";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import { GET_MEMBERS_LIST } from "../graphql";
import ColumnEditor from "../../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData } from "../../../components/memberColumns"
import MemberTable from "../../../components/memberTable";

function MemberListTab() {
  const { surveyID, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const [role, setRole] = useState(null);
  const savedviewProjectPrfList = localStorage.getItem('viewProjectPrfList');
  const viewProjectPrfListinitial = savedviewProjectPrfList
    ? JSON.parse(savedviewProjectPrfList)
    : {
      ...memberColumnStatus,
      "quota": { enabled: true, label: "Quota" }
    }
  const [columnStatus, setStatus] = useState(viewProjectPrfListinitial);

  let searchInput;
  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  useEffect(() => {
    localStorage.setItem('viewProjectPrfList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.quota = item.survey_x_members[0].quota;
        item.action = item.id;
      });
      setTableData(object);
    }
  }, [members]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
            : text}
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? text === "Not updated"
              ? "Not updated"
              : `${text.substring(0, 3)}XXXXXXX`
            : text}
        </div>
      ),
    },
    {
      title: "QUOTA",
      dataIndex: "quota",
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("quota"),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              history.push(
                `/viewSurvey/members/project/${projectID}/survey/${surveyID}/PRF/viewDetailedResponse/member/${record}`
              );
            }}
          >
            View response
          </Button>
        );
      },
    },
  ];*/

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),
    ...[{
      title: "QUOTA",
      dataIndex: "quota",
      isShow: columnStatus?.quota?.enabled,
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("quota"),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              history.push(
                `/viewSurvey/members/project/${projectID}/survey/${surveyID}/PRF/viewDetailedResponse/member/${record}`
              );
            }}
          >
            View response
          </Button>
        );
      },
    }]]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus])

  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">
        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setStatus(column)
        }} />
      </div>
      <MemberTable
        columns={memColumns}
        tableData={tableData}
      >
      </MemberTable>
    </div>
  );
}
export default MemberListTab;
