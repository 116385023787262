import React, { useEffect, useState } from "react";
import { Tabs, Layout, Tag, Button, Row, Col } from "antd";
import LocationHierarchy from "../../components/locationHierarchy";
import Preview from "../createSurvey/prf/questionnaire/preview";
import PreviewExternal from "../createSurvey/external/questionnaire/preview";
import { useMutation, useQuery } from "@apollo/client";
import { VIEW_PROJECT_DETAILS, CLOSE_PROJECT, MEMBER_TYPE } from "./graphql";
import { LeftOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import ViewSurveyTab from "./viewSurvey";
import UserView from "./users";
import FinancialDetails from "./financialDetails";
import ProjectDetails from "./projectDetails";
import MemberListTab from "./prf/membersList";
import ExternalMemberListTab from "./external/membersList";
import OnlineMemberListTab from "./online/membersList";
import OnlineClientMemberListTab from "./onlineClient/membersList";
import OnlineAssistedMemberListTab from "./assisted/membersList";
import OnlineResponsesClientTab from "./onlineClient/responses";
import PRFResponsesTab from "./prf/responses";
import ExternalResponsesTab from "./external/responses";
import OnlineResponsesTab from "./online/responses";
import ViewDetailedResponse from "./prf/viewDetailedResponse";
import PRFViewResponseQA from "./prf/viewResponseQA";
import ExternalViewResponseQA from "./external/viewResponseQA";
import OnlineViewResponseQA from "./online/viewResponseQA";
import RewardsTab from "./rewards";
import MemberStatsData from "../../components/MemberStatsData";

const { Content } = Layout;
const { TabPane } = Tabs;

function ViewProject() {
  const { surveyID, projectID, surveyType } = useParams();
  const [memberName, setMemberName] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [showDoc, setShowDoc] = useState();
  const tabPane = [
    "projectDetails",
    "user",
    "financial-details",
    "surveyOverview",
    "questionnaire",
    "members",
    "responses",
    "rewards",
  ];
  const { data: memberNames } = useQuery(MEMBER_TYPE, {
    fetchPolicy: "network-only",
  });
  const { data, loading, error } = useQuery(VIEW_PROJECT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      project_id: parseInt(projectID),
      survey_id: surveyID ? parseInt(surveyID) : 0,
    },
  });

  const [closeProject] = useMutation(CLOSE_PROJECT);

  useEffect(() => {
    if (location.pathname.split("/")[2] === "surveyOverview") {
      setActiveTabPane("4");
    } else if (location.pathname.split("/")[2] === "questionnaire") {
      setActiveTabPane("5");
    } else if (location.pathname.split("/")[2] === "members") {
      setActiveTabPane("6");
    } else if (location.pathname.split("/")[2] === "responses") {
      setActiveTabPane("7");
    } else if (location.pathname.split("/")[2] === "project") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[2] === "financial-details") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[2] === "projectDetails") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[2] === "rewards") {
      setActiveTabPane("8");
    }
  }, [location.pathname]);

  useEffect(() => {
    let copy = [...memberName];

    if (data?.project[0]) {
      if (data?.project[0]?.documents !== null) {
        if (data?.project[0]?.documents[1]?.fileList.length === 0) {
          setShowDoc(false);
        } else {
          setShowDoc(true);
        }
      }
      if (memberNames) {
        data?.project[0]?.member_types?.forEach((id) => {
          memberNames?.member_type?.forEach((element) => {
            if (id === element.id) {
              copy.push(element.name);
              return;
            }
          });
        });
      }
    }
    setMemberName(copy);
    // eslint-disable-next-line
  }, [data, memberNames]);

  const handleActiveTabPane = (key) => {
    surveyID
      ? history.push(
        `/viewProject/${tabPane[key - 1]
        }/project/${projectID}/survey/${surveyID}/${surveyType}`
      )
      : history.push(
        `/viewProject/${tabPane[key - 1]}/project/${projectID}/${surveyType}`
      );
    setActiveTabPane(key);
  };

  if (loading) {
    return null;
  }
  if (error) {
    return null;
  }

  return (
    <Layout className="bg-background w-full">
      <Content className="px-10 pb-10">
        {(location.pathname.split("/")[8] === "viewDetailedResponse" ||
          location.pathname.split("/")[8] === "viewResponseAnswers") && (
            <LeftOutlined
              style={{ margin: "20px 5px", color: "orange" }}
              className="float-left"
              onClick={() => {
                history.goBack();
              }}
            />
          )}
        <LocationHierarchy
          id={data?.project[0]?.id}
          sampleSize={data?.project[0]?.sample}
          completionCount={
            data?.project[0]?.surveys[0]?.survey_x_members_aggregate?.aggregate
              ?.count
          }
          projectType={
            location.pathname.split("/")[5] === "survey"
              ? location.pathname.split("/")[7]
              : location.pathname.split("/")[5]
          }
          status={data?.project[0]?.status}
        />
        <div className="absolute text-sm text-textLight pt-4">
          <p>
            ID#{data?.project[0]?.id}
            <span className="ml-4 ">
              {data?.project[0]?.surveys[0]?.survey_x_members_aggregate
                ?.aggregate?.count >= parseInt(data?.project[0]?.sample) &&
                data?.project[0]?.status === "active" && (
                  <Tag className="text-base text-orange " color="#FFEED5">
                    Closure pending
                  </Tag>
                )}
              {data?.project[0]?.surveys[0]?.survey_x_members_aggregate
                ?.aggregate?.count < parseInt(data?.project[0]?.sample) &&
                data?.project[0]?.status === "active" && (
                  <Tag className="text-base text-blue " color="#DDF1FF">
                    Active
                  </Tag>
                )}
              {data?.project[0]?.surveys?.length === 0 &&
                data?.project[0]?.status === "active" && (
                  <Tag className="text-base text-blue " color="#DDF1FF">
                    Active
                  </Tag>
                )}
              {data?.project[0]?.status === "draft" && (
                <Tag className="text-base text-Yellow " color="#FFF7DA">
                  Draft
                </Tag>
              )}
              {data?.project[0]?.status === "re-opened" && (
                <Tag className="text-base text-magenta " color="#ffc9fd">
                  Re-Opened
                </Tag>
              )}
              {data?.project[0]?.status === "completed" && (
                <Tag className="text-base text-green " color="#D9FBEA">
                  Completed
                </Tag>
              )}
            </span>
            <span className="ml-4 ">
              {/* {data?.project[0]?.surveys[0]?.status === "completed" &&
                data?.project[0]?.status === "active" && (
                  <Tag className="text-base text-orange " color="#FFEED5">
                    Closure pending
                  </Tag>
                )} */}
            </span>
            <span>
              {data?.project[0]?.status === "active" && (
                <>
                  {(data?.project[0]?.surveys.length === 0 || data?.project[0]?.surveys[0]?.status === "completed") &&
                    data?.project[0]?.status !== "completed" && (
                      <Button
                        type="primary"
                        className="rounded"
                        onClick={() => {
                          closeProject({
                            variables: {
                              project_id: parseInt(projectID),
                            },
                          }).then(() => {
                            window.location.reload();
                          });
                        }}
                      >
                        Close Project
                      </Button>
                    )}
                </>
              )}
            </span>
          </p>
          <p className="text-xl text-textDark">{data?.project[0]?.name}</p>
          <p>
            {location.pathname.split("/")[8] === "viewDetailedResponse" && (
              <p className="text-base text-textLight">
                {data?.project[0]?.surveys[0]?.name}
              </p>
            )}
          </p>
        </div>
        <Row className=" w-full mt-24">
          <Col span={18}>
            <div className=" flex gap-6">
              <div className=" shadow-lg bg-white rounded-md flex justify-between gap-10">
                <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow rounded-l-lg p-6">
                  <MemberStatsData
                    label={"Links sent"}
                    totalCount={
                      data?.project[0]?.surveys[0]?.totalLink?.aggregate?.count
                    }
                  />
                </div>
                <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6">
                  <MemberStatsData
                    label={"Completed"}
                    totalCount={
                      data?.project[0]?.surveys[0]?.completed?.aggregate?.count
                    }
                    totalPercentage={
                      data?.project[0]?.surveys[0]?.totalLink?.aggregate?.count
                    }
                  />
                </div>
                <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6">
                  <MemberStatsData
                    label={"In progress"}
                    totalCount={
                      data?.project[0]?.surveys[0]?.inprogress?.aggregate?.count
                    }
                    totalPercentage={
                      data?.project[0]?.surveys[0]?.totalLink?.aggregate?.count
                    }
                  />
                </div>
                <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow rounded-r-lg p-6">
                  <MemberStatsData
                    label={"Screened"}
                    totalCount={
                      data?.project[0]?.surveys[0]?.screened?.aggregate?.count
                    }
                    totalPercentage={
                      data?.project[0]?.surveys[0]?.totalLink?.aggregate?.count
                    }
                  />
                </div>
              </div>
              <div className=" w-40 shadow-lg bg-white rounded-md p-6 flex-shrink-0">
                <MemberStatsData
                  label={"Response rate"}
                  totalCount={(
                    (data?.project[0]?.surveys[0]?.completed?.aggregate?.count +
                      data?.project[0]?.surveys[0]?.inprogress?.aggregate
                        ?.count +
                      data?.project[0]?.surveys[0]?.screened?.aggregate
                        ?.count) /
                    data?.project[0]?.surveys[0]?.totalLink?.aggregate
                      ?.count || 0
                  ).toFixed(2)}
                />
              </div>
              <div className=" w-40  shadow-lg bg-white rounded-md p-6 flex-shrink-0">
                <MemberStatsData
                  label={"Timeout"}
                  totalCount={(
                    data?.project[0]?.surveys[0]?.inprogress?.aggregate?.count /
                    (data?.project[0]?.surveys[0]?.completed?.aggregate
                      ?.count +
                      data?.project[0]?.surveys[0]?.inprogress?.aggregate
                        ?.count +
                      data?.project[0]?.surveys[0]?.screened?.aggregate
                        ?.count) || 0
                  ).toFixed(2)}
                />
              </div>
            </div>
          </Col>
        </Row>
        {location.pathname.split("/")[8] === "viewDetailedResponse" && (
          <div>
            <ViewDetailedResponse projectData={data?.project[0]} />
          </div>
        )}
        {tabPane.includes(location.pathname.split("/")[2]) &&
          location.pathname.split("/")[8] !== "viewDetailedResponse" &&
          location.pathname.split("/")[8] !== "viewResponseAnswers" && (
            <div
              style={{ padding: "24px" }}
              className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full mt-5 shadow-lg rounded-md"
            >
              <Tabs
                tabPosition="top"
                size="large"
                className="text-textLight"
                activeKey={activeTabPane}
                onChange={handleActiveTabPane}
              >
                <TabPane tab="Project Details" key="1">
                  <ProjectDetails
                    projectData={data?.project[0]}
                    showDoc={showDoc}
                    memberName={memberName}
                  />
                </TabPane>
                <TabPane tab="Users" key="2">
                  <UserView
                    userDetails={data?.project[0]?.manager_x_projects}
                  />
                </TabPane>
                <TabPane tab="Financial Details" key="3">
                  <FinancialDetails projectData={data?.project[0]} />
                </TabPane>
                <TabPane
                  tab="Survey Overview"
                  key="4"
                  disabled={surveyID ? false : true}
                >
                  {data?.project[0]?.surveys.length !== 0 && (
                    <ViewSurveyTab
                      surveyData={data?.project[0]?.surveys}
                      sampleSize={data?.project[0]?.sample}
                      projectType={
                        data?.project[0]?.projectTypeByProjectType?.name
                      }
                    />
                  )}
                </TabPane>
                {location.pathname.split("-")[1] !== "Client" &&
                  location.pathname.split("-")[1] !== "Third_Party_survey" && (
                    <TabPane
                      tab="Questionnaire"
                      key="5"
                      disabled={surveyID ? false : true}
                    >
                      {(data?.project[0]?.projectTypeByProjectType?.name ===
                        "PRF" ||
                        data?.project[0]?.projectTypeByProjectType?.name ===
                        "Online" ||
                        data?.project[0]?.projectTypeByProjectType?.name ===
                        "Assisted") && <Preview viewcollapse={false} />}
                      {data?.project[0]?.projectTypeByProjectType?.name ===
                        "External" && <PreviewExternal />}
                    </TabPane>
                  )}
                <TabPane
                  tab="Members"
                  key="6"
                  disabled={surveyID ? false : true}
                >
                  {data?.project[0]?.surveys.length !== 0 &&
                    data?.project[0]?.projectTypeByProjectType?.name ===
                    "PRF" && <MemberListTab />}
                  {data?.project[0]?.surveys.length !== 0 &&
                    data?.project[0]?.projectTypeByProjectType?.name ===
                    "External" && <ExternalMemberListTab />}
                  {data?.project[0]?.surveys.length !== 0 &&
                    (
                      location.pathname.split("/")[7].toLowerCase() === "online" ||
                      location.pathname.split("/")[7].toLowerCase() === "online-krea"
                    ) && (
                      <OnlineMemberListTab />
                    )}
                  {data?.project[0]?.surveys.length !== 0 &&
                    (location.pathname.split("/")[7] === "Online-Client" ||
                      location.pathname.split("/")[7] ===
                      "Online-Third_Party_survey") && (
                      <OnlineClientMemberListTab
                        projectType={
                          data?.project[0]?.projectTypeByProjectType?.name
                        }
                      />
                    )}
                  {data?.project[0]?.surveys.length !== 0 &&
                    location.pathname.split("/")[7] === "Assisted-Krea" && (
                      <ExternalMemberListTab />
                    )}
                  {data?.project[0]?.surveys.length !== 0 &&
                    (location.pathname.split("/")[7] === "Assisted-Client" ||
                      location.pathname.split("/")[7] ===
                      "Assisted-Third_Party_survey") && (
                      <OnlineAssistedMemberListTab />
                    )}
                </TabPane>
                <TabPane
                  tab="Responses"
                  key="7"
                  disabled={surveyID ? false : true}
                >
                  {data?.project[0]?.projectTypeByProjectType?.name ===
                    "PRF" && <PRFResponsesTab name={data?.project[0]?.name} />}
                  {data?.project[0]?.projectTypeByProjectType?.name ===
                    "External" && <ExternalResponsesTab />}
                  {((location.pathname.split("/")[7] === "Online-Krea" &&
                    "Online") ||
                    location.pathname.split("/")[7] === "Assisted-Krea") && (
                      <OnlineResponsesTab />
                    )}
                  {(location.pathname.split("/")[7] === "Online-Client" ||
                    location.pathname.split("/")[7] ===
                    "Online-Third_Party_survey" ||
                    location.pathname.split("/")[7] ===
                    "Assisted-Third_Party_survey" ||
                    location.pathname.split("/")[7] === "Assisted-Client") && (
                      <OnlineResponsesClientTab />
                    )}
                </TabPane>
                <TabPane
                  tab="Rewards"
                  key="8"
                  disabled={surveyID ? false : true}
                >
                  <RewardsTab />
                </TabPane>
              </Tabs>
            </div>
          )}
        {location.pathname.split("/")[8] === "viewResponseAnswers" &&
          location.pathname.split("/")[7] === "PRF" && <PRFViewResponseQA />}
        {location.pathname.split("/")[8] === "viewResponseAnswers" &&
          location.pathname.split("/")[7] === "External" && (
            <ExternalViewResponseQA />
          )}
        {location.pathname.split("/")[8] === "viewResponseAnswers" &&
          location.pathname.split("/")[7] === "Online-Krea" && (
            <OnlineViewResponseQA />
          )}
        {location.pathname.split("/")[8] === "viewResponseAnswers" &&
          location.pathname.split("/")[7] === "Assisted-Krea" && (
            <OnlineViewResponseQA />
          )}
      </Content>
    </Layout>
  );
}

export default ViewProject;
