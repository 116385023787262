import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Form, notification } from "antd";
import { useHistory, useParams } from "react-router";
import "./selectMembers.css";
import { projectForm } from "../../../../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  GET_MEMBERS,
  GET_ASSIGNED_MEMBERS,
  SURVEYxMEMBERS,
  UPDATE_SURVEY_STATUS,
  DELETE_SURVEY_X_MEMBERS,
  INSERT_INTERVIEWER,
  PROJECT_DETAILS,
} from "../graphql";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../../components/memberColumns";
import { RoleContext } from "../../../../context/RoleContext";
import MemberTable from "../../../../components/memberTable";
import ViewActiveButton from "../../../../components/viewActiveButton";
import SpecialityModalPop from "../../../../utils/specialitySearch";

function SelectMembers(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const location = useLocation();
  const currentTime = moment().format();
  const surveyDetails = useRecoilValue(projectForm);
  const setValues = useSetRecoilState(projectForm);
  const memberListDetails = useRecoilValue(projectForm);
  const [tableData, setTableData] = useState([]);
  const [selectedKeyRow, setSelectedKeys] = useState([]);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const { role } = useContext(RoleContext);
  const [insertmembers] = useMutation(SURVEYxMEMBERS);
  const [deleteMembers] = useMutation(DELETE_SURVEY_X_MEMBERS);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  const [insertInterviewer] = useMutation(INSERT_INTERVIEWER);
  const [memberType, setMemberType] = useState([]);
  const savedexternalSelect = localStorage.getItem('externalSelect');
  const externalSelectinitial = savedexternalSelect
    ? JSON.parse(savedexternalSelect)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(externalSelectinitial);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const {
    data: assignedMembers,
    loading,
    refetch,
  } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "no-cache",
  });

  const { data: members } = useQuery(GET_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "network-only",
  });

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      let obj = {};
      let newarray = [];
      res.member_type.map((item) => {
        obj[item.id] = item.name;
      });
      res.project[0].member_types.map((type) => {
        newarray.push(obj[type]);
      });
      setMemberType(newarray);
    },
  });

  useEffect(() => {
    localStorage.setItem('external', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        let array = [];
        let leavearray = [];
        item.survey_x_members.length !== 0 &&
          // eslint-disable-next-line
          item.survey_x_members.map((member, index) => {
            let memberArray = [];
            let statusArray = [];
            member.survey_x_member_x_interviewers.length !== 0 &&
              // eslint-disable-next-line
              member.survey_x_member_x_interviewers.map((interviewID, i) => {
                statusArray[i] = interviewID.interviewer_on_leave;
                memberArray[i] = interviewID.interviewer_id;
              });
            if (memberArray.length > 0) {
              leavearray = statusArray;
              array = memberArray;
            }
          });
        item.action = array;
        item.leavearray = leavearray;
        return item;
      });
      let newList = object.filter((item) => {
        return memberType.includes(item.category);
      });

      setTableData(newList);
    }
  }, [members, memberType]);

  useEffect(() => {
    let obj = {};
    refetch();
    if (!loading) {
      if (memberListDetails.memberListDetails) {
        setSelectedKeys(
          memberListDetails?.memberListDetails?.selectedRowKeysSTORE
        );
      } else if (
        assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0
      ) {
        // eslint-disable-next-line
        let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.id;
          let status =
            el.survey_x_member_x_interviewers.length > 0
              ? el.survey_x_member_x_interviewers[0].status
              : "active";
          obj[id] = { status };
          return el.user.id;
        });

        let object,
          newObject = [];
        object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
        object.forEach((item) => {
          let array = [];
          let leavearray = [];
          item.user.genderNew =
            item?.user?.gender === null ? "Not updated" : item?.user?.gender;
          item.user.category = item.user.member_type.name;
          item.user.memberName =
            item?.user?.l_name !== null
              ? item?.user?.f_name + " " + item?.user?.l_name
              : item?.user?.f_name;
          // eslint-disable-next-line
          item.survey_x_member_x_interviewers.map((interviewID, index) => {
            leavearray.push(interviewID.interviewer_on_leave);
            array.push(interviewID.interviewer_id);
          });
          item.user.leavearray = leavearray;
          item.user.action = array;
        });
        object.forEach((item) => {
          newObject.push(item.user);
        });
        setSelectedKeys(selectedArray);

        setValues((oldValues) => ({
          ...oldValues,
          memberListDetails: {
            selectedRowKeysSTORE: selectedArray,
            selectedRowsSTORE: newObject,
          },
          updateMembersDetails: {
            updateMembers: true,
            memberList: assignedMembers,
            status: obj,
          },
        }));
      }
    }
    // eslint-disable-next-line
  }, [memberListDetails, assignedMembers, location]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleClick = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let interviewerList = [];
      let leaveList = [];
      let interviewerID = [];
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          payload.push({
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            quota: 1,
          });
        }
      );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          el.survey_x_member_x_interviewers &&
            // eslint-disable-next-line
            el.survey_x_member_x_interviewers.map((key) => {
              interviewerID.push(key.id);
            });
        });
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       item.survey_x_member_x_interviewers &&
      //         item.survey_x_member_x_interviewers.map((key) => {
      //           interviewerID.push(key.id);
      //         });
      //     }
      //   );
      memberListDetails?.memberListDetails?.selectedRowsSTORE?.forEach(
        (item, index) => {
          if (item.action.length !== 0) {
            item.action[0].length > 1
              ? (interviewerList[index] = item.action[0])
              : (interviewerList[index] = item.action);
          } else {
            interviewerList[index] = [];
          }
        }
      );
      memberListDetails?.memberListDetails?.selectedRowsSTORE?.forEach(
        (item, index) => {
          if (item.leavearray.length !== 0) {
            item.leavearray[0].length > 1
              ? (leaveList[index] = item.leavearray[0])
              : (leaveList[index] = item.leavearray);
          } else {
            leaveList[index] = [];
          }
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            Interviewerarray: interviewerID,
            object: payload,
          },
        }).then((res) => {
          let object = [];
          // eslint-disable-next-line
          res.data.insert_survey_x_member.returning.map((key, index) => {
            interviewerList &&
              interviewerList.length !== 0 &&
              // eslint-disable-next-line
              interviewerList[index].map((interviewer, i) => {
                object.push({
                  survey_x_member_id: key.id,
                  status: key.state,
                  interviewer_id: interviewer,
                  interviewer_on_leave: leaveList[index][i],
                  priority: i + 1,
                });
              });
          });
          insertInterviewer({
            variables: {
              objects: object,
            },
          }).then(() => {
            updateSurvey({
              variables: {
                id: parseInt(surveyID),
                status: moment().isSameOrAfter(props.startDate)
                  ? props.status === "draft"
                    ? "draft"
                    : "active"
                  : props.status === "active"
                    ? "active"
                    : "draft",
              },
            }).then(() => {
              setValues((oldValues) => ({
                ...oldValues,
                updateMembersDetails: {
                  updateMembers: true,
                },
              }));
              // props.handleToggle(true);
              let editOrCreate = location.pathname.split("/")[3];

              history.push(
                `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`
              );
            });
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            let editOrCreate = location.pathname.split("/")[3];

            history.push(
              `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`
            );
            // props.handleToggle(true);
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleDraft = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let interviewerList = [];
      let interviewerID = [];
      let leaveList = [];
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          payload.push({
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            quota: 1,
          });
        }
      );
      memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
          (item, index) => {
            item.survey_x_member_x_interviewers &&
              // eslint-disable-next-line
              item.survey_x_member_x_interviewers.map((key) => {
                interviewerID.push(key.id);
              });
          }
        );
      memberListDetails?.memberListDetails?.selectedRowsSTORE?.forEach(
        (item, index) => {
          if (item.action.length !== 0) {
            item.action[0].length > 1
              ? (interviewerList[index] = item.action[0])
              : (interviewerList[index] = item.action);
          } else {
            interviewerList[index] = [];
          }
        }
      );
      memberListDetails?.memberListDetails?.selectedRowsSTORE?.forEach(
        (item, index) => {
          if (item.leavearray.length !== 0) {
            item.leavearray[0].length > 1
              ? (leaveList[index] = item.leavearray[0])
              : (leaveList[index] = item.leavearray);
          } else {
            leaveList[index] = [];
          }
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            Interviewerarray: interviewerID,
            object: payload,
          },
        }).then((res) => {
          let object = [];
          // eslint-disable-next-line
          res.data.insert_survey_x_member.returning.map((key, index) => {
            interviewerList &&
              interviewerList.length !== 0 &&
              // eslint-disable-next-line
              interviewerList[index].map((interviewer, i) => {
                object.push({
                  survey_x_member_id: key.id,
                  status: key.state,
                  interviewer_id: interviewer,
                  interviewer_on_leave: leaveList[index][i],
                  priority: i + 1,
                });
              });
          });
          insertInterviewer({
            variables: {
              objects: object,
            },
          }).then(() => {
            updateSurvey({
              variables: {
                id: parseInt(surveyID),
                status: "draft",
              },
            }).then(() => {
              // resetMemberDetails();
              notification.success({
                message: "Success!",
                description: "Saved as draft!",
              });
              history.push("/projectList/active");
            });
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            // resetMemberDetails();
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          });
        });
      }
    } else {
      setShowError(true);
    }
  };


  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const columns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
      setValues((oldValues) => ({
        ...oldValues,
        memberListDetails: {
          selectedRowKeysSTORE: selectedRowKeys,
          selectedRowsSTORE: selectedRows,
        },
      }));
    },

    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedKeyRow,
  };
  return (
    <div className="selectMembers px-10 py-4 w-full">
      <div className="my-4 text-h5 text-textMedium flex justify-between ">
        Select Members
        <div className=" flex ">
          {!!selectedKeyRow?.length && (
            <ViewActiveButton memberId={selectedKeyRow} />
          )}

          <ColumnEditor
            columns={columnStatus}
            onSave={(column) => {
              setStatus(column);
            }}
          />
        </div>
      </div>
      <Form>
        <MemberTable
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          columns={columns}
          dataSource={tableData}
          rowClassName={(record, index) => "text-sm text-textMedium"}
          pagination={false}
        />

        <SpecialityModalPop
          titleSpec={`Selected ${selectedColumnNameSpec}`}
          visibleSpec={isModalVisibleSpec}
          onOkSpec={handleModalOkSpec}
          onCancelSpec={handleModalCloseSpec}
          searchQuerySpec={searchQuerySpec}
          handleSearchSpec={handleSearchSpec}
          handleCheckboxChangeSpec={handleCheckboxChangeSpec}
          filteredCheckboxDataSpec={filteredCheckboxDataSpec}
          selectedDataSpec={selectedDataSpec}
          initialSelectedDataSpec={initialSelectedDataSpec}
          isSubmitDisabledSpec={isSubmitDisabledSpec}
          handleResetSpec={handleResetSpec}
        />
        <Form.Item className="float-right mt-20">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => {
              const surveyStartDate =
                surveyDetails.surveyDetails &&
                surveyDetails.surveyDetails.start_date;
              if (moment(surveyStartDate).isSameOrAfter(currentTime)) {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}`
                  )
                  : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}`
                  );
              } else {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                  )
                  : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                  );
              }
            }}
          >
            Back
          </Button>
          <Button
            htmlType="submit"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
                props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-28"
            onClick={handleClick}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
      {showError ? (
        <p style={{ color: "red" }}>Please select atleast one member</p>
      ) : null}
    </div>
  );
}
export default SelectMembers;
