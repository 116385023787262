import { useState } from "react";
import { Form, Button, List, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import { GET_QUESTIONS } from "./graphql";
import { useLocation } from "react-router-dom";
import { UPDATE_SURVEY_STATUS } from "../graphql";
import moment from "moment";

function Preview(props) {
  const history = useHistory();
  const location = useLocation();
  const { surveyID, projectID, surveyType } = useParams();
  const [data, setData] = useState();
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  // eslint-disable-next-line
  const { dataSource1 } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setData(data.questions);
    },
  });
  const handleClick = () => {
    if (!props.isStarted) {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
          );
    } else {
      // props.handleToggle("addLogic");
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}`
          );
    }
  };

  const handleFormValues = (buttonAction) => {
    updateSurvey({
      variables: {
        id: parseInt(surveyID),
        status:
          buttonAction === "draft"
            ? "draft"
            : moment().isSameOrAfter(props.startDate)
            ? props.status === "draft"
              ? "draft"
              : "active"
            : props.status === "active"
            ? "active"
            : "draft",
      },
    }).then(() => {
      // props.handleEnable(true);
      if (buttonAction === "active") {
        location.pathname.split("/")[3] === "edit-survey"
          ? history.push(
              `/project/${projectID}/edit-survey/${surveyType}/assignmembers/survey/${surveyID}`
            )
          : history.push(
              `/project/${projectID}/create-survey/${surveyType}/assignmembers/survey/${surveyID}`
            );
      } else {
        notification.success({
          message: "Success!",
          description: "Saved as draft!",
        });
        history.push(`/projectList/active`);
      }
    });
  };

  const handleNext = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  const GetFields = () => {
    return (
      <Form className="w-full">
        <Form.Item className="block">
          <List
            itemLayout="horizontal"
            dataSource={data}
            rowKey={(item) => item?.id}
            renderItem={(item, index) => (
              <div className="border border-borderGray rounded-md my-4 p-4">
                <div>
                  <span className="text-base">Q{index + 1}</span>
                  <span className="ml-4 text-base">{item.question}</span>
                </div>
                <div className="py-4 px-9">
                  <p>
                    <span className="text-base text-textLight">File type:</span>
                    <span className="ml-2">
                      {item &&
                        item.questions_options.length !== 0 &&
                        item.questions_options[0].options.fileUploadValue.fileTypesAllowed.join(
                          ", "
                        )}
                    </span>
                  </p>
                  <p>
                    <span className="text-base text-textLight">
                      Filesize Limit:
                    </span>
                    <span className="ml-2">
                      {item &&
                        item.questions_options.length !== 0 &&
                        item.questions_options[0].options.fileUploadValue
                          .fileSize}
                    </span>
                  </p>
                </div>
              </div>
            )}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Form name="create survey">
      <GetFields />
      {props.enableButton === true && (
        <Form.Item className="float-right mt-8">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={handleClick}
          >
            Back
          </Button>
          <Button
            htmlType="button"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
              props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-28"
            onClick={handleNext}
          >
            Next
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
export default Preview;
