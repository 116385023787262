import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Slider } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const SliderComponent = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [markValue, setMarkValue] = useState([]);
  const [answer, setAnswer] = useState([]);
  const [boolean, setBoolean] = useState(false);
  const [data, setData] = useState([]);
  const [steps, setSteps] = useState();

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  function getKeyByValue(object, value) {
    if (object !== undefined && value !== undefined)
      return Object.keys(object).find((key) => object[key] === value);
  }

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy.push(value);
        }
      }
    });
    setAnswer(copy);
  }, [answers]);

  React.useEffect(() => {
    let copy = [];
    let newstep;
    // eslint-disable-next-line
    data?.map((ele, i) => {
      let obj = {};
      let length =
        ele.options.sliderValue.sliderText !== null &&
        ele.options.sliderValue.sliderText.length;
      newstep = 100 / (length - 1);
      let point = 0;

      ele.options.sliderValue.sliderText !== null &&
        ele.options.sliderValue.sliderText.forEach((value, index) => {
          if (index + 1 === length) {
            point = 100;
          } else if (index === 0) {
            point = 0;
          } else {
            point += newstep;
          }
          obj = { ...obj, [point]: value };
        });
      copy[i] = obj;
    });
    setSteps(newstep);
    setMarkValue(copy);
    let current_answer = [];
    // eslint-disable-next-line
    props.questions_options.map((option, i) => {
      if (
        option?.options?.sliderValue?.sliderType &&
        option?.options?.sliderValue?.sliderType === "numerical"
      ) {
        let copy = {};
        copy[option.label] = answer[i]
          ? answer[i]
          : option?.options?.sliderValue?.sliderMinimum;
        copy["type"] = props.question.question_type.name;
        current_answer[i] = copy;
        // current_answer[option.label] =

        setBoolean(true);
      } else {
        let copy = {};
        copy[option.label] = answer[i]
          ? answer[i]
          : option?.options?.sliderValue?.sliderText[0];
        copy["type"] = props.question.question_type.name;
        current_answer[i] = copy;
        setBoolean(false);
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer,
    }));
    // eslint-disable-next-line
  }, [data, answer]);

  const handleOnChange = (value, label, i, sliderType) => {
    let current_answer_copy = [...surveyDetails.current_answer];

    let ans_copy = [...answer];
    // ans_copy[i] = value;
    if (sliderType === "numerical") {
      let copy = {};
      copy[label] = value;
      ans_copy[i] = value;
      copy["type"] = props.question.question_type.name;
      current_answer_copy[i] = copy;
      setBoolean(true);
    } else {
      let mark = markValue[i];
      let copy = {};
      copy[label] = mark[value];
      ans_copy[i] = mark[value];
      copy["type"] = props.question.question_type.name;

      current_answer_copy[i] = copy;
      setBoolean(true);
    }
    setAnswer(ans_copy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer_copy,
    }));
  };
  return (
    <div className="mt-6">
      {props.questions_options.map((option, i) => (
        <div className="flex flex-col items-start justify-start mb-4">
          {option.label && (
            <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
              {option.label}
            </span>
          )}
          {option.options.sliderValue.sliderType === "numerical" && (
            <div className="lg:block">
              <span className="float-left mr-4 mt-1">
                {option.options.sliderValue.sliderMinimum}
              </span>
              <Slider
                className="w-72 lg:w-96 float-left"
                max={
                  option.options.sliderValue.sliderMinimum <
                  option.options.sliderValue.sliderMaximum
                    ? option.options.sliderValue.sliderMaximum
                    : option.options.sliderValue.sliderMinimum || 100
                }
                min={
                  option.options.sliderValue.sliderMinimum <
                  option.options.sliderValue.sliderMaximum
                    ? option.options.sliderValue.sliderMinimum
                    : option.options.sliderValue.sliderMaximum || 0
                }
                reverse={
                  option.options.sliderValue.sliderMinimum <
                  option.options.sliderValue.sliderMaximum
                    ? false
                    : true
                }
                value={answer[i]}
                step={option.options.sliderValue.sliderNumberSteps || null}
                onChange={(value) =>
                  handleOnChange(
                    value,
                    option.label,
                    i,
                    option.options.sliderValue.sliderType
                  )
                }
              />
              <span className="float-left ml-4 mt-1">
                {option.options.sliderValue.sliderMaximum}
              </span>
            </div>
          )}
          {option.options.sliderValue.sliderType === "text" && (
            <Slider
              marks={markValue[i]}
              tipFormatter={null}
              value={
                !boolean ? getKeyByValue(markValue[i], answer[i]) : answer[i]
              }
              className="w-80 lg:w-96"
              step={steps}
              onChange={(value) =>
                handleOnChange(
                  value,
                  option.label,
                  i,
                  option.options.sliderValue.sliderType
                )
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SliderComponent;
