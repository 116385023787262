import React, { useState, useEffect } from "react";
import { Divider, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { RESPONSE_ANSWERS, GET_DOWNLOAD_URL } from "../graphql";
import download from "../../../assets/Icon/download.svg";

function ViewResponseQA() {
  const { surveyID, memberID, patientID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [answerArray, setAnswerArray] = useState([]);
  const [fileUploadArray, setFileUploadArray] = useState([]);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);

  const { data: responseAnswers } = useQuery(RESPONSE_ANSWERS, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(surveyID),
      patient_id: parseInt(patientID),
      member_id: parseInt(memberID),
    },
  });
  const length = responseAnswers?.survey[0]?.patients[0]?.answers.length;

  const handleDownload = (originalName) => {
    const fileDetails = originalName.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];

    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileKey);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    let fileUpload = [...fileUploadArray];
    if (responseAnswers) {
      let copyAnswer = [];
      let object = JSON.parse(JSON.stringify(responseAnswers?.survey));
      object.forEach((item) => {
        item.memberName =
          item.survey_x_members[0].user.f_name +
          " " +
          item.survey_x_members[0].user.l_name;
        item.patients[0].answers.forEach((answer, index) => {
          let ans = [];
          if (answer.answer === "") {
            answer.more_info.forEach((info) => {
              if (info.type === "Date & Time") {
                info?.time
                  ? ans.push(
                      info?.label + " - " + info?.date + " " + info?.time
                    )
                  : ans.push(info?.label + " - " + info?.date);
              } else if (info.type === "Percentage") {
                for (let [key, value] of Object.entries(info)) {
                  if (key !== "type") {
                    value === ""
                      ? ans.push(key)
                      : ans.push(key + " - " + value + "%");
                  }
                }
              } else if (info.type === "File upload") {
                for (let [key, value] of Object.entries(info)) {
                  if (key !== "type") {
                    ans.push(key);
                    fileUpload[index] = value;
                  }
                }
              } else {
                for (let [key, value] of Object.entries(info)) {
                  if (key !== "type") {
                    value === ""
                      ? ans.push(key)
                      : ans.push(key + " - " + value);
                  }
                }
              }
            });
            copyAnswer.push(ans);
          } else {
            let result = answer.answer.split(",");
            // eslint-disable-next-line
            result.map((res) => {
              ans.push(res);
            });

            copyAnswer.push(ans);
          }
        });
      });
      setFileUploadArray(fileUpload);
      setAnswerArray(copyAnswer);
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [responseAnswers]);

  return (
    <>
      <p className="text-base text-textLight absolute top-52">
        {responseAnswers?.survey[0]?.name} | {tableData[0]?.memberName} |
        Response ID - #{responseAnswers?.survey[0]?.patients[0]?.id}
      </p>
      <div
        style={{ padding: "24px" }}
        className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full mt-32 shadow-lg rounded-md"
      >
        <p className="text-xl text-textDark">Survey Response</p>
        {responseAnswers?.survey[0]?.patients[0]?.status === "completed" && (
          <p className="text-green text-base">Completed</p>
        )}
        {responseAnswers?.survey[0]?.patients[0]?.status === "screened" && (
          <p className="text-blue text-base">Screened</p>
        )}
        <Divider />
        {responseAnswers?.survey[0]?.patients[0]?.answers?.map(
          (data, index) => {
            return (
              <div>
                <p className="text-base text-textLight">
                  Q{index + 1}. {data?.question?.question}
                </p>
                <p className="float-left">
                  {answerArray[index] &&
                    answerArray[index]?.length !== 0 &&
                    answerArray[index].map((answer, i) => {
                      return (
                        <p
                          className={
                            index + 1 === length &&
                            responseAnswers?.survey[0]?.patients[0]?.status ===
                              "screened"
                              ? "text-blue text-base pl-8 "
                              : "text-base text-textMedium pl-8 "
                          }
                        >
                          {answer}
                        </p>
                      );
                    })}
                </p>
                {data?.question?.question_type?.name === "File upload" && (
                  <Button
                    type="link"
                    className="mb-8 float-right pr-8"
                    onClick={() => {
                      handleDownload(fileUploadArray[index]);
                    }}
                  >
                    <img src={download} alt="download" />
                  </Button>
                )}
                <Divider />
              </div>
            );
          }
        )}
      </div>
    </>
  );
}
export default ViewResponseQA;
