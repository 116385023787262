import { useState } from "react";
import { Form, Input, Button, List, notification } from "antd";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import dragIcon from "../../../../../assets/dragIcon.svg";
import AddLogic from "./addLogic";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { GET_QUESTIONS } from "./graphql";
import { UPDATE_SURVEY_STATUS } from "../graphql";
import moment from "moment";

function ViewAddLogic(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [expand, setExpand] = useState(true);
  const [data, setData] = useState();
  const [enableLogic, setEnableLogic] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [dataObject, setDataObject] = useState([]);
  const [questionNumber, setQuestionNumber] = useState();
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  // eslint-disable-next-line
  const { getQuestions } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setData(data.questions);
    },
  });
  const [lazyQuery] = useLazyQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setData(data.questions);
    },
  });
  const handleClick = () => {
    // props.handleToggle("questionnaire");
    location.pathname.split("/")[3] === "create-survey"
      ? history.push(
          `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}`
        )
      : history.push(
          `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}`
        );
  };

  const handleFormValues = (buttonAction) => {
    updateSurvey({
      variables: {
        id: parseInt(surveyID),
        status:
          buttonAction === "draft"
            ? "draft"
            : moment().isSameOrAfter(props.startDate)
            ? props.status === "draft"
              ? "draft"
              : "active"
            : props.status === "active"
            ? "active"
            : "draft",
      },
    }).then(() => {
      // props.handleEnable(true);
      if (buttonAction === "active") {
        location.pathname.split("/")[3] === "create-survey"
          ? history.push(
              `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
            )
          : history.push(
              `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
            );
      } else {
        notification.success({
          message: "Success!",
          description: "Saved as draft!",
        });
        history.push(`/projectList/active`);
      }
    });
  };
  const handleNext = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const handleCallback = (value) => {
    lazyQuery();
    setEnableLogic(value);
  };
  const handleAddLogic = (item, index) => {
    let newObject = [];

    if (item.question_type.name === "Dropdown") {
      item.questions_options.map((answer, id) => {
        return newObject.push({
          key: id + 1,
          choice: answer.label,
          id: answer.id,
          whenIf: answer.options.dropdownValue
            ? answer.options.dropdownValue
            : null,
        });
      });
    } else if (item.question_type.name === "Date & Time") {
      item.questions_options.map((answer, id) => {
        return newObject.push({
          key: id + 1,
          choice: answer.label,
          id: answer.id,
          whenIf: answer.options.includeTime
            ? answer.options.includeTime
            : null,
        });
      });
    } else {
      item.questions_options.map((answer, id) => {
        return newObject.push({
          key: id + 1,
          choice: answer.label,
          id: answer.id,
        });
      });
    }
    setDataObject(newObject);
    setEnableLogic(true);
    setSelectedItem(item);
    setQuestionNumber(index + 1);
  };
  const GetFields = () => {
    return (
      <Form className="w-full">
        <Form.Item className="block">
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <div className="p-5 text-base mt-4 ml-0 mr-0 border border-borderGray">
                <div className="h-12">
                  <img src={dragIcon} className="float-left" alt="drag" />
                  {/* <Button
                    type="link"
                    className="float-right text-red"
                    onClick={() => {
                      let copy = [...data];
                      copy.splice(index, 1);
                      setData(copy);
                    }}
                  >
                    Delete
                  </Button> */}
                  {(data[index].question_type.name === "Multiple Choice" ||
                    data[index].question_type.name === "Dropdown" ||
                    data[index].question_type.name === "Percentage" ||
                    data[index].question_type.name === "Date & Time" ||
                    data[index].question_type.name ===
                      "Text box - numerical") && (
                    <Button
                      type="link"
                      className="float-right text-orange"
                      onClick={() => handleAddLogic(item, index)}
                    >
                      {data[index].logic === false || data[index].logic === null
                        ? "Add Logic"
                        : "Edit Logic"}
                    </Button>
                  )}
                </div>
                <div style={{ minHeight: "4.5rem" }}>
                  <span className="float-left">Q{index + 1}</span>
                  <Input.TextArea
                    className="ml-4 float-left "
                    style={{ width: "67%" }}
                    value={item.question}
                    autoSize
                    readOnly
                  />
                  <Input
                    className="float-left ml-8"
                    value={item.question_type.name}
                    style={{ width: "25%" }}
                    readOnly
                  />
                </div>
              </div>
            )}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      {!enableLogic && (
        <Form name="create survey">
          <div span={24} className="text-base w-full ml-0 mr-0 float-left">
            <Button
              type="link"
              size="large"
              onClick={() => {
                setExpand(!expand);
              }}
            >
              {expand ? (
                <div>
                  <span className="w-40 float-left">
                    {" "}
                    Collapse all questions{" "}
                  </span>
                  <CaretUpOutlined />
                </div>
              ) : (
                <div>
                  <span className="w-40 float-left">Expand all questions</span>
                  <CaretDownOutlined />
                </div>
              )}
            </Button>
          </div>
          {expand === true && <GetFields />}
          <Form.Item className="float-right mt-4">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="button"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={handleNext}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      )}
      {enableLogic && (
        <AddLogic
          length={data && data.length}
          selectedItem={selectedItem}
          dataObject={dataObject}
          handleCallback={handleCallback}
          questionNumber={questionNumber}
        />
      )}
    </>
  );
}
export default ViewAddLogic;
