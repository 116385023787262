import React from "react";
import pageNotFound from "../assets/pageNotFound.svg";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const PageNotFound = (props) => {
  const history = useHistory();

  return (
    <div className="pt-32 flex flex-col items-center justify-center">
      <div className="p-6 w-2/3 bg-white shadow rounded flex flex-col items-center justify-center">
        <img src={pageNotFound} alt="error" className="mb-16" />
        <div className="text-h6 text-textLight mb-4">
          Page not found. Please try again
        </div>
        <Button
          type="primary"
          size="large"
          className="px-12 rounded mb-4"
          onClick={() => history.goBack()}
        >
          Go Back
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
