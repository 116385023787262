import { LoadingOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Button, Layout, Spin, Tabs, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import LocationHierarchy from "../../components/locationHierarchy";
import { GET_PROJECT_DETAIL, GET_PROJECT_DETAIL_KREA, GET_USER_DETAILS } from "./graphql";
import MemberDetail from "./memberDetail";
import ProjectDetail from "./projectDetail";
import { LeftOutlined } from "@ant-design/icons";
import jwtDecode from "jwt-decode";
import pageNotFound from "../../assets/pageNotFound.svg";

const { TabPane } = Tabs;

const { Content } = Layout;
const Details = () => {
  const [activeTabPane, setActiveTabPane] = useState("1");
  const location = useLocation();
  const [interviewerID, setInterviewerID] = useState();
  const [showAccessRevoked, setShowAccessRevoked] = useState(false)

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-user-id"];
      setInterviewerID(decode);
    } catch (error) {
      console.log(error);
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location && location.pathname.split("/")[3] === "members") {
      setActiveTabPane("2");
    } //eslint-disable-next-line
  }, []);
  const { id } = useParams();
  const history = useHistory();
  const tabPane = ["overview", "members"];
  const handleActiveTabPane = (key) => {
    history.push(`/project/${id}/${tabPane[key - 1]}`);
    setActiveTabPane(key);
  };

  const { data, loading, error } = useQuery(GET_PROJECT_DETAIL, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  const { data: dataKrea, loading: loadingKrea, error: errorKrea } = useQuery(GET_PROJECT_DETAIL_KREA, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  const { data: userData } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: parseInt(interviewerID),
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data?.project_by_pk) {
      setShowAccessRevoked(data?.project_by_pk?.projectTypeByProjectType?.name === "Online" && !data?.project_by_pk.is_online_or_assisted)
    }
  }, [data])
  useEffect(() => {
    if (dataKrea?.project_by_pk) {
      setShowAccessRevoked(dataKrea?.project_by_pk?.projectTypeByProjectType?.name === "Online" && !dataKrea?.project_by_pk.is_online_or_assisted)
    }
  }, [dataKrea])

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} style={{ textAlign: "center" }} />
      </div>
    );
  }
  if (error) {
    return <p>Error fetching data {error}</p>;
  }

  if (loadingKrea) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} style={{ textAlign: "center" }} />
      </div>
    );
  }
  if (errorKrea) {
    return <p>Error fetching data {errorKrea}</p>;
  }
  
  const memberDetailsData =
    (data?.project_by_pk?.survey_type === "Krea" || null || "Third_Party_survey" || undefined) &&
      (dataKrea?.project_by_pk?.survey_type === "Krea" || null || "Third_Party_survey" || undefined)
      ? dataKrea : data;

  return (
    <Layout className="w-full">
      {showAccessRevoked && <>
        <div className="pt-32 flex flex-col items-center justify-center">
          <div className="p-6 w-2/3 bg-white shadow rounded flex flex-col items-center justify-center">
            <img src={pageNotFound} alt="error" className="mb-16" />

            <div className="text-h6 text-textLight mb-4">
              Sorry, access for this page is revoked. Please try again
            </div>
            <Button
              type="primary"
              size="large"
              className="px-12 rounded mb-4"
              onClick={() => history.goBack()}
            >
              Go Back
            </Button>
          </div>
        </div>
      </>}
      {!showAccessRevoked &&
        <Content className="px-24 pb-10 h-screen">
          <LeftOutlined
            style={{ margin: "20px 5px", color: "orange" }}
            className="float-left"
            onClick={() => {
              history.goBack();
            }}
          />

          <LocationHierarchy id={id} />
          <div className=" text-sm text-textLight pt-4">
            <p>
              ID#{id}
              {data?.project_by_pk?.status && (
                <span className="ml-2">
                  <Tag
                    color={
                      data?.project_by_pk?.status === "active" ? `blue` : `red`
                    }
                    className={`ml-2 capitalize font-medium text-sm px-3 rounded border-transparent pt-1 pb-1 ${data?.project_by_pk?.status === "active"
                      ? "bg-water text-active"
                      : ""
                      }`}
                  >
                    {data?.project_by_pk?.status}
                  </Tag>
                </span>
              )}
            </p>
            <div className="font-black text-textDark mb-2 flex items-center text-lg ">
              {data?.project_by_pk?.name}
            </div>
            <p className="text-base text-gray-500	font-medium mb-5">
              {data?.project_by_pk?.surveys[0]?.name} | created by :{" "}
              {data?.project_by_pk?.surveys[0]?.created_by_user?.f_name}{" "}
              {data?.project_by_pk?.surveys[0]?.created_by_user?.l_name}
            </p>
          </div>
          {tabPane.includes(location.pathname.split("/")[3]) && (
            <div className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full shadow-lg rounded-md">
              <Tabs
                tabPosition="top"
                size="large"
                className="text-textLight"
                activeKey={activeTabPane}
                onChange={handleActiveTabPane}
              >
                <TabPane
                  tab={<span className="px-8">Project Details</span>}
                  key="1"
                >
                  <ProjectDetail data={data} />
                </TabPane>
                <TabPane
                  tab={<span className="px-8">Assigned members</span>}
                  key="2"
                >
                  {/* {data?.project_by_pk?.survey_type === "Krea"
                    && dataKrea?.project_by_pk?.survey_type === "Krea"
                    ?
                    <>
                      <MemberDetail
                        data={dataKrea}
                        id={id}
                        interviewerID={interviewerID}
                        userData={userData}
                      />
                    </>
                    :
                    <>
                      <MemberDetail
                        data={data}
                        id={id}
                        interviewerID={interviewerID}
                        userData={userData}
                      />
                    </>
                  } */}
                  <MemberDetail
                    data={memberDetailsData}
                    id={id}
                    interviewerID={interviewerID}
                    userData={userData}
                  />
                </TabPane>
              </Tabs>
            </div>
          )}
        </Content>
      }
    </Layout>
  );
};

export default Details;
