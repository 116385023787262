import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Space, Input, notification } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_MEMBERS_LIST, RESEND_INVITE } from "../graphql";
import jwtDecode from "jwt-decode";
import ColumnEditor from "../../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../components/memberColumns";
import MemberTable from "../../../components/memberTable";

function MemberListTab(props) {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID } = useParams();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const [role, setRole] = useState(null);
  const [resendInvite] = useMutation(RESEND_INVITE);
  const savedviewProjectOnlineClientList = localStorage.getItem('viewProjectOnlineClientList');
  const viewProjectOnlineClientListinitial = savedviewProjectOnlineClientList
    ? JSON.parse(savedviewProjectOnlineClientList)
    : {
      ...memberColumnStatus
    }
  const [columnStatus, setStatus] = useState(viewProjectOnlineClientListinitial);

  useEffect(() => {
    localStorage.setItem('viewProjectOnlineClientList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let status = {
      ...columnStatus,
      status: { enabled: true, label: "Status" },
    };
    setStatus(status);
  }, []);

  let searchInput;

  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.status =
          item?.survey_x_members[0]?.state === "active" ||
            item?.survey_x_members[0]?.state === "in-progress"
            ? "Not responded"
            : "Responded";
        item.clientLink =
          location.pathname.split("-")[1] === "Third_Party_survey"
            ? item?.survey_x_members[0]?.survey?.links_status
            : item?.survey_x_members[0]?.survey_url;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;
      });
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [members]);


  const handleInvite = (record) => {
    resendInvite({
      variables: {
        user_id: record?.id,
        surveyID: parseInt(surveyID),
      },
    })
      .then(() => {
        notification.success({
          message: "Sent!",
          description: "Invitation has been sent to member",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "An error occurred while sending invitation.",
        });
      });
  };



  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus),
      ...roleBasedColumns(role, columnStatus),
      ...[
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.status?.enabled,
          className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
          width: 200,
          filters: [
            {
              text: "Not responded",
              value: "Not responded",
            },
            {
              text: "Responded",
              value: "Responded",
            },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text, record) => (
            <>
              {text === "Not responded" ? (
                <>
                  <p className={"text-red text-h8"}>Not responded</p>
                  <Button
                    type="link"
                    className="text-h8 p-0"
                    onClick={() => handleInvite(record)}
                  >
                    Resend Invite
                  </Button>
                </>
              ) : (
                <span className={"text-green text-h8"}>Responded</span>
              )}
            </>
          ),
        },
      ],
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      <MemberTable columns={memColumns} tableData={tableData}></MemberTable>
    </div>
  );
}
export default MemberListTab;
