import React from "react";
import MemberStatsData from "../../components/MemberStatsData";
import { Col, Row } from "antd";

function MemberStatusCount(props) {
  const statusCount = {
    raw: 0,
    Valid: 0,
    "partial-email": 0,
    "partial-phone": 0,
    "not-validated": 0,
    invalid: 0,
    DNC: 0,
    Prohibited: 0,
    total: 0,
  };

  props?.dataSource?.forEach((item) => {
    if (item.status in statusCount) {
      statusCount[item.status]++;
    }
    statusCount.total++;
  });
  return (
    <Row className="px-10 mb-2">
      <Col span={24} className="">
        <div className="gap-6 w-full">
          <div className="shadow-lg bg-white rounded-md flex justify-between gap-2">
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow cursor-pointer
                            rounded-l-lg p-6 ${
                              props.selectedStatus === null ? "" : ""
                            }`}
              onClick={props.handleFilterReset}
            >
              <MemberStatsData
                label={"Total"}
                totalCount={statusCount?.["total"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap
                            flex-grow p-6 ${
                              props.selectedStatus === "raw"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("raw")}
            >
              <MemberStatsData
                label={"Raw"}
                totalCount={statusCount?.["raw"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "partial-email"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("partial-email")}
            >
              <MemberStatsData
                label={"Partial Email"}
                totalCount={statusCount?.["partial-email"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "partial-phone"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("partial-phone")}
            >
              <MemberStatsData
                label={"Partial Phone"}
                totalCount={statusCount?.["partial-phone"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "not-validated"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("not-validated")}
            >
              <MemberStatsData
                label={"Not validated"}
                totalCount={statusCount?.["not-validated"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "invalid"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("invalid")}
            >
              <MemberStatsData
                label={"Invalid"}
                totalCount={statusCount?.["invalid"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "Valid"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("valid")}
            >
              <MemberStatsData
                label={"Valid"}
                totalCount={statusCount?.["Valid"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "DNC"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("dnc")}
            >
              <MemberStatsData
                label={"Dnc"}
                totalCount={statusCount?.["DNC"]}
              />
            </div>
            <div
              className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${
                              props.selectedStatus === "Prohibited"
                                ? "bg-bgMedium"
                                : ""
                            }`}
              onClick={() => props.handleStatusClick("prohibited")}
            >
              <MemberStatsData
                label={"Prohibited"}
                totalCount={statusCount?.["Prohibited"]}
              />
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MemberStatusCount;
