import React, { useState } from "react";
import SurveyLink from "./generateLinks";
import MemberList from "./memberList";
import { useLocation } from "react-router-dom";

function GenerateLinks(props) {
  const location = useLocation();
  // eslint-disable-next-line
  const [togglePage, setTogglePage] = useState(false);
  const handleToggle = (value) => {
    setTogglePage(value);
  };
  return (
    <div>
      {location.pathname.split("/")[8] === undefined && (
        <SurveyLink
          handleToggle={handleToggle}
          startDate={props.startDate}
          status={props.status}
        />
      )}
      {location.pathname.split("/")[8] === "schedule" && (
        <MemberList
          handleToggle={handleToggle}
          startDate={props.startDate}
          status={props.status}
        />
      )}
    </div>
  );
}

export default GenerateLinks;
