import { Form, Checkbox, Row, Col, InputNumber } from "antd";

const FileUpload = (props) => {
  return (
    <div className="w-7/12 border border-borderGray float-left ml-20 p-4 mb-8">
      <Form.Item
        name={[props.option.name, "fileTypesAllowed"]}
        label={
          <div style={{ width: props.checked === true ? `22rem` : `32rem` }}>
            <span className="text-sm text-textMedium float-left">
              File types allowed
            </span>
          </div>
        }
        colon={false}
        valuePropName="value"
        rules={[
          {
            required: true,
            message: "Select atlease one option",
          },
        ]}
      >
        <Checkbox.Group style={{ width: "100%" }} className="mt-2 block">
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="pdf">
                PDF
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="doc/docx">
                DOC/DOCX
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="png">
                PNG
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="xls/xlsx">
                XLS/XLSX
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="jpg/jpeg">
                JPEG/JPG
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="audio/video">
                AUDIO/VIDEO
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name={[props.option.name, "fileSize"]}
        colon={false}
        label={
          <div style={{ width: props.checked === true ? `22rem` : `32rem` }}>
            <span className="text-sm text-textMedium float-left">
              File Limit
            </span>
            <span className="text-sm text-textLight float-right">
              (Max. 16MB)
            </span>
          </div>
        }
        rules={[
          {
            required: true,
            message: "Enter a file size",
          },
        ]}
      >
        <InputNumber
          placeholder="Limit value"
          className="mt-2 block"
          min={1}
          max={16}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </div>
  );
};
export default FileUpload;
