var count = 0;

export const redirectURL = (url) => {
  var open = window.open(url);
  if (open == null || typeof open == "undefined") {
    if (count === 0) {
      count++;
      alert(
        "Turn off your pop-up blocker!\n\nWe try to open the following url:\n" +
          url
      );
    } else {
      count = 0;
      window.location.href = url;
    }
  }
};
