import React, { createContext, useState, useEffect } from 'react';

export const AppContext = createContext();

export const MemberProvider = ({ children }) => {
    const [isExportVisible, setIsExportVisible] = useState(false);
    const [isVerifyVisible, setIsVerifyVisible] = useState(false);
    const [isRejectVisible, setIsRejectVisible] = useState(false);

    useEffect(() => {
        localStorage.setItem('isExportVisible', JSON.stringify(isExportVisible));
        localStorage.setItem('isVerifyVisible', JSON.stringify(isVerifyVisible));
        localStorage.setItem('isRejectVisible', JSON.stringify(isRejectVisible));

    }, [isExportVisible, isVerifyVisible, isRejectVisible]);

    const handleMenuClick = ({ key }) => {
        switch (key) {
            case 'export':
                setIsExportVisible(true);
                setIsVerifyVisible(false);
                setIsRejectVisible(false);
                break;
            case 'verify':
                setIsExportVisible(false);
                setIsVerifyVisible(true);
                setIsRejectVisible(false);
                break;
            case 'reject':
                setIsExportVisible(false);
                setIsVerifyVisible(false);
                setIsRejectVisible(true);
                break;
            default:
                setIsExportVisible(false);
                setIsVerifyVisible(false);
                setIsRejectVisible(false);
                break;
        }
    };

    const isMenuItemActive = (key) => {
        switch (key) {
            case 'export':
                return isExportVisible;
            case 'verify':
                return isVerifyVisible;
            case 'reject':
                return isRejectVisible;
            default:
                return false;
        }
    };

    return (
        <AppContext.Provider
            value={{
                isExportVisible,
                setIsExportVisible,
                isVerifyVisible,
                setIsVerifyVisible,
                isRejectVisible,
                setIsRejectVisible,
                handleMenuClick,
                isMenuItemActive
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
