import moment from "moment";
import { Modal, Button, Checkbox, Divider, notification } from 'antd';
import { data as dataValue } from './data';

export function getStatusValue(data) {
    if (data === "Valid") {
        return "Valid";
    } else if (data === "Raw") {
        return "raw";
    } else if (data === "Partial Email") {
        return "partial-email";
    } else if (data === "Partial Phone") {
        return "partial-phone";
    } else if (data === "Not Validated") {
        return "not-validated";
    } else if (data === "Invalid") {
        return "invalid";
    } else if (data === "DNC") {
        return "DNC";
    } else if (data === "Prohibited") {
        return "Prohibited";
    }
    return data;
}

export const physicianRowData = (rowData, specialityValues, superSpecialityData, additionalColumns, role) => {
    const citiesInState = dataValue[rowData[13]];
    const cityValues = citiesInState && citiesInState.map(item => item.city);
    const additionalDataValues = additionalColumns.slice(0, rowData.length - 40).map((value, index) => ({
        info: rowData[40 + index],
        label: value.trim(),
    }));
    return {
        respondent_id: rowData[0],
        title: rowData[1],
        f_name: rowData[2],
        l_name: rowData[3],
        gender: rowData[4] && rowData[4].toLowerCase() === "male" ? "M" : rowData[4] && rowData[4].toLowerCase() === "female" ? "F" : "",
        year_of_birth: rowData[5] ? moment(rowData[5]) : null,
        email: rowData[6],
        phone_number: rowData[8],
        state: rowData[13] === "Others" ? rowData[14] : rowData[13],
        city: rowData[13] === "Others" ? "Others" : (cityValues && cityValues.includes(rowData[15]) ? rowData[15] : (rowData[15] === "Others" ? rowData[16] : "Others")),
        pincode: rowData[19] !== "" ? rowData[19] : null,
        primary_specialty: Object.keys(specialityValues).find(
            (key) => specialityValues[key].name === rowData[20]
        ),
        super_specialty_id: Object.keys(superSpecialityData).find(
            (key) => superSpecialityData[key].name === rowData[22]
        ),
        secondary_specialty: Object.keys(specialityValues).find(
            (key) => specialityValues[key].name === rowData[24]
        ),
        project_compatibilitys: rowData[31].match(/"(.*?)"/)
            ? [rowData[31].match(/"(.*?)"/)[1].replace(/"/g, "")]
            : [rowData[31]],
        member_type_id: 1,
        password: "",
        role_id: 6,
        status: getStatusValue(rowData[38]),
        is_verified: role === "super_admin"
            ? true
            : false,
        meta_date: {
            respondent: rowData[0],
            year_of_birth: rowData[5] === "" ? "Not Furnished" : "",
            email2: rowData[7],
            mobile1Whatsapp: rowData[9] === "yes",
            mobile2: rowData[10],
            mobile2Whatsapp: rowData[11] === "yes",
            phone: rowData[12],
            cityCategory: rowData[17],
            zone: rowData[18],
            primaryDetails: rowData[20] === 'Others' ? rowData[21] : "",
            superSpecialityDetails: rowData[22] === 'Others' ? rowData[23] : "",
            secondaryDetails: rowData[24] === 'Others' ? rowData[25] : "",
            hospital: rowData[26],
            hospitalType: rowData[27],
            noOfBeds: rowData[28],
            hospital1: rowData[29],
            hospital2: rowData[30],
            yearOfPractice: moment(rowData[32]),
            totalIncentive: rowData[33],
            previousStudies: rowData[34],
            payment: [{ label: rowData[35], info: rowData[36] }],
            validatedDate: moment(rowData[37]),
            status: getStatusValue(rowData[38]),
            access: rowData[39] === "yes",
        },
        additional_data: additionalDataValues ? additionalDataValues : null,
    };
};

export const technicianRowData = (rowData, professionData, additionalColumns, role) => {
    const professionNames = professionData.map((item) => item.tech_profession);
    const nurseDetails = professionData.find((item) => item.tech_profession === rowData[21]);
    const subOne = nurseDetails?.tech_categories_1.map((item) => item.sub_category_1);
    const subTwo = nurseDetails?.tech_category_2.map((item) => item.sub_category_2);

    const citiesInState = dataValue[rowData[13]];
    const cityValues = citiesInState && citiesInState.map(item => item.city);
    const additionalDataValues = additionalColumns.slice(0, rowData.length - 38).map((value, index) => ({
        info: rowData[38 + index],
        label: value.trim(),
    }));
    return {
        respondent_id: rowData[0],
        title: rowData[1],
        f_name: rowData[2],
        l_name: rowData[3],
        gender: rowData[4] && rowData[4].toLowerCase() === "male" ? "M" : rowData[4] && rowData[4].toLowerCase() === "female" ? "F" : "",
        year_of_birth: rowData[5] ? moment(rowData[5]) : null,
        email: rowData[6],
        phone_number: rowData[8],
        state: rowData[13] === "Others" ? rowData[14] : rowData[13],
        city: rowData[13] === "Others" ? "Others" : (cityValues && cityValues.includes(rowData[15]) ? rowData[15] : (rowData[15] === "Others" ? rowData[16] : "Others")),
        pincode: rowData[19] !== "" ? rowData[19] : null,
        member_type_id: 2,
        password: "",
        role_id: 6,
        status: getStatusValue(rowData[36]),
        is_verified: role === "super_admin"
            ? true
            : false,
        meta_date: {
            respondent: rowData[0],
            year_of_birth: rowData[4] === "" ? "Not Furnished" : "",
            email2: rowData[7],
            mobile1Whatsapp: rowData[9] === "yes" ? true : false,
            mobile2: rowData[10],
            mobile2Whatsapp: rowData[11] === "yes" ? true : false,
            phone: rowData[12],
            cityCategory: rowData[17],
            zone: rowData[18],
            experience: rowData[20],
            profession: professionNames.includes(rowData[21]) ? rowData[21] : "Others",
            sub: rowData[21] && subOne ? subOne.includes(rowData[23]) ? rowData[23] : "Others" : "",
            sub2: rowData[21] && subTwo ? subTwo.includes(rowData[25]) ? rowData[25] : "Others" : "",
            isOthersProfession: rowData[21] === "Others" ? rowData[22] : "",
            subCategoryDetails1: rowData[23] === "Others" ? rowData[24] : "",
            subCategoryDetails2: rowData[25] === "Others" ? rowData[26] : "",
            organisation: rowData[27],
            hospitalType: rowData[28],
            noOfBeds: rowData[29],
            yearOfPractice: moment(rowData[30]),
            totalIncentive: rowData[31],
            previousStudies: rowData[32],
            payment: [{ label: rowData[33], info: rowData[34] }],
            validatedDate: moment(rowData[35]),
            access: rowData[37] === "yes" ? true : false,
        },
        additional_data: additionalDataValues ? additionalDataValues : null,
    };
};

export const patientRowData = (rowData, additionalColumns, role) => {
    const citiesInState = dataValue[rowData[13]];
    const cityValues = citiesInState && citiesInState.map(item => item.city);
    const additionalDataValues = additionalColumns.slice(0, rowData.length - 32).map((value, index) => ({
        info: rowData[32 + index],
        label: value.trim(),
    }));
    return {
        respondent_id: rowData[0],
        title: rowData[1],
        f_name: rowData[2],
        l_name: rowData[3],
        gender: rowData[4] && rowData[4].toLowerCase() === "male" ? "M" : rowData[4] && rowData[4].toLowerCase() === "female" ? "F" : "",
        year_of_birth: rowData[5] ? moment(rowData[5]) : null,
        email: rowData[6],
        phone_number: rowData[8],
        state: rowData[13] === "Others" ? rowData[14] : rowData[13],
        city: rowData[13] === "Others" ? "Others" : (cityValues && cityValues.includes(rowData[15]) ? rowData[15] : (rowData[15] === "Others" ? rowData[16] : "Others")),
        pincode: rowData[19] !== "" ? rowData[19] : null,
        member_type_id: 3,
        password: "",
        role_id: 6,
        status: getStatusValue(rowData[30]),
        is_verified: role === "super_admin"
            ? true
            : false,
        meta_date: {
            year_of_birth: rowData[5] === "" ? "Not Furnished" : "",
            email2: rowData[7],
            mobile1Whatsapp: rowData[9] === "yes" ? true : false,
            mobile2: rowData[10],
            mobile2Whatsapp: rowData[11] === "yes" ? true : false,
            phone: rowData[12],
            cityCategory: rowData[17],
            zone: rowData[18],
            hospital: rowData[20],
            hospitalType: rowData[21],
            noOfBeds: rowData[22],
            ailments: rowData[23].match(/"(.*?)"/)
                ? [rowData[23].match(/"(.*?)"/)[1].replace(/"/g, "")]
                : [rowData[23]],
            yearOfPractice: moment(rowData[24]),
            totalIncentive: rowData[25],
            previousStudies: rowData[26],
            payment: [{ label: rowData[27], info: rowData[28] }],
            validatedDate: moment(rowData[29]),
            access: rowData[31] === "yes" ? true : false,
            respondent: rowData[0],
        },
        additional_data: additionalDataValues ? additionalDataValues : null,
    };
};

export const consumerRowData = (rowData, additionalColumns, role) => {
    const citiesInState = dataValue[rowData[13]];
    const cityValues = citiesInState && citiesInState.map(item => item.city);
    const additionalDataValues = additionalColumns.slice(0, rowData.length - 29).map((value, index) => ({
        info: rowData[29 + index],
        label: value.trim(),
    }));
    return {
        respondent_id: rowData[0],
        title: rowData[1],
        f_name: rowData[2],
        l_name: rowData[3],
        gender: rowData[4] && rowData[4].toLowerCase() === "male" ? "M" : rowData[4] && rowData[4].toLowerCase() === "female" ? "F" : "",
        year_of_birth: rowData[5] ? moment(rowData[5]) : null,
        email: rowData[6],
        phone_number: rowData[8],
        state: rowData[13] === "Others" ? rowData[14] : rowData[13],
        city: rowData[13] === "Others" ? "Others" : (cityValues && cityValues.includes(rowData[15]) ? rowData[15] : (rowData[15] === "Others" ? rowData[16] : "Others")),
        pincode: rowData[19] !== "" ? rowData[19] : null,
        member_type_id: 4,
        password: "",
        role_id: 6,
        status: getStatusValue(rowData[27]),
        is_verified: role === "super_admin"
            ? true
            : false,
        meta_date: {
            year_of_birth: rowData[5] === "" ? "Not Furnished" : "",
            email2: rowData[7],
            mobile1Whatsapp: rowData[9] === "yes" ? true : false,
            mobile2: rowData[10],
            mobile2Whatsapp: rowData[11] === "yes" ? true : false,
            phone: rowData[12],
            cityCategory: rowData[17],
            zone: rowData[18],
            noOfBeds: rowData[20],
            yearOfPractice: moment(rowData[21]),
            totalIncentive: rowData[22],
            previousStudies: rowData[23],
            payment: [{ label: rowData[24], info: rowData[25] }],
            validatedDate: moment(rowData[26]),
            access: rowData[28] === "yes" ? true : false,
            respondent: rowData[0],
        },
        additional_data: additionalDataValues ? additionalDataValues : null,
    };
};

export const processStatusMismatch = (data, trackStatus, setTrackStatus, setErrorTrackStatus, successRecords, errorRecords1, tempErrorRecords, activePage) => {
    let isError = false;
    // console.log(data, 'checkstatusmismatch');
    if (data.status === 'raw' && (!!data.email || !!data.phone)) {
        isError = true;
    } else if (data.status === 'partial-email' && (!data.email || !!data.phone_number)) {
        isError = true;
    } else if (data.status === 'partial-phone' && (!data.phone_number || !!data.email)) {
        isError = true;
    } else if (data.status === 'not-validated' && (!data.email || !data.phone_number)) {
        isError = true;
    } else if (data.status === 'Valid' && (
        !data.f_name ||
        !data.l_name ||
        !data.email ||
        !data.phone_number ||
        !data.gender ||
        !data.state ||
        (
            (activePage === "1" &&
                (!data.primary_specialty || !data.meta_date.hospitalType || !data.meta_date.noOfBeds || !data.meta_date.hospital)
            ) ||
            (activePage === "2" && !data.meta_date.profession) ||
            (activePage === "3") || (activePage === "4")
        ) ||
        !data.city
    )) {
        isError = true;
    }

    if (!isError) {
        successRecords.push(data);
        setTrackStatus([...trackStatus, data]);

    } else {
        errorRecords1.push(data);
        tempErrorRecords.push({ ...data, errorRecords1 });
        setErrorTrackStatus(data);
    }
};
export const handleExecution = (rejectionData, tempErrorRecords, setRejectionModal, handleShowWarnings, executeBulkInsertUser, tempData, statusCorrectedValue, activePage, setStatusVisible, trackStatus) => {
    let updatedErrorRecords = [];
    // console.log(trackStatus, tempData, 'trackStatus123');
    const updateStatus = (record) => {
        // console.log(record, 'updateStatus')
        const hasEmail = !!record.email;
        const hasPhone = !!record.phone_number;
        const hasFName = !!record.f_name;
        const hasLName = !!record.l_name;
        const hasPrimarySpecialty = !!record.primary_specialty;
        const hasProfession = !!record.profession;
        const gender = !!record.gender;
        const state = !!record.state;
        const city = !!record.city;
        const hospitalType = !!record.hospitalType;
        const noOfBeds = !!record.noOfBeds;
        const hospital = !!record.hospital;

        if (!hasEmail && !hasPhone) {
            return "raw";
        } else if (hasEmail && !hasPhone) {
            return "partial-email";
        } else if (hasPhone && !hasEmail) {
            return "partial-phone";
        } else if (hasEmail && hasPhone) {
            return "not-validated";
        } else if (
            hasFName && hasLName && hasEmail && hasPhone && gender && state && city && hospitalType && noOfBeds && hospital && ((activePage === "1" && hasPrimarySpecialty) || activePage !== "1" || (activePage === "2" && hasProfession) || (activePage === "3") || (activePage === "4"))
        ) {
            return "Valid";
        } else {
            return "invalid";
        }
    };
    // console.log(rejectionData, tempErrorRecords, "rejectionData, tempErrorRecords")
    if (rejectionData && rejectionData.length > 0) {
        setRejectionModal(true);
        setStatusVisible(false);
        handleShowWarnings(tempErrorRecords);

        updatedErrorRecords = tempErrorRecords.map((record) => ({
            ...record,
            status: updateStatus(record),
        }));
    } else if (tempErrorRecords && tempErrorRecords.length > 0) {
        setStatusVisible(true);
        handleShowWarnings(tempErrorRecords);

        updatedErrorRecords = tempErrorRecords.map((record) => ({
            ...record,
            status: updateStatus(record),
        }));
    } else {
        executeBulkInsertUser(tempData);
    }

    return updatedErrorRecords;
};


export const StatusMismatchModal = ({
    modalVisible,
    setModalVisible,
    errorRecords,
    statusCorrectedValue,
    handleCheckboxChange,
    handleProceed,
}) => {
    return (
        <Modal
            title="Status Mismatch"
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            maskClosable={false}
            closable={false}
            footer={[
                <>
                    <Button key="close" onClick={() => {
                        setModalVisible(false);
                        setTimeout(function () {
                            window.location.reload();
                        }, 500);
                    }}>
                        Close
                    </Button>
                    <Button key="primary" type="primary" onClick={handleProceed}>
                        Proceed
                    </Button>
                </>
            ]}
        >
            <div className="body_status">
                <p>Looks like your status doesn't match. Do you want to proceed overriding?</p>
                {errorRecords.map((record) => {
                    const matchingRecord2 = statusCorrectedValue.find(
                        (record2) => record2.respondent_id === record.respondent_id
                    );
                    function getStatusLabel(status) {
                        const statusLabels = {
                            raw: 'Raw',
                            'partial-email': 'Partial Email',
                            'partial-phone': 'Partial Phone',
                            'not-validated': 'Not Validated',
                            Valid: 'Valid',
                            invalid: 'Invalid',
                            DNC: 'DNC',
                            Prohibited: 'Prohibited'
                        };

                        return statusLabels[status] || status;
                    }
                    return (
                        record.respondent_id && (

                            <div key={record.respondent_id}>
                                <Checkbox
                                    onChange={(e) => {
                                        if (record.respondent_id) {
                                            if (matchingRecord2 && matchingRecord2.respondent_id) {
                                                handleCheckboxChange(
                                                    matchingRecord2,
                                                    matchingRecord2.respondent_id,
                                                    e.target.checked
                                                );
                                            } else {
                                                handleCheckboxChange(record, record.respondent_id, e.target.checked);
                                            }
                                        }
                                    }}
                                >
                                    {record.respondent_id} ({getStatusLabel(record.status)}) &rarr;
                                    {matchingRecord2 ? ` ${getStatusLabel(matchingRecord2.status)}` : ''}
                                </Checkbox>
                                <div className="ml-6">Primary Email: {record.email}</div>
                                <div className="ml-6">Mobile: {record.phone_number}</div>
                                {record.errorMessage}
                                <Divider className="my-2" />
                            </div>
                        )
                    );
                })}
            </div>
        </Modal>
    );
};

export const WarningMismatchPopup = ({
    rejectionModal,
    rejectionModalClose,
    rejectionProceed,
    rejectionData
}) => {
    return (
        <>
            <Modal
                title="Warning"
                visible={rejectionModal}
                onCancel={rejectionModalClose}
                maskClosable={false}
                closable={false}
                footer={[
                    <Button key="primary" type="primary" onClick={rejectionProceed}>
                        Proceed
                    </Button>
                ]}
            >
                <p>The following IDs have duplications / errors</p>
                <div className="body_status">
                    {rejectionData && rejectionData.map((rowData, index) => (
                        <div key={index} className="rejection-row">
                            <div className="rejection-item">
                                <div>{rowData["respondent id"]}</div>
                                <div>Primary Email: {rowData["primary email"]}</div>
                                <div>Mobile: {rowData["mobile number 1"]}</div>
                            </div>
                            <Divider className="my-2" />
                        </div>
                    ))}
                </div>
                <div className="mt-2">All these entires will be moved to the reject list</div>
            </Modal>
        </>
    )
}

export function generatePhysicianInfoObject(entry) {
    return {
        "user": {
            "title": entry?.title,
            "f_name": entry?.f_name,
            "l_name": entry?.l_name,
            "primary_specialty": entry?.primary_specialty,
            "secondary_specialty": entry?.secondary_specialty,
            "super_specialty_id": entry?.super_specialty_id,
            "phone_number": entry?.phone_number,
            "email": entry?.email,
            "state": entry?.state,
            "city": entry?.city,
            "pincode": entry?.pincode,
            "is_verified": entry?.is_verified,
            "gender": entry?.gender,
            "year_of_birth": entry?.year_of_birth,
            "project_compatibilitys": entry?.project_compatibilitys,
            "meta_date": {
                ...entry?.meta_date,
            },
            "additional_data": entry?.additional_data,
            "status": entry?.status,
        },
        "client": {}
    };
}

export function generateTechnicianInfoObject(entry) {
    return {
        "user": {
            "f_name": entry?.f_name,
            "l_name": entry?.l_name,
            "title": entry?.title,
            "gender": entry?.gender,
            "year_of_birth": entry?.year_of_birth,
            "phone_number": entry?.phone_number,
            "email": entry?.email,
            "state": entry?.state,
            "city": entry?.city,
            "is_verified": entry?.is_verified,
            "pincode": entry?.pincode,
            "meta_date": {
                ...entry?.meta_date,
            },
            "additional_data": entry?.additional_data,
            "status": entry?.status,
        },
        "client": {}
    };
}

export function generatePatientInfoObject(entry) {
    return {
        "user": {
            "f_name": entry?.f_name,
            "l_name": entry?.l_name,
            "title": entry?.title,
            "phone_number": entry?.phone_number,
            "email": entry?.email,
            "state": entry?.state,
            "city": entry?.city,
            "is_verified": entry?.is_verified,
            "pincode": entry?.pincode,
            "gender": entry?.gender,
            "year_of_birth": entry?.year_of_birth,
            "additional_data": entry?.additional_data,
            "meta_date": {
                ...entry?.meta_date,
            },
            "status": entry?.status,
        },
        "client": {}
    };
}

export function generateConsumerInfoObject(entry) {
    return {
        "user": {
            "f_name": entry?.f_name,
            "l_name": entry?.l_name,
            "title": entry?.title,
            "phone_number": entry?.phone_number,
            "email": entry?.email,
            "state": entry?.state,
            "city": entry?.city,
            "pincode": entry?.pincode,
            "is_verified": entry?.is_verified,
            "gender": entry?.gender,
            "year_of_birth": entry?.year_of_birth,
            "additional_data": entry?.additional_data,
            "meta_date": {
                ...entry?.meta_date,
            },
            "status": entry?.status,
        },
        "client": {}
    };
}

export const executeBulkInsertUserFunction = async (
    tempData,
    uncheckedStatus,
    rejectionData,
    trackStatus,
    cleanedJsonData,
    statusCounts,
    role,
    destination,
    getUserOldInfoArray,
    editMemberDataArray,
    id,
    history,
    newUserCreateData,
    rejectionDataList,
    addApproval,
    bulkInsertUser,
    ImportEmailTrigger,
    managerName,
    member_type,
    onboardUserResID
) => {
    const unmatchedInErrorRecords = uncheckedStatus && uncheckedStatus?.filter(uncheckedStatus => {
        return !trackStatus.find(trackRecord => trackRecord.respondent_id === uncheckedStatus['respondent id']);
    });
    // const updatedValue = [...rejectionData, ...unmatchedInErrorRecords];
    let updatedValue;
    if (Array.isArray(rejectionData) && Array.isArray(unmatchedInErrorRecords)) {
        updatedValue = [...rejectionData, ...unmatchedInErrorRecords];
    }
    if ((!updatedValue || !updatedValue.length) && (!cleanedJsonData || !cleanedJsonData.length)) {
        cleanedJsonData = cleanedJsonData.concat(tempData);
        cleanedJsonData.forEach(member => {
            const status = member.status;
            if (statusCounts.hasOwnProperty(status)) {
                statusCounts[status]++;
            }
        });
    }
    if (updatedValue && updatedValue.length > 0) {
        try {
            await rejectionDataList({
                variables: {
                    meta_data: updatedValue,
                    member_type: member_type,
                    rejected_by: id,
                },
            });
            notification.warning({
                message: "Duplicate/error entries have been moved to the reject list.",
            });
            if (cleanedJsonData && cleanedJsonData.length === 0) {
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        } catch (error) {
            console.log(error, 'error in rejectionDataList');
        }
    }

    if (cleanedJsonData && cleanedJsonData.length > 0) {
        return new Promise(async (resolve, reject) => {
            try {
                let response;
                let successfulApprovalsCount = 0;

                if (role === 'super_admin') {
                    response = await bulkInsertUser({
                        variables: {
                            object: cleanedJsonData,
                        },
                    });
                    const affectedRows = response?.data?.insert_user?.affected_rows || 0;
                    for (const member of cleanedJsonData) {
                        if (member.email) {
                            await onboardUserResID({
                                variables: {
                                    res_id: member.respondent_id,
                                },
                            }).catch((onboardError) => {
                                console.log(onboardError, 'error in onboardUserResID');
                            });
                        }
                    }
                    if (affectedRows === 0) {
                        notification.error({
                            message: "No users were created",
                        });
                        reject("No users created");
                    } else {
                        if (editMemberDataArray.length > 0) {
                            notification.success({
                                message: `${editMemberDataArray.length} 
                                user${editMemberDataArray.length > 1 ? 's' : ''}  have been updated`,
                            });
                        }
                        if (newUserCreateData.length > 0) {
                            notification.success({
                                message: `${newUserCreateData.length} 
                                user${newUserCreateData.length > 1 ? 's' : ''}  have been created`,
                            });
                        }
                        if (cleanedJsonData.length > 0 && newUserCreateData.length === 0 && editMemberDataArray.length === 0) {
                            notification.success({
                                message: `${cleanedJsonData.length} 
                                user${cleanedJsonData.length > 1 ? 's' : ''}  have been created`,
                            });
                        }
                        resolve(affectedRows);
                        if (destination) {
                            history.push(destination);
                        }
                    }
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                } else {
                    for (let i = 0; i < getUserOldInfoArray.length; i++) {
                        const oldInfoEntry = getUserOldInfoArray[i];
                        const updatedInfoEntry = editMemberDataArray[i];

                        const oldInfoArray = generatePhysicianInfoObject(oldInfoEntry);
                        const updatedInfoArray = generatePhysicianInfoObject(updatedInfoEntry);

                        const addApprovalResponse = await addApproval({
                            variables: {
                                old_info: oldInfoArray,
                                updated_info: updatedInfoArray,
                                user_id: oldInfoEntry.id,
                                updated_by: parseInt(id),
                            },
                        });
                        if (addApprovalResponse.data) {
                            successfulApprovalsCount++
                        } else {
                            notification.error({
                                message: "Approval request failed",
                            });
                        }
                    }
                    const editMemberRespondentIds = editMemberDataArray.map(item => item.respondent_id);
                    const cleanedJsonDataFiltered = cleanedJsonData.filter(item => !editMemberRespondentIds.includes(item.respondent_id));
                    response = await bulkInsertUser({
                        variables: {
                            object: newUserCreateData.length > 0 ? newUserCreateData : cleanedJsonDataFiltered,
                        },
                    });
                    const affectedRows = response?.data?.insert_user?.affected_rows || 0;
                    if (affectedRows === 0 && successfulApprovalsCount === 0) {
                        notification.error({
                            message: "No users were created",
                        });
                        reject("No users created");
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    } else {
                        if (successfulApprovalsCount > 0) {
                            notification.success({
                                message: `${successfulApprovalsCount} Approval request${successfulApprovalsCount > 1 ? 's' : ''} sent successfully`,
                            });
                        }
                        resolve(successfulApprovalsCount);
                        if (role === 'manager') {
                            try {
                                await ImportEmailTrigger({
                                    variables: {
                                        m_name: managerName,
                                        not_validated: statusCounts?.['not-validated'],
                                        raw_count: statusCounts?.['raw'],
                                        volume_of_upload: cleanedJsonData.length,
                                        dnc_count: statusCounts?.['DNC'],
                                        invalid_count: statusCounts?.['invalid'],
                                        partial_e_count: statusCounts?.['partial-email'],
                                        partial_p_count: statusCounts?.['partial-phone'],
                                        prohibited_count: statusCounts?.['Prohibited'],
                                        valid_count: statusCounts?.['Valid']
                                    },
                                });
                            } catch (error) {
                                console.log(error, 'error in ImportEmailTrigger');
                            }
                        }
                        if (affectedRows > 0) {
                            notification.success({
                                message: `${affectedRows} user(s) have been created`,
                            });
                        }
                        resolve(affectedRows);
                        if (destination) {
                            history.push(destination);
                        }
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    }
                }
            } catch (error) {
                notification.error({
                    message: "Something went wrong",
                });
                reject("Error");
                console.error(error, 'error');
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            }
        });
    }
};

export const handleCheckboxChangeCommon = (record, respondentId, checked, setSelectedRecords, setTrackStatus) => {
    if (checked) {
        setSelectedRecords((prevSelectedRecords) => [...prevSelectedRecords, record]);
        setTrackStatus((prevTrackStatus) => [...prevTrackStatus, record]);
    } else {
        setSelectedRecords((prevSelectedRecords) =>
            prevSelectedRecords.filter((r) => r.respondent_id !== record.respondent_id)
        );
        setTrackStatus((prevTrackStatus) =>
            prevTrackStatus.filter((r) => r.respondent_id !== record.respondent_id)
        );
    }
};

export const handleProceedCommon = (executeBulkInsertUser, trackTempData, setStatusVisible) => {
    executeBulkInsertUser(trackTempData);
    setStatusVisible(false);
};

export const rejectionProceedCommon = (setRejectionModal, statusmismatch, setStatusVisible, executeBulkInsertUser) => {
    setRejectionModal(false);
    if (statusmismatch && statusmismatch.length > 0) {
        setStatusVisible(true);
    } else {
        executeBulkInsertUser();
    }
};

export const isRespondentIdInList = (respondentId, respondentDB) => {
    return respondentDB.includes(respondentId);
};