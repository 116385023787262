import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Checkbox,
  DatePicker,
  Upload,
  message,
  notification,
  Spin,
  Switch,
} from "antd";
import ReactHtmlParser from "react-html-parser";
import RichTextbox from "../../../components/richTextbox";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { getISOstringFrom, getISOstringTo } from "../../../utils/dateTime";
import "./projectDetails.css";
import { projectForm } from "../../../atoms";
import { useSetRecoilState } from "recoil";
import { GET_DATA, GET_POC } from "../graphql";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PROJECT,
  UPDATE_SURVEY_STATUS_IN_PROJECT,
  PROJECTxPOC,
  UPDATE_PROJECT,
  UPDATE_POC,
  GET_PROJECT_DETAILS,
  GET_UPLOAD_URL,
  // GET_DOWNLOAD_URL,
  GET_SURVEY_STATUS,
} from "../graphql";
import _ from "lodash";

const { Option } = Select;
const { RangePicker } = DatePicker;

function ProjectDetails(props) {
  const { projectID } = useParams();
  const setValues = useSetRecoilState(projectForm);
  const history = useHistory();
  const location = useLocation();
  const [textLength, setTextLength] = useState("0");
  const [projectType, setProjectType] = useState("");
  const [surveyType, setSurveyType] = useState();
  const [content, setContent] = useState("");
  const [fileList, setFileList] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [switchChecked, setSwitchChecked] = useState(false);
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [updateSurveyStatus] = useMutation(UPDATE_SURVEY_STATUS_IN_PROJECT);
  // const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [createProject] = useMutation(CREATE_PROJECT);
  // const [managerxproject, { loading: managerxloading }] =
  //   useMutation(MANAGERxPROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  // const [updateManager, { loading: managerLoading }] =
  //   useMutation(UPDATE_MANAGER);
  const [projectxpoc] = useMutation(PROJECTxPOC);
  const [updatePOC] = useMutation(UPDATE_POC);

  const { data: surveyStatus, loading: surveyLoading } = useQuery(
    GET_SURVEY_STATUS,
    {
      fetchPolicy: "network-only",
      variables: {
        projectID: projectID ? parseInt(projectID) : 0,
      },
    }
  );

  const { data, loading: projectLoading } = useQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      project_id: projectID ? parseInt(projectID) : 0,
    },
  });

  const charCount = (txtLength, content) => {
    setTextLength(txtLength);
    form.setFieldsValue({
      description: content,
    });
  };

  const disabledDate = (current) => {
    return current && current <= moment().startOf("day");
  };

  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue();
      if (textLength === 0) {
        setErrorMessage("Please enter project description");
      } else if (
        textLength !== 0 &&
        ReactHtmlParser(formValues.description)[0].props.children[0].trim() ===
        ""
      ) {
        setErrorMessage("Please enter valid text for project description");
      } else {
        setErrorMessage("");
        let project_id;
        let responseURL = [];
        if (values.fileName) {
          values.fileName.fileList
            ? values.fileName.fileList
              // eslint-disable-next-line
              .map((url) => {
                url.responseUrl
                  ? responseURL.push(url.responseUrl)
                  : responseURL.push(url?.xhr?.responseURL?.split("?"));
              })
            : values.fileName !== undefined
              ? values.fileName
                // eslint-disable-next-line
                .map((url) => {
                  responseURL.push(url.responseUrl);
                })
              : (responseURL = []);
        }
        let documentObject = [
          {
            document: values.document !== undefined ? values.document : "",
          },
          {
            fileList:
              values.fileName !== undefined
                ? values.fileName.fileList
                  ? values.fileName.fileList
                  : values.fileName
                : [],
          },
          { responseURL: responseURL },
        ];
        const payload = {
          client_id: values.clientName,
          description: values.description,
          end_date: getISOstringTo(values.duration[1]),
          start_date: getISOstringFrom(values.duration[0]),
          name: values.projectName,
          project_type: values.projectType,
          survey_type:
            values.projectType === 3 || values.projectType === 4
              ? values.surveyType
                ? values.surveyType
                : null
              : null,
          client_project_id: values.clientProjectID,
          sample: values.sampleSize.toString(),
          view_permition: false,
          documents: documentObject,
          member_type_id: 1,
          status:
            buttonAction === "draft"
              ? "draft"
              : moment().isSameOrAfter(props.startDate)
                ? data?.project[0]?.status === "draft"
                  ? "draft"
                  : "active"
                : data?.project[0]?.status === "active"
                  ? "active"
                  : "draft",
          member_types: values.memberType,
          include_profiler_questions: values.include_profiler_questions,
          survey_does_not_carry_krea_landing_pages: values.landingPage,
          third_party_survey_name:
            values.surveyType === "Third_Party_survey"
              ? values.thirdPartySurveyName
              : null,
          is_online_or_assisted: switchChecked,
          third_party_survey_url:
            values.surveyType === "Third_Party_survey"
              ? values.thirdPartySurveyUrl
              : null,
        };
        projectID !== undefined
          ? updateProject({
            variables: {
              object: payload,
              id: parseInt(projectID),
            },
          }).then((res) => {
            let poc = values.selectPOC;
            let payloadObject = [];
            let object = {};
            poc.forEach((contact, index) => {
              object.project_id = parseInt(projectID);
              object.poc_id = contact.pocName;
              payloadObject[index] = object;
              object = {};
            });

            updatePOC({
              variables: {
                object: payloadObject,
                id: parseInt(projectID),
              },
            }).then(() => {
              if (surveyStatus.survey.length === 1) {
                updateSurveyStatus({
                  variables: {
                    id: surveyStatus.survey[0].id,
                    status:
                      buttonAction === "draft"
                        ? "draft"
                        : surveyStatus.survey[0].status,
                  },
                });
              }
              if (buttonAction === "active") {
                location.pathname.split("/")[1] === "create_project" &&
                  history.push(`/create_project/${projectID}/assign_members`);
                location.pathname.split("/")[3] === "edit-project" &&
                  history.push(
                    `/project/${projectID}/edit-project/assign_members`
                  );
                location.pathname.split("/")[5] === "re-opened" &&
                  history.push(
                    `/project/${projectID}/edit-project/assign_members/re-opened`
                  );
              } else {
                notification.success({
                  message: "Success!",
                  description: "Saved as draft!",
                });
                history.push("/projectList/active");
              }
            });
          })
          : createProject({
            variables: {
              objects: payload,
            },
          })
            .then((res) => {
              project_id = res?.data?.insert_project?.returning[0]?.id;
            })
            .then(() => {
              let poc = values.selectPOC;
              let payloadObject = [];
              let object = {};
              poc.forEach((contact, index) => {
                object.project_id = project_id;
                object.poc_id = contact.pocName;
                payloadObject[index] = object;
                object = {};
              });

              projectxpoc({
                variables: {
                  objects: payloadObject,
                },
              }).then(() => {
                if (buttonAction === "active") {
                  location.pathname.split("/")[1] === "create_project" &&
                    history.push(
                      `/create_project/${project_id}/assign_members`
                    );
                  location.pathname.split("/")[3] === "edit-project" &&
                    history.push(
                      `/project/${projectID}/edit-project/assign_members`
                    );
                  location.pathname.split("/")[5] === "re-opened" &&
                    history.push(
                      `/project/${projectID}/edit-project/assign_members/re-opened`
                    );
                } else {
                  notification.success({
                    message: "Success!",
                    description: "Saved as draft!",
                  });
                  history.push("/projectList/active");
                }
              });
            });
      }
    });
  };

  const addValues = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const [form] = Form.useForm();
  const { data: projectData, loading: projectDataLaoding } = useQuery(
    GET_DATA,
    {
      fetchPolicy: "network-only",
    }
  );

  const [get_POC, { data: poc }] = useLazyQuery(GET_POC);
  const getPOC = (id) => {
    get_POC({
      variables: { client_id: id },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    let pocNamearray = [];
    let managerArray = [];
    let fileListArray = [];

    if (data?.project[0]?.documents !== null) {
      data?.project[0]?.documents[1]?.fileList
        // eslint-disable-next-line
        .map((file, index) => {
          fileListArray.push({
            uid: file.uid,
            name: file.name,
            status: file.status,
            responseUrl: data?.project[0]?.documents[2]?.responseURL[index],
          });
        });
    }
    setFileList(fileListArray);

    data?.project[0]?.project_x_pocs
      // eslint-disable-next-line
      .map((poc) => {
        pocNamearray.push({ pocName: poc.poc_id });
      });
    data?.project[0]?.manager_x_projects
      // eslint-disable-next-line
      .map((manager) => {
        managerArray.push(manager?.user?.id);
      });
    if (data?.project.length > 0) {
      getPOC(data?.project[0]?.client.id);
      form.setFieldsValue({
        projectName: data?.project[0].name,
        clientName: data?.project[0].client.id,
        selectPOC: pocNamearray,
        memberType:
          data?.project[0].member_types !== null
            ? data?.project[0].member_types
            : [],
        projectType: data?.project[0].projectTypeByProjectType.id,
        clientProjectID: data?.project[0].client_project_id,
        surveyType: data?.project[0].survey_type,
        thirdPartySurveyName:
          data?.project[0].third_party_survey_name !== null
            ? data?.project[0].third_party_survey_name
            : "Alchemer",
        thirdPartySurveyUrl:
          data?.project[0].third_party_survey_url !== null
            ? data?.project[0].third_party_survey_url
            : "https://app.alchemer.com/login/v1",
        description: data?.project[0].description,
        include_profiler_questions:
          data?.project[0]?.include_profiler_questions,
        landingPage: data?.project[0]?.survey_does_not_carry_krea_landing_pages,
        duration: [
          moment(data?.project[0].start_date),
          moment(data?.project[0].end_date),
        ],
        sampleSize: data?.project[0].sample,
        document:
          data?.project[0]?.documents !== null
            ? _.isEmpty(data?.project[0]?.documents[0])
              ? ""
              : data?.project[0]?.documents[0].document
            : "",
        fileName: fileListArray,
        is_online_or_assisted: data?.project[0].is_online_or_assisted || false
      });
      setContent(data?.project[0].description);
      setSurveyType(data?.project[0].survey_type);
      setProjectType(data?.project[0].projectTypeByProjectType.name);
      setSwitchChecked(data?.project[0].is_online_or_assisted || false)
      setValues((oldValues) => ({
        ...oldValues,
        projectDetails: {
          ...form.getFieldsValue(),
        },
        userDetails: managerArray,
      }));
    } else {
      form.setFieldsValue({
        selectPOC: [""],
        uploadList: [""],
        include_profiler_questions: true,
        landingPage: true,
        thirdPartySurveyName: "Alchemer",
        thirdPartySurveyUrl: "https://app.alchemer.com/login/v1",
      });
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <Spin spinning={projectDataLaoding || projectLoading || surveyLoading}>
      <Form
        name="projectDetails_form"
        layout="vertical"
        requiredMark={false}
        form={form}
        // onFinish={addValues}
        preserve={true}
      >
        <Form.Item
          label={<span className="text-base text-textLight">Project Name</span>}
          name="projectName"
          rules={[{ required: true, message: "Please enter Project name!" }]}
        >
          <Input className="rounded-md" />
        </Form.Item>
        <Form.Item
          label={<span className="text-base text-textLight">Client</span>}
          name="clientName"
          rules={[
            {
              required: true,
              message: "Please select client",
            },
          ]}
        >
          <Select
            placeholder="Select"
            onChange={(e) => {
              form.setFieldsValue({
                selectPOC: [""],
              });
              getPOC(e);
            }}
            showSearch
            optionFilterProp="children"
          >
            {projectData?.user?.map((client) => {
              return (
                <Option key={client?.id} value={client?.id}>
                  {client?.f_name} {client?.l_name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.List name="selectPOC" className="w-full">
          {(fields, { add, remove }) => (
            <>
              {fields
                // eslint-disable-next-line
                .map((field, index) => (
                  <Form.Item
                    required={false}
                    {...field}
                    name={[field.name, "poc"]}
                    fieldKey={[field.fieldKey, "poc"]}
                    className="float-left -mb-0 w-11/12"
                  >
                    <Form.Item
                      name={[field.name, "pocName"]}
                      fieldKey={[field.fieldKey, "pocName "]}
                      rules={[
                        {
                          required: true,
                          message: "Please select poc",
                        },
                      ]}
                      className="float-left "
                      style={{ width: "49%" }}
                    >
                      <Select
                        placeholder="Select POC"
                        className="float-left ml-11 "
                        showSearch
                        optionFilterProp="children"
                      >
                        {poc?.poc
                          // eslint-disable-next-line
                          ?.map((poc, index) => {
                            return (
                              <Option key={index} value={poc?.id}>
                                {poc?.first_name} {poc?.last_name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item className="float-right w-10">
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          style={{ color: "red" }}
                          onClick={() => remove(field.name)}
                        />
                      ) : (
                        <MinusCircleOutlined
                          style={{ color: "red" }}
                          disabled
                        />
                      )}
                    </Form.Item>
                  </Form.Item>
                ))}
              <Form.Item className="float-left w-10">
                <PlusCircleOutlined
                  className="dynamic-add-button "
                  style={{ color: "orange" }}
                  onClick={() => add()}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider />
        <Form.Item
          label={
            <span className="text-base text-textLight block">Member Type</span>
          }
          name="memberType"
          rules={[
            {
              required: true,
              message: "Please enter Member type",
            },
          ]}
          style={{ flexFlow: "column" }}
        >
          <Select
            placeholder="Select"
            mode="multiple"
            showArrow
            style={{ width: "49%" }}
            showSearch
            optionFilterProp="children"
          >
            {projectData?.member_type
              // eslint-disable-next-line
              ?.map((member) => {
                return (
                  <Option key={member?.id} value={member?.id}>
                    {member?.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          label={<span className="text-base text-textLight">Project Type</span>}
          name="projectType"
          rules={[
            {
              required: true,
              message: "Please select Project type",
            },
          ]}
          className="float-left "
          style={{ width: "49%" }}
        >
          <Select
            placeholder="Select"
            onChange={(value, option) => {
              setProjectType(option.children);
            }}
          >
            {projectData?.project_type
              // eslint-disable-next-line
              ?.map((project) => {
                return (
                  <Option key={project?.id} value={project?.id}>
                    {project?.name}
                  </Option>
                );
              })}
          </Select>

        </Form.Item>
        <Form.Item
          label={<span className="text-base text-textLight">Survey Type</span>}
          name="surveyType"
          className="float-left ml-4"
          style={{ width: "49%" }}
          rules={[
            {
              required:
                form.getFieldValue("projectType") === 3 ||
                  form.getFieldValue("projectType") === 4
                  ? true
                  : false,
              message: "please select survey type",
            },
          ]}
        >
          <Select
            disabled={
              projectType === "PRF" ||
                projectType === "External" ||
                projectType === ""
                ? true
                : false
            }
            onChange={(e) => {
              setSurveyType(e);
            }}
            placeholder="Select"
          >
            <Option value="Krea">Krea’s survey creator</Option>
            <Option value="Client">Client’s survey URL</Option>
            <Option value="Third_Party_survey">Third Party Survey</Option>
          </Select>
        </Form.Item>
        {(projectType === 'Assisted' || projectType === 'Online') && data?.project[0]?.status !== "completed" && <Form.Item style={{ width: '49%' }} className=" flex justify-between items-center mt-3">
          <div className="flex justify-between items-center">

            <span className=" text-base text-textLight">
              Also create as {projectType === 'Assisted' ? "online" : "assisted"} type
            </span>
            <div>

              <span className="text-base text-textLight">
                {switchChecked === true ? "Yes" : "No"}
              </span>
              <Switch
                checked={switchChecked}
                onChange={setSwitchChecked}
                className="float-right ml-3"
              ></Switch>
            </div>
          </div>
        </Form.Item>
        }

        {surveyType === "Third_Party_survey" && (
          <>
            <Form.Item
              label={
                <span className="text-base text-textLight">
                  Third Party Survey Name
                </span>
              }
              name="thirdPartySurveyName"
              rules={[
                {
                  required: true,
                  message: "Please enter third party survey name",
                },
              ]}
              className="float-left "
              style={{ width: "49%" }}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-base text-textLight">
                  Third Party Survey URL
                </span>
              }
              name="thirdPartySurveyUrl"
              className="float-left ml-4"
              style={{ width: "49%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter third party survey URL",
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </>
        )}

        <Form.Item
          className="w-full"
          name="landingPage"
          valuePropName="checked"
        >
          <Checkbox
            disabled={
              form.getFieldValue("projectType") === 1 ||
                form.getFieldValue("projectType") === 5 ||
                surveyType !== "Client"
                ? true
                : false
            }
            className="text-base text-textLight"
          >
            This survey does not carry Krea's landing pages
          </Checkbox>
          {/* <InfoCircleOutlined className="text-textLight " /> */}
        </Form.Item>
        <Form.Item
          label={
            <div style={{ width: "32rem" }}>
              <span className="text-base text-textLight float-left">
                Client project ID
              </span>
              <span className="text-base text-textExtraLight float-left ml-4">
                Optional
              </span>
            </div>
          }
          name="clientProjectID"
          rules={[{ message: "Client project ID" }]}
          required="optional"
          style={{ width: "49%" }}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={
            <span className="text-base text-textLight">
              Project description
            </span>
          }
          name="description"
        >
          {(content || projectID === undefined) && (
            <RichTextbox
              charCount={charCount}
              placeholder="Enter Description"
              content={content ? content : "<p></p>"}
            />
          )}
          <div className="text-textExtraLight float-right">
            {"Character count  " + textLength + "/500"}
          </div>
        </Form.Item>
        <Form.Item>
          <Form.Item
            name="duration"
            label={<span className="text-base text-textLight">Date</span>}
            rules={[
              {
                required: true,
                message: "Please enter Date",
              },
            ]}
            style={{ width: "49%" }}
            className="float-left"
          >
            <RangePicker
              className="text-base text-textLight w-full"
              disabledDate={disabledDate}
              disabled={
                location.pathname.split("/")[1] !== "create_project"
                  ? data?.project[0]?.start_date
                    ? [
                      moment(data?.project[0]?.start_date).isSameOrBefore(
                        moment()
                      ) && data?.project[0]?.status === "active",
                      false,
                    ]
                    : [false, false]
                  : [false, false]
              }
              separator={<ArrowRightOutlined style={{ color: "#C4C4C4" }} />}
            />
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight ">Sample size</span>
            }
            name="sampleSize"
            style={{ width: "49%" }}
            className="float-left ml-4"
            rules={[
              {
                required: true,
                message: "Please enter sample size",
              },
              () => ({
                validator(_, value) {
                  let regx = /^\d*$/g;
                  if (value && !regx.test(value)) {
                    return Promise.reject("Please enter a valid number");
                  } else if (!value || parseInt(value) > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please enter a valid number");
                },
              }),
            ]}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
        </Form.Item>
        <Form.Item name="documentUpload">
          <div className="pb-2">
            <span className="text-base text-textLight">Documents</span>
            <span className="text-base text-textExtraLight ml-4">Optional</span>
          </div>

          <Form.Item
            required={false}
            name="upload"
            className="float-left -mb-0"
            style={{ width: "96%" }}
          >
            <Form.Item
              name="document"
              rules={[{ message: "Document description" }]}
              required="optional"
              style={{ width: "51%" }}
              className="float-left"
            >
              <Input type="text" placeholder="Description about the document" />
            </Form.Item>

            <Form.Item
              name="uploadFile"
              style={{ width: "46%" }}
              className="float-left ml-6"
            >
              <Form.Item name="fileName" className="float-left w-40">
                <Upload
                  name="file"
                  method="PUT"
                  fileList={fileList}
                  action={(file) => {
                    return new Promise((resolve) => {
                      uploadURL({
                        variables: {
                          fileName: file.name,
                          contentType: file.type,
                        },
                      }).then((res) => {
                        if (res.data.get_upload_url.message === "Success") {
                          resolve(res.data.get_upload_url.url);
                        }
                      });
                    });
                  }}
                  onChange={(info) => {
                    let newFileList = [...info.fileList];

                    setFileList(newFileList);
                    const { status } = info.file;

                    if (status === "done") {
                      message.success(
                        `${info.file.name} file uploaded successfully.`
                      );
                    } else if (status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                >
                  <Button ghost type="primary" className="rounded-sm">
                    Upload
                  </Button>
                </Upload>
              </Form.Item>
            </Form.Item>
          </Form.Item>
        </Form.Item>
        <Form.Item name="include_profiler_questions" valuePropName="checked">
          <Checkbox
            className="text-base text-textLight"
            disabled={
              form.getFieldValue("projectType") === 1 ||
                form.getFieldValue("projectType") === 5 ||
                (form.getFieldValue("projectType") === 3 &&
                  surveyType !== "Krea") ||
                (form.getFieldValue("projectType") === 4 && surveyType !== "Krea")
                ? true
                : false
            }
          >
            Include profiler questions
          </Checkbox>
          {/* <InfoCircleOutlined className="text-textLight " /> */}
        </Form.Item>
        <Form.Item>
          <div style={{ color: "red" }}>
            <span>{errorMessage}</span>
          </div>
        </Form.Item>
        <Form.Item className="float-right">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => history.push("/dashboard")}
          >
            Cancel
          </Button>
          <Button
            htmlType="submit"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              location.pathname.split("/")[1] !== "create_project"
                ? moment().isSameOrAfter(props.startDate) &&
                  data?.project[0]?.status === "active"
                  ? true
                  : false
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-sm w-28"
            onClick={addValues}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
}
export default ProjectDetails;
