import React, { useEffect, useMemo, useState, useCallback } from "react";
import ColumnEditor from "../../components/ColumnEditor";
import { Button, Col, Drawer, Form, List, Modal, Row, Select, Spin } from "antd";
import MemberStatsData from "../../components/MemberStatsData";
import MemberTable from "../../components/memberTable";
import { getColumnSearchProps } from "../../utils/table";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_USERS,
  STATUS_COUNTS,
  GET_INTERVIEWER,
  ASSIGN_INTERVIEWER_MEMBERS,
  UNASSIGN_MEMBERS,
} from "./graphql";
import moment from "moment";
import { FilterOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import jwtDecode from "jwt-decode";
import ViewActiveButton from "../../components/viewActiveButton";
import { getStatusText } from "../members/getStatusText";

const { Option } = Select;

const InterviewerAssignment = (props) => {
  const [memberIndex, setMemberIndex] = useState();
  const [singleMember, setSingleMember] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedMemberKeys, setSelectedMemberKeys] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [enableDrawer, setEnableDrawer] = useState(false);
  const [role, setRole] = useState(null);
  const [assignInterviewerMembers] = useMutation(ASSIGN_INTERVIEWER_MEMBERS);
  const [unassignInterviewerMembers] = useMutation(UNASSIGN_MEMBERS);
  const [interviewerLeaveList, setInterviewerLeaveList] = useState([]);
  const [userID, setUserID] = useState();
  const [editAssignInterviewer, setEditAssignInterviewer] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredValue, setFilteredValue] = useState(null);
  const [copy, setCopy] = useState([]);
  const [intPrivileges, setIntPrivileges] = useState([]);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [form] = Form.useForm();
  const savedinterviewerAssignment = localStorage.getItem('interviewerAssignment');
  const interviewerAssigninitial = savedinterviewerAssignment
    ? JSON.parse(savedinterviewerAssignment)
    : {
      respondent_id: { enabled: true, label: "Respondent ID" },
      status: { enabled: true, label: "Status" },
      name: { enabled: true, label: "Name" },
      title: { enabled: true, label: "Title" },
      email: { enabled: true, label: "Primary Email" },
      phone_number: { enabled: true, label: "Phone Number" },
      hospital: { enabled: true, label: "Hospital" },
      speciality: { enabled: true, label: "Primary speciality" },
      year_of_birth: { enabled: false, label: "Year of birth" },
      mobile1: { enabled: false, label: "Mobile Number1" },
      mobile2: { enabled: false, label: "Mobile Number2" },
      secondaryEmail: { enabled: false, label: "Secondary Email" },
      genderNew: { enabled: false, label: "Gender" },
      state: { enabled: false, label: "State" },
      city: { enabled: false, label: "City" },
      zone: { enabled: false, label: "Zone" },
      cityCategory: { enabled: false, label: "City Category" },
      pincode: { enabled: false, label: "Pincode" },
      secondarySpeciality: { enabled: false, label: "Secondary speciality" },
      consultingHospital1: { enabled: false, label: "Consulting Hospital 1" },
      consultingHospital2: { enabled: false, label: "Consulting Hospital 2" },
      projectCompatibility: { enabled: false, label: "Project compatibility" },
      hospitalType: { enabled: false, label: "Hospital Type" },
      noOfBeds: { enabled: false, label: "No. of beds" },
      access: { enabled: false, label: "Access to mobile" },
      startedPracticing: {
        enabled: false,
        label: "Started practicing (post Residency)",
      },
      totalIncentivesProcessed: {
        enabled: false,
        label: "Total incentives processed",
      },
      previouslyParticipatedStudies: {
        enabled: false,
        label: "Previously participated studies",
      },
      modeOfPayment: { enabled: false, label: "Mode of payment" },
      validatedDate: { enabled: false, label: "Validated date" },
      experience: { enabled: false, label: "Years of experience" },
    };
  const [columnStatus, setStatus] = useState(interviewerAssigninitial);


  const { data, loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
  });

  const { data: statusCount } = useQuery(STATUS_COUNTS, {
    fetchPolicy: "network-only",
  });

  const { data: interviewerData } = useQuery(GET_INTERVIEWER, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    localStorage.setItem('interviewerAssignment', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setFilteredValue(selectedStatus ? [selectedStatus] : null);
  }, [selectedStatus]);

  useEffect(() => {
    if (data) {
      let copy = JSON.parse(JSON.stringify(data));
      copy.user.forEach((user) => {
        user.name =
          user?.l_name !== null
            ? user?.f_name + " " + user?.l_name
            : user?.f_name;
        user.roleName = user?.role?.description;
        user.speciality =
          user?.primary_specialties?.speciality_name === undefined
            ? "Not updated"
            : user?.primary_specialties?.speciality_name;
        user.phone_number =
          user.phone_number === "" ? "Not updated" : user.phone_number;
        let phone_number = user.phone_number;
        user.name =
          user?.l_name !== null
            ? user?.f_name + " " + user?.l_name
            : user?.f_name;
        user.roleName = user?.role?.description;
        user.speciality =
          user?.primary_specialties?.speciality_name === undefined
            ? "Not updated"
            : user?.primary_specialties?.speciality_name;
        user.phone_number =
          user.phone_number === "" ? "Not updated" : user.phone_number;

        user.profession = user?.meta_date?.profession;
        user.sub = user?.meta_date?.sub;
        user.organisation = user?.meta_date?.organisation;
        user.secondaryEmail = user.meta_date?.email2;

        user.year_of_birth = user?.year_of_birth
          ? moment(user?.year_of_birth).format("yyyy")
          : "Not Updated";
        user.speciality =
          user?.primary_specialties?.speciality_name === undefined
            ? "Not updated"
            : user?.primary_specialties?.speciality_name;
        user.mobile1 = phone_number === "" ? "Not updated" : phone_number;

        user.mobile2 =
          user.meta_date?.mobile2 === ""
            ? "Not updated"
            : user.meta_date?.mobile2;
        user.phone_number =
          user?.meta_date?.phone === ""
            ? "Not updated"
            : user?.meta_date?.phone;
        user.respondent_id =
          user?.respondent_id === "" ? "Not updated" : user?.respondent_id;
        user.secondarySpeciality =
          user?.secondary_specialties?.speciality_name === ""
            ? "Not updated"
            : user?.secondary_specialties?.speciality_name;
        user.noOfBeds = user?.meta_date?.noOfBeds;
        user.zone = user?.meta_date?.zone;
        user.cityCategory = user?.meta_date?.cityCategory;
        user.hospitalType = user?.meta_date?.hospitalType;
        user.consultingHospital1 = user?.meta_date?.hospital1;
        user.consultingHospital2 = user?.meta_date?.hospital2;
        user.projectCompatibility =
          user?.project_compatibilitys?.length > 0
            ? user?.project_compatibilitys?.join(",")
            : "Not updated";
        user.startedPracticing = user?.meta_date?.yearOfPractice
          ? moment(user?.meta_date?.yearOfPractice).format("yyyy")
          : "Not updated";
        user.totalIncentivesProcessed = user?.meta_date?.totalIncentive
          ? user?.meta_date?.totalIncentive
          : "Not updated";
        user.previouslyParticipatedStudies = user?.meta_date?.previousStudies
          ? user?.meta_date?.previousStudies
          : "Not updated";
        user.modeOfPayment = Array.isArray(user?.meta_date?.payment)
          ? user?.meta_date?.payment?.length
            ? user?.meta_date?.payment
              ?.map((info) => {
                return info.label;
              })
              .join(",")
            : "Not Updated"
          : user?.meta_date?.payment || "Not Updated";
        user.validatedDate = user?.meta_date?.yearOfPractice
          ? moment(user?.meta_date?.yearOfPractice).format("DD-MM-YYYY")
          : "Not updated";
        user.status = user?.status ? user?.status : "Not updated";
        user.access = user?.meta_date?.access ? "Yes" : "No";
        user.experience =
          moment().format("yyyy") -
          moment(user?.meta_date?.yearOfPractice).format("yyyy");
        user.assID = user?.ass_interviewer_id ? user?.ass_interviewer_id : null;
        user.assStatus = user?.ass_interviewer_status
          ? user?.ass_interviewer_status
          : false;
      });
      setTableData(copy.user);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    let newList = [];
    let leavearray = {};
    setInterviewerList(interviewerList);
    if (interviewerData !== undefined && interviewerData.user !== undefined) {
      interviewerData.user.forEach((interviewer) => {
        if (interviewerList !== undefined && Array.isArray(interviewerList)) {
          interviewerList.forEach((el) => {
            if (interviewer.id === el) {
              newList.push(interviewer);
              leavearray[[interviewer.id]] =
                interviewer?.meta_date?.availability;
            }
          });
        }
      });
    }
    setCopy(newList);
    // newArray.push(leavearray);
    setInterviewerLeaveList(leavearray);
    // eslint-disable-next-line
  }, [props, interviewerData]);

  const additionalColumns = useCallback(
    (data, isShowData, type) => {
      let colList = Object.keys(data || {}).length
        ? Object.keys(data || {}).map((additionalData, additionalIndex) => {
          return {
            title: additionalData?.toUpperCase(),
            dataIndex: additionalData,
            key: additionalData,
            ...getColumnSearchProps(additionalData, additionalData),
            isShow: isShowData[additionalData]?.enabled,
            className: isShowData[additionalData]?.enabled
              ? "table-cell"
              : "hidden",
            width: 300,
            render: (text, record) => {
              return (
                <div className="flex flex-col items-start justify-start">
                  {text === "Not updated" || !text ? "Not updated" : text}
                </div>
              );
            },
          };
        })
        : [];
      return colList;
    },
    [columnStatus]
  );

  const [interviewerList, setInterviewerList] = useState(
    editAssignInterviewer?.ass_interviewer_details?.f_name || null
  );

  const handleValidationValues = (value, membersIndex, leaveList) => {
    let newArray = [...interviewerList];
    let newLeaveList = [...interviewerLeaveList];

    if (memberIndex === undefined) {
      let selectedMemberId = selectedMembers.map((member) => member.id);
      tableData.map((data, dataIndex) => {
        if (selectedMemberId.includes(data.id)) {
          newArray[dataIndex] = value;
          newLeaveList[dataIndex] = leaveList;
        }
      });
      setSelectedMemberKeys([]);
    } else {
      newArray[membersIndex] = value;
      newLeaveList[membersIndex] = leaveList;
    }
    setInterviewerList(newArray);
    setInterviewerLeaveList(newLeaveList);
  };

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setFilteredValue([status]);
  };

  const handleFilterReset = () => {
    setSelectedStatus(null);
    setFilteredValue(null);
  };

  const sortStatus = (statusData, selectedStatus) => {
    if (selectedStatus === "raw") {
      return statusData.filter((data) => data.status === "raw");
    } else if (selectedStatus === "partial-email") {
      return statusData.filter((data) => data.status === "partial-email");
    } else if (selectedStatus === "partial-phone") {
      return statusData.filter((data) => data.status === "partial-phone");
    } else if (selectedStatus === "invalid") {
      return statusData.filter((data) => data.status === "invalid");
    } else if (selectedStatus === "not-validated") {
      return statusData.filter((data) => data.status === "not-validated");
    }
    return statusData;
  };

  const InterviewerAssignmentColumns = useMemo(
    () =>
      [
        {
          title: "Respondent ID",
          dataIndex: "respondent_id",
          key: "respondent_id",
          ...getColumnSearchProps("respondent_id", "respondent_id"),
          isShow: columnStatus?.respondent_id?.enabled,
          className: columnStatus?.respondent_id?.enabled
            ? "table-cell whitespace-nowrap"
            : "hidden whitespace-nowrap",
          width: 200,
        },
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.status?.enabled,
          className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
          key: "status",
          width: 100,
          render: (text, record) => {
            return getStatusText(text, record);
          },
          filters: [
            {
              text: "Raw",
              value: "raw",
            },
            {
              text: "Partial Email",
              value: "partial-email",
            },
            {
              text: "Partial Phone",
              value: "partial-phone",
            },
            {
              text: "Not Validated",
              value: "not-validated",
            },
            {
              text: "Invalid",
              value: "invalid",
            },
          ],
          // filteredValue: filteredValue ? [filteredValue] : undefined,
          onFilter: (value, record) => {
            const result = record.status.indexOf(value) === 0;
            // setSelectedStatus(value);
            return result;
          },
          // filterMultiple: false,
          filterIcon: () => <FilterOutlined />,
          onFilterDropdownVisibleChange: (visible) => {
            if (!visible) {
              // console.log(visible, 'visible');
              // setFilteredValue(null);
              handleFilterReset();
            }
          },
        },
        {
          title: "TITLE",
          dataIndex: "title",
          key: "title",
          width: 100,
          ...getColumnSearchProps("title", "title"),
          isShow: columnStatus?.title?.enabled,
          className: columnStatus?.title?.enabled ? "table-cell" : "hidden",
        },
        {
          title: "NAME",
          dataIndex: "name",
          key: "name",
          ...getColumnSearchProps("name", "name"),
          isShow: columnStatus?.name?.enabled,
          className: columnStatus?.name?.enabled ? "table-cell" : "hidden",
          width: 200,
        },
        {
          title: "PRIMARY EMAIL",
          dataIndex: "email",
          key: "email",
          width: 200,
          ...getColumnSearchProps("email", "email"),
          isShow: columnStatus?.email?.enabled,
          className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "SECONDARY EMAIL",
          dataIndex: "secondaryEmail",
          key: "email2",
          width: 200,
          ...getColumnSearchProps("secondaryEmail", "secondaryEmail"),
          isShow: columnStatus?.secondaryEmail?.enabled,
          className: `${columnStatus?.secondaryEmail?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,

          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
                : text}
            </div>
          ),
        },
        {
          title: "SPECIALITY",
          dataIndex: "speciality",
          key: "speciality",
          isShow: columnStatus?.speciality?.enabled,
          className: columnStatus?.speciality?.enabled
            ? "table-cell"
            : "hidden",
          width: 300,
          ...getColumnSearchProps("speciality", "speciality"),
        },
        {
          title: "SECONDARY SPECIALITY",
          dataIndex: "secondarySpeciality",
          key: "secondarySpeciality",
          isShow: columnStatus?.secondarySpeciality?.enabled,
          className: columnStatus?.secondarySpeciality?.enabled
            ? "table-cell"
            : "hidden",
          width: 300,
          ...getColumnSearchProps("secondarySpeciality", "secondarySpeciality"),
        },
        {
          title: "HOSPITAL",
          dataIndex: "meta_date",
          key: "hospital",
          width: 300,
          ...getColumnSearchProps("meta_date.hospital", "hospital name"),
          isShow: columnStatus?.hospital?.enabled,
          className: columnStatus?.hospital?.enabled ? "table-cell" : "hidden",
          render: (_, record) => (
            <div className="flex flex-col items-start justify-start">
              <div className="text-h8 text-textMedium mb-1 font-semibold">
                {record?.meta_date?.hospital}
              </div>
              <div className="text-h8 text-textLight">
                {record?.meta_date?.hospitalType}
              </div>
            </div>
          ),
        },
        {
          title: "GENDER",
          dataIndex: "genderNew",
          key: "gender",
          isShow: columnStatus?.genderNew?.enabled,
          className: columnStatus?.genderNew?.enabled ? "table-cell" : "hidden",
          filters: [
            {
              text: "Male",
              value: "M",
            },
            {
              text: "Female",
              value: "F",
            },
            {
              text: "Not updated",
              value: "Not updated",
            },
          ],
          onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) =>
            text === "M" ? (
              <>Male</>
            ) : text === "F" ? (
              <>Female</>
            ) : (
              <>Not updated</>
            ),
          width: 300,
        },
        {
          title: "YEAR OF BIRTH",
          dataIndex: "year_of_birth",
          key: "year",
          width: 200,
          isShow: columnStatus?.year_of_birth?.enabled,
          className: `${columnStatus?.year_of_birth?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          ...getColumnDateSearchProps("year_of_birth", "year"),
        },
        {
          title: "PHONE NUMBER",
          dataIndex: "phone_number",
          key: "phone",
          ...getColumnSearchProps("phone_number", "phone"),
          isShow: columnStatus?.phone_number?.enabled,
          className: `${columnStatus?.phone_number?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 1",
          dataIndex: "mobile1",
          key: "mobile1",
          ...getColumnSearchProps("mobile1", "phone"),
          isShow: columnStatus?.mobile1?.enabled,
          className: `${columnStatus?.mobile1?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "MOBILE NUMBER 2",
          dataIndex: "mobile2",
          key: "mobile2",
          ...getColumnSearchProps("mobile2", "phone"),
          isShow: columnStatus?.mobile2?.enabled,
          className: `${columnStatus?.mobile2?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {role === "manager" && text !== null && text
                ? text === "Not updated"
                  ? "Not updated"
                  : `${text.substring(0, 3)}XXXXXXX`
                : text}
            </div>
          ),
        },
        {
          title: "STATE",
          dataIndex: "state",
          key: "state",
          ...getColumnSearchProps("state", "state"),
          isShow: columnStatus?.state?.enabled,
          className: columnStatus?.state?.enabled ? "table-cell" : "hidden",
          width: 200,
        },
        {
          title: "CITY",
          dataIndex: "city",
          key: "city",
          ...getColumnSearchProps("city", "city"),
          isShow: columnStatus?.city?.enabled,
          className: columnStatus?.city?.enabled ? "table-cell" : "hidden",
          width: 200,
        },
        {
          title: "ZONE",
          dataIndex: "zone",
          key: "zone",
          ...getColumnSearchProps("zone", "zone"),
          isShow: columnStatus?.zone?.enabled,
          className: columnStatus?.zone?.enabled ? "table-cell" : "hidden",
          width: 200,
        },
        {
          title: "CITY CATEGORY",
          dataIndex: "cityCategory",
          key: "cityCategory",
          ...getColumnSearchProps("cityCategory", "cityCategory"),
          isShow: columnStatus?.cityCategory?.enabled,
          className: `${columnStatus?.cityCategory?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          width: 200,
        },
        {
          title: "PINCODE",
          dataIndex: "pincode",
          key: "pincode",
          ...getColumnSearchProps("pincode", "pincode"),
          isShow: columnStatus?.pincode?.enabled,
          className: columnStatus?.pincode?.enabled ? "table-cell" : "hidden",
          width: 200,
        },
        {
          title: "HOSPITAL TYPE",
          dataIndex: "hospitalType",
          // ...getColumnSearchProps("hospitalType", "hospitalType"),
          isShow: columnStatus?.hospitalType?.enabled,
          className: `${columnStatus?.hospitalType?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          filters: [
            {
              text: "Government",
              value: "Government",
            },
            {
              text: "Private",
              value: "Private",
            },
            {
              text: "Non-Profit",
              value: "Non-Profit",
            },
            {
              text: "Others",
              value: "Others",
            },
          ],
          onFilter: (value, record) =>
            (record.hospitalType || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          key: "hospitalType",
          width: 200,
        },
        {
          title: "NO. OF BEDS",
          dataIndex: "noOfBeds",
          // ...getColumnSearchProps("noOfBeds", "noOfBeds"),
          isShow: columnStatus?.noOfBeds?.enabled,
          className: `${columnStatus?.noOfBeds?.enabled ? "table-cell" : "hidden"
            } whitespace-nowrap`,
          key: "noOfBeds",
          filters: [
            {
              value: "NA",
              text: "NA",
            },
            {
              value: "1 to 10",
              text: "1 to 10",
            },
            {
              value: "11 to 25",
              text: "11 to 25",
            },
            {
              value: "26 to 50",
              text: "26 to 50",
            },
            {
              value: "51 to 75",
              text: "51 to 75",
            },
            {
              value: "76 to 100",
              text: "76 to 100",
            },
            {
              value: "101 to 150",
              text: "101 to 150",
            },
            {
              value: "151 to 200",
              text: "151 to 200",
            },
            {
              value: "200+",
              text: "200+",
            },
          ],
          onFilter: (value, record) =>
            (record.noOfBeds || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text) => (
            <div className="flex flex-col items-start justify-start">
              {text === "Not updated" || !text ? "Not updated" : text}
            </div>
          ),
          width: 200,
        },
        {
          title: "CONSULTING HOSPITAL 1",
          dataIndex: "consultingHospital1",
          ...getColumnSearchProps("consultingHospital1", "consultingHospital1"),
          isShow: columnStatus?.consultingHospital1?.enabled,
          className: columnStatus?.consultingHospital1?.enabled
            ? "table-cell"
            : "hidden",
          key: "consultingHospital1",
          width: 200,
        },
        {
          title: "CONSULTING HOSPITAL 2",
          dataIndex: "consultingHospital2",
          ...getColumnSearchProps("consultingHospital2", "consultingHospital2"),
          isShow: columnStatus?.consultingHospital2?.enabled,
          className: columnStatus?.consultingHospital2?.enabled
            ? "table-cell"
            : "hidden",
          key: "consultingHospital2",
          width: 200,
        },
        {
          title: "STARTED PRACTICING (POST RESIDENCY)",
          dataIndex: "startedPracticing",
          ...getColumnSearchProps("startedPracticing", "startedPracticing"),
          isShow: columnStatus?.startedPracticing?.enabled,
          className: columnStatus?.startedPracticing?.enabled
            ? "table-cell"
            : "hidden",
          key: "startedPracticing",
          width: 200,
        },
        {
          title: "PROJECT COMPATIBILITY",
          dataIndex: "projectCompatibility",
          ...getColumnSearchProps(
            "projectCompatibility",
            "projectCompatibility"
          ),
          isShow: columnStatus?.projectCompatibility?.enabled,
          className: columnStatus?.projectCompatibility?.enabled
            ? "table-cell"
            : "hidden",
          key: "projectCompatibility",
          width: 200,
        },
        {
          title: "TOTAL INCENTIVES PROCESSED",
          dataIndex: "totalIncentivesProcessed",
          ...getColumnSearchProps(
            "totalIncentivesProcessed",
            "totalIncentivesProcessed"
          ),
          isShow: columnStatus?.totalIncentivesProcessed?.enabled,
          className: columnStatus?.totalIncentivesProcessed?.enabled
            ? "table-cell"
            : "hidden",
          key: "totalIncentivesProcessed",
          width: 200,
        },
        {
          title: "PREVIOUSLY PARTICIPATED STUDIES",
          dataIndex: "previouslyParticipatedStudies",
          ...getColumnSearchProps(
            "previouslyParticipatedStudies",
            "previouslyParticipatedStudies"
          ),
          isShow: columnStatus?.previouslyParticipatedStudies?.enabled,
          className: columnStatus?.previouslyParticipatedStudies?.enabled
            ? "table-cell"
            : "hidden",
          key: "previouslyParticipatedStudies",
          width: 200,
        },
        {
          title: "MODE OF PAYMENT",
          dataIndex: "modeOfPayment",
          ...getColumnSearchProps("modeOfPayment", "modeOfPayment"),
          isShow: columnStatus?.modeOfPayment?.enabled,
          className: columnStatus?.modeOfPayment?.enabled
            ? "table-cell"
            : "hidden",
          key: "modeOfPayment",
          width: 200,
        },
        {
          title: "VALIDATED DATE",
          dataIndex: "validatedDate",
          ...getColumnSearchProps("validatedDate", "validatedDate"),
          isShow: columnStatus?.validatedDate?.enabled,
          className: columnStatus?.validatedDate?.enabled
            ? "table-cell"
            : "hidden",
          key: "validatedDate",
          width: 200,
        },
        {
          title: "YEARS OF EXPERIENCE",
          dataIndex: "experience",
          ...getColumnSearchProps("experience", "experience"),
          isShow: columnStatus?.experience?.enabled,
          className: columnStatus?.experience?.enabled
            ? "table-cell"
            : "hidden",
          key: "experience",
          width: 200,
        },
        {
          title: "Access to Mobile App",
          dataIndex: "access",
          isShow: columnStatus?.access?.enabled,
          className: columnStatus?.access?.enabled ? "table-cell" : "hidden",
          key: "access",
          filters: [
            {
              value: "Yes",
              text: "Yes",
            },
            {
              value: "No",
              text: "No",
            },
          ],
          onFilter: (value, record) =>
            (record.access || "").indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          width: 200,
        },
        {
          title: "Action",
          dataIndex: "action",
          fixed: "right",
          width: 180,
          render: (value, item, index) => {
            return (
              <>
                {item &&
                  item?.assStatus === true &&
                  item &&
                  item?.assID === null ? (
                  <Button
                    type="link"
                    className="text-base p-0 text-left"
                    disabled
                  >
                    Waiting for approval
                  </Button>
                ) : (
                  <>
                    {item && item?.assID !== null ? (
                      <Button
                        type="link"
                        className="text-base p-0 text-left"
                        onClick={() => {
                          // setMemberIndex(index);
                          setSingleMember(false);
                          setEnableDrawer(true);
                          const rowData = item;
                          setUserID(rowData?.respondent_id);
                          setEditAssignInterviewer(rowData);
                          setInterviewerList(
                            rowData?.ass_interviewer_details?.f_name + " " + rowData?.ass_interviewer_details?.l_name
                          );
                          setIntPrivileges([rowData?.status])
                        }}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Button
                        type="link"
                        className="text-base p-0 text-left"
                        onClick={() => {
                          setInterviewerList("");
                          // setMemberIndex(index);
                          setSingleMember(false);
                          setEnableDrawer(true);
                          const rowData = item;
                          setUserID(rowData?.respondent_id);
                          setEditAssignInterviewer(rowData);
                          setIntPrivileges([rowData?.status])
                        }}
                      >
                        Assign
                      </Button>
                    )}
                  </>
                )}
              </>
            );
          },
        },
        ...additionalColumns(),
      ].filter((data) => data.isShow === undefined || data.isShow),
    [columnStatus, refetch, role, additionalColumns, filteredValue]
  );

  const filteredData2 = selectedStatus
    ? sortStatus(tableData, selectedStatus)
    : tableData;

  const handleChange = (e) => {
    setInterviewerList(e);
  };

  const removeItem = (value) => {
    const newList = interviewerList.filter((item) => item !== value);
    const copyList = copy.filter((item) => item.id !== value);
    setCopy(copyList);
    setInterviewerList(newList);
  };

  const onSave = async () => {
    try {
      let assignData, isAssign;
      if (interviewerList) {
        isAssign = true;
        ({ data: assignData } = await assignInterviewerMembers({
          variables: {
            userId: typeof userID === "number" ? [userID] : userID,
            interviewerId:
              typeof interviewerList === "number"
                ? interviewerList
                : editAssignInterviewer?.ass_interviewer_id,
          },
        }));
      } else {
        isAssign = false;
        ({ data: assignData } = await unassignInterviewerMembers({
          variables: {
            userId: userID,
            interviewerId: null,
          },
        }));
      }
      refetch();
      if (
        assignData &&
        (isAssign
          ? assignData.assignInterviewerMembers
          : assignData.unassignInterviewerMembers)
      ) {
        handleValidationValues(
          interviewerList,
          memberIndex,
          interviewerLeaveList,
          isAssign
            ? assignData.assignInterviewerMembers
            : assignData.unassignInterviewerMembers
        );
      }
      setEnableDrawer(false);
      setSelectedMemberKeys([]);
      setSelectedMembers([]);
      setSingleMember(false);
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const onCancel = () => {
    setEnableDrawer(false);
    form.resetFields();
  };

  const selectedMember = memberIndex === undefined ? selectedMembers : [];

  const isRowDisabled = (item) => {
    return item && item?.assStatus === true && item && item?.assID === null;
  };

  const handleRowClick = (item) => {
    if (isRowDisabled(item)) {
      return;
    }
  };

  const rowProps = (item) => ({
    onClick: isRowDisabled(item) ? null : () => handleRowClick(item),
  });

  const mappedPrivileges = intPrivileges.map(privilege => {
    switch (privilege) {
      case 'partial-email':
        return 'partialEmail';
      case 'invalid':
        return 'invalid';
      case 'not-validated':
        return 'notValidated';
      case 'partial-phone':
        return 'partialPhone';
      case 'raw':
        return 'raw';
      default:
        return '';
    }
  });

  const interviewerTrueRecords = (propertyNames) => {
    return interviewerData?.user?.filter(user => {
      return propertyNames.every(propertyName => user.int_privileges[propertyName] === true);
    });
  };

  const filteredRecords = interviewerTrueRecords(mappedPrivileges);
  const valuesArray = selectedMember.map(
    (member) => member?.respondent_id
  );
  const statusArray = selectedMember.map(
    (member) => member?.status
  );
  const areAllElementsSame = statusArray.every((status, index, arr) => status === arr[0]);
  const bulkAssign = () => {
    if (selectedMember && selectedMember.length > 0) {
      setInterviewerList("");
      setSingleMember(true);
      setMemberIndex();
      setEnableDrawer(true);
      setUserID(valuesArray);
      setIntPrivileges(statusArray)
    }
  }
  const handleWarningModalCancel = () => {
    setShowWarningModal(false);
  };
  const handleWarningModalOk = () => {
    setShowWarningModal(false);
    bulkAssign();
  }
  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="text-textMedium text-h5 font-semibold">
          Assign interviewers
        </div>
        <div className="flex items-center justify-between">
          {!!selectedMembers?.length && (
            <Button
              type="primary"
              className="px-10 rounded-md ml-5"
              size="large"
              onClick={() => {
                if (selectedMember && selectedMembers?.length > 1 && !areAllElementsSame) {
                  setShowWarningModal(true);
                } else {
                  bulkAssign();
                }
              }}
            >
              Assign
            </Button>
          )}
          <Modal
            title="Note"
            visible={showWarningModal}
            onOk={handleWarningModalOk}
            onCancel={handleWarningModalCancel}
            okText="Proceed"
          >
            <div>Only interviewers with common edit permissions as the selected members will be displayed.
            </div>
            <div>If you wish to proceed individually, please cancel and assign individually.
              Alternatively, you can filter members of common status to view all available interviewers.</div>
          </Modal>
          <ColumnEditor
            columns={columnStatus}
            onSave={(column) => {
              setStatus(column);
            }}
          />
        </div>
      </div>
      <Row className=" w-full mt-2 mb-5">
        <Col span={24} className="">
          <div className="gap-6 w-full">
            <div className="shadow-lg bg-white rounded-md flex justify-between gap-10">
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow cursor-pointer
                            rounded-l-lg p-6 ${selectedStatus === null ? "" : ""
                  }`}
                onClick={handleFilterReset}
              >
                <MemberStatsData
                  label={"Total"}
                  totalCount={statusCount?.total_count?.aggregate?.count}
                />
              </div>
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap  cursor-pointer
                            flex-grow p-6 ${selectedStatus === "raw" ? "bg-bgMedium" : ""
                  }`}
                onClick={() => handleStatusClick("raw")}
              >
                <MemberStatsData
                  label={"Raw"}
                  totalCount={statusCount?.raw_count?.aggregate?.count}
                />
              </div>
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${selectedStatus === "partial-email"
                    ? "bg-bgMedium"
                    : ""
                  }`}
                onClick={() => handleStatusClick("partial-email")}
              >
                <MemberStatsData
                  label={"Partial Email"}
                  totalCount={statusCount?.partial_email?.aggregate?.count}
                />
              </div>
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${selectedStatus === "partial-phone"
                    ? "bg-bgMedium"
                    : ""
                  }`}
                onClick={() => handleStatusClick("partial-phone")}
              >
                <MemberStatsData
                  label={"Partial Phone"}
                  totalCount={statusCount?.partial_phone?.aggregate?.count}
                />
              </div>
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${selectedStatus === "not-validated"
                    ? "bg-bgMedium"
                    : ""
                  }`}
                onClick={() => handleStatusClick("not-validated")}
              >
                <MemberStatsData
                  label={"Not validated"}
                  totalCount={
                    statusCount?.not_validated_count?.aggregate?.count
                  }
                />
              </div>
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap cursor-pointer
                            flex-grow p-6 ${selectedStatus === "invalid" ? "bg-bgMedium" : ""
                  }`}
                onClick={() => handleStatusClick("invalid")}
              >
                <MemberStatsData
                  label={"Invalid"}
                  totalCount={statusCount?.invalid_count?.aggregate?.count}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Form>
        <div className="selectMembers py-4 w-full">
          <Form.Item className="block" name="members">
            <Spin spinning={loading}>
              <MemberTable
                columns={InterviewerAssignmentColumns}
                tableData={filteredData2}
                pagination={false}
                rowClassName={(item) =>
                  isRowDisabled(item) ? "disabled-row" : ""
                }
                onRow={(item) => rowProps(item)}
                rowSelection={{
                  selectedRowKeys: selectedMemberKeys,
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows, info) => {
                    setSelectedMemberKeys(selectedRowKeys);
                    setSelectedMembers(selectedRows);
                  },
                  getCheckboxProps: (item) => ({
                    disabled: isRowDisabled(item),
                  }),
                }}
              ></MemberTable>
            </Spin>
          </Form.Item>
        </div>
      </Form>
      {enableDrawer === true && (
        <>
          <Drawer
            title={
              <span className="text-textMedium text-lg">Interviewers</span>
            }
            width={720}
            onClose={onCancel}
            visible={enableDrawer}
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable={false}
            destroyOnClose={true}
            footer={
              <>
                <div className=" flex justify-end items-end">
                  <Button
                    type="link"
                    className="text-base"
                    htmlType="button"
                    style={{ marginRight: 8 }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  {!!(copy || []).length && (
                    <ViewActiveButton
                      ghost={true}
                      memberId={copy.map((member) => member.id)}
                    />
                  )}

                  <Button
                    onClick={onSave}
                    type="primary"
                    className="text-base rounded-sm w-28"
                  >
                    Save
                  </Button>
                </div>
              </>
            }
          >
            {singleMember === true && !!selectedMember?.length && (
              <div className=" mb-5">
                <span className="text-base text-textLight mb-1">
                  Assign interviewers for
                </span>
                {selectedMember?.map((member, memberIndex) => {
                  return (
                    <div
                      key={`Member-${memberIndex}`}
                      className="text-textMedium mb-1 font-medium"
                    >{`${memberIndex + 1}. ${member.f_name}`}</div>
                  );
                })}
              </div>
            )}
            <Form form={form} layout="vertical">
              <Form.Item
                label={
                  <span className="text-base text-textLight">
                    Assign interviewers
                  </span>
                }
                name="Add Manager"
              >
                <Select
                  placeholder="Select"
                  mode="single"
                  showArrow
                  style={{ width: "100%" }}
                  onChange={(e) => handleChange(e)}
                  value={interviewerList}
                  showSearch
                  notFoundContent="No interviewer available for validating this status."
                  optionFilterProp="children"
                  allowClear={() => setInterviewerList("")}
                >
                  {filteredRecords?.map((interviewer) => {
                    return (
                      <Option key={interviewer.id} value={interviewer.id}>
                        {interviewer?.f_name} {interviewer?.l_name}
                      </Option>
                    );
                  })}
                </Select>
                <List
                  size="large"
                  bordered
                  dataSource={copy}
                  pagination={false}
                  renderItem={(item) => (
                    <List.Item
                      extra={
                        <MinusCircleOutlined
                          style={{ color: "red" }}
                          onClick={() => removeItem(item.id)}
                        />
                      }
                    >
                      {item.f_name + " " + item.l_name}
                    </List.Item>
                  )}
                  style={{ minHeight: "450px", width: "100%", border: 0 }}
                />
              </Form.Item>
            </Form>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default InterviewerAssignment;
