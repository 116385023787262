export function getStatusText(text, record) {
    if (text === "valid") {
        return "Valid";
    } else if (text === "raw") {
        return "Raw";
    } else if (text === "partial-email") {
        return "Partial Email";
    } else if (text === "partial-phone") {
        return "Partial Phone";
    } else if (text === "not-validated") {
        return "Not Validated";
    } else if (text === "invalid") {
        return "Invalid";
    } else if (text === "DNC") {
        return "DNC";
    } else if (text === "DNC" && record.dncCount === 3) {
        return "Prohibited";
    } else {
        return text;
    }
}

export const generateFilterOptions = (filterValues) => {
    return filterValues.map((option) => {
        return {
            text: option.text,
            value: option.value,
        };
    });
};

export const filterValues = [
    { text: "Valid", value: "Valid" },
    { text: "Raw", value: "raw" },
    { text: "Partial Email", value: "partial-email" },
    { text: "Partial Phone", value: "partial-phone" },
    { text: "Not Validated", value: "not-validated" },
    { text: "Invalid", value: "invalid" },
    { text: "DNC", value: "DNC" },
    { text: "Prohibited", value: "Prohibited" },
];