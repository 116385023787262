import React, { useEffect } from "react";
import { QUOTA_FULL } from "../../../src/pages/survey/graphql";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";

const SurveyQuotaFull = (props) => {
  const params = useParams();
  const [changeSurveyStatusToQuotaFull] = useMutation(QUOTA_FULL);
  useEffect(() => {
    if (
      props.quotacount !== undefined &&
      props.sampleSize !== undefined &&
      props.quotacount !== "" &&
      props.sampleSize !== "" &&
      props.quotacount === props.sampleSize &&
      props.quota !== props.completed
    ) {
      changeSurveyStatusToQuotaFull({
        variables: {
          survey_id: Number(params.surveyID),
          member_id: Number(params.memberID),
        },
      })
        .then(() => {
          localStorage.removeItem("memberID");
          localStorage.removeItem("surveyID");
        })
        .catch(() => {
          console.log("Error");
        });
    }
    // eslint-disable-next-line
  }, [props]);

  return (
    <div
      className="p-4 lg:py-48 lg:p-0 flex flex-col items-center justify-start bg-white shadow"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <div className="bg-background p-4 flex flex-col items-center justify-center w-full lg:w-1/2 text-center">
        <div className="text-h5 text-textDark font-semibold mb-2">
          Thank you for responding to our survey.
        </div>
        <div className="text-h11 text-textDark mb-2">
          Your views are important.
        </div>
        <p className="text-h8 text-textMedium text-center mb-4">
          The quote group that you qualified for is complete and we have already
          achieved enough responses for this specific target group. Please know
          your time and efforts are greatly appreciated. As a token of our
          appreciation, you have been entered in our monthly sweepstakes
          competition.
        </p>
        <div className="text-textLight text-h11">
          For any queries, please mail{" "}
          <a
            href="mailto:panelsupport@krea.in"
            className="decoration-solid underline"
          >
            panelsupport@krea.in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SurveyQuotaFull;
