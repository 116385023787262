import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query GET_USERS {
    user(order_by: {id: asc}, where: {role_id: {_eq: 6}, status: {_nin: ["Valid","DNC","Prohibited"]}}) {
      id
      email
      f_name
      l_name
      status
      phone_number
      title
      respondent_id
      ass_interviewer_status
      additional_data
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      role {
        id
        name
        description
      }
      is_active
      member_type_id
      year_of_birth
      gender
      meta_date
      member_type {
        name
      }
      is_verified
      state
      city
      pincode
      ass_interviewer_id
      ass_interviewer_details {
      f_name
      l_name
      id
      email
    }
      project_compatibilitys
      projectCompatibilityByProjectCompatibility {
        project_compatibility_text
      }
    }
  }
`;

export const STATUS_COUNTS = gql`
  query STATUS_COUNTS {
    total_count: user_aggregate(where: {role_id: {_eq: 6},status: {_nin: ["Valid","DNC","Prohibited"]} }) {
      aggregate {
        count(columns: status)
      }
    }
    raw_count: user_aggregate(where: { status: { _eq: "raw" }, _and: {role_id: {_eq: 6}}}) {
      aggregate {
        count(columns: status)
      }
    }
    partial_email: user_aggregate(where: { status: { _eq: "partial-email" }, _and: {role_id: {_eq: 6}}}) {
      aggregate {
        count(columns: status)
      }
    }
    partial_phone: user_aggregate(where: { status: { _eq: "partial-phone" }, _and: {role_id: {_eq: 6}}}) {
      aggregate {
        count(columns: status)
      }
    }
    not_validated_count: user_aggregate(where: { status: { _eq: "not-validated" }, _and: {role_id: {_eq: 6}}}) {
      aggregate {
        count(columns: status)
      }
    }
    invalid_count: user_aggregate(where: { status: { _eq: "invalid" } , _and: {role_id: {_eq: 6}}}) {
      aggregate {
        count(columns: status)
      }
    }
  }
`;

export const GET_INTERVIEWER = gql`
  query GET_INTERVIEWER {
    user(where: { role_id: { _eq: 4 } }) {
      f_name
      l_name
      id
      meta_date
      int_privileges
    }
  }
`;

export const UNASSIGN_MEMBERS = gql`
mutation UNASSIGN_MEMBERS($userId: bigint!, $id: Int) {
  update_user(where: {respondent_id: {_eq: $userId}}, _set: {ass_interviewer_id: $id}) {
    affected_rows
  }
}
`;

export const ASSIGN_INTERVIEWER_MEMBERS = gql`
mutation ASSIGN_INTERVIEWER_MEMBERS ($userId: [bigint!]!, $interviewerId: Int!) {
    update_user(where: {respondent_id: {_in: $userId}}, _set: {ass_interviewer_id: $interviewerId}) {
    returning {
      email
      f_name
      gender
      id
    }
  }
}
`