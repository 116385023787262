import React, { useState, useEffect, useMemo } from "react";
import { notification, Button } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_MEMBERS_LIST_EXTERNAL,
  RESEND_INVITE,
  PROJECT_DETAILS,
} from "../graphql";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import ColumnEditor from "../../../components/ColumnEditor";
import MemberTable from "../../../components/memberTable";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../components/memberColumns";
import { RoleContext } from "../../../context/RoleContext";
import SpecialityModalPop from "../../../utils/specialitySearch";

function MemberListTab() {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID, projectID } = useParams();
  const location = useLocation();
  const [role, setRole] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [resendInvite] = useMutation(RESEND_INVITE);
  const [isOnline, setIsOnline] = useState();
  const savedviewSurveyAssitedList = localStorage.getItem('viewSurveyAssitedList');
  const viewSurveyAssitedListinitial = savedviewSurveyAssitedList
    ? JSON.parse(savedviewSurveyAssitedList)
    : {
      ...memberColumnStatus,
      rewardedOn: { enabled: true, label: "Rewarded On" },
    }
  const [columnStatus, setStatus] = useState(viewSurveyAssitedListinitial);
  let searchInput;
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);


  const { data: members } = useQuery(GET_MEMBERS_LIST_EXTERNAL, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });
  useEffect(() => {
    localStorage.setItem('viewSurveyAssitedList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      let tempCol = {
        ...columnStatus,
        status: { enabled: true, label: "Status" },
      };
      setStatus(tempCol);
    }
  }, [isOnline]);

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      setIsOnline(res.project[0].is_online_or_assisted);
    },
  });
  /*useEffect(() => {
    if (members) {
      let object = JSON.parse(JSON.stringify(members?.user));
      object.forEach((item) => {
        item.category = item.member_type.name;
        item.memberName =
          item?.l_name !== null
            ? item?.f_name + " " + item?.l_name
            : item?.f_name;
        item.phone_number =
          item.phone_number === "" ? "Not updated" : item.phone_number;
        item.clientLink =
          location.pathname.split("-")[1] === "Third_Party_survey"
            ? item?.survey_x_members[0]?.survey?.links_status
            : item?.survey_x_members[0]?.survey_url;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;

        if (
          item.survey_x_members[0].survey_x_member_x_interviewers_aggregate
            .aggregate.count === 1
        ) {
          item.interviewer =
            item.survey_x_members[0].active.length === 0
              ? item.survey_x_members[0].leave[0].interviewer.l_name !== null
                ? (item.survey_x_members[0].leave[0].interviewer.f_name || "" +
                  " " +
                  item.survey_x_members[0].leave[0].interviewer.l_name || "")
                : item.survey_x_members[0].leave[0].interviewer.f_name || ""
              : item.survey_x_members[0].active[0].interviewer.l_name !== null
                ? (item.survey_x_members[0].active[0].interviewer.f_name || "") +
                " " +
                (item.survey_x_members[0].active[0].interviewer.l_name || "")
                : (item.survey_x_members[0].active[0].interviewer.f_name || "");
        } else {
          item.interviewer =
            item.survey_x_members[0].active[0]?.interviewer.l_name !== null
              ? (item.survey_x_members[0].active[0]?.interviewer.f_name || "") +
              " " +
              (item.survey_x_members[0].active[0]?.interviewer.l_name || "")
              : (item.survey_x_members[0].active[0]?.interviewer.f_name || "");
        }
      });

      setTableData(object);
    }
    // eslint-disable-next-line
  }, [members]);*/

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.clientLink =
          location.pathname.split("-")[1] === "Third_Party_survey"
            ? item?.survey_x_members[0]?.survey?.links_status
            : item?.survey_x_members[0]?.survey_url;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;
        item.status =
          item?.survey_x_members[0]?.state === "active" ||
            item?.survey_x_members[0]?.state === "in-progress"
            ? "Not responded"
            : "Responded";
        return item;
      });
      setTableData(object);
    }
  }, [members, baseURL, surveyID]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         ref={(node) => {
  //           searchInput = node;
  //         }}
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //         style={{ width: 188, marginBottom: 8, display: "block" }}
  //       />
  //       <Space>
  //         <Button
  //           type="primary"
  //           onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
  //           icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Search
  //         </Button>
  //         <Button
  //           onClick={() => handleReset(clearFilters)}
  //           size="small"
  //           style={{ width: 90 }}
  //         >
  //           Reset
  //         </Button>
  //       </Space>
  //     </div>
  //   ),
  //   filterIcon: (filtered) => (
  //     <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
  //   ),
  //   onFilter: (value, record) => {
  //     let getRecord = _.get(record, dataIndex);
  //     return getRecord
  //       ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
  //       : "";
  //   },
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => searchInput.select(), 100);
  //     }
  //   },
  // });

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
            : text}
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? text === "Not updated"
              ? "Not updated"
              : `${text.substring(0, 3)}XXXXXXX`
            : text}
        </div>
      ),
    },
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      className: columnStatus?.clientLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("clientLink"),
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "kreaLink",
      className: columnStatus?.kreaLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("kreaLink"),
    },
    {
      title: "INTERVIEWERS",
      dataIndex: "interviewer",
      className: columnStatus?.interviewer?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("interviewer"),
    },
  ];*/

  const handleInvite = (record) => {
    resendInvite({
      variables: {
        user_id: record?.id,
        surveyID: parseInt(surveyID),
      },
    })
      .then(() => {
        notification.success({
          message: "Sent!",
          description: "Invitation has been sent to member",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "An error occurred while sending invitation.",
        });
      });
  };

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const columns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
      ...[
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.status?.enabled,
          className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
          width: 200,
          filters: [
            {
              text: "Not responded",
              value: "Not responded",
            },
            {
              text: "Responded",
              value: "Responded",
            },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text, record) => (
            <>
              {text === "Not responded" ? (
                <>
                  <p className={"text-red text-h8"}>Not responded</p>
                  <Button
                    type="link"
                    className="text-h8 p-0"
                    onClick={() => handleInvite(record)}
                  >
                    Resend Invite
                  </Button>
                </>
              ) : (
                <span className={"text-green text-h8"}>Responded</span>
              )}
            </>
          ),
        },
      ],
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      <MemberTable columns={columns} dataSource={tableData} />
      <SpecialityModalPop
        titleSpec={`Selected ${selectedColumnNameSpec}`}
        visibleSpec={isModalVisibleSpec}
        onOkSpec={handleModalOkSpec}
        onCancelSpec={handleModalCloseSpec}
        searchQuerySpec={searchQuerySpec}
        handleSearchSpec={handleSearchSpec}
        handleCheckboxChangeSpec={handleCheckboxChangeSpec}
        filteredCheckboxDataSpec={filteredCheckboxDataSpec}
        selectedDataSpec={selectedDataSpec}
        initialSelectedDataSpec={initialSelectedDataSpec}
        isSubmitDisabledSpec={isSubmitDisabledSpec}
        handleResetSpec={handleResetSpec}
      />
    </div>
  );
}
export default MemberListTab;
