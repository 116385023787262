import React from "react";

const SurveyCompleted = (props) => {
  return (
    <div
      className="p-4 lg:py-48 lg:p-0 flex flex-col items-center justify-start bg-white shadow"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <div className="bg-background p-4 flex flex-col items-center justify-center w-full lg:w-1/2 text-center">
        <div className="text-h5 text-textDark font-semibold mb-2">
          Thanks a lot for participating in our survey!
        </div>
        <p className="text-h8 text-textMedium text-center mb-4">
          We have recorded your opinions. Your incentive will be processed in
          2-3 weeks vide Bank Transfer/Mobile Wallets and one of our associates
          shall be in touch with you for confirmation.
        </p>
        <div className="text-textLight text-h11">
          For any queries, please mail{" "}
          <a
            href="mailto:panelsupport@krea.in"
            className="decoration-solid underline"
          >
            panelsupport@krea.in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SurveyCompleted;
