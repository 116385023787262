import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import React, { Fragment, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.less";
import PageNotFound from "./components/404page";
import Endlink from "./components/endLink/index";
import PrivateRoute from "./components/privateRoute";
import Approvals from "./pages/approvals";
import ClientList from "./pages/clients";
import ViewClient from "./pages/clients/view";
import ViewDetailsProject from "./pages/clients/viewProject/index";
import CreateClient from "./pages/createClient/createClient";
import CreateProject from "./pages/createProject/createProject";
import CreateSurvey from "./pages/createSurvey/index";
import Dashboard from "./pages/dashboard/index";
import AttendSurvey from "./pages/interviewer/attendsurvey";
import Details from "./pages/interviewer/details";
import ViewResponses from "./pages/interviewer/viewResponses";
import { ForgotPassword } from "./pages/login/forgotPassword";
import LoginPage from "./pages/login/login";
import ResetPassword from "./pages/login/resetPassword";
import SignUp from "./pages/login/signup";
import MembersList from "./pages/members";
import ConsumerForm from "./pages/members/Consumer";
import EditMembers from "./pages/members/editMembers";
import PatientForm from "./pages/members/Patients";
import PhysicianForm from "./pages/members/Physician";
import TechnicianForm from "./pages/members/Technician";
import ViewMembers from "./pages/members/viewMembers";
import ProjectList from "./pages/projectList/index";
import Rewards from "./pages/rewards";
import Settings from "./pages/settings";
import OnlineSurvey from "./pages/survey";
import UsersList from "./pages/users/index";
import ViewProject from "./pages/viewProject/index";
import ViewSurvey from "./pages/viewSurvey/index";
import LandingPage from "./components/landingPage";
import { RoleContext } from "./context/RoleContext";
import jwtDecode from "jwt-decode";
import InterviewerAssignment from "./pages/InterviewerAssignment";
import MemberValidation from "./pages/memberValidation";
import { MemberProvider } from "./context/membersContext";
function App() {
  const [role, setRole] = useState("");
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);
  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GQL_SUBSCRIPTION_ENDPOINT,
    options: {
      reconnect: true,
      connectionParams: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
    },
  });

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API,
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      // eslint-disable-next-line
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        switch (extensions.code) {
          case "invalid-jwt":
            localStorage.clear();
            window.location.href = "/";
            break;
          default:
            console.log(
              `${JSON.stringify(
                extensions
              )}[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
        }
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers }) => {
      const token = localStorage.getItem("token");
      let new_headers = {
        ...headers,
        "content-type": "application/json",
      };
      if (token) {
        new_headers = {
          ...headers,
          "content-type": "application/json",
          authorization: `Bearer ${token}`,
        };
      }
      return { headers: new_headers };
    });
    return forward(operation);
  });

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: errorLink.concat(concat(authMiddleware, link)),
  });

  return (
    <Fragment>
      <RoleContext.Provider value={{
        role
      }}>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <MemberProvider>
              <Switch>
                <Route exact path="/" component={LoginPage} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/mobile-landing-page" component={LandingPage} />
                <Route
                  exact
                  path="/reset-password/:passkey"
                  component={ResetPassword}
                />
                <Route
                  exact
                  path="/onboarding/user/:passkey"
                  component={ResetPassword}
                />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute component={Dashboard} path="/dashboard" exact />
                <PrivateRoute
                  component={CreateProject}
                  path="/create_project/project_details"
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/create_project/:projectID/project_details"
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/create_project/:projectID/assign_members"
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/create_project/:projectID/financial_details"
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/projectDetails/project/:projectID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/user/project/:projectID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/financial-details/project/:projectID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/projectDetails/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/user/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/surveyOverview/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/questionnaire/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/members/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/responses/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/financial-details/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewProject}
                  path="/viewProject/rewards/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ProjectList}
                  path="/projectList/active"
                  exact
                />
                <PrivateRoute
                  component={ProjectList}
                  path="/projectList/drafts"
                  exact
                />
                <PrivateRoute
                  component={ProjectList}
                  path="/projectList/completed"
                  exact
                />
                <PrivateRoute component={ClientList} path="/clientList" exact />
                <PrivateRoute
                  component={ViewClient}
                  path="/viewClient/client-details/client/:clientID"
                  exact
                />
                <PrivateRoute
                  component={ViewClient}
                  path="/viewClient/project-details/client/:clientID"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/create-client/client-details"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/edit-client/:clientID/client-details"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/edit-client/:clientID/add-poc"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/create-client/:clientID/add-poc"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/create-client/:clientID/client-details"
                  exact
                />
                <PrivateRoute
                  component={CreateClient}
                  path="/create-client/:clientID/add-poc"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/overview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/overview/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/overview/survey/:surveyID"
                  exact
                />
                {/* <PrivateRoute
              component={CreateSurvey}
              path="/create-survey/External/overview"
              exact
            />
            <PrivateRoute
              component={CreateSurvey}
              path="/edit-survey/External/overview"
              exact
            /> */}
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/questionnaire/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/questionnaire/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/questionnaire/survey/:surveyID/preview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/questionnaire/survey/:surveyID/preview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/questionnaire/survey/:surveyID/addLogic"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/questionnaire/survey/:surveyID/addLogic"
                  exact
                />
                {/* <PrivateRoute
              component={CreateSurvey}
              path="/create-survey/External/questionnaire"
              exact
            />
            <PrivateRoute
              component={CreateSurvey}
              path="/edit-survey/External/questionnaire"
              exact
            /> */}
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/generate-links/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/generate-links/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/generate-links/survey/:surveyID/schedule"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/generate-links/survey/:surveyID/schedule"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/assign-interviewers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/assign-interviewers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/create-survey/PRF/questionnaire/preview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/assignmembers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/assignmembers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/assignmembers/survey/:surveyID/schedule"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/assigninterviwers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/assigninterviwers/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/assignmembers/survey/:surveyID/schedule"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/emailtemplate/:emailType/survey/:surveyID/preview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/edit-survey/:surveyType/emailtemplate/:emailType/survey/:surveyID/edit"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/emailtemplate/:emailType/survey/:surveyID/preview"
                  exact
                />
                <PrivateRoute
                  component={CreateSurvey}
                  path="/project/:projectID/create-survey/:surveyType/emailtemplate/:emailType/survey/:surveyID/edit"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/project_details"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/project_details/re-opened"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/assign_members"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/assign_members/re-opened"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/financial_details"
                  exact
                />
                <PrivateRoute
                  component={CreateProject}
                  path="/project/:projectID/edit-project/financial_details/re-opened"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/members/project/:projectID/survey/:surveyID/PRF/viewDetailedResponse/member/:memberID"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/members/project/:projectID/survey/:surveyID/PRF/viewResponseAnswers/member/:memberID/patient/:patientID"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/responses/project/:projectID/survey/:surveyID/External/viewResponseAnswers/member/:memberID/interviewer/:interviewerID"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/members/project/:projectID/survey/:surveyID/:surveyType/viewResponseAnswers/member/:memberID"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/projectDetails/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/projectDetails/project/:projectID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/surveyOverview/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/questionnaire/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/members/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/responses/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/rewards/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewSurvey}
                  path="/viewSurvey/feedbacks/project/:projectID/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute component={UsersList} path="/users" exact />
                <PrivateRoute
                  component={MembersList}
                  path="/members/physician/:id"
                  exact
                />
                <PrivateRoute
                  component={MembersList}
                  path="/members/technician/:id"
                  exact
                />
                <PrivateRoute
                  component={MembersList}
                  path="/members/consumer/:id"
                  exact
                />
                <PrivateRoute
                  component={MembersList}
                  path="/members/patient/:id"
                  exact
                />
                <PrivateRoute
                  component={PhysicianForm}
                  path="/members/create/physician"
                  exact
                />
                <PrivateRoute
                  component={TechnicianForm}
                  path="/members/create/technician"
                  exact
                />
                <PrivateRoute
                  component={ConsumerForm}
                  path="/members/create/consumer"
                  exact
                />
                <PrivateRoute
                  component={PatientForm}
                  path="/members/create/patient"
                  exact
                />
                <PrivateRoute
                  component={ViewMembers}
                  path="/members/:id/view"
                  exact
                />
                <PrivateRoute
                  component={EditMembers}
                  path="/members/:id/edit"
                  exact
                />
                <PrivateRoute
                  component={Details}
                  path="/project/:id/overview"
                  exact
                />
                <PrivateRoute
                  component={Details}
                  path="/project/:id/members"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/project-details/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/survey-overview/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/project-details"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/survey-overview"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/questionnaire/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/responses/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/clients/project/:projectID/responses/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/project-details/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/survey-overview/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/project-details"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/survey-overview"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/questionnaire/survey/:surveyID/:surveyType"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/responses/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={ViewDetailsProject}
                  path="/pocs/project/:projectID/responses/survey/:surveyID"
                  exact
                />
                <PrivateRoute
                  component={AttendSurvey}
                  path="/project/:id/attendsurvey/:surveyID/member/:memberID/interviewer/:interviewerID"
                  exact
                />
                <PrivateRoute
                  component={ViewResponses}
                  path="/project/:id/viewresponses/:surveyID/member/:memberID/interviewer/:interviewerID"
                  exact
                />
                <PrivateRoute path="/settings" component={Settings} exact />
                <PrivateRoute path="/rewards" component={Rewards} exact />
                <PrivateRoute path="/approvals" component={Approvals} exact />
                <PrivateRoute path="/interviewerAssignment" component={InterviewerAssignment} exact />
                <PrivateRoute path="/memberValidation" component={MemberValidation} exact />
                <Route
                  path="/survey/:surveyID/member/:memberID"
                  component={OnlineSurvey}
                  exact
                />
                <Route
                  path="/survey/:surveyID/member/:memberID/question/:questionID"
                  component={OnlineSurvey}
                  exact
                />
                <Route
                  path="/project/:projectID/Complete"
                  component={Endlink}
                  exact
                />
                <Route
                  path="/project/:projectID/QuotaFull"
                  component={Endlink}
                  exact
                />
                <Route
                  path="/project/:projectID/EarlyScreenOut"
                  component={Endlink}
                  exact
                />
                <Route component={PageNotFound} />
              </Switch>
            </MemberProvider>
          </BrowserRouter>
        </ApolloProvider>
      </RoleContext.Provider>
    </Fragment>
  );
}

export default App;
