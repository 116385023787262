import { gql, useQuery } from '@apollo/client'
import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import Loading from './Loading'


export const GET_USER_PROJECT = gql`
  query GET_USER_PROJECT($id: [Int]) {
  get_user_projects(ids: $id) {
    message
  }
}
`

export const GET_INTERVIEW_PROJECT = gql`
  query GET_INTERVIEW_PROJECT($id: [Int]) {
    get_interviewer_projects(ids: $id) {
    message
  }
}
`

const ActiveProjectList = ({ memberName, projectList, isLast = false, isActive = true }) => {
  return <div className=" text-sm">
    <div className=" text-sm text-textMedium font-semibold">{memberName}</div>
    {projectList?.length && <div className={`pb-3 mb-3 ${!isLast && "border-b border-borderGray"}  font-normal`}>
      <div className="text-textLight pt-1">{isActive ? "Active projects" : "Last projects"}</div>
      {projectList.map((project, projectIndex) => {
        return <div className=" pt-1" key={`project-${projectIndex}`}>{project?.survey?.project?.name || ""} {project?.state === 'active' ? <span className=" text-lightBlue capitalize"> (Active) </span> : project?.state === "Approved" || project?.state === "approved" || project?.state === "completed" ? <span className=" text-green capitalize">{`(${project?.state})`}</span> : project?.state === "in-progress" ? <span className=" text-textExtraLight capitalize">{`(${project?.state})`}</span> : <span className=" text-red capitalize">{`(${project?.state})`}</span>}
        </div>
      })}
    </div>}
  </div>
}

const ActiveInterviewerList = ({ memberName, projectList, isLast = false, isActive = true }) => {
  return <div className=" text-sm">
    <div className=" text-sm text-textMedium font-semibold">{memberName}</div>
    {projectList?.length && <div className={`pb-3 mb-3 ${!isLast && "border-b border-borderGray"}  font-normal`}>
      <div className="text-textLight pt-1">{isActive ? "Active projects" : "Last projects"}</div>
      {projectList.map((project, projectIndex) => {
        return <div className=" pt-1" key={`project-${projectIndex}`}>{project?.survey_x_member?.survey?.project?.name || ""} {project?.survey_x_member?.survey?.project?.status === 'active' ? <span className=" text-lightBlue capitalize"> (Active) </span> : project?.survey_x_member?.survey?.project?.status === "Approved" || project?.survey_x_member?.survey?.project?.status === "approved" || project?.survey_x_member?.survey?.project?.status === "completed" ? <span className=" text-green capitalize">{`(${project?.survey_x_member?.survey?.project?.status})`}</span> : project?.survey_x_member?.survey?.project?.status === "in-progress" ? <span className=" text-textExtraLight capitalize">{`(${project?.survey_x_member?.survey?.project?.status})`}</span> : <span className=" text-red capitalize">{`(${project?.survey_x_member?.survey?.project?.status || ""})`}</span>}
        </div>
      })}
    </div>}
  </div>
}


export default function ViewActiveButton({ memberId, ghost = false }) {
  const [showAssignedProject, setShowAssignedProject] = useState(false);
  const { data: { get_user_projects } = {}, isLoading: isUserLoading } = useQuery(GET_USER_PROJECT, {
    variables: {
      id: memberId,
    },
    skip: !showAssignedProject || ghost,
    fetchPolicy: "network-only",
  });
  const { data: { get_interviewer_projects } = {}, isLoading: isInterviewLoading } = useQuery(GET_INTERVIEW_PROJECT, {
    variables: {
      id: memberId,
    },
    skip: !showAssignedProject || !ghost,
    fetchPolicy: "network-only",
  });

  const data = React.useMemo(() => {
    return get_interviewer_projects || get_user_projects
  }, [get_interviewer_projects, get_user_projects])

  return (
    <div>
      <Button
        type="primary"
        htmlType="submit"
        ghost={ghost}
        className="text-base rounded-md mr-5 px-5 "
        onClick={() => {
          setShowAssignedProject(true)
          //  addValues();
          //  history.push("/create_project/assign_members");
        }}
      >
        View active projects
      </Button>
      <Modal
        title={null}
        visible={showAssignedProject}
        // okText={<span>Update</span>}
        onOk={() => { }}
        onCancel={() => {
          setShowAssignedProject(false)
        }}
        width={500}
        footer={null}
      >
        <div className=" max-h-[80%]">
          {(isUserLoading || isInterviewLoading) && <Loading />}
          {(data?.message?.active || [])?.map((user, userIndex) => {
            if (user.survey_x_members) {
              return <ActiveProjectList key={`activeProject-${userIndex}`} memberName={`${user.f_name || ""} ${user.l_name || ""}`} projectList={user.survey_x_members || user.survey_interviewers} isLast={userIndex + 1 === data?.message?.active?.length && (data?.message?.inactive || []).length === 0} isActive={true} />

            } else {
              return <ActiveInterviewerList key={`activeProject-${userIndex}`} memberName={`${user.f_name || ""} ${user.l_name || ""}`} projectList={user.survey_interviewers} isLast={userIndex + 1 === data?.message?.active?.length && (data?.message?.inactive || []).length === 0} isActive={true} />
            }
          })}
          {(data?.message?.inactive || [])?.map((user, userIndex) => {
            if (user.survey_x_members) {
              return <ActiveProjectList key={`InActiveProject-${userIndex}`} memberName={`${user.f_name || ""} ${user.l_name || ""}`} projectList={user.survey_x_members || user.survey_interviewers} isLast={userIndex + 1 === data?.message?.inactive?.length} isActive={false} />
            } else {
              return <ActiveProjectList key={`InActiveProject-${userIndex}`} memberName={`${user.f_name || ""} ${user.l_name || ""}`} projectList={user.survey_interviewers} isLast={userIndex + 1 === data?.message?.inactive?.length} isActive={false} />
            }
          })}
        </div>
      </Modal>
    </div>
  )
}
