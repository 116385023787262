import React, { useState, useEffect } from "react";
import { Divider, Button } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import {
  RESPONSE_QUESTION_ANSWER,
  UPDATE_STATUS_INTERVIEWER,
  GET_DOWNLOAD_URL,
} from "../graphql";
import pdf from "../../../assets/Icon/pdf.svg";
import doc from "../../../assets/Icon/doc.svg";
import download from "../../../assets/Icon/download.svg";
import png from "../../../assets/Icon/png.svg";
import mkv from "../../../assets/Icon/mkv.svg";
import mp3 from "../../../assets/Icon/mp3.svg";
import xls from "../../../assets/Icon/xls.svg";

function ViewResponseQA() {
  const { surveyID, memberID, interviewerID, projectID } = useParams();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [fileName, setFileName] = useState([]);

  const { data: responseAnswers } = useQuery(RESPONSE_QUESTION_ANSWER, {
    fetchPolicy: "network-only",
    variables: {
      survey_id: parseInt(surveyID),
      member_id: parseInt(memberID),
      interviewer_id: parseInt(interviewerID),
    },
  });

  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [updateStatus] = useMutation(UPDATE_STATUS_INTERVIEWER);

  useEffect(() => {
    if (responseAnswers) {
      let copyFileName = [...fileName];
      let files = JSON.parse(
        JSON.stringify(responseAnswers?.survey_by_pk?.questions)
      );
      let object = JSON.parse(
        JSON.stringify(responseAnswers?.survey_by_pk?.survey_x_members)
      );
      object.forEach((item, index) => {
        item.memberName = item.user.f_name + " " + item.user.l_name;
        item.status = item?.survey_x_member_x_interviewers[0]?.status;
      });
      files.forEach((item, index) => {
        copyFileName[index] = item.answers[0].answer;
      });
      setTableData(object);
      setFileName(copyFileName);
    }
    // eslint-disable-next-line
  }, [responseAnswers]);

  const handleDownload = (name, originalName) => {
    const fileDetails = originalName.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];

    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleUpdate = () => {
    updateStatus({
      variables: {
        interviewer_id: parseInt(interviewerID),
        member_id: parseInt(memberID),
      },
    }).then(() => {
      history.push(
        `/viewSurvey/responses/project/${projectID}/survey/${surveyID}`
      );
    });
  };
  return (
    <>
      <p className="text-base text-textLight absolute top-52">
        {responseAnswers?.survey_by_pk?.name} |{" " + tableData[0]?.memberName}
      </p>
      <div
        style={{ padding: "24px" }}
        className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full mt-32 shadow-lg rounded-md"
      >
        <p className="text-xl text-textDark">Survey Response</p>
        {tableData[0]?.status === "completed" && (
          <p className="text-green text-base">Completed</p>
        )}
        {tableData[0]?.status === "Approved" && (
          <p className="text-green text-base">Approved</p>
        )}
        <Divider />
        {responseAnswers?.survey_by_pk?.questions?.map((data, index) => {
          return (
            <div>
              <p className="text-base text-textLight">
                Q{index + 1}. {data.question}
              </p>
              {fileName.length !== 0 && (
                <p className={"text-base text-textMedium pl-8 w-full"}>
                  {fileName[index].split(".")[1] === "pdf" ? (
                    <>
                      <img src={pdf} alt="pdf" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  ) : fileName[index].split(".")[1] === "png" ? (
                    <>
                      <img src={png} alt="png" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  ) : fileName[index].split(".")[1] === "mkv" ? (
                    <>
                      <img src={mkv} alt="mkv" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  ) : fileName[index].split(".")[1] === "mp3" ? (
                    <>
                      <img src={mp3} alt="mp3" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  ) : fileName[index].split(".")[1] === "xls" ? (
                    <>
                      <img src={xls} alt="xls" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  ) : (
                    <>
                      <img src={doc} alt="doc" className="mb-16 float-left" />
                      <span className="float-left ml-4 pt-2">
                        {fileName[index]}
                      </span>
                    </>
                  )}
                  <Button
                    type="link"
                    className="mb-16 float-right pr-8"
                    onClick={() => {
                      handleDownload(fileName[index], data.answers[0].file_url);
                    }}
                  >
                    <img src={download} alt="download" />
                  </Button>
                </p>
              )}
              <Divider />
            </div>
          );
        })}
      </div>

      <div className="float-right mt-4">
        <Button
          htmlType="button"
          className="mr-5 px-6 rounded-md"
          size="large"
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        <Button
          type="primary"
          className="px-8 rounded-md"
          disabled={
            responseAnswers?.survey_by_pk?.survey_x_members[0].state ===
            "Approved"
              ? true
              : false
          }
          size="large"
          onClick={handleUpdate}
        >
          {responseAnswers?.survey_by_pk?.survey_x_members[0].state ===
          "Approved"
            ? "Approved"
            : "Approve"}
        </Button>
      </div>
    </>
  );
}
export default ViewResponseQA;
