import React from "react";
import { Route, Redirect } from "react-router-dom";
import Nav from "./nav";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authValue = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={(props) =>
        !authValue ? (
          <Redirect to="/" />
        ) : (
          <>
            <Nav />
            <Component {...props} />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
