import React, { useState, useEffect, useMemo } from "react";
import { Button, Table, Space, Input, notification } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_MEMBERS_LIST_EXTERNAL,
  PROJECT_DETAILS,
  RESEND_INVITE,
} from "../graphql";
import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import ColumnEditor from "../../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../components/memberColumns";
import MemberTable from "../../../components/memberTable";

function MemberListTab() {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID, projectID } = useParams();
  const location = useLocation();
  const [role, setRole] = useState(null);
  const [tableData, setTableData] = useState([]);
  const savedviewProjectAssited = localStorage.getItem('viewProjectAssited');
  const viewProjectAssitedinitial = savedviewProjectAssited
    ? JSON.parse(savedviewProjectAssited)
    : {
      ...memberColumnStatus
    }
  const [columnStatus, setStatus] = useState(viewProjectAssitedinitial);
  const [resendInvite] = useMutation(RESEND_INVITE);
  const [isOnline, setIsOnline] = useState();

  let searchInput;

  const { data: members } = useQuery(GET_MEMBERS_LIST_EXTERNAL, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      setIsOnline(res.project[0].is_online_or_assisted);
    },
  });

  useEffect(() => {
    localStorage.setItem('viewProjectAssited', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      let tempCol = {
        ...columnStatus,
        status: { enabled: true, label: "Status" },
      };
      setStatus(tempCol);
    }
  }, [isOnline]);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.clientLink =
          location.pathname.split("-")[1] === "Third_Party_survey"
            ? item?.survey_x_members[0]?.survey?.links_status
            : item?.survey_x_members[0]?.survey_url;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;
        item.status =
          item?.survey_x_members[0]?.state === "active" ||
            item?.survey_x_members[0]?.state === "in-progress"
            ? "Not responded"
            : "Responded";
      });
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [members]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const handleInvite = (record) => {
    resendInvite({
      variables: {
        user_id: record?.id,
        surveyID: parseInt(surveyID),
      },
    })
      .then(() => {
        notification.success({
          message: "Sent!",
          description: "Invitation has been sent to member",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "An error occurred while sending invitation.",
        });
      });
  };

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
            : text}
        </div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {role === "manager" && text !== null
            ? text === "Not updated"
              ? "Not updated"
              : `${text.substring(0, 3)}XXXXXXX`
            : text}
        </div>
      ),
    },
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      className: columnStatus?.clientLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("clientLink"),
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "kreaLink",
      className: columnStatus?.kreaLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("kreaLink"),
    },
    {
      title: "INTERVIEWERS",
      dataIndex: "interviewer",
      className: columnStatus?.interviewer?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("interviewer"),
    },
  ];*/

  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus),
      ...roleBasedColumns(role, columnStatus),
      ...[
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.status?.enabled,
          className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
          width: 200,
          filters: [
            {
              text: "Not responded",
              value: "Not responded",
            },
            {
              text: "Responded",
              value: "Responded",
            },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text, record) => (
            <>
              {text === "Not responded" ? (
                <>
                  <p className={"text-red text-h8"}>Not responded</p>
                  <Button
                    type="link"
                    className="text-h8 p-0"
                    onClick={() => handleInvite(record)}
                  >
                    Resend Invite
                  </Button>
                </>
              ) : (
                <span className={"text-green text-h8"}>Responded</span>
              )}
            </>
          ),
        },
      ],
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  return (
    <div className="membersTab w-full ">
      <div className=" float-right mb-5">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      <MemberTable columns={memColumns} tableData={tableData}></MemberTable>
    </div>
  );
}
export default MemberListTab;
