export const emailValidation = (surveyDetails) => {
  let status = true;
  let message = "";

  const validateEmail = (email) => {
    let test = [];
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    email.forEach((element) => {
      if (re.test(element)) {
        test.push(true);
      } else {
        test.push(false);
      }
    });
    const result = test.some((ele) => {
      return ele === false;
    });
    return result;
  };
  const res = surveyDetails?.answer.replace(/ /g, "");
  let result = validateEmail(res?.split(","));
  if (result) {
    status = false;
    message = "Please enter valid emails seperated by commas";
  } else {
    let answer = surveyDetails?.answer?.split(",");

    if (surveyDetails?.current_question?.validation?.noOfEmails !== null) {
      if (
        answer.length >
        parseInt(surveyDetails?.current_question?.validation?.noOfEmails)
      ) {
        status = false;
        message = `Please enter only ${parseInt(
          surveyDetails?.current_question?.validation?.noOfEmails
        )} Email id(s)`;
      }
    }
  }
  return {
    status,
    message,
  };
};
