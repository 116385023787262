import parse from "html-react-parser";
import React from "react";
import styled from "styled-components";
export const MailData = (data) => `
<!DOCTYPE html>
<html style="font-family: "Roboto", sans-serif;height: 100%;text-align: center;line-height: 2.2rem;font-size: 62.5%;color: #4f4f4f;">
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
      rel="stylesheet"
    />
    <style>
      .container {
        background: #f6f7fc;
        border-radius: 5px;
        padding: 2.4rem;
        width: 600px;
        margin: auto;
      }
      .mail-body {
        background: #ffffff;
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        padding: 2rem;
        margin-top: 2rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
        text-align: left;
      }
      .img {
        height: 4rem;
        width: 10.3rem;
        display: block;
        margin-bottom: 4rem;
      }
      .img-social {
        width: 1.8rem;
        cursor: pointer;
      }
      .heading {
        font-weight: 700;
        font-size: 2.4rem;
      }
      .mail-text {
        font-size: 1.4rem;
        color: #828282;
        font-weight: 400;
      }
      .bg-secondary {
        background: #f6f7fc;
        padding: 2rem;
        text-align: center;
        border-radius: 6px;
      }
      .btn-primary {
        text-align: center;
        border-radius: 0.4rem;
        border: none;
        font-size: 16px;
        cursor: pointer;
        background: #e08036;
        color: #ffffff;
        padding: 1.5rem 7rem;
        box-shadow: 0px 2px 5px rgba(33, 33, 33, 0.1);
        border-radius: 5px;
      }
      .primary {
        color: #e08036;
      }
      .note {
        font-size: 1.2rem;
        color: #828282;
      }
      .text-black {
        color: #4f4f4f;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 600px) and (-webkit-min-device-pixel-ratio: 2) {
        html {
          width: 100%;
          line-height: 3rem;
        }
        .container {
          width: 100%;
          padding: 2rem;
        }
      }
    </style>
  </head>
  <body>
    <div class="container">
      <div class="mail-body">
        <img src="https://images-from-local.s3.ap-south-1.amazonaws.com/logo.png" class="img" />
        <p class="mail-text">
          Hi <span style="font-weight: 500"> FIRST_NAME,</span>
​
          <br /><br />
          ${
            data ||
            `
          <span style="font-weight: 500">Greetings from Krea!</span>
          <br /><br />
​
          You were recently invited to participate in the survey -
          <span style="font-weight: 500">'SURVEY_TITLE'</span> and
          we noticed that you have not attended it yet. We value your insight
          and request you to spare a few minutes to attend the survey. To start
          the survey, please click on the button below:
        </p>
        <div class="bg-secondary">
          <input type="button" class="btn-primary" value="Start the survey" />
        </div>
        <p class="mail-text">
          Please note, you can attend the survey till
          <span style="font-weight: 500">'SURVEY_END_DATE'.</span><br />
          Thank you.
        </p>
        <p class="mail-text">
          Sincerely,<br />
          Krea Team
        </p>`
          }
      </div>
      <div class="note" style="height: 3rem">
        <p style="float: left; padding-top: 8px">
          <span class="text-black">Support:</span>
          <span class="primary"
            >support@krea.in | About us | Privacy policy</span
          >
        </p>
        <p style="float: right; padding-top: 8px; font-weight: normal">
          ©2021 Krea
        </p>
      </div>
    </div>
  </body>
</html>
`;
const MailContainer = styled.div`
  html {
    font-family: "Roboto", sans-serif;
    height: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 2.2rem;
    font-size: 62.5%;
    color: #4f4f4f;
  }
  .container {
    background: #f6f7fc;
    border-radius: 5px;
    padding: 2.4rem;
    width: 600px;
    margin: auto;
  }
  .mail-body {
    background: #ffffff;
    box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 2rem;
    margin-top: 2rem;
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: left;
  }
  .img {
    height: 4rem;
    width: 10.3rem;
    display: block;
    margin-bottom: 4rem;
  }
  .img-social {
    width: 1.8rem;
    cursor: pointer;
  }
  .heading {
    font-weight: 700;
    font-size: 2.4rem;
  }
  .mail-text {
    font-size: 1.4rem;
    color: #828282;
    font-weight: 400;
  }
  .bg-secondary {
    background: #f6f7fc;
    padding: 2rem;
    text-align: center;
    border-radius: 6px;
  }
  .btn-primary {
    text-align: center;
    border-radius: 0.4rem;
    border: none;
    font-size: 16px;
    cursor: pointer;
    background: #e08036;
    color: #ffffff;
    padding: 1.5rem 7rem;
    box-shadow: 0px 2px 5px rgba(33, 33, 33, 0.1);
    border-radius: 5px;
  }
  .primary {
    color: #e08036;
  }
  .note {
    font-size: 1.2rem;
    color: #828282;
  }
  .text-black {
    color: #4f4f4f;
  }
  @media only screen and (min-device-width: 320px) and (max-device-width: 600px) and (-webkit-min-device-pixel-ratio: 2) {
    html {
      width: 100%;
      line-height: 3rem;
    }
    .container {
      width: 100%;
      padding: 2rem;
    }
  }
  input[type="button"].btn-primary,
  .bg-secondary a {
    pointer-events: none;
  }
`;

export default function ReminderMail(props) {
  return (
    <MailContainer>
      {props.template ? (
        parse(String(props.template))
      ) : (
        <html>
          <head>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link
              rel="preconnect"
              href="https://fonts.gstatic.com"
              crossorigin
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
              rel="stylesheet"
            />
          </head>
          <body>
            <div class="container">
              <div class="mail-body">
                <img
                  src="https://images-from-local.s3.ap-south-1.amazonaws.com/logo.png"
                  class="img"
                  alt="logo"
                />
                <p class="mail-text">
                  Hi <span style={{ fontWeight: 500 }}> first_name,</span>
                  ​
                  <br />
                  <br />
                  <span style={{ fontWeight: 500 }}>Greetings from Krea!</span>
                  <br />
                  <br />​ You were recently invited to participate in the survey
                  -
                  <span style={{ fontWeight: 500 }}>
                    'survey_title_insert_here'
                  </span>{" "}
                  and we noticed that you have not attended it yet. We value
                  your insight and request you to spare a few minutes to attend
                  the survey. To start the survey, please click on the button
                  below:
                </p>
                <div class="bg-secondary">
                  <input
                    type="button"
                    class="btn-primary"
                    value="Start the survey"
                  />
                </div>
                <p class="mail-text">
                  Please note, you can attend the survey till
                  <span style={{ fontWeight: 500 }}>
                    'survey_end_date_time'.
                  </span>
                  <br />
                  Thank you.
                </p>
                <p class="mail-text">
                  Sincerely,
                  <br />
                  Krea Team
                </p>
              </div>
              <div class="note" style={{ height: "3rem" }}>
                <p style={{ float: "left", paddingTop: "8px" }}>
                  <span class="text-black">Support:</span>
                  <span class="primary">
                    support@krea.in | About us | Privacy policy
                  </span>
                </p>
                <p
                  style={{
                    float: "right",
                    paddingTop: "8px",
                    fontWeight: "normal",
                  }}
                >
                  ©{new Date().getFullYear()} Krea
                </p>
              </div>
            </div>
          </body>
        </html>
      )}
    </MailContainer>
  );
}
