import { Form, Input } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
const Dropdown = (prop) => {
  return (
    <Form.List
      initialValue={["dropdown"]}
      name={[prop.option.name, "dropdown"]}
    >
      {(dropdownList, { add: addDropdown, remove: removeDropdown }) => {
        return (
          <div>
            {dropdownList.map((dropdown, j) => (
              <div key={dropdown.key} className="w-10/12 float-left">
                <Form.Item
                  className="block"
                  {...dropdown}
                  name={[dropdown.name, "dropdown"]}
                  fieldKey={[dropdown.fieldKey, "dropdown"]}
                >
                  <Form.Item
                    className="float-left block ml-60"
                    style={{ width: "57%" }}
                    name={[dropdown.name, "dropdownLabel"]}
                    fieldKey={[dropdown.fieldKey, "dropdownLabel"]}
                  >
                    <Input placeholder="Dropdown option" />
                  </Form.Item>
                  <Form.Item className="w-10 float-left ml-8">
                    {dropdownList.length > 1 ? (
                      <MinusCircleOutlined
                        style={{
                          color: "red",
                        }}
                        onClick={() => removeDropdown(dropdown.name)}
                      />
                    ) : (
                      <MinusCircleOutlined
                        style={{
                          color: "red",
                        }}
                        disabled
                      />
                    )}
                  </Form.Item>
                </Form.Item>
              </div>
            ))}
            <Form.Item className="w-8 float-left">
              <PlusCircleOutlined
                className="dynamic-add-button "
                style={{ color: "orange" }}
                onClick={() => addDropdown()}
              />
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
};
export default Dropdown;
