import { gql } from "@apollo/client";

export const GET_CLIENTS = gql`
  query GET_CLIENTS {
    country {
      name
      id
    }
    client_category {
      id
      name
    }
    billing_cycle {
      id
      values
    }
    client(order_by: { created_at: desc }) {
      address
      billing_cycle
      category
      company_name
      country
      created_at
      id
      updated_at
      user_id
      user {
        id
        is_active
        email
        phone_number
        is_verified
        projects {
          surveys_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
      }
      billingCycleByBillingCycle {
        values
      }
      client_category {
        name
      }
      countryByCountry {
        name
      }
    }
  }
`;

export const SET_CLIENT_ACTIVE = gql`
  mutation ($user_id: Int!, $is_active: Boolean!) {
    update_user(
      _set: { is_active: $is_active }
      where: { id: { _eq: $user_id } }
    ) {
      affected_rows
      returning {
        clients {
          id
          user_id
          user {
            is_active
          }
        }
      }
    }
  }
`;

export const VIEW_PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($project_id: Int, $survey_id: Int) {
    project(where: { id: { _eq: $project_id } }) {
      budget
      name
      sample
      documents
      client {
        f_name
        l_name
      }
      status
      member_type {
        name
      }
      currency
      start_date
      end_date
      estimated_ir
      id
      incentive
      cost_per_interview
      client_project_id
      client_id
      created_at
      member_types
      description
      document_url
      project_type
      total_budget
      is_online_or_assisted
      manager_x_projects(order_by: { priority: asc }) {
        manager_on_leave
        id
        user {
          f_name
          l_name
          is_active
          meta_date
          role {
            name
          }
        }
        status
      }
      projectTypeByProjectType {
        name
      }
      surveys {
        start_date
        end_date
        description
        consent
        name
        status
        survey_x_members_aggregate(
          where: {
            _and: {
              _and: {
                state: { _in: ["completed", "approved", "Approved"] }
                survey_id: { _eq: $survey_id }
              }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        totalLink: survey_x_members_aggregate(
          where: { _and: { survey_id: { _eq: $survey_id } } }
          order_by: { member_id: asc }
          distinct_on: [member_id]
        ) {
          aggregate {
            count
          }
        }
        screened: survey_x_members_aggregate(
          where: {
            _and: { state: { _eq: "screened" }, survey_id: { _eq: $survey_id } }
          }
        ) {
          aggregate {
            count
          }
        }
        inprogress: survey_x_members_aggregate(
          where: {
            _and: {
              state: { _eq: "in-progress" }
              survey_id: { _eq: $survey_id }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        completed: survey_x_members_aggregate(
          where: {
            _and: {
              state: { _eq: "completed" }
              survey_id: { _eq: $survey_id }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        quotaFull: survey_x_members_aggregate(
          where: {
            _and: {
              state: { _eq: "quotaFull" }
              survey_id: { _eq: $survey_id }
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const GET_MEMBERS_LIST = gql`
  query GetMembers($id: Int) {
    user(
      where: {
        role_id: { _eq: 6 }
        survey_x_members: { survey_id: { _eq: $id } }
      }
      order_by: { id: asc }
    ) {
      title
      respondent_id
      status
      f_name
      l_name
      id
      gender
      email
      phone_number
      primary_specialty
      secondary_specialty
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty {
        id
        name
      }
      is_active
      member_type_id
      year_of_birth
      gender
      additional_data
      meta_date
      is_verified
      state
      city
      pincode
      project_compatibilitys
      member_type {
        name
      }

      member_type {
        name
      }
      survey_x_members(
        where: { survey_id: { _eq: $id } }
        order_by: { id: asc }
      ) {
        quota
        state
        survey_url
        survey {
          links_status
        }
        survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: false } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }
        active: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: false } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }
        leave: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: true } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }

        survey_x_member_x_interviewers_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
// export const GET_MEMBERS_LIST = gql`
//   query GetMembers($id: Int) {
//     user(
//       where: {
//         role_id: { _eq: 6 }
//         survey_x_members: { survey_id: { _eq: $id } }
//       }
//     ) {
//       title
//       f_name
//       l_name
//       id
//       gender
//       member_type {
//         name
//       }
//       is_verified
//       city
//       additional_data
//       meta_date
//       email
//       phone_number
//       primary_specialty
//       secondary_specialty
//       secondary_specialties {
//         speciality_name
//       }
//       primary_specialties {
//         speciality_name
//       }
//       member_type_id
//       year_of_birth
//       state
//       pincode
//       project_compatibilitys
//       survey_x_members(where: { survey_id: { _eq: $id } }) {
//         quota
//         state
//         survey_url
//         survey {
//           links_status
//         }
//       }
//     }
//   }
// `;

export const RESEND_INVITE = gql`
  mutation MyMutation($surveyID: Int!, $user_id: Int!) {
    survey_reminder(survey_id: $surveyID, user_id: $user_id) {
      message
    }
  }
`;

export const PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($id: Int) {
    project(where: { id: { _eq: $id } }) {
      id
      is_online_or_assisted
      member_types
    }
  }
`;

export const GET_MEMBERS_LIST_EXTERNAL = gql`
  query GetMembers($id: Int) {
    user(
      where: {
        role_id: { _eq: 6 }
        survey_x_members: { survey_id: { _eq: $id } }
      }
      order_by: { id: asc }
    ) {
      title
      respondent_id
      status
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      super_specialty {
        id
        name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
      survey_x_members(where: { survey_id: { _eq: $id } }) {
        quota
        state
        survey_url
        survey {
          links_status
        }
        active: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: false } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }
        leave: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: true } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }
        survey_x_member_x_interviewers_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
export const GET_MEMBERS_RESPONSES = gql`
  query MyQuery($id: Int) {
    patient(where: { survey_id: { _eq: $id } }, distinct_on: member_id) {
      user {
        title
        respondent_id
        status
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
        terminated: patients_aggregate(
          where: {
            _and: { status: { _eq: "cancelled" }, survey_id: { _eq: $id } }
          }
        ) {
          aggregate {
            count
          }
        }
        completed: patients_aggregate(
          where: {
            _and: { status: { _eq: "completed" }, survey_id: { _eq: $id } }
          }
        ) {
          aggregate {
            count
          }
        }
        screened: patients_aggregate(
          where: {
            _and: { status: { _eq: "screened" }, survey_id: { _eq: $id } }
          }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members(where: { survey_id: { _eq: $id } }) {
          quota
        }
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation REMOVE_USER($id: Int!) {
    delete_manager_x_project_by_pk(id: $id) {
      id
    }
  }
`;

export const GET_MEMBERS_RESPONSES_EXTERNAL = gql`
  query GET_MEMBERS_RESPONSES_EXTERNAL($id: Int) {
    survey_x_member(
      where: {
        _and: { survey_id: { _eq: $id }, state: { _nin: ["in-progress"] } }
      }
      order_by: { member_id: asc }
      distinct_on: [member_id]
    ) {
      user {
        title
        respondent_id
        status
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
      }
      survey {
        project {
          name
        }
      }
      state
      priority: survey_x_member_x_interviewers(
        where: { priority: { _eq: 1 } }
      ) {
        id
        created_at
        status
        comments
        interviewer {
          f_name
          l_name
          id
        }
      }
    }
  }
`;
export const GET_MEMBERS_RESPONSES_ONLINE_KREA = gql`
  query GET_MEMBERS_RESPONSES_ONLINE($id: Int) {
    # survey_x_member(where: { _and: { survey_id: { _eq: $id } } }) {
      survey_x_member(
      where: { _and: { survey_id: { _eq: $id } }}
      order_by: { member_id: asc }
      distinct_on: [member_id]
    ){
      user {
        title
        f_name
        respondent_id
        status
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
      }
      survey_url
      id
      state
      priority: survey_x_member_x_interviewers(
        where: { priority: { _eq: 1 } }
      ) {
        id
        created_at
        status
        comments
        interviewer {
          f_name
          l_name
          id
        }
      }
    }
    survey(where: { id: { _eq: $id } }) {
      links_status
      project {
        name
      }
    }
  }
`;
export const GET_MEMBERS_RESPONSES_ONLINE = gql`
  query GET_MEMBERS_RESPONSES_ONLINE($id: Int) {
    # survey_x_member(where: { _and: { survey_id: { _eq: $id } } }) {
      survey_x_member(
      where: { _and: { survey_id: { _eq: $id } } ,survey_url: {_neq: "null"}}
      order_by: { member_id: asc }
      distinct_on: [member_id]
    ){
      user {
        title
        respondent_id
        status
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
      }
      survey_url
      id
      state
      priority: survey_x_member_x_interviewers(
        where: { priority: { _eq: 1 } }
      ) {
        id
        created_at
        status
        comments
        interviewer {
          f_name
          l_name
          id
        }
      }
    }
    survey(where: { id: { _eq: $id } }) {
      links_status
      project {
        name
      }
    }
  }
`;

export const GET_MEMBER_RESPONSE = gql`
  query GET_MEMBER_RESPONSE($id: Int, $member_id: Int) {
    patient(
      where: {
        _and: {
          survey_id: { _eq: $id }
          member_id: { _eq: $member_id }
          status: { _nin: ["cancelled", "in_progress", "in-progress"] }
        }
      }
      order_by: { id: asc }
    ) {
      id
      updated_at
      status
    }
    user(where: { id: { _eq: $member_id } }) {
      f_name
      l_name
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UPDATE_STATUS($id: Int, $status: String) {
    update_patient(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_STATUS_ONLINE = gql`
  mutation UPDATE_ONLINE_STATUS($id: Int, $status: String) {
    update_survey_x_member(
      where: { id: { _eq: $id } }
      _set: { state: $status }
    ) {
      affected_rows
    }
  }
`;

export const RESPONSE_ANSWERS = gql`
  query RESPONSE_ANSWERS($id: Int, $patient_id: Int, $member_id: Int) {
    survey(where: { id: { _eq: $id } }) {
      name
      patients(where: { _and: { id: { _eq: $patient_id } } }) {
        id
        status
        member_id
        answers {
          answer
          question {
            question
          }
          more_info
        }
      }
      survey_x_members(where: { member_id: { _eq: $member_id } }) {
        member_id
        user {
          f_name
          l_name
        }
      }
    }
  }
`;

export const RESPONSE_QUESTION_ANSWER = gql`
  query MyQuery($interviewer_id: Int, $survey_id: Int!, $member_id: Int) {
    survey_by_pk(id: $survey_id) {
      name
      questions {
        question
        answers(where: { interviewer_id: { _eq: $interviewer_id } }) {
          answer
          file_url
        }
      }
      survey_x_members(
        where: {
          _and: {
            survey_id: { _eq: $survey_id }
            member_id: { _eq: $member_id }
          }
        }
      ) {
        survey_x_member_x_interviewers(
          where: { interviewer_id: { _eq: $interviewer_id } }
        ) {
          status
        }
        user {
          f_name
          l_name
        }
      }
    }
  }
`;
export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    get_view_url(file_name: $name) {
      message
      url
    }
  }
`;
export const UPDATE_STATUS_INTERVIEWER = gql`
  mutation UPDATE_STATUS($interviewer_id: Int, $member_id: Int) {
    update_survey_x_member_x_interviewer(
      where: {
        _and: {
          interviewer_id: { _eq: $interviewer_id }
          survey_x_member: { member_id: { _eq: $member_id } }
        }
      }
      _set: { status: "Approved" }
    ) {
      affected_rows
      returning {
        survey_x_member {
          member_id
        }
      }
    }
    update_survey_x_member(
      where: {
        _and: {
          member_id: { _eq: $member_id }
          survey_x_member_x_interviewers: {
            interviewer_id: { _eq: $interviewer_id }
          }
        }
      }
      _set: { state: "Approved" }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SCHEDULE = gql`
  mutation update_schedule($id: Int, $start: timestamptz, $end: timestamptz) {
    update_survey(
      where: { id: { _eq: $id } }
      _set: { start_date: $start, end_date: $end }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_QUOTA = gql`
  mutation update_quota($survey_id: Int, $member_id: Int, $quota: Int) {
    update_survey_x_member(
      where: {
        _and: [
          { member_id: { _eq: $member_id } }
          { survey_id: { _eq: $survey_id } }
        ]
      }
      _set: { quota: $quota }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_QUOTA_ALL = gql`
  mutation update_quota($survey_id: Int, $quota: Int) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id } }
      _set: { quota: $quota }
    ) {
      affected_rows
    }
  }
`;

export const CLOSE_PROJECT = gql`
  mutation CLOSE_PROJECT($project_id: Int) {
    update_project(
      where: { id: { _eq: $project_id } }
      _set: { status: "completed" }
    ) {
      affected_rows
    }
  }
`;
export const MEMBER_TYPE = gql`
  query MEMBER_TYPE {
    member_type {
      id
      name
    }
  }
`;

export const REWARDS_TAB = gql`
  query REWARDS_TAB($survey_id: Int) {
    survey(where: { id: { _eq: $survey_id } }) {
      survey_x_members_aggregate(
        where: { state: { _in: ["completed", "approved", "Approved"] } }
      ) {
        aggregate {
          count
        }
      }
      survey_x_members(
        where: { state: { _in: ["completed", "approved", "Approved"] } }
      ) {
        id
        member_id
        user {
          title
          respondent_id
          status
          f_name
          l_name
          id
          gender
          member_type {
            name
          }
          is_verified
          city
          additional_data
          meta_date
          email
          phone_number
          primary_specialty
          secondary_specialty
          secondary_specialties {
            speciality_name
          }
          primary_specialties {
            speciality_name
          }
          member_type_id
          year_of_birth
          state
          pincode
          project_compatibilitys
        }
        quota
        updated_at
        rewards {
          comments
          created_at
          file_url
          id
          status
          survey_member_id
          updated_at
          survey_id
          created_user {
            id
            f_name
            l_name
            role {
              name
              description
            }
          }
        }
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    get_upload_url(content_type: $contentType, file_name: $fileName) {
      message
      url
    }
  }
`;

export const CREATE_REWARD = gql`
  mutation CREATE_REWARD(
    $file: String
    $status: String
    $survey: Int
    $survey_x_member: Int
    $comment: String
  ) {
    insert_rewards(
      objects: {
        file_url: $file
        status: $status
        survey_id: $survey
        survey_member_id: $survey_x_member
        comments: $comment
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_REWARDS = gql`
  mutation UPDATE_REWARDS($id: Int) {
    update_rewards(where: { id: { _eq: $id } }, _set: { status: "Approved" }) {
      affected_rows
    }
  }
`;
export const RESPONSE_ANSWERS_ONLINE = gql`
  query RESPONSE_ONLINE($id: Int!, $member_id: Int) {
    survey_by_pk(id: $id) {
      name
      questions(order_by: { index: asc }) {
        question
        index
        answers(where: { member_id: { _eq: $member_id } }) {
          answer
          file_url
          id
          more_info
        }
        question_type {
          name
        }
      }
      survey_x_members(
        where: {
          _and: { survey_id: { _eq: $id }, member_id: { _eq: $member_id } }
        }
      ) {
        user {
          f_name
          l_name
        }
        state
      }
    }
  }
`;
