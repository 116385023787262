import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Button,
  Table,
  Form,
  Space,
  Input,
  Modal,
  DatePicker,
  notification,
} from "antd";
import { useHistory, useParams } from "react-router";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./selectMembers.css";
import { projectForm } from "../../../../../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  GET_MEMBERS,
  GET_ASSIGNED_MEMBERS,
  SURVEYxMEMBERS,
  UPDATE_SURVEY_STATUS,
  DELETE_SURVEY_X_MEMBERS,
  SURVEY_DATES,
  UPDATE_SURVEY_DETAILS,
  NOTIFY_NEW_MEMBER,
  PROJECT_DETAILS,
} from "../graphql";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../../../../components/ColumnEditor";
import { RoleContext } from "../../../../../context/RoleContext";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../../../components/memberColumns";
import MemberTable from "../../../../../components/memberTable";
import AssignInterviewerList from "../assigninterviewers";
import ViewActiveButton from "../../../../../components/viewActiveButton";
import SpecialityModalPop from "../../../../../utils/specialitySearch";

function SelectMembers(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const location = useLocation();
  const currentTime = moment().format();
  const surveyDetails = useRecoilValue(projectForm);
  const setValues = useSetRecoilState(projectForm);
  const memberListDetails = useRecoilValue(projectForm);
  const [tableData, setTableData] = useState([]);
  const [selectedKeyRow, setSelectedKeys] = useState([]);
  const [defaultRow, setDefaultRow] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const [insertmembers, { loading: onInsertLoading }] =
    useMutation(SURVEYxMEMBERS);

  const [deleteMembers, { loading: onDeleteLoading }] = useMutation(
    DELETE_SURVEY_X_MEMBERS
  );
  const [updateSurvey, { loading: onUpdateLoading }] =
    useMutation(UPDATE_SURVEY_STATUS);
  const [scheduleSurvey, { loading: onScheduleLoading }] = useMutation(
    UPDATE_SURVEY_DETAILS
  );
  const [notifyMembers] = useMutation(NOTIFY_NEW_MEMBER);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const savedonlineMemberSelectKrea = localStorage.getItem('onlineMemberSelectKrea');
  const onlineMemberSelectKreainitial = savedonlineMemberSelectKrea
    ? JSON.parse(savedonlineMemberSelectKrea)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(onlineMemberSelectKreainitial);
  const { role } = useContext(RoleContext);
  const [triggerOnComplete, setTriggerOnComplete] = useState(false);
  const [triggeronDraft, setTriggerOnDraft] = useState(false);
  const [assignedMembers, setAssignedMembers] = useState({});
  const [memberType, setMemberType] = useState([]);

  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  const defaultSelectedRows =
    memberListDetails?.memberListDetails?.selectedRowsSTORE;
  const [rowSelectionValue, setRowSelectionValue] = useState([]);
  const [updatedTableData, setUpdatedTableData] = useState([]);
  useEffect(() => {
    if (defaultSelectedRows && defaultSelectedRows.length > 0) {
      setRowSelectionValue(defaultSelectedRows);
    }
  }, [defaultSelectedRows]);
  useEffect(() => {
    localStorage.setItem('onlineMemberSelectKrea', JSON.stringify(columnStatus));
  }, [columnStatus]);
  const handleEditRow = (modifiedRow) => {
    const rowIndex = rowSelectionValue.findIndex(
      (row) => row.id === modifiedRow.id
    );

    if (rowIndex !== -1) {
      const updatedTable = [...rowSelectionValue];
      updatedTable[rowIndex] = modifiedRow;
      setRowSelectionValue(updatedTable);
    } else {
      setUpdatedTableData((prevState) => [...prevState, modifiedRow]);
    }
  };
  let searchInput;

  const {
    data: assignedDataMembers,
    loading,
    refetch: assignedMembersRefetch,
  } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },

    fetchPolicy: "no-cache",
  });

  const { data: members } = useQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
  });

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      let obj = {};
      let newarray = [];
      res.member_type.map((item) => {
        obj[item.id] = item.name;
      });
      res.project[0].member_types.map((type) => {
        newarray.push(obj[type]);
      });
      setMemberType(newarray);
    },
  });
  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  useEffect(() => {
    setAssignedMembers(assignedDataMembers);
    if (triggerOnComplete) {
      // handleClick();
      let editOrCreate = location.pathname.split("/")[3];
      history.push({
        pathname: `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`,
        state: { rowSelectionValue: rowSelectionValue },
      });
      setTriggerOnComplete(false);
    }
    if (triggeronDraft) {
      // handleDraft();
      notification.success({
        message: "Success!",
        description: "Saved as draft!",
      });
      history.push("/projectList/active");
      setTriggerOnDraft(false);
    }
  }, [assignedDataMembers, triggerOnComplete, triggeronDraft]);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setDueDate(moment(surveyDates?.survey[0]?.end_date));
    }
  }, [surveyDates]);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members);
      let newList = object.filter((item) => {
        return memberType.includes(item.category);
      });

      setTableData(newList);
    }
  }, [members, memberType]);

  useEffect(() => {
    assignedMembersRefetch();
    surveyDatesRefetch();
    let obj = {};
    if (!loading) {
      if (memberListDetails.memberListDetails) {
        let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.id;
          let status = el.user.survey_x_members[0].state;
          obj[id] = { status };
          return el.user.id;
        });
        setSelectedKeys(
          memberListDetails?.memberListDetails?.selectedRowKeysSTORE
        );
        setDefaultRow(selectedArray);
      } else if (
        assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0
      ) {
        // let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
        //   let id = el.user.id;
        //   let status = el.quota;
        //   obj[id] = { status };
        //   return el.user.id;
        // });

        let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.id;
          let status = el.user.survey_x_members[0].state;
          obj[id] = { status };
          return el.user.id;
        });
        let object,
          newObject = [];
        object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
        object.forEach((item) => {
          item.user.genderNew =
            item?.user?.gender === null ? "Not updated" : item?.user?.gender;
          item.user.category = item.user.member_type.name;
          item.user.memberName =
            item?.user?.l_name !== null
              ? item?.user?.f_name + " " + item?.user?.l_name
              : item?.user?.f_name;
          item.user.quota = item?.quota || 1;
          item.user.completed = item?.completed || 0;
        });
        object.forEach((item) => {
          newObject.push(item.user);
        });
        setSelectedKeys(selectedArray);
        setDefaultRow(selectedArray);
        setValues((oldValues) => ({
          ...oldValues,
          memberListDetails: {
            selectedRowKeysSTORE: selectedArray,
            selectedRowsSTORE: newObject,
          },
          updateMembersDetails: {
            updateMembers: true,
            memberList: assignedMembers,
            status: obj,
          },
        }));
      }
    }
    // eslint-disable-next-line
  }, [memberListDetails, assignedMembers, location]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleMemberData = () => {
    const combinedData = [...rowSelectionValue, ...updatedTableData];
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [],
        interviewerID = [],
        interviewer_obj = {};
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  delete key.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state: "active",
            survey_id: parseInt(surveyID),
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );

      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        return deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        });
      } else {
        return insertmembers({
          variables: {
            objects: payload,
          },
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleClick = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let quotaCopy = [];
      let completedCopy = [],
        interviewerID = [],
        interviewer_obj = {};
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  delete key.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });
      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member?.quota) {
            quotaCopy[index] = member?.quota;
            completedCopy[index] = member?.completed;
          } else {
            quotaCopy[index] = 1;
            completedCopy[index] = 0;
          }
        }
      );

      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            quota: quotaCopy[index],
            completed: completedCopy[index],
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: moment().isSameOrAfter(props.startDate)
                ? props.status === "draft"
                  ? "draft"
                  : "active"
                : props.status === "active"
                  ? "active"
                  : "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            // props.handleToggle(true);
            let editOrCreate = location.pathname.split("/")[3];

            // history.push(
            //   `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`
            // );
            history.push({
              pathname: `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`,
              state: { rowSelectionValue: rowSelectionValue },
            });
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            let editOrCreate = location.pathname.split("/")[3];

            history.push({
              pathname: `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`,
              state: { rowSelectionValue: rowSelectionValue },
            });
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleDraft = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [],
        interviewerID = [],
        interviewer_obj = {};
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  delete key.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state: "active",
            survey_id: parseInt(surveyID),
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );

      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleOk = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let quotaCopy = [];
      let completedCopy = [],
        interviewerID = [],
        interviewer_obj = {};
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  delete key.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });
      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member?.quota) {
            quotaCopy[index] = member?.quota;
            completedCopy[index] = member?.completed;
          } else {
            quotaCopy[index] = 1;
            completedCopy[index] = 0;
          }
        }
      );
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            quota: quotaCopy[index],
            completed: completedCopy[index],
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );
      let difference = selectedKeyRow.filter((x) => !defaultRow.includes(x));
      // if (memberListDetails?.updateMembersDetails?.updateMembers) {
      deleteMembers({
        variables: {
          array: surveymemberID,
          object: payload,
          Interviewerarray: interviewerID,
        },
      }).then((res) => {
        // res.data.insert_survey_x_member.returning
        // // eslint-disable-next-line
        // .map((key, index) => {

        // });

        scheduleSurvey({
          variables: {
            id: parseInt(surveyID),
            status: "active",
            startDate: startDate,
            endDate: dueDate,
          },
        })
          .then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            notifyMembers({
              variables: {
                survey_id: parseInt(surveyID),
                member_id: difference,
              },
            });
          })
          .then(() => {
            notification.success({
              message: "Success!",
              description: "Survey created!",
            });
            history.push("/projectList/active");
          });
      });
      // }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const disabledDuebyDate = (current) => {
    return current && current < startDate;
  };


  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelectionValue({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
      });
      const object = assignedMembers?.survey_x_member;
      const rowCopy = selectedRows.map((row) => {
        const foundItem = object.find((item) => item?.user?.id === row?.id);
        if (foundItem) {
          return {
            ...row,
            quota: foundItem.quota,
            completed: foundItem.completed,
          };
        }
        return row;
      });
      // let object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      // let rowCopy = JSON.parse(JSON.stringify(selectedRows));
      // if (object?.length > 0) {
      //   object.forEach((item) => {
      //     rowCopy.forEach((row, index) => {
      //       if (item?.user?.id === row?.id) {
      //         row.quota = item.quota;
      //         row.completed = item.completed;
      //         return;
      //       }
      //     });
      //   });
      // }
      setSelectedKeys(selectedRowKeys);
      setValues((oldValues) => ({
        ...oldValues,
        memberListDetails: {
          selectedRowKeysSTORE: selectedRowKeys,
          selectedRowsSTORE: rowCopy,
        },
      }));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedKeyRow,
  };

  // const SelectMembers = () => {
  //   return (

  //   );
  // };

  return (
    <div className="selectMembers px-10 py-4 w-full">
      {location.pathname.split("/")[5] === "assigninterviwers" ? (
        <AssignInterviewerList
          assignedMembers={assignedMembers}
          handleDraft={async () => {
            await assignedMembersRefetch();
            setTriggerOnDraft(true);
          }}
          onComplete={async (memId) => {
            await assignedMembersRefetch();
            const surveyStartDate =
              surveyDetails.surveyDetails &&
              surveyDetails.surveyDetails.start_date;
            if (
              moment().isSameOrAfter(surveyStartDate) &&
              props.status === "active"
            ) {
              if (selectedKeyRow.length > 0) {
                setIsModalVisible(true);
              } else {
                setShowError(true);
              }
            } else {
              setTriggerOnComplete(true);
            }
          }}
        />
      ) : (
        <>
          <div className="my-4 text-h5 text-textMedium flex justify-between ">
            Select members
            <div className=" flex">
              {!!selectedKeyRow?.length && (
                <ViewActiveButton memberId={selectedKeyRow} />
              )}

              <ColumnEditor
                columns={columnStatus}
                onSave={(column) => {
                  setStatus(column);
                }}
              />
            </div>
          </div>
          <Form>
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={false}
            ></MemberTable>
            
            <SpecialityModalPop
              titleSpec={`Selected ${selectedColumnNameSpec}`}
              visibleSpec={isModalVisibleSpec}
              onOkSpec={handleModalOkSpec}
              onCancelSpec={handleModalCloseSpec}
              searchQuerySpec={searchQuerySpec}
              handleSearchSpec={handleSearchSpec}
              handleCheckboxChangeSpec={handleCheckboxChangeSpec}
              filteredCheckboxDataSpec={filteredCheckboxDataSpec}
              selectedDataSpec={selectedDataSpec}
              initialSelectedDataSpec={initialSelectedDataSpec}
              isSubmitDisabledSpec={isSubmitDisabledSpec}
              handleResetSpec={handleResetSpec}
            />
            <Form.Item className="float-right mt-20">
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={() => {
                  const surveyStartDate =
                    surveyDetails.surveyDetails &&
                    surveyDetails.surveyDetails.start_date;
                  if (moment(surveyStartDate).isSameOrAfter(currentTime)) {
                    location.pathname.split("/")[3] === "create-survey"
                      ? history.push(
                          `/project/${projectID}/create-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        )
                      : history.push(
                          `/project/${projectID}/edit-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        );
                  } else {
                    location.pathname.split("/")[3] === "create-survey"
                      ? history.push(
                          `/project/${projectID}/create-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        )
                      : history.push(
                          `/project/${projectID}/edit-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        );
                  }
                }}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="text-base mx-5 rounded-md text-orange"
                onClick={handleDraft}
                disabled={
                  moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                    ? true
                    : false
                }
                loading={
                  onInsertLoading ||
                  onDeleteLoading ||
                  onUpdateLoading ||
                  onScheduleLoading
                }
              >
                Save as draft
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="text-base rounded-md w-28"
                onClick={async () => {
                  if (project?.[0]?.is_online_or_assisted) {
                    // setShowInterviewers(true)
                    let editOrCreate = location.pathname.split("/")[3];
                    await handleMemberData();
                    // history.push(
                    //   `/project/${projectID}/${editOrCreate}/${surveyType}/assigninterviwers/survey/${surveyID}`
                    // );
                    history.push({
                      pathname: `/project/${projectID}/${editOrCreate}/${surveyType}/assigninterviwers/survey/${surveyID}`,
                      state: { rowSelectionValue: rowSelectionValue },
                    });
                  } else {
                    const surveyStartDate =
                      surveyDetails.surveyDetails &&
                      surveyDetails.surveyDetails.start_date;
                    if (
                      moment().isSameOrAfter(surveyStartDate) &&
                      props.status === "active"
                    ) {
                      if (selectedKeyRow.length > 0) {
                        setIsModalVisible(true);
                      } else {
                        setShowError(true);
                      }
                    } else {
                      handleClick();
                    }
                  }
                }}
                loading={
                  onInsertLoading ||
                  onDeleteLoading ||
                  onUpdateLoading ||
                  onScheduleLoading
                }
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      )}

      <Modal
        title="Schedule"
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{
          loading:
            onInsertLoading ||
            onDeleteLoading ||
            onUpdateLoading ||
            onScheduleLoading,
        }}
        onCancel={handleCancel}
        width={400}
        okText="Schedule"
      >
        {/* <div style={{ color: "red" }}>
          <p>{errorMessage}</p>
        </div> */}
        <div className="flex justify-start items-center mb-5">
          <span>
            <p className="text-gray-100">
              The newly added members will be sent the survey invitation email.
              Please tap on Schedule to proceed.
            </p>
          </span>
        </div>
        <div className="flex justify-start items-center mb-5">
          <span className="w-20">
            <p className="text-gray-100">DUE BY</p>
          </span>
          <span className="ml-5">
            <DatePicker
              format="YYYY-MM-DD HH:MM"
              disabledDate={disabledDuebyDate}
              showTime
              value={dueDate}
              showNow={false}
              className="w-40"
              onChange={(e) => {
                setDueDate(e);
              }}
            />
          </span>
        </div>
      </Modal>
      {showError ? (
        <p style={{ color: "red" }}>Please select atleast one member</p>
      ) : null}
    </div>
  );
}
export default SelectMembers;
