import React from 'react'
export default function MemberStatsData({ totalCount, totalPercentage, label }) {
  return <div>
    <div className="text-h1 text-orange">{totalCount || 0}
      {!!totalCount && totalPercentage &&
        <span className=" ml-2.5 text-h6 font-medium  text-textMedium ">{(((totalCount / totalPercentage) * 100)).toFixed(2)}%</span>}
    </div>
    <div className=" mt-3 text-h7 font-normal text-textLight">
      {label}
    </div>
  </div>

}
