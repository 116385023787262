import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { Col, Dropdown, Menu, Row, Spin } from "antd";
import MemberStatsData from "../../components/MemberStatsData";
import MemberTable from "../../components/memberTable";
import { getColumnSearchProps } from "../../utils/table";
import { useQuery } from "@apollo/client";
import { GET_USERS, STATUS_COUNTS } from "./graphql";
import {
  FilterOutlined,
  MoreOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import jwtDecode from "jwt-decode";
import { getStatusText } from "../members/getStatusText";
import SpecialityModalPop from "../../utils/specialitySearch";

const MemberValidation = () => {
  const [tableData, setTableData] = useState([]);
  const [role, setRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredValue, setFilteredValue] = useState(null);
  const [selectedStatusList, setSelectedStatusList] = useState([]);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState("");
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const history = useHistory();
  const [additionalColumnList, setAdditionalColumnList] = useState({});
  const [columnStatus, setStatus] = useState({});
  const [interviewerId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-user-id"
    ]
  );

  const [activeTab, setActiveTab] = useState(true);
  const { data, loading, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "network-only",
    variables: { interviewerID: interviewerId },
  });

  const { data: statusCount } = useQuery(STATUS_COUNTS, {
    fetchPolicy: "network-only",
    variables: { interviewerID: interviewerId },
  });

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (data) {
      let copy = JSON.parse(JSON.stringify(data));
      let ColList = { ...additionalColumnList };
      let colStatus = { ...columnStatus };
      copy.user.forEach((user) => {
        user.name =
          user?.l_name !== null
            ? user?.f_name + " " + user?.l_name
            : user?.f_name;
        user.roleName = user?.role?.description;
        user.respondent_id =
          user?.respondent_id === "" ? "Not updated" : user?.respondent_id;
        user.member_type =
          user?.member_type?.name === ""
            ? "Not updated"
            : user?.member_type?.name;
        user.speciality =
          user?.primary_specialties?.speciality_name === undefined
            ? "Not updated"
            : user?.primary_specialties?.speciality_name;
        user.phone_number =
          user.phone_number === "" ? "Not updated" : user.phone_number;
        user.dncCount = user?.dnc_count;
      });
      setTableData(copy.user);
      setStatus(colStatus);
      setAdditionalColumnList(ColList);
    }
    // eslint-disable-next-line
  }, [data, activeTab]);

  const Columns = useMemo(
    () =>
      [
        {
          title: "RESPONDENT ID",
          dataIndex: "respondent_id",
          key: "respondent_id",
          ...getColumnSearchProps("respondent_id", "respondent_id"),
          className: "table-cell whitespace-nowrap",
          width: 100,
        },
        {
          title: "MEMBER TYPE",
          dataIndex: "member_type",
          key: "member_type",
          className: "table-cell whitespace-nowrap",
          width: 120,
          filters: [
            {
              text: "Technician",
              value: "Technician",
            },
            {
              text: "Patient",
              value: "Patient",
            },
            {
              text: "Physicians",
              value: "Physicians",
            },
            {
              text: "Consumers",
              value: "Consumers",
            },
          ],
          onFilter: (value, record) => record.member_type.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
        },
        {
          title: (
            <div
              onClick={() => handleIconClick("speciality")}
              className="flex justify-between items-center"
            >
              <div>PRIMARY SPECIALITY</div>
              <div>
                <SearchOutlined
                  className="cursor-pointer column-custom-searchicon"
                  style={{ color: "#9CA3AF" }}
                />
              </div>
            </div>
          ),
          dataIndex: "speciality",
          key: "speciality",
          className: "table-cell whitespace-nowrap",
          width: 200,
          onHeaderCell: () => ({
            onClick: () => handleIconClick("speciality"),
          }),
        },
        {
          title: "STATUS",
          dataIndex: "status",
          className: "table-cell whitespace-nowrap",
          key: "status",
          width: 100,
          render: (text, record) => {
            return getStatusText(text, record);
          },
          filters: [
            {
              text: "Raw",
              value: "raw",
            },
            {
              text: "Partial Email",
              value: "partial-email",
            },
            {
              text: "Partial Phone",
              value: "partial-phone",
            },
            {
              text: "Not Validated",
              value: "not-validated",
            },
            {
              text: "Invalid",
              value: "invalid",
            },
          ],
          onFilter: (value, record) => {
            const result = record.status.indexOf(value) === 0;
            return result;
          },
          filterIcon: () => <FilterOutlined />,
        },
        {
          title: "Action",
          dataIndex: "action",
          width: 100,
          render: (text, record) => {
            return (
              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu>
                    <Menu.Item
                      key={1}
                      onClick={() => {
                        history.push(`/members/${record?.id}/edit`);
                      }}
                    >
                      Edit details
                    </Menu.Item>
                  </Menu>
                }
              >
                <MoreOutlined className="text-2xl font-bold cursor-pointer" />
              </Dropdown>
            );
          },
        },
      ].filter((data) => data.isShow === undefined || data.isShow),
    [refetch, columnStatus, role, additionalColumnList]
  );

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(
      filteredDataSource.map((item) => item[columnName])
    );
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec("");
    setSearchQuerySpec("");
    setFilteredDataSource("");
  };

  const filteredCheckboxDataSpec = Array.from(
    new Set(tableData?.map((item) => item[selectedColumnNameSpec]))
  ).filter((data) =>
    data?.toLowerCase().includes(searchQuerySpec.toLowerCase())
  );

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const handleStatusClick = (status) => {
    setSelectedStatus(status);
    setFilteredValue([status]);
  };

  const handleFilterReset = () => {
    setSelectedStatus(null);
    setFilteredValue(null);
  };

  const sortStatus = (statusData, selectedStatus) => {
    if (selectedStatus === "raw") {
      return statusData.filter((data) => data.status === "raw");
    } else if (selectedStatus === "partial-email") {
      return statusData.filter((data) => data.status === "partial-email");
    } else if (selectedStatus === "partial-phone") {
      return statusData.filter((data) => data.status === "partial-phone");
    } else if (selectedStatus === "invalid") {
      return statusData.filter((data) => data.status === "invalid");
    } else if (selectedStatus === "not-validated") {
      return statusData.filter((data) => data.status === "not-validated");
    }
    return statusData;
  };

  const filteredData2 = selectedStatus
    ? sortStatus(tableData, selectedStatus)
    : tableData;

  const statusOptions = [
    { text: "Raw", value: "raw", countKey: "raw_count" },
    {
      text: "Partial Email",
      value: "partial-email",
      countKey: "partial_email",
    },
    {
      text: "Partial Phone",
      value: "partial-phone",
      countKey: "partial_phone",
    },
    {
      text: "Not Validated",
      value: "not-validated",
      countKey: "not_validated_count",
    },
    { text: "Invalid", value: "invalid", countKey: "invalid_count" },
  ];
  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="text-textMedium text-h5 font-semibold">
          Member Validation
        </div>
      </div>
      <Row className=" w-full mt-2 mb-5">
        <Col span={24} className="">
          <div className="gap-6 w-full">
            <div className="shadow-lg bg-white rounded-md flex justify-between gap-10">
              <div
                className={`flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6  ${
                  selectedStatusList.length === 0 ? "" : ""
                }`}
                onClick={handleFilterReset}
              >
                <MemberStatsData
                  label={"Total"}
                  totalCount={statusCount?.total_count?.aggregate?.count}
                />
              </div>
              {statusOptions.map((option) => (
                <div
                  key={option.value}
                  className={`flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6 cursor-pointer ${
                    selectedStatusList.includes(option.value)
                      ? "bg-bgMedium"
                      : ""
                  }`}
                  onClick={() => handleStatusClick(option.value)}
                >
                  <MemberStatsData
                    label={option.text}
                    totalCount={
                      statusCount?.[option.countKey]?.aggregate?.count
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      <div className="selectMembers py-4 w-full">
        <Spin spinning={loading}>
          <MemberTable
            columns={Columns}
            tableData={filteredData2}
            pagination={false}
            scroll={{
              x: "100%",
              y: 500,
              minHeight: 200,
            }}
          ></MemberTable>
        </Spin>

        <SpecialityModalPop
          titleSpec={`Selected ${selectedColumnNameSpec}`}
          visibleSpec={isModalVisibleSpec}
          onOkSpec={handleModalOkSpec}
          onCancelSpec={handleModalCloseSpec}
          searchQuerySpec={searchQuerySpec}
          handleSearchSpec={handleSearchSpec}
          handleCheckboxChangeSpec={handleCheckboxChangeSpec}
          filteredCheckboxDataSpec={filteredCheckboxDataSpec}
          selectedDataSpec={selectedDataSpec}
          initialSelectedDataSpec={initialSelectedDataSpec}
          isSubmitDisabledSpec={isSubmitDisabledSpec}
          handleResetSpec={handleResetSpec}
        />
      </div>
    </div>
  );
};

export default MemberValidation;
