import { Drawer, Form, Button, Input } from "antd";
import { useState } from "react";

function StarDrawerForm(props) {
  let labels = ["Label1", "Label2", "Label3", "Label4", "Label5"];
  const [visible, setVisible] = useState(props.visible);
  const [form] = Form.useForm();
  const initialValue = props?.ratingLabels[props.index]?.[props.optionIndex]
    ? props?.ratingLabels[props.index]?.[props.optionIndex]
    : props.questionData &&
      props.questionData[props.index]?.questions_options[props.optionIndex]
        ?.options?.rating_labels;

  let labelList = labels.map((label, index) => {
    return (
      <Form.Item
        name={label}
        label={<span className="text-textMedium text-sm">{label}</span>}
        className="w-3/4"
        key={index}
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter the label text" />
      </Form.Item>
    );
  });
  const onClose = () => {
    setVisible(false);
    props.handleRatingLabels(
      form.getFieldsValue(),
      visible,
      props.optionIndex,
      props.index
    );
    form.resetFields();
  };
  const onReset = () => {
    form.resetFields();
  };
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    props.handleRatingLabels(
      form.getFieldsValue(),
      visible,
      props.optionIndex,
      props.index
    );
  };

  const onFinish = () => {
    onClose();
  };

  return (
    <>
      <Drawer
        title={
          <span className="text-textMedium text-lg">Labels for rating</span>
        }
        width={720}
        onClose={onCancel}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <>
            <div style={{ float: "left", width: "70%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
            <div style={{ float: "left", width: "30%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={form.submit}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Add
              </Button>
            </div>
          </>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialValue}
          onFinish={onFinish}
        >
          {labelList}
        </Form>
      </Drawer>
    </>
  );
}

export default StarDrawerForm;
