import {
  Form,
  Input,
  Button,
  Divider,
  Table,
  Select,
  Radio,
  DatePicker,
  Checkbox,
  InputNumber,
} from "antd";
import { useState, useEffect } from "react";
import ReferQuestions from "./referQuestionsDrawer";
import { useMutation } from "@apollo/client";
import { UPDATE_LOGIC, UPDATE_IS_LOGIC } from "./graphql";
import moment from "moment";
import { formatDateTimeLogic, formatDate } from "../../../../../utils/dateTime";

const Option = { Select };
const { RangePicker } = DatePicker;

function AddLogic(props) {
  const [form] = Form.useForm();
  const emptyArray = new Array(props.dataObject.length).fill("");
  const [questionNumber, setQuestionNumber] = useState(emptyArray);
  const [quotaValue, setQuotaValue] = useState(emptyArray);
  const [isEnable, setIsEnable] = useState(emptyArray);
  const [enableQuotaInput, setEnableQuotaInput] = useState(emptyArray);
  const [range, setRange] = useState(emptyArray);
  const [dateRange, setDateRange] = useState(emptyArray);
  const [dropdown, setDropdown] = useState(emptyArray);
  const [rangeFrom, setRangeFrom] = useState(emptyArray);
  const [rangeTo, setRangeTo] = useState(emptyArray);
  const [operatorValue, setOperatorValue] = useState(emptyArray);
  const [dateValue, setDateValue] = useState(emptyArray);
  const [dateValueArray, setDateValueArray] = useState(emptyArray);
  const [dateValueMoment, setDateValueMoment] = useState(emptyArray);
  const [dateValueArrayMoment, setDateValueArrayMoment] = useState(emptyArray);
  const [visible, setVisible] = useState(false);
  const [isLogicValue, setIsLogicValue] = useState(true);
  const [enableDate, setEnableDate] = useState(false);

  const [updateLogic] = useMutation(UPDATE_LOGIC);
  const [updateIsLogic] = useMutation(UPDATE_IS_LOGIC);

  const qTypeValue = props.selectedItem.question_type.name;

  const onReset = () => {
    setQuestionNumber(emptyArray);
    setQuotaValue(emptyArray);
    setIsEnable(emptyArray);
    setEnableQuotaInput(emptyArray);
    setRange(emptyArray);
    setDateRange(emptyArray);
    setDropdown(emptyArray);
    setIsLogicValue(false);
    form.resetFields();
    // handleInitialValues(props);
  };

  const onCancel = () => {
    props.handleCallback(false);
  };
  useEffect(() => {
    handleInitialValues(props);
  }, [props]);

  const handleInitialValues = (props) => {
    let enablaScreenValue = [];
    let jumpToQuestionValue = [];
    let enableQuotaarray = [];
    let quotanumberArray = [];
    let dropdownValue = [];
    let numericalOperatorArray = [];
    let numericalOperatorValueArray = [];
    let rangeFromArray = [];
    let rangeToArray = [];
    let dateOperatorArray = [];
    let dateValueArray = [];
    let dateBetweenValueArray = [];
    // eslint-disable-next-line
    props.selectedItem.questions_options.map((option, index) => {
      enablaScreenValue[index] =
        option.logic && option.logic.screenExit === true
          ? "screenExit"
          : option.logic && option.logic.jumpToQuestion !== null
          ? "questionNumber"
          : "";
      dateOperatorArray[index] =
        option.logic && option.logic.dateOperator !== null
          ? option.logic.dateOperator
          : "";
      dateValueArray[index] =
        option.logic &&
        option.logic.dateValueMoment &&
        option.logic.dateValueMoment !== null
          ? moment(option.logic.dateValueMoment)
          : "";
      dateBetweenValueArray[index] =
        option.logic &&
        option.logic.dateValueArrayMoment &&
        option.logic.dateValueArrayMoment !== null
          ? [
              option.logic.dateValueArrayMoment[0]
                ? moment(option.logic.dateValueArrayMoment[0])
                : "",
              option.logic.dateValueArrayMoment[1]
                ? moment(option.logic.dateValueArrayMoment[1])
                : "",
            ]
          : "";
      numericalOperatorArray[index] =
        option.logic && option.logic.numericalOperator !== null
          ? option.logic.numericalOperator
          : "";
      numericalOperatorValueArray[index] =
        option.logic && option.logic.numericalOperatorValue !== null
          ? option.logic.numericalOperatorValue
          : "";
      rangeFromArray[index] =
        option.logic && option.logic.rangeFrom !== null
          ? option.logic.rangeFrom
          : "";
      rangeToArray[index] =
        option.logic && option.logic.rangeTo !== null
          ? option.logic.rangeTo
          : "";
      jumpToQuestionValue[index] =
        option.logic && option.logic.jumpToQuestion !== null
          ? option.logic.jumpToQuestion
          : "";
      enableQuotaarray[index] =
        option.logic && option.logic.quotaForChoice !== null ? true : false;
      quotanumberArray[index] =
        option.logic && option.logic.quotaForChoice !== null
          ? option.logic.quotaForChoice
          : null;
      dropdownValue[index] =
        option.logic && option.logic.dropdownValue !== null
          ? option.logic.dropdownValue
          : "";
    });

    setIsEnable(enablaScreenValue);
    setQuestionNumber(jumpToQuestionValue);
    setEnableQuotaInput(enableQuotaarray);
    setQuotaValue(quotanumberArray);
    setDropdown(dropdownValue);
    setRange(numericalOperatorArray);
    setOperatorValue(numericalOperatorValueArray);
    setRangeFrom(rangeFromArray);
    setRangeTo(rangeToArray);
    setDateRange(dateOperatorArray);
    setDateValueMoment(dateValueArray);
    setDateValueArrayMoment(dateBetweenValueArray);
  };
  const onSave = () => {
    let logic = [];
    props.selectedItem.questions_options.map((label, index) => {
      return logic.push({
        screenExit: isEnable[index] === "screenExit" ? true : false,
        jumpToQuestion:
          questionNumber.length !== 0
            ? questionNumber[index] !== ""
              ? questionNumber[index]
              : null
            : null,
        quotaForChoice:
          enableQuotaInput[index] === true ? quotaValue[index] : null,
        dropdownValue: dropdown[index] !== "" ? dropdown[index] : null,
        numericalOperatorValue:
          operatorValue[index] !== "" ? operatorValue[index] : null,
        numericalOperator: range[index] !== "" ? range[index] : null,
        dateOperatorValue: dateValue[index] !== "" ? dateValue[index] : null,
        dateOperatorValueBetween:
          dateValueArray[index] !== "" ? dateValueArray[index] : null,
        dateOperator: dateRange[index] !== "" ? dateRange[index] : null,
        rangeFrom: rangeFrom[index] !== "" ? rangeFrom[index] : null,
        rangeTo: rangeTo[index] !== "" ? rangeTo[index] : null,
        dateValueArrayMoment:
          dateValueArrayMoment[index] !== ""
            ? dateValueArrayMoment[index]
            : null,
        dateValueMoment:
          dateValueMoment[index] !== "" ? dateValueMoment[index] : null,
      });
    });
    props.selectedItem.questions_options.map((label, index) => {
      let logicObject = JSON.parse(JSON.stringify(logic[index]));
      let data;
      updateLogic({
        variables: {
          id: label?.id,
          object: logicObject,
        },
      }).then((res) => {
        data = res;
        updateIsLogic({
          variables: {
            id: res?.data?.update_questions_options?.returning[0]?.question?.id,
            isLogic: isLogicValue,
          },
        }).then(() => {
          props.handleCallback(false);
        });
      });
      return data;
    });
  };

  const handleQuestionNumber = (e, index, record) => {
    let newArray = [...questionNumber];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setIsLogicValue(true);
    setQuestionNumber(newArray);
  };

  const handleOperatorValue = (e, index) => {
    let newArray = [...operatorValue];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setIsLogicValue(true);
    setOperatorValue(newArray);
  };
  const handleRangeFrom = (e, index) => {
    let newArray = [...rangeFrom];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setIsLogicValue(true);
    setRangeFrom(newArray);
  };
  const handleRangeTo = (e, index) => {
    let newArray = [...rangeTo];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setIsLogicValue(true);
    setRangeTo(newArray);
  };

  const handleQuotaInput = (e, index, record) => {
    let newArray = [...quotaValue];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setIsLogicValue(true);
    setQuotaValue(newArray);
  };

  const handleRadio = (e, index, record) => {
    let newArray = [...isEnable];
    if (newArray[index] === undefined) {
      newArray.push(e.target.value);
    } else {
      newArray[index] = e.target.value;
    }
    if (newArray[index] === "screenExit") {
      let qNo = [...questionNumber];
      qNo[index] = "";
      setQuestionNumber(qNo);
    }
    setIsLogicValue(true);
    setIsEnable(newArray);
  };

  const handleCheckbox = (e, index) => {
    let newArray = [...enableQuotaInput];
    if (newArray[index] === undefined) {
      newArray.push(e.target.checked);
    } else {
      newArray[index] = e.target.checked;
    }
    setIsLogicValue(true);
    setEnableQuotaInput(newArray);
  };

  const handleDateValue = (e, index, record) => {
    let newArray = [...dateValue];
    let momentArray = [...dateValueMoment];
    if (newArray[index] === undefined) {
      newArray[index] = record.whenIf ? formatDateTimeLogic(e) : formatDate(e);
      momentArray[index] = e;
    } else {
      newArray[index] = record.whenIf ? formatDateTimeLogic(e) : formatDate(e);
      momentArray[index] = e;
    }
    setIsLogicValue(true);
    setDateValue(newArray);
    setDateValueMoment(momentArray);
  };

  const handleDateValueArray = (e, index, record) => {
    let newArray = [...dateValueArray];
    let momentArray = [...dateValueArrayMoment];
    if (newArray[index] === undefined) {
      newArray[index] = record.whenIf ? formatDateTimeLogic(e) : formatDate(e);
      momentArray[index] = e;
    } else {
      newArray[index] = record.whenIf ? formatDateTimeLogic(e) : formatDate(e);
      momentArray[index] = e;
    }
    setIsLogicValue(true);
    setDateValueArrayMoment(momentArray);
    setDateValueArray(newArray);
  };

  const handleReferQuestions = (visible) => {
    setVisible(visible);
  };
  const columns = [
    {
      title: "If choice is...",
      dataIndex: "choice",
      key: "choice",
      className: "text-base text-textMedium w-32",
      render: (text) => (
        <Form.Item>
          <Input value={text} readOnly />
        </Form.Item>
      ),
    },
    {
      title: "Then, go to...",
      dataIndex: "goTo",
      key: "goTo",
      className: "text-base text-textMedium w-56",
      render: (text, record, index) => (
        <>
          <Radio.Group
            onChange={(e) => {
              handleRadio(e, index, record);
            }}
            value={isEnable[index]}
          >
            <Radio value="screenExit">Screen Exit</Radio>
            <Radio
              value="questionNumber"
              disabled={props.length === props.questionNumber ? true : false}
            >
              Question
            </Radio>
          </Radio.Group>

          <InputNumber
            id={index}
            placeholder="000"
            className="w-14 ml-1"
            value={questionNumber[index]}
            min={props.selectedItem.index + 1}
            max={props.length}
            disabled={isEnable[index] !== "questionNumber"}
            onChange={(e) => {
              handleQuestionNumber(e, index, record);
            }}
            required
          />
        </>
      ),
    },
    {
      title: "When/if",
      dataIndex: "whenIf",
      key: "whenIf",
      className: "text-base text-textMedium w-52",
      render: (text, record, index) => (
        <>
          {(qTypeValue === "Percentage" ||
            qTypeValue === "Text box - numerical") && (
            <>
              <Select
                name="setRange"
                className="text-sm text-textMedium float-left w-28  "
                placeholder="Operator"
                value={range[index] === "" ? "Operator" : range[index]}
                onChange={(e) => {
                  let newArray = [...range];
                  if (newArray[index] === undefined) {
                    newArray.push(e);
                  } else {
                    newArray[index] = e;
                  }
                  setIsLogicValue(true);
                  setRange(newArray);
                }}
                showSearch
                optionFilterProp="children"
              >
                <Option key="greater">&gt;</Option>
                <Option key="less">&lt;</Option>
                <Option key="greaterEqual">&gt;=</Option>
                <Option key="lesserEqual">&lt;=</Option>
                <Option key="between">Between</Option>
              </Select>
              {range[index] === "between" && (
                <>
                  <InputNumber
                    className="w-16 rounded-md mx-2"
                    placeholder="From"
                    onChange={(e) => handleRangeFrom(e, index)}
                    value={rangeFrom[index]}
                    min={0}
                    required
                  />
                  <InputNumber
                    className="w-14 rounded-md"
                    placeholder="To"
                    onChange={(e) => handleRangeTo(e, index)}
                    value={rangeTo[index]}
                    min={0}
                    required
                  />
                </>
              )}
              {range[index] !== "" && range[index] !== "between" && (
                <InputNumber
                  className="w-14 ml-1 rounded-md"
                  placeholder="000"
                  onChange={(e) => handleOperatorValue(e, index)}
                  value={operatorValue[index]}
                  min={0}
                  required
                />
              )}
            </>
          )}
          {qTypeValue === "Dropdown" && (
            <>
              <Select
                name="dropdown"
                className="text-sm text-textMedium float-left"
                value={dropdown[index] === "" ? "Select" : dropdown[index]}
                onChange={(e) => {
                  let newArray = [...dropdown];
                  if (newArray[index] === undefined) {
                    newArray.push(e);
                  } else {
                    newArray[index] = e;
                  }
                  setIsLogicValue(true);
                  setDropdown(newArray);
                }}
              >
                {record.whenIf.map((dropdownValue, index) => (
                  <Option key={index} value={dropdownValue}>
                    {dropdownValue}
                  </Option>
                ))}
              </Select>
            </>
          )}
          {qTypeValue === "Multiple Choice" && (
            <>
              <Checkbox
                onChange={(e) => handleCheckbox(e, index)}
                checked={enableQuotaInput[index]}
              >
                Quota for choice
              </Checkbox>
              {enableQuotaInput[index] === true && (
                <InputNumber
                  className="w-14 ml-1 rounded-md"
                  placeholder="000"
                  value={quotaValue[index]}
                  onChange={(e) => handleQuotaInput(e, index)}
                  min={0}
                  required
                />
              )}
            </>
          )}
          {qTypeValue === "Date & Time" && (
            <>
              <Select
                name="date"
                className="text-sm text-textMedium float-left w-28"
                value={dateRange[index] === "" ? "Operator" : dateRange[index]}
                placeholder="Operator"
                onChange={(e) => {
                  let newArray = [...dateRange];
                  if (newArray[index] === undefined) {
                    newArray.push(e);
                  } else {
                    newArray[index] = e;
                  }
                  setDateRange(newArray);
                  setEnableDate(!enableDate);
                  setIsLogicValue(true);
                }}
              >
                <Option key="greater">&gt;</Option>
                <Option key="less">&lt;</Option>
                <Option key="greaterEqual">&gt;=</Option>
                <Option key="lesserEqual">&lt;=</Option>
                <Option key="between">Between</Option>
              </Select>
              <div className="w-80 float-left">
                {dateRange[index] === "between" && (
                  <RangePicker
                    showTime={record.whenIf ? record.whenIf : false}
                    use12Hours
                    format={record.whenIf ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"}
                    value={
                      dateValueArrayMoment[index]
                        ? dateValueArrayMoment[index]
                        : ""
                    }
                    className="text-sm text-textMedium w-80 ml-1"
                    showToday={true}
                    onChange={(date) => {
                      handleDateValueArray(date, index, record);
                    }}
                  />
                )}
                {dateRange[index] !== "" && dateRange[index] !== "between" && (
                  <DatePicker
                    className="text-sm text-textMedium w-80 ml-1"
                    showTime={record.whenIf ? record.whenIf : false}
                    use12Hours
                    value={dateValueMoment[index] ? dateValueMoment[index] : ""}
                    format={record.whenIf ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY"}
                    showToday={true}
                    onChange={(date) => handleDateValue(date, index, record)}
                  />
                )}
              </div>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <div className="w-full mt-4 ml-0 mr-0 p-5 border border-borderGray">
      <div className="w-full h-16">
        <Button
          htmlType="button"
          ghost
          type="primary"
          className="text-base rounded-sm float-right"
          onClick={() => handleReferQuestions(true)}
        >
          Refer questions
        </Button>
      </div>
      <Form name="addLogic" layout="vertical" form={form} onFinish={onSave}>
        <Form.Item name="question">
          <span className="float-left mt-1">Q{props.questionNumber}</span>
          <Input
            style={{ width: "96.5%" }}
            className="ml-4 float-left rounded-sm "
            defaultValue={props.selectedItem.question}
            readOnly
          />
        </Form.Item>
        <Form.Item name="table">
          <Table
            dataSource={props.dataObject}
            columns={columns}
            pagination={false}
            rowClassName={(record, index) => "text-base text-textLight"}
            rowKey={(record) => record.id}
          />
        </Form.Item>

        <Divider />
        <Form.Item>
          <div style={{ float: "left", width: "80%" }}>
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={onReset}
            >
              Reset Logic
            </Button>
          </div>
          <div style={{ float: "right", width: "20%" }}>
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              style={{ marginRight: 8 }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-sm w-28"
            >
              Save
            </Button>
          </div>
        </Form.Item>
        {visible && (
          <ReferQuestions
            visible={visible}
            handleReferQuestions={handleReferQuestions}
          />
        )}
      </Form>
    </div>
  );
}

export default AddLogic;
