export const percentageValidation = (surveyDetails) => {
  let status = true;
  let message = "";
  let screened = false;
  let jumpToquestion;
  let jumpToStatus = false;

  if (
    surveyDetails.current_question.validation !== null &&
    surveyDetails.current_question.validation.percentage
  ) {
    let copy = 0;
    // eslint-disable-next-line
    surveyDetails.current_answer.map((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy += value;
        }
      }
    });

    if (copy !== 100) {
      status = false;
      message = "Percentage should add upto 100";
    }
  }
  if (status) {
    // eslint-disable-next-line
    surveyDetails.current_question.questions_options.some((option, i) => {
      // let label = surveyDetails.current_answer[i];
      // let answer = label[option.label];
      let answer;
      let copy = surveyDetails.current_answer[i];
      for (let [key, value] of Object.entries(copy)) {
        if (key !== "type") {
          answer = value;
        }
      }
      // eslint-disable-next-line
      switch (option.logic && option.logic.numericalOperator) {
        case "greaterEqual":
          if (
            answer > option.logic.numericalOperatorValue ||
            answer === option.logic.numericalOperatorValue
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "lesserEqual":
          if (
            answer < option.logic.numericalOperatorValue ||
            answer === option.logic.numericalOperatorValue
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "greater":
          if (answer > option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "less":
          if (answer < option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "between":
          if (
            answer > option?.logic?.rangeFrom &&
            answer < option?.logic?.rangeTo
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
      }
    });
  }
  return {
    status,
    message,
    jumpToquestion,
    screened,
    jumpToStatus,
  };
};
