import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Spin, Button } from "antd";
import { useParams } from "react-router-dom";
import { useSetRecoilState, useRecoilValue } from "recoil";
import StartSurvey from "./StartSurvey";
import SurveyNav from "../../components/survey/nav";
import SurveyCompleted from "../../components/survey/completed";
import SurveyScreened from "../../components/survey/screened";
import SurveyQuotaFull from "../../components/survey/quotaFull";
import StartSurveyClient from "./StartSurveyClient";
import {
  GET_SURVEY_ONLINE,
  EXIT_SURVEY,
  RESPONSE_ANSWERS_ONLINE_VALIDATION,
} from "./graphql";
import ErrorPage from "../../components/errorPage";
import { surveyState } from "../../atoms";

const OnlineSurvey = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [questionID, setQuestionID] = useState([]);
  const [exitSurvey] = useMutation(EXIT_SURVEY);
  const [client, setClient] = useState(null);

  const {
    data: surveyData,
    loading,
    error,
  } = useQuery(GET_SURVEY_ONLINE, {
    fetchPolicy: "network-only",
    variables: {
      survey_id: Number(params.surveyID),
      member_id: Number(params.memberID),
    },
  });

  const { data: responseAnswers } = useQuery(
    RESPONSE_ANSWERS_ONLINE_VALIDATION,
    {
      fetchPolicy: "network-only",
      variables: {
        id: Number(params.surveyID),
        member_id: Number(params.memberID),
      },
    }
  );
  // function setCookie(cName, cValue, expDays) {
  //   let date = new Date();
  //   date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  //   const expires = "expires=" + date.toUTCString();
  //   document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
  // }
  useEffect(() => {
    if (
      surveyData &&
      responseAnswers &&
      surveyData.survey_by_pk.project.survey_type !== "Client" &&
      surveyData.survey_by_pk.project.survey_type !== "Third_Party_survey"
    ) {
      let questionIDArray = [];
      // eslint-disable-next-line
      surveyData?.survey_by_pk?.questions.map((question) => {
        questionIDArray.push(question.id);
      });
      setQuestionID(questionIDArray);
      setSurveyState((oldSurveyState) => ({
        ...oldSurveyState,
        questions: surveyData?.survey_by_pk?.questions,
        member_type:
          surveyData?.survey_by_pk?.survey_x_members[0]?.user?.member_type_id ||
          "",
        survey_status:
          surveyData?.survey_by_pk?.survey_x_members[0]?.state || "",
        quota: surveyData?.survey_by_pk?.survey_x_members[0]?.quota || 1,
        completed:
          surveyData?.survey_by_pk?.survey_x_members[0]?.completed || 0,
        quotacount:
          surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
            ?.count || 0,
        sampleSize: surveyData?.survey_by_pk?.project?.sample,
        current_answer: [],
        current_answer_type:
          surveyData?.survey_by_pk?.questions[0]?.question_type?.name,
        current_question: surveyData?.survey_by_pk?.questions[0],
        include_profiler_questions:
          surveyData?.survey_by_pk?.project?.include_profiler_questions !== null
            ? surveyData?.survey_by_pk?.project?.include_profiler_questions
            : false,
        surveyType:
          surveyData.survey_by_pk.project.projectTypeByProjectType.name,
        responseAnswers: responseAnswers?.survey_by_pk,
      }));
    } else if (surveyData !== undefined) {
      setSurveyState((oldSurveyState) => ({
        ...oldSurveyState,
        survey_status:
          surveyData?.survey_by_pk?.survey_x_members[0]?.state || "",
        quota: surveyData?.survey_by_pk?.survey_x_members[0]?.quota || 1,
        completed:
          surveyData?.survey_by_pk?.survey_x_members[0]?.completed || 0,
        quotacount:
          surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
            ?.count || 0,
      }));
      surveyData?.survey_by_pk?.project?.survey_type === "Client"
        ? setClient(surveyData?.survey_by_pk?.survey_x_members[0]?.survey_url)
        : setClient(surveyData?.survey_by_pk?.links_status);

      localStorage.setItem(
        "memberID",
        surveyData?.survey_by_pk?.survey_x_members[0]?.member_id
      );
      localStorage.setItem("surveyID", surveyData?.survey_by_pk?.id);
      // const name = "memberID";
      // const path = `survey/${surveyData?.survey_by_pk?.id}/member/${surveyData?.survey_by_pk?.survey_x_members[0]?.member_id}`;
      // console.log(surveyData?.survey_by_pk?.survey_x_members[0]?.member_id);
      // setCookie(
      //   "memberID",
      //   surveyData?.survey_by_pk?.survey_x_members[0]?.member_id,
      //   1
      // );
      // document.cookie =
      //   name +
      //   "=" +
      //   surveyData?.survey_by_pk?.survey_x_members[0]?.member_id +
      //   "; path=/";
      // document.cookie = `memberID=${surveyData?.survey_by_pk?.survey_x_members[0]?.member_id}`;
    }
    // eslint-disable-next-line
  }, [surveyData, responseAnswers]);

  if (error) {
    return <ErrorPage />;
  }

  const handleExit = () => {
    exitSurvey({
      variables: {
        questionID: questionID,
        memberId: Number(params.memberID),
        surveyId: Number(params.surveyID),
      },
    }).then((res) => {
      window.location.reload();
    });
  };
  return (
    <Spin spinning={loading}>
      <div>
        {client !== undefined && client !== null ? (
          <>
            {surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
              ?.count !== undefined &&
              surveyData?.survey_by_pk?.project?.sample !== undefined &&
              surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
                ?.count < parseInt(surveyData?.survey_by_pk?.project?.sample) &&
              (surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
                "active" ||
                surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
                "in-progress") && (
                <>
                  <SurveyNav />
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-6">
                      <div className="hidden md:block text-h5 font-semibold text-textDark capitalize">
                        {surveyData?.survey_by_pk?.name || ""}
                      </div>
                    </div>

                    <StartSurveyClient
                      data={surveyData?.survey_by_pk}
                      client={client}
                      surveyType={surveyData.survey_by_pk.project.survey_type}
                    />
                  </div>
                  {/* {surveyData?.survey_by_pk?.project?.survey_type ===
                    "Client" && (
                    <div className="flex items-center justify-between mb-6">
                      <div className="hidden md:block text-h5 font-semibold text-textDark capitalize">
                        {surveyData?.survey_by_pk?.name || ""}
                      </div>
                    </div>
                  )} */}
                </>
              )}
            {surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
              "completed" && (
                <>
                  <SurveyNav /> <SurveyCompleted />
                </>
              )}
            {surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
              "screened" && (
                <>
                  <SurveyNav />
                  <SurveyScreened />
                </>
              )}
            {surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
              "quotaFull" && surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
                ?.count !==
              parseInt(surveyData?.survey_by_pk?.project?.sample) && (
                <>
                  <SurveyNav />
                  <>
                    <SurveyQuotaFull
                      quotacount={
                        surveyData?.survey_by_pk?.survey_x_members_aggregate
                          ?.aggregate?.count
                      }
                      sampleSize={parseInt(
                        surveyData?.survey_by_pk?.project?.sample
                      )}
                      quota={surveyData?.survey_by_pk?.survey_x_members[0]?.quota}
                      completed={
                        surveyData?.survey_by_pk?.survey_x_members[0]?.completed
                      }
                    />
                  </>
                </>
              )}
            {surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
              ?.count !== undefined &&
              surveyData?.survey_by_pk?.project?.sample !== undefined &&
              surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
                ?.count ===
              parseInt(surveyData?.survey_by_pk?.project?.sample) &&
              surveyDetails.survey_status !== "completed" &&
              surveyDetails.survey_status !== "" && (
                <>
                  <SurveyNav />
                  <SurveyQuotaFull
                    quotacount={
                      surveyData?.survey_by_pk?.survey_x_members_aggregate
                        ?.aggregate?.count
                    }
                    sampleSize={parseInt(
                      surveyData?.survey_by_pk?.project?.sample
                    )}
                    quota={surveyData?.survey_by_pk?.survey_x_members[0]?.quota}
                    completed={
                      surveyData?.survey_by_pk?.survey_x_members[0]?.completed
                    }
                  />
                </>
              )}
          </>
        ) : (
          <>
            <SurveyNav />
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="hidden md:block text-h5 font-semibold text-textDark capitalize">
                  {surveyData?.survey_by_pk?.name || ""}
                </div>
                {(surveyDetails.survey_status === "active" ||
                  surveyDetails.survey_status === "in-progress") &&
                  !surveyDetails.survey_completed && (
                    <div className="flex items-center justify-between md:justify-end w-full md:w-auto">
                      <div className="text-h8 text-textLight">
                        Question {surveyDetails.question_index + 1} of{" "}
                        {surveyDetails.questions.length}
                      </div>
                      <div className="hidden md:block mx-4 border-2 border-bgMedium h-10 rounded" />
                      <Button
                        type="link"
                        className="text-h8 font-semibold text-red"
                        onClick={handleExit}
                      >
                        Exit survey
                      </Button>
                    </div>
                  )}
              </div>
              {surveyDetails.survey_status === "completed" && (
                <SurveyCompleted />
              )}
              {surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
                ?.count !== undefined &&
                surveyData?.survey_by_pk?.project?.sample !== undefined &&
                surveyData?.survey_by_pk?.survey_x_members_aggregate?.aggregate
                  ?.count !== "" &&
                surveyData?.survey_by_pk?.project?.sample !== "" &&
                ((surveyData?.survey_by_pk?.survey_x_members_aggregate
                  ?.aggregate?.count ===
                  parseInt(surveyData?.survey_by_pk?.project?.sample) &&
                  surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
                  "active" &&
                  surveyData?.survey_by_pk?.survey_x_members[0]?.completed !==
                  1) ||
                  (surveyData?.survey_by_pk?.survey_x_members_aggregate
                    ?.aggregate?.count ===
                    parseInt(surveyData?.survey_by_pk?.project?.sample) &&
                    surveyData?.survey_by_pk?.survey_x_members[0]?.state ===
                    "quotaFull" &&
                    surveyData?.survey_by_pk?.survey_x_members[0]?.completed !==
                    1)) && (
                  <SurveyQuotaFull
                    quotacount={
                      surveyData?.survey_by_pk?.survey_x_members_aggregate
                        ?.aggregate?.count
                    }
                    sampleSize={parseInt(
                      surveyData?.survey_by_pk?.project?.sample
                    )}
                    quota={surveyData?.survey_by_pk?.survey_x_members[0]?.quota}
                    completed={
                      surveyData?.survey_by_pk?.survey_x_members[0]?.completed
                    }
                  />
                )}
              {surveyDetails.survey_status === "screened" && <SurveyScreened />}
              {surveyDetails.quota !== surveyDetails.completed &&
                surveyDetails.quotacount !==
                parseInt(surveyDetails.sampleSize) &&
                surveyDetails.survey_status !== "cancelled" &&
                surveyDetails.survey_status !== "screened" && (
                  <StartSurvey data={surveyData?.survey_by_pk} />
                )}
            </div>
          </>
        )}
      </div>
    </Spin>
  );
};

export default OnlineSurvey;
