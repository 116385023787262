import React, { useEffect } from "react";
import { SCREEN_SURVEY, UPDATE_INTERVIEWER_STATUS } from "./graphql";
import { useMutation } from "@apollo/client";

const SurveyScreened = () => {
  const [changeSurveyStatusToScreened] = useMutation(SCREEN_SURVEY);
  const [updateInterviewerStatus] = useMutation(UPDATE_INTERVIEWER_STATUS);

  useEffect(() => {
    changeSurveyStatusToScreened({
      variables: {
        survey_id: parseInt(localStorage.getItem("surveyID")),
        member_id: parseInt(localStorage.getItem("memberID")),
      },
    })
      .then(() => {
        if (localStorage.getItem("projectType") === "Assisted") {
          updateInterviewerStatus({
            variables: {
              member_id: parseInt(localStorage.getItem("memberID")),
              interviewer_id: parseInt(localStorage.getItem("interviewerID")),
              status: "screened",
              survey_id: parseInt(localStorage.getItem("surveyID")),
            },
          }).then(() => {
            localStorage.removeItem("interviewerID");
            localStorage.removeItem("surveyID");
            localStorage.removeItem("memberID");
          });
        } else {
          localStorage.removeItem("memberID");
          localStorage.removeItem("surveyID");
        }
      })
      .catch(() => {
        console.log("Error");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className="p-4 lg:py-48 lg:p-0 flex flex-col items-center justify-start bg-white shadow"
      style={{ height: "calc(100vh - 208px)" }}
    >
      <div className="bg-background p-4 flex flex-col items-center justify-center w-full lg:w-1/2 text-center">
        <div className="text-h5 text-textDark font-semibold mb-2">
          Thank you for responding to our survey invitation!
        </div>
        <div className="text-h11 text-textDark mb-2">
          Your views are important.
        </div>
        <p className="text-h8 text-textMedium text-center mb-4">
          We are sorry you were not able to complete this survey. Unfortunately,
          you did not match the criteria required for this survey. We appreciate
          your time and efforts and will soon send you another survey
          opportunity.
        </p>
        <div className="text-textLight text-h11">
          For any queries, please mail{" "}
          <a
            href="mailto:panelsupport@krea.in"
            className="decoration-solid underline"
          >
            panelsupport@krea.in
          </a>
        </div>
      </div>
    </div>
  );
};

export default SurveyScreened;
