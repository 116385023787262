import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import jwtDecode from 'jwt-decode';
import { useMutation, useQuery } from '@apollo/client';
import { EXPORT_EMAIL_TRIGGER, GET_ONE_USER, UPDATE_EXPORT_INFO } from './graphql';

const Export = (props) => {
    const [id, setId] = useState();
    const [updateExportInfo] = useMutation(UPDATE_EXPORT_INFO);
    const [ExportEmailTrigger] = useMutation(EXPORT_EMAIL_TRIGGER);
    const [exportedCount, setExportedCount] = useState(0);

    const { data } = useQuery(GET_ONE_USER, {
        variables: {
            id: parseInt(id),
        },
        fetchPolicy: "network-only",
    });

    const managerUserId = id;
    const todayDate = new Date().toISOString().slice(0, 10);
    const exportedOnDate = data?.user_by_pk?.exported_on?.slice(0, 10);
    const managerName = data?.user_by_pk?.f_name + " " + data?.user_by_pk?.l_name;
    const totalCount = props?.selectedMembers?.length;
    const statusCounts = {
        "Valid": 0,
        "raw": 0,
        "partial-email": 0,
        "partial-phone": 0,
        "not-validated": 0,
        "invalid": 0,
        "DNC": 0,
        "Prohibited": 0
    };
    props?.selectedMembers.forEach(member => {
        const status = member.status;
        if (statusCounts.hasOwnProperty(status)) {
            statusCounts[status]++;
        }
    });

    useEffect(() => {
        if (todayDate !== exportedOnDate) {
            setExportedCount(0);
        } else {
            setExportedCount(data?.user_by_pk?.export_count);
        }
    }, [data, exportedOnDate, todayDate]);

    useEffect(() => {
        try {
            let decode = jwtDecode(localStorage.getItem('token'))['https://hasura.io/jwt/claims']['x-hasura-user-id'];
            setId(decode);
        } catch (error) {
            console.log(error);
        }
    }, []);

    const selectedTabKey =
        props?.activeTab === '1'
            ? 'physician'
            : props?.activeTab === '2'
                ? 'technician'
                : props?.activeTab === '3'
                    ? 'patient'
                    : 'consumer';

    const exportToCSV = async () => {
        const newExportedToday = exportedCount + props?.selectedRowKeys.length;
        if (props?.userRole === 'manager') {
            if (newExportedToday > 1000) {
                const currentDate = new Date();
                const endOfDay = new Date(currentDate);
                endOfDay.setHours(23, 59, 59, 999);
                const timeRemainingMs = endOfDay - currentDate;
                const timeRemainingMinutes = Math.ceil(timeRemainingMs / (1000 * 60));
                const timeRemainingHours = Math.floor(timeRemainingMinutes / 60);
                const remainingMinutes = timeRemainingMinutes % 60;

                message.error(
                    `You have reached the maximum export limit for today (1000 members). Please wait for ${timeRemainingHours} hours and ${remainingMinutes} minutes before exporting again.`
                );
                return;
            }
            if (props?.selectedRowKeys.length > 100) {
                // maximum export limit per set
                message.error('You can export a maximum of 100 members at a time.');
                return;
            }
        }

        const arrayCheck = [];
        for (const key in props?.columnStatus[selectedTabKey]) {
            if (props?.columnStatus[selectedTabKey][key].enabled) {
                arrayCheck.push(key);
            }
        }

        const selectedColumns = arrayCheck;
        
        const csvContent = `\ufeff${selectedColumns.join(',')}\n${props?.selectedMembers
            .map((record) => selectedColumns.map((columnKey) => record[columnKey]).join(','))
            .join('\n')}`;

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', 'exported_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        try {
            if (props?.userRole === 'manager') {
                await updateExportInfo({
                    variables: {
                        id: parseInt(managerUserId),
                        export_count: newExportedToday,
                        exported_on: todayDate,
                    },
                });
                await ExportEmailTrigger({
                    variables: {
                        m_name: managerName,
                        not_validated: statusCounts?.['not-validated'],
                        raw_count: statusCounts?.['raw'],
                        volume_of_upload: totalCount,
                        dnc_count: statusCounts?.['DNC'],
                        invalid_count: statusCounts?.['invalid'],
                        partial_e_count: statusCounts?.['partial-email'],
                        partial_p_count: statusCounts?.['partial-phone'],
                        prohibited_count: statusCounts?.['Prohibited'],
                    },
                });
                setExportedCount(newExportedToday);
            }

            message.success('Exported successfully!');
            props.refetch();
            props.setSelectedRowKeys([]);
        } catch (error) {
            console.error('Error updating export info:', error);
            return;
        }
    };

    return (
        <div>
            <Button
                onClick={exportToCSV}
                className="ml-2"
                type="primary"
                size="medium"
                disabled={
                    props?.selectedRowKeys.length <= 0 ||
                    Object.values(props?.columnStatus[selectedTabKey]).every((item) => !item.enabled)
                }
            >
                Export
            </Button>
        </div>
    );
};

export default Export;