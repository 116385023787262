import moment from "moment";
import { notification } from "antd";
import { data } from "../pages/members/data";

export const downloadCSV = (csvData, fileInfo) => {
  let finalData = "";
  finalData = csvData.headers.map((header) => header.title).join(",") + "\n";
  let dataValues = csvData.data;
  for (let rowValues of dataValues) {
    let line = "";
    rowValues.map((value) => {
      let val = "";
      if (line != "") line += ",";
      if (value.includes('"')) {
        val = value.replace(/"/g, '""');
      } else if (value.includes(",") || value.includes("\n")) {
        val = `"${value}"`;
      } else {
        val = value;
      }
      line = line + val;
    });
    finalData += line + "\n";
  }
  const csvContent = "data:text/csv;charset=utf-8," + finalData;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute(
    "download",
    `${fileInfo}-${moment().format("DD-MM-YY")}.csv`
  );
  document.body.appendChild(link); // Required for FF
  link.click();
  link.remove();
};

export const validateCSV = (
  csvData,
  csvFormat,
  DBPhoneValues,
  DBEmailValues,
  respondentDB
) => {
  let rejectedRecords = [];
  let headers = csvFormat.headers.map((header) => header.title);
  let isHeadersMatch = true;
  let headerFormatLength = headers.length;
  let rowDataCountError = "";
  let mobileTest = /^[0-9]{10}$/;
  let respondentTest = /^\d{10}$/;
  let yearsOfExperienceTest = /^[0-9]+$/;
  let pincodeTest = /^[0-9]{6}$/;
  let yearOfBirthTest = /^[0-9]{4}$/;
  let emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
  let dataValues = csvData.split("\n");

  const dataValuesSplit = dataValues[0].split(",").map(item => item.trim());
  const headerValues = headers;
  const matchedValues = [];
  const unmatchedValues = [];

  // Loop through the 'one' array
  for (let i = 0; i < dataValuesSplit.length; i++) {
    const valueOne = dataValuesSplit
    [i];
    const valueTwo = headerValues[i];

    // Compare the values at the same index
    if (valueOne === valueTwo) {
      matchedValues.push(valueOne);
    } else {
      unmatchedValues.push(valueOne);
    }
  }

  let csvHeaderLenth = matchedValues.length;

  if (csvHeaderLenth !== headerFormatLength) {
    notification.error({
      message: `Header count mismatch`,
    });
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  } else {
    let csvHeaders = matchedValues;
    csvHeaders[csvHeaders.length - 1] = csvHeaders[
      csvHeaders.length - 1
    ].replace(/[\r]/g, "");
    csvHeaders.map((header, index) => {
      if (header !== headers[index]) {
        notification.error({
          message: `${header} header doesnot match`,
        });
        isHeadersMatch = false;
      }
    });
    if (isHeadersMatch) {
      dataValues.splice(0, 1);
      let errorData = "";
      let errors;
      let stateValue = "";
      let isStateValid = true;
      let repeatedArr = [];
      let respondentArr = [];
      let validData = new Set();
      let cityIndex = "";
      let isRowDataCountValid = true;
      let cityMatched;
      let rowNumber;
      let primaryValue = "", secondaryValue = "", superValue = "";
      dataValues.map((csvRow, rowIndex) => {
        rowNumber = 0;
        errors = "";
        stateValue = "";
        isStateValid = true;
        cityMatched = false;
        cityIndex = "";
        primaryValue = "";
        secondaryValue = "";
        superValue = "";
        if (csvRow !== "") {
          csvRow = csvRow.replace(/[\r]/g, "");

          let rowData = csvRow.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
          let unmatchedValuesLength = unmatchedValues && unmatchedValues.length;
          let startIndex = rowData.length - unmatchedValuesLength;

          const statusIndex = csvFormat.headers.findIndex((header) => header.title === "status");
          if (rowData[statusIndex] === "") {
            errors += `\t${++rowNumber}.Value for 'status' is required \n`;
          } else if (csvFormat.headers[statusIndex].allowedValues && !csvFormat.headers[statusIndex].allowedValues.includes(rowData[statusIndex])) {
            errors += `\t${++rowNumber}.Invalid value '${rowData[statusIndex]}' for 'status'\n`;
          }
          
          // Check if "respondent id" column is empty
          const respondentIdIndex = csvFormat.headers.findIndex((header) => header.title === "respondent id");
          if (rowData[respondentIdIndex] === "") {
            let randomRespondentId;
            do {
              randomRespondentId = Math.floor(1000000000 + Math.random() * 9000000000).toString();
            } while (respondentDB.includes(randomRespondentId) || randomRespondentId.length !== 10 || randomRespondentId[0] === '0');
            rowData[respondentIdIndex] = randomRespondentId;
          }
          else if (respondentDB.includes(rowData[respondentIdIndex])) {
            validData.add(csvRow);
          }
          if (rowData[respondentIdIndex] !== "") {
            let rowDataMatchedValue = rowData.slice(0, startIndex);
            if (rowDataMatchedValue.length === headerFormatLength) {
              rowDataMatchedValue.map((value, index) => {
                if (csvFormat.headers[index].isRequired && !value) {
                  errors += `\t${++rowNumber}.${csvFormat.headers[index].title
                    } is required \n`;
                }
                if (csvFormat.headers[index].isUnique && value) {
                  if (respondentDB.includes(rowDataMatchedValue[respondentIdIndex])) {
                    repeatedArr.push(value);
                  } else if (
                    DBPhoneValues.includes(value) ||
                    DBEmailValues.includes(value) ||
                    repeatedArr.includes(value)
                  ) {
                    errors += `\t${++rowNumber}.${csvFormat.headers[index].title} '${value}' is already used. Please use a different one \n`;
                  } else {
                    repeatedArr.push(value);
                  }
                }
                if (value && csvFormat.headers[index].title === 'respondent id') {
                  if (value.trim() === "") {
                    errors += `\t${++rowNumber}.Value is empty respondent id\n`;
                  } else if (!respondentTest.test(value)) {
                    errors += `\t${++rowNumber}.Value '${value}' is not a valid respondent id\n`;
                  } else {
                    respondentArr.push(value);
                  }
                }
                if (
                  value &&
                  (csvFormat.headers[index].type === "dropdown" ||
                    csvFormat.headers[index].type === "checkbox") &&
                  csvFormat.headers[index].title !== "ailment list" &&
                  csvFormat.headers[index].title !== "status" &&
                  csvFormat.headers[index].title !== "project compatibility" &&
                  csvFormat.headers[index].title !== "super speciality" &&
                  csvFormat.headers[index].title !== "state" &&
                  csvFormat.headers[index].title !== "city" &&
                  csvFormat.headers[index].title !== "profession" &&
                  csvFormat.headers[index].title !== "sub-category1" &&
                  csvFormat.headers[index].title !== "sub-category2" &&
                  !csvFormat.headers[index].allowedValues.includes(value)
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid in ${csvFormat.headers[index].title
                    }\n`;
                }
                if (
                  value &&
                  csvFormat.headers[index].type === "dropdown" &&
                  csvFormat.headers[index].title === "status" &&
                  !csvFormat.headers[index].allowedValues.includes(value)
                ) {
                  return `\t${++rowNumber}.Value '${value}' is not valid in ${csvFormat.headers[index].title}\n`;
                }

                if (
                  value &&
                  (csvFormat.headers[index].title === "ailment list" ||
                    csvFormat.headers[index].title === "project compatibility")
                ) {
                  if (value.match(/"(.*?)"/)) {
                    let multiValues = value
                      .match(/"(.*?)"/)[1]
                      .replace(/"/g, "")
                      .split(",");
                    multiValues.map((newValue) => {
                      if (
                        !csvFormat.headers[index].allowedValues.includes(newValue)
                      )
                        errors += `\t${++rowNumber}.Invalid value '${newValue}' in ${csvFormat.headers[index].title
                          }\n`;
                    });
                  } else {
                    if (!csvFormat.headers[index].allowedValues.includes(value)) {
                      errors += `\t${++rowNumber}.Invalid value '${value}' in ${csvFormat.headers[index].title
                        }\n`;
                    }
                  }
                }
                if (
                  value &&
                  csvFormat.headers[index].title === "state" &&
                  !data[value]
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid ${csvFormat.headers[index].title
                    }\n`;
                  isStateValid = false;
                }
                if (isStateValid && csvFormat.headers[index].title === "state") {
                  stateValue = value;
                }
                if (
                  value &&
                  isStateValid &&
                  stateValue &&
                  csvFormat.headers[index].type === "city"
                ) {
                  for (let [index, state] of data[stateValue].entries()) {
                    if (state.city === value) {
                      cityMatched = true;
                      cityIndex = index;
                    }
                  }
                  if (!cityMatched)
                    errors += `\t${++rowNumber}.Value '${value}' is not valid city\n`;
                }
                if (
                  stateValue &&
                  value &&
                  csvFormat.headers[index].title === "city category" &&
                  data[stateValue][cityIndex].category !== value
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid city category\n`;
                }

                if (
                  stateValue &&
                  value &&
                  csvFormat.headers[index].title === "zone" &&
                  data[stateValue][cityIndex].zone !== value
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid zone\n`;
                }

                if (
                  value &&
                  csvFormat.headers[index].title === "pincode" &&
                  !pincodeTest.test(value)
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid pincode\n`;
                }

                if (
                  value &&
                  (csvFormat.headers[index].title === "mobile number 1" ||
                    csvFormat.headers[index].title === "mobile number 2") &&
                  !mobileTest.test(value)
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid mobile number\n`;
                }

                if (
                  value &&
                  csvFormat.headers[index].title === "phone" &&
                  !mobileTest.test(value)
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid phone number\n`;
                }

                if (
                  value &&
                  (csvFormat.headers[index].title === "year of birth" ||
                    csvFormat.headers[index].title ===
                    "practice start year post residency") &&
                  (!yearOfBirthTest.test(value) ||
                    moment(value).isAfter(moment()))
                ) {
                  errors += `\t${++rowNumber}.'Value ${value}' is not valid year\n`;
                }

                if (value && csvFormat.headers[index].title === "validated date") {
                  let check = moment(value, 'DD-MM-YYYY', true).isValid();
                  if (!check) {
                    errors += `\t${++rowNumber}.'Value ${value}' is not valid date\n`;
                  }
                }

                if (
                  value &&
                  (csvFormat.headers[index].title === "primary email" ||
                    csvFormat.headers[index].title === "secondary email") &&
                  !emailTest.test(value)
                ) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid email Id\n`;
                }
                if (value && csvFormat.headers[index].title === "years of experience" && (!yearsOfExperienceTest.test(value) || value < 0 || value > 80)) {
                  errors += `\t${++rowNumber}.Value '${value}' is not valid years of experience\n`;
                }

                //Primary Speciality
                if (value && csvFormat.headers[index].title === 'primary speciality') {
                  primaryValue = value;
                }
                if (csvFormat.headers[index].title === 'primary details' && primaryValue === 'Others' && !value) {
                  errors += `\t${++rowNumber}.Primary details is not provided\n`;
                }

                //Super speciality
                if (value && csvFormat.headers[index].title === 'super speciality' && value === 'Others') {
                  superValue = value;
                }
                if (
                  value &&
                  csvFormat.headers[index].title === 'super speciality' &&
                  value !== 'Others'
                ) {
                  const specialityObj = csvFormat.headers[index]?.specialityObj?.[primaryValue];

                  if (specialityObj && !specialityObj.includes(value)) {
                    errors += `\t${++rowNumber}. Invalid super speciality value\n`;
                  }
                }
                if (csvFormat.headers[index].title === 'super speciality details' && superValue === 'Others' && !value) {
                  errors += `\t${++rowNumber}.Super speciality details is not provided\n`;
                }

                //Secondary speciality
                if (value && csvFormat.headers[index].title === 'secondary speciality' && value === 'Others') {
                  secondaryValue = value;
                }
                if (csvFormat.headers[index].title === 'secondary details' && secondaryValue === 'Others' && !value) {
                  errors += `\t${++rowNumber}.Secondary details is not provided\n`;
                }

              });
              if (errors === "") {
                validData.add(csvRow);
              } else {
                errors = `\n"Row Number ${rowIndex + 1} - \n${errors}"`;
              }
            } else {
              isRowDataCountValid = false;
              rowDataCountError += `Row Number ${rowIndex + 1
                } data count mismatch with the header count \n`;
            }

            if (errors === "") {
              validData.add(rowData.join(","));
            } else {
              errors = `\n"Row Number ${rowIndex + 1} - \n${errors}"`;
              const csvRowArray = csvRow.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
              const rejectedRecord = {};

              csvFormat.headers.forEach((header, index) => {
                rejectedRecord[header.title] = csvRowArray[index];
              });
              rejectedRecord.error = errors;

              rejectedRecords.push(rejectedRecord);
            }
          }
        }

        errorData += errors;
      });
      if (!isRowDataCountValid) {
        const csvContent = "data:text/csv;charset=utf-8," + rowDataCountError;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `Error_data-${moment().format("DD-MM-YY")}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
      }
      if (isRowDataCountValid && errorData) {
        const csvContent = "data:text/csv;charset=utf-8," + errorData;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `Error_data-${moment().format("DD-MM-YY")}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
        link.remove();
        rejectedRecords.forEach((rejectedRecord) => {
        });
      }

      const exceptEmptyResID = Array.from(validData).filter(item => {
        const parts = item.split(',');
        return parts[0].trim() !== "";
      });

      // console.log(exceptEmptyResID, dataValuesSplit, 'exceptEmptyResID');
      const resultArray = [];
      exceptEmptyResID.forEach((rowData) => {
        const rowDataArray = rowData.split(',');
        const rowDataObject = {};
        dataValuesSplit.forEach((header, index) => {
          rowDataObject[header] = rowDataArray[index];
        });
        resultArray.push(rowDataObject);
      });
      // console.log(exceptEmptyResID, rejectedRecords, unmatchedValues, resultArray, 'exceptEmptyResID,rejectedRecords,unmatchedValues,resultArray')
      return {
        finalValidData: exceptEmptyResID,
        rejectedRecords: rejectedRecords,
        additionalColumns: unmatchedValues,
        toCheckStatusMismatch: resultArray,
      };
    }
  }
};
