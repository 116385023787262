export const numericalValidation = (surveyDetails) => {
  let status = true;
  let message = "";
  let screened = false;
  let jumpToquestion;
  let jumpToStatus = false;
  let regex = /^d{1,}(.d{0,4})?$/;
  let validation =
    surveyDetails.current_question.validation !== null &&
    surveyDetails.current_question.validation.numericalValidation
      // eslint-disable-next-line
      .map((validation, i) => {
        let answer;
        let copy = surveyDetails.current_answer[i];

        for (let [key, value] of Object.entries(copy)) {
          if (key !== "type") {
            answer = value;
          }
        }
        if (validation.format !== null) {
          if (validation.format === "integer" && !Number.isInteger(answer)) {
            status = false;
            message = `Enter a integer value for ${validation.label}`;
            return true;
          } else if (validation.format === "decimal" && regex.test(answer)) {
            status = false;
            message = `Enter a decimal value for ${validation.label}`;
            return true;
          } else if (validation.format === "both") {
            return true;
          }
          if (validation.range === "custom") {
            if (
              !(answer >= validation.rangeFrom && answer <= validation.rangeTo)
            ) {
              status = false;
              message = `Enter a value for ${surveyDetails.current_question.questions_options[i].label} from ${validation.rangeFrom} to ${validation.rangeTo}`;
              return true;
            }
          } else if (validation.range === "question") {
            let ans_value =
              surveyDetails?.responseAnswers?.questions[
                validation.operatorValue - 1
              ]?.answers[0]?.answer;
            // eslint-disable-next-line
            switch (validation.operator) {
              case "greaterEqual":
                if (ans_value > answer || ans_value === answer) {
                  status = false;
                  message = `Enter a value for  ${surveyDetails.current_question.questions_options[i].label} greater than or equal to than ${ans_value}`;
                  return true;
                }
                break;
              case "lesserEqual":
                if (ans_value < answer || ans_value === answer) {
                  status = false;
                  message = `Enter a value for  ${surveyDetails.current_question.questions_options[i].label} less than or equal to ${ans_value}`;
                  return true;
                }
                break;
              case "greater":
                if (ans_value > answer) {
                  status = false;
                  message = `Enter a value for  ${surveyDetails.current_question.questions_options[i].label} greater than ${ans_value}`;
                  return true;
                }
                break;
              case "less":
                if (ans_value < answer) {
                  status = false;
                  message = `Enter a value for  ${surveyDetails.current_question.questions_options[i].label} less than ${ans_value}`;
                  return true;
                }
                break;
            }
          }
        }
      })
      .filter((data) => data);
  if (!validation.length) {
    // eslint-disable-next-line
    surveyDetails.current_question.questions_options.map((option, i) => {
      let answer;
      let copy = surveyDetails.current_answer[i];
      for (let [key, value] of Object.entries(copy)) {
        if (key !== "type") {
          answer = value;
        }
      }
      // eslint-disable-next-line
      switch (option.logic !== null && option.logic.numericalOperator) {
        case "greaterEqual":
          if (answer >= option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
              return true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
              return true;
            }
          }
          break;
        case "lesserEqual":
          if (answer <= option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
              return true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
              return true;
            }
          }
          break;
        case "greater":
          if (answer > option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
              return true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
              return true;
            }
          }
          break;
        case "less":
          if (answer < option.logic.numericalOperatorValue) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
              return true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
              return true;
            }
          }
          break;
        case "between":
          if (
            answer > option?.logic?.rangeFrom &&
            answer < option?.logic?.rangeTo
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
              return true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
              return true;
            }
          }
          break;
      }
    });
  }
  return {
    status,
    message,
    jumpToquestion,
    screened,
    jumpToStatus,
  };
};
