import {
  Drawer,
  Form,
  Button,
  Table,
  Checkbox,
  DatePicker,
  TimePicker,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import FileUpload from "./fileUploadValidation";
import { formatTime } from "../../../../../../utils/dateTime";
import { ArrowRightOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

function DateAndTimeValidation(props) {
  const [visible, setVisible] = useState(props.visible);
  const [value, setValue] = useState(
    props?.validationObject[props?.questionNumber]
  );
  const emptyArray = new Array(props.optionValue.length).fill("");
  const [uploadOption, setUploadOption] = useState(
    props?.validationObject[props.questionNumber]
      ? props?.validationObject[props.questionNumber].addFileOption
      : false
  );
  const [errorMessage, setErrorMessage] = useState(emptyArray);
  const [errorTime, setErrorTime] = useState(emptyArray);
  const [dateRange, setDateRange] = useState(emptyArray);
  const [timeRange, setTimeRange] = useState(emptyArray);
  const [form] = Form.useForm();
  let dateArray = [];
  let timeArray = [];
  useEffect(() => {
    assignInitialValue(props);
    // eslint-disable-next-line
  }, [props]);

  const assignInitialValue = (props) => {
    if (
      props?.validationObject[props.questionNumber] &&
      props?.validationObject[props.questionNumber]?.dateAndTimeRange &&
      props?.validationObject[props.questionNumber]?.dateAndTimeRange.length !==
        0
    ) {
      props?.validationObject[props.questionNumber]?.dateAndTimeRange
        // eslint-disable-next-line
        .map((value, index) => {
          dateArray.push({
            dateRange:
              value.dateRange &&
              value.dateRange.dateRange !== null &&
              value.dateRange.length !== 0
                ? [
                    value.dateRange.dateRange[0]
                      ? moment(value.dateRange.dateRange[0])
                      : "",
                    value.dateRange.dateRange[1]
                      ? moment(value.dateRange.dateRange[1])
                      : "",
                  ]
                : "",
          });
          timeArray.push({
            label: value.timeRange.label,
            startTime: value.timeRange.startTime,
            endTime: value.timeRange.endTime,
            timeRange:
              value.timeRange &&
              value.timeRange.timeRange !== null &&
              value.timeRange.length !== 0
                ? [
                    value.timeRange.timeRange[0]
                      ? moment(value.timeRange.timeRange[0])
                      : "",
                    value.timeRange.timeRange[1]
                      ? moment(value.timeRange.timeRange[1])
                      : "",
                  ]
                : "",
          });
        });
      setDateRange(dateArray);
      setTimeRange(timeArray);
    }
  };

  const onClose = () => {
    let dateAndTimeRange = [];
    props.optionValue.map((optionValue, index) => {
      return dateAndTimeRange.push({
        label: optionValue.option,
        dateRange: dateRange[index] !== "" ? dateRange[index] : null,
        timeRange: timeRange[index] !== "" ? timeRange[index] : null,
      });
    });

    let validationObject = {
      dateAndTimeRange: dateAndTimeRange,
      questionMandatory: form.getFieldValue("questionMandatory")
        ? form.getFieldValue("questionMandatory")
        : false,
      addFileOption: form.getFieldValue("addFileOption")
        ? form.getFieldValue("addFileOption")
        : null,
      fileUploadDescription: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileUploadDescription")
        : null,
      fileTypesAllowed: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileTypesAllowed")
        : null,
      fileSize: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileSize")
        : null,
      fileNumbers: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers")
        : null,
      fileLimit: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers") &&
          form.getFieldValue("fileNumbers")[0] === "Limit to"
          ? form.getFieldValue("fileLimit")
          : null
        : null,
    };
    if (
      errorTime.every((option, index) => {
        return option === "";
      }) &&
      errorMessage.every((option, index) => {
        return option === "";
      })
    ) {
      props.handleValidationValues(
        validationObject,
        visible,
        props.questionNumber
      );
      setVisible(false);
    }
  };
  // let error = [];
  // function disabledDate(current) {
  //   // Can not select days before today and today
  //   return current && current < moment();
  // }
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    props.handleValidationValues(
      props.validationObject[props.questionNumber],
      visible,
      props.questionNumber
    );
  };

  const onReset = () => {
    form.setFieldsValue({ addFileOption: false });
    form.setFieldsValue({ fileUploadDescription: "" });
    form.setFieldsValue({ fileTypesAllowed: [] });
    form.setFieldsValue({ fileSize: null });
    form.setFieldsValue({ fileNumbers: [] });
    form.setFieldsValue({ fileLimit: null });
    setValue(null);
    setUploadOption(false);
    setDateRange(emptyArray);
    setTimeRange(emptyArray);
  };
  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Date range",
      dataIndex: "dateRange",
      key: "dateRange",
      render: (text, record, index) => (
        <>
          <RangePicker
            className="text-sm text-textMedium"
            allowClear={true}
            value={
              dateRange[index] === undefined || dateRange[index] === ""
                ? ""
                : dateRange[index].dateRange
            }
            separator={<ArrowRightOutlined style={{ color: "#C4C4C4" }} />}
            onChange={(e) => {
              let error = [...errorMessage];
              if (e !== null && e[0].isSame(e[1])) {
                error[index] = `Start date and end date should be different`;
              } else {
                error[index] = "";
              }
              setErrorMessage(error);

              let newArray = [...dateRange];
              if (newArray[index] === undefined) {
                newArray.push({ label: record.label, dateRange: e });
              } else {
                newArray[index] = {
                  label: record.label,
                  dateRange: e,
                };
              }
              setDateRange(newArray);
            }}
          />
          <p className="text-red">{errorMessage[index]}</p>
        </>
      ),
    },
    {
      title: "Time Range",
      dataIndex: "timeRange",
      key: "timeRange",
      render: (text, record, index) => (
        <>
          <TimePicker.RangePicker
            className="text-sm text-textMedium"
            use12Hours
            format="h:mm A"
            allowClear={true}
            value={
              timeRange[index] === undefined || timeRange[index] === ""
                ? ""
                : timeRange[index].timeRange
            }
            disabled={!props.optionValue[index].includeTime}
            separator={<ArrowRightOutlined style={{ color: "#C4C4C4" }} />}
            onChange={(e) => {
              let error = [...errorTime];
              if (e !== null && formatTime(e[0]) === formatTime(e[1])) {
                error[index] = `Start time and end time should be different`;
              } else {
                error[index] = "";
              }
              setErrorTime(error);

              let newArray = [...timeRange];
              if (newArray[index] === undefined) {
                newArray.push({
                  label: record.label,
                  startTime: e !== null ? formatTime(e[0]) : null,
                  endTime: e !== null ? formatTime(e[1]) : null,
                  timeRange: e,
                });
              } else {
                newArray[index] = {
                  label: record.label,
                  startTime: e !== null ? formatTime(e[0]) : null,
                  endTime: e !== null ? formatTime(e[1]) : null,
                  timeRange: e,
                };
              }
              setTimeRange(newArray);
            }}
          />
          <p className="text-red">{errorTime[index]}</p>
        </>
      ),
    },
  ];
  let data = [];
  const dataSource = () => {
    props.optionValue.map((optionValue, index) =>
      data.push({ key: index + 1, label: optionValue.option })
    );
    return data;
  };
  const initialValue =
    props?.validationObject &&
    props?.validationObject?.length !== 0 &&
    props?.validationObject[props.questionNumber] !== null
      ? props?.validationObject[props.questionNumber]
      : {
          questionMandatory: true,
        };
  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Validation</span>}
        width={720}
        onClose={onCancel}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={
          <>
            <div style={{ float: "left", width: "70%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
            <div style={{ float: "left", width: "30%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={onClose}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Add
              </Button>
            </div>
          </>
        }
      >
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <Form.Item>
            <Table
              dataSource={dataSource()}
              columns={columns}
              pagination={false}
              rowClassName={(record, index) => "text-sm text-textMedium"}
            />
          </Form.Item>
          <Form.Item
            name="questionMandatory"
            value="questionMandatory"
            valuePropName="checked"
          >
            <Checkbox className="text-textMedium text-sm">
              This question is mandatory
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="addFileOption"
            value="addFileOption"
            valuePropName="checked"
          >
            <Checkbox
              className="text-textMedium text-sm"
              onChange={(e) => setUploadOption(e.target.checked)}
            >
              Add upload file option
            </Checkbox>
          </Form.Item>
          <Form.Item value="fileDetails">
            {uploadOption && (
              <FileUpload
                checked={true}
                textInput={value !== null ? value?.fileLimit : null}
                fileNumbers={
                  value !== null && value?.fileNumbers
                    ? value?.fileNumbers[0]
                    : null
                }
              />
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default DateAndTimeValidation;
