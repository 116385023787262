import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Space,
  Input,
  Modal,
  InputNumber,
  Tooltip,
} from "antd";
import { useHistory, useParams } from "react-router";
import {
  SearchOutlined,
  DownloadOutlined,
  FormOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import verticalDots from "../../../assets/verticalDots.svg";
import {
  GET_MEMBERS_RESPONSES,
  UPDATE_QUOTA,
  UPDATE_QUOTA_ALL,
} from "../graphql";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { downloadCSVFile } from "../../../utils/downloadCSVFile";
import ColumnEditor from "../../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../../components/memberColumns"
import MemberTable from "../../../components/memberTable";

function ResponsesTab(props) {
  const { surveyID, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [maxQuota, setMaxQuota] = useState(0);
  const history = useHistory();
  let searchInput;
  const [role, setRole] = useState(null);
  const todayDate = moment().format("DD/MMM/YYYY");
  const savedviewProjectPrf = localStorage.getItem('viewProjectPrf');
  const viewProjectPrfinitial = savedviewProjectPrf
    ? JSON.parse(savedviewProjectPrf)
    : {
      ...memberColumnStatus,
      "quota": { enabled: true, label: "Quote" },
      "responses": { enabled: true, label: "Responses" },
      "completed": { enabled: true, label: "Completed" },
      "screened": { enabled: true, label: "Screened" },
    }
  const [columnStatus, setStatus] = useState(viewProjectPrfinitial);

  useEffect(() => {
    localStorage.setItem('viewProjectPrf', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { data: membersResponses } = useQuery(GET_MEMBERS_RESPONSES, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  const [updateQuota] = useMutation(UPDATE_QUOTA, {
    refetchQueries: [
      {
        query: GET_MEMBERS_RESPONSES,
        variables: {
          id: parseInt(surveyID),
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  const [updateQuotaAll] = useMutation(UPDATE_QUOTA_ALL, {
    refetchQueries: [
      {
        query: GET_MEMBERS_RESPONSES,
        variables: {
          id: parseInt(surveyID),
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  /*useEffect(() => {
    if (membersResponses) {
      let object = JSON.parse(JSON.stringify(membersResponses?.patient));
      object.forEach((item) => {
        item.memberName = item.user.f_name + " " + item.user.l_name;
        item.quota = item.user.survey_x_members[0].quota;
        item.completed = item.user.completed.aggregate.count;
        item.screened = item.user.screened.aggregate.count;
        item.action = item.id;
        item.email = item.user.email;
        item.phone_number = item.user.phone_number;
        // item.terminated = item.user.terminated.aggregate.count;
        item.terminated = 0;
        item.responses = item.screened + item.completed + item.terminated;
      });
      setTableData(object);
    }
  }, [membersResponses]);*/

  useEffect(() => {
    let newObject = [];
    if (membersResponses) {
      let object = JSON.parse(JSON.stringify(membersResponses?.patient));
      object.forEach((item, index) => {
        newObject.push(userMemberData(item?.user, (data) => {
          data.quota = item.user.survey_x_members[0].quota;
          data.completed = item.user.completed.aggregate.count;
          data.screened = item.user.screened.aggregate.count;
          data.action = item.id;
          //data.terminated = item.user.terminated.aggregate.count;
          data.terminated = 0;
          data.responses = data.screened + data.completed + data.terminated;
        }));
      });
      setTableData(newObject);
    }
  }, [membersResponses]);

  // function handleDownloadExcel() {
  //   const header = [
  //     "Name",
  //     "Email",
  //     "Phone_number",
  //     "Quota",
  //     "Responses",
  //     "Completed",
  //     "Screened",
  //   ];
  //   const body = [];
  //   const todayDate = moment().format("DD/MMM/YYYY");
  //   tableData.map((item) => {
  //     console.log(membersResponses);
  //     return body.push({
  //       Name: item.memberName,
  //       Email: item.email,
  //       Phone_number: item.phone_number,
  //       Quota: item.user.survey_x_members[0].quota,
  //       Responses:
  //         item.user.screened.aggregate.count +
  //         item.user.completed.aggregate.count +
  //         0,
  //       Completed: item.user.completed.aggregate.count,
  //       Screened: item.user.screened.aggregate.count,
  //     });
  //   });
  //   downloadExcel({
  //     fileName: `${props.name}-${todayDate}`,
  //     sheet: `${props.name}-${todayDate}`,
  //     tablePayload: {
  //       header,
  //       body: body,
  //     },
  //   });
  // }

  console.log(tableData);
  useEffect(() => {
    if (tableData) {
      tableData.forEach((data) => {
        setMaxQuota((prevMax) => (prevMax > data.quota ? prevMax : data.quota));
      });
    }
  }, [tableData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleAll, setIsModalVisibleAll] = useState(false);
  const [quota, setQuota] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = (id) => {
    updateQuota({
      variables: {
        survey_id: parseInt(surveyID),
        member_id: id,
        quota: quota,
      },
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalAll = () => {
    setIsModalVisibleAll(true);
  };

  const handleOkAll = () => {
    updateQuotaAll({
      variables: {
        survey_id: parseInt(surveyID),
        quota: maxQuota,
      },
    }).then(() => {
      window.location.reload();
    });
    setIsModalVisibleAll(false);
  };

  const handleCancelAll = () => {
    setIsModalVisibleAll(false);
  };

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "QUOTA",
      dataIndex: "quota",
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("quota"),
    },
    {
      title: "RESPONSES",
      dataIndex: "responses",
      className: columnStatus?.responses?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("responses"),
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: "COMPLETED",
      dataIndex: "completed",
      className: columnStatus?.completed?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("completed"),
    },
    {
      title: "SCREENED",
      dataIndex: "screened",
      className: columnStatus?.screened?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("screened"),
    },
    // {
    //   title: "TERMINATED",
    //   dataIndex: "terminated",
    // },
    {
      title: () => {
        return (
          <>
            <span>ACTION</span>
            <span>
              <Tooltip placement="top" title="Edit All">
                <Button
                  type="link"
                  className="p-0 mx-5 "
                  onClick={showModalAll}
                >
                  <FormOutlined style={{ fontSize: "1.5rem" }} />
                </Button>
              </Tooltip>
              <Tooltip placement="top" title="Dowload Data">
                <Button
                  type="link"
                  className="float-right p-0"
                  onClick={() => {
                    columns.pop();
                    downloadCSVFile(
                      tableData,
                      columns,
                      `${props.name}-${todayDate}`
                    );
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "1.5rem" }} />
                </Button>
              </Tooltip>
            </span>
          </>
        );
      },
      dataIndex: "action",
      render: (text, record) => (
        <Dropdown
          overlay={menu({
            id: record.user.id,
            handleCancel: handleCancel,
            handleOk: handleOk,
            showModal: showModal,
            isModalVisible: isModalVisible,
            name: record.memberName,
            quota: record.quota,
          })}
          placement="bottomRight"
        // trigger={["click"]}
        >
          <Button type="link" size="large">
            <img src={verticalDots} alt="vertical_Dots" />
          </Button>
        </Dropdown>
      ),
    },
  ];*/

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),
    ...[
      {
        title: "QUOTA",
        dataIndex: "quota",
        isShow: columnStatus?.quota?.enabled,
        className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("quota"),
      },
      {
        title: "RESPONSES",
        dataIndex: "responses",
        isShow: columnStatus?.responses?.enabled,
        className: columnStatus?.responses?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("responses"),
        render: (text, record) => <span>{text}</span>,
      },
      {
        title: "COMPLETED",
        dataIndex: "completed",
        isShow: columnStatus?.completed?.enabled,
        className: columnStatus?.completed?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("completed"),
      },
      {
        title: "SCREENED",
        dataIndex: "screened",
        isShow: columnStatus?.screened?.enabled,
        className: columnStatus?.screened?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("screened"),
      },
      // {
      //   title: "TERMINATED",
      //   dataIndex: "terminated",
      // },
      {
        title: () => {
          return (
            <>
              <span>ACTION</span>
              <span>
                <Tooltip placement="top" title="Edit All">
                  <Button
                    type="link"
                    className="p-0 mx-5 "
                    onClick={showModalAll}
                  >
                    <FormOutlined style={{ fontSize: "1.5rem" }} />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title="Dowload Data">
                  <Button
                    type="link"
                    className="float-right p-0"
                    onClick={() => {
                      memColumns.pop();
                      downloadCSVFile(
                        tableData,
                        memColumns,
                        `${props.name}-${todayDate}`
                      );
                    }}
                  >
                    <DownloadOutlined style={{ fontSize: "1.5rem" }} />
                  </Button>
                </Tooltip>
              </span>
            </>
          );
        },
        dataIndex: "action",
        render: (text, record) => (
          <Dropdown
            overlay={menu({
              id: record.id,
              handleCancel: handleCancel,
              handleOk: handleOk,
              showModal: showModal,
              isModalVisible: isModalVisible,
              name: record.memberName,
              quota: record.quota,
            })}
            placement="bottomRight"
          // trigger={["click"]}
          >
            <Button type="link" size="large">
              <img src={verticalDots} alt="vertical_Dots" />
            </Button>
          </Dropdown>
        )
      }
    ]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus, isModalVisible])
  const menu = (props) => {
    return (
      <>
        <Modal
          title={`Edit quota for ${props.name}`}
          visible={props.isModalVisible}
          okText={<span>Update</span>}
          onOk={() => props.handleOk(props.id)}
          onCancel={props.handleCancel}
        >
          <div className="flex justify-start items-center">
            <div className="mr-2">Current Quota</div>
            <div className="ml-2">
              <InputNumber
                min={props.quota}
                max={10}
                defaultValue={props.quota}
                onChange={(e) => {
                  setQuota(e);
                }}
              />
            </div>
          </div>
        </Modal>
        <Menu className="rounded-md bg-gray">
          <Menu.Item key="1" onClick={props.showModal}>
            <span className="text-textLight hover:text-orange">Edit quota</span>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="2">
            <span
              className="text-textLight hover:text-orange"
              onClick={() => {
                history.push(
                  `/viewSurvey/members/project/${projectID}/survey/${surveyID}/PRF/viewDetailedResponse/member/${props.id}`
                );
              }}
            >
              View response
            </span>
          </Menu.Item>
        </Menu>
      </>
    );
  };

  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">
        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setStatus(column)
        }} />
      </div>
      <MemberTable
        columns={memColumns}
        tableData={tableData}
        pagination={false}
        rowClassName={(record, index) => "text-sm text-textMedium"}
      />
      <Modal
        title={`Edit quota for all`}
        visible={isModalVisibleAll}
        okText={<span>Update</span>}
        onOk={handleOkAll}
        onCancel={handleCancelAll}
      >
        <div className="flex justify-start items-center">
          <div className="mr-2">Current Quota</div>
          <div className="ml-2">
            <InputNumber
              min={maxQuota}
              max={100}
              defaultValue={maxQuota}
              onChange={(e) => {
                setMaxQuota(e);
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default ResponsesTab;
