import React, { useState } from "react";
import { InputNumber } from "antd";
import { useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const NumericalTextBox = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [data, setData] = useState([]);
  const [answer, setAnswer] = useState([]);

  const { data: answers, refetch: fetchAnswer } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    fetchAnswer();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy.push(value);
        }
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      answer: copy.length > 0 && copy[0] !== null ? copy[0].toString() : "",
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
    }));
    setAnswer(copy);
    // eslint-disable-next-line
  }, [data, answers]);

  const handleOnChange = (value, label, i) => {
    let current_answer_copy = [...surveyDetails.current_answer];
    let ans_copy = [...answer];
    ans_copy[i] = value;
    let copy = {};
    copy[label] = value;
    copy["type"] = props.question.question_type.name;
    current_answer_copy[i] = copy;
    setAnswer(ans_copy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      answer:
        ans_copy.length > 0 && ans_copy[0] !== null
          ? ans_copy[0].toString()
          : "",
      current_answer: current_answer_copy,
      // responseAnswers: responseAnswers?.survey_by_pk,
    }));
  };

  return (
    <div className="mt-6">
      {data.map((option, i) => (
        <div className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4">
          {option.label && (
            <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
              {option.label}
            </span>
          )}
          <InputNumber
            size="large"
            className="w-85 lg:w-96 rounded"
            placeholder={
              props.questionType === "Text box - numerical"
                ? ""
                : "Enter percentage"
            }
            value={answer[i]}
            step={
              props?.question?.validation !== null &&
              props?.question?.validation?.numericalValidation &&
              props?.question?.validation?.numericalValidation[i]?.format ===
                "decimal"
                ? 0.01
                : 1
            }
            min={0}
            onChange={(value) => handleOnChange(value, option.label, i)}
          />
          {props.questionType === "Text box - numerical" &&
            props?.question?.validation !== null &&
            props?.question?.validation?.numericalValidation[i] &&
            props?.question?.validation?.numericalValidation[i].range ===
              "custom" && (
              <span className="text-textLight">
                Hint: Enter a value between{" "}
                {props?.question?.validation?.numericalValidation[i].rangeFrom}{" "}
                and{" "}
                {props?.question?.validation?.numericalValidation[i].rangeTo}
              </span>
            )}
        </div>
      ))}
    </div>
  );
};

export default NumericalTextBox;
