export const data = {
  "Andaman and Nicobar Islands": [
    {
      city: "Port Blair",
      category: "Tier 3",
      zone: '-'
    },
  ],
  "Andhra Pradesh": [
    {
      city: "Visakhapatnam",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Vijayawada",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Guntur",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Nellore",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Kurnool",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rajahmundry",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kakinada",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirupati",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Anantapur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kadapa",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vizianagaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Eluru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ongole",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nandyal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Machilipatnam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Adoni",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tenali",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chittoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Hindupur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Proddatur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bhimavaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Madanapalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Guntakal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Dharmavaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gudivada",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Srikakulam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Narasaraopet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rajampet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tadpatri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tadepalligudem",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chilakaluripet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Yemmiganur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kadiri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chirala",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Anakapalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kavali",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palacole",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sullurpeta",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tanuku",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rayachoti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Srikalahasti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bapatla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Naidupet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nagari",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gudur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vinukonda",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Narasapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nuzvid",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Markapur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ponnur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kandukur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bobbili",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rayadurg",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Samalkot",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Jaggaiahpet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tuni",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Amalapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bheemunipatnam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Venkatagiri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sattenapalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pithapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palasa Kasibugga",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Parvathipuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Macherla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gooty",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Salur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mandapeta",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Jammalamadugu",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Peddapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Punganur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nidadavole",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Repalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ramachandrapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kovvur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruvuru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Uravakonda",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Narsipatnam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Yerraguntla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pedana",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Puttur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Renigunta",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rajam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Srisailam Project (Right Flank Colony) Township",
      category: "Tier 3",
      zone: "South",
    },
  ],
  "Arunachal Pradesh": [
    {
      city: "Naharlagun",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Pasighat",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Assam: [
    {
      city: "Guwahati",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Silchar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dibrugarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nagaon",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tinsukia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jorhat",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bongaigaon City",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dhubri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Diphu",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "North Lakhimpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tezpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Karimganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sibsagar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Goalpara",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Barpeta",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lanka",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lumding",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mankachar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nalbari",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rangia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Margherita",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mangaldoi",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Silapathar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mariani",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Marigaon",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Bihar: [
    {
      city: "Patna",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Gaya",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bhagalpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Muzaffarpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Darbhanga",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Arrah",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Begusarai",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Chhapra",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Katihar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Munger",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Purnia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Saharsa",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sasaram",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Hajipur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dehri-on-Sone",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bettiah",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Motihari",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bagaha",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Siwan",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kishanganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jamalpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Buxar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jehanabad",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Aurangabad",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lakhisarai",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nawada",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jamui",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sitamarhi",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Araria",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Gopalganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Madhubani",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Masaurhi",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Samastipur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mokameh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Supaul",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dumraon",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Arwal",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Forbesganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "BhabUrban Agglomeration",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Narkatiaganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Naugachhia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Madhepura",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sheikhpura",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sultanganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Raxaul Bazar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Ramnagar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mahnar Bazar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Warisaliganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Revelganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rajgir",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sonepur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sherghati",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sugauli",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Makhdumpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Maner",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rosera",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nokha",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Piro",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rafiganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Marhaura",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mirganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lalganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Murliganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Motipur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Manihari",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sheohar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Maharajganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Silao",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Barh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Asarganj",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Chandigarh: [
    {
      city: "Chandigarh",
      category: "Tier 2",
      zone: "North",
    },
  ],
  Chhattisgarh: [
    {
      city: "Raipur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Bhilai Nagar",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Korba",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bilaspur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Durg",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajnandgaon",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Jagdalpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Raigarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ambikapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mahasamund",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Dhamtari",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Chirmiri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bhatapara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Dalli-Rajhara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Naila Janjgir",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tilda Newra",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mungeli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Manendragarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sakti",
      category: "Tier 3",
      zone: "North",
    },
  ],
  "Dadra and Nagar Haveli": [
    {
      city: "Silvassa",
      category: "Tier 3",
      zone: "West",
    },
  ],
  Delhi: [
    {
      city: "Delhi",
      category: "Tier 1",
      zone: "North",
    },
  ],
  Goa: [
    {
      city: "Marmagao",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Panaji",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Margao",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mapusa",
      category: "Tier 3",
      zone: "South",
    },
  ],
  Gujarat: [
    {
      city: "Ahmedabad",
      category: "Tier 1",
      zone: "West",
    },
    {
      city: "Surat",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Vadodara",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Rajkot",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Bhavnagar",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Jamnagar",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Nadiad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Porbandar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Anand",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Morvi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mahesana",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Bharuch",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vapi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Navsari",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Veraval",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Bhuj",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Godhra",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Palanpur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Valsad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Patan",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Deesa",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Amreli",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Anjar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Dhoraji",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Khambhat",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mahuva",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Keshod",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wadhwan",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ankleshwar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Savarkundla",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Kadi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Visnagar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Upleta",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Una",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sidhpur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Unjha",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mangrol",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Viramgam",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Modasa",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Palitana",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Petlad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Kapadvanj",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sihor",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wankaner",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Limbdi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mandvi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Thangadh",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vyara",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Padra",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Lunawada",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Rajpipla",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vapi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Umreth",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sanand",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Rajula",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Radhanpur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mahemdabad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ranavav",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Tharad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mansa",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Umbergaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Talaja",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vadnagar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Manavadar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Salaya",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vijapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pardi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Rapar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Songadh",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Lathi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Adalaj",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Chhapra",
      category: "Tier 3",
      zone: "West",
    },
  ],
  Haryana: [
    {
      city: "Faridabad",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Gurgaon",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Hisar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rohtak",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Panipat",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Karnal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sonipat",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Yamunanagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Panchkula",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bhiwani",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bahadurgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Jind",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sirsa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Thanesar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kaithal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Palwal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rewari",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Hansi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Narnaul",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Fatehabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Gohana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tohana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Narwana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandi Dabwali",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Charkhi Dadri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahbad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pehowa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Samalkha",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pinjore",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ladwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sohna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Safidon",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Taraori",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mahendragarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ratia",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rania",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sarsod",
      category: "Tier 3",
      zone: "North",
    },
  ],
  "Himachal Pradesh": [
    {
      city: "Shimla",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Solan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nahan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sundarnagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Palampur",
      category: "Tier 3",
      zone: "North",
    },
  ],
  "Jammu and Kashmir": [
    {
      city: "Srinagar",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Jammu",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Baramula",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Anantnag",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sopore",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "KathUrban Agglomeration",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajauri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Punch",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Udhampur",
      category: "Tier 3",
      zone: "North",
    },
  ],
  Jharkhand: [
    {
      city: "Dhanbad",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Ranchi",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Jamshedpur",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Bokaro Steel City",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Deoghar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Phusro",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Adityapur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Hazaribag",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Giridih",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Ramgarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jhumri Tilaiya",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Saunda",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sahibganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Medininagar (Daltonganj)",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Chaibasa",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Chatra",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Gumia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dumka",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Madhupur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Chirkunda",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Pakaur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Simdega",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Musabani",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mihijam",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Patratu",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lohardaga",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tenu dam-cum-Kathhara",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Karnataka: [
    {
      city: "Bengaluru",
      category: "Tier 1",
      zone: "South",
    },
    {
      city: "Hubli-Dharwad",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Belagavi(Belgam)",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Mangaluru",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Mysore",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Davanagere",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ballari",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tumkur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shivamogga",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Raayachuru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Robertson Pet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kolar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mandya",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Udupi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chikkamagaluru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Karwar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ranebennuru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ranibennur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ramanagaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gokak",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Yadgir",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rabkavi Banhatti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shahabad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sirsi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sindhnur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiptur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Arsikere",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nanjangud",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sagara",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sira",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Puttur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Athni",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mulbagal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Surapura",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Siruguppa",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mudhol",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sidlaghatta",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shahpur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Saundatti-Yellamma",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Wadi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Manvi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nelamangala",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Lakshmeshwar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ramdurg",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nargund",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tarikere",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Malavalli",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Savanur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Lingsugur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vijayapura",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sankeshwara",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Madikeri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Talikota",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sedam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shikaripur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mahalingapura",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mudalagi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Muddebihal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pavagada",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Malur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sindhagi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sanduru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Afzalpur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Maddur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Madhugiri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tekkalakote",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Terdal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mudabidri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Magadi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Navalgund",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shiggaon",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shrirangapattana",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sindagi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sakaleshapura",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Srinivaspur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ron",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mundargi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sadalagi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Piriyapatna",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Adyar",
      category: "Tier 3",
      zone: "South",
    },
  ],
  Kerala: [
    {
      city: "Thiruvananthapuram",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Kochi",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Kozhikode",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Kollam",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Thrissur",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Malappuram",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Kannur",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Palakkad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Alappuzha",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ponnani",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vatakara",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kanhangad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Taliparamba",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Koyilandy",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Neyyattinkara",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kayamkulam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nedumangad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kottayam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kasaragod",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kunnamkulam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ottappalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thiruvalla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thodupuzha",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chalakudy",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Changanassery",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Punalur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nilambur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Cherthala",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Perinthalmanna",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mattannur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shoranur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Varkala",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Paravoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pathanamthitta",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Peringathur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Attingal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kodungallur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pappinisseri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Chittur-Thathamangalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Muvattupuzha",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Adoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mavelikkara",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mavoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Perumbavoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vaikom",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Panniyannur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Guruvayoor",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Puthuppally",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Panamattom",
      category: "Tier 3",
      zone: "South",
    },
  ],
  Ladakh: [

  ],
  Lakshadweep: [

  ],
  "Madhya Pradesh": [
    {
      city: "Indore",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Bhopal",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Jabalpur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Gwalior",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Ujjain",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Sagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ratlam",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Satna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Murwara (Katni)",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Morena",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Singrauli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rewa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Vidisha",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ganjbasoda",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shivpuri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandsaur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Neemuch",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nagda",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Itarsi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sarni",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sehore",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mhow Cantonment",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Seoni",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Balaghat",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ashok Nagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tikamgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahdol",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pithampur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Alirajpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandla",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sheopur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shajapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Panna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Raghogarh-Vijaypur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sendhwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sidhi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pipariya",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shujalpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sironj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pandhurna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nowgong",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandideep",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sihora",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Raisen",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lahar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Maihar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sanawad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sabalgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Umaria",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Porsa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Narsinghgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Malaj Khand",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sarangpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mundi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nepanagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pasan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mahidpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Seoni-Malwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rehli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Manawar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rahatgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Panagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Wara Seoni",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tarana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sausar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Niwari",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mauganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Manasa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nainpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Prithvipur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sohagpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nowrozabad (Khodargama)",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shamgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Maharajpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Multai",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pali",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pachore",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rau",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mhowgaon",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Vijaypur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Narsinghgarh",
      category: "Tier 3",
      zone: "North",
    },
  ],
  Maharashtra: [
    {
      city: "Mumbai",
      category: "Tier 1",
      zone: "West",
    },
    {
      city: "Pune",
      category: "Tier 1",
      zone: "West",
    },
    {
      city: "Nagpur",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Thane",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Nashik",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Vasai-Virar",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Solapur",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Bhiwandi",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Amravati",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Nanded-Waghala",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Sangli",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Malegaon",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Aurangabad",
      category: "Tier 2",
      zone: "West",
    },
    {
      city: "Kalyan-Dombivali",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mira-Bhayandar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Akola",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Latur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Dhule",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ahmednagar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ichalkaranji",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Parbhani",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Panvel",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Yavatmal",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Achalpur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Osmanabad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Nandurbar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Satara",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wardha",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Udgir",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Amalner",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Akot",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pandharpur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shrirampur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Parli",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Washim",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ambejogai",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Manmad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ratnagiri",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Uran Islampur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pusad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sangamner",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shirpur-Warwade",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Malkapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wani",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Lonavla",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Talegaon Dabhade",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Anjangaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Umred",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Palghar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shegaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ozar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Phaltan",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Yevla",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shahade",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vita",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Umarkhed",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Warora",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pachora",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Tumsar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Manjlegaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sillod",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Arvi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Nandura",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vaijapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wadgaon Road",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sailu",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Murtijapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Tasgaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mehkar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Yawal",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pulgaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Nilanga",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Wai",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Umarga",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Paithan",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Rahuri",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Nawapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Tuljapur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Morshi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Purna",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Satana",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pathri",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sinnar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Uchgaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Uran",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pen",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Karjat",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Manwath",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Partur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sangole",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mangrulpir",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Risod",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shirur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Savner",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sasvad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pandharkaoda",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Talode",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shrigonda",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shirdi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Raver",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mukhed",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Rajura",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Vadgaon Kasba",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Tirora",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mahad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Lonar",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Sawantwadi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pathardi",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Pauni",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Ramtek",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mul",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Soyagaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mangalvedhe",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Narkhed",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Shendurjana",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Patur",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Mhaswad",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Loha",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Nandgaon",
      category: "Tier 3",
      zone: "West",
    },
    {
      city: "Warud",
      category: "Tier 3",
      zone: "West",
    },
  ],
  Manipur: [
    {
      city: "Imphal",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Thoubal",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lilong",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mayang Imphal",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Meghalaya: [
    {
      city: "Shillong",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tura",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nongstoin",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Mizoram: [
    {
      city: "Aizawl",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Lunglei",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Saiha",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Nagaland: [
    {
      city: "Dimapur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kohima",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Zunheboto",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tuensang",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Wokha",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mokokchung",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Odisha: [
    {
      city: "Bhubaneswar",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Cuttack",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Raurkela",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Brahmapur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sambalpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Puri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Baleshwar Town",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Baripada Town",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bhadrak",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Balangir",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jharsuguda",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bargarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Paradip",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bhawanipatna",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dhenkanal",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Barbil",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kendujhar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sunabeda",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rayagada",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jatani",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Byasanagar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kendrapara",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rajagangapur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Parlakhemundi",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Talcher",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sundargarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Phulabani",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Pattamundai",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Titlagarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nabarangapur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Soro",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Malkangiri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rairangpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tarbha",
      category: "Tier 3",
      zone: "East",
    },
  ],
  Puducherry: [
    {
      city: "Pondicherry",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Karaikal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Yanam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mahe",
      category: "Tier 3",
      zone: "South",
    },
  ],
  Punjab: [
    {
      city: "Ludhiana",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Amritsar",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Jalandhar",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Patiala",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bathinda",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pathankot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Hoshiarpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Batala",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Moga",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Malerkotla",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Khanna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mohali",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Barnala",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Firozpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Phagwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kapurthala",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Zirakpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kot Kapura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Faridkot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Muktsar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajpura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sangrur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Fazilka",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Gurdaspur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kharar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Gobindgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mansa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Malout",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nabha",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tarn Taran",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Jagraon",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sunam",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Dhuri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Firozpur Cantt.",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sirhind Fatehgarh Sahib",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rupnagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Jalandhar Cantt.",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Samana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nawanshahr",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rampura Phul",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nangal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nakodar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Zira",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Patti",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Raikot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Longowal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Urmar Tanda",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Morinda, India",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Phillaur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pattran",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Qadian",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sujanpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mukerian",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Talwara",
      category: "Tier 3",
      zone: "North",
    },
  ],
  Rajasthan: [
    {
      city: "Jaipur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Jodhpur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Bikaner",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Ajmer",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Udaipur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bhilwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Alwar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bharatpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pali",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Barmer",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sikar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tonk",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sadulpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sawai Madhopur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nagaur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Makrana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sujangarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sardarshahar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ladnu",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ratangarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nokha",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nimbahera",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Suratgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajsamand",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lachhmangarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajgarh (Churu)",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nasirabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nohar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Phalodi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nathdwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pilani",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Merta City",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sojat",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Neem-Ka-Thana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sirohi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pratapgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rawatbhata",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sangaria",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lalsot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pilibanga",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pipar City",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Taranagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Vijainagar, Ajmer",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sumerpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sagwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ramganj Mandi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lakheri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Udaipurwati",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Losal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sri Madhopur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ramngarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rawatsar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajakhera",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahpura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahpura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Raisinghnagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Malpura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nadbai",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sanchore",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajgarh (Alwar)",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sheoganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sadri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Todaraisingh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Todabhim",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Reengus",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rajaldesar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sadulshahar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sambhar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Prantij",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mount Abu",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mangrol",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Phulera",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandawa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pindwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mandalgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Takhatgarh",
      category: "Tier 3",
      zone: "North",
    },
  ],
  // Rajestan: [
  //   {
  //     city: "Udaipur",
  //     category: "Tier 3",
  //     zone: "North",
  //   },
  // ],
  Sikkim: [

  ],
  "Tamil Nadu": [
    {
      city: "Chennai",
      category: "Tier 1",
      zone: "South",
    },
    {
      city: "Coimbatore",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Madurai",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Tiruchirappalli",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Salem",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Tiruppur",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Erode",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Tirunelveli",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Dindigul",
    },
    {
      city: "Cuddalore",
    },
    {
      city: "Ranipet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nagercoil",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thanjavur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vellore",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kancheepuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruvannamalai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pollachi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rajapalayam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sivakasi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pudukkottai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Neyveli (TS)",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nagapattinam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Viluppuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruchengode",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vaniyambadi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Theni Allinagaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Udhagamandalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Aruppukkottai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Paramakudi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Arakkonam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Virudhachalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Srivilliputhur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tindivanam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Virudhunagar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Karur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Valparai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sankarankovil",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tenkasi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palani",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pattukkottai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirupathur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ramanathapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Udumalaipettai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gobichettipalayam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thiruvarur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thiruvallur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Panruti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Namakkal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thirumangalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vikramasingapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nellikuppam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rasipuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruttani",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nandivaram-Guduvancheri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Periyakulam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pernampattu",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vellakoil",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sivaganga",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vadalur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Rameshwaram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruvethipuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Perambalur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Usilampatti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vedaranyam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sathyamangalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Puliyankudi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nanjikottai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thuraiyur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sirkali",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tiruchendur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Periyasemur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sattur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vandavasi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tharamangalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirukkoyilur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Oddanchatram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palladam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vadakkuvalliyur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirukalukundram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Uthamapalayam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Surandai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sankari",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Shenkottai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vadipatti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sholingur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tirupathur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Manachanallur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Viswanatham",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Polur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Panagudi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Uthiramerur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thiruthuraipoondi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pallapatti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ponneri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Lalgudi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Natham",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Unnamalaikadai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "P.N.Patti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tharangambadi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tittakudi",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pacode",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "O' Valley",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Suriyampalayam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sholavandan",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thammampatti",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Namagiripettai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Peravurani",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Parangipettai",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pudupattinam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Pallikonda",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sivagiri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Punjaipugalur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Padmanabhapuram",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Thirupuvanam",
      category: "Tier 3",
      zone: "South",
    },
  ],
  Telangana: [
    {
      city: "Hyderabad",
      category: "Tier 1",
      zone: "South",
    },
    {
      city: "Warangal",
      category: "Tier 2",
      zone: "South",
    },
    {
      city: "Nizamabad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Karimnagar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Ramagundam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Khammam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mahbubnagar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mancherial",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Adilabad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Suryapet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Jagtial",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Miryalaguda",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nirmal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kamareddy",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kothagudem",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bodhan",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Palwancha",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Mandamarri",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Koratla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sircilla",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Tandur",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Siddipet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Wanaparthy",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kagaznagar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Gadwal",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sangareddy",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bellampalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bhongir",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Vikarabad",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Jangaon",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bhadrachalam",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Bhainsa",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Farooqnagar",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Medak",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Narayanpet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Sadasivpet",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Yellandu",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Manuguru",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Kyathampalle",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Nagarkurnool",
      category: "Tier 3",
      zone: "South",
    },
    {
      city: "Secunderabad",
    },
  ],
  Tripura: [
    {
      city: "Agartala",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Dharmanagar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Pratapgarh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kailasahar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Belonia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Khowai",
      category: "Tier 3",
      zone: "East",
    },
  ],
  "Uttar Pradesh": [
    {
      city: "Lucknow",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Kanpur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Firozabad",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Agra",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Meerut",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Varanasi",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Allahabad",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Moradabad",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Aligarh",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Saharanpur",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Noida",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Jhansi",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Amroha",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Loni",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahjahanpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rampur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Modinagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Hapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Etawah",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sambhal",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Orai",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bahraich",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Unnao",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rae Bareli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lakhimpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sitapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lalitpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pilibhit",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Chandausi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Hardoi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Azamgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Khair",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sultanpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tanda",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nagina",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shamli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Najibabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shikohabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sikandrabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahabad, Hardoi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pilkhuwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Renukoot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Vrindavan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ujhani",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Laharpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tilhar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sahaswan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rath",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sherkot",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kalpi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tundla",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sandila",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nanpara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sardhana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nehtaur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Seohara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Padrauna",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mathura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Thakurdwara",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nawabganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Siana",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Noorpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sikandra Rao",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Puranpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rudauli",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Thana Bhawan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Palia Kalan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Zaidpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nautanwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Zamania",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shikarpur, Bulandshahr",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Naugawan Sadat",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Fatehpur Sikri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahabad, Rampur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Robertsganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Utraula",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sadabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rasra",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lal Gopalganj Nindaura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sirsaganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pihani",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shamsabad, Agra",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rudrapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Soron",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "SUrban Agglomerationr",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Samdhan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sahjanwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rampur Maniharan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sumerpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shahganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tulsipur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tirwaganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "PurqUrban Agglomerationzi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shamsabad, Farrukhabad",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Warhapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Powayan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sandi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Achhnera",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Naraura",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nakur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sahaspur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Safipur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Reoti",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sikanderpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Saidpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sirsi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Purwa",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Parasi",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Lalganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Phulpur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Shishgarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sahawar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Samthar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pukhrayan",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Obra",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Niwai",
      category: "Tier 3",
      zone: "North",
    },
  ],
  Uttarakhand: [
    {
      city: "Dehradun",
      category: "Tier 2",
      zone: "North",
    },
    {
      city: "Hardwar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Haldwani-cum-Kathgodam",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Srinagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Kashipur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Roorkee",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rudrapur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Rishikesh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Ramnagar",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pithoragarh",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Manglaur",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nainital",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Mussoorie",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Tehri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Pauri",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Nagla",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Sitarganj",
      category: "Tier 3",
      zone: "North",
    },
    {
      city: "Bageshwar",
      category: "Tier 3",
      zone: "North",
    },
  ],
  "West Bengal": [
    {
      city: "Kolkata",
      category: "Tier 1",
      zone: "East",
    },
    {
      city: "Siliguri",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Asansol",
      category: "Tier 2",
      zone: "East",
    },
    {
      city: "Raghunathganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kharagpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Naihati",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "English Bazar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Baharampur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Hugli-Chinsurah",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Raiganj",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jalpaiguri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Santipur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Balurghat",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Medinipur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Habra",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Ranaghat",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Bankura",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Nabadwip",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Darjiling",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Purulia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Arambagh",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tamluk",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "AlipurdUrban Agglomerationr",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Suri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Jhargram",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Gangarampur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Rampurhat",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Kalimpong",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sainthia",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Taki",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Murshidabad",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Memari",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Paschim Punropara",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Tarakeswar",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Sonamukhi",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "PandUrban Agglomeration",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mainaguri",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Malda",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Panchla",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Raghunathpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Mathabhanga",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Monoharpur",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Srirampore",
      category: "Tier 3",
      zone: "East",
    },
    {
      city: "Adra",
      category: "Tier 3",
      zone: "East",
    },
  ],
};
