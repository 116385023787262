import { gql } from "@apollo/client";

export const GET_DATA = gql`
  query GET_DATA {
    user(where: { role_id: { _eq: 5 }, is_active: { _eq: true } }) {
      f_name
      l_name
      id
    }
    member_type {
      id
      name
    }
    project_type {
      id
      name
    }
  }
`;

export const GET_POC = gql`
  query MyQuery($client_id: Int) {
    poc(where: { client_id: { _eq: $client_id } }) {
      id
      first_name
      last_name
    }
  }
`;

export const GET_MANAGER = gql`
  query GET_MANAGER {
    user(where: { role_id: { _eq: 3 } }, order_by: { id: asc }) {
      f_name
      l_name
      id
      meta_date
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation ($objects: [project_insert_input!]!) {
    insert_project(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const MANAGERxPROJECT = gql`
  mutation ($objects: [manager_x_project_insert_input!]!) {
    insert_manager_x_project(objects: $objects) {
      affected_rows
    }
  }
`;
export const PROJECTxPOC = gql`
  mutation ($objects: [project_x_poc_insert_input!]!) {
    insert_project_x_poc(objects: $objects) {
      affected_rows
    }
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($project_id: Int) {
    project(where: { id: { _eq: $project_id } }) {
      budget
      name
      sample
      client {
        f_name
        l_name
        id
      }
      status
      member_type {
        name
        id
      }
      currency
      start_date
      end_date
      estimated_ir
      id
      incentive
      member_types
      cost_per_interview
      survey_type
      client_project_id
      client_id
      created_at
      description
      documents
      project_type
      total_budget
      survey_does_not_carry_krea_landing_pages
      include_profiler_questions
      include_survey_quality_questionnaire
      third_party_survey_name
      third_party_survey_url
      is_online_or_assisted
      manager_x_projects {
        manager_on_leave
        user {
          f_name
          l_name
          id
          role {
            name
          }
        }
        status
      }
      projectTypeByProjectType {
        name
        id
      }
      project_x_pocs {
        poc_id
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UPDATE_PROJECT($id: Int!, $object: project_set_input) {
    update_project(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;

export const UPDATE_MANAGER = gql`
  mutation DELETE_MANAGER(
    $id: Int!
    $object: [manager_x_project_insert_input!]!
  ) {
    delete_manager_x_project(where: { project_id: { _eq: $id } }) {
      affected_rows
    }
    insert_manager_x_project(objects: $object) {
      affected_rows
    }
  }
`;

export const UPDATE_POC = gql`
  mutation UPDATE_POC($object: [project_x_poc_insert_input!]!, $id: Int) {
    delete_project_x_poc(where: { project_id: { _eq: $id } }) {
      affected_rows
    }
    insert_project_x_poc(objects: $object) {
      affected_rows
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    get_upload_url(content_type: $contentType, file_name: $fileName) {
      message
      url
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    get_view_url(file_name: $name) {
      message
      url
    }
  }
`;

export const GET_SURVEY_STATUS = gql`
  query GET_SURVEY_STATUS($projectID: Int) {
    survey(where: { project_id: { _eq: $projectID } }) {
      id
      status
    }
  }
`;

export const UPDATE_SURVEY_STATUS_IN_PROJECT = gql`
  mutation UPDATE_SURVEY($id: Int, $status: String) {
    update_survey(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const PROJECT_DATES = gql`
  query PROJECT_DATES($id: Int) {
    project(where: { id: { _eq: $id } }) {
      end_date
      start_date
    }
  }
`;

export const MANAGER_LIST = gql`
  query MANAGER_LIST($id: Int) {
    project(where: { id: { _eq: $id } }) {
      manager_x_projects {
        id
      }
    }
  }
`;
