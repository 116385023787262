import React, { useState, useEffect } from "react";
import { Form, Input, Select, DatePicker } from "antd";
import { useQuery } from "@apollo/client";
import { data } from "../../../pages/members/data";
import { GET_EMAIL_PHONE } from "../../../pages/survey/graphql";
import moment from "moment";

const PatientForm = (props) => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [email, setEmail] = useState([]);
  // eslint-disable-next-line
  const [phone, setPhone] = useState([]);

  let initialValues = {
    title: "",
    f_name: "",
    phone_number: "",
    email: "",
    gender: "",
    year_of_birth: "",
    state: "",
    city: "",
    pincode: "",
    ailments: [],
  };
  function disabledDate(current) {
    return current && current > moment().endOf("day");
  }

  useEffect(() => {
    if (data) {
      let state,
        statesCopy = [];
      for (state in data) {
        statesCopy.push(state);
      }
      setStates(statesCopy);
    }
    // eslint-disable-next-line
  }, [data]);
  // eslint-disable-next-line
  const { data: emailPhone, loading: emailPhoneLoading } = useQuery(
    GET_EMAIL_PHONE,
    {
      onCompleted: (res) => {
        let emailCopy = [],
          phoneCopy = [];
        res?.user.forEach((user) => {
          emailCopy.push(user.email);
          phoneCopy.push(user.phone_number);
        });
        setEmail(emailCopy);
        setPhone(phoneCopy);
      },
    }
  );

  const getCities = (stateInput) => {
    if (states.length > 0) {
      let citiesCopy = [],
        state;
      for (state in data) {
        if (state === stateInput) {
          citiesCopy = data[state];
          setCities(citiesCopy);
        }
      }
    }
  };
  let mobile = /^[0-9]{10}$/;
  let pincodetest = /^[0-9]{6}$/;
  return (
    <Form
      form={props.form}
      layout="vertical"
      requiredMark={false}
      initialValues={props.initialValues || initialValues}
      onFinish={props.onSubmit}
    >
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Input size="large" className="rounded" />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Name"
            name="f_name"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Input size="large" className="rounded" />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Phone"
            name="phone_number"
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "" && !mobile.test(value)) {
                    return Promise.reject("Please enter a valid phone number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size="large" className="rounded" />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Required" },
              {
                type: "email",
                message: "Please enter an valid email address",
              },
              () => ({
                validator(_, value) {
                  if (email.includes(value)) {
                    if (value && value !== props.email) {
                      return Promise.reject(
                        "Email ID already used. Please use a different one."
                      );
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size="large" className="rounded" />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-1">
          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Select placeholder="Select" size="large">
              <Select.Option value="M">Male</Select.Option>
              <Select.Option value="F">Female</Select.Option>
              <Select.Option value="O">Other</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-1">
          <Form.Item
            label="Year of Birth"
            name="year_of_birth"
            rules={[{ required: true, message: "Required!" }]}
          >
            <DatePicker
              picker="year"
              size="large"
              className="w-full"
              disabledDate={disabledDate}
            />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Select
              placeholder="Select"
              size="large"
              onChange={(e) => {
                getCities(e);
              }}
            >
              {Object.keys(data).map((state, i) => {
                return (
                  <Select.Option value={state} key={i}>
                    {state}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Select placeholder="Select" size="large" className="rounded">
              {cities.map((cityObj, index) => (
                <Select.Option key={index} value={cityObj.city}>
                  {cityObj.city}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Pincode"
            name="pincode"
            rules={[
              { required: true, message: "Required" },
              () => ({
                validator(_, value) {
                  if (!pincodetest.test(value)) {
                    return Promise.reject("Please enter a valid pincode");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input size="large" className="rounded" />
          </Form.Item>
        </div>
        <div className="col-span-4 lg:col-span-2">
          <Form.Item
            label="Ailment list"
            name="ailment_list"
            rules={[{ required: true, message: "Required!" }]}
          >
            <Select placeholder="Select" size="large" mode="multiple">
              {props.formData.ailments
                ? props.formData.ailments.map((ailment, i) => {
                  return (
                    <Select.Option key={i} value={ailment.ailment}>
                      {ailment.ailment}
                    </Select.Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default PatientForm;
