import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button, Table, Space, Input } from "antd";
import { useHistory, useParams } from "react-router";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { GET_MEMBERS_LIST } from "../graphql";
import ColumnEditor from "../../../components/ColumnEditor";
import MemberTable from "../../../components/memberTable";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData } from "../../../components/memberColumns";
import { RoleContext } from "../../../context/RoleContext";
import SpecialityModalPop from "../../../utils/specialitySearch";

function MemberListTab() {
  const { surveyID, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  let searchInput;
  const { role } = useContext(RoleContext);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const savedviewSurveyPrfList = localStorage.getItem('viewSurveyPrfList');
  const viewSurveyPrfListinitial = savedviewSurveyPrfList
    ? JSON.parse(savedviewSurveyPrfList)
    : {
      ...memberColumnStatus,
      quota: { enabled: true, label: "Quota" }
    }
  const [columnStatus, setStatus] = useState(viewSurveyPrfListinitial);

  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });
  useEffect(() => {
    localStorage.setItem('viewSurveyPrfList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.quota = item.survey_x_members[0].quota;
        item.action = item.id;

      });
      setTableData(object);
    }
  }, [members]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });


  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const columns = useMemo(() => {
    return [...memberColumns(columnStatus, handleIconClick), ...roleBasedColumns(role, columnStatus),
    ...[{
      title: "QUOTA",
      dataIndex: "quota",
      className: columnStatus?.quota?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("quota"),
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              history.push(
                `/viewSurvey/members/project/${projectID}/survey/${surveyID}/PRF/viewDetailedResponse/member/${record}`
              );
            }}
          >
            View response
          </Button>
        );
      },
    },]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus])


  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">

        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setStatus(column)
        }} />
      </div>
      <MemberTable
        columns={columns}
        dataSource={tableData}
      />
      <SpecialityModalPop
        titleSpec={`Selected ${selectedColumnNameSpec}`}
        visibleSpec={isModalVisibleSpec}
        onOkSpec={handleModalOkSpec}
        onCancelSpec={handleModalCloseSpec}
        searchQuerySpec={searchQuerySpec}
        handleSearchSpec={handleSearchSpec}
        handleCheckboxChangeSpec={handleCheckboxChangeSpec}
        filteredCheckboxDataSpec={filteredCheckboxDataSpec}
        selectedDataSpec={selectedDataSpec}
        initialSelectedDataSpec={initialSelectedDataSpec}
        isSubmitDisabledSpec={isSubmitDisabledSpec}
        handleResetSpec={handleResetSpec}
      />
    </div>
  );
}
export default MemberListTab;
