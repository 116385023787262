import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import styled from "styled-components";
import "../../src/index.css";
const RichTextEditor = styled.div`
  .rdw-editor-wrapper {
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    background: #fff;
  }
  .public-DraftEditorPlaceholder-root {
    margin-top: 8px;
  }
  .DraftEditor-root {
    margin: 6px;
  }
  .rdw-editor-toolbar {
    margin-left: 14px !important;
    margin-right: 14px !important;

    margin-top: 14px !important;
    border: 0 !important;
    padding: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #cecece !important;
    & .rdw-option-wrapper {
      margin: 0;
      height: 32px;
      padding: 10px 12px;
      box-shadow: none;
      &.rdw-option-disabled {
        border-color: #cbcbcb;
      }
    }
    .rdw-link-wrapper {
      margin: 0;
    }
  }
  .rdw-inline-wrapper {
    margin-bottom: 0;
  }
  .rdw-editor-main {
    border: 0 !important;
  }
`;
function RichTextbox(props) {
  const { setEditorState, editorState } = props;

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.charCount(
      editorState.getCurrentContent().getPlainText().length,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  useEffect(() => {
    props.charCount(
      editorState.getCurrentContent().getPlainText().length,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    // eslint-disable-next-line
  }, [props]);
  const toolbarConfig = {
    options: ["inline", "link"],
    inline: {
      inDropdown: false,
      className: "richText",
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic", "underline"],
    },

    link: {
      defaultTargetOption: "_blank",
      options: ["link", "unlink"],
      className: "richText",
    },
  };
  return (
    <RichTextEditor>
      <Editor
        placeholder={props.placeholder}
        defaultEditorState={editorState}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
        editorStyle={{
          minHeight: "100px",
          border: "1px solid #DCDCDC",
          borderTop: "0px",
          paddingLeft: "10px",
        }}
        toolbarStyle={{
          margin: "0px",
          border: "1px solid #DCDCDC",
        }}
        onFocus={props.onFocus}
        // handleBeforeInput={(val) => {
        //   const textLength = editorState
        //     .getCurrentContent()
        //     .getPlainText().length;
        //   if (val && textLength >= 500) {
        //     return "handled";
        //   }
        //   return "not-handled";
        // }}
        // handlePastedText={(val) => {
        //   const textLength = editorState
        //     .getCurrentContent()
        //     .getPlainText().length;
        //   return val.length + textLength >= 500;
        // }}
      />
    </RichTextEditor>
  );
}
export default RichTextbox;
