import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import jwtDecode from "jwt-decode";
import {
  Dropdown,
  Menu,
  Spin,
  Table,
  Tag,
  Checkbox,
  Row,
  Tabs,
  Col,
} from "antd";
import { MoreOutlined, FilterOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../../utils/table";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import ErrorPage from "../../components/errorPage";
import { MANGERLIST, GET_USER_DETAILS } from "./graphql";
import greenCircle from "../../assets/Icon/greenCircle.svg";
import exclamationCircle from "../../assets/Icon/exclamationCircle.svg";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../components/ColumnEditor";

const { TabPane } = Tabs;

function SuperadminList() {
  const history = useHistory();
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const [draft, setDraftProjects] = React.useState([]);
  const [completed, setCompletedProjects] = React.useState([]);
  const [liveProjects, setLiveProjects] = React.useState([]);
  const [activeList, setActiveList] = React.useState([]);
  // eslint-disable-next-line
  const [role, setRole] = useState(null);
  const [activeTabPane, setActiveTabPane] = useState("1");
  const tabPane = ["active", "drafts", "completed"];
  const [name, setName] = useState("");
  // eslint-disable-next-line
  const [id, setId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
    "x-hasura-user-id"
    ]
  );
  const query = gql`
    query MyQuery($id: Int) {
      user(where: { id: { _eq: $id } }) {
        l_name
        f_name
      }
    }
  `;

  const savedprojectList = localStorage.getItem('projectList');
  const projectListinitial = savedprojectList
    ? JSON.parse(savedprojectList)
    : {
      client: { enabled: true, label: "Client" },
      category: { enabled: true, label: "Category" },
      assigned: { enabled: true, label: "Assigned to" },
      sample: { enabled: true, label: "Sample" },
      start: { enabled: true, label: "Start date" },
      end: { enabled: true, label: "End date" },
      status: { enabled: true, label: "Status" },
      total_budget: { enabled: false, label: "Total budget" },
    }
  const [columnStatus, setStatus] = useState(projectListinitial);

  useEffect(() => {
    localStorage.setItem('projectList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const { data: user } = useQuery(query, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });

  const { data: userData } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (location.pathname === "/projectList/active") {
      setActiveTabPane("1");
    } else if (location.pathname === "/projectList/drafts") {
      setActiveTabPane("2");
    } else if (location.pathname === "/projectList/completed") {
      setActiveTabPane("3");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      setName(`${user?.user[0]?.f_name} ${user?.user[0]?.l_name}`);
    }
  }, [user]);

  useEffect(() => {
    // eslint-disable-next-line
    let result = liveProjects.filter((record) => {
      if (record.manager_x_projects.length !== 0) {
        return parseInt(id) === record.manager_x_projects[0].user.id;
      }
    });
    setActiveList(result);
    // eslint-disable-next-line
  }, [liveProjects]);

  const columns = () => [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
      fixed: "left",
    },
    {
      title: "CLIENT",
      key: "client.f_name",
      dataIndex: "clientName",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{`${text}`}</div>
      ),
      ...getColumnSearchProps("clientName", "Client Name"),
    },
    {
      title: "CATEGORY",
      key: "projectTypeByProjectType.name",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      dataIndex: "category",
      ...getColumnSearchProps("category", "Category"),
      render: (text, record, index) => (
        <>
          <span> {text}</span>
          <div>
            {record.member_types !== null && record.member_types[0] === 1 && (
              <span className="text-xs text-textLight font-normal">
                Physician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 2 && (
              <span className="text-xs text-textLight font-normal">
                Technician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 3 && (
              <span className="text-xs text-textLight font-normal">
                Patient
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 4 && (
              <span className="text-xs text-textLight font-normal">
                Consumer
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "ASSIGNED TO",
      key: "managerName",
      dataIndex: "managerName",
      className: columnStatus?.assigned?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          {record.manager_x_projects.map((manager, i) => (
            <div className="text-h8 text-textMedium">{`${text}`}</div>
          ))}
        </div>
      ),
      ...getColumnSearchProps("managerName", "Assigned Managers"),
    },
    {
      title: "SAMPLE",
      key: "sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (text, record, index) => {
        let avgPerDay = Math.ceil(
          record.sample /
          moment(record?.end_date).diff(moment(record?.start_date), "days")
        );
        let days = moment().diff(moment(record?.start_date), "days");
        return (
          <div className="text-textMedium text-h8 w-20">
            <span className="float-left">
              {record?.surveys?.reduce(
                (arr, cur) =>
                  arr +
                  (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                0
              )}{" "}
              / {record.sample}
            </span>
            {location.pathname.split("/")[2] === "active" && (
              <>
                <span className="float-right">
                  {activeTab === "1" &&
                    days >= 1 &&
                    record?.surveys?.reduce(
                      (arr, cur) =>
                        arr +
                        (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                      0
                    ) >= avgPerDay ? (
                    <img src={greenCircle} className="ml-2" alt="greencircle" />
                  ) : // <ArrowUpOutlined style={{ color: "green" }} className="ml-2" />
                    null}
                </span>
                <span className="float-right">
                  {activeTab === "1" &&
                    days >= 1 &&
                    record?.surveys?.reduce(
                      (arr, cur) =>
                        arr +
                        (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                      0
                    ) < avgPerDay ? (
                    <img
                      src={exclamationCircle}
                      className="ml-2"
                      alt="exclamation"
                    />
                  ) : // <ArrowDownOutlined style={{ color: "red" }} className="ml-2" />
                    null}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "START DATE",
      key: "start_date",
      className: columnStatus?.start?.enabled ? "table-cell" : "hidden",
      dataIndex: "start_date",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      className: columnStatus?.end?.enabled ? "table-cell" : "hidden",
      dataIndex: "end_date",
      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      key: "state",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      dataIndex: "state",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.state.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.state === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.state === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.state === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "TOTAL BUDGET",
      key: "total_budget",
      dataIndex: "total_budget",
      className: columnStatus?.total_budget?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("total_budget", "Total budget"),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  let val =
                    record?.survey_type !== null && record?.survey_type !== ""
                      ? "- " + record?.survey_type
                      : "";
                  let category =
                    record?.projectTypeByProjectType?.name + " " + val;
                  const surveyType = category.replace(/ /g, "");

                  record?.surveys.length !== 0
                    ? history.push(
                      `/viewSurvey/projectDetails/project/${record?.id}/survey/${record?.surveys[0]?.id}/${surveyType}`
                    )
                    : history.push(
                      `/viewSurvey/projectDetails/project/${record?.id}/${surveyType}`
                    );
                }}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];

  const { data, loading, error } = useQuery(MANGERLIST, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      data &&
      (userData?.user[0]?.meta_date?.availability === "Available" ||
        userData?.user[0]?.meta_date === null)
    ) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.live_projects));
      object.forEach((item) => {
        if (
          item?.surveys?.length === 0 ||
          item?.surveys[0]?.status === "active"
        ) {
          item.state = "active";
          let val =
            item?.survey_type !== null && item?.survey_type !== ""
              ? item?.survey_type === "Third_Party_survey"
                ? "- " + item?.third_party_survey_name
                : "- " + item?.survey_type
              : "";
          item.category = item?.projectTypeByProjectType?.name + " " + val;
          item.clientName =
            item?.client?.l_name !== null
              ? item?.client?.f_name + " " + item?.client?.l_name
              : item?.client?.f_name;
          item.managerName =
            item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
          copy.push(item);
        }
      });
      setLiveProjects(copy);
    } else if (data) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.leave_projects));
      object.forEach((item) => {
        if (
          item?.manager_x_projects_aggregate?.aggregate?.count === 1 &&
          (item?.surveys?.length === 0 || item?.surveys[0]?.status === "active")
        ) {
          item.state = "active";
          let val =
            item?.survey_type !== null && item?.survey_type !== ""
              ? item?.survey_type === "Third_Party_survey"
                ? "- " + item?.third_party_survey_name
                : "- " + item?.survey_type
              : "";
          item.category = item?.projectTypeByProjectType?.name + " " + val;
          item.clientName =
            item?.client?.l_name !== null
              ? item?.client?.f_name + " " + item?.client?.l_name
              : item?.client?.f_name;
          item.managerName =
            item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
          copy.push(item);
        }
      });
      setLiveProjects(copy);
    }
  }, [data, userData]);

  useEffect(() => {
    if (
      data &&
      (userData?.user[0]?.meta_date?.availability === "Available" ||
        userData?.user[0]?.meta_date === null)
    ) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.draft_projects));
      object.forEach((project) => {
        if (
          project?.surveys?.length > 0 &&
          project?.surveys[0]?.status === "draft" &&
          project?.manager_x_projects[0]?.priority === 1 &&
          project?.manager_x_projects[0]?.user.id === parseInt(id)
        ) {
          project.state = "draft";
          let val =
            project?.survey_type !== null && project?.survey_type !== ""
              ? project?.survey_type === "Third_Party_survey"
                ? "- " + project?.third_party_survey_name
                : "- " + project?.survey_type
              : "";
          project.category =
            project?.projectTypeByProjectType?.name + " " + val;
          project.clientName =
            project?.client?.l_name !== null
              ? project?.client?.f_name + " " + project?.client?.l_name
              : project?.client?.f_name;
          project.managerName =
            project?.manager_x_projects[0]?.user?.l_name !== null
              ? project?.manager_x_projects[0]?.user?.f_name +
              " " +
              project?.manager_x_projects[0]?.user?.l_name
              : project?.manager_x_projects[0]?.user?.f_name;
          copy.push(project);
        }
      });
      setDraftProjects(copy);
    } else if (data) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.draft_projects));
      object.forEach((project) => {
        if (
          project?.surveys?.length > 0 &&
          project?.surveys[0]?.status === "draft" &&
          project?.leaveManager[0]?.priority === 1 &&
          project?.leaveManager[0]?.user.id === parseInt(id)
        ) {
          project.state = "draft";
          let val =
            project?.survey_type !== null && project?.survey_type !== ""
              ? project?.survey_type === "Third_Party_survey"
                ? "- " + project?.third_party_survey_name
                : "- " + project?.survey_type
              : "";
          project.category =
            project?.projectTypeByProjectType?.name + " " + val;
          project.clientName =
            project?.client?.l_name !== null
              ? project?.client?.f_name + " " + project?.client?.l_name
              : project?.client?.f_name;
          project.managerName =
            project?.leaveManager[0]?.user?.l_name !== null
              ? project?.leaveManager[0]?.user?.f_name +
              " " +
              project?.leaveManager[0]?.user?.l_name
              : project?.leaveManager[0]?.user?.f_name;
          copy.push(project);
        }
      });
      setDraftProjects(copy);
    }
  }, [data, userData]);

  useEffect(() => {
    if (data) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.completed_projects));
      object.forEach((project) => {
        if (
          project?.surveys?.length > 0 &&
          project?.surveys[0]?.status === "completed" &&
          project?.manager_x_projects[0]?.priority === 1 &&
          project?.manager_x_projects[0]?.user.id === parseInt(id)
        ) {
          project.state = "completed";
          let val =
            project?.survey_type !== null && project?.survey_type !== ""
              ? project?.survey_type === "Third_Party_survey"
                ? "- " + project?.third_party_survey_name
                : "- " + project?.survey_type
              : "";
          project.category =
            project?.projectTypeByProjectType?.name + " " + val;
          project.clientName =
            project?.client?.l_name !== null
              ? project?.client?.f_name + " " + project?.client?.l_name
              : project?.client?.f_name;
          project.managerName =
            project?.manager_x_projects[0]?.user?.l_name !== null
              ? project?.manager_x_projects[0]?.user?.f_name +
              " " +
              project?.manager_x_projects[0]?.user?.l_name
              : project?.manager_x_projects[0]?.user?.f_name;
          copy.push(project);
        }
      });
      setCompletedProjects(copy);
    } else if (data) {
      let copy = [];
      let object = JSON.parse(JSON.stringify(data.completed_projects));
      object.forEach((project) => {
        if (
          project?.surveys?.length > 0 &&
          project?.surveys[0]?.status === "completed" &&
          project?.leaveManager[0]?.priority === 1 &&
          project?.leaveManager[0]?.user.id === parseInt(id)
        ) {
          project.state = "completed";
          let val =
            project?.survey_type !== null && project?.survey_type !== ""
              ? project?.survey_type === "Third_Party_survey"
                ? "- " + project?.third_party_survey_name
                : "- " + project?.survey_type
              : "";
          project.category =
            project?.projectTypeByProjectType?.name + " " + val;
          project.clientName =
            project?.client?.l_name !== null
              ? project?.client?.f_name + " " + project?.client?.l_name
              : project?.client?.f_name;
          project.managerName =
            project?.leaveManager[0]?.user?.l_name !== null
              ? project?.leaveManager[0]?.user?.f_name +
              " " +
              project?.leaveManager[0]?.user?.l_name
              : project?.leaveManager[0]?.user?.f_name;
          copy.push(project);
        }
      });
      setCompletedProjects(copy);
    }
  }, [data]);

  if (error) {
    return <ErrorPage />;
  }
  const onTabChange = (e) => {
    history.push(`/projectList/${tabPane[e - 1]}`);
    setActiveTabPane(e);
  };
  return (
    <Spin spinning={loading}>
      <div className="projectList p-6">
        <div className="my-4 float-right text-h5 text-textMedium font-semibold">
          <span>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>
        </div>
        <Tabs
          className="w-full bg-white"
          activeKey={activeTabPane}
          tabBarGutter="5"
          onChange={onTabChange}
        >
          <TabPane tab="Active" key="1">
            <Table
              columns={columns()}
              dataSource={activeList}
              className="shadow rounded"
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
          <TabPane tab="Drafts" key="2">
            <Table
              columns={columns()}
              dataSource={draft}
              className="shadow rounded"
              // rowClassName={(record, index) =>
              //   record.manager_x_projects.length !== 0 &&
              //   `${record.manager_x_projects[0].user.f_name} ${record.manager_x_projects[0].user.l_name}` ===
              //     name
              //     ? "table-row"
              //     : "hidden"
              // }
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
          <TabPane tab="Completed" key="3">
            <Table
              columns={columns()}
              dataSource={completed}
              className="shadow rounded"
              // rowClassName={(record, index) =>
              //   `${record.manager_x_projects[0].user.f_name} ${record.manager_x_projects[0].user.l_name}` ===
              //   name
              //     ? "table-row"
              //     : "hidden"
              // }
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default SuperadminList;
