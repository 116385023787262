import { numericalValidation } from "./NumericalValidation";
import { percentageValidation } from "./PercentageValidation";
import { dateTimeValidation } from "./DateTimeValidation";
import { emailValidation } from "./EmailValidation";
import { dropDownScreenValidation } from "./DropDownValidation";
import { multipleChoiceValidation } from "./multipleChoiceValidation";
import { textBoxValidation } from "./TextboxValidation";

const mandatoryCheck = (surveyDetails) => {
  let status = true;
  let message = "";

  if (
    surveyDetails.current_question.question_type.name === "Star rating" &&
    surveyDetails.current_answer.length !== 0 &&
    !surveyDetails.ratingValue.includes(0)
  ) {
    if (
      surveyDetails?.current_question?.validation?.addFileOption &&
      surveyDetails.file_url.length === 0
    ) {
      status = false;
      message = "Please upload a file to continue";
    }
  } else if (
    surveyDetails.current_question.question_type.name !== "Email" &&
    surveyDetails.current_question.question_type.name !== "Star rating" &&
    surveyDetails.current_answer.length !== 0 &&
    surveyDetails.current_answer.length ===
      surveyDetails.current_question.questions_options.length &&
    surveyDetails.current_question.questions_options.every((option, index) => {
      return ![null, "", undefined].includes(
        surveyDetails.current_answer[index][option.label]
      );
    })
  ) {
    if (
      surveyDetails?.current_question?.validation?.addFileOption &&
      surveyDetails.file_url.length === 0
    ) {
      status = false;
      message = "Please upload a file to continue";
    }
  } else if (surveyDetails.current_question.question_type.name === "Email") {
    if (
      surveyDetails.current_answer.length !== 0 &&
      surveyDetails.current_question.questions_options.every(
        (option, index) =>
          ![null, "", undefined].includes(
            surveyDetails.current_answer[index]["email"]
          )
      )
    ) {
      if (
        surveyDetails?.current_question?.validation?.addFileOption &&
        surveyDetails.file_url.length === 0
      ) {
        status = false;
        message = "Please upload a file to continue";
      }
    } else {
      status = false;
      message = "This question is mandatory";
    }
    return {
      status,
      message,
    };
  } else {
    status = false;
    message = "This question is mandatory";
  }
  return {
    status,
    message,
  };
};

const getSuccessObject = (
  surveyDetails,
  screened = false,
  jumpToQuestion,
  jumpToStatus
) => ({
  status: true,
  screened: screened,
  jumpToQuestion: jumpToQuestion,
  jumpToStatus: jumpToStatus,
  object: {
    answer: surveyDetails.answer || "",
    fileUrl:
      surveyDetails.file_url.length !== 0
        ? surveyDetails.file_url.join(",")
        : "",
    moreInfo: surveyDetails.current_answer,
    documents: surveyDetails.documents,
  },
  message: "",
});

const getErrorObject = (error_message) => ({
  status: false,
  screened: false,
  object: {},
  message: error_message,
});

const validateAnswer = async (surveyDetails) => {
  switch (surveyDetails.current_answer_type) {
    case "Multiple Choice":
      if (surveyDetails.current_question.is_mandatory) {
        if (surveyDetails.optionCount > 0) {
          if (surveyDetails.current_question.validation) {
            if (
              surveyDetails.current_question.validation.optionsCount !== null &&
              surveyDetails.optionCount ===
                parseInt(surveyDetails.current_question.validation.optionsCount)
            ) {
              if (
                surveyDetails?.current_question?.validation?.addFileOption &&
                surveyDetails.file_url.length === 0
              ) {
                return getErrorObject(`Please upload a file to continue`);
              }
              let validate = await multipleChoiceValidation(surveyDetails);
              if (validate.status) {
                return getSuccessObject(
                  surveyDetails,
                  validate.screened,
                  validate.jumpToquestion,
                  validate.jumpToStatus
                );
              }
            } else {
              return getErrorObject(
                `Please select ${surveyDetails.current_question.validation.optionsCount} option(s)`
              );
            }
          } else {
            let validate = await multipleChoiceValidation(surveyDetails);
            if (validate.status) {
              return getSuccessObject(
                surveyDetails,
                validate.screened,
                validate.jumpToquestion,
                validate.jumpToStatus
              );
            } else {
              return getSuccessObject(surveyDetails);
            }
          }
        } else {
          return getErrorObject("This question is mandatory");
        }
      } else {
        return getSuccessObject(surveyDetails);
      }
      break;
    case "Text box - numerical":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          let validate = numericalValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        if (
          surveyDetails.current_answer.length !== 0 &&
          surveyDetails.current_question.questions_options.every(
            (option, index) =>
              ![null, "", undefined].includes(
                surveyDetails.current_answer[index][option.label]
              )
          )
        ) {
          let validate = numericalValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getSuccessObject(surveyDetails);
        }
        // return getSuccessObject(surveyDetails);
      }
    case "Percentage":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);

        if (mandatoryValidation.status) {
          // if (surveyDetails.current_question.validation) {
          let validate = percentageValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        if (
          surveyDetails.current_answer.length !== 0 &&
          surveyDetails.current_question.questions_options.every(
            (option, index) =>
              ![null, "", undefined].includes(
                surveyDetails.current_answer[index][option.label]
              )
          )
        ) {
          let validate = percentageValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getSuccessObject(surveyDetails);
        }
        // return getSuccessObject(surveyDetails);
      }
    case "Star rating":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          if (
            surveyDetails?.current_question?.validation?.addFileOption &&
            surveyDetails.file_url.length === 0
          ) {
            return getErrorObject(`Please upload a file to continue`);
          }
          return getSuccessObject(surveyDetails);
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        return getSuccessObject(surveyDetails);
      }
    case "Date & Time":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          let validate = dateTimeValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        if (
          surveyDetails.current_answer.length !== 0 &&
          surveyDetails.current_question.questions_options.every(
            (option, index) =>
              ![null, "", undefined].includes(
                surveyDetails.current_answer[index][option.label]
              )
          )
        ) {
          let validate = dateTimeValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getSuccessObject(surveyDetails);
        }
      }
    case "File upload":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          return getSuccessObject(surveyDetails);
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        return getSuccessObject(surveyDetails);
      }
    case "Text area":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          let validate = textBoxValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(surveyDetails);
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        return getSuccessObject(surveyDetails);
      }
    case "Text box - text":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          let validate = textBoxValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(surveyDetails);
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        return getSuccessObject(surveyDetails);
      }
    case "Ranking":
      if (
        surveyDetails?.current_question?.validation?.addFileOption &&
        surveyDetails.file_url.length === 0
      ) {
        return getErrorObject(`Please upload a file to continue`);
      } else {
        return {
          status: true,
          object: {
            answer: Object.keys(surveyDetails.current_answer).join(","),
            fileUrl:
              surveyDetails.file_url.length !== 0
                ? surveyDetails.file_url.join(",")
                : "",
            moreInfo: surveyDetails.current_question.questions_options.map(
              (option) => ({
                type: surveyDetails.current_answer_type,
              })
            ),
          },
          message: "",
        };
      }
    case "Dropdown":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          let validate = dropDownScreenValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }

            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          }
          return getSuccessObject(surveyDetails, validate.screened);
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        if (
          surveyDetails.current_answer.length !== 0 &&
          surveyDetails.current_question.questions_options.every(
            (option, index) =>
              ![null, "", undefined].includes(
                surveyDetails.current_answer[index][option.label]
              )
          )
        ) {
          let validate = dropDownScreenValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }

            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          }
          return getSuccessObject(surveyDetails, validate.screened);
        } else {
          return getSuccessObject(surveyDetails);
        }
      }
    case "Email":
      if (surveyDetails.current_question.is_mandatory) {
        let mandatoryValidation = mandatoryCheck(surveyDetails);
        if (mandatoryValidation.status) {
          if (surveyDetails.current_question) {
            let validate = emailValidation(surveyDetails);
            if (validate.status) {
              if (
                surveyDetails?.current_question?.validation?.addFileOption &&
                surveyDetails.file_url.length === 0
              ) {
                return getErrorObject(`Please upload a file to continue`);
              }
              return getSuccessObject(surveyDetails);
            } else {
              return getErrorObject(validate.message);
            }
          } else {
            return getSuccessObject(surveyDetails);
          }
        } else {
          return getErrorObject(
            mandatoryValidation.message || "All the fields are mandatory"
          );
        }
      } else {
        if (
          surveyDetails.current_answer.length !== 0 &&
          surveyDetails.current_question.questions_options.every(
            (option, index) =>
              ![null, "", undefined].includes(
                surveyDetails.current_answer[index][option.label]
              )
          )
        ) {
          let validate = emailValidation(surveyDetails);
          if (validate.status) {
            if (
              surveyDetails?.current_question?.validation?.addFileOption &&
              surveyDetails.file_url.length === 0
            ) {
              return getErrorObject(`Please upload a file to continue`);
            }
            return getSuccessObject(
              surveyDetails,
              validate.screened,
              validate.jumpToquestion,
              validate.jumpToStatus
            );
          } else {
            return getErrorObject(validate.message);
          }
        } else {
          return getSuccessObject(surveyDetails);
        }
        // return getSuccessObject(surveyDetails);
      }
    case "Slider":
      if (
        surveyDetails?.current_question?.validation?.addFileOption &&
        surveyDetails.file_url.length === 0
      ) {
        return getErrorObject(`Please upload a file to continue`);
      } else {
        return {
          status: true,
          object: {
            answer: Object.keys(surveyDetails.current_answer).join(","),
            fileUrl:
              surveyDetails.file_url.length !== 0
                ? surveyDetails.file_url.join(",")
                : "",
            moreInfo: surveyDetails.current_answer,
          },
          message: "",
        };
      }
    default:
      return {
        status: false,
        object: {},
        message: "Default Error message",
      };
  }
};

export { validateAnswer };
