import React, { useState } from "react";
import { Rate } from "antd";
import { useQuery } from "@apollo/client";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const StarRating = (props) => {
  const emptyArray = new Array(props.questions_options.length).fill(0);
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [data, setData] = useState([]);
  const [label, setLabel] = useState([]);
  const [labelValue, setLabelValue] = useState(emptyArray);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let newarray = [...label];
    // eslint-disable-next-line
    data.map((option, index) => {
      let copy = [];
      if (
        option.options.rating_labels !== undefined &&
        option.options.rating_labels !== null
      ) {
        // eslint-disable-next-line
        for (let [key, value] of Object.entries(option.options.rating_labels)) {
          copy.push(value);
        }
      }
      newarray[index] = copy;
    });
    setLabel(newarray);
    // eslint-disable-next-line
  }, [data]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy.push(value);
        }
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
    }));
    setLabelValue(copy);
    // eslint-disable-next-line
  }, [answers]);

  const handleOnChange = (value, label, i) => {
    let copyLabel = [...labelValue];
    let current_answer_copy = [...surveyDetails.current_answer];
    let copy = {};
    copyLabel[i] = value;
    setLabelValue(copyLabel);
    copy[label] = value;
    copy["type"] = props.question.question_type.name;
    current_answer_copy[i] = copy;

    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer_copy,
      ratingValue: copyLabel,
    }));
  };
  return (
    <div className="mt-6">
      {data.map((option, i) => (
        <div
          className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4"
          key={i}
        >
          {option.label && (
            <span className="text-h8 text-textMedium mr-8 font-semibold mt-6">
              {option.label}
            </span>
          )}

          <Rate
            style={{ fontSize: 36 }}
            tooltips={label[i]}
            onChange={(value) => handleOnChange(value, option.label, i)}
            value={labelValue[i] !== undefined ? labelValue[i] : 0}
          />
          {label[i] !== undefined && label[i].length !== 0 ? (
            <span className="ant-rate-text mt-4">
              {label[i][labelValue[i] - 1]}
            </span>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
};

export default StarRating;
