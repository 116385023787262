import { atom, useRecoilState } from "recoil";

export const projectForm = atom({
  key: "formValue",
  default: {},
});

export const useResetMemberDetails = () => {
  // eslint-disable-next-line
  const [memberListDetails, setMemberListDetails] = useRecoilState(projectForm);
  return () => {
    setMemberListDetails({});
  };
};

export const client = atom({
  key: "clientId",
  default: null,
});

export const user = atom({
  key: "userId",
  default: null,
});
export const surveyState = atom({
  key: "surveyState",
  default: {
    member_type: "",
    questions: [],
    question_index: 0,
    current_question: {},
    patient_id: "",
    survey_status: "",
    quota: "",
    previous_index: [],
    numerical_answer: [],
    completed: "",
    surveyStart: false,
    current_answer: [],
    current_answer_type: null,
    answer: "",
    file_url: [],
    survey_completed: false,
    survey_finished: false,
    quotacount: 0,
    sampleSize: 0,
    responseAnswers: {},
    documents: [],
    ratingValue: [],
    optionCount: 0,
    quotachoice: [],
    exisitingAnswer: [],
    include_profiler_questions: true,
    surveyType: "",
  },
});
