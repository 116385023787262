import { data } from '../pages/members/data';

const states = Object.keys(data);
export const checkBoxValues = ["yes", "no"];
export const genderValues = ["male", "female", "Male", "Female"];
export const statusValues = [
  "Valid",
  "Raw",
  "Partial Email",
  "Partial Phone",
  "Not Validated",
  "Invalid",
  "DNC",
  "Prohibited"
];

export const physician = {
  headers: [
    {
      title: "respondent id",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "title",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "first name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "last name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },

    {
      title: "gender",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: genderValues,
    },
    {
      title: "year of birth",
      type: "string",
      isRequired: false,
      isUnique: false,
    },

    {
      title: "primary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "secondary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mobile number 1",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 1 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "mobile number 2",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 2 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "phone",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "state",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: states,
    },
    {
      title: "state details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city",
      type: "city",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "city details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city category",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Tier 1", "Tier 2", "Tier 3"],
    },
    {
      title: "zone",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["North", "West", "South", "East"],
    },
    {
      title: "pincode",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "primary speciality",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "primary details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "super speciality",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      specialityObj: {}
    },
    {
      title: "super speciality details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "secondary speciality",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "secondary details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "name of the hospital",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "hospital type",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Government", "Private", "Non-profit", "Others"],
    },
    {
      title: "no of beds",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "NA",
        "1 to 10",
        "11 to 25",
        "26 to 50",
        "51 to 75",
        "76 to 100",
        "101 to 150",
        "151 to 200",
        "200+",
      ],
    },
    {
      title: "consulting hospital 1",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "consulting hospital 2",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "project compatibility",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "practice start year post residency",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "total incentive processed",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "previously participated studies",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "mode of payment",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "cheque",
        "cash",
        "wire",
        "voucher",
        "UPI",
        "charity",
        "others",
      ],
    },
    {
      title: "payment details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "validated date",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "status",
      type: "dropdown",
      // isRequired: true,
      isUnique: false,
      allowedValues: statusValues,
    },
    {
      title: "access to mobile app",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
  ],
  data: [
    [
      "1019389998",
      "Title-x",
      "Pria",
      "Patel",
      "female",
      "1990",
      "test123@gmmmmaaaail.com",
      "test999@gmmmmaaaaild.com",
      "1000000000",
      "yes",
      "2000000000",
      "no",
      "3000000000",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "400056",
      "Others",
      "xxx",
      "Others",
      "yyy",
      "Cardiology",
      "",
      "SK Hospital",
      "Government",
      "1 to 10",
      "CS hospital",
      "XUC hosptial",
      "CAWI,CATI",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Raw",
      "yes",
    ],
    [
      "1097751111",
      "Title-y",
      "Raj",
      "Dan",
      "male",
      "1990",
      "wwww@mailinator.com",
      "www@mailinator.com",
      "1110703000",
      "yes",
      "1111043000",
      "no",
      "1111678000",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "400056",
      "Allergy & Immunology",
      "",
      "General Allergy & Immunology",
      "",
      "Others",
      "zzz",
      "SK Hospital",
      "Private",
      "11 to 25",
      "CS hospital",
      "AS hospital",
      "CAWI",
      "2010",
      "09",
      "study x",
      "voucher",
      "payment pending ",
      "09-11-2023",
      "Partial Email",
      "yes",
    ],
  ],
};

export const technician = {
  headers: [
    {
      title: "respondent id",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "title",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "first name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "last name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "gender",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: genderValues,
    },
    {
      title: "year of birth",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "primary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "secondary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mobile number 1",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 1 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "mobile number 2",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 2 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "phone",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "state",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: states,
    },
    {
      title: "state details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city",
      type: "city",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "city details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city category",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Tier 1", "Tier 2", "Tier 3"],
    },
    {
      title: "zone",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["North", "West", "South", "East"],
    },
    {
      title: "pincode",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "years of experience",
      type: "number",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "profession",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "profession details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "sub-category1",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "sub-category1 details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "sub-category2",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "sub-category2 details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "name of the organization",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "hospital type",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Government", "Private", "Non-profit", "Others"],
    },
    {
      title: "no of beds",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "NA",
        "1 to 10",
        "11 to 25",
        "26 to 50",
        "51 to 75",
        "76 to 100",
        "101 to 150",
        "151 to 200",
        "200+",
      ],
    },
    {
      title: "Which Year did you start practicing (post Residency)",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "total incentive processed",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "previously participated studies",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "mode of payment",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "cheque",
        "cash",
        "wire",
        "voucher",
        "UPI",
        "charity",
        "others",
      ],
    },
    {
      title: "payment details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "validated date",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "status",
      type: "dropdown",
      // isRequired: true,
      isUnique: false,
      allowedValues: statusValues,
    },
    {
      title: "access to mobile app",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
  ],
  data: [
    [
      "1231940000",
      "Title-x",
      "Jim",
      "Jammy",
      "male",
      "1990",
      "jim233@mailinator.com",
      "jay19933@mailinator.com",
      "6773333876",
      "yes",
      "6773333872",
      "yes",
      "5673333654",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "600002",
      "20",
      "Nurse",
      "others",
      "General",
      "others",
      "others",
      "others",
      "xyz hospitals",
      "Government",
      "1 to 10",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Raw",
      "yes",
    ],
    [
      "1231540787",
      "Title-y",
      "Jey",
      "Lao",
      "female",
      "1991",
      "jim10147@mailinator.com",
      "jay20258@mailinator.com",
      "6333909472",
      "yes",
      "6888909834",
      "yes",
      "3688843234",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "600002",
      "12",
      "Profes",
      "others",
      "consultant",
      "others",
      "consultant2",
      "others",
      "ABC hospitals",
      "Private",
      "101 to 150",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Not Validated",
      "no",
    ],
  ],
};

export const patient = {
  headers: [
    {
      title: "respondent id",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "title",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "first name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "last name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "gender",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: genderValues,
    },
    {
      title: "year of birth",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "primary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "secondary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mobile number 1",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 1 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "mobile number 2",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 2 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "phone",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "state",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: states,
    },
    {
      title: "state details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city",
      type: "city",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "city details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city category",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Tier 1", "Tier 2", "Tier 3"],
    },
    {
      title: "zone",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["North", "West", "South", "East"],
    },
    {
      title: "pincode",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "name of the hospital",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "hospital type",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: ["Government", "Private", "Non-profit", "Others"],
    },
    {
      title: "no of beds",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "NA",
        "1 to 10",
        "11 to 25",
        "26 to 50",
        "51 to 75",
        "76 to 100",
        "101 to 150",
        "151 to 200",
        "200+",
      ],
    },
    {
      title: "ailment list",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "Which Year did you start practicing (post Residency)",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "total incentive processed",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "previously participated studies",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "mode of payment",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "cheque",
        "cash",
        "wire",
        "voucher",
        "UPI",
        "charity",
        "others",
      ],
    },
    {
      title: "payment details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "validated date",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "status",
      type: "dropdown",
      // isRequired: true,
      isUnique: false,
      allowedValues: statusValues,
    },
    {
      title: "access to mobile app",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
  ],
  data: [
    [
      "4565418800",
      "Title-x",
      "Pam",
      "Ravi",
      "male",
      "1998",
      "pam1014788@mailinator.com",
      "pam202888@mailinator.com",
      "9876780088",
      "yes",
      "9870000388",
      "no",
      "6560000388",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "625001",
      "AXU",
      "Government",
      "101 to 150",
      "Acne",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Valid",
      "yes",
    ],
    [
      "4565416639",
      "Title-y",
      "Sam",
      "Meen",
      "female",
      "1990",
      "sam196653@mailinator.com",
      "sam236689@mailinator.com",
      "9876700077",
      "yes",
      "9822086309",
      "no",
      "2223345308",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "626123",
      "PLA",
      "Private",
      "151 to 200",
      "Acne",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Valid",
      "yes",
    ],
  ],
};

export const consumer = {
  headers: [
    {
      title: "respondent id",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "title",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "first name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "last name",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "gender",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: genderValues,
    },
    {
      title: "year of birth",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "primary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "secondary email",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mobile number 1",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 1 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "mobile number 2",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "mark mobile 2 as whatsapp",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
    {
      title: "phone",
      type: "string",
      isRequired: false,
      isUnique: true,
    },
    {
      title: "state",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: states,
    },
    {
      title: "state details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city",
      type: "city",
      isRequired: false,
      isUnique: false,
      allowedValues: [],
    },
    {
      title: "city details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "city category",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "zone",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "pincode",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "no of beds",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "NA",
        "1 to 10",
        "11 to 25",
        "26 to 50",
        "51 to 75",
        "76 to 100",
        "101 to 150",
        "151 to 200",
        "200+",
      ],
    },
    {
      title: "Which Year did you start practicing (post Residency)",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "total incentive processed",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "previously participated studies",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "mode of payment",
      type: "dropdown",
      isRequired: false,
      isUnique: false,
      allowedValues: [
        "cheque",
        "cash",
        "wire",
        "voucher",
        "UPI",
        "charity",
        "others",
      ],
    },
    {
      title: "payment details",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "validated date",
      type: "string",
      isRequired: false,
      isUnique: false,
    },
    {
      title: "status",
      type: "dropdown",
      // isRequired: true,
      isUnique: false,
      allowedValues: statusValues,
    },
    {
      title: "access to mobile app",
      type: "checkbox",
      isRequired: false,
      isUnique: false,
      allowedValues: checkBoxValues,
    },
  ],
  data: [
    [
      "7800029865",
      "Title-x",
      "Jeo",
      "mount",
      "male",
      "1993",
      "test0001@mailinator.com",
      "testj000@mailinator.com",
      "9999000959",
      "yes",
      "7008888882",
      "no",
      "3890009321",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "600001",
      "26 to 50",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Not Validated",
      "yes",
    ],
    [
      "7894598655",
      "Title-y",
      "Jan",
      "Mon",
      "female",
      "1999",
      "testjane17410@mailinator.com",
      "testjane28520@mailinator.com",
      "9999945639",
      "yes",
      "8888985382",
      "no",
      "3457855583",
      "Maharashtra",
      "others",
      "Mumbai",
      "others",
      "Tier 1",
      "West",
      "600001",
      "11 to 25",
      "2010",
      "10",
      "study x",
      "voucher",
      "payment done",
      "09-11-2023",
      "Valid",
      "yes",
    ],
  ],
};
