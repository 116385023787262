import { Form, Input, InputNumber, Select } from "antd";
import { useState, useEffect } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const SliderNew = (prop) => {
  const [changeOptionValue, setChangeOptionValue] = useState();
  const [minimum, setMinimum] = useState(prop.sliderMinimum);
  const [maximum, setMaximum] = useState(prop.sliderMaximum);

  const onChange = (value) => {
    prop.handleOption(value, prop.index, prop.optionIndex);
  };
  const handleError = () => {
    if (minimum !== maximum) {
      return true;
    }
    return false;
  };
  let regex = /^[0-9]$/;
  useEffect(() => {
    setChangeOptionValue(prop.changeOptionValue);
  }, [prop]);
  return (
    <Form.Item className="block pl-20">
      <Form.Item
        name={[prop.option.name, "sliderType"]}
        fieldKey={[prop.option.fieldKey, "sliderType"]}
        style={{ width: "70%" }}
        className="float-left"
        label={<span>Type</span>}
        colon={false}
      >
        <Select
          placeholder="Select"
          onChange={onChange}
          className="ml-2.5"
          style={{ width: "98%" }}
        >
          <Option value="numerical">Numerical</Option>
          <Option value="text">Text</Option>
        </Select>
      </Form.Item>
      {changeOptionValue === "numerical" && (
        <div className="block">
          <Form.Item
            label={<span>Range</span>}
            colon={false}
            className="float-left"
            style={{ width: "70%" }}
          >
            <Input.Group compact>
              <Form.Item
                style={{
                  width: "47.5%",
                  borderRight: 0,
                }}
                name={[prop.option.name, "sliderMinimum"]}
                rules={[
                  () => ({
                    validator(_, value) {
                      let result = handleError();
                      if (result === true && value >= 0) {
                        return Promise.resolve();
                      }
                      if (value < 0) {
                        return Promise.reject(
                          "Value should be greater than or equal to zero"
                        );
                      } else {
                        return Promise.reject(
                          "Maximum and minimum number should not be same"
                        );
                      }
                    },
                  }),
                ]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                    textAlign: "center",
                    borderRight: 0,
                  }}
                  placeholder="Minimum"
                  onChange={(e) => setMinimum(e)}
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: "5%",
                }}
              >
                <Input
                  className="site-input-split"
                  style={{
                    borderRight: 0,
                    pointerEvents: "none",
                  }}
                  placeholder="~"
                  disabled
                />
              </Form.Item>
              <Form.Item
                style={{
                  width: "47.5%",
                  borderRight: 0,
                }}
                name={[prop.option.name, "sliderMaximum"]}
                rules={[
                  () => ({
                    validator(_, value) {
                      let result = handleError();
                      if (result === true && value >= 0) {
                        return Promise.resolve();
                      }
                      if (value < 0) {
                        return Promise.reject(
                          "Value should be greater than or equal to zero"
                        );
                      } else {
                        return Promise.reject(
                          "Maximum and minimum number should not be same"
                        );
                      }
                    },
                  }),
                ]}
              >
                <InputNumber
                  className="site-input-right"
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                  placeholder="Maximum"
                  onChange={(e) => setMaximum(e)}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            className="float-left"
            style={{ width: "70%" }}
            label={<span>Steps</span>}
            colon={false}
            name={[prop.option.name, "sliderNumberSteps"]}
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== "" && !regex.test(value)) {
                    return Promise.reject("Please enter a valid step number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber className="ml-1" style={{ width: "100%" }} />
          </Form.Item>
        </div>
      )}
      {changeOptionValue === "text" && (
        <Form.List
          name={[prop.option.name, "sliderText"]}
          initialValue={["slider"]}
        >
          {(sliderList, { add: addSlider, remove: removeSlider }) => (
            <>
              {sliderList.map((slider, index) => (
                <Form.Item
                  {...slider}
                  name={[slider.name, "slider"]}
                  fieldKey={[slider.fieldKey, "slider"]}
                  className="w-4/5 float-left"
                  key={index}
                >
                  <Form.Item
                    className="float-left"
                    name={[slider.name, "steps"]}
                    fieldKey={[slider.fieldKey, "steps"]}
                    label={<span>Step {index + 1}</span>}
                    colon={false}
                    style={{ width: "87%" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item className="w-10 float-left ml-12">
                    {sliderList.length > 1 ? (
                      <MinusCircleOutlined
                        style={{ color: "red" }}
                        onClick={() => removeSlider(slider.name)}
                      />
                    ) : (
                      <MinusCircleOutlined style={{ color: "red" }} disabled />
                    )}
                  </Form.Item>
                </Form.Item>
              ))}
              <Form.Item className="w-8 float-left">
                <PlusCircleOutlined
                  className="dynamic-add-button "
                  style={{ color: "orange" }}
                  onClick={() => addSlider()}
                />
              </Form.Item>
            </>
          )}
        </Form.List>
      )}
    </Form.Item>
  );
};
export default SliderNew;
