import { Table } from "antd";
import { useMemo } from "react";
import { useVT } from 'virtualizedtableforantd4';

export default function MemberTable({ columns, tableData, ...rest }) {
  const scrollEnabled = useMemo(() => columns.filter((col) => col.isShow).length < 8, [columns])
  const tableColumns = useMemo(() => columns.map((col) => {
    if (!col.width) {
      col.width = 200;
    }
    return col
  }), [columns])

  const [vt, set_components] = useVT(() => ({ scroll: { y: 600 } }), []);
  return (
    <Table
      columns={tableColumns}
      components={vt}
      style={{ overflow: "auto" }}
      dataSource={tableData}
      scroll={{
        // x: '100vw',
        x: !scrollEnabled ? 0 : 2000,
        y: 500
      }}
      rowKey={(record) => record.id}
      // className="memberTable border-0"
      rowClassName={(record, index) =>
        "text-sm text-textMedium border-t border-l border-r border-textLight"
      }
      {...rest}
    />
  );
}
