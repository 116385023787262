import { MoreOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Dropdown, Menu, Spin, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import { useHistory } from "react-router-dom";
import ErrorPage from "../../components/errorPage";
import { getColumnSearchProps } from "../../utils/table";
import { GET_CLIENT_DASHBOARD } from "./graphql";
import ColumnEditor from "../../components/ColumnEditor";

const ClientDashboard = () => {
  const history = useHistory();

  const [liveProjects, setLiveProjects] = React.useState([]);

  const savedclientDashboard = localStorage.getItem('clientDashboard');
  const clientDashboardinitial = savedclientDashboard
    ? JSON.parse(savedclientDashboard)
    : {
      category: { enabled: true, label: "Category" },
      sample: { enabled: true, label: "Sample" },
      start: { enabled: true, label: "Start date" },
      end: { enabled: true, label: "End date" },
      "completed": { enabled: true, label: "Completed" },
      "screened": { enabled: true, label: "Screened" },
      "terminated": { enabled: true, label: "Terminated" },
    }
  const [columnStatus, setStatus] = useState(clientDashboardinitial);

  useEffect(() => {
    localStorage.setItem('clientDashboard', JSON.stringify(columnStatus));
  }, [columnStatus]);
  
  const { data, loading, error } = useQuery(GET_CLIENT_DASHBOARD, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    let newObj = [];
    if (data) {
      let object = JSON.parse(JSON.stringify(data?.pending_projects_list));
      object.forEach((item) => {
        let val =
          item?.survey_type !== null && item?.survey_type !== ""
            ? item?.survey_type === "Third_Party_survey"
              ? "- " + item?.third_party_survey_name
              : "- " + item?.survey_type
            : "";
        item.category = item?.projectTypeByProjectType?.name + " " + val;
        item.completed = item?.surveys[0]?.completed?.aggregate?.count || 0;
        item.screened = item?.surveys[0]?.screened?.aggregate?.count || 0;

        item.terminated = item?.surveys[0]?.quotaFull?.aggregate?.count || 0;
        newObj.push(item);
      });
      setLiveProjects(newObj);
    }
  }, [data]);
  const columns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
    },
    {
      title: "CATEGORY",
      key: "projectTypeByProjectType.name",
      dataIndex: "category",
      ...getColumnSearchProps("category", "Category"),
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",

      render: (text, record, index) => (
        <>
          <span> {text}</span>
          <div>
            {record.member_types !== null && record.member_types[0] === 1 && (
              <span className="text-xs text-textLight font-normal">
                Physician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 2 && (
              <span className="text-xs text-textLight font-normal">
                Technician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 3 && (
              <span className="text-xs text-textLight font-normal">
                Patient
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 4 && (
              <span className="text-xs text-textLight font-normal">
                Consumer
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "SAMPLE",
      key: "sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (text, record, index) => {
        // let avgPerDay = Math.ceil(
        //   record.sample /
        //     moment(record?.end_date).diff(moment(record?.start_date), "days")
        // );
        // let days = moment().diff(moment(record?.start_date), "days");
        return (
          <div className="text-textMedium text-h8 w-20">
            <span className="float-left">
              {record?.surveys?.reduce(
                (arr, cur) =>
                  arr +
                  (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                0
              )}{" "}
              / {record.sample}
            </span>
          </div>
        );
      },
    },
    {
      title: "START DATE",
      key: "start_date",
      dataIndex: "start_date",
      className: columnStatus?.start?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      dataIndex: "end_date",
      className: columnStatus?.end?.enabled ? "table-cell" : "hidden",

      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "COMPLETED",
      key: "completed",
      dataIndex: "completed",
      className: columnStatus?.completed?.enabled ? "table-cell" : "hidden",

      ...getColumnSearchProps("completed", "Completed"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "SCREENED",
      key: "screened",
      dataIndex: "screened",
      className: columnStatus?.screened?.enabled ? "table-cell" : "hidden",

      ...getColumnSearchProps("screened", "screened"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "TERMINATED",
      key: "terminated",
      dataIndex: "terminated",
      className: columnStatus?.terminated?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("terminated", "terminated"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  if (record?.surveys.length) {
                    history.push(
                      `/clients/project/${record.id}/project-details/survey/${record.surveys[0].id}`
                    );
                  } else {
                    history.push(
                      `/clients/project/${record.id}/project-details`
                    );
                  }
                }}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Spin spinning={loading}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-textMedium text-h4 font-semibold">
            Projects
          </span>
          <span>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={liveProjects}
          className="bg-white shadow"
          pagination={
            loading
              ? false
              : {
                showTotal: (total, range) => {
                  return (
                    <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                  );
                },
              }
          }
        />
      </div>
    </Spin>
  );
};

export default ClientDashboard;
