import React from "react";
import PropTypes from "prop-types";
import { PieChart, Pie, Cell, Label, Text } from "recharts";

const ChartCard = (props) => {
  const CustomLabel = (data) => {
    const {
      viewBox: { cx, cy },
    } = data;

    return (
      <React.Fragment>
        {props.topLabel && (
          <Text
            x={cx}
            y={cy - 20}
            textAnchor="middle"
            verticalAnchor="middle"
            fill="#C4C4C4"
            fontSize="12px"
            fontWeight="500"
          >
            {props.topLabel}
          </Text>
        )}
        <Text
          x={cx}
          y={cy}
          textAnchor="middle"
          verticalAnchor="middle"
          fontSize="24px"
          fill="#E08036"
        >
          {props.count}
        </Text>
        {props.bottomLabel && (
          <Text
            x={cx}
            y={cy + 20}
            textAnchor="middle"
            verticalAnchor="middle"
            fill="#C4C4C4"
            fontSize="12px"
            fontWeight="500"
          >
            {props.bottomLabel}
          </Text>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className={`p-6 flex flex-col bg-white ${props.className}`}>
      <div className="text-h7 text-textLight mb-6">{props.title}</div>
      <div className="flex items-center justify-between">
        <div className="w-1/2">
          <PieChart width={168} height={168}>
            <Pie
              data={props.data}
              outerRadius={80}
              innerRadius={45}
              dataKey="count"
            >
              {props.data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
              <Label position="center" content={CustomLabel} />
            </Pie>
          </PieChart>
        </div>
        <div className="flex flex-col items-start justify-start w-1/2">
          {props.data.map((entry, index) => (
            <div key={index} className="flex items-center justify-center mb-4">
              <div
                style={{
                  width: 8,
                  height: 8,
                  backgroundColor: entry.color,
                  marginRight: 10,
                  borderRadius: "50%",
                }}
              />
              <div>{`${entry.title} (${entry.count})`}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ChartCard.defaultProps = {
  count: 0,
  title: "",
  className: "",
  data: [],
  topLabel: "",
  bottomLabel: "",
};

ChartCard.propTypes = {
  count: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  topLabel: PropTypes.string,
  bottomLabel: PropTypes.string,
};

export default ChartCard;
