import React from "react";
import logo from "../../assets/logo.svg";

const SurveyNav = (props) => {
  return (
    <div className="w-full h-24 bg-white shadow p-6">
      <img src={logo} alt="krea" className="mt-4" />
    </div>
  );
};

export default SurveyNav;
