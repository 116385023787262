import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { Button, Form, notification } from "antd";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import { projectForm } from "../../../../../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  GET_MEMBERS,
  GET_ASSIGNED_MEMBERS,
  SURVEYxMEMBERS,
  UPDATE_STATUS,
  DELETE_SURVEY_X_MEMBERS,
  SURVEY_DATES,
} from "../graphql";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../../../../components/ColumnEditor";
import { RoleContext } from "../../../../../context/RoleContext";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../../../components/memberColumns";
import MemberTable from "../../../../../components/memberTable";
// import AssignInterviewerList from "../../kreaSurvey/assigninterviewers";
// import { PROJECT_DETAILS } from "../../kreaSurvey/graphql";
import ViewActiveButton from "../../../../../components/viewActiveButton";
import AssignInterviewerList from "../assigninterviewers";
import { PROJECT_DETAILS } from "../graphql";
import SpecialityModalPop from "../../../../../utils/specialitySearch";
function SelectMembers(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const location = useLocation();
  const setValues = useSetRecoilState(projectForm);
  const memberListDetails = useRecoilValue(projectForm);
  const [tableData, setTableData] = useState([]);
  const [selectedKeyRow, setSelectedKeys] = useState([]);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const [insertmembers, { loading: onInsertLoading }] =
    useMutation(SURVEYxMEMBERS);
  const [deleteMembers, { loading: onDeleteLoading }] = useMutation(
    DELETE_SURVEY_X_MEMBERS
  );
  const [updateSurvey, { loading: onUpdateLoading }] =
    useMutation(UPDATE_STATUS);
  const [assignedMembers, setAssignedMembers] = useState({});
  const [startDate, setStartDate] = useState(null);
  // const [dueDate, setDueDate] = useState(null);
  const savedonlineSelectClient = localStorage.getItem('onlineSelectClient');
  const onlineSelectClientinitial = savedonlineSelectClient
    ? JSON.parse(savedonlineSelectClient)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(onlineSelectClientinitial);
  const { role } = useContext(RoleContext);
  const [triggerOnComplete, setTriggerOnComplete] = useState(false);
  const [triggeronDraft, setTriggerOnDraft] = useState(false);
  const [memberType, setMemberType] = useState([]);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState("");
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  let searchInput;

  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      let obj = {};
      let newarray = [];
      res.member_type.map((item) => {
        obj[item.id] = item.name;
      });
      res.project[0].member_types.map((type) => {
        newarray.push(obj[type]);
      });
      setMemberType(newarray);
    },
  });

  const {
    data: assignedDataMembers,
    loading,
    refetch: assignedMembersRefetch,
  } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },

    fetchPolicy: "no-cache",
  });

  const { data: members } = useQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    localStorage.setItem('onlineSelectClient', JSON.stringify(columnStatus));
  }, [columnStatus]);

  // useEffect(() => {
  //   assignedMembersRefetch();
  //   surveyDatesRefetch();
  // }, [location]);
  const defaultSelectedRows =
    memberListDetails?.memberListDetails?.selectedRowsSTORE;
  const [rowSelectionValue, setRowSelectionValue] = useState([]);
  const [updatedTableData, setUpdatedTableData] = useState([]);
  useEffect(() => {
    if (defaultSelectedRows && defaultSelectedRows.length > 0) {
      setRowSelectionValue(defaultSelectedRows);
    }
  }, [defaultSelectedRows]);

  const handleEditRow = (modifiedRow) => {
    const rowIndex = rowSelectionValue.findIndex(
      (row) => row.id === modifiedRow.id
    );

    if (rowIndex !== -1) {
      const updatedTable = [...rowSelectionValue];
      updatedTable[rowIndex] = modifiedRow;
      setRowSelectionValue(updatedTable);
    } else {
      setUpdatedTableData((prevState) => [...prevState, modifiedRow]);
    }
  };
  useEffect(() => {
    setAssignedMembers(assignedDataMembers);
    let editOrCreate = location.pathname.split("/")[3];
    if (triggerOnComplete) {
      // handleClick();
      history.push(
        `/project/${projectID}/${editOrCreate}/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
      );
      setTriggerOnComplete(false);
    }
    if (triggeronDraft) {
      // handleDraft();
      notification.success({
        message: "Success!",
        description: "Saved as draft!",
      });
      history.push("/projectList/active");
      setTriggerOnDraft(false);
    }
  }, [assignedDataMembers, triggerOnComplete, triggeronDraft]);
  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      // setDueDate(moment(surveyDates?.survey[0]?.end_date));
    }
  }, [surveyDates]);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members);
      let newList = object.filter((item) => {
        return memberType.includes(item.category);
      });

      setTableData(newList);
    }
  }, [members, memberType]);

  useEffect(() => {
    assignedMembersRefetch();
    surveyDatesRefetch();
    let obj = {};
    if (!loading) {
      if (memberListDetails.memberListDetails) {
        setSelectedKeys(
          memberListDetails?.memberListDetails?.selectedRowKeysSTORE
        );
        // setRowSelectionValue(memberListDetails?.memberListDetails?.selectedRowKeysSTORE,'edd')
      } else if (
        assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length !== 0
      ) {
        let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.id;
          let status = el.user.survey_x_members[0].state;
          obj[id] = { status };
          return el.user.id;
        });
        let object,
          newObject = [];

        object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
        object.forEach((item) => {
          item.user.genderNew =
            item?.user?.gender === null ? "Not updated" : item?.user?.gender;
          item.user.category = item.user.member_type.name;
          item.user.memberName =
            item?.user?.l_name !== null
              ? item?.user?.f_name + " " + item?.user?.l_name
              : item?.user?.f_name;
          item.user.survey_url =
            item?.user?.survey_x_members[0]?.survey_url || null;
        });
        object.forEach((item) => {
          newObject.push(item.user);
        });
        setSelectedKeys(selectedArray);
        setValues((oldValues) => ({
          ...oldValues,
          memberListDetails: {
            selectedRowKeysSTORE: selectedArray,
            selectedRowsSTORE: newObject,
          },
          updateMembersDetails: {
            updateMembers: true,
            memberList: assignedMembers,
            status: obj,
          },
        }));
      }
    }
    // eslint-disable-next-line
  }, [memberListDetails, assignedMembers, location]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleMemberData = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let linkCopy = [],
        interviewerID = [],
        interviewer_obj = {};
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  // delete key["id"];
                  // delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });

      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member.survey_url) {
            linkCopy[index] = member.survey_url;
          } else {
            linkCopy[index] = null;
          }
        }
      );
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            survey_url: linkCopy[index],
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );
      if (surveymemberID.length > 0) {
        return deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        });
      } else {
        return insertmembers({
          variables: {
            objects: payload,
          },
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleClick = (interviewer_data_set = {}) => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let linkCopy = [],
        interviewerID = [],
        interviewer_obj = {};
      let assignmembers_data = interviewer_data_set?.survey_x_member
        ? interviewer_data_set
        : assignedMembers;
      assignmembers_data?.survey_x_member &&
        assignmembers_data?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignmembers_data?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });

      assignmembers_data?.survey_x_member &&
        assignmembers_data?.survey_x_member?.length > 0 &&
        assignmembers_data?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let mKey = { ...key };
                  let id = key.id;
                  delete mKey.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(mKey);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });

      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member.survey_url) {
            linkCopy[index] = member.survey_url;
          } else {
            linkCopy[index] = null;
          }
        }
      );
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;

      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            survey_url: linkCopy[index],
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: moment().isSameOrAfter(props.startDate)
                ? props.status === "draft"
                  ? "draft"
                  : "active"
                : props.status === "active"
                  ? "active"
                  : "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            // props.handleToggle(true);
            let editOrCreate = location.pathname.split("/")[3];
            history.push(
              `/project/${projectID}/${editOrCreate}/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
            );
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            let editOrCreate = location.pathname.split("/")[3];
            history.push(
              `/project/${projectID}/${editOrCreate}/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
            );
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleDraft = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let linkCopy = [],
        interviewerID = [],
        interviewer_obj = {};
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      let statusObj =
        memberListDetails?.updateMembersDetails &&
        memberListDetails?.updateMembersDetails?.status;
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });

      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  let id = key.id;
                  delete key.id;
                  delete key.survey_x_member_id;
                  if (!interviewer_obj[el.member_id]) {
                    interviewer_obj[el.member_id] = [];
                  }
                  interviewer_obj[el.member_id].push(key);
                  if (id) {
                    interviewerID.push(id);
                  }
                });
          });

      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member.survey_url) {
            linkCopy[index] = member.survey_url;
          } else {
            linkCopy[index] = null;
          }
        }
      );

      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          let payloadData = {
            member_id: key,
            state:
              statusObj === undefined || statusObj[key] === undefined
                ? "active"
                : statusObj[key].status,
            survey_id: parseInt(surveyID),
            survey_url: linkCopy[index],
          };
          // if (interviewer_obj[key]) {
          //   payloadData.survey_x_member_x_interviewers = {
          //     data: interviewer_obj[key],
          //   };
          // }
          if (interviewer_obj[key]) {
            payloadData.survey_x_member_x_interviewers = {
              data: interviewer_obj[key].map((item) => {
                return {
                  // id: item.id,
                  priority: item.priority,
                  interviewer_id: item.interviewer_id,
                  interviewer_on_leave: item.interviewer_on_leave,
                  status: item.status,
                };
              }),
            };
          }
          payload.push(payloadData);
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
            Interviewerarray: interviewerID,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(
      filteredDataSource.map((item) => item[columnName])
    );
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(
    new Set(tableData?.map((item) => item[selectedColumnNameSpec]))
  ).filter((data) =>
    data?.toLowerCase().includes(searchQuerySpec.toLowerCase())
  );

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setRowSelectionValue({
        selectedRowKeys: selectedRowKeys,
        selectedRows: selectedRows,
      });
      const object = assignedMembers?.survey_x_member;
      const rowCopy = selectedRows.map((row) => {
        const foundItem = object.find((item) => item?.user?.id === row?.id);
        if (foundItem) {
          return {
            ...row,
            survey_url: foundItem?.user?.survey_x_members[0]?.survey_url,
          };
        }
        return row;
      });
      // let object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      // let rowCopy = JSON.parse(JSON.stringify(selectedRows));
      // if (object?.length > 0) {
      //   object.forEach((item) => {
      //     rowCopy.forEach((row, index) => {
      //       if (item?.user?.id === row?.id) {
      //         row.survey_url = item?.user?.survey_x_members[0]?.survey_url;
      //         return;
      //       }
      //     });
      //   });
      // }
      setSelectedKeys(selectedRowKeys);
      setValues((oldValues) => ({
        ...oldValues,
        memberListDetails: {
          selectedRowKeysSTORE: selectedRowKeys,
          selectedRowsSTORE: rowCopy,
        },
      }));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedKeyRow,
  };

  return (
    <div className="selectMembers px-10 py-4 w-full">
      {location.pathname.split("/")[5] === "assigninterviwers" ? (
        <AssignInterviewerList
          assignedMembers={assignedMembers}
          handleDraft={async () => {
            await assignedMembersRefetch({
              fetchPolicy: "no-cache",
            });
            setTriggerOnDraft(true);
          }}
          onComplete={async () => {
            await assignedMembersRefetch({
              fetchPolicy: "no-cache",
            });
            setTriggerOnComplete(true);
          }}
        />
      ) : (
        <>
          <div className="my-4 text-h5 text-textMedium flex justify-between ">
            Select members
            <div className=" flex items-center">
              {!!selectedKeyRow?.length && (
                <ViewActiveButton memberId={selectedKeyRow} />
              )}
              <ColumnEditor
                columns={columnStatus}
                onSave={(column) => {
                  setStatus(column);
                }}
              />
            </div>
          </div>
          <Form>
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={false}
            ></MemberTable>
            <SpecialityModalPop
              titleSpec={`Selected ${selectedColumnNameSpec}`}
              visibleSpec={isModalVisibleSpec}
              onOkSpec={handleModalOkSpec}
              onCancelSpec={handleModalCloseSpec}
              searchQuerySpec={searchQuerySpec}
              handleSearchSpec={handleSearchSpec}
              handleCheckboxChangeSpec={handleCheckboxChangeSpec}
              filteredCheckboxDataSpec={filteredCheckboxDataSpec}
              selectedDataSpec={selectedDataSpec}
              initialSelectedDataSpec={initialSelectedDataSpec}
              isSubmitDisabledSpec={isSubmitDisabledSpec}
              handleResetSpec={handleResetSpec}
            />
            <Form.Item className="float-right mt-20">
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={() => {
                  location.pathname.split("/")[3] === "create-survey"
                    ? history.push(
                      `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
                    )
                    : history.push(
                      `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
                    );
                }}
              >
                Back
              </Button>
              <Button
                htmlType="button"
                className="text-base mx-5 rounded-md text-orange"
                onClick={handleDraft}
                disabled={
                  moment().isSameOrAfter(startDate) && props.status !== "draft"
                    ? true
                    : false
                }
                loading={onInsertLoading || onDeleteLoading || onUpdateLoading}
              >
                Save as draft
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="text-base rounded-md w-28"
                onClick={async () => {
                  if (project?.[0]?.is_online_or_assisted) {
                    // setShowInterviewers(true)
                    let editOrCreate = location.pathname.split("/")[3];
                    await handleMemberData();
                    history.push({
                      pathname: `/project/${projectID}/${editOrCreate}/${surveyType}/assigninterviwers/survey/${surveyID}`,
                      state: { rowSelectionValue: rowSelectionValue },
                    });
                  } else {
                    handleClick();
                  }
                }}
                loading={onInsertLoading || onDeleteLoading || onUpdateLoading}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {showError ? (
        <p style={{ color: "red" }}>Please select atleast one member</p>
      ) : null}
    </div>
  );
}
export default SelectMembers;
