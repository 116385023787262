import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Input } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const TextBox = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [answer, setAnswer] = useState([]);
  const [data, setData] = useState([]);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy.push(value);
        }
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
    }));
    setAnswer(copy);
    // eslint-disable-next-line
  }, [answers]);

  const handleOnChange = (value, label, i) => {
    let current_answer_copy = [...surveyDetails.current_answer];
    let ans_copy = [...answer];
    ans_copy[i] = value;
    let copy = {};
    // copy.push({ type: props.question.question_type.name });
    copy[label] = value;
    copy["type"] = props.question.question_type.name;
    current_answer_copy[i] = copy;

    setAnswer(ans_copy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer_copy,
    }));
  };
  return (
    <div className="mt-6">
      {data.map((option, i) => (
        <div className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4">
          {option.label && (
            <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
              {option.label}
            </span>
          )}
          <Input.TextArea
            type={props.type || "text"}
            autoSize={{ maxRows: 1 }}
            showCount={
              props?.question?.validation !== null
                ? props?.question?.validation?.labelCharLimit[i]
                  ? true
                  : false
                : false
            }
            maxLength={
              props?.question?.validation?.labelCharLimit[i]?.charLimit
            }
            value={answer[i]}
            size="large"
            className="w-85 lg:w-96 rounded"
            onChange={(e) => handleOnChange(e.target.value, option.label, i)}
          />
        </div>
      ))}
    </div>
  );
};

export default TextBox;
