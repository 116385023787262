import React from "react";
import SurveyNav from "./survey/nav";

const ErrorPage = () => {
  return (
    <>
      <SurveyNav />
      <div
        className="p-4 lg:py-48 lg:p-0 flex flex-col items-center justify-start bg-white shadow"
        style={{ height: "calc(100vh - 208px)" }}
      >
        <div className="bg-background p-4 flex flex-col items-center justify-center w-full lg:w-1/2 text-center">
          <div className="text-h5 text-textDark font-semibold mb-2">
            Password Reset Successfully!
          </div>
          <p className="text-h8 text-textMedium text-center mb-4">
            Your password has been updated. You may now proceed to login to your
            account using the KreaInsights app.
          </p>
          <div className="text-textLight text-h11">
            For any queries, please mail{" "}
            <a
              href="mailto:panelsupport@krea.in"
              className="decoration-solid underline"
            >
              ask@krea.in
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
