import React, { useState } from "react";
import SelectMembers from "./selectMembers";
import MemberList from "./memberList";
import { useLocation } from "react-router-dom";

function AssignMembers(props) {
  const location = useLocation();
  // eslint-disable-next-line
  const [togglePage, setTogglePage] = useState(false);
  const handleToggle = (value) => {
    setTogglePage(value);
  };
  return (
    <div>
      {/* Select Membersing page */}
      {location.pathname.split("/")[8] === undefined && (
        <SelectMembers
          handleToggle={handleToggle}
          startDate={props.startDate}
          status={props.status}
        />
      )}
      {/* Interviewers page */}
      {location.pathname.split("/")[8] === "schedule" && (
        <MemberList
          handleToggle={handleToggle}
          startDate={props.startDate}
          status={props.status}
        />
      )}
    </div>
  );
}

export default AssignMembers;
