import { Tabs, Layout, Menu } from "antd";
import { useQuery } from "@apollo/client";
import LocationHierarchy from "../../../../components/locationHierarchy";
import Overview from "./overview/overview";
import Questionnaire from "./questionnaire/questionnaire";
import AssignMembers from "./assignMembers/index";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import EmailTemplate from "./email-template";
import { SURVEY_DATES } from "./graphql";
import moment from "moment";

const { Content } = Layout;
const { TabPane } = Tabs;

function CreateSurvey() {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tabPane = [
    "overview",
    "questionnaire",
    "assignmembers",
    "emailtemplate",
  ];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState();
  const [surveyData, setSurveyData] = useState({});
  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  useEffect(() => {
    surveyDatesRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location.pathname.split("/")[5] === "questionnaire") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[5] === "assignmembers" || location.pathname.split("/")[5] === "assigninterviwers") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[5] === "overview") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[5] === "emailtemplate") {
      setActiveTabPane("4");
    }
  }, [location.pathname]);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setStatus(surveyDates?.survey[0]?.status);
    }
  }, [surveyDates]);

  const handleActiveTabPane = (key) => {
    location.pathname.split("/")[3] === "create-survey"
      ? history.push(
        `/project/${projectID}/create-survey/${surveyType}/${tabPane[key - 1]
        }/survey/${surveyID}`
      )
      : history.push(
        `/project/${projectID}/edit-survey/${surveyType}/${tabPane[key - 1]
        }/survey/${surveyID}`
      );
    setActiveTabPane(key);
  };
  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10">
        <LocationHierarchy id={projectID} />
        <div className="absolute text-base text-gray-700 pt-2">
          {location.pathname.split("/")[3] === "create-survey" && (
            <p>Create survey</p>
          )}
          {location.pathname.split("/")[3] === "edit-survey" && (
            <p>Edit survey</p>
          )}
        </div>

        <div
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            tabPosition="left"
            size="large"
            className="text-textExtraLight"
            tabBarStyle={{ width: "200px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane tab="Overview" key="1">
              <Overview
                startDate={startDate}
                status={status}
                onSurveyData={(data) => {
                  setSurveyData(data);
                }}
              />
            </TabPane>
            <TabPane
              tab="Questionnaire"
              key="2"
              disabled={surveyID ? false : true}
            >
              <Questionnaire startDate={startDate} status={status} />
            </TabPane>
            <TabPane
              className="w-full customMenu pt-0"
              disabled={surveyID ? false : true}
              tab={
                <Menu
                  className="text-base border-r-0 pl-0"
                  disabled={surveyID ? false : true}
                  selectedKeys={
                    location.pathname.includes("emailtemplate/inviteemail")
                      ? ["inviteEmail"]
                      : location.pathname.includes(
                        "emailtemplate/reminderemail"
                      )
                        ? ["reminderEmail"]
                        : []
                  }
                >
                  <Menu.ItemGroup
                    key="g2"
                    className="text-sm"
                    title={
                      <div className="text-base -ml-4 text-textExtraLight">
                        Email templates
                      </div>
                    }
                  >
                    <Menu.Item
                      key="inviteEmail"
                      className="flex items-center"
                      onClick={() => {
                        history.push(
                          `/project/${projectID}/${location.pathname.split("/")[3]
                          }/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
                        );
                      }}
                    >
                      <div className="text-sm  ">Survey invite email</div>
                    </Menu.Item>
                    <Menu.Item
                      key="reminderEmail"
                      className="flex items-center"
                      onClick={() => {
                        history.push(
                          `/project/${projectID}/${location.pathname.split("/")[3]
                          }/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        );
                      }}
                    >
                      <div className="text-sm  ">Reminder email</div>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              }
              key="4"
            >
              <EmailTemplate
                surveyId={surveyID}
                surveyData={surveyData}
                status={status}
              />
            </TabPane>
            <TabPane tab="Send to" key="3" disabled={surveyID ? false : true}>
              <AssignMembers startDate={startDate} status={status} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}

export default CreateSurvey;
