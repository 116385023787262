import { Drawer, Form, Button, Table, Checkbox, InputNumber } from "antd";
import { useState, useEffect } from "react";
import FileUpload from "./fileUploadValidation";

function TextBoxTextValidation(props) {
  const [visible, setVisible] = useState(props.visible);
  const [value, setValue] = useState(
    props?.validationObject[props?.questionNumber]
  );
  const emptyArray = new Array(props.optionValue.length).fill({});
  const [uploadOption, setUploadOption] = useState(
    props?.validationObject[props.questionNumber]
      ? props?.validationObject[props.questionNumber].addFileOption
      : false
  );
  const [labelCharLimit, setLabelCharLimit] = useState(emptyArray);

  const [form] = Form.useForm();

  let charValueArray = [];

  useEffect(() => {
    assignInitialValue(props);
    // eslint-disable-next-line
  }, [props]);

  const assignInitialValue = (props) => {
    props?.validationObject[props.questionNumber] &&
      props?.validationObject[props.questionNumber]?.labelCharLimit &&
      props?.validationObject[props.questionNumber]?.labelCharLimit.length !==
        0 &&
      // eslint-disable-next-line
      props?.validationObject[props.questionNumber]?.labelCharLimit.map(
        // eslint-disable-next-line
        (value, index) => {
          charValueArray.push(value);
        }
      );
    setLabelCharLimit(charValueArray);
  };

  const onClose = () => {
    setVisible(false);
    let validationObject = {
      labelCharLimit: labelCharLimit,
      questionMandatory: form.getFieldValue("questionMandatory")
        ? form.getFieldValue("questionMandatory")
        : false,
      addFileOption: form.getFieldValue("addFileOption")
        ? form.getFieldValue("addFileOption")
        : null,
      fileUploadDescription: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileUploadDescription")
        : null,
      fileTypesAllowed: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileTypesAllowed")
        : null,
      fileSize: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileSize")
        : null,
      fileNumbers: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers")
        : null,
      fileLimit: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers") &&
          form.getFieldValue("fileNumbers")[0] === "Limit to"
          ? form.getFieldValue("fileLimit")
          : null
        : null,
    };
    props.handleValidationValues(
      validationObject,
      visible,
      props.questionNumber
    );
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    props.handleValidationValues(
      props.validationObject[props.questionNumber],
      visible,
      props.questionNumber
    );
  };

  const onReset = () => {
    form.setFieldsValue({ addFileOption: false });
    form.setFieldsValue({ fileUploadDescription: "" });
    form.setFieldsValue({ fileTypesAllowed: [] });
    form.setFieldsValue({ fileSize: null });
    form.setFieldsValue({ fileNumbers: [] });
    form.setFieldsValue({ fileLimit: null });
    setValue(null);
    setUploadOption(false);
    setLabelCharLimit(emptyArray);
  };

  const handleCharLimit = (e, record, index) => {
    let newArray = [...labelCharLimit];

    if (newArray[index] === undefined) {
      newArray.push({ label: record.label, charLimit: e });
    } else {
      newArray[index] = { label: record.label, charLimit: e };
    }
    setLabelCharLimit(newArray);
  };

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "Char limit",
      dataIndex: "charLimit",
      key: "charLimit",
      render: (text, record, index) => (
        <InputNumber
          className="w-20 rounded-md"
          placeholder="00"
          value={labelCharLimit[index] ? labelCharLimit[index].charLimit : ""}
          onChange={(e) => handleCharLimit(e, record, index)}
          min={0}
        />
      ),
    },
  ];

  let data = [];

  const dataSource = () => {
    props.optionValue.map((optionValue, index) =>
      data.push({ key: index + 1, label: optionValue.option })
    );
    return data;
  };

  const initialValue =
    props?.validationObject &&
    props?.validationObject?.length !== 0 &&
    props?.validationObject[props.questionNumber] !== null
      ? props?.validationObject[props.questionNumber]
      : {
          questionMandatory: true,
        };
  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Validation</span>}
        width={720}
        onClose={onCancel}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <>
            <div style={{ float: "left", width: "70%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
            <div style={{ float: "left", width: "30%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={onClose}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Add
              </Button>
            </div>
          </>
        }
      >
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <Form.Item>
            <Table
              dataSource={dataSource()}
              columns={columns}
              pagination={false}
              rowClassName={(record, index) => "text-sm text-textMedium"}
              rowKey={(record) => record.id}
            />
          </Form.Item>
          <Form.Item
            name="questionMandatory"
            value="questionMandatory"
            valuePropName="checked"
          >
            <Checkbox className="text-textMedium text-sm">
              This question is mandatory
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="addFileOption"
            value="addFileOption"
            valuePropName="checked"
          >
            <Checkbox
              className="text-textMedium text-sm"
              onChange={(e) => setUploadOption(e.target.checked)}
            >
              Add upload file option
            </Checkbox>
          </Form.Item>
          <Form.Item value="fileDetails">
            {uploadOption && (
              <FileUpload
                checked={true}
                textInput={value !== null ? value?.fileLimit : null}
                fileNumbers={
                  value !== null && value?.fileNumbers
                    ? value?.fileNumbers[0]
                    : null
                }
              />
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default TextBoxTextValidation;
