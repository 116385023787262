import { Layout, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import LocationHierarchy from "../../components/locationHierarchy";
import AddPoc from "./addPoc/addPoc";
import ClientDetails from "./clientDetails/clientDetails";
import jwtDecode from "jwt-decode";

const { Content } = Layout;
const { TabPane } = Tabs;

function CreateClient() {
  const history = useHistory();
  const location = useLocation();
  const { clientID } = useParams();
  const [role, setRole] = useState(null);
  const tabPane = ["client-details", "add-poc"];
  const [activeTabPane, setActiveTabPane] = useState("1");

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      // let decodedId = jwtDecode(localStorage.getItem("token"))[
      //   "https://hasura.io/jwt/claims"
      // ]["X-Hasura-User-Id"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (location.pathname.includes("/client-details")) {
      setActiveTabPane("1");
    } else if (location.pathname.includes("/add-poc")) {
      setActiveTabPane("2");
    }
  }, [location.pathname]);

  const handleActiveTabPane = (key) => {
    history.push(`/create_client/${tabPane[key - 1]}`);
    setActiveTabPane(key);
  };

  function addTask(key) {
    setActiveTabPane(key);
  }

  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10 ">
        <LocationHierarchy id={clientID} />
        <div className="absolute text-base text-gray-700 pt-2">
          <p>{clientID ? `Edit client` : `Create a new client`}</p>
        </div>
        <div
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            defaultActiveKey={"1"}
            tabPosition="left"
            size="large"
            className="text-textExtraLight content-start"
            tabBarStyle={{ width: "229px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane
              tab="Client details"
              disabled={activeTabPane !== 1}
              key="1"
            >
              <ClientDetails
                onComplete={addTask}
                clientId={parseInt(clientID)}
              />
            </TabPane>
            <TabPane tab="Add POC" disabled key="2">
              <AddPoc
                onBack={addTask}
                clientId={parseInt(clientID)}
                isEdit={clientID ? true : false}
                role={role}
              />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}

export default CreateClient;
