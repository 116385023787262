import React, { useRef, useEffect } from "react";
import { Row, Button, Col, Drawer, Form, Input, Divider } from "antd";
import { useMutation } from "@apollo/client";
import { REJECT_USER, VERIFY_USER } from "./graphql";

const ViewManagerForm = ({
  isVisible,
  setIsVisible,
  setData,
  data,
  clearData,
  role,
}) => {
  const btn = useRef(null);
  const [form] = Form.useForm();
  const [rejectUser] = useMutation(REJECT_USER);
  const [verifyUser] = useMutation(VERIFY_USER);
  let mobile = /^[0-9]{10}$/;

  const onFinish = (values) => {
    let variables = {
      f_name: values.firstName,
      l_name: values.lastName,
      phone_number: values.phone,
      email: values.email,
      additional_data: values.additional_fields,
      is_verified: data.is_verified,
    };
    setData({ ...variables });
    setIsVisible(false);
    form.resetFields([
      "firstName",
      "lastName",
      "phone",
      "email",
      "additional_fields",
    ]);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        firstName: data?.f_name || "",
        lastName: data?.l_name || "",
        phone: data?.phone_number || "",
        email: data?.email || "",
        additional_fields: data?.additional_data || "",
      });
    }
    // eslint-disable-next-line
  }, [data]);

  const footer = (
    <div>
      {role === "super_admin" ? (
        <div className="w-2/4 h-16 mt-4 float-right">
          <Button
            type="primary"
            htmlType="submit"
            className="float-right text-base rounded-md"
            onClick={() => {
              verifyUser({
                variables: {
                  id: parseInt(data.id),
                  status: true,
                },
              }).then(() => {
                window.location.reload();
              });
            }}
          >
            Approve
          </Button>

          <div className="text-textMedium float-right pt-1 pr-6">
            <Button
              type="link"
              htmlType="submit"
              onClick={() => {
                rejectUser({
                  variables: {
                    id: parseInt(data.id),
                  },
                }).then(() => {
                  clearData(null);
                  setIsVisible(false);
                  window.location.reload();
                });
              }}
            >
              Reject
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );

  return (
    <>
      {" "}
      <Drawer
        title="Edit Manager"
        footer={footer}
        width={600}
        visible={isVisible}
        onClose={() => {
          setIsVisible(false);
          form.resetFields([
            "firstName",
            "lastName",
            "phone",
            "email",
            "additional_fields",
          ]);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          name="admin"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          autoComplete="off"
          requiredMark={false}
          colon={false}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
            className="mt-2"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
            className="mt-6"
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Required" },
              () => ({
                validator(_, value) {
                  if (!mobile.test(value)) {
                    return Promise.reject("Please enter a valid phone number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="mt-6"
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Required" },
              { type: "email", message: "Please enter an valid email address" },
            ]}
            className="mt-6"
          >
            <Input disabled />
          </Form.Item>
          <Divider />
          <Form.List name="additional_fields">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={fields.key}>
                    <Row justify="space-between mb-4">
                      <Col>Custom field {index + 1}</Col>
                      <Col>
                        <Button
                          type="link"
                          onClick={() => remove(index)}
                          style={{ color: "red" }}
                          disabled
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Label"
                      {...restField}
                      name={[name, "label"]}
                      fieldKey={[fieldKey, "label"]}
                      rules={[{ required: true, message: "Missing label" }]}
                      labelCol={{ span: 8 }}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label="Information"
                      {...restField}
                      name={[name, "info"]}
                      fieldKey={[fieldKey, "info"]}
                      rules={[
                        { required: true, message: "Missing information" },
                      ]}
                      labelCol={{ span: 8 }}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Divider className="mb-2" />
                  </div>
                ))}
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                  <Button
                    type="link"
                    ref={btn}
                    onClick={() => add()}
                    style={{ visibility: "hidden" }}
                    disabled
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

export default ViewManagerForm;
