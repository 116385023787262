import {
  Drawer,
  Form,
  Button,
  Table,
  Checkbox,
  Select,
  InputNumber,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import FileUpload from "./fileUploadValidation";

const { Option } = Select;

function NumericalValidation(props) {
  const emptyArray = new Array(props.optionValue.length).fill("");
  const [visible, setVisible] = useState(props.visible);
  const [value, setValue] = useState(
    props?.validationObject[props?.questionNumber]
  );
  const [range, setRange] = useState(emptyArray);
  const [format, setFormat] = useState(emptyArray);
  const [rangeFrom, setRangeFrom] = useState(emptyArray);
  const [operatorValue, setOperatorValue] = useState(emptyArray);
  const [operator, setOperator] = useState(emptyArray);
  const [rangeTo, setRangeTo] = useState(emptyArray);
  const [enableTooltip, setEnableTooltip] = useState(emptyArray);
  const [uploadOption, setUploadOption] = useState(
    props?.validationObject[props.questionNumber]
      ? props?.validationObject[props.questionNumber].addFileOption
      : false
  );
  const [form] = Form.useForm();

  let rangeValue = [];
  let formatValue = [];
  let rangeFromValue = [];
  let rangeToValue = [];
  let operatorArray = [];
  let operatorValueArray = [];

  useEffect(() => {
    assignInitialValue(props);
    // eslint-disable-next-line
  }, [props]);

  const assignInitialValue = (props) => {
    if (
      props?.validationObject[props.questionNumber] &&
      props?.validationObject[props.questionNumber]?.numericalValidation &&
      props?.validationObject[props.questionNumber]?.numericalValidation
        .length !== 0
    ) {
      props?.validationObject[props.questionNumber]?.numericalValidation
        // eslint-disable-next-line
        .map((value, index) => {
          rangeValue.push(value.range);
          formatValue.push(value.format);
          rangeFromValue.push(value.rangeFrom);
          rangeToValue.push(value.rangeTo);
          operatorArray.push(value.operator);
          operatorValueArray.push(value.operatorValue);
        });
      setRange(rangeValue);
      setFormat(formatValue);
      setRangeFrom(rangeFromValue);
      setRangeTo(rangeToValue);
      setOperatorValue(operatorValueArray);
      setOperator(operatorArray);
    }
  };

  const onClose = () => {
    enableTooltip.includes(true) !== true && setVisible(false);
    let numericalValidation = [];
    props.optionValue.map((optionValue, index) => {
      return numericalValidation.push({
        label: optionValue.option,
        index: index + 1,
        format: format[index] !== "" ? format[index] : null,
        range: range[index] !== "" ? range[index] : null,
        rangeFrom: rangeFrom[index] !== "" ? rangeFrom[index] : null,
        rangeTo: rangeTo[index] !== "" ? rangeTo[index] : null,
        operator: operator[index] !== "" ? operator[index] : null,
        operatorValue:
          operatorValue[index] !== "" ? operatorValue[index] : null,
      });
    });

    let validationObject = {
      numericalValidation: numericalValidation,
      questionMandatory: form.getFieldValue("questionMandatory")
        ? form.getFieldValue("questionMandatory")
        : false,
      addFileOption: form.getFieldValue("addFileOption")
        ? form.getFieldValue("addFileOption")
        : null,
      fileUploadDescription: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileUploadDescription")
        : null,
      fileTypesAllowed: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileTypesAllowed")
        : null,
      fileSize: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileSize")
        : null,
      fileNumbers: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers")
        : null,
      fileLimit: form.getFieldValue("addFileOption")
        ? form.getFieldValue("fileNumbers") &&
          form.getFieldValue("fileNumbers")[0] === "Limit to"
          ? form.getFieldValue("fileLimit")
          : null
        : null,
    };
    enableTooltip.includes(true) !== true &&
      props.handleValidationValues(
        validationObject,
        visible,
        props.questionNumber
      );
  };
  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    props.handleValidationValues(
      props.validationObject[props.questionNumber],
      visible,
      props.questionNumber
    );
  };

  const onReset = () => {
    form.setFieldsValue({ addFileOption: false });
    form.setFieldsValue({ fileUploadDescription: "" });
    form.setFieldsValue({ fileTypesAllowed: [] });
    form.setFieldsValue({ fileSize: null });
    form.setFieldsValue({ fileNumbers: [] });
    form.setFieldsValue({ fileLimit: null });
    setValue(null);
    setUploadOption(false);
    setFormat(emptyArray);
    setRange(emptyArray);
  };
  const handleRangeFrom = (e, index) => {
    let newArray = [...rangeFrom];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setRangeFrom(newArray);
  };
  const handleRangeTo = (e, index) => {
    let newArray = [...rangeTo];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    setRangeTo(newArray);
  };
  const handleOperatorValue = (e, index) => {
    let newArray = [...operatorValue];
    let copy = [...enableTooltip];
    if (newArray[index] === undefined) {
      newArray.push(e);
    } else {
      newArray[index] = e;
    }
    if (
      e > 0 &&
      e <= props.allValues.length &&
      props.allValues[e - 1].questionType !== "Text box - numerical" &&
      props.allValues[e - 1].questionType !== "Percentage"
    ) {
      copy[index] = true;
    } else if (props.questionNumber + 1 === e) {
      copy[index] = true;
    } else {
      copy[index] = false;
    }
    setEnableTooltip(copy);
    setOperatorValue(newArray);
  };
  const columns = [
    {
      title: "Label",
      dataIndex: "label",
      key: "label",
      className: "w-28",
    },
    {
      title: "Format",
      dataIndex: "format",
      key: "format",
      className: "w-40",
      render: (text, record, index) => (
        <Select
          name="format"
          className="text-sm text-textMedium"
          placeholder="Select"
          value={format[index] === "" ? "Select" : format[index]}
          onChange={(e) => {
            let newArray = [...format];
            if (newArray[index] === undefined) {
              newArray.push(e);
            } else {
              newArray[index] = e;
            }
            setFormat(newArray);
          }}
        >
          <Option key="integer">Integer</Option>
          <Option key="decimal">Decimal</Option>
          <Option key="both">Both</Option>
        </Select>
      ),
    },
    {
      title: "Range",
      dataIndex: "range",
      key: "range",
      className: "w-40",
      render: (text, record, index) => (
        <Select
          name="range"
          className="text-sm text-textMedium "
          value={range[index] === "" ? "Select" : range[index]}
          placeholder="Select"
          onChange={(e) => {
            let newArray = [...range];
            if (newArray[index] === undefined) {
              newArray.push(e);
            } else {
              newArray[index] = e;
            }
            setRange(newArray);
          }}
        >
          <Option key="custom">Custom</Option>
          <Option key="question">Question</Option>
        </Select>
      ),
    },
    {
      title: "Set range",
      dataIndex: "setRange",
      key: "setRange",
      render: (text, record, index) => (
        <>
          {range[index] === "question" && (
            <>
              <Select
                name="setRange"
                className="text-sm text-textMedium float-left w-24"
                placeholder="Operator"
                value={operator[index] === "" ? "Select" : operator[index]}
                onChange={(e) => {
                  let newArray = [...operator];
                  if (newArray[index] === undefined) {
                    newArray.push(e);
                  } else {
                    newArray[index] = e;
                  }
                  setOperator(newArray);
                }}
              >
                <Option key="greater">&gt;</Option>
                <Option key="less">&lt;</Option>
                <Option key="greaterEqual">&gt;=</Option>
                <Option key="lesserEqual">&lt;=</Option>
              </Select>

              <InputNumber
                className="w-14 py-0 ml-2 h-8 float-left rounded-md"
                placeholder="00"
                onChange={(e) => handleOperatorValue(e, index)}
                value={operatorValue[index] === "" ? "" : operatorValue[index]}
                min={0}
                max={props.allValues.length}
              />
              <Tooltip
                placement="topLeft"
                title="Please choose either a numerical or percentage question with a single label. If multiple labels are present, the validation will be added to the first label only"
                arrowPointAtCenter
              >
                <InfoCircleOutlined className="float-left ml-2" />
              </Tooltip>
              {enableTooltip[index] && (
                <span className="text-red float-right">
                  Enter a numerical/percentage question number
                </span>
              )}
            </>
          )}
          {range[index] === "custom" && (
            <>
              <span>From</span>
              <InputNumber
                className="w-14 rounded-md mx-2"
                placeholder="00"
                onChange={(e) => handleRangeFrom(e, index)}
                min={0}
                value={rangeFrom[index] === "" ? "" : rangeFrom[index]}
              />
              <span>To</span>
              <InputNumber
                className="w-14 rounded-md ml-2"
                placeholder="00"
                value={rangeTo[index] === "" ? "" : rangeTo[index]}
                onChange={(e) => handleRangeTo(e, index)}
                min={0}
              />
            </>
          )}
        </>
      ),
    },
  ];

  let data = [];

  const dataSource = () => {
    props.optionValue.map((optionValue, index) =>
      data.push({ key: index + 1, label: optionValue.option })
    );
    return data;
  };
  const initialValue =
    props?.validationObject &&
    props?.validationObject?.length !== 0 &&
    props?.validationObject[props.questionNumber] !== null
      ? props?.validationObject[props.questionNumber]
      : {
          questionMandatory: true,
        };

  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Validation</span>}
        width={720}
        onClose={onCancel}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        footer={
          <>
            <div style={{ float: "left", width: "70%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
            <div style={{ float: "left", width: "30%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={onClose}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Add
              </Button>
            </div>
          </>
        }
      >
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <Form.Item>
            <Table
              dataSource={dataSource()}
              columns={columns}
              pagination={false}
              rowClassName={(record, index) => "text-sm text-textMedium"}
            />
          </Form.Item>
          <Form.Item
            name="questionMandatory"
            value="questionMandatory"
            valuePropName="checked"
          >
            <Checkbox className="text-textMedium text-sm">
              This question is mandatory
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="addFileOption"
            value="addFileOption"
            valuePropName="checked"
          >
            <Checkbox
              className="text-textMedium text-sm"
              onChange={(e) => setUploadOption(e.target.checked)}
            >
              Add upload file option
            </Checkbox>
          </Form.Item>
          <Form.Item value="fileDetails">
            {uploadOption && (
              <FileUpload
                checked={true}
                textInput={value !== null ? value?.fileLimit : null}
                fileNumbers={
                  value !== null && value?.fileNumbers
                    ? value?.fileNumbers[0]
                    : null
                }
              />
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default NumericalValidation;
