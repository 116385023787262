import { gql } from "@apollo/client";

export const CREATE_TECHNICIAN = gql`
  mutation TECHNICIAN(
    $respondent_id: bigint
    $firstName: String
    $lastName: String    
    $status: String
    $title: String
    $phone: String
    $email: String
    $password: String
    $year_of_birth: timestamptz
    $additional_fields: jsonb
    $metadata: jsonb
    $state: String
    $city: String
    $pincode: Int
    $gender: String
    $is_verified: Boolean
  ) {
    insert_user_one(
      object: {
        respondent_id: $respondent_id
        f_name: $firstName
        l_name: $lastName
        status: $status
        title: $title
        phone_number: $phone
        email: $email
        gender: $gender
        password: $password
        role_id: 6
        year_of_birth: $year_of_birth
        additional_data: $additional_fields
        meta_date: $metadata
        pincode: $pincode
        city: $city
        state: $state
        member_type_id: 2
        is_verified: $is_verified
      }
    ) {
      id
      meta_date
    }
  }
`;

export const ADD_APPROVAL = gql`
  mutation MyMutation(
    $old_info: jsonb
    $updated_info: jsonb
    $updated_by: Int
    $user_id: Int
  ) {
    insert_approval(
      objects: {
        old_info: $old_info
        updated_info: $updated_info
        updated_by: $updated_by
        user_id: $user_id
      }
    ) {
      affected_rows
    }
  }
`;
// export const GET_TECHPROFESSION = gql`
//   query TechProfession {
//     tech_profession {
//       id
//       tech_profession
//     }
//   }
// `;
// export const GET_TechCategory = gql`
//  query TechCategory {
//   tech_category(where: {tech_profession: {id: {_eq: 1}}}) {
//     id
//     sub_category_1
//     tech_id
//   }
// }
// `;

export const GET_TECHPROFESSION = gql`
  query TechProfession {
    tech_profession {
      id
      tech_profession
      tech_categories_1 {
        id
        sub_category_1
        tech_id
      }
      tech_category_2 {
        id
        sub_category_2
        tech_id
      }
    }
  }
`;

export const GET_TechCategory = gql`
  query TechCategory($techProfessionId: Int!) {
    tech_category(where: { tech_profession: { id: { _eq: $techProfessionId } } }) {
      id
      sub_category_1
      tech_id
    }
  }
`;

export const GET_TechCategory2 = gql`
  query TechCategory2($techProfessionId: Int!) {
    tech_category_2(where: {tech_profession: {id: {_eq: $techProfessionId}}}) {
      id
      sub_category_2
      tech_id
    }
  }
`