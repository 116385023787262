import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Button, Divider, Form, Input, notification, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { client, user } from "../../../atoms";
import Loading from "../../../components/Loading";
import {
  GET_CLIENTS_DETAILS,
  GET_CLIENTS_META,
  INSERT_CLIENT1,
  ONBOARD_USER,
  UPDATE_CLIENT,
  VALIDATE_CLIENT_MAIL,
  ADD_APPROVAL,
} from "../graphql.js";
import jwtDecode from "jwt-decode";

const { Option } = Select;

// let country = [];
// let billingCycle = [];
// let category = [];

function ClientDetails(props) {
  const setValues = useSetRecoilState(client);
  const setUserId = useSetRecoilState(user);
  const [addApproval] = useMutation(ADD_APPROVAL);
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation();
  const [dropDownData, setDropDownData] = useState({
    country: [],
    billingCycle: [],
    category: [],
  });
  const [clientData, setClientData] = useState({});
  const [clientDataCopy, setClientDataCopy] = useState({});
  const [clientUserId, setClientUserId] = useState(null);
  const [role, setRole] = useState(null);
  const [id, setId] = useState(null);
  const [user_id, setUser_id] = useState(null);
  let mobile = /^[0-9()+-\s]{0,}$/;
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      let decodedId = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["X-Hasura-User-Id"];
      setId(decodedId);
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const initialValues = {
    selectPOC: [""],
  };
  const {
    // eslint-disable-next-line
    data: clientMetaData,
    loading: loadingClientMeta,
    error: clientError,
  } = useQuery(GET_CLIENTS_META, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data) {
        let object = JSON.parse(JSON.stringify(data));
        setDropDownData({
          country: object.country,
          billingCycle: object.billing_cycle,
          category: object.client_category,
        });
      }
      if (clientError) {
        console.log(clientError);
      }
    },
  });

  const { error: clientDataError, loading: loadingClientLoading } = useQuery(
    GET_CLIENTS_DETAILS,
    {
      skip: !props.clientId,
      fetchPolicy: "network-only",
      variables: {
        client_id: props.clientId,
      },
      onCompleted(data) {
        if (data) {
          let clients = JSON.parse(JSON.stringify(data?.client));
          let client = clients[0];
          if (client) {
            setClientUserId(client.user_id);
            form.setFieldsValue({
              company_name: client.company_name,
              address: client.address,
              billing_cycle: client.billing_cycle,
              country: client.country,
              category: client.category,
              email: client.user.email.toLowerCase(),
              phone_number: client.user.phone_number,
              data:
                client.client_custom_fields &&
                client.client_custom_fields.length
                  ? client.client_custom_fields
                  : [],
            });
            setClientDataCopy({
              company_name: client.company_name,
              address: client.address,
              billing_cycle: client.billing_cycle,
              country: client.country,
              category: client.category,
              email: client.user.email.toLowerCase(),
              phone_number: client.user.phone_number,
              data:
                client.client_custom_fields &&
                client.client_custom_fields.length
                  ? client.client_custom_fields
                  : [],
            });
            setUser_id(client.user.id);
          }
        }
        if (clientDataError) {
          console.log(clientError);
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  const onFinish = (values) => {
    if (values.data === undefined) {
      values.data = [];
    }
    validateEmail({
      variables: {
        email: values.email.toLowerCase(),
        client_id: props.clientId ? [props.clientId] : [],
        user_id: clientUserId ? [clientUserId] : [],
      },
    });

    let variables = {
      address: values.address,
      billing_cycle: values.billing_cycle,
      country: values.country,
      category: values.category,
      company_name: values.company_name,
      email: values.email.toLowerCase(),
      phone_number: values.phone_number + "",
      is_verified: role === "super_admin" ? true : false,
      role_id: 5,
      data: values.data,
    };
    setClientData(variables);
  };

  const [onboardUser] = useMutation(ONBOARD_USER, {
    onCompleted: (data) => {
      if (data.onbord_user) {
        notification.success({ message: data.onbord_user.message });
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  const [createClient, { loading: loadingInsertClient }] = useMutation(
    INSERT_CLIENT1,
    {
      onError(error) {
        error.graphQLErrors?.forEach((error) => {
          notification.error({
            message: "Error!",
            description: "Something went wrong",
          });
        });
      },
      async onCompleted(data) {
        if (!data.insert_client.affected_rows) {
          return;
        }
        onboardUser({
          variables: {
            user_id: data?.insert_client?.returning[0]?.user_id,
          },
        });
        history.push(
          `${
            location.pathname.includes("/edit-client")
              ? `/edit-client/${props.clientId}`
              : `/create-client/${data?.insert_client?.returning[0]?.id}`
          }/add-poc`
        );
        setValues(() => data?.insert_client?.returning[0]?.id);
        setUserId(() => data?.insert_client?.returning[0]?.user_id);
        props.onComplete("2");
      },
    }
  );

  const [editClient, { loading: loadingUpdateClient }] = useMutation(
    UPDATE_CLIENT,
    {
      onError(error) {
        error.graphQLErrors?.forEach((error) => {
          notification.error({
            message: "Error!",
            description: "Something went wrong",
          });
        });
      },
      onCompleted(data) {
        history.push(
          `${
            location.pathname.includes("/edit-client")
              ? `/edit-client/${props.clientId}`
              : `/create-client/${props.clientId}`
          }/add-poc`
        );
        setValues(() => props.clientId);
        setUserId(() => data?.update_client?.returning[0]?.user_id);
        props.onComplete("2");
      },
    }
  );

  const [validateEmail, { loading: loadingValidateEmail }] = useLazyQuery(
    VALIDATE_CLIENT_MAIL,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (
          (data.client && data.client.length) ||
          (data.user && data.user.length)
        ) {
          notification.error({
            message: "Email already exist for another user",
          });
          return;
        }
        if (props.clientId) {
          if (role !== "super_admin") {
            let approvalPayload = {
              old_info: {},
              updated_info: {},
            };
            approvalPayload.old_info = {
              client: {
                ...clientDataCopy,
              },
              user: {},
            };
            approvalPayload.updated_info = {
              client: {
                ...clientData,
              },
              user: {},
            };
            if (
              approvalPayload && // 👈 null and undefined check
              Object.keys(approvalPayload).length > 0 &&
              Object.getPrototypeOf(approvalPayload) === Object.prototype
            ) {
              approvalPayload.user_id = parseInt(user_id);
              approvalPayload.updated_by = parseInt(id);
            }
            addApproval({
              variables: {
                old_info: approvalPayload.old_info,
                updated_info: approvalPayload.updated_info,
                user_id: approvalPayload.user_id,
                updated_by: approvalPayload.updated_by,
              },
            })
              .then(() => {
                notification.success({
                  message: "Updates scheduled for approval",
                });
                history.push(
                  `${
                    location.pathname.includes("/edit-client")
                      ? `/edit-client/${props.clientId}`
                      : `/create-client/${data?.insert_client?.returning[0]?.id}`
                  }/add-poc`
                );
              })
              .catch(() => {
                notification.error({
                  message: "Error",
                  description: "An error occurred while updating consumer.",
                });
              });
          } else {
            editClient({
              variables: {
                ...clientData,
                client_id: props.clientId,
                data: clientData.data.map((field) => {
                  field.client_data_id = props.clientId;
                  return field;
                }),
              },
            });
          }
        } else {
          createClient({
            variables: clientData,
          });
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  const triggerCSVDownload = () => {
    document.getElementById("addCustomVal").click();
  };

  return (
    <div>
      {(loadingClientLoading ||
        loadingClientMeta ||
        loadingInsertClient ||
        loadingUpdateClient ||
        loadingValidateEmail) && <Loading />}
      <Form
        name="projectDetails_form"
        layout="vertical"
        requiredMark={false}
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight">Company name</span>
            }
            name="company_name"
            rules={[
              {
                required: true,
                message: "Please enter Company name",
              },
            ]}
            className="float-left "
            style={{ width: "49%" }}
          >
            <Input type="text" />
          </Form.Item>

          <Form.Item
            label={<span className="text-base text-textLight">Country</span>}
            name="country"
            rules={[
              {
                required: true,
                message: "Please select country",
              },
            ]}
            className="float-left ml-4"
            style={{ width: "49%" }}
          >
            <Select placeholder="Select" showSearch optionFilterProp="children">
              {" "}
              {dropDownData.country.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            label={<span className="text-base text-textLight">Category</span>}
            name="category"
            rules={[
              {
                required: true,
                message: "Please selct Category",
              },
            ]}
            className="float-left "
            style={{ width: "49%" }}
          >
            <Select placeholder="Select" showSearch optionFilterProp="children">
              {dropDownData.category.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight">Billing cycle</span>
            }
            name="billing_cycle"
            rules={[
              {
                required: true,
                message: "Please select the billing cycle",
              },
            ]}
            className="float-left ml-4"
            style={{ width: "49%" }}
          >
            <Select placeholder="Select" showSearch optionFilterProp="children">
              {" "}
              {dropDownData.billingCycle.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.values}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight">General phone</span>
            }
            name="phone_number"
            rules={[
              { required: true, message: "Required" },
              () => ({
                validator(_, value) {
                  if (!mobile.test(value)) {
                    return Promise.reject("Please enter a valid phone number");
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            // rules={[
            //   {
            //     required: true,
            //     message: "Please enter phone",
            //   },
            // ]}
            className="float-left "
            style={{ width: "49%" }}
          >
            <Input style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight">General email</span>
            }
            name="email"
            type="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
            className="float-left ml-4"
            style={{ width: "49%" }}
          >
            <Input type="text" />
          </Form.Item>
        </Form.Item>

        <Form.Item>
          <Form.Item
            label={
              <span className="text-base text-textLight">Complete address</span>
            }
            rules={[
              {
                required: true,
                message: "Please enter the address",
              },
            ]}
            name="address"
          >
            <TextArea
              showCount
              maxLength={500}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          {/* <Form.Item>
          <div style={{ display: 'block' }} className="w-100">
            <p className="text-textExtraLight block float-right">
              {"Character count  " + textLength + "/500"}
            </p>
          </div>
        </Form.Item> */}
        </Form.Item>

        <Form.List name="data">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key} style={{ display: "block" }}>
                  <div className="block">
                    <span className="text-base text-textLight">
                      Custom field {name + 1}
                    </span>
                    <span
                      className="float-right text-base text-red cursor-pointer"
                      onClick={() => remove(name)}
                    >
                      Remove
                    </span>
                  </div>
                  <Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">Label</span>
                      }
                      name={[name, "label"]}
                      fieldKey={[fieldKey, "label"]}
                      rules={[{ required: true, message: "Enter the lable" }]}
                      style={{ width: "49%", float: "left" }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={
                        <span className="text-base text-textLight">
                          Information
                        </span>
                      }
                      name={[name, "value"]}
                      fieldKey={[fieldKey, "value"]}
                      rules={[
                        { required: true, message: "Enter the information" },
                      ]}
                      style={{ width: "49%", float: "right" }}
                    >
                      <Input />
                    </Form.Item>
                  </Form.Item>
                  <MinusCircleOutlined
                    className="invisible"
                    onClick={() => remove(name)}
                  />
                </div>
              ))}
              <Form.Item className="invisible">
                <Button
                  id="addCustomVal"
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Divider />

        <Form.Item>
          <Button
            type="link"
            className="text-base float-left "
            htmlType="button"
            onClick={() => triggerCSVDownload()}
          >
            + Add a new field
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-sm w-28 float-right"
            onClick={() => {
              //  addValues();
            }}
          >
            Next
          </Button>
          {/* <Button
          htmlType="button"
          className="text-base mx-5 rounded-md text-orange"
        >
          Save as draft
        </Button> */}
          <Button
            type="link"
            className="text-base float-right"
            htmlType="button"
            onClick={() => {
              history.push("/clientList");
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
export default ClientDetails;
