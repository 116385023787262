import { Form, Checkbox, Row, Col, InputNumber } from "antd";

const FileUpload = (props) => {
  const onChange = (e) => {
    props.handleFileInput(e.target.checked, props.index, props.optionIndex);
  };

  const onEnableChange = (e) => {
    props.handleEnablechange(e.target.checked, props.index, props.optionIndex);
  };

  return (
    <div className="w-7/12 border border-borderGray float-left ml-20 p-4 mb-8">
      <Form.Item
        name={[props.option.name, "fileTypesAllowed"]}
        label={
          <div style={{ width: props.checked === true ? `22rem` : `32rem` }}>
            <span className="text-sm text-textMedium float-left">
              File types allowed
            </span>
          </div>
        }
        colon={false}
        valuePropName="value"
      >
        <Checkbox.Group style={{ width: "100%" }} className="mt-2 block">
          <Row gutter={[12, 12]}>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="pdf">
                PDF
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="doc/docx">
                DOC/DOCX
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="png">
                PNG
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="xls/xlsx">
                XLS/XLSX
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="jpg/jpeg">
                JPEG/JPG
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox className="text-sm text-textMedium" value="audio/video">
                AUDIO/VIDEO
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <Form.Item
        name={[props.option.name, "fileSize"]}
        colon={false}
        label={
          <div style={{ width: props.checked === true ? `22rem` : `32rem` }}>
            <span className="text-sm text-textMedium float-left">
              File Limit
            </span>
            <span className="text-sm text-textLight float-right">
              (Max. 16MB)
            </span>
          </div>
        }
      >
        <InputNumber
          placeholder="Limit value"
          className="mt-2 block"
          min={1}
          max={16}
          style={{ width: "100%" }}
        />
      </Form.Item>

      <Form.Item
        className="text-sm text-textMedium"
        name={[props.option.name, "fileNumbers"]}
        valuePropName="value"
        label={
          <div style={{ width: props.checked === true ? `22rem` : `32rem` }}>
            <span className="text-sm text-textMedium float-left">
              File Numbers
            </span>
          </div>
        }
        colon={false}
      >
        <Checkbox.Group
          style={{ width: "100%" }}
          className={"mt-4 text-sm text-textMedium"}
        >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Checkbox
                className="text-sm text-textMedium"
                value="noLimit"
                disabled={props.textInput === true ? true : false}
                onChange={onEnableChange}
              >
                No limit
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                value="Limit to"
                className="text-sm text-textMedium float-left"
                disabled={props.enableInput === true ? true : false}
                onChange={onChange}
              >
                Limit to
              </Checkbox>
              {props.textInput === true ? (
                <Form.Item name={[props.option.name, "no_of_files"]}>
                  <InputNumber
                    placeholder="00"
                    className="h-8 py-0 px-1.5 ml-2 w-16"
                  />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
        </Checkbox.Group>
      </Form.Item>
    </div>
  );
};
export default FileUpload;
