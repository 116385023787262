import React, { useState, useEffect } from "react";
import { Divider, Button } from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import { RESPONSE_ANSWERS_ONLINE, GET_DOWNLOAD_URL } from "../graphql";

function ViewResponseQA() {
  const { surveyID, memberID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [answerArray, setAnswerArray] = useState([]);
  const [fileUploadArray, setFileUploadArray] = useState([]);
  const [urlArray, setUrlArray] = useState([]);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);

  const { data: responseAnswers } = useQuery(RESPONSE_ANSWERS_ONLINE, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(surveyID),
      member_id: parseInt(memberID),
    },
  });
  const length = responseAnswers?.survey_by_pk?.questions?.length;

  const handleDownload = (originalName) => {
    const fileDetails = originalName.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];

    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileKey);
      document.body.appendChild(link);
      link.click();
    });
  };
  useEffect(() => {
    let fileUpload = [...fileUploadArray];
    let urlSet = [...urlArray];
    if (responseAnswers?.survey_by_pk) {
      let copyAnswer = [];
      let object = JSON.parse(
        JSON.stringify(responseAnswers?.survey_by_pk?.survey_x_members)
      );
      object.forEach((item, index) => {
        item.memberName =
          item?.user?.l_name !== null
            ? item?.user?.f_name + " " + item?.user?.l_name
            : item?.user?.f_name;
      });
      let answers = JSON.parse(
        JSON.stringify(responseAnswers?.survey_by_pk?.questions)
      );

      answers.forEach((item, index) => {
        let ans = [];
        let files = [];
        let urlArray = [];
        if (item.answers[0].answer === "") {
          urlArray = item.answers[0].file_url.split(",");
          urlSet[index] = urlArray;
          item.answers[0].more_info.forEach((info) => {
            if (info.type === "Percentage") {
              for (let [key, value] of Object.entries(info)) {
                if (key !== "type") {
                  value === ""
                    ? ans.push(key)
                    : ans.push(key + " - " + value + "%");
                }
              }
            } else if (info.type === "File upload") {
              let i = 0;
              let arr = [];
              for (let [key, value] of Object.entries(info)) {
                let newCopy = [];
                if (key !== "type") {
                  ans.push(key);
                  let res = value.split(",");
                  res.forEach((val, i) => {
                    newCopy.push(val);
                  });
                  arr[i] = newCopy;
                }
              }
              files.push(arr);
            } else {
              for (let [key, value] of Object.entries(info)) {
                if (key !== "type") {
                  value === "" ? ans.push(key) : ans.push(key + " - " + value);
                }
              }
            }
          });
          fileUpload[index] = files;
          copyAnswer.push(ans);
        } else {
          let result = item.answers[0].answer.split(",");
          // eslint-disable-next-line
          result.map((res) => {
            ans.push(res);
          });
          urlSet[index] = [];
          fileUpload[index] = [];
          copyAnswer.push(ans);
        }
      });

      setUrlArray(urlSet);
      setFileUploadArray(fileUpload);
      setAnswerArray(copyAnswer);
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [responseAnswers]);

  return (
    <>
      <p className="text-base text-textLight absolute top-52">
        {responseAnswers?.survey_by_pk?.name} | {tableData[0]?.memberName}
      </p>
      <div
        style={{ padding: "24px" }}
        className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full mt-32 shadow-lg rounded-md"
      >
        <p className="text-xl text-textDark">Survey Response</p>
        {responseAnswers?.survey_by_pk?.survey_x_members[0]?.state ===
          "completed" && <p className="text-green text-base">Completed</p>}
        {responseAnswers?.survey_by_pk?.survey_x_members[0]?.state ===
          "screened" && <p className="text-blue text-base">Screened</p>}
        <Divider />
        {responseAnswers?.survey_by_pk?.questions?.map((data, index) => {
          return (
            <div>
              <p className="text-base text-textLight">
                Q{index + 1}. {data?.question}
              </p>
              <div className="space-y-4">
                {answerArray[index] &&
                  answerArray[index]?.length !== 0 &&
                  answerArray[index].map((answer, i) => {
                    return (
                      <div className="flow-root ">
                        <p
                          className={
                            index + 1 === length &&
                            responseAnswers?.survey_by_pk?.survey_x_members[0]
                              ?.state === "screened"
                              ? "text-blue text-base pl-8 "
                              : "text-base text-textMedium pl-8 "
                          }
                        >
                          {answer}
                        </p>
                        <p className="float-left">
                          {data?.question_type?.name === "File upload" &&
                            fileUploadArray[index] &&
                            fileUploadArray[index].length !== 0 &&
                            fileUploadArray[index][i].map((value, j) => {
                              return value.map((item) => {
                                return (
                                  <p className="float-left">
                                    <span className="pl-8">
                                      {item.split("/").pop()}{" "}
                                    </span>
                                    <Button
                                      type="link"
                                      className=" pr-8 text-orange"
                                      onClick={() => {
                                        handleDownload(item);
                                      }}
                                    >
                                      Download
                                    </Button>
                                  </p>
                                );
                              });
                            })}
                        </p>
                      </div>
                    );
                  })}
              </div>
              {data?.question_type?.name !== "File upload" &&
                urlArray[index] &&
                urlArray[index].length !== 0 &&
                // eslint-disable-next-line
                urlArray[index].map((item, j) => {
                  if (item !== "") {
                    return (
                      <p>
                        <span className="pl-8">{item.split("/").pop()} </span>
                        <Button
                          type="link"
                          className=" pr-8 text-orange"
                          onClick={() => {
                            handleDownload(item);
                          }}
                        >
                          Download
                        </Button>
                      </p>
                    );
                  }
                })}
              <Divider />
            </div>
          );
        })}
      </div>
    </>
  );
}
export default ViewResponseQA;
