import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Space,
  Input,
  Modal,
  Select,
  Tag,
} from "antd";
import { useHistory, useParams } from "react-router";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import verticalDots from "../../../assets/verticalDots.svg";
import { GET_MEMBER_RESPONSE, UPDATE_STATUS } from "../graphql";
import { formatDateTime } from "../../../utils/dateTime";
import ColumnEditor from "../../../components/ColumnEditor";

const { Option } = Select;

function ViewDetailedResponse() {
  const { surveyID, memberID, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [status, setStatus] = useState();
  const history = useHistory();
  const savedviewDetailProjectPrf = localStorage.getItem('viewDetailProjectPrf');
  const viewDetailProjectPrfinitial = savedviewDetailProjectPrf
    ? JSON.parse(savedviewDetailProjectPrf)
    : {
      "updated_at": { enabled: true, label: "Submitted On" },
      "status": { enabled: true, label: "Status" },
    }
  const [columnStatus, setColStatus] = useState(viewDetailProjectPrfinitial);
  let searchInput;

  const { data: membersResponses } = useQuery(GET_MEMBER_RESPONSE, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(surveyID),
      member_id: parseInt(memberID),
    },
  });
  const [updateStatus] = useMutation(UPDATE_STATUS, {
    refetchQueries: [
      {
        query: GET_MEMBER_RESPONSE,
        variables: {
          id: parseInt(surveyID),
          member_id: parseInt(memberID),
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  useEffect(() => {
    localStorage.setItem('viewDetailProjectPrf', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    if (membersResponses) {
      let object = JSON.parse(JSON.stringify(membersResponses?.user));
      object.forEach((item) => {
        item.memberName = item.f_name + " " + item.l_name;
      });
      setTableData(object);
    }
  }, [membersResponses]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = (index) => {
    updateStatus({
      variables: {
        id: membersResponses?.patient[index].id,
        status: status,
      },
    });
    setIsModalVisible(false);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "RESPONSE ID",
      dataIndex: "id",
      className: "text-textDark",
      ...getColumnSearchProps("id"),
    },
    {
      title: "SUBMITTED ON",
      dataIndex: "updated_at",
      className: columnStatus?.updated_at?.enabled ? "table-cell" : "hidden",
      render: (record) => (
        <span className="text-base">{formatDateTime(record)}</span>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Screened",
          value: "screened",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (record) => (
        <>
          {record === "screened" && (
            <span className={"text-blue text-base"}>Screened</span>
          )}
          {record === "completed" && (
            <span className={"text-green text-base"}>Completed</span>
          )}
        </>
      ),
    },

    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          <Dropdown
            overlay={menu({
              index: index,
              record: record,
              handleCancel: handleCancel,
              handleOk: handleOk,
              showModal: showModal,
              isModalVisible: isModalVisible,
            })}
            placement="bottomRight"
            trigger={["click"]}
          >
            <Button type="link" size="large">
              <img src={verticalDots} alt="vertical_Dots" />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];
  const menu = (props) => (
    <>
      <Modal
        title="Edit status"
        visible={props.isModalVisible}
        okText={<span>Update</span>}
        onOk={() => props.handleOk(props.index)}
        onCancel={props.handleCancel}
      >
        <div className="flex justify-start items-center">
          <div className="mr-2">Current status</div>
          <div className="ml-2 w-1/2">
            <Select
              className="w-full"
              onChange={(e) => {
                setStatus(e);
              }}
            >
              <Option key="completed">
                <Tag color="green">Completed</Tag>
              </Option>
              <Option key="screened">
                <Tag color="blue">Screened</Tag>
              </Option>
            </Select>
          </div>
        </div>
      </Modal>
      <Menu className="rounded-md bg-gray">
        <Menu.Item key="1" onClick={props.showModal}>
          <span className="text-textLight hover:text-orange">Edit status</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <span
            className="text-textLight hover:text-orange"
            onClick={() => {
              history.push(
                `/viewSurvey/members/project/${projectID}/survey/${surveyID}/PRF/viewResponseAnswers/member/${memberID}/patient/${props.record.id}`
              );
            }}
          >
            View response
          </span>
        </Menu.Item>
      </Menu>
    </>
  );
  return (
    <div
      style={{ padding: "24px" }}
      className="reviewSurvey bg-white float-left border-r border-textExtraLight w-full mt-32 shadow-lg rounded-md"
    >
      <div className=" flex justify-between w-full">
        <p className="text-xl text-textDark">{tableData[0]?.memberName}</p>
        <div>
          <ColumnEditor columns={columnStatus} onSave={(column) => {
            setColStatus(column)
          }} />
        </div>
      </div>
      <Table
        columns={columns}
        style={{ overflow: "auto" }}
        dataSource={membersResponses?.patient}
        rowKey={(record) => record.id}
        rowClassName={(record, index) =>
          "text-base text-textMedium border-t border-l border-r border-textLight"
        }
        pagination={false}
      />
    </div>
  );
}
export default ViewDetailedResponse;
