import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const SpecialityModalPop = (props) => {
    const [selectedData, setSelectedData] = useState(props.selectedDataSpec);

    useEffect(() => {
        setSelectedData(props.selectedDataSpec);
    }, [props.selectedDataSpec]);

    // useEffect(() => {
    //     if (props.visibleSpec) {
    //         setSelectedData([]);
    //     }
    // }, [props.visibleSpec]);
    
    const handleModalOkSpec = () => {
        props.onOkSpec(selectedData);
    };

    const handleResetModalSpec = () => {
        props.handleResetSpec();
        setSelectedData(props.initialSelectedDataSpec);
    };

    return (
        <Modal
            title={props.titleSpec}
            visible={props.visibleSpec}
            onOk={handleModalOkSpec}
            onCancel={props.onCancelSpec}
            footer={
                <div className="flex justify-between modalfooterStyle">
                    <Button type="primary" onClick={handleModalOkSpec} disabled={props.isSubmitDisabledSpec}>
                        Search
                    </Button>
                    <Button key="reset" onClick={handleResetModalSpec} className="mr-2">
                        Reset
                    </Button>
                </div>
            }
            className="modalStyle"
            bodyStyle={{ maxHeight: '50vh', overflowY: 'auto' }}
        >
            <Input
                placeholder="Search..."
                value={props.searchQuerySpec}
                onChange={props.handleSearchSpec}
                prefix={<SearchOutlined className="search-icon" />}
                className="mb-3"
            />
            <Checkbox.Group
                onChange={props.handleCheckboxChangeSpec}
                value={selectedData}
                defaultValue={props.initialSelectedDataSpec}
            >
                {props.filteredCheckboxDataSpec.map((data) => (
                    <div key={data}>
                        <Checkbox value={data}>{data}</Checkbox>
                    </div>
                ))}
            </Checkbox.Group>
        </Modal>
    );
};

export default SpecialityModalPop;