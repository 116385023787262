import { Tabs, Layout } from "antd";
import LocationHierarchy from "../../../components/locationHierarchy";
import Overview from "./overview/overview";
import Questionnaire from "./questionnaire/index";
import AssignMembers from "./assignMembers";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_QUESTIONS, SURVEY_DATES } from "../prf/questionnaire/graphql";

const { Content } = Layout;
const { TabPane } = Tabs;
function CreateSurvey() {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tabPane = ["overview", "questionnaire", "assignmembers"];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [question, setQuestion] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState();
  // eslint-disable-next-line
  const { dataSource1, refetch: questionRefetch } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setQuestion(data.questions);
    },
  });
  const [enableSendTo, setEnableSendTo] = useState(false);
  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  useEffect(() => {
    if (location.pathname.split("/")[5] === "questionnaire") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[5] === "assignmembers") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[5] === "overview") {
      setActiveTabPane("1");
    }

    if (question.length !== 0) {
      setEnableSendTo(true);
    }
  }, [location.pathname, question]);

  useEffect(() => {
    questionRefetch();
    surveyDatesRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setStatus(surveyDates?.survey[0]?.status);
    }
  }, [surveyDates]);

  const handleEnable = (value) => {
    setEnableSendTo(value);
  };
  const handleActiveTabPane = (key) => {
    if (parseInt(key) === 2) {
      if (moment().isSameOrAfter(startDate)) {
        location.pathname.split("/")[3] === "create-survey"
          ? history.push(
              `/project/${projectID}/create-survey/${surveyType}/${
                tabPane[key - 1]
              }/survey/${surveyID}/preview`
            )
          : history.push(
              `/project/${projectID}/edit-survey/${surveyType}/${
                tabPane[key - 1]
              }/survey/${surveyID}/preview`
            );
      } else {
        location.pathname.split("/")[3] === "create-survey"
          ? history.push(
              `/project/${projectID}/create-survey/${surveyType}/${
                tabPane[key - 1]
              }/survey/${surveyID}`
            )
          : history.push(
              `/project/${projectID}/edit-survey/${surveyType}/${
                tabPane[key - 1]
              }/survey/${surveyID}`
            );
      }
    } else {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/${
              tabPane[key - 1]
            }/survey/${surveyID}`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/${
              tabPane[key - 1]
            }/survey/${surveyID}`
          );
    }

    setActiveTabPane(key);
  };
  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10">
        <LocationHierarchy id={projectID} />
        <div className="absolute text-base text-gray-700 pt-2">
          {location.pathname.split("/")[3] === "create-survey" && (
            <p>Create survey</p>
          )}
          {location.pathname.split("/")[3] === "edit-survey" && (
            <p>Edit survey</p>
          )}
        </div>

        <div
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            tabPosition="left"
            size="large"
            className="text-textExtraLight"
            tabBarStyle={{ width: "200px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane tab="Overview" key="1">
              <Overview startDate={startDate} status={status} />
            </TabPane>
            <TabPane
              tab="Questionnaire"
              key="2"
              disabled={surveyID ? false : true}
            >
              <Questionnaire
                handleEnable={handleEnable}
                startDate={startDate}
                status={status}
              />
            </TabPane>
            <TabPane
              tab="Send to"
              key="3"
              disabled={surveyID ? (enableSendTo ? false : true) : true}
            >
              <AssignMembers startDate={startDate} status={status} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}

export default CreateSurvey;
