import React, { useState, useRef } from "react";
import {
  Row,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Divider,
  Select,
  Checkbox,
  Spin,
} from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_COMPATIBILITY, GET_LANGUAGES } from "./graphql";

const { Option } = Select;

const InterviewerForm = ({
  isVisible,
  setIsVisible,
  users,
  phone,
  setData,
  role,
}) => {
  const btn = useRef(null);
  const optionBtn = useRef(null);
  const removeBtn = useRef(null);
  const [form] = Form.useForm();
  let mobile = /^[0-9]{10}$/;
  const { data: languages, loading: languageLoading } = useQuery(GET_LANGUAGES);
  const { data: compatibility, loading: compatibilityLoading } =
    useQuery(GET_COMPATIBILITY);
  const [checkedValues, setCheckedValues] = useState(['raw', 'partialEmail', 'partialPhone', 'notValidated', 'invalid']);

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues(checkedValues.filter(item => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };
  const onFinish = (values) => {
    const int_privileges = {
      raw: checkedValues.includes('raw'),
      partialEmail: checkedValues.includes('partialEmail'),
      partialPhone: checkedValues.includes('partialPhone'),
      notValidated: checkedValues.includes('notValidated'),
      invalid: checkedValues.includes('invalid'),
    };
    let variables = {
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      additional_fields: values.additional_fields,
      password: "",
      wpm: parseInt(values.typing_speed),
      compatibility: values.compatibility,
      title: values.title,
      is_active: true,
      is_verified: role === "super_admin" ? true : false,
      language: values.language_proficiency,
      meta_date: {
        availability: "Available",
        date_from: null,
        date_to: null,
      },
      int_privileges: int_privileges,
    };
    setData({ ...variables });
    setIsVisible(false);
    form.resetFields([
      "firstName",
      "lastName",
      "phone",
      "email",
      "additional_fields",
      "typing_speed",
      "compatibility",
      "title",
      "language_proficiency",
      "language",
      "int_privileges",
    ]);
  };

  const [language, setLanguage] = useState([]);

  const footer = (
    <Row>
      <div className="w-2/4 h-16 mt-4">
        <Button
          type="link"
          htmlType="submit"
          onClick={() => {
            btn.current.click();
          }}
        >
          Add field
        </Button>
      </div>
      <div className="w-2/4 h-16 mt-4">
        <Button
          type="primary"
          htmlType="submit"
          className="float-right text-base rounded-md"
          onClick={form.submit}
        >
          Add
        </Button>

        <div className="text-textMedium float-right pt-1 pr-6">
          <Button
            type="link"
            htmlType="submit"
            onClick={() => {
              form.resetFields([
                "firstName",
                "lastName",
                "phone",
                "email",
                "additional_fields",
                "typing_speed",
                "compatibility",
                "title",
                "language_proficiency",
                "language",
                "int_privileges",
              ]);
              setIsVisible(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );

  const options = [
    { label: "Read", value: "Read" },
    { label: "Speak", value: "Speak" },
    { label: "Write", value: "Write" },
  ];

  if (languageLoading || compatibilityLoading) {
    return <Spin />;
  }
  return (
    <>
      {" "}
      <Drawer
        title="Add new Interviewer"
        footer={footer}
        width={600}
        visible={isVisible}
        onClose={() => {
          setIsVisible(false);
          form.resetFields([
            "firstName",
            "lastName",
            "phone",
            "email",
            "additional_fields",
            "typing_speed",
            "compatibility",
            "title",
            "language_proficiency",
            "language",
            "int_privileges",
          ]);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          name="admin"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          autoComplete="off"
          requiredMark={false}
          colon={false}
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input title!" }]}
            className="mt-2"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="First name"
            name="firstName"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
            className="mt-6"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last name"
            name="lastName"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
            className="mt-6"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Required" },
              () => ({
                validator(_, value) {
                  if (!mobile.test(value)) {
                    return Promise.reject("Please enter a valid phone number");
                  }
                  if (!value || !phone.includes(value)) {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
            className="mt-6"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Required" },
              { type: "email", message: "Please enter an valid email address" },
              () => ({
                validator(_, value) {
                  if (!value || !users.includes(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "Email ID already used. Please use a different one."
                  );
                },
              }),
            ]}
            className="mt-6"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Project compatibility"
            name="compatibility"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
            className="mt-6"
          >
            <Select showArrow>
              {compatibility?.project_compatibility?.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.project_compatibility_text}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            label="Typing speed"
            name="typing_speed"
            rules={[
              { required: true, message: "Please input typing speed!" },
              () => ({
                validator(_, value) {
                  if (!value || Number.isInteger(parseInt(value))) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please enter a valid number");
                },
              }),
            ]}
            className="mt-6"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Language"
            name="language"
            rules={[{ required: true, message: "Please select a language!" }]}
            className="mt-6"
          >
            <Select
              mode="multiple"
              showArrow
              removeIcon={() => null}
              onChange={(e) => {
                let copy = [];
                if (e.length < language.length) {
                  languages?.language.forEach((language) => {
                    e.forEach((el, index) => {
                      if (el !== language.language_name) {
                        removeBtn.current.click(index);
                      }
                    });
                  });
                } else {
                  e.forEach((el) => {
                    copy.push(el);
                  });
                  optionBtn.current.click();
                  setLanguage(copy);
                }
              }}
              value={language}
            >
              {languages?.language?.map((language) => {
                return (
                  <Option value={language.language_name} key={language.id}>
                    {language.language_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.List name="language_proficiency">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={fields.key}>
                    <Row justify="space-between mb-4 mt-4">
                      <Col span={16} offset={8}>
                        <Button
                          type="link"
                          onClick={() => {
                            const copy = form.getFieldValue("language");
                            const newList = copy.filter((_, i) => index !== i);
                            form.setFieldsValue({
                              language: newList,
                            });
                            remove(index);
                            setLanguage(newList);
                          }}
                          style={{ color: "red" }}
                          className="float-right"
                          ref={removeBtn}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Col>
                    </Row>
                    <Form.Item
                      {...restField}
                      label={language[index]}
                      name={[name, language[index]]}
                      fieldKey={[fieldKey, language[index]]}
                      className="custom-field"
                    >
                      <Checkbox.Group options={options} />
                    </Form.Item>
                  </div>
                ))}
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                  <Button
                    type="link"
                    onClick={() => add()}
                    ref={optionBtn}
                    style={{ visibility: "hidden" }}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.Item
            label="Edit permission"
            name="int_privileges"
            className="mt-6"
          >
            <Checkbox
              value={'raw'}
              checked={checkedValues.includes('raw')}
              onChange={() => handleCheckboxChange('raw')}
              className="mb-2"
            >
              Raw
            </Checkbox>
            <Checkbox
              value={'partialEmail'}
              checked={checkedValues.includes('partialEmail')}
              onChange={() => handleCheckboxChange('partialEmail')}
              className="mb-2"
            >
              Partial Email
            </Checkbox>
            <Checkbox
              value={'partialPhone'}
              checked={checkedValues.includes('partialPhone')}
              onChange={() => handleCheckboxChange('partialPhone')}
              className="mb-2"
            >
              Partial Phone
            </Checkbox>
            <Checkbox
              value={'notValidated'}
              checked={checkedValues.includes('notValidated')}
              onChange={() => handleCheckboxChange('notValidated')}
              className="mb-2 ml-0"
            >
              Not Validated
            </Checkbox>
            <Checkbox
              value={'invalid'}
              checked={checkedValues.includes('invalid')}
              onChange={() => handleCheckboxChange('invalid')}
              className="mb-2"
            >
              Invalid
            </Checkbox>
          </Form.Item>
          <Divider />
          <Form.List name="additional_fields">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                  <div key={fields.key}>
                    <Row justify="space-between mb-4">
                      <Col>Custom field {index + 1}</Col>
                      <Col>
                        <Button
                          type="link"
                          onClick={() => remove(index)}
                          style={{ color: "red" }}
                        >
                          Remove
                        </Button>
                      </Col>
                    </Row>
                    <Form.Item
                      label="Label"
                      {...restField}
                      name={[name, "label"]}
                      fieldKey={[fieldKey, "label"]}
                      rules={[{ required: true, message: "Missing label" }]}
                      labelCol={{ span: 8 }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Information"
                      {...restField}
                      name={[name, "info"]}
                      fieldKey={[fieldKey, "info"]}
                      rules={[
                        { required: true, message: "Missing information" },
                      ]}
                      labelCol={{ span: 8 }}
                    >
                      <Input />
                    </Form.Item>
                    <Divider className="mb-2" />
                  </div>
                ))}
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                  <Button
                    type="link"
                    ref={btn}
                    onClick={() => add()}
                    style={{ visibility: "hidden" }}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Drawer>
    </>
  );
};

export default InterviewerForm;
