import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Divider,
  notification,
} from "antd";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import {
  CaretUpOutlined,
  CaretDownOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import dragIcon from "../../../../../assets/dragIcon.svg";
import Dropdown from "./dropdown";
import SliderNew from "./sliderNew";
import FileUpload from "./fileUpload";
import DefaultValidation from "./validation/defaultValidation";
import MultipleChoiceValidation from "./validation/multipleChoiceValidation";
import EmailValidation from "./validation/emailValidation";
import DateAndTimeValidation from "./validation/dateAndTimeValidation";
import NumericalValidation from "./validation/numericalValidation";
import PercentageValidation from "./validation/percentageValidation";
import StarDrawerFrom from "./validation/starDrawerForm";
import TextBoxTextValidation from "./validation/textBoxTextValidation";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_QUESTION_TYPES, GET_QUESTIONS, DELETE_QUESTION } from "./graphql";
import { UPDATE_SURVEY_STATUS } from "../graphql";

const { Option } = Select;

function QuestionEdit(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [expand, setExpand] = useState(true);
  const [questionData, setQuestionData] = useState([]);
  const [addTextBox, setAddTextBox] = useState([]);
  const [textInput, setTextInput] = useState([]);
  const [enableInput, setEnableInput] = useState([]);
  const [qTypeValue, setQTypeValue] = useState([]);
  const [qTypeValueNew, setQTypeValueNew] = useState([]);
  const [optionValue, setOptionValue] = useState([]);
  const [validationObject, setValidationObject] = useState([]);
  const [isLogic, setIsLogic] = useState([]);
  const [validation, setValidation] = useState();
  const [questionIndex, setQuestionIndex] = useState();
  const [optionIndex, setOptionIndex] = useState();
  const [ratingLabels, setRatingLabels] = useState([]);
  const [questionID, setQuestionID] = useState([]);
  const [logicArrayValue, setLogicArrayValue] = useState([]);
  const [addRatingDrawer, setAddRatingDrawer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [changeOptionValue, setChangeOptionValue] = useState([]);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  const [delete_question] = useMutation(DELETE_QUESTION);
  const { data: types } = useQuery(GET_QUESTION_TYPES, {
    fetchPolicy: "network-only",
  });
  // eslint-disable-next-line
  const { dataSource1 } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      let questionIDArray = [];
      // eslint-disable-next-line
      data.questions.map((question) => {
        questionIDArray.push(question.id);
      });
      setQuestionID(questionIDArray);
      setQuestionData(data.questions);
    },
  });

  const [form] = Form.useForm();

  const addValidation = (value, index) => {
    let option = [];
    form.getFieldsValue().questions[index].options &&
      form
        .getFieldsValue()
        .questions[index].options.map((options) => option.push(options));
    setValidation(value);
    setVisible(true);
    setOptionValue(option);
    setQuestionIndex(index);
  };
  const handleOption = (value, index, optionIndex) => {
    let newArray = [...changeOptionValue];
    let newArrayoption = newArray[index] ? newArray[index] : [];
    if (newArray[index] === undefined) {
      newArrayoption[optionIndex] = value;
      newArray[index] = newArrayoption;
    } else if (newArray[index] !== undefined) {
      if (newArrayoption[optionIndex] === undefined) {
        newArrayoption[optionIndex] = value;
        newArray[index] = newArrayoption;
      } else {
        newArrayoption[optionIndex] = value;
        newArray[index] = newArrayoption;
      }
    }
    setChangeOptionValue(newArray);
  };

  const handleTextBox = (index, optionIndex) => {
    let newArray = [...addTextBox];
    let newArrayoption = newArray[index] ? newArray[index] : [];
    if (newArray[index] === undefined) {
      newArrayoption[optionIndex] = true;
      newArray[index] = newArrayoption;
    } else if (newArray[index] !== undefined) {
      if (newArrayoption[optionIndex] === undefined) {
        newArrayoption[optionIndex] = true;
        newArray[index] = newArrayoption;
      } else {
        newArrayoption[optionIndex] = true;
        newArray[index] = newArrayoption;
      }
    }

    setAddTextBox(newArray);
  };

  const handleFileInput = (value, index, optionIndex) => {
    let newArray = [...textInput];
    let newArrayoption = newArray[index] ? newArray[index] : [];
    if (newArray[index] === undefined) {
      newArrayoption[optionIndex] = true;
      newArray[index] = newArrayoption;
    } else if (newArray[index] !== undefined) {
      if (newArrayoption[optionIndex] === undefined) {
        newArrayoption[optionIndex] = true;
        newArray[index] = newArrayoption;
      } else {
        newArrayoption[optionIndex] = value;
        newArray[index] = newArrayoption;
      }
    }
    setTextInput(newArray);
  };

  const handleEnablechange = (value, index, optionIndex) => {
    let newArray = [...enableInput];
    let newArrayoption = newArray[index] ? newArray[index] : [];
    if (newArray[index] === undefined) {
      newArrayoption[optionIndex] = true;
      newArray[index] = newArrayoption;
    } else if (newArray[index] !== undefined) {
      if (newArrayoption[optionIndex] === undefined) {
        newArrayoption[optionIndex] = true;
        newArray[index] = newArrayoption;
      } else {
        newArrayoption[optionIndex] = value;
        newArray[index] = newArrayoption;
      }
    }
    setEnableInput(newArray);
  };

  const handleValidationValues = (values, visible, questionIndex) => {
    let newArray = [...validationObject];
    if (newArray[questionIndex] === undefined) {
      newArray[questionIndex] = values;
    } else {
      newArray[questionIndex] = values;
    }
    setValidationObject(newArray);
    setVisible(!visible);
  };

  const handleRatingLabels = (values, visible, optionIndex, index) => {
    let newArray = [...ratingLabels];
    let newArrayoption = newArray[index] ? newArray[index] : [];
    if (newArray[index] === undefined) {
      newArrayoption[optionIndex] = values;
      newArray[index] = newArrayoption;
    } else if (newArray[index] !== undefined) {
      if (newArrayoption[optionIndex] === undefined) {
        newArrayoption[optionIndex] = values;
        newArray[index] = newArrayoption;
      } else {
        newArrayoption[optionIndex] = values;
        newArray[index] = newArrayoption;
      }
    }
    setRatingLabels(newArray);
    setVisible(!visible);
    setAddRatingDrawer(false);
  };

  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue();
      let questionValues = [];
      formValues.questions.map((formValues, i) => {
        let optionValue = [];
        formValues.options !== undefined &&
          formValues.options.map((value, index) => {
            let dropdownValue = [];
            value.dropdown &&
              value.dropdown.forEach((item, i) => {
                dropdownValue[i] = item.dropdownLabel;
              });
            let stepValue = [];
            value.sliderText &&
              value.sliderText.forEach((item, i) => {
                stepValue[i] = item.steps;
              });
            let sliderValue = formValues.questionType === "Slider" && {
              sliderMaximum:
                value.sliderType === "numerical" ? value.sliderMaximum : null,
              sliderMinimum:
                value.sliderType === "numerical" ? value.sliderMinimum : null,
              sliderType: value.sliderType,
              sliderNumberSteps:
                value.sliderType === "numerical"
                  ? value.sliderNumberSteps
                  : null,
              sliderText: value.sliderType === "text" ? stepValue : null,
            };
            let fileUploadValue = formValues.questionType === "File upload" && {
              fileTypesAllowed: value.fileTypesAllowed
                ? value.fileTypesAllowed
                : null,
              fileSize: value.fileSize ? value.fileSize : null,
              fileNumbers: value.fileNumbers ? value.fileNumbers : null,
              fileLimit: value.no_of_files ? value.no_of_files : null,
            };
            return optionValue.push({
              label: value.option ? value.option : null,
              index: index + 1,
              more_info: value.text ? value.text : null,
              logic: logicArrayValue[i] ? logicArrayValue[i][index] : null,
              options: {
                rating_labels: ratingLabels[i] ? ratingLabels[i][index] : null,
                ranking:
                  formValues.questionType === 4 ? index + 1 : value.ranking,
                includeTime: value.includeTime,
                dropdownValue: dropdownValue.length > 0 ? dropdownValue : null,
                sliderValue: sliderValue ? sliderValue : null,
                fileUploadValue: fileUploadValue ? fileUploadValue : null,
              },
            });
          });
        let question_id;
        types?.question_types?.forEach((type) => {
          if (type.name === formValues.questionType) {
            question_id = type.id;
          }
        });
        return questionValues.push({
          question: formValues.question,
          is_mandatory: validationObject[i]
            ? validationObject[i].questionMandatory
            : true,
          index: i + 1,
          logic: isLogic[i],
          survey_id: parseInt(surveyID),
          questions_options: { data: optionValue },
          question_type_id: question_id,
          validation: validationObject[i],
        });
      });
      delete_question({
        variables: {
          array: questionID,
          object: questionValues,
        },
      }).then(() => {
        updateSurvey({
          variables: {
            id: parseInt(surveyID),
            status:
              buttonAction === "draft"
                ? "draft"
                : moment().isSameOrAfter(props.startDate)
                ? props.status === "draft"
                  ? "draft"
                  : "active"
                : props.status === "active"
                ? "active"
                : "draft",
          },
        }).then(() => {
          props.handleEnable(true);
          if (buttonAction === "active") {
            location.pathname.split("/")[3] === "create-survey"
              ? history.push(
                  `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/addLogic`
                )
              : history.push(
                  `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/addLogic`
                );
          } else {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push(`/projectList/active`);
          }
        });
      });
    });
  };
  const onFinish = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  let initialValue = [];
  let newArray = [];
  let emptyArray = [];
  let fileTextArray = [];
  let enableInputArray = [];
  let changeOptionArray = [];
  let validationArray = [];
  let isLogicArray = [];
  let logicArray = [];
  let ratingArray = [];

  useEffect(() => {
    questionData.length !== 0
      ? // eslint-disable-next-line
        questionData.map((data, index) => {
          let array = [];
          let textArray = [];
          let fileInput = [];
          let enableInput = [];
          let changeOption = [];
          let logicValue = [];
          let ratingValue = [];
          emptyArray[index] = [];
          fileTextArray[index] = [];
          enableInputArray[index] = [];
          changeOptionArray[index] = [];
          logicArray[index] = [];
          ratingArray[index] = [];
          newArray.push(data.question_type && data.question_type.name);
          validationArray.push(data.validation);
          isLogicArray.push(data.logic);
          data.questions_options.length !== 0
            ? // eslint-disable-next-line
              data.questions_options.map((option, i) => {
                logicValue = logicArray[index];
                logicValue[i] = option.logic ? option.logic : null;

                logicArray[index] = logicValue;
                ratingValue = ratingArray[index];
                ratingValue[i] = option.options
                  ? option.options.rating_labels
                  : null;

                ratingArray[index] = ratingValue;
                if (data.question_type.name === "Multiple Choice") {
                  textArray = emptyArray[index];
                  textArray[i] = option.more_info !== null ? true : false;
                }
                emptyArray[index] = textArray;
                if (data.question_type.name === "File upload") {
                  fileInput = fileTextArray[index];
                  enableInput = enableInputArray[index];
                  fileInput[i] =
                    option?.options?.fileUploadValue?.fileLimit !== null
                      ? true
                      : false;
                  enableInput[i] =
                    option?.options?.fileUploadValue?.fileLimit === null
                      ? true
                      : false;
                }
                fileTextArray[index] = fileInput;
                enableInputArray[index] = enableInput;

                if (data.question_type.name === "Slider") {
                  changeOption = changeOptionArray[index];
                  changeOption[i] =
                    option?.options?.sliderValue?.sliderType === "numerical"
                      ? "numerical"
                      : "text";
                }
                changeOptionArray[index] = changeOption;

                let dropdownValue = [];
                option.options.dropdownValue &&
                  // eslint-disable-next-line
                  option.options.dropdownValue.map((dropdownLabel) => {
                    dropdownValue.push({ dropdownLabel });
                  });

                let sliderText = [];
                option.options.sliderValue &&
                  // eslint-disable-next-line
                  option?.options?.sliderValue?.sliderText?.map((steps) => {
                    sliderText.push({ steps });
                  });
                array[i] = {
                  option: option.label,
                  id: option.id,
                  dropdown: dropdownValue ? dropdownValue : null,
                  text: option.more_info ? option.more_info : null,
                  ranking: option.options.ranking
                    ? option.options.ranking
                    : null,
                  includeTime: option.options.includeTime
                    ? option.options.includeTime
                    : null,
                  fileNumbers: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileNumbers
                    : null,
                  fileSize: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileSize
                    : null,
                  fileTypesAllowed: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileTypesAllowed
                    : null,
                  no_of_files: option.options.fileUploadValue
                    ? option.options.fileUploadValue.fileLimit
                    : null,
                  sliderType: option.options.sliderValue
                    ? option.options.sliderValue.sliderType
                    : null,
                  sliderMaximum: option.options.sliderValue
                    ? option.options.sliderValue.sliderMaximum
                    : null,
                  sliderMinimum: option.options.sliderValue
                    ? option.options.sliderValue.sliderMinimum
                    : null,
                  sliderNumberSteps: option.options.sliderValue
                    ? option.options.sliderValue.sliderNumberSteps
                    : null,
                  sliderText: sliderText ? sliderText : null,
                };
              })
            : // eslint-disable-next-line
              data.questions_options.map((option, i) => {
                array[i] = {
                  option: option.label,
                  id: option.id,
                };
              });
          initialValue.push({
            question: data.question,
            id: data.id,
            logic: data.logic,
            questionType: data?.question_type?.name,
            options: array,
          });
        })
      : initialValue.push({
          question: "",
          questionType: "",
          options: [{ option: "" }],
        });
    if (questionData.length !== 0) {
      setLogicArrayValue(logicArray);
      setTextInput(fileTextArray);
      setEnableInput(enableInputArray);
      setAddTextBox(emptyArray);
      setQTypeValueNew(newArray);
      setChangeOptionValue(changeOptionArray);
      setQTypeValue(newArray);
      setValidationObject(validationArray);
      setIsLogic(isLogicArray);
      setRatingLabels(ratingArray);
    }
    // eslint-disable-next-line
  }, [questionData]);

  const GetFields = () => {
    return (
      questionData.length !== 0 && (
        <>
          <Form
            name="dynamic_form_nest_item"
            form={form}
            autoComplete="off"
            // onFinish={onFinish}
            requiredMark={false}
            preserve={true}
          >
            <Form.List
              initialValue={initialValue ? initialValue : ["survey"]}
              name="questions"
            >
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <>
                        <Form.Item
                          className="text-base w-full mt-4 ml-0 mr-0 p-5 float-left border border-borderGray"
                          {...field}
                          name={[field.name, "survey"]}
                          fieldKey={[field.fieldKey, "survey"]}
                          key={index}
                        >
                          <Form.Item
                            name={[field.name, "dragDelete"]}
                            fieldKey={[field.fieldKey, "dragDelete"]}
                          >
                            <div className="h-12">
                              <img
                                src={dragIcon}
                                className="float-left"
                                alt="drag"
                              />
                              {fields.length > 1 ? (
                                <Button
                                  type="link"
                                  className="float-right text-red"
                                  onClick={() => {
                                    let ratingArray = [...ratingLabels];
                                    let validationArray = [...validationObject];
                                    let isLogicCopy = [...isLogic];
                                    let copyNew = [...qTypeValueNew];
                                    let copy = [...qTypeValue];
                                    let logicArray = [...logicArrayValue];
                                    copy.splice(index, 1);
                                    ratingArray.splice(index, 1);
                                    validationArray.splice(index, 1);
                                    isLogicCopy.splice(index, 1);
                                    copyNew.splice(index, 1);
                                    logicArray.splice(index, 1);
                                    setQTypeValueNew(copyNew);
                                    setQTypeValue(copy);
                                    setRatingLabels(ratingArray);
                                    setValidationObject(validationArray);
                                    setLogicArrayValue(logicArray);
                                    setIsLogic(isLogicCopy);
                                    remove(index);
                                  }}
                                >
                                  Delete
                                </Button>
                              ) : (
                                <Button
                                  type="link"
                                  className="float-right text-red"
                                  disabled
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "question"]}
                            fieldKey={[field.fieldKey, "question"]}
                            style={{ width: "65%" }}
                            className="float-left"
                            label={<span>Q{index + 1}</span>}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Enter the question sentence",
                              },
                            ]}
                          >
                            <Input className="ml-4" placeholder="Question" />
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, "questionType"]}
                            fieldKey={[field.fieldKey, "questionType"]}
                            style={{ width: "25%" }}
                            className="float-left"
                            rules={[
                              {
                                required: true,
                                message: "Select the question type",
                              },
                            ]}
                          >
                            {qTypeValue[index] && qTypeValueNew[index] ? (
                              <Select
                                name="questionChoice"
                                disabled={true}
                                onChange={(e) => {
                                  let newArray = [...qTypeValue];
                                  let validation = [...validationObject];
                                  validation.splice(index, 0, null);
                                  newArray[index] = e;
                                  setValidationObject(validation);
                                  setQTypeValue(newArray);
                                }}
                                showSearch
                                optionFilterProp="children"
                                className="ml-12"
                                placeholder="Choose question Type"
                                value={qTypeValue[index]}
                              >
                                {types?.question_types?.map((type) => {
                                  return (
                                    <Option value={type.name} key={type.name}>
                                      {type.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Select
                                name="questionChoice"
                                onChange={(e) => {
                                  let newArray = [...qTypeValue];
                                  let validation = [...validationObject];
                                  validation.splice(index, 0, null);
                                  newArray[index] = e;
                                  setValidationObject(validation);
                                  setQTypeValue(newArray);
                                }}
                                className="ml-12"
                                placeholder="Choose question Type"
                                value={qTypeValue[index]}
                              >
                                {types?.question_types?.map((type) => {
                                  return (
                                    <Option value={type.name} key={type.name}>
                                      {type.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            )}
                          </Form.Item>
                          {qTypeValue[index] !== "Email" && (
                            <Form.List
                              name={[field.name, "options"]}
                              initialValue={["optionList"]}
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Enter option for the selected question",
                                },
                              ]}
                            >
                              {(
                                options,
                                { add: addOption, remove: removeOption }
                              ) => {
                                return (
                                  <div>
                                    {options.map((option, i) => (
                                      <div key={option.key} className="block">
                                        <Form.Item
                                          {...option}
                                          name={[option.name, "optionList"]}
                                          fieldKey={[
                                            option.fieldKey,
                                            "optionList",
                                          ]}
                                          key={i}
                                          className=" w-11/12 block float-left"
                                        >
                                          <Form.Item
                                            name={[option.name, "ranking"]}
                                            className="w-8 float-left h-7"
                                          >
                                            {qTypeValue[index] ===
                                              "Ranking" && (
                                              <select
                                                className="w-8 text-textMedium border-b "
                                                defaultValue={i + 1}
                                              >
                                                {options.map((option, i) => (
                                                  <option value={i + 1}>
                                                    {i + 1}
                                                  </option>
                                                ))}
                                              </select>
                                            )}
                                          </Form.Item>
                                          <Form.Item
                                            name={[option.name, "option"]}
                                            fieldKey={[
                                              option.fieldKey,
                                              "option",
                                            ]}
                                            className="float-left ml-3.5"
                                            style={{ width: "67.5%" }}
                                            rules={[
                                              {
                                                required: true,
                                                message:
                                                  "Enter option for the selected question",
                                              },
                                            ]}
                                          >
                                            <Input placeholder="Label" />
                                          </Form.Item>

                                          <Form.Item className="w-10 ml-8 float-left">
                                            <img src={dragIcon} alt="drag" />
                                          </Form.Item>
                                          <Form.Item className="float-left">
                                            {qTypeValue[index] ===
                                            "Multiple Choice" ? (
                                              <Button
                                                type="primary"
                                                className="bg-lightButton text-orange border-0 "
                                                onClick={() => {
                                                  handleTextBox(index, i);
                                                }}
                                              >
                                                Add text box
                                              </Button>
                                            ) : null}
                                            {qTypeValue[index] ===
                                            "Star rating" ? (
                                              <Button
                                                type="primary"
                                                className="bg-lightButton text-orange border-0 "
                                                onClick={() => {
                                                  setAddRatingDrawer(true);
                                                  setVisible(true);
                                                  setOptionIndex(i);
                                                  setQuestionIndex(index);
                                                  setValidation(undefined);
                                                }}
                                              >
                                                Add rating labels
                                              </Button>
                                            ) : null}
                                          </Form.Item>
                                          {options.length > 1 ? (
                                            <MinusCircleOutlined
                                              style={{ color: "red" }}
                                              className="mr-8 float-right mt-2"
                                              onClick={() => {
                                                removeOption(option.name);
                                              }}
                                            />
                                          ) : (
                                            <MinusCircleOutlined
                                              style={{ color: "red" }}
                                              className="mr-8 float-right mt-2"
                                              disabled
                                            />
                                          )}
                                          {qTypeValue[index] ===
                                            "Date & Time" && (
                                            <Form.Item
                                              valuePropName="checked"
                                              className="block pl-12"
                                              name={[
                                                option.name,
                                                "includeTime",
                                              ]}
                                              initialValue={false}
                                            >
                                              <Checkbox className="text-base text-textLight">
                                                Include time
                                              </Checkbox>
                                            </Form.Item>
                                          )}
                                          {qTypeValue[index] ===
                                            "File upload" && (
                                            <FileUpload
                                              option={option}
                                              optionIndex={i}
                                              index={index}
                                              handleFileInput={handleFileInput}
                                              handleEnablechange={
                                                handleEnablechange
                                              }
                                              textInput={
                                                textInput[index] !== undefined
                                                  ? textInput[index][i]
                                                  : ""
                                              }
                                              enableInput={
                                                enableInput[index] !== undefined
                                                  ? enableInput[index][i]
                                                  : ""
                                              }
                                            />
                                          )}
                                          {qTypeValue[index] === "Dropdown" && (
                                            <Dropdown option={option} />
                                          )}
                                          {qTypeValue[index] === "Slider" && (
                                            <SliderNew
                                              option={option}
                                              handleOption={handleOption}
                                              index={index}
                                              optionIndex={i}
                                              sliderMinimum={
                                                questionData[index]
                                                  ?.questions_options[i]
                                                  ?.options?.sliderValue
                                                  ?.sliderMinimum
                                              }
                                              sliderMaximum={
                                                questionData[index]
                                                  ?.questions_options[i]
                                                  ?.options?.sliderValue
                                                  ?.sliderMaximum
                                              }
                                              changeOptionValue={
                                                changeOptionValue[index] !==
                                                undefined
                                                  ? changeOptionValue[index][i]
                                                  : ""
                                              }
                                            />
                                          )}
                                          {addTextBox[index] !== undefined &&
                                            addTextBox[index][i] === true &&
                                            qTypeValue[index] ===
                                              "Multiple Choice" && (
                                              <>
                                                <Form.Item
                                                  className="block float-left w-6/12"
                                                  style={{
                                                    marginLeft: "22.25%",
                                                  }}
                                                  name={[option.name, "text"]}
                                                  fieldKey={[
                                                    option.fieldKey,
                                                    "text",
                                                  ]}
                                                >
                                                  <Input placeholder="Enter label for the text box" />
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                  style={{ color: "red" }}
                                                  className="ml-8 float-left mt-2 h-7"
                                                  onClick={() => {
                                                    let newArray = [
                                                      ...addTextBox,
                                                    ];
                                                    form.getFieldsValue().questions[
                                                      index
                                                    ].options[i].text = "";
                                                    newArray[index][i] = false;
                                                    setAddTextBox(newArray);
                                                  }}
                                                />
                                              </>
                                            )}
                                        </Form.Item>
                                      </div>
                                    ))}
                                    <PlusCircleOutlined
                                      style={{ color: "orange" }}
                                      className="float-left mt-1.5"
                                      onClick={() => {
                                        addOption();
                                      }}
                                    />
                                  </div>
                                );
                              }}
                            </Form.List>
                          )}
                          <Divider />
                          <Form.Item className="h-10 mb-0 block">
                            <Button
                              type="link"
                              size="large"
                              className="float-left"
                              onClick={() =>
                                addValidation(
                                  qTypeValue[index]
                                    ? qTypeValue[index]
                                    : questionData[index].question_type.name,
                                  index
                                )
                              }
                            >
                              Add Validation
                            </Button>

                            <Button
                              className="text-base text-orange float-right "
                              onClick={() => {
                                let copyArray = [...qTypeValue];
                                let ratingArray = [...ratingLabels];
                                let validationArray = [...validationObject];
                                let isLogicCopy = [...isLogic];
                                let copyArrayNew = [...qTypeValueNew];
                                let logicArray = [...logicArrayValue];
                                copyArray.splice(index + 1, 0, null);
                                ratingArray.splice(index + 1, 0, null);
                                validationArray.splice(index + 1, 0, null);
                                isLogicCopy.splice(index + 1, 0, null);
                                copyArrayNew.splice(index + 1, 0, null);
                                logicArray.splice(index + 1, 0, null);
                                setQTypeValue(copyArray);
                                setRatingLabels(ratingArray);
                                setValidationObject(validationArray);
                                setIsLogic(isLogicCopy);
                                setQTypeValueNew(copyArrayNew);
                                setLogicArrayValue(logicArray);
                                add(field.name, index + 1);
                              }}
                            >
                              Add question
                            </Button>
                          </Form.Item>
                        </Form.Item>
                      </>
                    ))}
                  </div>
                );
              }}
            </Form.List>
            <Form.Item className="float-right">
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={() => {
                  location.pathname.split("/")[3] === "create-survey"
                    ? history.push(
                        `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
                      )
                    : history.push(
                        `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
                      );
                }}
              >
                Back
              </Button>
              <Button
                htmlType="submit"
                className="text-base mx-5 rounded-md text-orange"
                onClick={handleDraft}
                disabled={
                  moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                    ? true
                    : false
                }
              >
                Save as draft
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                className="text-base rounded-md w-28"
                onClick={onFinish}
              >
                Next
              </Button>
            </Form.Item>
          </Form>
          {addRatingDrawer === true && visible && (
            <StarDrawerFrom
              handleRatingLabels={handleRatingLabels}
              index={questionIndex}
              visible={true}
              optionIndex={optionIndex}
              questionData={questionData}
              ratingLabels={ratingLabels}
            />
          )}
          {validation === "Multiple Choice" && visible && (
            <MultipleChoiceValidation
              visible={true}
              handleValidationValues={handleValidationValues}
              optionValue={optionValue}
              questionNumber={questionIndex}
              validationObject={validationObject}
            />
          )}
          {validation === "Text box - numerical" && visible && (
            <NumericalValidation
              visible={true}
              handleValidationValues={handleValidationValues}
              questionNumber={questionIndex}
              optionValue={optionValue}
              validationObject={validationObject}
              allValues={form.getFieldsValue().questions}
            />
          )}
          {(validation === "Text area" || validation === "Text box - text") &&
            visible && (
              <TextBoxTextValidation
                visible={true}
                handleValidationValues={handleValidationValues}
                questionNumber={questionIndex}
                optionValue={optionValue}
                validationObject={validationObject}
              />
            )}
          {validation === "Percentage" && visible && (
            <PercentageValidation
              visible={true}
              handleValidationValues={handleValidationValues}
              questionNumber={questionIndex}
              validationObject={validationObject}
            />
          )}
          {validation === "Date & Time" && visible && (
            <DateAndTimeValidation
              visible={true}
              handleValidationValues={handleValidationValues}
              questionNumber={questionIndex}
              optionValue={optionValue}
              validationObject={validationObject}
            />
          )}
          {validation === "Email" && visible && (
            <EmailValidation
              visible={true}
              handleValidationValues={handleValidationValues}
              questionNumber={questionIndex}
              validationObject={validationObject}
            />
          )}
          {(validation === "Dropdown" ||
            validation === "Ranking" ||
            validation === "Star rating" ||
            validation === "File upload" ||
            validation === "Slider") &&
            visible && (
              <DefaultValidation
                visible={true}
                validation={validation}
                handleValidationValues={handleValidationValues}
                questionNumber={questionIndex}
                validationObject={validationObject}
              />
            )}
        </>
      )
    );
  };
  return (
    <div name="create survey">
      <div span={24} className="text-base w-full mt-4 ml-0 mr-0 float-left">
        <Button
          type="link"
          size="large"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? (
            <div>
              <span className="w-40 float-left"> Collapse all questions </span>
              <CaretUpOutlined />
            </div>
          ) : (
            <div>
              <span className="w-40 float-left">Expand all questions</span>
              <CaretDownOutlined />
            </div>
          )}
        </Button>
      </div>
      {expand === true && <GetFields />}
    </div>
  );
}
export default QuestionEdit;
