import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  INSERT_ANSWER,
  SCREEN_SURVEY,
  FETCH_ANSWER,
  COMPLETE_SURVEY,
  GET_SURVEY_ONLINE,
  UPDATE_INTERVIEWER_STATUS,
} from "../../../pages/survey/graphql";
import { Button, Upload, Modal, message } from "antd";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { surveyState } from "../../../atoms";
import NumericalTextBox from "./NumericalTextBox";
import StarRating from "./StarRating";
import DateTime from "./DateTime";
import FileUpload from "./FileUpload";
import TextArea from "./TextArea";
import TextBox from "./TextBox";
import DropDown from "./DropDown";
import MultipleChoice from "./MultipleChoice";
import Ranking from "./Ranking";
import SliderComponent from "./SliderComponent";
import Email from "./Email";
import { validateAnswer } from "../../../pages/survey/validations";
import { GET_UPLOAD_URL } from "../../../pages/createProject/graphql";
import { compact } from "lodash";

const SurveyQuestion = (props) => {
  const params = useParams();
  const history = useHistory();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [fileList, setFileList] = React.useState([]);
  const [previousIndex, setPreviousIndex] = React.useState([]);
  const [data, setData] = useState([]);
  const [insertAnswer] = useMutation(INSERT_ANSWER);
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [updateInterviewerStatus] = useMutation(UPDATE_INTERVIEWER_STATUS);
  const [changeSurveyStatusToComplete] = useMutation(COMPLETE_SURVEY, {
    refetchQueries: [
      {
        query: GET_SURVEY_ONLINE,
        variables: {
          survey_id: Number(params.surveyID),
          member_id: Number(params.memberID),
        },
      },
    ],
  });
  const [changeSurveyStatusToScreened] = useMutation(SCREEN_SURVEY);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });
  React.useEffect(() => {
    refetch();
    if (props) {
      let array = JSON.parse(JSON.stringify(props.question));
      let copy = [array];
      copy?.forEach((question) => {
        question.fileSize = question?.validation?.fileSize || null;
        let fileTypesAllowed = "";
        question?.validation?.fileTypesAllowed !== null &&
          question?.validation?.fileTypesAllowed !== undefined &&
          question?.validation?.fileTypesAllowed.forEach((type) => {
            const array = type.split("/");
            array.forEach((item) => {
              if (item === "video") {
                fileTypesAllowed = fileTypesAllowed + ".mp4,.3gp,";
              } else if (item === "audio") {
                fileTypesAllowed = fileTypesAllowed + ".mp3,.wav,";
              } else {
                fileTypesAllowed = fileTypesAllowed + `.${item},`;
              }
              question.fileTypesAllowed = fileTypesAllowed;
            });
          });
      });

      setData(copy);
    }
    // eslint-disable-next-line
  }, [props]);
  React.useEffect(() => {
    let copy = [];
    let url_link = [];
    if (props.question.question_type?.name !== "File upload") {
      url_link = answers?.questions[0]?.answers[0]?.file_url.split(",");
      url_link !== undefined &&
        url_link.forEach((item, i) => {
          let fileName = item.split("/");

          copy.push({
            uid: { i },
            name: fileName[fileName.length - 1],
            status: "done",
            responseUrl: item,
          });
        });
      setFileList(copy);

      setSurveyState((oldSurveyState) => ({
        ...oldSurveyState,
        file_url:
          answers?.questions[0]?.answers[0]?.file_url !== undefined
            ? url_link
            : [],
      }));
    }
    // eslint-disable-next-line
  }, [answers]);

  const next = async (survey_completed = false) => {
    let validation = await validateAnswer(surveyDetails);

    if (validation.status) {
      insertAnswer({
        variables: {
          ...validation.object,
          memberId: Number(params.memberID),
          questionId: props.question.id,
        },
      })
        .then((res) => {
          if (res.errors) {
            Modal.error({
              title: res.errors[0].message,
              okButtonProps: { className: "rounded" },
            });
          } else {
            if (survey_completed && !validation.screened) {
              if (!surveyDetails.include_profiler_questions) {
                changeSurveyStatusToComplete({
                  variables: {
                    survey_id: Number(params.surveyID),
                    member_id: Number(params.memberID),
                  },
                }).then((res) => {
                  if (surveyDetails.surveyType === "Assisted") {
                    updateInterviewerStatus({
                      variables: {
                        member_id: Number(params.memberID),
                        interviewer_id: parseInt(
                          localStorage.getItem("interviewerID")
                        ),
                        status: "completed",
                        survey_id: Number(params.surveyID),
                      },
                    }).then(() => {
                      localStorage.removeItem("interviewerID");
                      localStorage.removeItem("surveyID");
                      localStorage.removeItem("memberID");
                      setSurveyState((oldSurveyState) => ({
                        ...oldSurveyState,
                        survey_finished: true,
                      }));
                      // window.location.reload();
                    });
                  } else {
                    setSurveyState((oldSurveyState) => ({
                      ...oldSurveyState,
                      survey_finished: true,
                    }));
                    // window.location.reload();
                  }
                });
              } else {
                if (surveyDetails.surveyType === "Assisted") {
                  updateInterviewerStatus({
                    variables: {
                      member_id: Number(params.memberID),
                      interviewer_id: parseInt(
                        localStorage.getItem("interviewerID")
                      ),
                      status: "completed",
                      survey_id: Number(params.surveyID),
                    },
                  }).then(() => {
                    let copy = [...previousIndex];

                    copy[surveyDetails.question_index + 1] =
                      surveyDetails.question_index;
                    localStorage.removeItem("interviewerID");
                    localStorage.removeItem("surveyID");
                    localStorage.removeItem("memberID");
                    setPreviousIndex(copy);
                    setSurveyState((oldSurveyState) => ({
                      ...oldSurveyState,
                      current_question: {},
                      question_index: surveyDetails.question_index + 1,
                      current_answer: [],
                      documents: [],
                      previous_index: surveyDetails.question_index,
                      file_url: [],
                      answer: "",
                      current_answer_type: "",
                      survey_completed: true,
                      quotachoice: [],
                      exisitingAnswer: [],
                    }));
                  });
                } else {
                  let copy = [...previousIndex];

                  copy[surveyDetails.question_index + 1] =
                    surveyDetails.question_index;

                  setPreviousIndex(copy);
                  setSurveyState((oldSurveyState) => ({
                    ...oldSurveyState,
                    current_question: {},
                    question_index: surveyDetails.question_index + 1,
                    current_answer: [],
                    documents: [],
                    previous_index: surveyDetails.question_index,
                    file_url: [],
                    answer: "",
                    current_answer_type: "",
                    survey_completed: true,
                    quotachoice: [],
                    exisitingAnswer: [],
                  }));
                }
              }
            } else {
              if (validation.screened) {
                changeSurveyStatusToScreened({
                  variables: {
                    survey_id: Number(params.surveyID),
                    member_id: Number(params.memberID),
                  },
                }).then((res) => {
                  if (surveyDetails.surveyType === "Assisted") {
                    updateInterviewerStatus({
                      variables: {
                        member_id: Number(params.memberID),
                        interviewer_id: parseInt(
                          localStorage.getItem("interviewerID")
                        ),
                        status: "screened",
                        survey_id: Number(params.surveyID),
                      },
                    }).then(() => {
                      localStorage.removeItem("interviewerID");
                      localStorage.removeItem("surveyID");
                      localStorage.removeItem("memberID");
                      setSurveyState((oldSurveyState) => ({
                        ...oldSurveyState,
                        survey_finished: true,
                      }));
                      window.location.reload();
                    });
                  } else {
                    setSurveyState((oldSurveyState) => ({
                      ...oldSurveyState,
                      survey_finished: true,
                    }));
                    window.location.reload();
                  }
                });
              } else if (validation.jumpToStatus) {
                let copy = [...previousIndex];

                copy[validation.jumpToQuestion - 1] =
                  surveyDetails.question_index;

                setPreviousIndex(copy);
                setSurveyState((oldSurveyState) => ({
                  ...oldSurveyState,
                  current_question:
                    surveyDetails.questions[validation.jumpToQuestion - 1],
                  question_index: validation.jumpToQuestion - 1,
                  current_answer: [],
                  documents: [],
                  previous_index: surveyDetails.question_index,
                  file_url: [],
                  answer: "",
                  quotachoice: [],
                  exisitingAnswer: [],
                  current_answer_type:
                    surveyDetails.questions[validation.jumpToQuestion - 1]
                      .question_type.name,
                }));

                history.push(
                  `/survey/${params.surveyID}/member/${
                    params.memberID
                  }/question/${
                    surveyDetails.questions[validation.jumpToQuestion - 1].id
                  }`
                );
              } else {
                let copy = [...previousIndex];
                copy[surveyDetails.question_index + 1] =
                  surveyDetails.question_index;

                setPreviousIndex(copy);
                setSurveyState((oldSurveyState) => ({
                  ...oldSurveyState,
                  current_question:
                    surveyDetails.questions[surveyDetails.question_index + 1],
                  question_index: surveyDetails.question_index + 1,
                  current_answer: [],
                  documents: [],
                  previous_index: surveyDetails.question_index,
                  file_url: [],
                  answer: "",
                  quotachoice: [],
                  exisitingAnswer: [],
                  current_answer_type:
                    surveyDetails.questions[surveyDetails.question_index + 1]
                      .question_type.name,
                }));

                history.push(
                  `/survey/${params.surveyID}/member/${
                    params.memberID
                  }/question/${
                    surveyDetails.questions[surveyDetails.question_index + 1].id
                  }`
                );
              }
            }
            // setFileList([]);
          }
        })
        .catch((err) => {
          Modal.error({
            title: "Something went wrong! Please try again",
            okButtonProps: { className: "rounded" },
          });
        });
    } else {
      Modal.error({
        title: validation.message,
        okButtonProps: { className: "rounded" },
      });
    }
  };

  const previous = () => {
    let qno =
      previousIndex[surveyDetails.question_index] === undefined
        ? surveyDetails.question_index - 1
        : previousIndex[surveyDetails.question_index];

    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_question: surveyDetails.questions[qno],
      question_index: qno,
      current_answer: [],
      file_url: [],
      answer: "",
      current_answer_type: surveyDetails.questions[qno].question_type.name,
      documents: [],
      quotachoice: [],
      exisitingAnswer: [],
    }));

    history.push(
      `/survey/${params.surveyID}/member/${params.memberID}/question/${surveyDetails.questions[qno].id}`
    );
  };

  const handleFileChange = (info) => {
    setFileList(info.fileList);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      file_url: compact(
        info.fileList.map((file) => {
          if (file.status === "done") {
            return file.responseUrl
              ? file.responseUrl
              : file.xhr.responseURL.split("?")[0];
          } else {
            return null;
          }
        })
      ),
    }));
  };
  return (
    <React.Fragment>
      <div className="fixed lg:absolute bottom-0 left-0 right-0 h-20 flex items-center justify-between lg:justify-end border-t-2 border-bgLite bg-white  px-4 lg:px-12">
        {surveyDetails.question_index !== 0 && (
          <Button
            className="rounded px-8 mr-4 text-orange w-1/2 lg:w-auto"
            size="large"
            onClick={() => previous()}
          >
            Previous
          </Button>
        )}
        {surveyDetails.question_index + 1 !==
          surveyDetails.questions.length && (
          <Button
            className={`rounded px-12 ${
              surveyDetails.question_index === 0 ? "" : "w-1/2"
            } lg:w-auto`}
            size="large"
            onClick={() => next()}
            type="primary"
            block={surveyDetails.question_index === 0}
          >
            Next
          </Button>
        )}
        {surveyDetails.question_index + 1 ===
          surveyDetails.questions.length && (
          <Button
            className="rounded px-12 w-1/2 lg:w-auto"
            size="large"
            onClick={() => next(true)}
            type="primary"
          >
            Submit
          </Button>
        )}
      </div>
      <div className="flex items-start justify-start">
        <div className="hidden lg:block text-h6 font-semibold text-textMedium mr-8">
          Q{surveyDetails.question_index + 1}
        </div>
        <div className="flex flex-col items-start justify-start">
          <p className="text-h6 text-textMedium mb-4">
            {props.question.question}
          </p>
          {props.question.is_mandatory && (
            <div className="text-h8 text-textLight">
              This question is mandatory
            </div>
          )}
          {props.question.question_type?.name === "Multiple Choice" && (
            <MultipleChoice
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Text box - numerical" && (
            <NumericalTextBox
              questions_options={props.question.questions_options}
              question={props.question}
              questionType={props.question.question_type?.name}
            />
          )}
          {props.question.question_type?.name === "Percentage" && (
            <NumericalTextBox
              questions_options={props.question.questions_options}
              questionType={props.question.question_type?.name}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Star rating" && (
            <StarRating
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Date & Time" && (
            <DateTime
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "File upload" && (
            <FileUpload
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Text area" && (
            <TextArea
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Text box - text" && (
            <TextBox
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Ranking" && (
            <Ranking
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Dropdown" && (
            <DropDown
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {props.question.question_type?.name === "Email" && (
            <Email
              type="email"
              question={props.question}
              questions_options={props.question.questions_options}
            />
          )}
          {props.question.question_type?.name === "Slider" && (
            <SliderComponent
              questions_options={props.question.questions_options}
              question={props.question}
            />
          )}
          {data.length !== 0 &&
            data[0].validation &&
            data[0].validation.addFileOption && (
              <div className="mt-4">
                <Upload
                  name="file"
                  method="PUT"
                  fileList={fileList}
                  accept={data[0]?.fileTypesAllowed}
                  action={(file) => {
                    return new Promise((resolve) => {
                      uploadURL({
                        variables: {
                          fileName: file.name,
                          contentType: file.type,
                        },
                      }).then((res) => {
                        if (res.data.get_upload_url.message === "Success") {
                          resolve(res.data.get_upload_url.url);
                        }
                      });
                    });
                  }}
                  beforeUpload={(file, fileList) => {
                    if (data[0].validation.fileLimit) {
                      if (
                        surveyDetails.file_url.length >=
                        data[0].validation?.fileLimit
                      ) {
                        message.error("Maximum limit reached");
                        return Upload.LIST_IGNORE;
                      } else {
                        return true;
                      }
                    } else {
                      return true;
                    }
                  }}
                  onChange={handleFileChange}
                >
                  <Button size="large">Upload Document</Button>
                </Upload>
                <ul className="mt-4 text-h8 text-textLight list-disc pl-4">
                  {props.question.validation &&
                    props.question.validation.fileTypesAllowed &&
                    props.question.validation.fileTypesAllowed !== null &&
                    props.question.validation.fileTypesAllowed.length !== 0 && (
                      <li>
                        Files types allowed{" "}
                        {props.question.validation?.fileTypesAllowed.join(", ")}
                      </li>
                    )}
                  {props.question.validation &&
                    props.question.validation.fileSize && (
                      <li>
                        Maximum size {props.question.validation.fileSize} mb
                      </li>
                    )}
                  {props.question.validation &&
                  props.question.validation.fileLimit ? (
                    <li>
                      Maximum no of files -{" "}
                      {props.question.validation.fileLimit}
                    </li>
                  ) : (
                    <li>No file limit</li>
                  )}
                </ul>
              </div>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SurveyQuestion;
