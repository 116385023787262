import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useMutation, useQuery, useLazyQuery } from "@apollo/client";
import {
  Button,
  Divider,
  Input,
  Layout,
  notification,
  Space,
  Table,
  Tabs,
  Tag,
} from "antd";
import jwtDecode from "jwt-decode";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation, Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import SliderImage from "../../assets/Icon/sliders.svg";
import { client, user } from "../../atoms";
import ColumnEditor from "../../components/ColumnEditor";
import Loading from "../../components/Loading";
import {
  DELETE_POC_BY_ID,
  GET_CLIENT_BY_ID,
  GET_ACTIVITY,
  VERIFY_USER,
  REJECT_USER,
} from "../createClient/graphql";

const { Content } = Layout;
const { TabPane } = Tabs;

export default function ViewClient() {
  const setValues = useSetRecoilState(client);
  const setUserID = useSetRecoilState(user);
  let searchInput;
  const [activeTabPane, setActiveTabPane] = useState("1");
  const location = useLocation();
  const history = useHistory();
  const { clientID } = useParams();
  const tabPane = ["client-details", "project-details"];
  const [clientInfo, setClientInfo] = useState(null);
  const [pocList, setPOClist] = useState([]);
  const [projects, setProjects] = useState([]);
  const [pocId, setPocId] = useState([]);
  const [id, setId] = useState(null);
  const [pocUSERId, setPOCUSERId] = useState([]);
  const [verifyUser] = useMutation(VERIFY_USER);
  const [rejectUser] = useMutation(REJECT_USER);
  const [role, setRole] = useState(null);
  const [columnStatus, setStatus] = useState({

  });
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    if (role) {
      let status = {
        "category": { enabled: true, label: "Category" },
        "sample": { enabled: true, label: "Sample" },
        "status": { enabled: true, label: "Status" }
      }
      if (role !== 'manager') {
        status["assignedTo"] = { enabled: true, label: "Assigned To" }
      }
      setStatus(status)
    }
  }, [role])


  useEffect(() => {
    if (location.pathname.split("/")[2] === "client-details") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[2] === "project-details") {
      setActiveTabPane("2");
    }
  }, [location.pathname]);

  const handleActiveTabPane = (key) => {
    history.push(`/viewClient/${tabPane[key - 1]}/client/${clientID}`);
    setActiveTabPane(key);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const { refetch: refetchClientDetails, loading: loadingClient } = useQuery(
    GET_CLIENT_BY_ID,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        client_id: parseInt(clientID),
      },
      onCompleted: (data) => {
        let pocarray = [...pocUSERId];
        let pocIDarray = [...pocId];
        if (data.client_by_pk) {
          //add role check
          setClientInfo(data.client_by_pk);
          if (data.client_by_pk.user?.projects) {
            let projectData = JSON.parse(
              JSON.stringify(data.client_by_pk.user?.projects)
            );
            projectData.forEach((item) => {
              let val =
                item?.survey_type !== null && item?.survey_type !== ""
                  ? "- " + item?.survey_type
                  : "";
              item.manager =
                role !== "manager"
                  ? item?.manager_x_projects[0]
                    ? item?.manager_x_projects[0]?.user?.f_name +
                    " " +
                    item?.manager_x_projects[0]?.user?.l_name
                    : ""
                  : null;
              item.category = item?.manager_x_projects[0]
                ? item?.manager_x_projects[0]?.project?.projectTypeByProjectType
                  ?.name +
                " " +
                val
                : "";
              item.categoryId = item?.manager_x_projects[0]
                ? item?.manager_x_projects[0]?.project?.projectTypeByProjectType
                  ?.id
                : "";
              item.status = "active";
              item.member =
                item?.member_types[0] === 1
                  ? "Physician"
                  : item?.member_types[0] === 2
                    ? "Technician"
                    : item?.member_types[0] === 3
                      ? "Patient"
                      : "Consumer";
            });
            setId(data.client_by_pk.user_id);
            setUserID(() => data.client_by_pk.user_id);
            setProjects(projectData);
          }
        } else {
          notification.error({
            message: "No Client Information found",
          });
          history.push(`/clientList`);
        }
        if (data.poc && data.poc.length) {
          // eslint-disable-next-line
          data.poc.map((item) => {
            pocIDarray.push(item.id);
            pocarray.push(item.user_id);
          });
          setPocId(pocIDarray);
          setPOCUSERId(pocarray);
          setPOClist(data.poc);
        }
      },
      onError: (error) => {
        notification.error({
          message: error.message,
        });
      },
    }
  );

  const [getApproval, { data: activity }] = useLazyQuery(GET_ACTIVITY);

  useEffect(() => {
    if (id) {
      getApproval({
        variables: {
          id: parseInt(id),
        },
      });
    }
    // eslint-disable-next-line
  }, [id]);

  const ViewContainer = ({ title, desc = "-" }) => {
    return (
      <div className="mb-4 flex">
        <div className="text-xs text-textLight w-28 mr-11 min-w-28">
          {title}
        </div>
        <div className="text-sm text-textMedium font-medium">{desc}</div>
      </div>
    );
  };
  const POCContainer = ({ data }) => {
    const [removePOC, { loading: deletePOCLoading }] = useMutation(
      DELETE_POC_BY_ID,
      {
        onCompleted: (data) => {
          if (data.delete_poc && data.delete_poc.affected_rows) {
            notification.success({
              message: "POC detail removed",
            });
            refetchClientDetails();
          }
        },
        onError: (error) => {
          notification.error({
            message: error.message,
          });
        },
      }
    );

    return (
      <div className="border border-bgMedium rounded p-4">
        {deletePOCLoading && <Loading />}

        <div>{data.first_name}</div>
        <div className="mt-2">{data.email}</div>
        <div className="mt-1">{data.phone_number}</div>
        <div className="mt-3 text-right">
          <>
            <Button
              type="link"
              className="text-orange text-h8 p-0 font-medium"
              onClick={() => {
                setValues(() => parseInt(clientID));
                history.push({
                  pathname: `/edit-client/${clientID}/add-poc`,
                  state: {
                    edit: true,
                    client_id: clientID,
                  },
                });
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" className="bg-black" />
            <Button
              type="link"
              disabled={pocList.length <= 1 ? true : false}
              className={`text-red text-h8 p-0 font-medium ${pocList.length <= 1 ? "opacity-60" : ""
                }`}
              onClick={() => {
                removePOC({
                  variables: {
                    pocIds: [data.id],
                    pocUserIds: [data.user_id],
                  },
                });
              }}
            >
              Remove
            </Button>
          </>
        </div>
      </div>
    );
  };
  const ActivityContainer = () => {
    return (
      <div className="border-b border-borderGray mb-4 pb-4">
        {activity?.approval?.map((copy) => {
          let approval = JSON.parse(JSON.stringify(copy));
          approval.old_info.client = {
            ...approval.old_info.client,
            ...approval.old_info.client.meta_date,
            ...approval.old_info.client.additional_data,
          };
          approval.updated_info.client = {
            ...approval.updated_info.client,
            ...approval.updated_info.client.meta_date,
            ...approval.updated_info.client.additional_data,
          };
          // eslint-disable-next-line
          return Object.keys(approval?.old_info?.client).map((key) => {
            if (
              approval?.old_info?.client[key] !==
              approval?.updated_info?.client[key]
            ) {
              if (key === "address") {
                return (
                  <p>
                    Address changed from {approval?.old_info?.client[key]} to{" "}
                    {approval?.updated_info?.client[key]}
                  </p>
                );
              } else if (key === "company_name") {
                return (
                  <p>
                    Company name changed from {approval?.old_info?.client[key]}{" "}
                    to {approval?.updated_info?.client[key]}
                  </p>
                );
              } else if (key === "email") {
                return (
                  <p>
                    Email changed from {approval?.old_info?.client[key]} to{" "}
                    {approval?.updated_info?.client[key]}
                  </p>
                );
              } else if (key === "phone_number") {
                return (
                  <p>
                    Phone number changed from{" "}
                    {approval?.old_info?.client[key] || "null"} to{" "}
                    {approval?.updated_info?.client[key] || "null"}
                  </p>
                );
              } else if (key === "category") {
                return <p>Company category changed</p>;
              } else if (key === "billing_cycle") {
                return <p>Billing cycle changed</p>;
              } else if (key === "country") {
                return <p>Country changed</p>;
              }
            }
          });
        })}
      </div>
    );
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex, keyword) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${keyword}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Project Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div>
            <div className="text-xs text-textLight font-normal">{`ID #${record.id || ""
              }`}</div>
            <div className="mt-1 font-medium text-textDark text-sm">
              {text || ""}
            </div>
          </div>
        );
      },
      ...getColumnSearchProps("name", "project name"),
    },

    {
      title: "Category",
      dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      key: "category",
      ...getColumnSearchProps("category", "Category"),
      render: (record) => {
        return (
          <div>
            <div>
              <div className="font-medium text-textDark text-sm">
                {record.category}
              </div>
              <div className="text-xs text-textLight font-normal">
                {record.member}
              </div>
            </div>
          </div>
        );
      },
    },
    role !== "manager" && {
      title: "Assigned to",
      dataIndex: "manager",
      className: columnStatus?.assignedTo?.enabled ? "table-cell" : "hidden",
      key: "manager",
      ...getColumnSearchProps("manager", "Assigned Managers"),
      render: (record) => {
        return (
          <div>
            <span className="text-h8">{record}</span>
          </div>
        );
      },
    },
    {
      title: "Sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      key: "sample",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (record) => {
        return (
          <div>
            <span className="float-right text-sm text-textMedium">
              {record}
            </span>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      key: "status",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.status === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.status === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.status === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (record) => {
        return (
          <Button
            type="link"
            size="large"
            onClick={() => {
              const surveyType = record.category.replace(/ /g, "");
              if (role !== "manager") {
                record?.surveys.length !== 0
                  ? history.push(
                    `/viewProject/projectDetails/project/${record?.id}/survey/${record?.surveys[0]?.id}/${surveyType}`
                  )
                  : history.push(
                    `/viewProject/projectDetails/project/${record?.id}/${surveyType}`
                  );
              } else if (role === "manager") {
                record?.surveys.length !== 0
                  ? history.push(
                    `/viewSurvey/projectDetails/project/${record?.id}/survey/${record?.surveys[0]?.id}`
                  )
                  : history.push(
                    `/viewSurvey/projectDetails/project/${record?.id}`
                  );
              }
            }}
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <div className="relative">
      {loadingClient && <Loading />}
      <Content className="bg-white ml-24 mb-5 mt-4 flex">
        {clientInfo && (
          <>
            <div className="w-8/12 border-r border-borderGray">
              <div className="pl-8 pt-4 pr-4 pb-4 flex justify-between">
                <div>
                  <div className="text-textLight text-xs font-normal">
                    <Link
                      to="/clientList"
                      className="text-textLight text-xs font-normal"
                    >
                      Clients
                    </Link>{" "}
                    / {clientInfo.company_name}
                  </div>
                  <div className="text-lg font-medium text-textDark mt-6">
                    {clientInfo.company_name}
                  </div>
                </div>
                {clientInfo?.user?.is_verified === true ? (
                  <Button
                    type="primary"
                    className="rounded"
                    size="large"
                    onClick={() => {
                      history.push({
                        pathname: `/edit-client/${clientID}/client-details`,
                      });
                    }}
                  >
                    Edit
                  </Button>
                ) : role === "super_admin" ? (
                  <div className="float-right">
                    <Button
                      type="primary"
                      htmlType="button"
                      className="rounded float-right"
                      onClick={() => {
                        let result = [...pocUSERId, id];
                        verifyUser({
                          variables: {
                            id: result,
                            status: true,
                          },
                        }).then(() => {
                          window.location.reload();
                        });
                      }}
                    >
                      Verify
                    </Button>
                    <Button
                      type="link"
                      htmlType="button"
                      className="rounded float-right"
                      onClick={() => {
                        let result = [...pocUSERId, id];
                        rejectUser({
                          variables: {
                            id: result,
                            pocId: pocId,
                            clientID: parseInt(clientID),
                          },
                        }).then(() => {
                          history.push("/clientList");
                        });
                      }}
                    >
                      Reject
                    </Button>
                  </div>
                ) : null}

                {/* {clientInfo?.user?.is_verified && (
                  <Button
                    type="primary"
                    className="rounded"
                    size="large"
                    onClick={() => {
                      history.push({
                        pathname: `/edit-client/${clientID}/client-details`,
                        // state: {
                        //   edit: true,
                        //   client_id: clientID,
                        // },
                      });
                    }}
                  >
                    Edit
                  </Button>
                )} */}
              </div>
              <Tabs
                className="w-full bg-white mt-4"
                // defaultActiveKey="1"
                activeKey={activeTabPane}
                onChange={handleActiveTabPane}
              >
                <TabPane
                  tab={<span className="px-4">Client details</span>}
                  key="1"
                  className="px-8 py-4"
                >
                  <div className="pb-3">
                    <div>
                      <ViewContainer
                        title={"Country"}
                        desc={clientInfo.countryByCountry?.name}
                      />

                      <ViewContainer
                        title={"Category"}
                        desc={clientInfo.client_category?.name}
                      />
                      <ViewContainer
                        title={"Billing cycle"}
                        desc={clientInfo.billingCycleByBillingCycle?.values}
                      />
                      <ViewContainer
                        title={"General email"}
                        desc={clientInfo.user?.email}
                      />
                      <ViewContainer
                        title={"General phone"}
                        desc={clientInfo.user?.phone_number}
                      />
                      <ViewContainer
                        title={"Address"}
                        desc={clientInfo.address}
                      />
                      {clientInfo.client_custom_fields &&
                        clientInfo.client_custom_fields.length !== 0 &&
                        clientInfo.client_custom_fields.map((customField) => {
                          return (
                            <ViewContainer
                              title={customField.label}
                              desc={customField.value}
                            />
                          );
                        })}
                    </div>
                    <div className="mt-7">
                      <div className="text-lg text-textMedium font-medium mb-4">
                        POCs
                      </div>
                      <div className="grid grid-cols-3 gap-5">
                        {pocList.length !== 0 ? (
                          pocList.map((poc) => <POCContainer data={poc} />)
                        ) : (
                          <div className="text-left text-textLight text-sm">
                            No POC details found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane
                  tab={<span className="px-4">Projects</span>}
                  key="2"
                  className="px-8 projectList"
                >
                  <div className=" pb-5 float-right">

                    <ColumnEditor columns={columnStatus} onSave={(column) => {
                      setStatus(column)
                    }} />
                  </div>
                  <Table
                    rowKey={(record) => record.id}
                    className="w-full bg-white"
                    dataSource={projects}
                    columns={columns}
                  // loading={clientLoading}
                  />
                </TabPane>
              </Tabs>
            </div>
            <div className="w-4/12">
              <div className="sticky top-20">
                <div className="flex justify-between items-center p-4 border-b border-borderGray">
                  <div className="text-h5 font-medium text-textDark">
                    Activity
                  </div>
                  <img src={SliderImage} alt="activities" />
                </div>
                <div className="p-4">
                  {activity?.approval?.length !== 0 ? (
                    <ActivityContainer />
                  ) : (
                    <div className="text-center text-textLight font-normal">
                      No activities found
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </Content>
    </div>
  );
}
