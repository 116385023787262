import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Table,
  Tabs,
  Input,
  Space,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  GET_APPROVALS,
  UPDATE_APPROVALS,
  GET_COUNTRY,
  GET_SPECIALITIES,
  GET_BILLING_CYCLES,
  REJECTED_APPROVALS,
  EDIT_USERS,
} from "./graphql";
import jwtDecode from "jwt-decode";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import _ from "lodash";
import ColumnEditor from "../../components/ColumnEditor";
import { getColumnDateSearchProps } from "../../utils/dateSearch";

const { TabPane } = Tabs;
const Approvals = () => {
  const [role, setRole] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [activeTab, setActiveTab] = useState("pending");
  const [selectedMemberKeys, setSelectedMemberKeys] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const savedApprovals = localStorage.getItem('approvalsColumn');
  const approvalinitial = savedApprovals
    ? JSON.parse(savedApprovals)
    : {
      pending: {
        name: { enabled: true, label: "Name" },
        oldInfo: { enabled: true, label: "Old Information" },
        newInfo: { enabled: true, label: "New Information" },
        updatedBy: { enabled: true, label: "Updated By" },
        updatedOn: { enabled: true, label: "Updated On" },
        approvedOn: { enabled: true, label: "Approved On" },
        approvedBy: { enabled: true, label: "Approved By" },
        rejectedOn: { enabled: true, label: "Rejected On" },
        rejectedBy: { enabled: true, label: "Rejected By" },
      },
      approved: {
        name: { enabled: true, label: "Name" },
        oldInfo: { enabled: true, label: "Old Information" },
        newInfo: { enabled: true, label: "New Information" },
        updatedBy: { enabled: true, label: "Updated By" },
        updatedOn: { enabled: true, label: "Updated On" },
        approvedOn: { enabled: true, label: "Approved On" },
        approvedBy: { enabled: true, label: "Approved By" },
      },
      rejected: {
        name: { enabled: true, label: "Name" },
        oldInfo: { enabled: true, label: "Old Information" },
        newInfo: { enabled: true, label: "New Information" },
        updatedBy: { enabled: true, label: "Updated By" },
        updatedOn: { enabled: true, label: "Updated On" },
        rejectedOn: { enabled: true, label: "Rejected On" },
        rejectedBy: { enabled: true, label: "Rejected By" },
      },
    }
  const [columnStatus, setStatus] = useState(approvalinitial);

  let searchInput;

  const [getApprovals, { data, loading }] = useLazyQuery(GET_APPROVALS, {
    fetchPolicy: "network-only",
  });

  const { data: country } = useQuery(GET_COUNTRY, {
    fetchPolicy: "network-only",
  });

  const { data: speciality } = useQuery(GET_SPECIALITIES, {
    fetchPolicy: "network-only",
  });

  const { data: billingCycle } = useQuery(GET_BILLING_CYCLES, {
    fetchPolicy: "network-only",
  });

  const [id] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
    "x-hasura-user-id"
    ]
  );

  const [updateApprovals] = useMutation(UPDATE_APPROVALS);
  const [rejectApprovals] = useMutation(REJECTED_APPROVALS);
  const [hasRendered, setHasRendered] = useState(false);
  const [editUser] = useMutation(EDIT_USERS);
  const currentTime = new Date();
  const formattedTime = currentTime.toISOString();

  useEffect(() => {
    localStorage.setItem('approvalsColumn', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (role === "super_admin") {
      getApprovals({
        variables: {
          roles: [2, 3, 4, 6],
        },
      });
    } else if (role === "sub_admin") {
      getApprovals({
        variables: {
          roles: [3, 4, 6],
        },
      });
    } else if (role === "manager") {
      getApprovals({
        variables: {
          roles: [4, 6],
        },
      });
    } else {
      getApprovals({
        variables: {
          roles: [3],
        },
      });
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    let tableArray = [],
      tableObject = {};
    if (data) {
      data?.approval.forEach((entry) => {
        if (entry.status === "Pending") {
          if (
            entry?.old_info?.user &&
            Object.keys(entry?.old_info?.user)?.length !== 0
          ) {
            tableObject.name =
              entry?.user?.l_name !== null
                ? entry?.user?.f_name + " " + entry?.user?.l_name
                : entry?.user?.f_name;
            tableObject.id = entry.user?.id;
            tableObject.user_id = entry.user?.id;
            tableObject.updatedBy =
              entry?.updated_by_user?.l_name !== null
                ? entry?.updated_by_user?.f_name +
                " " +
                entry?.updated_by_user?.l_name
                : entry?.updated_by_user?.f_name;
            tableObject.updatedOn = moment(entry?.updated_at).format(
              "MMM DD, yyyy"
            );
            tableObject.updatedTime = moment(entry?.updated_at).format(
              "HH:mm a"
            );
            tableObject.role = entry?.user?.role?.description;
            tableObject.oldInfo = entry?.old_info;
            tableObject.newInfo = entry?.updated_info;
            tableObject.id = entry?.id;
            tableArray.push(tableObject);
            tableObject = {};
          } else {
            tableObject.name =
              entry?.user?.l_name !== null
                ? entry?.user?.f_name + " " + entry?.user?.l_name
                : entry?.user?.f_name;
            tableObject.id = entry.user?.id;
            tableObject.updatedBy =
              entry?.updated_by_user?.l_name !== null
                ? entry?.updated_by_user?.f_name +
                " " +
                entry?.updated_by_user?.l_name
                : entry?.updated_by_user?.f_name;
            tableObject.approvedBy =
              entry?.userApprovedBy === null
                ? ""
                : entry?.approved_by_user &&
                  entry?.approved_by_user?.l_name !== null &&
                  entry?.approved_by_user?.f_name !== null
                  ? entry?.approved_by_user?.f_name +
                  " " +
                  entry?.approved_by_user?.l_name
                  : entry?.approved_by_user?.f_name;
            tableObject.updatedOn = moment(entry?.updated_at).format(
              "MMM DD, yyyy"
            );
            if (
              entry?.approved_at !== null &&
              entry?.approved_at !== undefined &&
              moment(entry.approved_at).isValid()
            ) {
              tableObject.approvedOn = moment(entry.approved_at).format(
                "MMM DD, yyyy"
              );
              tableObject.approvedTime = moment(entry.approved_at).format(
                "HH:mm a"
              );
            } else {
              tableObject.approvedOn = "";
              tableObject.approvedTime = "";
            }
            tableObject.rejectedBy =
              entry?.userRejectedBy === null
                ? ""
                : entry?.userRejectedBy?.l_name !== null
                  ? entry?.userRejectedBy?.f_name +
                  " " +
                  entry?.userRejectedBy?.l_name
                  : entry?.userRejectedBy?.f_name;
            if (
              entry?.rejected_at !== null &&
              entry?.rejected_at !== undefined &&
              moment(entry.rejected_at).isValid()
            ) {
              tableObject.rejectedOn = moment(entry.rejected_at).format(
                "MMM DD, yyyy"
              );
              tableObject.rejectedTime = moment(entry.rejected_at).format(
                "HH:mm a"
              );
            } else {
              tableObject.rejectedOn = "";
              tableObject.rejectedTime = "";
            }
            tableObject.updatedTime = moment(entry?.updated_at).format(
              "HH:mm a"
            );
            tableObject.role = entry?.user?.role?.description;
            tableObject.oldInfo = entry?.old_info;
            tableObject.newInfo = entry?.updated_info;
            tableObject.id = entry?.id;
            tableArray.push(tableObject);
            tableObject = {};
          }
        }
      });
      setTableData(tableArray);
    }
    // eslint-disable-next-line
  }, [data]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: columnStatus?.pending?.name?.enabled
        ? "table-cell"
        : "hidden",
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-sm text-textLight">
              {record.role}
            </div>
            <div className="text-base text-textDark font-normal">
              {record.name}
            </div>
          </div>
        </div>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "OLD INFO",
      dataIndex: "oldInfo",
      className: columnStatus?.pending?.oldInfo?.enabled
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.oldInfo?.client &&
          Object.keys(recordCopy?.oldInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;

          for (k in recordCopy?.oldInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                (recordCopy?.oldInfo?.user[k] !==
                  recordCopy?.newInfo?.user[k] ||
                  // eslint-disable-next-line
                  k !== k) &&
                recordCopy?.oldInfo?.user[k] !== "" &&
                recordCopy?.oldInfo?.user[k] !== null
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !== moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.newInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.oldInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.oldInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }
          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>Phone - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            }
            else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            }
            else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            }
            //  else if (keys[index] === "payment") {
            //   return <p>Mode of Payment - {value}</p>;
            // }
            else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            }
            else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            }
            else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            }
            else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "noOfBeds") {
              return <p>No Of Beds - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            }
            else {
              // Return null if data is the same (won't render the column)
              return null;
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.oldInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED INFO",
      dataIndex: "newInfo",
      className: columnStatus?.pending?.newInfo?.enabled
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.newInfo?.client &&
          Object.keys(recordCopy?.newInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          for (k in recordCopy?.newInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                (recordCopy?.oldInfo?.user[k] !==
                  recordCopy?.newInfo?.user[k] ||
                  // eslint-disable-next-line
                  k !== k) &&
                recordCopy?.newInfo?.user[k] !== "" &&
                recordCopy?.newInfo?.user[k] !== null
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !==
                    moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.newInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.oldInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.newInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.newInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }

          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;

          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>Phone - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            }
            else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            }
            else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            }
            // else if (keys[index] === "payment") {
            //   return <p>Mode of Payment - {value}</p>;
            // }
            else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            }
            else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            }
            else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            }
            else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "noOfBeds") {
              return <p>No Of Beds - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            } else {
              // Return null if data is the same (won't render the column)
              return null;
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.newInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED BY",
      dataIndex: "updatedBy",
      className: columnStatus?.pending?.updatedBy?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnSearchProps("updatedBy"),
    },
    {
      title: "UPDATED ON",
      dataIndex: "updatedOn",
      className: columnStatus?.pending?.updatedOn?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnDateSearchProps("updatedOn"),
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-textDark text-base">
              {record.updatedOn}
            </div>
            <div className="text-textLight text-sm font-normal">
              {record.updatedTime}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "ACTION",
      width: 270,
      render: (_, record) => (
        <>
          <span>
            <Button
              type="primary"
              className="mx-2"
              onClick={() => {
                editUser({
                  variables: {
                    id: [record?.user_id],
                    object: false,
                  },
                });
                updateApprovals({
                  variables: {
                    id: [record?.id],
                    status: "Approved",
                    approvedBy_id: id,
                    approved_at: formattedTime,
                  },
                })
                  .then(() => {
                    window.location.reload();
                  })
                  .catch(() => {
                    notification.error({
                      message: "An error occurred",
                    });
                  });
              }}
            >
              Approve
            </Button>
          </span>
          <span>
            <Button
              type="ghost"
              className="mx-2"
              onClick={() => {
                editUser({
                  variables: {
                    id: [record?.user_id],
                    object: false,
                  },
                });
                rejectApprovals({
                  variables: {
                    id: [record?.id],
                    status: "Rejected",
                    rejected_by_id: id,
                    rejected_at: formattedTime,
                  },
                })
                  .then(() => {
                    window.location.reload();
                  })
                  .catch(() => {
                    notification.error({
                      message: "An error occurred",
                    });
                  });
              }}
            >
              Reject
            </Button>
          </span>
        </>
      ),
    },
  ];

  const updatedColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.name?.enabled
          : columnStatus?.rejected?.name?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-sm text-textLight">
              {record.role}
            </div>
            <div className="text-base text-textDark font-normal">
              {record.name}
            </div>
          </div>
        </div>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "OLD INFO",
      dataIndex: "oldInfo",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.oldInfo?.enabled
          : columnStatus?.rejected?.oldInfo?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.oldInfo?.client &&
          Object.keys(recordCopy?.oldInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          for (k in recordCopy?.oldInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                recordCopy?.oldInfo?.user[k] !== recordCopy?.newInfo?.user[k] ||
                // eslint-disable-next-line
                k !== k
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !==
                    moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.newInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.oldInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.oldInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }

          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;

          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>Phone - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            } else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            } else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            } else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            } else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            } else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            } else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.oldInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED INFO",
      dataIndex: "newInfo",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.newInfo?.enabled
          : columnStatus?.rejected?.newInfo?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.newInfo?.client &&
          Object.keys(recordCopy?.newInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          for (k in recordCopy?.newInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                recordCopy?.oldInfo?.user[k] !== recordCopy?.newInfo?.user[k] ||
                // eslint-disable-next-line
                k !== k
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !==
                    moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.newInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo &&
                    recordCopy?.newInfo &&
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.oldInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.newInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.newInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }
          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;
          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>phone - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            } else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            } else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            } else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            } else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            } else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            } else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.newInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED BY",
      dataIndex: "updatedBy",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.updatedBy?.enabled
          : columnStatus?.rejected?.updatedBy?.enabled
      )
        ? "table-cell"
        : "hidden",

      ...getColumnSearchProps("updatedBy"),
    },
    {
      title: "UPDATED ON",
      dataIndex: "updatedOn",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.updatedOn?.enabled
          : columnStatus?.rejected?.updatedOn?.enabled
      )
        ? "table-cell"
        : "hidden",
      ...getColumnDateSearchProps("updatedOn"),
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-textDark text-base">
              {record.updatedOn}
            </div>
            <div className=" text-textLight text-sm font-normal">
              {record.updatedTime}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "APPROVED BY",
      dataIndex: "approvedBy",
      className: columnStatus?.pending?.approvedBy?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnSearchProps("approvedBy"),
    },
    {
      title: "APPROVED ON",
      dataIndex: "approvedOn",
      className: columnStatus?.pending?.approvedOn?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnDateSearchProps("approvedOn"),
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-textDark text-base">
              {record.approvedOn}
            </div>
            <div className="text-textLight text-sm font-normal">
              {record.approvedTime}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const rejectedColumns = [
    {
      title: "NAME",
      dataIndex: "name",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.name?.enabled
          : columnStatus?.rejected?.name?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-sm text-textLight">
              {record.role}
            </div>
            <div className="text-base text-textDark font-normal">
              {record.name}
            </div>
          </div>
        </div>
      ),
      ...getColumnSearchProps("name"),
    },
    {
      title: "OLD INFO",
      dataIndex: "oldInfo",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.oldInfo?.enabled
          : columnStatus?.rejected?.oldInfo?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.oldInfo?.client &&
          Object.keys(recordCopy?.oldInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          for (k in recordCopy?.oldInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                recordCopy?.oldInfo?.user[k] !== recordCopy?.newInfo?.user[k] ||
                // eslint-disable-next-line
                k !== k
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !==
                    moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.newInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.oldInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.oldInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }
          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;
          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>Phone - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            } else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            } else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            } else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            } else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            } else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            } else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.oldInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED INFO",
      dataIndex: "newInfo",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.newInfo?.enabled
          : columnStatus?.rejected?.newInfo?.enabled
      )
        ? "table-cell"
        : "hidden",
      render: (_, record) => {
        let recordCopy = JSON.parse(JSON.stringify(record));
        let k,
          values = [],
          keys = [],
          oldAilments = [];
        if (
          recordCopy?.newInfo?.client &&
          Object.keys(recordCopy?.newInfo?.client)?.length === 0
        ) {
          recordCopy.oldInfo.user = {
            ...recordCopy.oldInfo.user,
            ...recordCopy.oldInfo.user.meta_date,
            ...recordCopy.oldInfo.user.additional_data,
          };
          recordCopy.newInfo.user = {
            ...recordCopy.newInfo.user,
            ...recordCopy.newInfo.user.meta_date,
            ...recordCopy.newInfo.user.additional_data,
          };
          for (k in recordCopy?.newInfo?.user) {
            if (k !== "additional_data" && k !== "meta_date") {
              if (
                recordCopy?.oldInfo?.user[k] !== recordCopy?.newInfo?.user[k] ||
                // eslint-disable-next-line
                k !== k
              ) {
                if (k === "year_of_birth") {
                  if (
                    moment(recordCopy?.oldInfo?.user[k]).format(
                      "YYYY-MM-DD"
                    ) !==
                    moment(recordCopy?.newInfo?.user[k]).format("YYYY-MM-DD")
                  ) {
                    values.push(recordCopy?.oldInfo?.user[k]);
                    keys.push(k);
                  }
                } else if (k === "ailments") {
                  if (
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length !==
                    recordCopy?.newInfo?.user[k]
                  ) {
                    values.push(recordCopy?.newInfo?.user[k]);
                    keys.push(k);
                  } else if (
                    recordCopy?.oldInfo &&
                    recordCopy?.newInfo &&
                    recordCopy?.oldInfo?.user[k] &&
                    recordCopy?.oldInfo?.user[k].length ===
                    recordCopy?.newInfo?.user[k]
                  ) {
                    // eslint-disable-next-line
                    recordCopy?.oldInfo?.user[k].forEach((item) => {
                      if (!recordCopy?.newInfo?.user[k].includes(item)) {
                        oldAilments.push(item);
                      }
                    });
                    keys.push(k);
                  }
                } else {
                  values.push(recordCopy?.newInfo?.user[k]);
                  keys.push(k);
                }
              }
            }
          }
          const oldCompatibility = record.oldInfo.user.project_compatibilitys;
          const newCompatibility = record.newInfo.user.project_compatibilitys;
          const isCompatibilitySame = JSON.stringify(oldCompatibility) === JSON.stringify(newCompatibility);
          const oldYearOfPractice = moment(record.oldInfo.user.yearOfPractice).format("YYYY");
          const newYearOfPractice = moment(record.newInfo.user.yearOfPractice).format("YYYY");
          const isYearOfPracticeSame = oldYearOfPractice === newYearOfPractice;
          const oldAilments = record.oldInfo.user.ailments || [];
          const newAilments = record.newInfo.user.ailments || [];
          const areAilmentsDifferent = JSON.stringify(oldAilments) !== JSON.stringify(newAilments);
          const oldValidatedDate = moment(record.oldInfo.user.validatedDate).format("DD/MM/YYYY");
          const newValidatedDate = moment(record.newInfo.user.validatedDate).format("DD/MM/YYYY");
          const isValidatedDateSame = oldValidatedDate === newValidatedDate;
          // eslint-disable-next-line
          return values.map((value, index) => {
            if (keys[index] === "status") {
              return <p>Status - {value}</p>;
            } else if (keys[index] === "year_of_birth") {
              return (
                <p>Year of birth - {moment(value).format("YYYY-MM-DD")}</p>
              );
            } else if (keys[index] === "f_name") {
              return <p>Name - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "email") {
              return <p>Email - {value}</p>;
            } else if (keys[index] === "email2") {
              return <p>Secondary Email - {value}</p>;
            } else if (keys[index] === "phone") {
              return <p>phone - {value}</p>;
            } else if (keys[index] === "gender") {
              return <p>Gender - {value}</p>;
            } else if (keys[index] === "title") {
              return <p>Title - {value}</p>;
            } else if (keys[index] === "state") {
              return <p>State - {value}</p>;
            } else if (keys[index] === "city") {
              return <p>City - {value}</p>;
            } else if (keys[index] === "pincode") {
              return <p>Pincode - {value}</p>;
            } else if (keys[index] === "access") {
              return (
                <p>
                  Access to mobile app -{" "}
                  {value === true ? <p>Yes</p> : <p>No</p>}
                </p>
              );
            } else if (areAilmentsDifferent) {
              return (
                <p>
                  Ailments -{" "}
                  {newAilments.map((item) => {
                    return <p key={item}>{item}</p>;
                  })}
                </p>
              );
            } else if (keys[index] === "organisation") {
              return <p>Organisation - {value}</p>;
            } else if (keys[index] === "sub") {
              return <p>Sub category - {value}</p>;
            } else if (keys[index] === "sub2") {
              return <p>Sub category 2 - {value}</p>;
            } else if (keys[index] === "profession") {
              return <p>Profession - {value}</p>;
            } else if (keys[index] === "experience") {
              return <p>Experience - {value}</p>;
            } else if (keys[index] === "phone_number") {
              return <p>Mobile 1 - {value}</p>;
            } else if (keys[index] === "mobile2") {
              return <p>Mobile 2 - {value}</p>;
            } else if (keys[index] === "hospital") {
              return <p>Name of the hospital - {value}</p>;
            } else if (keys[index] === "hospital1") {
              return <p>Consulting hospital 1 - {value}</p>;
            } else if (keys[index] === "hospital2") {
              return <p>Consulting hospital 2 - {value}</p>;
            } else if (keys[index] === "hospitalType") {
              return <p>Hospital type - {value}</p>;
            } else if (!isValidatedDateSame) {
              return (
                <p>Validated date - {newValidatedDate}</p>
              );
            } else if (keys[index] === "totalIncentive") {
              return <p>Total incentive processed - {value}</p>;
            } else if (!isYearOfPracticeSame) {
              return (
                <p>Year of Practice - {newYearOfPractice}</p>
              );
            } else if (keys[index] === "zone") {
              return <p>Zone - {value}</p>;
            } else if (keys[index] === "cityCategory") {
              return <p>City category - {value}</p>;
            } else if (keys[index] === "previousStudies") {
              return <p>Previously participated studies - {value}</p>;
            } else if (keys[index] === "primary_specialty") {
              return (
                <p>
                  Primary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "secondary_specialty") {
              return (
                <p>
                  Secondary speciality -{" "}
                  {speciality?.specialties
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.speciality_name}</span>;
                      }
                    })}
                </p>
              );
            }
            else if (!isCompatibilitySame && !hasRendered) {
              const compatibilitys = record.newInfo.user.project_compatibilitys;
              if (compatibilitys && compatibilitys.length > 0) {
                setHasRendered(true);
                return (
                  <p>Project compatibility - {compatibilitys.join(",")}</p>
                );
              }
            }
          });
        } else {
          for (k in recordCopy?.newInfo?.client) {
            if (k !== "data" && k !== "role_id") {
              if (
                recordCopy?.oldInfo?.client[k] !==
                recordCopy?.newInfo?.client[k]
              ) {
                values.push(recordCopy?.newInfo?.client[k]);
                keys.push(k);
              }
            }
          }
          return values.map((value, index) => {
            if (keys[index] === "country") {
              return (
                <p>
                  Country -{" "}
                  {country?.country
                    // eslint-disable-next-line
                    ?.map((item) => {
                      if (item.id === value) {
                        return <span>{item.name}</span>;
                      }
                    })}
                </p>
              );
            } else if (keys[index] === "billing_cycle") {
              return (
                <p>
                  Billing cycle -{" "}
                  {billingCycle?.billing_cycle
                    // eslint-disable-next-line
                    ?.map((cycle) => {
                      if (cycle.id === value) {
                        return <span>{cycle.values}</span>;
                      }
                    })}
                </p>
              );
            } else {
              return (
                <p>
                  {keys[index]} - {value}
                </p>
              );
            }
          });
        }
      },
    },
    {
      title: "UPDATED BY",
      dataIndex: "updatedBy",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.updatedBy?.enabled
          : columnStatus?.rejected?.updatedBy?.enabled
      )
        ? "table-cell"
        : "hidden",

      ...getColumnSearchProps("updatedBy"),
    },
    {
      title: "UPDATED ON",
      dataIndex: "updatedOn",
      className: (
        activeTab === "approved"
          ? columnStatus?.approved?.updatedOn?.enabled
          : columnStatus?.rejected?.updatedOn?.enabled
      )
        ? "table-cell"
        : "hidden",
      ...getColumnDateSearchProps("updatedOn"),
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-textDark text-base">
              {record.updatedOn}
            </div>
            <div className=" text-textLight text-sm font-normal">
              {record.updatedTime}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "REJECTED BY",
      dataIndex: "rejectedBy",
      className: columnStatus?.pending?.rejectedBy?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnSearchProps("rejectedBy"),
    },
    {
      title: "REJECTED ON",
      dataIndex: "rejectedOn",
      className: columnStatus?.pending?.rejectedOn?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnDateSearchProps("rejectedOn"),
      render: (_, record) => (
        <div>
          <div>
            <div className="font-medium text-textDark text-base">
              {record.rejectedOn}
            </div>
            <div className="text-textLight text-sm font-normal">
              {record.rejectedTime}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const onChange = (e) => {
    let tableObject = {},
      tableArray = [];
    if (e === "approved") {
      setActiveTab("approved");
      if (data) {
        data?.approval.forEach((entry) => {
          if (entry.status === "Approved") {
            tableObject.name =
              entry?.user?.l_name !== null
                ? entry?.user?.f_name + " " + entry?.user?.l_name
                : entry?.user?.f_name;
            tableObject.id = entry.user?.id;
            tableObject.updatedBy =
              entry?.updated_by_user?.l_name !== null
                ? entry?.updated_by_user?.f_name +
                " " +
                entry?.updated_by_user?.l_name
                : entry?.updated_by_user?.f_name;
            tableObject.updatedOn = moment(entry?.updated_at).format(
              "MMM DD, yyyy"
            );
            tableObject.updatedTime = moment(entry?.updated_at).format(
              "HH:mm a"
            );
            tableObject.approvedBy =
              entry?.userApprovedBy === null
                ? ""
                : entry?.userApprovedBy?.l_name !== null
                  ? entry?.userApprovedBy?.f_name +
                  " " +
                  entry?.userApprovedBy?.l_name
                  : entry?.userApprovedBy?.f_name;
            if (
              entry?.approved_at !== null &&
              entry?.approved_at !== undefined &&
              moment(entry.approved_at).isValid()
            ) {
              tableObject.approvedOn = moment(entry.approved_at).format(
                "MMM DD, yyyy"
              );
              tableObject.approvedTime = moment(entry.approved_at).format(
                "HH:mm a"
              );
            } else {
              tableObject.approvedOn = "";
              tableObject.approvedTime = "";
            }
            tableObject.role = entry?.user?.role?.description;
            tableObject.oldInfo = entry?.old_info;
            tableObject.newInfo = entry?.updated_info;
            tableObject.id = entry?.id;
            tableArray.push(tableObject);
            tableObject = {};
          }
        });
        setTableData(tableArray);
      }
    } else if (e === "rejected") {
      setActiveTab("rejected");
      if (data) {
        data?.approval.forEach((entry) => {
          if (entry.status === "Rejected") {
            tableObject.name =
              entry?.user?.l_name !== null
                ? entry?.user?.f_name + " " + entry?.user?.l_name
                : entry?.user?.f_name;
            tableObject.id = entry.user?.id;
            tableObject.updatedBy =
              entry?.updated_by_user?.l_name !== null
                ? entry?.updated_by_user?.f_name +
                " " +
                entry?.updated_by_user?.l_name
                : entry?.updated_by_user?.f_name;
            tableObject.updatedOn = moment(entry?.updated_at).format(
              "MMM DD, yyyy"
            );
            tableObject.updatedTime = moment(entry?.updated_at).format(
              "HH:mm a"
            );
            tableObject.rejectedBy =
              entry?.userRejectedBy === null
                ? ""
                : entry?.userRejectedBy?.l_name !== null
                  ? entry?.userRejectedBy?.f_name +
                  " " +
                  entry?.userRejectedBy?.l_name
                  : entry?.userRejectedBy?.f_name;
            if (
              entry?.rejected_at !== null &&
              entry?.rejected_at !== undefined &&
              moment(entry.rejected_at).isValid()
            ) {
              tableObject.rejectedOn = moment(entry.rejected_at).format(
                "MMM DD, yyyy"
              );
              tableObject.rejectedTime = moment(entry.rejected_at).format(
                "HH:mm a"
              );
            } else {
              tableObject.rejectedOn = "";
              tableObject.rejectedTime = "";
            }
            tableObject.role = entry?.user?.role?.description;
            tableObject.oldInfo = entry?.old_info;
            tableObject.newInfo = entry?.updated_info;
            tableArray.push(tableObject);
            tableObject.id = entry?.id;
            tableObject = {};
          }
        });
        setTableData(tableArray);
      }
    } else {
      setActiveTab("pending");
      if (data) {
        data?.approval.forEach((entry) => {
          if (entry.status === "Pending") {
            tableObject.name =
              entry?.user?.l_name !== null
                ? entry?.user?.f_name + " " + entry?.user?.l_name
                : entry?.user?.f_name;
            tableObject.id = entry.user?.id;
            tableObject.updatedBy =
              entry?.updated_by_user?.l_name !== null
                ? entry?.updated_by_user?.f_name +
                " " +
                entry?.updated_by_user?.l_name
                : entry?.updated_by_user?.f_name;
            tableObject.updatedOn = moment(entry?.updated_at).format(
              "MMM DD, yyyy"
            );
            tableObject.updatedTime = moment(entry?.updated_at).format(
              "HH:mm a"
            );
            tableObject.approvedBy =
              entry?.userApprovedBy === null
                ? ""
                : entry?.userApprovedBy?.l_name !== null
                  ? entry?.userApprovedBy?.f_name +
                  " " +
                  entry?.userApprovedBy?.l_name
                  : entry?.userApprovedBy?.f_name;
            if (
              entry?.approved_at !== null &&
              entry?.approved_at !== undefined &&
              moment(entry.approved_at).isValid()
            ) {
              tableObject.approvedOn = moment(entry.approved_at).format(
                "MMM DD, yyyy"
              );
              tableObject.approvedTime = moment(entry.approved_at).format(
                "HH:mm a"
              );
            } else {
              tableObject.approvedOn = "";
              tableObject.approvedTime = "";
            }
            tableObject.role = entry?.user?.role?.description;
            tableObject.oldInfo = entry?.old_info;
            tableObject.newInfo = entry?.updated_info;
            tableObject.id = entry?.id;
            tableArray.push(tableObject);
            tableObject = {};
          }
        });
        setTableData(tableArray);
      }
    }
  };

  const userIDs = selectedMembers.map((member) => member.user_id);

  const handleBulkReject = () => {
    editUser({
      variables: {
        id: userIDs,
        object: false,
      },
    });
    rejectApprovals({
      variables: {
        id: selectedMemberKeys,
        status: "Rejected",
        rejected_by_id: id,
        rejected_at: formattedTime,
      },
    })
      .then(() => {
        window.location.reload();
        notification.success({
          message: "Rejected successfully",
        });
      })
      .catch(() => {
        notification.error({
          message: "An error occurred",
        });
      });
  };

  const handleBulkApprove = () => {
    editUser({
      variables: {
        id: userIDs,
        object: false,
      },
    });
    updateApprovals({
      variables: {
        id: selectedMemberKeys,
        status: "Approved",
        approvedBy_id: id,
        approved_at: formattedTime,
      },
    })
      .then(() => {
        window.location.reload();
        notification.success({
          message: "Approved successfully",
        });
      })
      .catch(() => {
        notification.error({
          message: "An error occurred",
        });
      });
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between mb-4">
        <span className="text-textMedium text-h4 font-semibold">Approvals</span>
        <div>
          {!!selectedMembers?.length && (
            <>
              <Button type="ghost" className="mx-2" onClick={handleBulkReject}>
                Bulk Reject
              </Button>
              <Button
                type="primary"
                className="mx-2"
                onClick={handleBulkApprove}
              >
                Bulk Approve
              </Button>
            </>
          )}
          <ColumnEditor
            columns={columnStatus[activeTab]}
            onSave={(column) => {
              setStatus({
                ...columnStatus,
                [activeTab]: column,
              });
            }}
          />
        </div>
      </div>
      <Row gutter={16}>
        <Col span={24}>
          <Card>
            <Tabs
              defaultActiveKey={activeTab}
              size="large"
              tabBarGutter={24}
              onChange={onChange}
            >
              <TabPane
                key="pending"
                tab={<span className="px-4">Pending</span>}
              >
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                      }
                  }
                  rowKey={(record) => record.id}
                  rowSelection={{
                    selectedRowKeys: selectedMemberKeys,
                    type: "checkbox",
                    onChange: (selectedRowKeys, selectedRows, info) => {
                      setSelectedMemberKeys(selectedRowKeys);
                      setSelectedMembers(selectedRows);
                    },
                  }}
                />
              </TabPane>
              <TabPane
                key="approved"
                tab={<span className="px-4">Approved</span>}
              >
                <Table
                  columns={updatedColumns}
                  dataSource={tableData}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                      }
                  }
                />
              </TabPane>
              <TabPane
                key="rejected"
                tab={<span className="px-4">Rejected</span>}
              >
                <Table
                  columns={rejectedColumns}
                  dataSource={tableData}
                  pagination={
                    loading
                      ? false
                      : {
                        showTotal: (total, range) => {
                          return (
                            <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                          );
                        },
                      }
                  }
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Approvals;