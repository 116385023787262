import { Modal } from 'antd';

export const MemberStatus = (
    selectedOption,
    formValues,
    raw,
    partialEmail,
    partialPhone,
    notValidated,
    inValid,
    valid,
    DNC,
    Prohibited
) => {
    const {
        email1,
        mobile1,
        firstName,
        lastName,
        gender,
        state,
        city,
        cityCategory,
        primarySpeciality,
        superSpeciality,
        hospitalName,
        hospitalType,
        noOfBeds
    } = formValues;

    if (selectedOption === 'raw' && raw) {
        showErrorNotification('Status mismatch, please check email or phone');
    } else if (selectedOption === 'partial-email' && partialEmail) {
        showErrorNotification('Status mismatch, please check email');
    } else if (selectedOption === 'partial-phone' && partialPhone) {
        showErrorNotification('Status mismatch, please check phone');
    } else if (selectedOption === 'not-validated' && notValidated) {
        showErrorNotification('Status mismatch, please check email or phone');
    } else if (selectedOption === 'invalid' && inValid) {
        showErrorNotification('Record has been taken for validation by Interviewer but marked as Invalid');
    } else if (selectedOption === 'Valid' && valid) {
        showErrorNotification('Status mismatch, please check');
    } else if (selectedOption === 'DNC' && DNC) {
        showErrorNotification('Status mismatch, please check email or phone');
    } else if (selectedOption === 'Prohibited' && Prohibited) {
        showErrorNotification('Status mismatch, please check email or phone');
    } else {
        return true;
    }

    return false;
};

export const showErrorNotification = (message) => {
    Modal.confirm({
        title: 'Status mismatch',
        content: (
            <div className="show_error_modal_content">
                {message}
            </div>
        ),
        centered: true,
        className: 'show_error_modal',
        okText: 'Proceed',
        cancelButtonProps: { style: { display: 'none' } },
    });
};