import { gql } from "@apollo/client";

const GET_SURVEY_ONLINE = gql`
  query get_survey($survey_id: Int!, $member_id: Int) {
    survey_by_pk(id: $survey_id) {
      name
      id
      description
      consent
      display_questions_on_survey_cards
      consent_title
      start_date
      end_date
      links_status
      survey_x_members(where: { member_id: { _eq: $member_id }}) {
        quota
        state
        completed
        member_id
        survey_url
        user {
          id
          f_name
          l_name
          email
          phone_number
          role_id
          member_type_id
          member_type {
            id
            name
            description
          }
        }
      }
      questions(order_by: { index: asc }) {
        file_limit
        id
        is_file_option
        is_mandatory
        question
        validation
        answers(order_by: { created_at: desc }, limit: 1) {
          answer
          file_url
          id
          member_id
          created_at
          more_info
          question_id
          updated_at
          patient
          patientByPatient {
            status
            id
            member_id
            survey_id
            created_at
            updated_at
          }
        }
        question_type {
          id
          description
          name
          created_at
        }
        questions_options(order_by: { index: asc }) {
          logic
          more_info
          options
          questions_id
          label
          id
          index
          range_value
          format
          range
          refrence
        }
        survey_id
        index
        updated_at
      }
      survey_x_members_aggregate(where: { state: { _in: ["completed"] } }) {
        aggregate {
          count
        }
      }
      project {
        sample
        include_profiler_questions
        survey_type
        projectTypeByProjectType {
          name
        }
      }
    }
  }
`;

const START_SURVEY = gql`
  mutation changeSurveyStatusToInProgress($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "in-progress" }
    ) {
      affected_rows
    }
  }
`;

const COMPLETE_SURVEY = gql`
  mutation changeSurveyStatusToComplete($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "completed", completed: 1 }
    ) {
      affected_rows
    }
  }
`;

const SCREEN_SURVEY = gql`
  mutation changeSurveyStatusToScreened($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "screened", completed: 1 }
    ) {
      affected_rows
    }
  }
`;

const QUOTA_FULL = gql`
  mutation changeSurveyStatusToQuotaFull($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "quotaFull" }
    ) {
      affected_rows
    }
  }
`;

const INSERT_ANSWER = gql`
  mutation insert_new_answer(
    $answer: String
    $fileUrl: String
    $memberId: Int!
    $moreInfo: jsonb!
    $documents: jsonb
    $questionId: Int!
  ) {
    delete_answers(
      where: {
        question_id: { _eq: $questionId }
        member_id: { _eq: $memberId }
      }
    ) {
      affected_rows
    }
    insert_answers_one(
      object: {
        answer: $answer
        file_url: $fileUrl
        member_id: $memberId
        more_info: $moreInfo
        question_id: $questionId
        documents: $documents
      }
    ) {
      id
    }
  }
`;

const UPDATE_MEMBER = gql`
  mutation ($user_id: Int!, $object: user_set_input) {
    update_user_by_pk(pk_columns: { id: $user_id }, _set: $object) {
      id
    }
  }
`;

const GET_MEMBER = gql`
  query get_member_details($user_id: Int!) {
    user_by_pk(id: $user_id) {
      additional_data
      city
      email
      respondent_id
      f_name
      gender
      id
      is_active
      is_verified
      l_name
      language
      member_type_id
      meta_date
      phone_number
      pincode
      primary_specialty
      project_compatibility
      project_compatibilitys
      secondary_specialty
      state
      title
      year_of_birth
    }
    ailments {
      id
      ailment
    }
    specialties {
      id
      speciality_name
    }
    project_compatibility {
      id
      project_compatibility_text
    }
  }
`;

const FETCH_ANSWER = gql`
  query FETCH_ANSWER($questionID: Int, $memberID: Int, $surveyID: Int) {
    questions(
      where: { id: { _eq: $questionID }, survey_id: { _eq: $surveyID } }
    ) {
      answers(where: { member_id: { _eq: $memberID } }) {
        more_info
        answer
        documents
        file_url
        id
      }
    }
  }
`;
const RESPONSE_ANSWERS_ONLINE_VALIDATION = gql`
  query RESPONSE_ONLINE($id: Int!, $member_id: Int) {
    survey_by_pk(id: $id) {
      name
      questions(order_by: { index: asc }) {
        question
        answers(where: { member_id: { _eq: $member_id } }) {
          answer
          file_url
          id
          more_info
        }
        question_type {
          name
        }
      }
    }
  }
`;
const EXIT_SURVEY = gql`
  mutation EXIT_SURVEY($surveyId: Int!, $memberId: Int!, $questionID: [Int!]) {
    delete_answers(
      where: {
        _and: {
          member_id: { _eq: $memberId }
          question_id: { _in: $questionID }
        }
      }
    ) {
      affected_rows
    }
    update_survey_x_member(
      where: {
        _and: { survey_id: { _eq: $surveyId }, member_id: { _eq: $memberId } }
      }
      _set: { state: "active", completed: 0 }
    ) {
      affected_rows
    }
  }
`;

const QUOTA_FOR_CHOICE = gql`
  query MyQuery($questionId: Int!, $option: String!, $answerId: Int!) {
    answers_aggregate(
      where: {
        answer: { _like: $option }
        question_id: { _eq: $questionId }
        _not: { id: { _eq: $answerId } }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export {
  GET_SURVEY_ONLINE,
  START_SURVEY,
  INSERT_ANSWER,
  COMPLETE_SURVEY,
  SCREEN_SURVEY,
  UPDATE_MEMBER,
  GET_MEMBER,
  QUOTA_FULL,
  FETCH_ANSWER,
  RESPONSE_ANSWERS_ONLINE_VALIDATION,
  EXIT_SURVEY,
  QUOTA_FOR_CHOICE,
};

export const GET_SURVEY_EMAIL_TEMPLATE = gql`
  query emailTemplateBySurvey($surveyId: Int!) {
    survey_by_pk(id: $surveyId) {
      id
      mail_data
      mail_template
      reminder_mail_data
      reminder_mail_template
    }
  }
`;

export const UPDATE_SURVEY_EMAIL_TEMPLATE = gql`
  mutation updateSurveyEmailTemplate($id: Int!, $object: survey_set_input) {
    update_survey_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
    }
  }
`;
export const GET_EMAIL_PHONE = gql`
  query GET_EMAIL_PHONE {
    user {
      email
      phone_number
    }
  }
`;

export const GET_CLIENT = gql`
  query GET_CLIENT($project_id: Int) {
    project(where: { id: { _eq: $project_id } }) {
      survey_type
    }
  }
`;

export const UPDATE_INTERVIEWER_STATUS = gql`
  mutation UPDATE_STATUS(
    $member_id: Int
    $interviewer_id: Int
    $status: String
    $survey_id: Int
  ) {
    update_survey_x_member_x_interviewer(
      where: {
        _and: [
          {
            survey_x_member: {
              _and: [
                { member_id: { _eq: $member_id } }
                { survey_id: { _eq: $survey_id } }
              ]
            }
          }
          { interviewer_id: { _eq: $interviewer_id } }
        ]
      }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`;
