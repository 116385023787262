import React, { useState } from "react";
import { Upload, Button } from "antd";
import { useMutation, useQuery } from "@apollo/client";
import { GET_UPLOAD_URL } from "../../../pages/createProject/graphql";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { surveyState } from "../../../atoms";
import { useParams } from "react-router-dom";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const FileUpload = (props) => {
  const params = useParams();
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [fileList, setFileList] = useState([]);
  // eslint-disable-next-line
  const [fileURL, setFileURL] = useState([]);
  const [data, setData] = useState([]);
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    if (props) {
      let copy = JSON.parse(JSON.stringify(props.questions_options));
      copy?.forEach((question) => {
        question.fileSize =
          question?.options?.fileUploadValue?.fileSize || null;
        let fileTypesAllowed = "";
        question?.options?.fileUploadValue?.fileTypesAllowed.forEach((type) => {
          const array = type.split("/");
          array.forEach((item) => {
            if (item === "video") {
              fileTypesAllowed = fileTypesAllowed + ".mp4,.3gp,";
            } else if (item === "audio") {
              fileTypesAllowed = fileTypesAllowed + ".mp3,.wav,";
            } else {
              fileTypesAllowed = fileTypesAllowed + `.${item},`;
            }
            question.fileTypesAllowed = fileTypesAllowed;
          });
        });
      });
      setData(copy);
    }
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    // eslint-disable-next-line
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      if (answers?.questions[0]?.answers[0]?.documents !== null) {
        // eslint-disable-next-line
        answers?.questions[0]?.answers[0].documents?.map((file, index) => {
          // eslint-disable-next-line
          file[0].documents.map((item, i) => {
            let fileName = file[1]?.responseUrl[i].split("/");
            copy.push({
              uid: item.uid,
              name: fileName[fileName.length - 1],
              status: item.status,
              responseUrl: file[1]?.responseUrl[i],
            });
          });
        });
      }
    });
    setFileList(copy);

    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      answer: "",
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
      documents:
        answers?.questions[0]?.answers[0]?.documents !== undefined
          ? answers?.questions[0]?.answers[0]?.documents
          : [],
    }));
    // eslint-disable-next-line
  }, [answers]);

  const handleOnChange = (info, label, i) => {
    let newFileList = [];
    let documentObject = [];
    let newDocumentArray = [];
    let newFileArray = [];
    let current_answer_copy = [...surveyDetails.current_answer];
    let copy = {};
    let copyDocument = {};

    if (info.fileList) {
      info.fileList.map((file, i) => {
        if (file.status === "done") {
          newFileArray.push(
            file.responseUrl
              ? file.responseUrl
              : file.xhr.responseURL.split("?")[0]
          );
          newDocumentArray.push(file);
          newFileList.push(
            file.responseUrl
              ? file.responseUrl
              : file.xhr.responseURL.split("?")[0]
          );
          // eslint-disable-next-line
          return;
        } else {
          return null;
        }
      });
    }

    if (newFileList.length > 0) {
      copyDocument = [
        { documents: newDocumentArray },
        { responseUrl: newFileArray },
      ];
      documentObject[i] = copyDocument;
      copy[label] = newFileList;
      copy["type"] = props.question.question_type.name;
      current_answer_copy[i] = copy;
    } else {
      copyDocument = [];
      documentObject = [];
      current_answer_copy = [];
    }

    setFileList(info.fileList);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      answer: "",
      current_answer: current_answer_copy,
      documents: documentObject,
    }));
  };
  return (
    <div className="mt-6">
      {data.map((option, i) => (
        <div className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4">
          {option.label && (
            <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
              {option.label}
            </span>
          )}
          <Upload
            name="file"
            method="PUT"
            accept={option?.fileTypesAllowed}
            fileList={fileList}
            action={(file) => {
              return new Promise((resolve) => {
                uploadURL({
                  variables: {
                    fileName: file.name,
                    contentType: file.type,
                  },
                }).then((res) => {
                  if (res.data.get_upload_url.message === "Success") {
                    setFileURL(res);
                    resolve(res.data.get_upload_url.url);
                  }
                });
              });
            }}
            beforeUpload={() => {
              if (option.options.fileUploadValue.fileLimit) {
                return fileList.length >=
                  option?.options?.fileUploadValue?.fileLimit
                  ? Upload.LIST_IGNORE
                  : true;
              } else {
                return true;
              }
            }}
            onChange={(info) => handleOnChange(info, option.label, i)}
          >
            <Button size="large">Upload Document</Button>
          </Upload>
          <ul className="mt-4 text-h8 text-textLight list-disc pl-4">
            {option?.options?.fileUploadValue?.fileTypesAllowed && (
              <li>
                Files types allowed{" "}
                {option?.options?.fileUploadValue?.fileTypesAllowed.join(", ")}
              </li>
            )}
            {option?.options?.fileUploadValue?.fileSize && (
              <li>
                Maximum size {option?.options?.fileUploadValue?.fileSize} mb
              </li>
            )}
            {option.options.fileUploadValue.fileLimit ? (
              <li>
                Maximum no of files - {option.options.fileUploadValue.fileLimit}
              </li>
            ) : (
              <li>No file limit</li>
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default FileUpload;
