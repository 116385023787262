function isBool(x) {
  return typeof x === "boolean";
}

function getKeys(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return [];
  return [
    ...Object.keys(obj).map((key) => `${prefix}${key}`),
    ...Object.entries(obj).reduce((acc, [key, value]) => {
      if (typeof value === "object")
        return [...acc, ...getKeys(value, `${prefix}${key}.`)];
      return acc;
    }, []),
  ];
}
function flatObject(obj, prefix = "") {
  if (typeof obj === "undefined" || obj === null) return {};
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === "object")
      return { ...acc, ...flatObject(value, `${prefix}${key}.`) };
    return { ...acc, [`${prefix}${key}`]: value };
  }, {});
}

function escapeCsvValue(cell) {
  if (cell.replace(/ /g, "").match(/[\s,"]/)) {
    return '"' + cell.replace(/"/g, '""') + '"';
  }
  return cell;
}

const getRenderData = (data) => {
  let str = "";
  if (data) console.log(data, "dhaarni");
  if (typeof data !== "string")
    if (Array.isArray(data.props?.children)) {
      // console.log(data.props?.children);
      data.props?.children.map((child) => {
        console.log(child, "child");
        if (child) {
          if (typeof child === "object") {
            str += getRenderData(child);
          } else {
            str += child;
          }
        }
      });
      // str += data.props?.children.join("");
    } else if (typeof data.props?.children === "object") {
      str += getRenderData(data.props?.children);
    } else {
      str +=
        data.props?.children !== 0
          ? data.props?.children
          : data.props?.children === 0
          ? 0
          : "";
    }
  else {
    str += data;
  }
  console.log(str, "str");
  return str;
};

function objectsToCsv(arrayOfObjects, colKeys) {
  // collect all available keys
  const keys = new Set(
    arrayOfObjects.reduce((acc, item) => [...acc, ...getKeys(item)], [])
  );
  const objectKeys = new Set();
  for (let key of keys.keys()) {
    objectKeys.add(colKeys[key]);
  }
  // for each object create all keys
  const values = arrayOfObjects.map((item) => {
    const fo = flatObject(item);
    const val = Array.from(keys).map((key) =>
      key in fo ? escapeCsvValue(`${fo[key]}`) : ""
    );
    return val.join(",");
  });
  return `${Array.from(objectKeys).join(",")}\n${values.join("\n")}`;
}

export function downloadCSVFile(records, columns, fileName) {
  let finalRecord = (records || []).map((record, idx) => {
    const finalRecord = {};

    for (const col of columns) {
      const propName = col.dataIndex || col.key;
      if (propName && isBool(col.isExportable) ? col.isExportable : true) {
        const text = record[propName];
        finalRecord[propName] = text;
        //
        if (col.render) {
          console.log(record, finalRecord[propName]);
          finalRecord[propName] = getRenderData(col.render(text, record, idx)); //col.render(text, record, idx);
        } else {
          finalRecord[propName] = text !== 0 ? text : text === 0 ? 0 : "";
        }
      }
    }
    console.log(finalRecord, "finalRecord");
    return finalRecord;
  });
  let colKeys = {};
  columns.map((column) => {
    const propName = column.dataIndex || column.key;
    if (propName) {
      colKeys[propName] = column.title;
    }
  });
  let csv = objectsToCsv(finalRecord, colKeys);
  console.log(csv);
  const blob = new Blob([csv]);
  const a = window.document.createElement("a");
  a.href = window.URL.createObjectURL(blob);
  a.download = `${fileName || "tableData"}.csv`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
