import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { getColumnSearchProps } from "../utils/table";
import { getColumnDateSearchProps } from "../utils/dateSearch";
import moment from "moment";
import {
  filterValues,
  generateFilterOptions,
  getStatusText,
} from "../pages/members/getStatusText";

export const columnStatus = {
  respondent_id: { enabled: true, label: "Respondent ID" },
  memberStatus: { enabled: true, label: "Member Status" },
  memberName: { enabled: true, label: "Name" },
  title: { enabled: true, label: "Title" },
  category: { enabled: true, label: "Category" },
  email: { enabled: true, label: "Primary Email" },
  secondaryEmail: { enabled: false, label: "Secondary Email" },
  phone_number: { enabled: true, label: "Phone Number" },
  mobile1: { enabled: true, label: "Mobile Number1" },
  mobile2: { enabled: false, label: "Mobile Number2" },
  // status: { enabled: true, label: "Status" },
  genderNew: { enabled: true, label: "Gender" },
  year_of_birth: { enabled: true, label: "Year of birth" },
  state: { enabled: false, label: "State" },
  city: { enabled: false, label: "City" },
  zone: { enabled: false, label: "Zone" },
  cityCategory: { enabled: false, label: "City Category" },
  pincode: { enabled: false, label: "Pincode" },
  noOfBeds: { enabled: false, label: "No. of beds" },
  hospitalName: { enabled: false, label: "Hospital Name" },
  hospitalType: { enabled: false, label: "Hospital Type" },
  profession: { enabled: false, label: "Profession" },
  organisation: { enabled: false, label: "Organisation" },
  sub: { enabled: false, label: "Sub-category" },
  sub2: { enabled: false, label: "Sub-category 2" },
  speciality: { enabled: false, label: "Primary speciality" },
  secondarySpeciality: { enabled: false, label: "Secondary speciality" },
  superSpeciality: { enabled: false, label: "Super speciality" },
  consultingHospital1: { enabled: false, label: "Consulting Hospital 1" },
  consultingHospital2: { enabled: false, label: "Consulting Hospital 2" },
  projectCompatibility: { enabled: false, label: "Project compatibility" },
  startedPracticing: {
    enabled: false,
    label: "Started practicing (post Residency)",
  },
  totalIncentivesProcessed: {
    enabled: false,
    label: "Total incentives processed",
  },
  previouslyParticipatedStudies: {
    enabled: false,
    label: "Previously participated studies",
  },
  modeOfPayment: { enabled: false, label: "Mode of payment" },
  validatedDate: { enabled: false, label: "Validated date" },
  ailments: { enabled: false, label: "Ailment list" },
  years_of_experience: { enabled: false, label: "Years of Experience" },
  access: { enabled: false, label: "Access to mobile" },
  clientLink: { enabled: false, label: "Client Link" },
  kreaLink: { enabled: true, label: "Krea Link" },
  interviewer: { enabled: false, label: "Member Interviewers" },
};

export const roleBasedColumns = (role, columnStatus) => [
  {
    title: "PRIMARY EMAIL",
    dataIndex: "email",
    isShow: columnStatus?.email?.enabled,
    className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
    key: "email",
    width: 280,
    ...getColumnSearchProps("email", "email"),
    render: (text) => (
      <div className="flex flex-col items-start justify-start">
        {role === "manager" && text !== null && text
          ? `${text.substring(0, 3)}XXX@${text.split("@")[1]}`
          : text}
      </div>
    ),
  },
  {
    title: "SECONDARY EMAIL",
    dataIndex: "email2",
    isShow: columnStatus?.secondaryEmail?.enabled,
    className: columnStatus?.secondaryEmail?.enabled ? "table-cell" : "hidden",
    key: "email2",
    width: 200,
    ...getColumnSearchProps("email2", "email2"),
    render: (text) => (
      <div className="flex flex-col items-start justify-start">
        {role === "manager"
          ? text !== ""
            ? `${text?.substring(0, 3)}XXX@${text?.split("@")[1]}`
            : "Not updated"
          : text}
      </div>
    ),
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phone_number",
    isShow: columnStatus?.phone_number?.enabled,
    className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
    key: "phone",
    ...getColumnSearchProps("phone_number", "phone"),
    width: 200,
    render: (text) => (
      <div className="flex flex-col items-start justify-start">
        {role === "manager" && text !== null
          ? text === "Not updated"
            ? "Not updated"
            : `${text?.substring(0, 3)}XXXXXXX`
          : text}
      </div>
    ),
  },
  {
    title: "MOBILE NUMBER 1",
    dataIndex: "mobile1",
    isShow: columnStatus?.mobile1?.enabled,
    className: columnStatus?.mobile1?.enabled ? "table-cell" : "hidden",
    key: "mobile1",
    ...getColumnSearchProps("mobile1", "mobile1"),
    width: 200,
    render: (text) => (
      <div className="flex flex-col items-start justify-start">
        {role === "manager" && text !== null
          ? text === "Not updated"
            ? "Not updated"
            : `${text?.substring(0, 3)}XXXXXXX`
          : text}
      </div>
    ),
  },
  {
    title: "MOBILE NUMBER 2",
    dataIndex: "mobile2",
    isShow: columnStatus?.mobile2?.enabled,
    className: columnStatus?.mobile2?.enabled ? "table-cell" : "hidden",
    key: "mobile2",
    ...getColumnSearchProps("mobile2", "mobile2"),
    width: 200,
    render: (text) => (
      <div className="flex flex-col items-start justify-start">
        {role === "manager" && text !== null
          ? text === "Not updated"
            ? "Not updated"
            : `${text?.substring(0, 3)}XXXXXXX`
          : text}
      </div>
    ),
  },
];

export const memberColumns = (columnStatus, handleIconClick) =>
  [
    {
      title: "Respondent ID",
      dataIndex: "respondent_id",
      key: "respondent_id",
      ...getColumnSearchProps("respondent_id", "respondent_id"),
      isShow: columnStatus?.respondent_id?.enabled,
      className: columnStatus?.respondent_id?.enabled
        ? "table-cell whitespace-nowrap"
        : "hidden",
      width: 200,
    },
    {
      title: "MEMBER STATUS",
      dataIndex: "memberStatus",
      isShow: columnStatus?.memberStatus?.enabled,
      className: columnStatus?.memberStatus?.enabled ? "table-cell" : "hidden",
      key: "memberStatus",
      width: 200,
      filters: generateFilterOptions(filterValues),
      filterMultiple: true,
      onFilter: (value, record) => record.memberStatus === value,
      render: (text, record) => {
        return getStatusText(text, record);
      },
    },
    {
      title: "NAME",
      dataIndex: "memberName",
      isShow: columnStatus?.memberName?.enabled,
      className: columnStatus?.memberName?.enabled ? "table-cell" : "hidden",
      key: "memberName",
      ...getColumnSearchProps("memberName", "name"),
      width: 200,
      render: (text, record) => (
        <div className="">{`${text}${record.response_rate ? ` (${record.response_rate.toFixed(2)})` : ""
          }`}</div>
      ),
    },
    {
      title: "TITLE",
      dataIndex: "title",
      key: "title",
      ...getColumnSearchProps("title", "title"),
      isShow: columnStatus?.title?.enabled,
      className: columnStatus?.title?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      isShow: columnStatus?.category?.enabled,
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      width: 200,
    },
    {
      title: "GENDER",
      dataIndex: "gender",
      key: "gender",
      isShow: columnStatus?.genderNew?.enabled,
      className: columnStatus?.genderNew?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Male",
          value: "M",
        },
        {
          text: "Female",
          value: "F",
        },
        {
          text: "Not updated",
          value: "Not updated",
        },
      ],
      onFilter: (value, record) => record.gender.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) =>
        text === "M" ? (
          <>Male</>
        ) : text === "F" ? (
          <>Female</>
        ) : (
          <>Not updated</>
        ),
      width: 300,
    },
    {
      title: "YEAR OF BIRTH",
      dataIndex: "year_of_birth",
      key: "year",
      width: 200,
      isShow: columnStatus?.year_of_birth?.enabled,
      className: `${columnStatus?.year_of_birth?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      ...getColumnDateSearchProps("year_of_birth", "year"),
    },
    {
      title: (
        <div
          onClick={() => handleIconClick("speciality")}
          className="flex justify-between items-center"
        >
          <div>SPECIALITY</div>
          <div>
            <SearchOutlined
              className="cursor-pointer column-custom-searchicon"
              style={{ color: "#9CA3AF" }}
            />
          </div>
        </div>
      ),
      dataIndex: "speciality",
      key: "speciality",
      // ...getColumnSearchProps("speciality", "speciality"),
      isShow: columnStatus?.speciality?.enabled,
      className: columnStatus?.speciality?.enabled ? "table-cell" : "hidden",
      width: 300,
      onHeaderCell: () => ({
        onClick: () => handleIconClick("speciality"),
      }),
    },
    {
      title: (
        <div
          onClick={() => handleIconClick("secondarySpeciality")}
          className="flex justify-between items-center"
        >
          <div>SECONDARY SPECIALITY</div>
          <div>
            <SearchOutlined
              className="cursor-pointer column-custom-searchicon"
              style={{ color: "#9CA3AF" }}
            />
          </div>
        </div>
      ),
      dataIndex: "secondarySpeciality",
      key: "secondarySpeciality",
      // ...getColumnSearchProps("secondarySpeciality", "secondarySpeciality"),
      isShow: columnStatus?.secondarySpeciality?.enabled,
      className: columnStatus?.secondarySpeciality?.enabled
        ? "table-cell"
        : "hidden",
      width: 300,
      onHeaderCell: () => ({
        onClick: () => handleIconClick("secondarySpeciality"),
      }),
    },
    {
      title: "SUPER SPECIALITY",
      dataIndex: "superSpeciality",
      key: "superSpeciality",
      ...getColumnSearchProps("superSpeciality", "superSpeciality"),
      isShow: columnStatus?.superSpeciality?.enabled,
      className: columnStatus?.superSpeciality?.enabled
        ? "table-cell"
        : "hidden",
      width: 250,
    },
    {
      title: "STATE",
      dataIndex: "state",
      key: "state",
      ...getColumnSearchProps("state", "state"),
      isShow: columnStatus?.state?.enabled,
      className: columnStatus?.state?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "CITY",
      dataIndex: "city",
      key: "city",
      ...getColumnSearchProps("city", "city"),
      isShow: columnStatus?.city?.enabled,
      className: columnStatus?.city?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "ZONE",
      dataIndex: "zone",
      key: "zone",
      ...getColumnSearchProps("zone", "zone"),
      isShow: columnStatus?.zone?.enabled,
      className: columnStatus?.zone?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "CITY CATEGORY",
      dataIndex: "cityCategory",
      key: "cityCategory",
      ...getColumnSearchProps("cityCategory", "cityCategory"),
      isShow: columnStatus?.cityCategory?.enabled,
      className: `${columnStatus?.cityCategory?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      width: 200,
    },
    {
      title: "PINCODE",
      dataIndex: "pincode",
      key: "pincode",
      ...getColumnSearchProps("pincode", "pincode"),
      isShow: columnStatus?.pincode?.enabled,
      className: columnStatus?.pincode?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "NO. OF BEDS",
      dataIndex: "noOfBeds",
      isShow: columnStatus?.noOfBeds?.enabled,
      className: `${columnStatus?.noOfBeds?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      key: "noOfBeds",
      filters: [
        {
          value: "NA",
          text: "NA",
        },
        {
          value: "1 to 10",
          text: "1 to 10",
        },
        {
          value: "11 to 25",
          text: "11 to 25",
        },
        {
          value: "26 to 50",
          text: "26 to 50",
        },
        {
          value: "51 to 75",
          text: "51 to 75",
        },
        {
          value: "76 to 100",
          text: "76 to 100",
        },
        {
          value: "101 to 150",
          text: "101 to 150",
        },
        {
          value: "151 to 200",
          text: "151 to 200",
        },
        {
          value: "200+",
          text: "200+",
        },
        {
          value: "Not updated",
          text: "Not updated",
        },
      ],
      onFilter: (value, record) => (record.noOfBeds || "").indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          {text === "Not updated" || !text ? "Not updated" : text}
        </div>
      ),
      width: 200,
    },
    {
      title: "HOSPITAL NAME",
      dataIndex: "hospitalName",
      ...getColumnSearchProps("hospitalName", "hospitalName"),
      isShow: columnStatus?.hospitalName?.enabled,
      className: columnStatus?.hospitalName?.enabled ? "table-cell" : "hidden",
      key: "hospitalName",
      width: 200,
    },
    {
      title: "HOSPITAL TYPE",
      dataIndex: "hospitalType",
      isShow: columnStatus?.hospitalType?.enabled,
      className: `${columnStatus?.hospitalType?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      filters: [
        {
          text: "Government",
          value: "Government",
        },
        {
          text: "Private",
          value: "Private",
        },
        {
          text: "Non-Profit",
          value: "Non-Profit",
        },
        {
          text: "Others",
          value: "Others",
        },
        {
          text: "Not updated",
          value: "Not updated",
        },
      ],
      onFilter: (value, record) =>
        (record.hospitalType || "").indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      key: "hospitalType",
      width: 200,
    },
    {
      title: "PROFESSION",
      dataIndex: "profession",
      key: "profession",
      ...getColumnSearchProps("profession", "profession"),
      isShow: columnStatus?.profession?.enabled,
      className: columnStatus?.profession?.enabled ? "table-cell" : "hidden",
      width: 200,
    },
    {
      title: "SUB-CATEGORY",
      dataIndex: "sub",
      key: "sub",
      ...getColumnSearchProps("sub", "sub-category"),
      isShow: columnStatus?.sub?.enabled,
      className: `${columnStatus?.sub?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      width: 200,
    },
    {
      title: "SUB-CATEGORY 2",
      dataIndex: "sub2",
      key: "sub2",
      ...getColumnSearchProps("sub2", "sub-category"),
      isShow: columnStatus?.sub2?.enabled,
      className: `${columnStatus?.sub2?.enabled ? "table-cell" : "hidden"
        } whitespace-nowrap`,
      width: 200,
    },
    {
      title: "ORGANISATION",
      dataIndex: "organisation",
      ...getColumnSearchProps("organisation", "organisation"),
      isShow: columnStatus?.organisation?.enabled,
      className: columnStatus?.organisation?.enabled ? "table-cell" : "hidden",
      key: "org",
      width: 200,
    },
    {
      title: "CONSULTING HOSPITAL 1",
      dataIndex: "consultingHospital1",
      ...getColumnSearchProps("consultingHospital1", "consultingHospital1"),
      isShow: columnStatus?.consultingHospital1?.enabled,
      className: columnStatus?.consultingHospital1?.enabled
        ? "table-cell"
        : "hidden",
      key: "consultingHospital1",
      width: 200,
    },
    {
      title: "CONSULTING HOSPITAL 2",
      dataIndex: "consultingHospital2",
      ...getColumnSearchProps("consultingHospital2", "consultingHospital2"),
      isShow: columnStatus?.consultingHospital2?.enabled,
      className: columnStatus?.consultingHospital2?.enabled
        ? "table-cell"
        : "hidden",
      key: "consultingHospital2",
      width: 200,
    },
    {
      title: "STARTED PRACTICING (POST RESIDENCY)",
      dataIndex: "startedPracticing",
      ...getColumnSearchProps("startedPracticing", "startedPracticing"),
      isShow: columnStatus?.startedPracticing?.enabled,
      className: columnStatus?.startedPracticing?.enabled
        ? "table-cell"
        : "hidden",
      key: "startedPracticing",
      width: 200,
    },
    {
      title: "PROJECT COMPATIBILITY",
      dataIndex: "projectCompatibility",
      ...getColumnSearchProps("projectCompatibility", "projectCompatibility"),
      isShow: columnStatus?.projectCompatibility?.enabled,
      className: columnStatus?.projectCompatibility?.enabled
        ? "table-cell"
        : "hidden",
      key: "projectCompatibility",
      width: 200,
    },
    {
      title: "TOTAL INCENTIVES PROCESSED",
      dataIndex: "totalIncentivesProcessed",
      ...getColumnSearchProps(
        "totalIncentivesProcessed",
        "totalIncentivesProcessed"
      ),
      isShow: columnStatus?.totalIncentivesProcessed?.enabled,
      className: columnStatus?.totalIncentivesProcessed?.enabled
        ? "table-cell"
        : "hidden",
      key: "totalIncentivesProcessed",
      width: 200,
    },
    {
      title: "PREVIOUSLY PARTICIPATED STUDIES",
      dataIndex: "previouslyParticipatedStudies",
      ...getColumnSearchProps(
        "previouslyParticipatedStudies",
        "previouslyParticipatedStudies"
      ),
      isShow: columnStatus?.previouslyParticipatedStudies?.enabled,
      className: columnStatus?.previouslyParticipatedStudies?.enabled
        ? "table-cell"
        : "hidden",
      key: "previouslyParticipatedStudies",
      width: 200,
    },
    {
      title: "MODE OF PAYMENT",
      dataIndex: "modeOfPayment",
      ...getColumnSearchProps("modeOfPayment", "modeOfPayment"),
      isShow: columnStatus?.modeOfPayment?.enabled,
      className: columnStatus?.modeOfPayment?.enabled ? "table-cell" : "hidden",
      key: "modeOfPayment",
      width: 200,
    },
    {
      title: "VALIDATED DATE",
      dataIndex: "validatedDate",
      ...getColumnSearchProps("validatedDate", "validatedDate"),
      isShow: columnStatus?.validatedDate?.enabled,
      className: columnStatus?.validatedDate?.enabled ? "table-cell" : "hidden",
      key: "validatedDate",
      width: 200,
    },
    {
      title: "YEARS OF EXPERIENCE",
      dataIndex: "experience",
      ...getColumnSearchProps("experience"),
      isShow: columnStatus?.years_of_experience?.enabled,
      className: columnStatus?.years_of_experience?.enabled
        ? "table-cell"
        : "hidden",
    },
    {
      title: "AILMENTS LIST",
      dataIndex: "ailments",
      key: "ailments",
      isShow: columnStatus?.ailments?.enabled,
      className: columnStatus?.ailments?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Acne",
          value: "Acne",
        },
        {
          text: "ADHD",
          value: "ADHD",
        },
        {
          text: "Back pain",
          value: "Back pain",
        },
        {
          text: "Chikungunya",
          value: "Chikungunya",
        },
        {
          text: "Cancer",
          value: "Cancer",
        },
        {
          text: "Bronchitis",
          value: "Bronchitis",
        },
        {
          text: "Not Furnished",
          value: "Not Furnished",
        },
      ],
      onFilter: (value, record) => (record.ailments || "").includes(value),
      width: 200,
    },
    {
      title: "Access to Mobile App",
      dataIndex: "access",
      isShow: columnStatus?.access?.enabled,
      className: `${columnStatus?.access?.enabled} ? "table-cell" : "hidden"} whitespace-nowrap`,
      key: "access",
      filters: [
        {
          value: "Yes",
          text: "Yes",
        },
        {
          value: "No",
          text: "No",
        },
      ],
      onFilter: (value, record) => (record.access || "").indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      width: 200,
    },
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      isShow: columnStatus?.clientLink?.enabled,
      className: columnStatus?.clientLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("clientLink"),
      width: 200,
    },
    {
      title: "KREA LINK",
      dataIndex: "kreaLink",
      key: "kreaLink",
      isShow: columnStatus?.kreaLink?.enabled,
      className: columnStatus?.kreaLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("kreaLink"),
      width: 200,
    },
    {
      title: "MEMBER INTERVIEWERS",
      dataIndex: "interviewer",
      isShow: columnStatus?.interviewer?.enabled,
      className: columnStatus?.interviewer?.enabled ? "table-cell" : "hidden",
      width: 200,
      ...getColumnSearchProps("interviewer"),
    },
  ].filter((data) => data.isShow === undefined || data.isShow);

export const userMemberData = (item, additional_data) => {
  let phone_number =
    item.phone_number === "" ? "Not updated" : item.phone_number;
  item.title = item.title || "Not updated";
  item.category = item.member_type.name;
  item.memberName =
    item?.l_name !== null ? item?.f_name + " " + item?.l_name : item?.f_name;
  item.respondent_id = item?.respondent_id || "Not updated";
  item.mobile1 = phone_number;
  item.phone_number = item.meta_date?.phone || "Not updated";
  item.mobile2 = item.meta_date?.mobile2 || "Not updated";
  item.email2 = item.meta_date?.email2 || "";
  item.gender = item?.gender !== null ? item?.gender : "Not updated";
  item.year_of_birth = item?.year_of_birth
    ? moment(item?.year_of_birth).format("yyyy")
    : "Not Updated";
  item.state = item.state || "Not updated";
  item.city = item.city || "Not updated";
  item.zone = item?.meta_date?.zone || "Not updated";
  item.cityCategory = item?.meta_date?.cityCategory || "Not updated";
  item.pincode = item?.pincode || "Not updated";
  item.noOfBeds = item?.meta_date?.noOfBeds || "Not updated";
  item.hospitalName = item?.meta_date?.hospital || "Not updated";
  item.hospitalType = item?.meta_date?.hospitalType || "Not updated";
  item.profession = item?.meta_date?.profession || "Not updated";
  item.sub = item?.meta_date?.sub || "Not updated";
  item.sub2 = item?.meta_date?.sub2 || "Not updated";
  item.organisation = item?.meta_date?.organisation || "Not updated";
  item.access = item?.meta_date?.access ? "Yes" : "No";
  item.ailments =
    item?.meta_date?.ailments?.length > 0
      ? item?.meta_date?.ailments.join()
      : "Not Furnished";

  item.speciality =
    item?.primary_specialties?.speciality_name === undefined
      ? "Not updated"
      : item?.primary_specialties?.speciality_name;
  item.secondarySpeciality =
    item?.secondary_specialties?.speciality_name || "Not updated";
  item.superSpeciality = item?.super_specialty?.name || "Not updated";
  item.consultingHospital1 = item?.meta_date?.hospital1 || "Not updated";
  item.consultingHospital2 = item?.meta_date?.hospital2 || "Not updated";
  item.projectCompatibility =
    item?.project_compatibilitys?.length > 0
      ? item?.project_compatibilitys?.join(",")
      : "Not updated";
  item.startedPracticing = item?.meta_date?.yearOfPractice
    ? moment(item?.meta_date?.yearOfPractice).format("yyyy")
    : "Not updated";
  item.totalIncentivesProcessed = item?.meta_date?.totalIncentive
    ? item?.meta_date?.totalIncentive
    : "Not updated";
  item.previouslyParticipatedStudies = item?.meta_date?.previousStudies
    ? item?.meta_date?.previousStudies
    : "Not updated";
  item.modeOfPayment = Array.isArray(item?.meta_date?.payment)
    ? item?.meta_date?.payment?.length > 0
      ? item?.meta_date?.payment.map((payment) => payment.label).join(",")
      : "Not updated"
    : item?.meta_date?.payment || "Not updated";
  item.validatedDate = item?.meta_date?.yearOfPractice
    ? moment(item?.meta_date?.yearOfPractice).format("DD-MM-YYYY")
    : "Not updated";
  item.memberStatus = item?.status ? item?.status : "Not updated";
  item.experience =
    item.member_type_id === 1
      ? moment().format("yyyy") -
      moment(item?.meta_date?.yearOfPractice).format("yyyy")
      : item?.meta_date?.experience
        ? item?.meta_date?.experience
        : "Not updated";

  // alert(item.survey_x_members.length)
  if (item.survey_x_members?.length) {
    if (
      item.survey_x_members[0]?.survey_x_member_x_interviewers_aggregate
        ?.aggregate.count === 1
    ) {
      item.interviewer =
        item.survey_x_members[0].active.length === 0
          ? item.survey_x_members[0].leave[0].interviewer.l_name !== null
            ? item.survey_x_members[0].leave[0].interviewer.f_name +
            " " +
            item.survey_x_members[0].leave[0].interviewer.l_name
            : item.survey_x_members[0].leave[0].interviewer.f_name
          : item.survey_x_members[0].active[0].interviewer.l_name !== null
            ? item.survey_x_members[0].active[0].interviewer.f_name +
            " " +
            item.survey_x_members[0].active[0].interviewer.l_name
            : item.survey_x_members[0].active[0].interviewer.f_name;
    } else {
      item.interviewer = item.survey_x_members[0].active?.length
        ? item.survey_x_members[0].active[0].interviewer.l_name !== null
          ? item.survey_x_members[0].active[0].interviewer.f_name +
          " " +
          item.survey_x_members[0].active[0].interviewer.l_name
          : item.survey_x_members[0].active[0].interviewer.f_name
        : "";
    }
  }
  if (additional_data) {
    let itemObj = additional_data(item);
    item = { ...item, ...itemObj };
  }
  return item;
};

export const parseMemberData = (members, additional_data) => {
  let object = JSON.parse(JSON.stringify(members?.user));
  object.forEach((item) => {
    item = userMemberData(item);
    if (additional_data) {
      let itemObj = additional_data(item);
      item = { ...item, ...itemObj };
    }
  });
  return object;
};
