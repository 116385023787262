import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import SuperAdminList from "./superadminList";
import ManagerList from "./managerList";

const ProjectList = () => {
  const [role, setRole] = useState(null);
  // eslint-disable-next-line
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]; //["x-hasura-default-role"];
      setUserId(decode["x-hasura-user-id"]);
      setRole(decode["x-hasura-default-role"]);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      {(role === "super_admin" || role === "sub_admin") && <SuperAdminList />}
      {role === "manager" && <ManagerList />}
    </>
  );
};
export default ProjectList;
