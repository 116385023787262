import React from "react";
import error from "../assets/error.svg";
import { Button } from "antd";

const ErrorPage = (props) => {
  return (
    <div className="pt-32 flex flex-col items-center justify-center">
      <div className="p-6 w-2/3 bg-white shadow rounded flex flex-col items-center justify-center">
        <img src={error} alt="error" className="mb-4" />
        <div className="text-h6 text-textLight mb-4">
          Looks like something went wrong. Please try again
        </div>
        <Button
          type="primary"
          size="large"
          className="px-12 rounded mb-4"
          // eslint-disable-next-line
          onClick={() => (window.location.href = window.location.href)}
        >
          Try Again
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
