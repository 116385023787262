import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button, Spin, Tag } from "antd";
import CountCard from "../../components/Cards/countCard";
import { getColumnSearchProps } from "../../utils/table";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_BY_STATUS, REWARDS_TABLE_DATA } from "./graphql";
import { FilterOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import ColumnEditor from "../../components/ColumnEditor";

const Rewards = () => {
  const { data, loading } = useQuery(GET_PROJECT_BY_STATUS);
  const { data: rewardData } = useQuery(REWARDS_TABLE_DATA);
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const savedrewardsPage = localStorage.getItem('rewardsPage');
  const rewardsPageinitial = savedrewardsPage
    ? JSON.parse(savedrewardsPage)
    : {
      "client": { enabled: true, label: "Client" },
      "type": { enabled: true, label: "Category" },
      "manager": { enabled: true, label: "Assigned To" },
      "rewardedMembers": { enabled: true, label: "Reward Status" },
      "completion": { enabled: true, label: "Survey Completion" },
      "status": { enabled: true, label: "Status" },
    }
  const [columnStatus, setStatus] = useState(rewardsPageinitial);

  useEffect(() => {
    localStorage.setItem('rewardsPage', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    let tempData = [],
      tempObject = {};
    if (rewardData) {
      rewardData?.project?.forEach((project) => {
        tempObject.name = project.name;
        tempObject.id = project.id;
        tempObject.client =
          project?.client?.l_name !== null
            ? project?.client?.f_name + " " + project?.client?.l_name
            : project?.client?.f_name;
        let val =
          project?.survey_type !== null && project?.survey_type !== ""
            ? project?.survey_type === "Third_Party_survey"
              ? "- " + project?.third_party_survey_name
              : "- " + project?.survey_type
            : "";
        let category = project?.projectTypeByProjectType?.name + " " + val;
        tempObject.type = category;
        tempObject.manager =
          project.surveys.length > 0 &&
            project.surveys[0].survey_x_members.length > 0
            ? project?.surveys[0]?.survey_x_members[0]?.user?.f_name +
            " " +
            project?.surveys[0]?.survey_x_members[0]?.user?.l_name
            : null;
        tempObject.completion =
          project.surveys.length > 0
            ? project?.surveys[0]?.survey_x_members_aggregate?.aggregate?.sum
              ?.completed
            : 0;
        tempObject.rewardStatus =
          project.surveys.length > 0 ? project?.surveys[0]?.rewards.length : 0;

        tempObject.rewardedMembers =
          project.surveys.length > 0
            ? project?.surveys[0]?.rewarded_survey_members?.aggregate?.count
            : 0;
        tempObject.status =
          tempObject?.completion !== 0 &&
            tempObject?.rewardedMembers === tempObject?.completion
            ? "Rewarded"
            : "Pending";
        tempObject.surveyId =
          project.surveys.length > 0 ? project?.surveys[0]?.id : null;
        tempData.push(tempObject);
        tempObject = {};
      });
    }
    setTableData(tempData);
  }, [rewardData]);
  const columns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (_, record) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
    },
    {
      title: "CLIENT",
      key: "client",
      dataIndex: "client",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("client", "Client Name"),
    },
    {
      title: "CATEGORY",
      key: "category",
      dataIndex: "type",
      className: columnStatus?.type?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("type", "Category Name"),
    },
    {
      title: "ASSIGNED TO",
      key: "manager",
      dataIndex: "manager",
      className: columnStatus?.manager?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("manager", "Manager Name"),
    },
    {
      title: "REWARD STATUS",
      key: "rewardStatus",
      dataIndex: "rewardedMembers",
      className: columnStatus?.rewardedMembers?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("rewardedMembers", "Reward status"),
      render: (_, record) => (
        <p>
          {record?.rewardedMembers}/{record?.completion}
        </p>
      ),
    },
    {
      title: "SURVEY COMPLETION",
      key: "completion",
      dataIndex: "completion",
      className: columnStatus?.completion?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("completion", "Survey Completion"),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Rewarded",
          value: "Rewarded",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record) =>
        text === "Rewarded" ? (
          <Tag color="green">Rewarded</Tag>
        ) : (
          <Tag color="orange">Pending</Tag>
        ),
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => {
        return (
          <Button
            type="link"
            onClick={() => {
              history.push(
                `viewProject/rewards/project/${record.id}/survey/${record.surveyId}/Online-Krea`
              );
            }}
          >
            View
          </Button>
        );
      },
    },
  ];
  return (
    <Spin spinning={loading}>
      <Row>
        <Col
          span={24}
          className="p-8 text-2xl font-bold"
          style={{ color: "#4f4f4f" }}
        >
          Overview
        </Col>
      </Row>
      <Row>
        <Col span={12} className="px-8">
          <div className="flex justify-between col-span-4 rounded shadow">
            <CountCard
              count={data?.completed_projects?.aggregate?.count || 0}
              description="Completed projects"
              className="flex-grow rounded-l-lg"
            />
            <CountCard
              count={data?.pending?.aggregate?.count || 0}
              description="Pending rewards"
              className="flex-grow"
            />
            <CountCard
              count={data?.completed?.aggregate?.count || 0}
              description="Completed rewards"
              className="flex-grow rounded-r-lg"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="p-8 text-xl" style={{ color: "#4f4f4f" }}>
          <div className=" flex justify-between">
            <span> Completed projects</span>
            <div>

              <ColumnEditor columns={columnStatus} onSave={(column) => {
                setStatus(column)
              }} />
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="px-8">
          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            pagination={
              loading
                ? false
                : {
                  showTotal: (total, range) => {
                    return (
                      <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                    );
                  },
                }
            }
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default Rewards;
