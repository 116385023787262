import React, { useEffect, useState } from "react";
import { Col, Layout, Row, Card, Divider, Button, Spin } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { GET_SURVEY, GET_DOWNLOAD_URL } from "./graphql";
import { LoadingOutlined } from "@ant-design/icons";
import doc from "../../assets/Icon/doc.svg";
import pdf from "../../assets/Icon/pdf.svg";
import xls from "../../assets/Icon/xls.svg";
import mp3 from "../../assets/Icon/mp3.svg";
import png from "../../assets/Icon/png.svg";
import mkv from "../../assets/Icon/mkv.svg";

const { Content } = Layout;
const ViewResponses = () => {
  const { id, surveyID, memberID, interviewerID } = useParams();
  const [questionData, setQuestionData] = useState(null);
  const { data, loading, error } = useQuery(GET_SURVEY, {
    variables: {
      id: parseInt(surveyID),
      member_id: parseInt(memberID),
      interviewer_id: parseInt(interviewerID),
    },
    fetchPolicy: "network-only",
  });
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  useEffect(() => {
    if (data) {
      let copy = JSON.parse(JSON.stringify(data));
      copy?.survey_by_pk?.questions?.forEach((question) => {
        question.fileSize =
          question?.questions_options[0]?.options?.fileUploadValue?.fileSize ||
          null;
        let fileTypesAllowed = "";
        question?.questions_options[0]?.options?.fileUploadValue?.fileTypesAllowed.forEach(
          (type) => {
            fileTypesAllowed = fileTypesAllowed + `.${type},`;
            question.fileTypesAllowed = fileTypesAllowed;
          }
        );
      });
      setQuestionData(copy);
    }
  }, [data]);
  const history = useHistory();

  const handleDownload = (name, originalName) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", originalName);
      document.body.appendChild(link);
      link.click();
    });
  };

  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} style={{ textAlign: "center" }} />
      </div>
    );
  }
  if (error) {
    return <p>Error fetching data {error}</p>;
  }
  return (
    <Layout className="w-full">
      <Content className="px-8 py-10 h-screen">
        <Row justify="space-between">
          <Col>
            <p className="text-lg">{questionData?.survey_by_pk?.name}</p>
          </Col>
          <Col>
            <p>
              Interview for :{" "}
              {questionData?.survey_by_pk?.survey_x_members[0]?.user?.f_name}{" "}
              {questionData?.survey_by_pk?.survey_x_members[0]?.user?.l_name}
            </p>
          </Col>
        </Row>

        {questionData?.survey_by_pk?.questions?.map((question, index) => {
          return (
            <Card className="mb-2" key={index}>
              <p>{`Q${index + 1} ${question.question}`}</p>
              <Row justify="space-between">
                <Col>
                  <Row justify="center" align="middle">
                    <Col className="mr-4">
                      {question?.answers[0]?.answer.split(".")[
                        question?.answers[0]?.answer.split(".").length - 1
                      ] === "doc" ||
                      question?.answers[0]?.answer.split(".")[
                        question?.answers[0]?.answer.split(".").length - 1
                      ] === "docx" ? (
                        <img src={doc} alt="doc" className="w-12" />
                      ) : question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "pdf" ? (
                        <img src={pdf} alt="pdf" className="w-12" />
                      ) : question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "xls" ? (
                        <img src={xls} alt="xls" className="w-12" />
                      ) : question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "mp3" ? (
                        <img src={mp3} alt="mp3" className="w-12" />
                      ) : question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "jpg" ||
                        question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "png" ||
                        question?.answers[0]?.answer.split(".")[
                          question?.answers[0]?.answer.split(".").length - 1
                        ] === "jpeg" ? (
                        <img src={png} alt="png" className="w-12" />
                      ) : (
                        <img src={mkv} alt="mkv" className="w-12" />
                      )}
                    </Col>
                    <Col>{question?.answers[0]?.answer}</Col>
                  </Row>
                </Col>
                <Col>
                  <DownloadOutlined
                    style={{
                      fontSize: "2rem",
                      color: "#E08036",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleDownload(
                        question?.answers[0]?.file_url,
                        question?.answers[0]?.answer
                      );
                    }}
                  />
                </Col>
              </Row>
            </Card>
          );
        })}
        <Divider />
        <Row
          justify="end"
          style={{
            position: "fixed",
            width: "95.5%",
            bottom: 0,
            marginBottom: "25px",
          }}
        >
          <Button
            size="large"
            type="secondary"
            htmlType="button"
            className="rounded-md mr-5 px-10"
            style={{ color: "#E08036" }}
            onClick={() => {
              history.goBack();
            }}
          >
            Back
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="rounded-md px-10"
            disabled={
              data?.survey_by_pk?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].status === "approved" ||
              data?.survey_by_pk?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].status === "Approved"
            }
            onClick={() => {
              history.push(
                `/project/${id}/attendsurvey/${surveyID}/member/${memberID}/interviewer/${interviewerID}`
              );
            }}
          >
            Edit
          </Button>
        </Row>
      </Content>
    </Layout>
  );
};

export default ViewResponses;
