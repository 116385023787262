import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Form, Space, Input, notification, Modal } from "antd";
import _ from "lodash";
import moment from "moment";
import { formatDateTime } from "../../../../../utils/dateTime";
import {
  UPDATE_SURVEY_DETAILS,
  DELETE_SURVEY_X_MEMBERS,
  INSERT_INTERVIEWER,
  GET_ACTIVE_ASSIGNED_MEMBERS,
} from "../../../assisted/kreaSurvey/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { RoleContext } from "../../../../../context/RoleContext";
import ColumnEditor from "../../../../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  userMemberData,
} from "../../../../../components/memberColumns";
import MemberTable from "../../../../../components/memberTable";
import AssignInterviewer from "../../../assisted/kreaSurvey/assignMembers/assignInterviewer";
import SpecialityModalPop from "../../../../../utils/specialitySearch";

function AssignInterviewerList(props) {
  const { surveyID, projectID } = useParams();
  const location = useLocation();
  const [isEnabled, setIsEnabled] = useState(false);
  const [enableDrawer, setEnableDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [startDate, setStartDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [memberIndex, setMemberIndex] = useState();
  const [interviewerLeaveList, setInterviewerLeaveList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deleteMembers, { loading: onDeleteLoading }] = useMutation(
    DELETE_SURVEY_X_MEMBERS
  );
  const [updateSurvey, { loading: onUpdateSurveyLoading }] = useMutation(
    UPDATE_SURVEY_DETAILS
  );
  const [insertInterviewer, { loading: onInsertInterviewerLoading }] =
    useMutation(INSERT_INTERVIEWER);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedMemberKeys, setSelectedMemberKeys] = useState([]);
  const [showInterviewerAlert, setShowInterviewerAlert] = useState(false);
  const [assignedMembers, setAssignMembers] = useState({});
  const savedonlineKrea = localStorage.getItem('onlineKrea');
  const onlineKreainitial = savedonlineKrea
    ? JSON.parse(savedonlineKrea)
    : {
      ...memberColumnStatus,
      action: { enabled: true, label: "Action" },
    }
  const [columnStatus, setStatus] = useState(onlineKreainitial);
  const { role } = useContext(RoleContext);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  const rowSelectionValue = location?.state?.rowSelectionValue;
  const [tableData1, setTableData1] = useState(
    rowSelectionValue?.selectedRows || null
  );

  useEffect(() => {
    localStorage.setItem('onlineKrea', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    if (rowSelectionValue && rowSelectionValue.selectedRows) {
      // Update the table's data source with the rowSelectionValue
      setTableData1(rowSelectionValue.selectedRows);
    }
  }, [rowSelectionValue]);

  const { data: assignedDataMembers, refetch } = useQuery(
    GET_ACTIVE_ASSIGNED_MEMBERS,
    {
      variables: {
        survey_id: parseInt(surveyID),
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    setAssignMembers(assignedDataMembers);
  }, [assignedDataMembers]);

  const history = useHistory();
  let searchInput;

  const refetchAssignQuery = async () => {
    await refetch();
  };
  useEffect(() => {
    refetchAssignQuery();
  }, [location]);

  useEffect(() => {
    let newList = [...interviewerList];
    let leaveArrayList = [...leaveList];
    if (assignedMembers?.survey && assignedMembers?.survey?.length !== 0) {
      let time =
        moment().isSameOrAfter(
          formatDateTime(assignedMembers?.survey[0]?.start_date)
        ) && props.status !== "draft";
      setIsEnabled(time);
      setStartDate(
        assignedMembers?.survey[0]?.start_date !== null
          ? moment(assignedMembers?.survey[0]?.start_date)
          : null
      );
      setDueDate(
        assignedMembers?.survey[0]?.end_date !== null
          ? moment(assignedMembers?.survey[0]?.end_date)
          : null
      );
    }
    let obj = {};
    if (
      assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member?.length > 0
    ) {
      assignedMembers?.survey_x_member
        // eslint-disable-next-line
        ?.map((el) => {
          let id = el.user.id;
          let status =
            el?.survey_x_member_x_interviewers?.length > 0
              ? el.survey_x_member_x_interviewers[0].status
              : "active";
          obj[id] = { status };
          return el.user.id;
        });

      let object,
        newObject = [];
      object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      object.forEach((item, index) => {
        let array = [];
        let leaveArray = [];
        newObject.push(
          userMemberData(item?.user, () => {
            (item?.survey_x_member_x_interviewers || [])
              // eslint-disable-next-line
              .map((interviewID, index) => {
                leaveArray.push(interviewID.interviewer_on_leave);
                array.push(interviewID.interviewer_id);
              });
            item.user.leaveArray = leaveArray;
            item.user.action = array;
          })
        );
      });
      newObject.forEach((item, index) => {
        if (item.leaveArray?.length !== 0) {
          item.leaveArray[0].length > 1
            ? (leaveArrayList[index] = item.leaveArray[0])
            : (leaveArrayList[index] = item.leaveArray);
        } else {
          leaveArrayList[index] = [];
        }
      });
      newObject.forEach((item, index) => {
        if (item.action.length !== 0) {
          item.action[0].length > 1
            ? (newList[index] = item.action[0])
            : (newList[index] = item.action);
        } else {
          newList[index] = [];
        }
      });
      setLeaveList(leaveArrayList);
      setTableData(newObject);
      setInterviewerList(newList);
    }
    // eslint-disable-next-line
  }, [assignedMembers, location]);

  const handleClick = () => {
    history.goBack();
  };

  const handleValidationValues = (value, membersIndex, leaveList) => {
    let newArray = [...interviewerList];
    let newLeaveList = [...interviewerLeaveList];
    if (memberIndex === undefined) {
      let selectedMemberId = selectedMembers.map((member) => member.id);
      tableData.map((data, dataIndex) => {
        if (selectedMemberId.includes(data.id)) {
          newArray[dataIndex] = value;
          newLeaveList[dataIndex] = leaveList;
        }
      });
      setSelectedMemberKeys([]);
    } else {
      newArray[membersIndex] = value;
      newLeaveList[membersIndex] = leaveList;
    }
    setInterviewerList(newArray);
    setInterviewerLeaveList(newLeaveList);
  };
  // const handleSearch = (selectedKeys, confirm, dataIndex) => {
  //   confirm();
  // };

  // const handleReset = (clearFilters) => {
  //   clearFilters();
  // };
  const handleFormValues = (buttonAction) => {
    let payload = [];
    let interviewerID = [];
    let surveymemberID = [];
    let interviewerError = interviewerList.some((el) => {
      return el.length === 0;
    });
    if (interviewerError) {
      setErrorMessage("Please select interviewer for all members");
    } else if (
      assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member.length === interviewerList.length
    ) {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            let id = el.user.survey_x_members[0].id;
            surveymemberID.push(id);
          });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member?.forEach((key, index) => {
          payload.push({
            member_id: key.user.id,
            quota: 1,
            state: key.user.survey_x_members[0].state,
            survey_id: parseInt(surveyID),
          });
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  interviewerID.push(key.id);
                });
          });

      deleteMembers({
        variables: {
          array: surveymemberID,
          Interviewerarray: interviewerID,
          object: payload,
        },
      }).then((res) => {
        let object = [];
        res.data.insert_survey_x_member.returning
          // eslint-disable-next-line
          .map((key, index) => {
            interviewerList &&
              interviewerList.length !== 0 &&
              interviewerList[index]
                // eslint-disable-next-line
                .map((interviewer, i) => {
                  object.push({
                    survey_x_member_id: key.id,
                    status: key.state,
                    interviewer_id: interviewer,
                    interviewer_on_leave:
                      interviewerLeaveList.length === 0
                        ? leaveList[index][i] === undefined
                          ? false
                          : leaveList[index][i]
                        : interviewerLeaveList[index] === undefined
                          ? leaveList[index][i] === undefined
                            ? false
                            : leaveList[index][i]
                          : interviewerLeaveList[index][interviewer] ===
                            "On Leave"
                            ? true
                            : false,
                    priority: i + 1,
                  });
                });
          });
        insertInterviewer({
          variables: {
            objects: object,
          },
        }).then((ins_res) => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              startDate: startDate,
              endDate: dueDate,
              status: buttonAction,
            },
          }).then(() => {
            // resetMemberDetails();
            refetchAssignQuery();
            if (buttonAction === "active") {
              props.onComplete();
            } else {
              props.handleDraft();
            }
          });
        });
      });
    }
  };

  const onComplete = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
      ...[
        {
          title: "Action",
          dataIndex: "action",
          isShow: columnStatus?.action?.enabled,
          className: columnStatus?.action?.enabled ? "table-cell" : "hidden",
          render: (value, item, index) => {
            return (
              <>
                {interviewerList &&
                  interviewerList.length !== 0 &&
                  interviewerList[index]?.length !== 0 ? (
                  <Button
                    type="link"
                    className="text-base p-0 text-left"
                    onClick={() => {
                      setEnableDrawer(true);
                      setMemberIndex(index);
                    }}
                  >
                    <span className="w-24">
                      {interviewerList[index]?.length === 1
                        ? "Interviewer"
                        : "Interviewers"}
                    </span>
                    <Input
                      value={
                        interviewerList[index]
                          ? interviewerList[index]?.length
                          : value?.length
                      }
                      readOnly
                      className="w-8 rounded-xl ml-2"
                    />
                  </Button>
                ) : (
                  <Button
                    type="link"
                    className="text-base p-0 text-left"
                    onClick={() => {
                      setEnableDrawer(true);
                      setMemberIndex(index);
                    }}
                  >
                    Assign
                  </Button>
                )}
              </>
            );
          },
        },
      ],
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus, interviewerList]);
  // if (!tableData1) {
  //   return <p>Loading...</p>;
  // }
  return (
    <>
      <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div>
      <Form>
        <div className="selectMembers px-10 py-4 w-full">
          <p className="text-textMedium text-lg float-left w-1/2">
            Assign interviewers
          </p>
          <span className=" float-right ml-5">
            <ColumnEditor
              columns={columnStatus}
              onSave={(column) => {
                setStatus(column);
              }}
            />
          </span>
          <Form.Item className="float-right">
            <button
              className="text-orange text-base"
              size="middle"
              type="link"
              onClick={handleClick}
            >
              Edit list
            </button>
            {!!selectedMembers?.length && (
              <Button
                type="primary"
                className="px-8 rounded-md ml-5"
                size="large"
                onClick={() => {
                  setMemberIndex();
                  setEnableDrawer(true);
                }}
              >
                Assign
              </Button>
            )}
          </Form.Item>
          <Form.Item className="block" name="members">
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectedMemberKeys,
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows, info) => {
                  setSelectedMemberKeys(selectedRowKeys);
                  setSelectedMembers(selectedRows);
                },
                onSelect: (record, selected) => {
                  if (selected) {
                    tableData.map((data, dataIndex) => {
                      if (record.id === data.id) {
                        if (
                          interviewerList &&
                          interviewerList.length !== 0 &&
                          interviewerList[dataIndex].length !== 0
                        ) {
                          setShowInterviewerAlert(true);
                        }
                      }
                    });
                  }
                },
              }}
            ></MemberTable>

            <SpecialityModalPop
              titleSpec={`Selected ${selectedColumnNameSpec}`}
              visibleSpec={isModalVisibleSpec}
              onOkSpec={handleModalOkSpec}
              onCancelSpec={handleModalCloseSpec}
              searchQuerySpec={searchQuerySpec}
              handleSearchSpec={handleSearchSpec}
              handleCheckboxChangeSpec={handleCheckboxChangeSpec}
              filteredCheckboxDataSpec={filteredCheckboxDataSpec}
              selectedDataSpec={selectedDataSpec}
              initialSelectedDataSpec={initialSelectedDataSpec}
              isSubmitDisabledSpec={isSubmitDisabledSpec}
              handleResetSpec={handleResetSpec}
            />
          </Form.Item>
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                  ? true
                  : false
              }
              loading={
                onDeleteLoading ||
                onUpdateSurveyLoading ||
                onInsertInterviewerLoading
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={onComplete}
              loading={
                onDeleteLoading ||
                onUpdateSurveyLoading ||
                onInsertInterviewerLoading
              }
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
      {enableDrawer === true && (
        <AssignInterviewer
          visible={enableDrawer}
          setVisible={setEnableDrawer}
          handleValidationValues={handleValidationValues}
          memberIndex={memberIndex !== undefined ? memberIndex : 0}
          interviewerList={
            memberIndex !== undefined ? interviewerList[memberIndex] : []
          }
          selectedMember={memberIndex === undefined ? selectedMembers : []}
          member={memberIndex !== undefined ? tableData[memberIndex] : {}}
        />
      )}
      <Modal
        title={<b>Assign interviewers</b>}
        visible={showInterviewerAlert}
        // okText={<span>Update</span>}
        onOk={() => { }}
        onCancel={() => {
          setShowInterviewerAlert(false);
        }}
        width={300}
        footer={[
          <Button
            type="primary"
            key="back"
            className=" px-8 rounded-md"
            onClick={() => {
              setShowInterviewerAlert(false);
            }}
          >
            Ok
          </Button>,
        ]}
      >
        <div className="flex justify-start items-center">
          Assigning to previously assigned members will override the
          interviewers
        </div>
      </Modal>
    </>
  );
}
export default AssignInterviewerList;
