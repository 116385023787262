import { gql } from "@apollo/client";

export const UPDATE_LOGIC = gql`
  mutation UPDATE_LOGIC($id: Int, $object: jsonb) {
    update_questions_options(
      where: { id: { _eq: $id } }
      _set: { logic: $object }
    ) {
      affected_rows
      returning {
        question {
          id
        }
      }
    }
  }
`;

export const INSERT_QUESTION = gql`
  mutation MyMutation($object: [questions_insert_input!]!) {
    insert_questions(objects: $object) {
      affected_rows
    }
  }
`;
export const UPDATE_IS_LOGIC = gql`
  mutation UPDATE_LOGIC_BOOLEAN($id: Int, $isLogic: Boolean) {
    update_questions(where: { id: { _eq: $id } }, _set: { logic: $isLogic }) {
      affected_rows
    }
  }
`;
export const UPSERT_QUESTION = gql`
  mutation MyMutation(
    $object: [questions_insert_input!]!
    $optionObject: [questions_options_insert_input!]!
  ) {
    insert_questions(
      objects: $object
      on_conflict: {
        constraint: questions_pkey
        update_columns: [
          question
          question_type_id
          survey_id
          index
          validation
        ]
      }
    ) {
      affected_rows
    }
    insert_questions_options(
      objects: $optionObject
      on_conflict: {
        constraint: questions_options_pkey
        update_columns: [label, index, more_info, options]
      }
    ) {
      affected_rows
    }
  }
`;
export const DELETE_QUESTION = gql`
  mutation DELETE_QUESTION(
    $array: [Int!]
    $object: [questions_insert_input!]!
  ) {
    delete_questions_options(where: { questions_id: { _in: $array } }) {
      affected_rows
    }
    delete_questions(where: { id: { _in: $array } }) {
      affected_rows
    }
    insert_questions(objects: $object) {
      affected_rows
    }
  }
`;
export const GET_QUESTION_TYPES = gql`
  query GET_QUESTION_TYPES {
    question_types {
      id
      name
    }
  }
`;

export const GET_QUESTIONS = gql`
  query GET_QUESTIONS($id: Int!) {
    questions(where: { survey_id: { _eq: $id } }, order_by: { index: asc }) {
      question
      logic
      id
      index
      validation
      questions_options(order_by: { index: asc }) {
        label
        logic
        id
        index
        options
        more_info
        questions_id
      }
      question_type {
        name
        id
      }
    }
  }
`;

export const GET_QUESTION_OPTIONS = gql`
  query GET_QUESTIONS($id: Int!) {
    questions(where: { id: { _eq: $id } }, order_by: { index: asc }) {
      questions_options(order_by: { index: asc }) {
        label
        logic
        id
      }
    }
  }
`;
