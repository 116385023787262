import { gql } from "graphql-tag";

export const INSERT_CLIENT = gql`
  mutation MyMutation(
    $address: String
    $billing_cycle: String
    $category: String
    $company_name: String
    $country: String
    $email: String
    $f_name: String
    $l_name: String
    $phone_number: String
    $role_id: Int
    $password: String = ""
  ) {
    insert_client(
      objects: {
        address: $address
        billing_cycle: $billing_cycle
        category: $category
        company_name: $company_name
        country: $country
        user: {
          data: {
            email: $email
            f_name: $f_name
            l_name: $l_name
            phone_number: $phone_number
            role_id: $role_id
            password: $password
          }
        }
      }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_CLIENT1 = gql`
  mutation MyMutation(
    $address: String
    $billing_cycle: Int
    $category: Int
    $company_name: String
    $country: Int
    $email: String
    $phone_number: String
    $role_id: Int
    $password: String = ""
    $is_verified: Boolean
    $data: [client_custom_field_insert_input!]!
  ) {
    insert_client(
      objects: {
        address: $address
        billing_cycle: $billing_cycle
        category: $category
        company_name: $company_name
        country: $country
        user: {
          data: {
            f_name: $company_name
            email: $email
            phone_number: $phone_number
            role_id: $role_id
            password: $password
            is_verified: $is_verified
          }
        }
        client_custom_fields: { data: $data }
      }
    ) {
      affected_rows
      returning {
        id
        user_id
        user {
          id
          email
        }
      }
    }
  }
`;

export const INSERT_POC = gql`
  mutation CreatePOC(
    $client_id: Int
    $designation: String
    $email: String
    $first_name: String
    $last_name: String
    $phone_number: String
  ) {
    insert_poc(
      objects: [
        {
          client_user: $client_id
          first_name: $first_name
          last_name: $last_name
          phone_number: $phone_number
          designation: $designation
          email: $email
        }
      ]
    ) {
      affected_rows
    }
  }
`;

export const INSERT_POC1 = gql`
  mutation InsertPOC($data: [poc_insert_input!]!) {
    insert_poc(objects: $data) {
      affected_rows
      returning {
        id
        user_id
      }
    }
  }
`;

export const GET_CLIENTS_META = gql`
  query GET_CLIENTS {
    country(order_by: { id: asc }) {
      name
      id
    }
    client_category {
      id
      name
    }
    billing_cycle {
      id
      values
    }
  }
`;

export const GET_CLIENTS_DETAILS = gql`
  query getClientInfoById($client_id: Int) {
    client(where: { id: { _eq: $client_id } }) {
      address
      billing_cycle
      category
      company_name
      country
      id
      user_id
      user {
        id
        email
        f_name
        l_name
        phone_number
      }
      client_custom_fields {
        label
        value
      }
    }
  }
`;

export const GET_CLIENT_BY_ID = gql`
  query getClientInfoById($client_id: Int!) {
    client_by_pk(id: $client_id) {
      address
      billingCycleByBillingCycle {
        id
        values
      }
      billing_cycle
      client_category {
        id
        name
      }
      category
      company_name
      country
      countryByCountry {
        id
        name
      }
      id
      user_id
      user {
        email
        f_name
        l_name
        phone_number
        is_verified
        projects {
          id
          name
          survey_type
          sample
          manager_x_projects {
            id
            manager_id
            user {
              id
              f_name
              l_name
            }
            project {
              id
              projectTypeByProjectType {
                id
                name
              }
            }
          }
          surveys {
            id
          }
          member_types
        }
      }
      client_custom_fields {
        label
        value
      }
    }
    poc(where: { client_user: { _eq: $client_id } }) {
      designation
      email
      first_name
      id
      user_id
      last_name
      phone_number
    }
  }
`;

export const UPDATE_CLIENT = gql`
  mutation UpdateClient(
    $client_id: Int
    $email: String
    $phone_number: String
    $address: String
    $billing_cycle: Int
    $category: Int
    $company_name: String
    $country: Int
    $data: [client_custom_field_insert_input!]!
  ) {
    update_client(
      where: { id: { _eq: $client_id } }
      _set: {
        address: $address
        billing_cycle: $billing_cycle
        category: $category
        company_name: $company_name
        country: $country
      }
    ) {
      affected_rows
      returning {
        id
        user_id
      }
    }
    update_user(
      where: { clients: { id: { _eq: $client_id } } }
      _set: {
        f_name: $company_name
        email: $email
        phone_number: $phone_number
      }
    ) {
      affected_rows
    }
    delete_client_custom_field(where: { client_data_id: { _eq: $client_id } }) {
      affected_rows
    }
    insert_client_custom_field(objects: $data) {
      affected_rows
    }
  }
`;

export const GET_POC_BY_CLIENT = gql`
  query GetPOCdetails($client_id: Int) {
    poc(where: { client_user: { _eq: $client_id } }) {
      designation
      email
      first_name
      id
      user_id
      last_name
      phone_number
      clientByClientUser {
        id
        user_id
      }
    }
  }
`;

export const UPDATE_POC_BY_USER_ID = gql`
  mutation UpdatePOC($objects: [poc_insert_input!]!, $user_id: Int) {
    delete_poc(where: { client_user: { _eq: $user_id } }) {
      affected_rows
    }
    insert_poc(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_POC = gql`
  mutation UpdatePocDetails(
    $poc_id: Int!
    $poc_user_id: Int!
    $first_name: String
    $last_name: String
    $email: String
    $phone_number: String
    $designation: String
  ) {
    update_poc_by_pk(
      pk_columns: { id: $poc_id }
      _set: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone_number: $phone_number
        designation: $designation
      }
    ) {
      id
    }
    update_user(
      where: { id: { _eq: $poc_user_id } }
      _set: {
        f_name: $first_name
        l_name: $last_name
        email: $email
        phone_number: $phone_number
      }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_POC_BY_ID = gql`
  mutation DeletePOC($pocIds: [Int!]!, $pocUserIds: [Int!]) {
    delete_poc(where: { id: { _in: $pocIds } }) {
      affected_rows
    }
    delete_user(where: { id: { _in: $pocUserIds } }) {
      affected_rows
    }
  }
`;

export const REMOVE_POC_BY_ID = gql`
  mutation removePOC($poc_id: Int) {
    delete_poc(where: { id: { _eq: $poc_id } }) {
      affected_rows
    }
  }
`;

export const VALIDATE_CLIENT_MAIL = gql`
  query checkClientMail($email: String, $client_id: [Int!], $user_id: [Int]!) {
    client(
      where: { id: { _nin: $client_id }, user: { email: { _eq: $email } } }
    ) {
      id
      company_name
    }
    user(where: { id: { _nin: $user_id }, email: { _eq: $email } }) {
      id
      f_name
      email
    }
  }
`;

export const VALIDATE_POC_EMAIL = gql`
  query checkPOCmail($emailIds: [String], $pocIds: [Int], $userIds: [Int]) {
    poc(where: { id: { _nin: $pocIds }, email: { _in: $emailIds } }) {
      id
      first_name
      email
    }
    user(where: { id: { _nin: $userIds }, email: { _in: $emailIds } }) {
      id
      f_name
      email
    }
  }
`;

export const ONBOARD_USER = gql`
  mutation onbord_user($user_id: Int!) {
    onbord_user(user_id: $user_id) {
      message
    }
  }
`;

export const ADD_APPROVAL = gql`
  mutation MyMutation(
    $old_info: jsonb
    $updated_info: jsonb
    $updated_by: Int
    $user_id: Int
  ) {
    insert_approval(
      objects: {
        old_info: $old_info
        updated_info: $updated_info
        updated_by: $updated_by
        user_id: $user_id
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_ACTIVITY = gql`
  query GET_ACTIVITY($id: Int) {
    approval(where: { user: { id: { _eq: $id } } }) {
      id
      old_info
      status
      updated_by
      updated_info
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VERIFY_USER($id: [Int!], $status: Boolean) {
    update_user(where: { id: { _in: $id } }, _set: { is_verified: $status }) {
      affected_rows
    }
  }
`;

export const REJECT_USER = gql`
  mutation REMOVE_USER($id: [Int!], $pocId: [Int!], $clientID: Int) {
    delete_poc(where: { id: { _in: $pocId } }) {
      affected_rows
    }
    delete_client(where: { id: { _eq: $clientID } }) {
      affected_rows
    }
    delete_user(where: { id: { _in: $id } }) {
      affected_rows
    }
  }
`;
