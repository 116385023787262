import {
  Col,
  Row,
  Breadcrumb,
  Card,
  Form,
  Button,
  Input,
  Select,
  notification,
  Upload,
  Divider,
  Switch,
  InputNumber,
  Checkbox,
  DatePicker,
  Tabs,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  CREATE_TECHNICIAN,
  ADD_APPROVAL,
  GET_TECHPROFESSION,
  GET_TechCategory,
  GET_TechCategory2,
} from "./graphql";
import { useForm } from "antd/lib/form/Form";
import { useMutation, useQuery } from "@apollo/client";
import { data } from "../data";
import {
  EDIT_USERS,
  GET_EMAIL_PHONE,
  ONBOARD_USER,
  CREATE_USERS_BULK,
  GET_DOWNLOAD_URL,
  UPDATE_DNC_COUNT,
  IMPORT_EMAIL_TRIGGER,
  GET_ONE_USER,
  REJECTION_DATA,
  GET_USERS,
  ONBOARD_USER_RES_ID,
} from "../graphql";
import jwtDecode from "jwt-decode";
import { InboxOutlined } from "@ant-design/icons";
import { downloadCSV, validateCSV } from "../../../utils/processCSV";
import { technician } from "../../../constants/csvData";
import moment from "moment";
import { disableDateYearOfBirth } from "../../../utils/dateTime";
import { generateRandomString } from "../../../utils/randomGenerator";
import WorkDetails from "../workDetails";
import { MemberStatus } from "../../../utils/memberStatus";
import { StatusMismatchModal, WarningMismatchPopup, executeBulkInsertUserFunction, generatePhysicianInfoObject, handleCheckboxChangeCommon, handleExecution, handleProceedCommon, processStatusMismatch, rejectionProceedCommon, technicianRowData } from "../bulkimport";

const { TabPane } = Tabs;
const { Dragger } = Upload;
const { Option } = Select;
const TechnicianForm = (props) => {
  const [role, setRole] = useState(null);
  const [id, setId] = useState(null);

  const [activeTab, setActiveTab] = useState("1");
  const [formData, setFormData] = useState(null);
  const [validRequired, setValidRequired] = useState(false);
  const { data } = useQuery(GET_ONE_USER, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      let decodedId = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["X-Hasura-User-Id"];
      setId(decodedId);
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const managerName = data?.user_by_pk?.f_name + " " + data?.user_by_pk?.l_name;
  return (
    <>
      <Row>
        <Col span={24} className="px-8 py-5">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {props?.data
                ? " Edit a member - Technician"
                : " Create a new member - Technician"}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="px-8 font-semibold text-lg">
          {props?.data ? (
            <p>Edit a member - Technician</p>
          ) : (
            <p>Create a new member - Technician</p>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="px-8">
          <Card
            bodyStyle={{
              maxHeight: "85vh",
              boxShadow: "0px 2px 5px rgba(33, 33, 33, 0.1);",
              borderRadius: "5px",
              overflow: "auto",
            }}
          >
            <Tabs
              tabPosition="left"
              size="large"
              className="text-textExtraLight"
              tabBarStyle={{ width: "200px" }}
              activeKey={activeTab}
            >
              <TabPane tab="Basic details" key="1">
                <BasicDetailForm
                  setTab={setActiveTab}
                  setData={setFormData}
                  role={role}
                  editData={props?.data}
                  validRequired={validRequired}
                  managerName={managerName}
                  id={id}
                />
              </TabPane>
              <TabPane tab="Work details" key="2">
                <WorkDetailForm
                  data={formData}
                  setTab={setActiveTab}
                  editData={props?.data}
                  role={role}
                  id={id}
                  setValidRequired={setValidRequired}
                  validRequired={validRequired}
                />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const BasicDetailForm = ({
  setTab,
  setData,
  role,
  editData,
  validRequired,
  managerName,
  id
}) => {
  const history = useHistory();
  const [formRef] = Form.useForm();
  const [states, setStates] = useState([]);
  const btn = useRef(null);
  const [cities, setCities] = useState([]);
  const [isOtherState, setIsOtherState] = useState(false);
  const [isOtherCity, setIsOtherCity] = useState(false);
  const [respondentDB, setRespondentDB] = useState([]);
  const [respondentVal, setRespondentVal] = useState("");
  const [isZoneDisabled, setIsZoneDisabled] = useState(true);
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);
  const [isOthersProfession, setIsOthersProfession] = useState(false);
  const [isOthersSubCategory1, setIsOthersSubCategory1] = useState(false);
  const [isOthersSubCategory2, setIsOthersSubCategory2] = useState(false);
  const [selectedSubCategory1, setSelectedSubCategory1] = useState([]);
  const [subCate1, setSubCate1] = useState("");
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [techProfessionId, setTechProfessionId] = useState();
  const [statusVisible, setStatusVisible] = useState(false);
  const [errorRecords, setErrorRecords] = useState([]);
  const [trackStatus, setTrackStatus] = useState([]);
  const [errorTrackStatus, setErrorTrackStatus] = useState([]);
  const [rejectionModal, setRejectionModal] = useState(false);
  const [rejectionData, setRejectionData] = useState();
  const [trackTempData, setTrackTempData] = useState([]);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [statusCorrectedValue, setStatusCorrectedValue] = useState([]);
  const [ImportEmailTrigger] = useMutation(IMPORT_EMAIL_TRIGGER);
  const [form] = useForm();
  const [bulkInsertUser] = useMutation(CREATE_USERS_BULK);
  const [rejectionDataList] = useMutation(REJECTION_DATA);
  const [professionData, setProfessionData] = useState();
  const [statusmismatch, setStatusmismatch] = useState();
  const [uncheckedStatus, setUncheckedStatus] = useState();

  // eslint-disable-next-line
  const {
    data: emailPhone,
    loading: emailPhoneLoading,
    refetch,
  } = useQuery(GET_EMAIL_PHONE);

  const { data: techProfessionData } = useQuery(GET_TECHPROFESSION, {
    onCompleted: (resp) => {
      if (resp.tech_profession) {
        const profession = resp.tech_profession.map((profession) => profession);
        setProfessionData(profession);
      }
    },
  });

  const { data: techCategoryData, refetch: refetchTechCategory } = useQuery(
    GET_TechCategory,
    {
      variables: { techProfessionId },
    }
  );

  const { data: techCategoryData2, refetch: refetchTechCategory2 } = useQuery(
    GET_TechCategory2,
    {
      variables: { techProfessionId },
    }
  );
  useEffect(() => {
    setTechProfessionId(techProfessionData?.tech_profession?.id);
  }, [techProfessionData]);

  useEffect(() => {
    refetchTechCategory({ techProfessionId });
  }, [techProfessionId, refetchTechCategory]);

  useEffect(() => {
    refetchTechCategory2({ techProfessionId });
  }, [techProfessionId, refetchTechCategory2]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (emailPhone) {
      let emailCopy = [],
        respondentCopy = [],
        phoneCopy = [];
      emailPhone?.user.forEach((user) => {
        emailCopy.push(user.email);
        emailCopy.push(user.meta_date?.email2);
        phoneCopy.push(user.phone_number);
        phoneCopy.push(user.meta_date?.mobile2);
        phoneCopy.push(user.meta_date?.phone);
        if (user.meta_date?.respondent)
          respondentCopy.push(user.meta_date?.respondent);
      });
      setEmail(emailCopy);
      setPhone(phoneCopy);
      setRespondentDB(respondentCopy);
    }
  }, [emailPhone]);

  useEffect(() => {
    if (data) {
      let state,
        statesCopy = [];
      for (state in data) {
        statesCopy.push(state);
      }
      statesCopy.push("Others");
      setStates(statesCopy);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (
      !editData?.user_by_pk?.meta_date?.respondent &&
      respondentDB.length > 0
    ) {
      const usedRespondents = new Set(respondentDB);
      const maxAttempts = 100;
      let randomDigits,
        attempts = 0;

      do {
        randomDigits = generateRandomString(10);
        attempts++;
      } while (usedRespondents.has(randomDigits) && attempts < maxAttempts);

      if (attempts === maxAttempts) {
        console.log("Failed to find a unique random string.");
      } else {
        setRespondentVal(randomDigits);
      }
    } else if (editData?.user_by_pk?.meta_date?.respondent) {
      setRespondentVal(editData?.user_by_pk?.meta_date?.respondent);
    }
  }, [editData, respondentDB]);

  useEffect(() => {
    if (editData && states.length > 0) {
      if (!states.includes(editData?.user_by_pk?.state)) {
        setIsOtherState(true);
        setIsOtherCity(true);
        formRef.current.setFieldsValue({
          otherState: editData?.user_by_pk?.state,
          state: "Others",
        });
        formRef.current.setFieldsValue({
          otherCity: editData?.user_by_pk?.city,
          city: "Others",
        });
      } else {
        getCities(editData?.user_by_pk?.state);
        formRef.current.setFieldsValue({
          state: editData?.user_by_pk?.state,
        });
      }
    }
  }, [editData, states]);

  useEffect(() => {
    if (
      editData &&
      cities.length > 0 &&
      editData?.user_by_pk?.city === formRef.current.getFieldValue("city")
    ) {
      if (!cities.includes(editData?.user_by_pk?.city)) {
        setIsOtherCity(true);
        formRef.current.setFieldsValue({
          otherCity: editData?.user_by_pk?.city,
          city: "Others",
        });
      } else {
        formRef.current.setFieldsValue({
          city: editData?.user_by_pk?.city,
        });
      }
    }
  }, [editData, cities]);

  let mobile = /^[0-9]{10}$/;
  let pincodetest = /^[0-9]{6}$/;
  let experience = /^[0-9]*$/;
  const getCities = (stateInput) => {
    if (states.length > 0) {
      let citiesCopy = [],
        state;
      for (state in data) {
        if (state === stateInput) {
          for (let stateVal of data[state]) {
            citiesCopy.push(stateVal.city);
          }
          citiesCopy.push("Others");
          setCities(citiesCopy);
        }
      }
    }
  };

  const getCategoryAndZone = (cityInput) => {
    let stateInput = formRef.current.getFieldValue("state");
    let fieldObj;
    for (let stateVal of data[stateInput]) {
      if (stateVal.city === cityInput) {
        if (!stateVal.zone) setIsZoneDisabled(false);
        else setIsZoneDisabled(true);
        if (!stateVal.category) setIsCategoryDisabled(false);
        else setIsCategoryDisabled(true);
        fieldObj = {
          zone: stateVal.zone,
          cityCategory: stateVal.category,
        };
      }
    }
    formRef.current.setFieldsValue(fieldObj);
  };

  const onFinish = (values) => {
    if (values?.access === undefined) {
      form.setFieldsValue({
        access: true,
      });
    }
    const variables = {
      respondent_id: editData ? values.respondent_id : respondentVal,
      firstName: values.fname,
      lastName: values.lname,
      title: values.title,
      phone: values.mobile1,
      email: values.email1.toLowerCase(),
      gender: values.gender,
      state: values.state === "Others" ? values.otherState : values.state,
      city: values.city === "Others" ? values.otherCity : values.city,
      is_verified: role === "super_admin" ? true : false,
      pincode: parseInt(values.pincode),
      year_of_birth: values.year,
      metadata: {
        email2: values.email2?.toLowerCase(),
        mobile2: values.mobile2,
        mobile1: values.mobile1,
        phone: values.phone,
        profession: values.profession,
        sub: values.sub,
        sub2: values.sub2,
        access: values.access,
        organisation: values.organisation,
        cityCategory: values.cityCategory,
        zone: values.zone,
        noOfBeds: values.noOfBeds,
        hospitalType: values.hospitalType,
        mobile1Whatsapp: values.mobile1Whatsapp,
        mobile2Whatsapp: values.mobile2Whatsapp,
        experience: values.experience,
        respondent: respondentVal,
      },
      additional_fields: values.additional_fields,
      password: "",
      status: values.status,
    };
    setData({ ...variables });
    setTab("2");
  };

  const initialValues = {
    respondent_id: editData?.user_by_pk?.meta_date?.respondent || "",
    fname: editData?.user_by_pk?.f_name || "",
    lname: editData?.user_by_pk?.l_name || "",
    title: editData?.user_by_pk?.title || "",
    gender: editData?.user_by_pk?.gender || undefined,
    year: editData?.user_by_pk
      ? editData?.user_by_pk?.year_of_birth
        ? moment(editData?.user_by_pk?.year_of_birth)
        : ""
      : "",
    mobile1: editData?.user_by_pk?.phone_number || "",
    mobile2: editData?.user_by_pk?.meta_date?.mobile2 || "",
    phone: editData?.user_by_pk?.meta_date?.phone || "",
    email1: editData?.user_by_pk?.email.toLowerCase() || "",
    email2: editData?.user_by_pk?.meta_date?.email2?.toLowerCase() || "",
    state: editData?.user_by_pk?.state || undefined,
    city: editData?.user_by_pk?.city || undefined,
    pincode: editData?.user_by_pk?.pincode || "",
    cityCategory: editData?.user_by_pk?.meta_date?.cityCategory || "",
    zone: editData?.user_by_pk?.meta_date?.zone || "",
    profession: editData?.user_by_pk?.meta_date?.profession || "",
    sub: editData?.user_by_pk?.meta_date?.sub || "",
    sub2: editData?.user_by_pk?.meta_date?.sub2 || "",
    organisation: editData?.user_by_pk?.meta_date?.organisation || "",
    additional_fields:
      editData?.user_by_pk?.meta_date?.additional_data ||
      "" ||
      editData?.additional_data,
    access: editData?.user_by_pk?.meta_date?.access === false ? false : true,
    mobile1Whatsapp:
      editData?.user_by_pk?.meta_date?.mobile1Whatsapp === false ? false : true,
    mobile2Whatsapp:
      editData?.user_by_pk?.meta_date?.mobile2Whatsapp === true ? true : false,
    noOfBeds: editData?.user_by_pk?.meta_date?.noOfBeds || "",
    hospitalType: editData?.user_by_pk?.meta_date?.hospitalType || "",
    experience: editData?.user_by_pk?.meta_date?.experience || "",
    status: editData?.user_by_pk?.status || "",
  };

  const handleShowWarnings = (errors) => {
    setErrorRecords(errors);
    // setStatusVisible(true);
  };

  const rejectionModalClose = () => {
    setRejectionModal(false);
  }

  let successRecords = [];
  const errorRecords1 = [];
  const tempErrorRecords = [];
  const onProcessFile = (e) => {
    let tempData = [];
    const activePage = "2";
    // let validData;
    let processed_tech = technician.headers.map((technician) => {
      return technician;
    });
    technician.headers = processed_tech;
    // validData = validateCSV(e.target.result, technician, phone, email, respondentDB);
    const finalValidData = validateCSV(e.target.result, technician, phone, email, respondentDB);
    const validData = finalValidData.finalValidData;
    const rejectedRecords = finalValidData.rejectedRecords;
    const additionalColumns = finalValidData.additionalColumns;
    const toCheckStatusMismatch = finalValidData.toCheckStatusMismatch;
    setUncheckedStatus(toCheckStatusMismatch)
    setRejectionData(rejectedRecords);

    validData.map((rowData) => {
      if (rowData !== "") {
        rowData = rowData.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
        const processedData = technicianRowData(rowData, professionData, additionalColumns, role);
        tempData.push(processedData);
      }
    });
    setTrackTempData(tempData);

    tempData.forEach((data) => {
      processStatusMismatch(data, trackStatus, setTrackStatus, setErrorTrackStatus, successRecords, errorRecords1, tempErrorRecords, activePage);
    });
    const updatedErrorRecords = handleExecution(rejectedRecords, tempErrorRecords, setRejectionModal, handleShowWarnings, executeBulkInsertUser, tempData, statusCorrectedValue, activePage, setStatusVisible);
    setStatusCorrectedValue(updatedErrorRecords);
    setStatusmismatch(tempErrorRecords);
  };
  useEffect(() => {
  }, [uncheckedStatus]);
  useEffect(() => {
    // executeBulkInsertUser(trackTempData);
  }, [trackStatus]);

  let cleanedJsonData = trackStatus
    .map(item => {
      const { errorRecords1, ...rest } = item;
      return rest;
    })
    .filter(item => Object.keys(item).length > 0);
  const statusCounts = {
    "Valid": 0,
    "raw": 0,
    "partial-email": 0,
    "partial-phone": 0,
    "not-validated": 0,
    "invalid": 0,
    "DNC": 0,
    "Prohibited": 0
  };
  cleanedJsonData.forEach(member => {
    const status = member.status;
    if (statusCounts.hasOwnProperty(status)) {
      statusCounts[status]++;
    }
  });
  const { data: getUser } = useQuery(GET_USERS);
  const isRespondentIdInList = (respondentId) => {
    return respondentDB.includes(respondentId);
  };

  const editMemberData = [];
  const newUserCreateData = [];
  const getUserOldInfo = [];

  cleanedJsonData.forEach((entry) => {
    const respondentId = entry.respondent_id;
    if (isRespondentIdInList(respondentId)) {
      editMemberData.push(entry);
    } else {
      newUserCreateData.push(entry);
    }
  });

  editMemberData.forEach((entry) => {
    const respondentId = parseInt(entry.respondent_id, 10);
    const userWithMatchingId = getUser?.user.find((item) => {
      return item.respondent_id === respondentId;
    });

    if (userWithMatchingId) {
      getUserOldInfo.push(userWithMatchingId);
    }
  });

  const getUserOldInfoMap = {};
  getUserOldInfo.forEach((entry) => {
    getUserOldInfoMap[entry.respondent_id] = entry;
  });

  const editMemberDataMap = {};
  editMemberData.forEach((entry) => {
    editMemberDataMap[entry.respondent_id] = entry;
  });

  const getUserOldInfoArray = Object.values(getUserOldInfoMap);
  const editMemberDataArray = Object.values(editMemberDataMap);
  const destination = '/members/technician/1';
  const [addApproval] = useMutation(ADD_APPROVAL);
  const member_type = 'Technician';
  const [onboardUserResID] = useMutation(ONBOARD_USER_RES_ID);

  const executeBulkInsertUser = (tempData) => {
    executeBulkInsertUserFunction(
      tempData,
      uncheckedStatus,
      rejectionData,
      trackStatus,
      cleanedJsonData,
      statusCounts,
      role,
      destination,
      getUserOldInfoArray,
      editMemberDataArray,
      id,
      history,
      newUserCreateData,
      rejectionDataList,
      addApproval,
      bulkInsertUser,
      ImportEmailTrigger,
      managerName,
      member_type,
      onboardUserResID
    );
  }

  const handleCheckboxChange = (record, respondentId, checked) => {
    handleCheckboxChangeCommon(record, respondentId, checked, setSelectedRecords, setTrackStatus);
  };

  const handleProceed = () => {
    handleProceedCommon(executeBulkInsertUser, trackTempData, setStatusVisible);
  };

  const rejectionProceed = () => {
    rejectionProceedCommon(setRejectionModal, statusmismatch, setStatusVisible, executeBulkInsertUser);
  };

  const handleProfessionChange = (value, option) => {
    if (value === "Others") {
      setIsOthersProfession(true);
      setSelectedSubCategory1("Others");
      setIsOthersSubCategory1(true);
    } else {
      setIsOthersProfession(false);
      setIsOthersSubCategory1(false);
      setSelectedSubCategory1([]);
    }
    if (subCate1 === "Others") {
      setIsOthersSubCategory1(true);
    }
    refetchTechCategory({ techProfessionId: option.key });
    refetchTechCategory2({ techProfessionId: option.key });
  };

  const handleSubCategoryChange = (value) => {
    setSubCate1(value);
    if (value === "Others") {
      setIsOthersSubCategory1(true);
    } else {
      setIsOthersSubCategory1(false);
    }
  };
  const handleSubCategory2Change = (value) => {
    if (value === "Others") {
      setIsOthersSubCategory2(true);
    } else {
      setIsOthersSubCategory2(false);
    }
  };
  return (
    <>
      <WarningMismatchPopup
        rejectionModal={rejectionModal}
        rejectionModalClose={rejectionModalClose}
        rejectionProceed={rejectionProceed}
        rejectionData={rejectionData}
      />
      <StatusMismatchModal
        modalVisible={statusVisible}
        setModalVisible={setStatusVisible}
        errorRecords={errorRecords}
        statusCorrectedValue={statusCorrectedValue}
        handleCheckboxChange={handleCheckboxChange}
        handleProceed={handleProceed}
      />
      <Form
        ref={formRef}
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Row gutter={16}>
          {editData && (
            <Col span={12}>
              <Form.Item label="Response ID">
                <Input
                  className="p-2"
                  size="large"
                  value={editData?.user_by_pk?.respondent_id}
                  disabled
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name="title" label="Title">
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="fname"
              label="First name"
              rules={[
                {
                  required: validRequired,
                  message: "Please input first name!",
                },
              ]}
            >
              <Input placeholder="First name" className="p-2" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lname"
              label="Last name"
              rules={[
                {
                  required: validRequired,
                  message: "Please input last name!",
                },
              ]}
            >
              <Input placeholder="Last name" className="p-2" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                {
                  required: validRequired,
                  message: "Please select gender!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
              >
                <Option value="M">Male</Option>
                <Option value="F">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="year" label="Year of birth">
              <DatePicker
                placeholder="YYYY"
                picker="year"
                size="large"
                className="w-full"
                disabledDate={(current) => disableDateYearOfBirth(current)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="email1"
              label="Primary email"
              rules={[
                {
                  required: validRequired,
                  message: "Please enter primary email!",
                },
                {
                  type: "email",
                  message: "Please enter an valid email address",
                },
                () => ({
                  validator(_, value) {
                    if (email.includes(value.toLowerCase())) {
                      if (value && value !== initialValues.email1) {
                        return Promise.reject(
                          "Email ID already used. Please use a different one."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email2"
              label="Secondary email"
              rules={[
                () => ({
                  validator(_, value) {
                    if (email.includes(value.toLowerCase())) {
                      if (value && value !== initialValues.email2) {
                        return Promise.reject(
                          "Email ID already used. Please use a different one."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mobile1"
              label="Mobile number 1"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
              rules={[
                { required: validRequired, message: "Required" },
                () => ({
                  validator(_, value) {
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid mobile number"
                      );
                    }
                    if (phone.includes(value)) {
                      if (value && value !== initialValues.mobile1) {
                        return Promise.reject(
                          "Phone number already used. Please use a different one."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
            <Form.Item
              name="mobile2"
              label="Mobile number 2"
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0 0 0 8px",
              }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid mobile number"
                      );
                    }
                    if (phone.includes(value)) {
                      if (value && value !== initialValues.mobile2) {
                        return Promise.reject(
                          "Phone number already used. Please use a different one."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label="Phone"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value !== "" && !mobile.test(value)) {
                      return Promise.reject(
                        "Please enter a valid phone number"
                      );
                    }
                    if (phone.includes(value)) {
                      if (value && value !== initialValues.phone) {
                        return Promise.reject(
                          "Phone number already used. Please use a different one."
                        );
                      }
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="mobile1Whatsapp"
              valuePropName="checked"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Checkbox>Mark as WhatsApp number</Checkbox>
            </Form.Item>
            <Form.Item
              name="mobile2Whatsapp"
              valuePropName="checked"
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0 0 0 8px",
              }}
            >
              <Checkbox>Mark as WhatsApp number</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: validRequired,
                  message: "Please select state!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                onChange={(e) => {
                  if (e === "Others") {
                    setIsOtherState(true);
                    setIsOtherCity(true);
                    formRef.current.setFieldsValue({
                      city: "Others",
                    });
                  } else {
                    setIsOtherState(false);
                    setIsOtherCity(false);
                    getCities(e);
                    formRef.current.setFieldsValue({
                      city: "",
                      otherCity: "",
                      cityCategory: "",
                      zone: "",
                      pincode: "",
                    });
                  }
                }}
                showSearch
                optionFilterProp="children"
              >
                {states.map((state, index) => {
                  return (
                    <Option key={index} value={state}>
                      {state}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              label="City"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
              rules={[
                {
                  required: validRequired,
                  message: "Please select city!",
                },
              ]}
            >
              <Select
                disabled={isOtherState}
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                showSearch
                optionFilterProp="children"
                onChange={(e) => {
                  if (e === "Others") {
                    setIsOtherCity(true);
                  } else {
                    setIsOtherCity(false);
                    getCategoryAndZone(e);
                  }
                }}
              >
                {cities.map((city, index) => {
                  return (
                    <Option key={index} value={city}>
                      {city}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="cityCategory"
              label="City category"
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0 0 0 8px",
              }}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                disabled={!isOtherState && !isOtherCity && isCategoryDisabled}
              >
                <Option key={"Tier 1"} value={"Tier 1"}>
                  Tier 1
                </Option>
                <Option key={"Tier 2"} value={"Tier 2"}>
                  Tier 2
                </Option>
                <Option key={"Tier 3"} value={"Tier 3"}>
                  Tier 3
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            {isOtherState && (
              <Form.Item name="otherState" label="Other State">
                <Input className="p-2" size="large" />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isOtherCity && (
              <Form.Item
                name="otherCity"
                label="Other City"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
              >
                <Input className="p-2" size="large" />
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="experience"
              label="Years of Experience"
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && (!experience.test(value) || value > 80)) {
                      return Promise.reject(
                        "Please enter a valid years of experience"
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <InputNumber className="p-1 w-full" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="zone"
              label="Zone"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                disabled={!isOtherState && !isOtherCity && isZoneDisabled}
              >
                <Option key={"north"} value={"North"}>
                  North
                </Option>
                <Option key={"south"} value={"South"}>
                  South
                </Option>
                <Option key={"west"} value={"West"}>
                  West
                </Option>
                <Option key={"east"} value={"East"}>
                  East
                </Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="pincode"
              label="Pincode"
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0 0 0 8px",
              }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (value && !pincodetest.test(value)) {
                      return Promise.reject("Please enter a valid pincode");
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input className="p-2" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="profession"
              label="Profession"
              rules={[{ required: validRequired, message: "Required" }]}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                showSearch
                optionFilterProp="children"
                onChange={handleProfessionChange}
              >
                {techProfessionData?.tech_profession?.map((techProfession) => (
                  <Option
                    key={techProfession.id}
                    value={techProfession.tech_profession}
                  >
                    {selectedSubCategory1 && (
                      <>{techProfession.tech_profession}</>
                    )}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isOthersProfession && (
            <Col span={24}>
              <Form.Item
                name="isOthersProfession"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Provide details"
                  className="p-2"
                  rows={3}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item
              name="sub"
              label="Sub category 1"
              rules={[
                {
                  required: isOthersProfession,
                  message: "Please input sub-category!",
                },
              ]}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                showSearch
                optionFilterProp="children"
                value={subCate1}
                onChange={handleSubCategoryChange}
              >
                {techCategoryData?.tech_category?.map((subCategory1) => (
                  <Option
                    key={subCategory1.id}
                    value={subCategory1.sub_category_1}
                  >
                    {subCategory1.sub_category_1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isOthersSubCategory1 && (
            <Col span={24}>
              <Form.Item
                name="subCategoryDetails1"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Provide details"
                  className="p-2"
                  rows={3}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={24}>
            <Form.Item name="sub2" label="Sub category 2">
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
                showSearch
                onChange={handleSubCategory2Change}
                optionFilterProp="children"
              >
                {techCategoryData2?.tech_category_2?.map((sub_category_2) => (
                  <Option
                    key={sub_category_2.id}
                    value={sub_category_2.sub_category_2}
                  >
                    {sub_category_2.sub_category_2}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {isOthersSubCategory2 && (
            <Col span={24}>
              <Form.Item
                name="subCategoryDetails2"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder="Sub-category details"
                  className="p-2"
                  rows={3}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="organisation"
              label="Name of the organisation"
              rules={[{ required: validRequired, message: "Required" }]}
            >
              <Input className="p-2" size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="hospitalType"
              label="Hospital type"
              style={{
                display: "inline-block",
                width: "calc(50% - 8px)",
              }}
              rules={[{ required: validRequired, message: "Required" }]}
            >
              <Select
                placeholder="Select"
                style={{ borderRadius: "0.25rem !important" }}
                size="large"
              >
                <Option value="Government">Government</Option>
                <Option value="Private">Private</Option>
                <Option value="Non-Profit">Non-Profit</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="noOfBeds"
              label="No of Beds"
              style={{
                display: "inline-block",
                width: "50%",
                margin: "0 0 0 8px",
              }}
              rules={[{ required: validRequired, message: "Required" }]}
            >
              <Select size="large" placeholder="Select">
                <Option value="NA">NA</Option>
                <Option value="1 to 10">1 to 10</Option>
                <Option value="11 to 25">11 to 25</Option>
                <Option value="26 to 50">26 to 50</Option>
                <Option value="51 to 75">51 to 75</Option>
                <Option value="76 to 100">76 to 100</Option>
                <Option value="101 to 150">101 to 150</Option>
                <Option value="151 to 200">151 to 200</Option>
                <Option value="200+">200+</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="file" label="File upload">
              <Dragger
                showUploadList={false}
                name="file"
                multiple={false}
                action={(file) => {
                  var reader = new FileReader();
                  reader.readAsBinaryString(file);
                  reader.onload = function (e) {
                    onProcessFile(e);
                  };
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  <span className="text-primary">Upload a file</span> or drag
                  and drop
                </p>
                <p className="ant-upload-hint">
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      downloadCSV(technician, "technician");
                    }}
                  >
                    Click to download sample file
                  </Button>
                </p>
              </Dragger>
            </Form.Item>
          </Col>
        </Row>
        {/* <Divider />
        <Row justify="space-between" gutter={16}>
          <Col span={12}>
            <p>Access to the mobile app</p>
          </Col>
          <Col span={12}>
            <Form.Item name="access">
              <Switch
                className="float-right"
                defaultChecked={initialValues?.access}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />*/}
        {/*<Row>
          <Col span={24}>
            <Form.List name="additional_fields">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <div key={fields.key}>
                        <Row justify="space-between mb-4" gutter={16}>
                          <Col span={12}>Custom field {index + 1}</Col>
                          <Col span={12}>
                            <Button
                              type="link"
                              onClick={() => remove(index)}
                              style={{ color: "red" }}
                              className="float-right"
                            >
                              Remove
                            </Button>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              label="Label"
                              {...restField}
                              name={[name, "label"]}
                              fieldKey={[fieldKey, "label"]}
                              rules={[
                                {
                                  // required: true,
                                  message: "Missing label",
                                },
                              ]}
                              labelCol={{ span: 8 }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label="Information"
                              {...restField}
                              name={[name, "info"]}
                              fieldKey={[fieldKey, "info"]}
                              rules={[
                                {
                                  // required: true,
                                  message: "Missing information",
                                },
                              ]}
                              labelCol={{ span: 8 }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    )
                  )}
                  <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                    <Button
                      type="link"
                      ref={btn}
                      onClick={() => add()}
                      style={{ visibility: "hidden" }}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>*/}
        <Row justify="space-between" gutter={16}>
          {/*<Col span={12}>
            <Button
              type="link"
              onClick={() => {
                btn.current.click();
              }}
            >
              Add new field
            </Button>
          </Col>*/}
          <Col span={12} offset={12}>
            <Button
              type="primary"
              htmlType="submit"
              className="float-right rounded"
            >
              Next
            </Button>
            <Button
              type="link"
              className="float-right mr-5"
              onClick={() => history.goBack()}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

const WorkDetailForm = ({
  data,
  setTab,
  editData,
  role,
  id,
  setValidRequired,
  validRequired,
}) => {
  const history = useHistory();
  const [form] = useForm();
  const btn = useRef(null);
  const [createTechnician] = useMutation(CREATE_TECHNICIAN);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const [editUser] = useMutation(EDIT_USERS);
  const [addApproval] = useMutation(ADD_APPROVAL);
  const [onboardUser] = useMutation(ONBOARD_USER);
  const [selectedOption, setSelectedOption] = useState(
    editData?.user_by_pk?.status || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [dncCount, setDncCount] = useState(0);
  const [updateDncCount] = useMutation(UPDATE_DNC_COUNT);

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    setErrorMessage("");
  };

  useEffect(() => {
    form?.setFieldsValue({
      yearOfPractice: moment().subtract(data.metadata.experience, "years"),
    });
  }, [data]);

  const initialValues = {
    totalIncentive: editData?.user_by_pk?.meta_date?.totalIncentive || "",
    previousStudies: editData?.user_by_pk?.meta_date?.previousStudies || "",
    payment: editData?.user_by_pk?.meta_date?.payment || ["payment"],
    validatedDate: moment(editData?.user_by_pk?.meta_date?.validatedDate) || "",
    status:
      editData?.user_by_pk?.dnc_count === 3
        ? "Prohibited"
        : editData?.user_by_pk?.status || "",
    access: editData?.user_by_pk?.meta_date?.access === false ? false : true,
    additional_fields: editData?.user_by_pk?.additional_data || "",
  };

  const onFinish = (values) => {
    let raw = !!data.email || !!data.phone;
    let partialEmail = !data.email || !!data.phone;
    let partialPhone = !data.phone || !!data.email;
    let notValidated = !data.email || !data.phone;
    let inValid = "";
    let valid = !data.firstName || !data.lastName;
    let DNC = "";
    let Prohibited = "";

    form
      .validateFields()
      .then((values) => {
        const canSubmit = MemberStatus(
          selectedOption,
          values,
          raw,
          partialEmail,
          partialPhone,
          notValidated,
          inValid,
          valid,
          DNC,
          Prohibited
        );
        if (selectedOption === "Valid") {
          setValidRequired(true);
          setTab("1");
        }
        if (canSubmit) {
          let yearOfBirthObj, yearMetadataObj;
          if (data.year_of_birth) {
            yearOfBirthObj = {
              year_of_birth: data.year_of_birth,
            };
            yearMetadataObj = {};
          } else {
            yearOfBirthObj = {
              year_of_birth: null,
            };
            yearMetadataObj = {
              year_of_birth: "Not Furnished",
            };
          }
          let metadataCopy = data?.metadata;
          metadataCopy = { ...metadataCopy, ...values };
          data.meta_date = { ...metadataCopy };
          data.additional_data = values.additional_fields;
          data.status = values.status;
          if (editData) {
            if (role !== "super_admin") {
              let approvalPayload = {
                old_info: {},
                updated_info: {},
              };
              approvalPayload.old_info = {
                user: {
                  f_name: editData?.user_by_pk?.f_name || "",
                  l_name: editData?.user_by_pk?.l_name || "",
                  title: editData?.user_by_pk?.title || "",
                  gender: editData?.user_by_pk?.gender || "",
                  year_of_birth: editData?.user_by_pk?.year_of_birth || "",
                  phone_number: editData?.user_by_pk?.phone_number || "",
                  email: editData?.user_by_pk?.email.toLowerCase() || "",
                  state: editData?.user_by_pk?.state || "",
                  city: editData?.user_by_pk?.city || "",
                  is_verified: editData?.user_by_pk?.is_verified,
                  pincode: editData?.user_by_pk?.pincode || "",
                  meta_date: editData?.user_by_pk?.meta_date,
                  additional_data: editData?.user_by_pk?.additional_data || "",
                  status: editData?.user_by_pk?.status,
                },
                client: {},
              };
              approvalPayload.updated_info = {
                user: {
                  f_name: data.firstName,
                  l_name: data.lastName,
                  title: data.title,
                  phone_number: data.phone,
                  email: data.email,
                  state: data.state,
                  city: data.city,
                  pincode: parseInt(data.pincode),
                  is_verified: editData?.user_by_pk?.is_verified,
                  gender: data.gender,
                  ...yearOfBirthObj,
                  meta_date: {
                    ...data.meta_date,
                    ...yearMetadataObj,
                  },
                  additional_data: data.additional_data,
                  status: data.status,
                },
                client: {},
              };
              if (
                approvalPayload && // 👈 null and undefined check
                Object.keys(approvalPayload).length > 0 &&
                Object.getPrototypeOf(approvalPayload) === Object.prototype
              ) {
                approvalPayload.user_id = editData?.user_by_pk?.id;
                approvalPayload.updated_by = parseInt(id);
              }
              if (role === "interviewer") {
                editUser({
                  variables: {
                    id: editData?.user_by_pk?.id,
                    object: {
                      ass_interviewer_status: true,
                      ass_interviewer_id: null,
                    },
                  },
                });
              }
              addApproval({
                variables: {
                  old_info: approvalPayload.old_info,
                  updated_info: approvalPayload.updated_info,
                  user_id: approvalPayload.user_id,
                  updated_by: approvalPayload.updated_by,
                },
              })
                .then(() => {
                  notification.success({
                    message: "Updates scheduled for approval",
                  });
                  if (role === "interviewer") {
                    history.push("/memberValidation");
                  } else {
                    history.push("/members/technician/1");
                  }
                })
                .catch(() => {
                  notification.error({
                    message: "Error",
                    description: "An error occurred while updating consumer.",
                  });
                });
            } else {
              editUser({
                variables: {
                  id: editData?.user_by_pk?.id,
                  object: {
                    respondent_id: data.respondent_id,
                    f_name: data.firstName,
                    l_name: data.lastName,
                    title: data.title,
                    phone_number: data.phone,
                    email: data.email,
                    state: data.state,
                    city: data.city,
                    pincode: data.pincode,
                    gender: data.gender,
                    ...yearOfBirthObj,
                    meta_date: {
                      ...data.meta_date,
                      ...yearMetadataObj,
                    },
                    additional_data: data.additional_data,
                    status: values.status,
                  },
                },
              })
                .then((res) => {
                  if (values.status === 'DNC') {
                    updateDncCount({
                      variables: {
                        userId: res.data.update_user_by_pk.respondent_id,
                        count: res.data.update_user_by_pk.dnc_count + 1,
                        status: res.data.update_user_by_pk.dnc_count + 1 >= 3 ? 'Prohibited' : values.status,
                      },
                    }).then(() => {
                      if (res.data.update_user_by_pk.dnc_count + 1 >= 3) {
                        setTimeout(() => {
                          window.location.reload();
                        }, 1000);
                      }
                    });
                  }
                  if (
                    res?.data?.update_user_by_pk?.meta_date?.access === true &&
                    initialValues.access === false
                  ) {
                    if (data?.email && role === 'super_admin') {
                      onboardUser({
                        variables: {
                          user_id: res?.data?.update_user_by_pk?.id,
                        },
                      })
                        .then(() => {
                          notification.success({
                            message: "Technician updated",
                            description: "A technician has been updated.",
                          });
                          if (role === "interviewer") {
                            history.push("/memberValidation");
                          } else {
                            history.push("/members/technician/1");
                          }
                        })
                        .catch(() => {
                          notification.error({
                            message: "Error",
                            description:
                              "An error occurred while updating technician.",
                          });
                        });
                    } else {
                      notification.success({
                        message: "Technician updated",
                        description: "A technician has been updated.",
                      });
                      if (role === "interviewer") {
                        history.push("/memberValidation");
                      } else {
                        history.push("/members/technician/1");
                      }
                    }
                  } else {
                    notification.success({
                      message: "Technician updated",
                      description: "A technician has been updated.",
                    });
                    if (role === "interviewer") {
                      history.push("/memberValidation");
                    } else {
                      history.push("/members/technician/1");
                    }
                  }
                })
                .catch(() => {
                  notification.error({
                    message: "Error",
                    description: "An error occurred while updating technician.",
                  });
                });
            }
          } else {
            createTechnician({
              variables: {
                ...data,
                ...yearOfBirthObj,
                metadata: {
                  ...data.meta_date,
                  ...yearOfBirthObj,
                },
                additional_fields: data.additional_data,
                status: data.status,
              },
            })
              .then((res) => {
                if (selectedOption === "DNC") {
                  const updatedDncCount = dncCount + 1;
                  setDncCount(updatedDncCount);
                  updateDncCount({
                    variables: {
                      userId: data.respondent_id,
                      count: updatedDncCount,
                    },
                  });
                }
                if (res?.data?.insert_user_one?.meta_date?.access) {
                  if (data?.email && role === 'super_admin') {
                    onboardUser({
                      variables: {
                        user_id: res?.data?.insert_user_one?.id,
                      },
                    })
                      .then(() => {
                        notification.success({
                          message: "Technician created",
                          description: "A new technician has been created.",
                        });
                        history.push("/members/technician/1");
                      })
                      .catch(() => {
                        notification.error({
                          message: "Error",
                          description:
                            "An error occurred while creating technician.1",
                        });
                      });
                  } else {
                    notification.success({
                      message: "Technician created",
                      description: "A new technician has been created.",
                    });
                    history.push("/members/technician/1");
                  }
                } else {
                  notification.success({
                    message: "Technician created",
                    description: "A new technician has been created.",
                  });
                  history.push("/members/technician/1");
                }
              })
              .catch(() => {
                notification.error({
                  message: "Error",
                  description: "An error occurred while creating technician.",
                });
              });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage("Please fill in the required fields.");
      });
  };

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <WorkDetails
          btn={btn}
          isPracticeYearDisabled={true}
          handleOptionChange={handleOptionChange}
          editData={editData}
          validRequired={validRequired}
          selectedOption={selectedOption}
        ></WorkDetails>
        <Row justify="end" gutter={16}>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              className="float-right rounded"
            >
              Submit
            </Button>
            <Button
              type="link"
              className="float-right mr-5"
              onClick={() => setTab("1")}
            >
              Back
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TechnicianForm;
