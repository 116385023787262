import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ONE_USER } from "./graphql";
import { Spin } from "antd";
import ConsumerForm from "./Consumer/index";
import TechnicianForm from "./Technician/index";
import PatientForm from "./Patients/index";
import PhysicianForm from "./Physician/index";

const EditMembers = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_ONE_USER, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });

  return (
    <Spin spinning={loading}>
      {data?.user_by_pk?.member_type?.description === "Consumer" && (
        <ConsumerForm data={data} />
      )}
      {data?.user_by_pk?.member_type?.description === "Technician" && (
        <TechnicianForm data={data} />
      )}
      {data?.user_by_pk?.member_type?.description === "Patient" && (
        <PatientForm data={data} />
      )}
      {data?.user_by_pk?.member_type?.description === "Physician" && (
        <PhysicianForm data={data} />
      )}
    </Spin>
  );
};

export default EditMembers;
