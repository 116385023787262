import { formatDateTOString } from "../../utils/dateTime";
import parse from "html-react-parser";

function ProjectDetails(props) {
  return (
    <div className="projectDetails w-3/4">
      <div className="pl-6">
        <p>
          <span className="text-sm text-textLight w-40 float-left">Client</span>
          <span className="text-base text-textDark">
            {props.projectData.client.f_name}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Member type
          </span>
          <span className="text-base text-textDark">
            {props.memberName.join(",")}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Project type
          </span>
          <span className="text-base text-textDark">
            {(props.projectData?.projectTypeByProjectType?.name === 'Assisted' || props.projectData?.projectTypeByProjectType?.name === 'Online') && props.projectData?.is_online_or_assisted ? props.projectData?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : props.projectData?.projectTypeByProjectType?.name}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Start date
          </span>
          <span className="text-base text-textDark">
            {formatDateTOString(props.projectData.start_date)}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            End date
          </span>
          <span className="text-base text-textDark">
            {formatDateTOString(props.projectData.end_date)}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Sample size
          </span>
          <span className="text-base text-textDark">
            {props.projectData.sample}
          </span>
        </p>
        <p style={{ width: "100%", minHeight: "10px" }}>
          <span className="text-sm text-textLight w-40 float-left">
            Description
          </span>
          <span className="text-base text-textDark w-4/5 float-left">
            {props.projectData.description
              ? parse(props.projectData.description)
              : ""}
          </span>
        </p>
      </div>
      <p className="w-72 h-14 bg-gray pt-4 pl-6 float-left mt-4">
        <span className="text-lg text-textLight w-40 float-left">
          Total budget
        </span>
        <span className="text-base text-textDark">
          {props.projectData.currency === "inr" ? "₹ " : "$ "}
          {props.projectData.total_budget.toFixed(2)}
        </span>
      </p>
    </div>
  );
}
export default ProjectDetails;
