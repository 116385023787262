import React from "react";
import { formatDateTOString } from "../../utils/dateTime";
import parse from "html-react-parser";
import { Button } from "antd";
import { useMutation } from "@apollo/client";
// import download from "../../assets/Icon/download.svg";
import { GET_DOWNLOAD_URL } from "./graphql";

function ProjectDetails(props) {
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);

  const handleDownload = (name, originalName) => {
    const fileDetails = originalName[0].split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="projectDetails w-3/4">
      <div className="pl-6">
        <p>
          <span className="text-sm text-textLight w-40 float-left">Client</span>
          <span className="text-base text-textDark">
            {props.projectData?.client?.f_name}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Member type
          </span>
          <span className="text-base text-textDark">
            {props.memberName.join(",")}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Project type
          </span>
          <span className="text-base text-textDark">
            {(props.projectData?.projectTypeByProjectType?.name === 'Assisted' || props.projectData?.projectTypeByProjectType?.name === 'Online') && props.projectData?.is_online_or_assisted ? props.projectData?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : props.projectData?.projectTypeByProjectType?.name}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Start date
          </span>
          <span className="text-base text-textDark">
            {formatDateTOString(props.projectData?.start_date)}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            End date
          </span>
          <span className="text-base text-textDark">
            {formatDateTOString(props.projectData?.end_date)}
          </span>
        </p>
        <p>
          <span className="text-sm text-textLight w-40 float-left">
            Sample size
          </span>
          <span className="text-base text-textDark">
            {props.projectData?.sample}
          </span>
        </p>
        {props?.projectData?.client_project_id !== "" &&
          props?.projectData?.client_project_id !== null && (
            <p>
              <span className="text-sm text-textLight w-40 float-left">
                Client project ID
              </span>
              <span className="text-base text-textDark">
                {props.projectData?.client_project_id}
              </span>
            </p>
          )}
        {props?.showDoc === true && (
          <>
            <p>
              <span className="text-sm text-textLight w-40 float-left">
                Documents
              </span>
              <span className="text-base text-textDark ">
                {props.projectData.documents[1] && (
                  <>{props.projectData.documents[0].document}</>
                )}
              </span>
            </p>
            <p className="text-base text-textDark w-4/5 ml-40">
              {props.projectData.documents[1].fileList.map((file, index) => {
                return (
                  <p>
                    <span className="text-base text-textMedium">
                      {file.name}
                    </span>
                    <span className="text-base text-textDark">
                      <Button
                        type="link"
                        className="pr-8"
                        onClick={() => {
                          handleDownload(
                            file.name,
                            props.projectData.documents[2].responseURL[index]
                          );
                        }}
                      >
                        Download
                      </Button>
                    </span>
                  </p>
                );
              })}
            </p>
          </>
        )}

        <p style={{ width: "100%", minHeight: "10px" }}>
          <span className="text-sm text-textLight w-40 float-left">
            Description
          </span>
          <span className="text-base text-textDark w-4/5 float-left">
            {props.projectData?.description
              ? parse(props.projectData.description)
              : ""}
          </span>
        </p>
      </div>
      <p className="w-72 h-14 bg-gray pt-4 pl-6 float-left mt-4">
        <span className="text-lg text-textLight w-40 float-left">
          Total budget
        </span>
        <span className="text-base text-textDark">
          {props.projectData?.total_budget !== null ? (
            <>
              {" "}
              {props.projectData?.currency === "inr" ? "₹ " : "$ "}
              {props.projectData?.total_budget.toFixed(2)}
            </>
          ) : (
            ""
          )}
        </span>
      </p>
    </div>
  );
}
export default ProjectDetails;
