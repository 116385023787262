import { gql } from "@apollo/client";

export const CREATE_SURVEY = gql`
  mutation createSurvey($objects: [survey_insert_input!]!) {
    insert_survey(objects: $objects) {
      affected_rows
      returning {
        id
        description
        name
        consent
        status
      }
    }
  }
`;
export const GET_MEMBERS = gql`
  query GetMembers($survey_id: Int) {
    user(where: { role_id: { _eq: 6 },is_verified: {_eq: true}, 
          status: { _nin: ["raw", "Prohibited"] } },order_by:{response_rate:desc}) {
      title
      status
      f_name
      l_name
      respondent_id
      id
      gender
      response_rate
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      super_specialty {
        id
        name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
      survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
        id
        survey_x_member_x_interviewers {
          id
          interviewer_id
          interviewer_on_leave
        }
      }
      is_verified
    }
  }
`;

export const GET_ASSIGNED_MEMBERS_ONLINE = gql`
  query GET_ASSIGNED_MEMBERS($survey_id: Int) {
    survey_x_member(where: { survey_id: { _eq: $survey_id }, user: {} }) {
      user {
        f_name
        l_name
        status
        respondent_id
        gender
        id
        member_type {
          name
        }
        survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
          id
        }
      }
    }
  }
`;

export const DELETE_SURVEY_X_MEMBERS = gql`
  mutation DELETE_SURVEY_X_MEMBERS(
    $array: [Int!]
    $Interviewerarray: [Int!]
    $object: [survey_x_member_insert_input!]!
  ) {
    delete_survey_x_member_x_interviewer(
      where: { id: { _in: $Interviewerarray } }
    ) {
      affected_rows
    }
    delete_survey_x_member(where: { id: { _in: $array } }) {
      affected_rows
    }

    insert_survey_x_member(objects: $object) {
      affected_rows
      returning {
        id
        state
      }
    }
  }
`;

export const SURVEYxMEMBERS = gql`
  mutation ($objects: [survey_x_member_insert_input!]!) {
    insert_survey_x_member(objects: $objects) {
      affected_rows
    }
  }
`;
export const GET_SURVEY_DETAILS = gql`
  query getSurvey {
    survey {
      name
      description
      id
      consent
      start_date
      end_date
      display_questions_on_survey_cards
      links_status
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UPDATE_SURVEY($id: Int, $object: survey_set_input) {
    update_survey(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        description
        name
        id
        consent
      }
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_DETAILS = gql`
  mutation UPDATE_SURVEY(
    $id: Int
    $startDate: timestamptz
    $endDate: timestamptz
    $show: Boolean
    $status: String
    $links_status: String
  ) {
    update_survey(
      where: { id: { _eq: $id } }
      _set: {
        start_date: $startDate
        end_date: $endDate
        display_questions_on_survey_cards: $show
        status: $status
        links_status: $links_status
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UPDATE_SURVEY($status: String, $id: Int) {
    update_survey(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_STATUS = gql`
  mutation UPDATE_SURVEY($id: Int, $status: String) {
    update_survey(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const GET_MEMBERS_LIST = gql`
  query GetMembers($id: Int) {
    user(
      where: {
        role_id: { _eq: 6 }
        survey_x_members: { survey_id: { _eq: $id } }
      }
      order_by: { id: asc }
    ) {
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      survey_x_members(
        where: { survey_id: { _eq: $id } }
        order_by: { created_at: asc }
      ) {
        survey_url
      }
    }
  }
`;

export const GET_ASSIGNED_MEMBERS = gql`
  query GET_ASSIGNED_MEMBERS($survey_id: Int) {
    survey_x_member(where: { survey_id: { _eq: $survey_id }, user: {} }) {
      state
      member_id
      user {
        title
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
        survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
          id
          state
        }
      }
      survey_x_member_x_interviewers {
        id
        priority
        interviewer_id
        interviewer_on_leave
        status
      }
    }
    survey(where: { id: { _eq: $survey_id } }) {
      display_questions_on_survey_cards
      end_date
      start_date
    }
  }
`;

export const UPDATE_SURVEY_X_MEMEBER = gql`
  mutation UPDATE_SURVEY_X_MEMEBER($id: Int, $survey_url: String) {
    update_survey_x_member(
      where: { id: { _eq: $id } }
      _set: { survey_url: $survey_url }
    ) {
      affected_rows
    }
  }
`;

export const GET_LINK_STATUS = gql`
  query GET_LINK_STATUS($survey_id: Int!) {
    survey_by_pk(id: $survey_id) {
      links_status
    }
  }
`;

export const GET_THIRD_PARTY_URL = gql`
  query GET_THIRD_PARTY_URL($project_id: Int!) {
    project_by_pk(id: $project_id) {
      third_party_survey_name
      third_party_survey_url
    }
  }
`;

export const UPDATE_LINK_STATUS = gql`
  mutation UPDATE_SURVEY($id: Int, $status: String, $links_status: String) {
    update_survey(
      where: { id: { _eq: $id } }
      _set: { status: $status, links_status: $links_status }
    ) {
      affected_rows
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    get_upload_url(content_type: $contentType, file_name: $fileName) {
      message
      url
    }
  }
`;

export const SURVEY_DATES = gql`
  query SURVEY_DATES($id: Int) {
    survey(where: { id: { _eq: $id } }) {
      end_date
      start_date
      status
    }
  }
`;

export const NOTIFY_NEW_MEMBER = gql`
  mutation NOTIFY_NEW_MEMBER($survey_id: Int!, $member_id: [Int]) {
    notify_member_after_date(survey_id: $survey_id, member_ids: $member_id) {
      message
    }
  }
`;

export const PROJECT_DATES = gql`
  query PROJECT_DATES($id: Int) {
    project(where: { id: { _eq: $id } }) {
      end_date
      start_date
      id
      is_online_or_assisted
    }
  }
`;

export const GET_INTERVIEWER = gql`
  query GET_INTERVIEWER {
    user(where: { role_id: { _eq: 4 } }) {
      f_name
      l_name
      id
      meta_date
    }
  }
`;

export const INSERT_INTERVIEWER = gql`
  mutation ($objects: [survey_x_member_x_interviewer_insert_input!]!) {
    insert_survey_x_member_x_interviewer(objects: $objects) {
      affected_rows
    }
  }
`;
