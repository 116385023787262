export const dropDownScreenValidation = (surveyDetails) => {
  let status = false;
  let message = "";
  let screened = false;
  let jumpToquestion;
  let jumpToStatus = false;
  // eslint-disable-next-line
  surveyDetails.current_question.questions_options.some((option, i) => {
    let answer;
    let copy = surveyDetails.current_answer[i];
    for (let [key, value] of Object.entries(copy)) {
      if (key !== "type") {
        answer = value;
      }
    }
    if (option.logic && option.logic.screenExit) {
      if (
        option.logic.dropdownValue !== null &&
        answer === option.logic.dropdownValue
      ) {
        screened = true;
        status = true;
        return true;
      }
    } else if (option.logic !== null) {
      if (
        option.logic.dropdownValue !== null &&
        answer === option.logic.dropdownValue
      ) {
        screened = false;
        status = true;
        jumpToquestion = option.logic.jumpToQuestion;
        jumpToStatus = true;
        return true;
      }
    }
  });
  return {
    status,
    message,
    jumpToquestion,
    screened,
    jumpToStatus,
  };
};
