import React, { useState, useEffect } from "react";
import PRFSurvey from "./prf/index";
import Externalsurvey from "./external/index";
import OnlineKreaSurvey from "./online/kreaSurvey/index";
import OnlineClientSurvey from "./online/clientSurvey/index";
import OnlineAlchemerSurvey from "./online/alchemerSurvey/index";
import AssistedSurvey from "./assisted/kreaSurvey/index";
import AssistedClientSurvey from "./assisted/clientSurvey/index";
import AssistedAlchemerSurvey from "./assisted/alchemerSurvey/index";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const location = useLocation();
  const [surveyType, setSurveyType] = useState();
  useEffect(() => {
    setSurveyType(location.pathname.split("/")[4]);
    console.log(location.pathname.split("/")[4])
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {surveyType === "PRF" && <PRFSurvey />}
      {surveyType === "External" && <Externalsurvey />}
      {surveyType === "Online-Krea" && <OnlineKreaSurvey />}
      {surveyType === "Online-Client" && <OnlineClientSurvey />}
      {surveyType === "Online-Third_Party_survey" && <OnlineAlchemerSurvey />}
      {surveyType === "Assisted-Krea" && <AssistedSurvey />}
      {surveyType === "Assisted-Client" && <AssistedClientSurvey />}
      {surveyType === "Assisted-Third_Party_survey" && (
        <AssistedAlchemerSurvey />
      )}
    </>
  );
};
export default Dashboard;
