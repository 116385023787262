import { Drawer, Form, Button, Select, List } from "antd";
import { useState, useEffect } from "react";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { GET_INTERVIEWER } from "../graphql";
import ViewActiveButton from "../../../../../components/viewActiveButton";

const { Option } = Select;
function AssignInterviewer(props) {
  const { data: interviewerData } = useQuery(GET_INTERVIEWER, {
    fetchPolicy: "network-only",
  });
  const [interviewerList, setInterviewerList] = useState([]);
  const [interviewerLeaveList, setInterviewerLeaveList] = useState([]);

  const [copy, setCopy] = useState([]);

  const handleChange = (e) => {
    let newList = [];
    let leavearray = {};
    interviewerData?.user?.forEach((interviewer) => {
      e.forEach((el) => {
        if (interviewer.id === el) {
          newList.push(interviewer);
          leavearray[[interviewer.id]] = interviewer?.meta_date?.availability;
        }
      });
    });
    setCopy(newList);
    setInterviewerList(e);
    // newArray.push(leavearray);
    setInterviewerLeaveList(leavearray);
  };

  useEffect(() => {
    let newList = [];
    let leavearray = {};
    setInterviewerList(props.interviewerList);
    interviewerData !== undefined &&
      interviewerData?.user?.forEach((interviewer) => {
        props.interviewerList.forEach((el) => {
          if (interviewer.id === el) {
            newList.push(interviewer);
            leavearray[[interviewer.id]] = interviewer?.meta_date?.availability;
          }
        });
      });
    setCopy(newList);
    // newArray.push(leavearray);
    setInterviewerLeaveList(leavearray);
    // eslint-disable-next-line
  }, [props, interviewerData]);

  const removeItem = (value) => {
    const newList = interviewerList.filter((item) => item !== value);
    const copyList = copy.filter((item) => item.id !== value);
    setCopy(copyList);
    setInterviewerList(newList);
  };

  const [form] = Form.useForm();

  const onSave = () => {
    props.setVisible(false);
    props.handleValidationValues(
      interviewerList,
      props.memberIndex,
      interviewerLeaveList
    );
  };

  const onCancel = () => {
    props.setVisible(false);
    form.resetFields();
    // props.handleValidationValues(
    //   props.interviewerList,
    //   props.memberIndex,
    //   interviewerLeaveList
    // );
  };
  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Interviewers</span>}
        width={720}
        onClose={onCancel}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <>
            <div className=" flex justify-end items-end">
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              {!!(copy || []).length &&
                <ViewActiveButton ghost={true} memberId={copy.map(member => member.id)} />}

              <Button
                onClick={onSave}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Save
              </Button>
            </div>
          </>
        }
      >
        {!!props?.selectedMember?.length &&
          <div className=" mb-5">

            <span className="text-base text-textLight mb-1">
              Assign interviewers for
            </span>
            {props?.selectedMember?.map((member, memberIndex) => {
              return <div key={`Member-${memberIndex}`} className="text-textMedium mb-1 font-medium">{`${memberIndex + 1}. ${member.memberName}`}</div>
            })}
          </div>}
        <Form form={form} layout="vertical">
          <Form.Item
            label={
              <span className="text-base text-textLight">
                Assign interviewers
              </span>
            }
            name="Add Manager"
          >
            <Select
              placeholder="Select"
              mode="multiple"
              showArrow
              style={{ width: "49%" }}
              onChange={(e) => handleChange(e)}
              value={interviewerList}
              showSearch
              optionFilterProp="children"
            >
              {interviewerData?.user?.map((interviewer) => {
                return (
                  <Option key={interviewer.id} value={interviewer.id}>
                    {interviewer?.f_name} {interviewer?.l_name}
                  </Option>
                );
              })}
            </Select>
            <List
              size="large"
              bordered
              dataSource={copy}
              pagination={false}
              renderItem={(item) => (
                <List.Item
                  extra={
                    <MinusCircleOutlined
                      style={{ color: "red" }}
                      onClick={() => removeItem(item.id)}
                    />
                  }
                >
                  {item.f_name + " " + item.l_name}
                </List.Item>
              )}
              style={{ minHeight: "450px", width: "49%", border: 0 }}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default AssignInterviewer;
