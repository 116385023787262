import React from "react";

function FinancialDetails(props) {
  return (
    <div className="projectDetails w-3/4">
      <div className="pl-6">
        <p className="w-full float-left">
          <span className="text-sm text-textLight w-40 float-left">
            Cost per interview
          </span>
          <span className="text-base text-textDark float-right">
            {props.projectData.cost_per_interview !== null ? (
              <>
                {" "}
                {props.projectData.currency === "inr" ? "₹ " : "$ "}
                {props.projectData.cost_per_interview.toFixed(2)}
              </>
            ) : (
              ""
            )}
          </span>
        </p>
        <p className="w-full float-left">
          <span className="text-sm text-textLight w-40 float-left">
            Incentive
          </span>
          <span className="text-base text-textDark  float-right">
            {props.projectData.incentive !== null ? (
              <>
                {" "}
                {props.projectData.currency === "inr" ? "₹ " : "$ "}
                {props.projectData.incentive.toFixed(2)}
              </>
            ) : (
              ""
            )}
          </span>
        </p>
        <p className="w-full float-left">
          <span className="text-sm text-textLight w-40 float-left">
            Estimated iR
          </span>
          <span className="text-base text-textDark float-right">
            {props.projectData.estimated_ir !== null ? (
              <>{props.projectData.estimated_ir} %</>
            ) : (
              ""
            )}
          </span>
        </p>
        <p className="w-full float-left">
          <span className="text-sm text-textLight w-40 float-left">
            Project budget
          </span>
          <span className="text-base text-textDark float-right">
            {props.projectData.budget !== null ? (
              <>
                {" "}
                {props.projectData.currency === "inr" ? "₹ " : "$ "}
                {props.projectData.budget.toFixed(2)}
              </>
            ) : (
              ""
            )}
          </span>
        </p>
      </div>
      <p className="w-full h-14 bg-gray pt-4 pl-6 float-left mt-4">
        <span className="text-lg text-textLight w-40 float-left">
          Total budget
        </span>
        <span className="text-base text-textDark float-right">
          {props.projectData.total_budget !== null ? (
            <>
              {" "}
              {props.projectData.currency === "inr" ? "₹ " : "$ "}
              {props.projectData.total_budget.toFixed(2)}
            </>
          ) : (
            ""
          )}
        </span>
      </p>
    </div>
  );
}
export default FinancialDetails;
