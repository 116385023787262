
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  DatePicker,
  Divider,
  Switch,
} from "antd";
import moment from "moment";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = Input;

const WorkDetails = ({ btn, isPracticeYearDisabled, handleOptionChange, editData, validRequired, selectedOption }) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="yearOfPractice"
            label="Which Year did you start practicing (post Residency)"
            rules={[
              {
                required: validRequired,
                message: "Required",
              },
            ]}
          >
            <DatePicker
              disabled={isPracticeYearDisabled}
              picker="year"
              size="large"
              className="w-full"
              disabledDate={(current) => {
                return current && current > moment().endOf("day")
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="totalIncentive" label="Total incentive processed">
            <Input className="p-2" size="large" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name="previousStudies"
            label="Previously participated studies"
          >
            <TextArea autoSize={{ minRows: 5, maxRows: 5 }} />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Form.List name="payment" initialValue={["payment"]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }, index) => (
                    <div key={fields.key}>
                      <Row justify="space-between mb-4" gutter={16}>
                        <Col span={12}>Mode of payment</Col>
                        <Col span={12}>
                          <Button
                            type="link"
                            onClick={() => add()}
                            className="float-right"
                          >
                            <PlusCircleOutlined />
                          </Button>
                          <Button
                            type="link"
                            onClick={() => remove(index)}
                            style={{ color: "red" }}
                            className="float-right"
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "label"]}
                            fieldKey={[fieldKey, "label"]}
                            labelCol={{ span: 8 }}
                          >
                            <Select
                              placeholder="Select"
                              style={{ borderRadius: "0.25rem !important" }}
                              size="large"
                              showSearch
                              optionFilterProp="children"
                            >
                              <Option value="Cheque">Cheque</Option>
                              <Option value="Cash">Cash</Option>
                              <Option value="Wire">Wire Transfer</Option>
                              <Option value="Voucher">Voucher</Option>
                              <Option value="UPI">UPI</Option>
                              <Option value="charity">Charity</Option>
                              <Option value="Others">Others</Option>
                              <Option value="Not Furnished">Not Furnished</Option>

                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "info"]}
                            fieldKey={[fieldKey, "info"]}
                            labelCol={{ span: 8 }}
                          >
                            <TextArea
                              autoSize={{ minRows: 5, maxRows: 5 }}
                              style={{ width: "90%", marginLeft: "10%" }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )
                )}
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}></Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="validatedDate" label="Validated date">
            <DatePicker
              size="large"
              className="w-full"
              disabledDate={(current) => {
                return current && current > moment().endOf("day")
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: 'Please select a status' }]}
          >

            <Select
              placeholder="Select"
              style={{ borderRadius: "0.25rem !important" }}
              size="large"
              disabled={editData?.user_by_pk?.dnc_count === 3}
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <Option value="raw">Raw</Option>
              <Option value="partial-email">Partial Email</Option>
              <Option value="partial-phone">Partial Phone</Option>
              <Option value="not-validated">Not Validated</Option>
              <Option value="invalid">Invalid</Option>
              <Option value="Valid">Valid</Option>
              {editData?.user_by_pk?.dnc_count === 3 ?
                <Option value="Prohibited" disabled>Prohibited</Option>
                :
                <>
                  <Option value="DNC">DNC</Option>
                  <Option value="Prohibited" disabled>Prohibited</Option>
                </>
              }
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" gutter={16}>
        <Col span={12}>
          <p>Access to the mobile app</p>
        </Col>
        <Col span={12}>
          <Form.Item name="access" valuePropName="checked">
            <Switch className="float-right" />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Form.List name="additional_fields">
            {(fields, { add, remove }) => (
              <>
                {fields.map(
                  ({ key, name, fieldKey, ...restField }, index) => (
                    <div key={fields.key}>
                      <Row justify="space-between mb-4" gutter={16}>
                        <Col span={12}>Custom field {index + 1}</Col>
                        <Col span={12}>
                          <Button
                            type="link"
                            onClick={() => remove(index)}
                            style={{ color: "red" }}
                            className="float-right"
                          >
                            Remove
                          </Button>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Label"
                            {...restField}
                            name={[name, "label"]}
                            fieldKey={[fieldKey, "label"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing label",
                              },
                            ]}
                            labelCol={{ span: 8 }}
                          >
                            <Input className="p-2" size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Information"
                            {...restField}
                            name={[name, "info"]}
                            fieldKey={[fieldKey, "info"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing information",
                              },
                            ]}
                            labelCol={{ span: 8 }}
                          >
                            <Input className="p-2" size="large" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  )
                )}
                <Form.Item wrapperCol={{ span: 4, offset: 20 }}>
                  <Button
                    type="link"
                    ref={btn}
                    onClick={() => add()}
                    style={{ visibility: "hidden" }}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      <Row justify="space-between" gutter={16}>
        <Col span={12}>
          <Button
            type="link"
            onClick={() => {
              btn?.current.click();
            }}
          >
            Add new field
          </Button>
        </Col>

      </Row>
    </>
  )

};

export default WorkDetails;