import React from "react";
import { Button } from "antd";
import SurveyQuestion from "../../components/survey/questions";
import { useMutation } from "@apollo/client";
import { START_SURVEY } from "./graphql";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { surveyState } from "../../atoms";
import ProfilerQuestions from "../../components/survey/profilerQuestions";
import ReactHtmlParser from "html-react-parser";

const StartSurvey = (props) => {
  const params = useParams();
  const history = useHistory();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);

  const [changeSurveyStatusToInProgress] = useMutation(START_SURVEY);
  React.useEffect(() => {
    if (surveyDetails.surveyStart) {
      history.replace(
        `/survey/${params.surveyID}/member/${params.memberID}/question/${surveyDetails.current_question.id}`
      );
    } else {
      history.replace(`/survey/${params.surveyID}/member/${params.memberID}`);
    }
    // eslint-disable-next-line
  }, []);
  const startSurvey = () => {
    changeSurveyStatusToInProgress({
      variables: {
        survey_id: Number(params.surveyID),
        member_id: Number(params.memberID),
      },
    }).then((res) => {
      setSurveyState((oldSurveyState) => ({
        ...oldSurveyState,
        surveyStart: true,
      }));
      history.push(
        `/survey/${params.surveyID}/member/${params.memberID}/question/${surveyDetails.current_question.id}`
      );
    });
  };
  return (
    <React.Fragment>
      {surveyDetails.survey_completed &&
      surveyDetails.include_profiler_questions ? (
        <ProfilerQuestions />
      ) : (
        <React.Fragment>
          <div
            className="pt-4 lg:pt-10 flex flex-col items-center justify-start bg-white shadow relative "
            style={{ height: "calc(100vh - 208px)" }}
          >
            <div className="fixed lg:absolute bottom-0 left-0 right-0 h-20 flex items-center justify-between lg:justify-end border-t-2 border-bgLite bg-white  px-4 lg:px-12">
              <Button
                className="rounded px-8 w-full lg:w-auto"
                size="large"
                onClick={() => startSurvey()}
                type="primary"
              >
                Start Survey
              </Button>
            </div>
            {!surveyDetails.surveyStart && (
              <div className="w-full px-4 lg:w-2/3 lg:px-0">
                <p className="text-h6 font-semibold text-textMedium">
                  Description
                </p>
                <p
                  className="text-h8 text-textLight"
                  dangerouslySetInnerHTML={{
                    __html: props?.data?.description || <p />,
                  }}
                />
                {ReactHtmlParser(props?.data?.consent)[0].props.children !==
                  null && (
                  <>
                    <p className="mt-4 text-h6 font-semibold text-textMedium">
                      Consent
                    </p>
                    <p
                      className="text-h8 text-textLight"
                      dangerouslySetInnerHTML={{
                        __html: props?.data?.consent || <p />,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {surveyDetails.surveyStart && (
              <div
                className="px-4 lg:px-8 w-full overflow-y-auto"
                style={{ maxHeight: "55vh" }}
              >
                <SurveyQuestion
                  question={
                    surveyDetails.questions[surveyDetails.question_index]
                  }
                />
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StartSurvey;
