import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Radio,
  notification,
  Upload,
  message,
} from "antd";
import { useHistory, useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import {
  GET_MEMBERS_LIST,
  GET_ASSIGNED_MEMBERS_LINK,
  UPDATE_SURVEY_X_MEMEBER,
  GET_ASSIGNED_MEMBERS,
  UPDATE_SURVEY_DETAILS,
  GET_SURVEY_DETAILS,
  GET_UPLOAD_URL,
  GET_DOWNLOAD_URL,
} from "../graphql";
import moment from "moment";

function SurveyLink(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const [singleURL, setSingleURL] = useState(null);
  const [multipleURL, setMultipleURL] = useState(null);
  const [value, setValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkcsv, setCheckcsv] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_DETAILS);
  const [updateMembers] = useMutation(UPDATE_SURVEY_X_MEMEBER);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const { data: assignedMembers, refetch: memberList } = useQuery(
    GET_ASSIGNED_MEMBERS_LINK,
    {
      variables: {
        survey_id: parseInt(surveyID),
      },
      fetchPolicy: "no-cache",
    }
  );
  const { data: assignedMemberscsv, refetch: memberListcsv } = useQuery(
    GET_ASSIGNED_MEMBERS,
    {
      variables: {
        survey_id: parseInt(surveyID),
      },
      fetchPolicy: "no-cache",
    }
  );
  const { data: survey, refetch: surveydetails } = useQuery(
    GET_SURVEY_DETAILS,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data) {
          surveyID &&
            data.survey.forEach((survey) => {
              if (parseInt(surveyID) === survey.id) {
                setValue(survey.links_status);
              }
            });
        }
      },
    }
  );
  useEffect(() => {
    if (survey) {
      surveyID &&
        survey.survey.forEach((survey) => {
          if (parseInt(surveyID) === survey.id) {
            setValue(survey.links_status);
          }
        });
    }
    // eslint-disable-next-line
  }, [survey]);

  useEffect(() => {
    memberList();
    surveydetails();
    memberListcsv();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    let url = [];
    if (value === "single") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el, i) => {
            url[i] = el.user.survey_x_members[0].survey_url;
          });
      setSingleURL(url[0]);
    } else if (value === "multiple") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el, i) => {
            url[i] = el.user.survey_x_members[0].survey_url;
          });
      let filtered = url.filter(function (el) {
        return el != null;
      });
      let length = filtered.filter((d) => d !== null).length;
      length === 0 ? setMultipleURL("") : setMultipleURL(filtered.join(","));
    }
  }, [assignedMembers, survey, value]);
  useEffect(() => {
    let url = [];
    if (value === "single") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el, i) => {
            url[i] = el.user.survey_x_members[0].survey_url;
          });
      setSingleURL(url[0]);
    } else if (value === "multiple") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el, i) => {
            url[i] = el.user.survey_x_members[0].survey_url;
          });
      let filtered = url.filter(function (el) {
        return el != null;
      });
      let length = filtered.filter((d) => d !== null).length;
      length === 0 ? setMultipleURL("") : setMultipleURL(filtered.join(","));
    }
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line
  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });
  const [form] = Form.useForm();

  const handleFormValues = (buttonAction) => {
    let payload = [];

    if (value === null) {
      setErrorMessage("Please select one option");
    } else if (value === "single" && (singleURL === "" || singleURL === null)) {
      setErrorMessage("Please enter single URL");
    } else if (
      value === "multiple" &&
      (multipleURL === "" || multipleURL === null)
    ) {
      setErrorMessage("Please enter multiple URL");
    } else {
      if (value === "single") {
        assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          assignedMembers?.survey_x_member
            // eslint-disable-next-line
            ?.map((el) => {
              payload.push(singleURL);
            });
        assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          assignedMembers?.survey_x_member
            // eslint-disable-next-line
            ?.map((el, i) => {
              let id = el.user.survey_x_members[0].id;
              updateMembers({
                variables: {
                  id: id,
                  survey_url: payload[i],
                },
              }).then(() => {
                updateSurvey({
                  variables: {
                    id: parseInt(surveyID),
                    status:
                      buttonAction === "draft"
                        ? "draft"
                        : moment().isSameOrAfter(props.startDate)
                        ? props.status === "draft"
                          ? "draft"
                          : "active"
                        : props.status === "active"
                        ? "active"
                        : "draft",
                    links_status: value,
                  },
                }).then(() => {
                  if (buttonAction === "active") {
                    let editOrCreate = location.pathname.split("/")[3];
                    history.push(
                      `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                    );
                  } else {
                    notification.success({
                      message: "Success!",
                      description: "Saved as draft!",
                    });
                    history.push("/projectList/active");
                  }
                });
              });
            });
      } else {
        const links = multipleURL.replace(/ /g, "");
        let result = links?.split(",");
        let length = result.filter((d) => d !== "").length;
        if (!checkcsv) {
          assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          assignedMembers?.survey_x_member?.length === length
            ? assignedMembers?.survey_x_member
                // eslint-disable-next-line
                ?.map((el, i) => {
                  let id = el.user.survey_x_members[0].id;
                  updateMembers({
                    variables: {
                      id: id,
                      survey_url: result[i],
                    },
                  }).then(() => {
                    updateSurvey({
                      variables: {
                        id: parseInt(surveyID),
                        status:
                          buttonAction === "draft"
                            ? "draft"
                            : moment().isSameOrAfter(props.startDate)
                            ? props.status === "draft"
                              ? "draft"
                              : "active"
                            : props.status === "active"
                            ? "active"
                            : "draft",
                        links_status: value,
                      },
                    }).then(() => {
                      if (buttonAction === "active") {
                        let editOrCreate = location.pathname.split("/")[3];
                        history.push(
                          `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                        );
                      } else {
                        notification.success({
                          message: "Success!",
                          description: "Saved as draft!",
                        });
                        history.push("/projectList/active");
                      }
                    });
                  });
                })
            : setErrorMessage(
                `You have selected ${assignedMembers?.survey_x_member?.length} members. Please match the number of URLs added`
              );
        } else {
          assignedMemberscsv?.survey_x_member &&
          assignedMemberscsv?.survey_x_member?.length > 0 &&
          assignedMemberscsv?.survey_x_member?.length === length
            ? assignedMemberscsv?.survey_x_member
                // eslint-disable-next-line
                ?.map((el, i) => {
                  let id = el.user.survey_x_members[0].id;
                  updateMembers({
                    variables: {
                      id: id,
                      survey_url: result[i],
                    },
                  }).then(() => {
                    updateSurvey({
                      variables: {
                        id: parseInt(surveyID),
                        status:
                          buttonAction === "draft"
                            ? "draft"
                            : moment().isSameOrAfter(props.startDate)
                            ? props.status === "draft"
                              ? "draft"
                              : "active"
                            : props.status === "active"
                            ? "active"
                            : "draft",
                        links_status: value,
                      },
                    }).then(() => {
                      if (buttonAction === "active") {
                        let editOrCreate = location.pathname.split("/")[3];
                        history.push(
                          `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                        );
                      } else {
                        notification.success({
                          message: "Success!",
                          description: "Saved as draft!",
                        });
                        history.push("/projectList/active");
                      }
                    });
                  });
                })
            : setErrorMessage(
                `You have selected ${assignedMembers?.survey_x_member?.length} members. Please match the number of URLs added`
              );
        }
      }
    }
  };

  const handleDraft = () => {
    handleFormValues("draft");
  };

  const handleClick = () => {
    handleFormValues("active");
  };
  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleMultipleURL = (e) => {
    const text = e.target.value;
    setMultipleURL(text);
  };

  const handleonChange = (e) => {
    setErrorMessage(null);
    // setSingleURL(null);
    setValue(e.target.value);
  };
  const handleSingleURL = (e) => {
    const text = e.target.value;
    if (text.includes(" ") || text.includes(",")) {
      setSingleURL(text);
      setErrorMessage("Enter only one URL");
      e.preventDefault();
      e.stopPropagation();
    } else {
      setErrorMessage(null);
      setSingleURL(text);
    }
  };

  return (
    <>
      <Form
        className="w-full"
        layout="vertical"
        // onFinish={onComplete}
        form={form}
      >
        <Form.Item>
          <p className="text-h5 text-textMedium">Survey URLs</p>
        </Form.Item>
        <Form.Item>
          <Radio.Group
            name="surveyURL"
            onChange={handleonChange}
            className="w-full"
            value={value}
          >
            <Radio value="multiple">Upload multiple links</Radio>
            <p className="text-h11 text-textLight ml-6">
              Enter individual survey links for each member
            </p>
            {value === "multiple" && (
              <div>
                <p className="text-h8 ml-6 mb-0">Survey Links</p>
                <p className="text-h11 text-textLight ml-6">
                  Please make sure to add any new links at the end of the
                  current list only
                </p>
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  style={{ width: "97%" }}
                  className="ml-6 mb-6"
                  placeholder="Enter multiple emails seperated by commas"
                  onChange={handleMultipleURL}
                  value={multipleURL}
                />

                <Upload
                  name="file"
                  method="PUT"
                  className="float-left"
                  action={(file) => {
                    let result = [];
                    var reader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = function (e) {
                      result = e.target.result;
                      result = result.split("\r\n");
                      result.shift();
                      // tempData.push(result);
                    };
                    return new Promise((resolve) => {
                      // setMultipleURL(result.join(","));

                      uploadURL({
                        variables: {
                          fileName: file.name,
                          contentType: file.type,
                        },
                      }).then((res) => {
                        if (res.data.get_upload_url.message === "Success") {
                          setCheckcsv(true);
                          setMultipleURL(result.join(","));
                          resolve(res.data.get_upload_url.url);
                        }
                      });
                    });
                  }}
                  onChange={(info) => {
                    const { status } = info.file;
                    if (status === "done") {
                      message.success(
                        `${info.file.name} file uploaded successfully.`
                      );
                    } else if (status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                >
                  <Button
                    htmlType="button"
                    className="text-base mx-5 rounded-md text-orange"
                  >
                    Upload (CSV)
                  </Button>
                </Upload>
                <Button
                  type="link"
                  className="text-base"
                  onClick={() => {
                    handleDownload("267614Client_survey_linksSheet.csv");
                  }}
                >
                  Download sample CSV file
                </Button>
              </div>
            )}
            <Divider className="mt-12" />
            <Radio value="single">Upload single URL</Radio>
            <p className="text-h11 text-textLight ml-6">
              Enter single URL to generate multiple Krea links
            </p>
            {value === "single" && (
              <>
                <Form.Item label={<p className="text-h8 ml-6">Survey Link</p>}>
                  <Input
                    value={singleURL}
                    style={{ width: "97%" }}
                    className="ml-6"
                    // onKeyPress={this.handleKeyPress}
                    onChange={handleSingleURL}
                  />
                </Form.Item>
              </>
            )}
          </Radio.Group>
        </Form.Item>
        <div style={{ color: "red" }}>
          <p className="ml-6">{errorMessage}</p>
        </div>
        <Divider />
        <Form.Item className="float-right mt-4">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => {
              location.pathname.split("/")[3] === "create-survey"
                ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/assignmembers/survey/${surveyID}`
                  )
                : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/assignmembers/survey/${surveyID}`
                  );
            }}
          >
            Back
          </Button>
          <Button
            htmlType="button"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
              props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-40"
            onClick={handleClick}
          >
            Generate links
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
export default SurveyLink;
