import React, { useEffect } from "react";
import { Steps } from "antd";
import QuestionCreation from "./questionnaire";
import Preview from "./preview";
import moment from "moment";
import ViewAddLogic from "./viewAddLogic";
import { useHistory, useParams } from "react-router";
import { projectForm } from "../../../../../atoms";
import { useRecoilValue } from "recoil";
import { useLocation } from "react-router-dom";

const { Step } = Steps;

function Questionnaire(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const surveyDetails = useRecoilValue(projectForm);
  const currentTime = moment().format();
  const startDate =
    surveyDetails.surveyDetails && surveyDetails.surveyDetails.start_date;
  // const [togglePage, setTogglePage] = useState();
  // const handleToggle = (value) => {
  //   setTogglePage(value);
  // };

  useEffect(() => {
    if (
      startDate !== null &&
      !moment(currentTime).isSameOrBefore(startDate) &&
      props.status === "active"
    ) {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
          );
    }
    // eslint-disable-next-line
  }, []);

  const currentIndex = [undefined, "addLogic", "preview"];
  return (
    <div>
      <Steps
        className="mt-2.5"
        progressDot
        current={currentIndex.indexOf(location.pathname.split("/")[8])}
      >
        <Step title="Add questions" />
        <Step title="Add logic" />
        <Step title="Preview" />
      </Steps>
      {((props.status === "draft" &&
        location.pathname.split("/")[8] === undefined) ||
        (location.pathname.split("/")[8] !== "preview" &&
          location.pathname.split("/")[8] !== "addLogic")) && (
        <QuestionCreation
          handleEnable={props.handleEnable}
          startDate={props.startDate}
          status={props.status}
        />
      )}
      {location.pathname.split("/")[8] === "addLogic" && (
        <ViewAddLogic startDate={props.startDate} status={props.status} />
      )}
      {location.pathname.split("/")[8] === "preview" && (
        <Preview
          enableButton={true}
          viewcollapse={true}
          isStarted={moment(startDate).isSameOrAfter(currentTime)}
          startDate={props.startDate}
          status={props.status}
        />
      )}
    </div>
  );
}

export default Questionnaire;
