import React, { useState, useEffect } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../src/index.css";

function RichTextbox(props) {
  let contentState = ContentState.createFromBlockArray(convertFromHTML(""));
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  useEffect(() => {
    // eslint-disable-next-line
    contentState = ContentState.createFromBlockArray(
      convertFromHTML(`${props.content}`)
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, [props?.content]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.charCount(
      editorState.getCurrentContent().getPlainText().length,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };
  useEffect(() => {
    props.charCount(
      editorState.getCurrentContent().getPlainText().length,
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    // eslint-disable-next-line
  }, [props]);
  const toolbarConfig = {
    options: ["inline", "list", "link", "history"],
    inline: {
      inDropdown: false,
      className: "richText",
      component: undefined,
      dropdownClassName: undefined,
      options: ["bold", "italic"],
    },
    list: {
      inDropdown: false,
      className: "richText",
      component: undefined,
      dropdownClassName: undefined,
      options: ["unordered", "ordered"],
    },
    link: {
      defaultTargetOption: "_blank",
      options: ["link", "unlink"],
      className: "richText",
    },
    history: {
      inDropdown: false,
      className: "richText",
      component: undefined,
      dropdownClassName: undefined,
      options: ["undo", "redo"],
    },
  };
  return (
    <>
      <Editor
        placeholder={props.placeholder}
        defaultEditorState={editorState}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarConfig}
        editorStyle={{
          minHeight: "100px",
          border: "1px solid #DCDCDC",
          borderTop: "0px",
          paddingLeft: "10px",
        }}
        toolbarStyle={{
          margin: "0px",
          border: "1px solid #DCDCDC",
        }}
        handleBeforeInput={(val) => {
          const textLength = editorState
            .getCurrentContent()
            .getPlainText().length;
          if (val && textLength >= 500) {
            return "handled";
          }
          return "not-handled";
        }}
        handlePastedText={(val) => {
          const textLength = editorState
            .getCurrentContent()
            .getPlainText().length;
          return val.length + textLength >= 500;
        }}
      />
    </>
  );
}
export default RichTextbox;
