import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Tabs,
  Tag,
  Table,
  Spin,
  Button,
  Form,
  Divider,
  Drawer,
  Tooltip,
} from "antd";
import moment from "moment";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  GET_ASSIGNED_PROJECTS,
  REWARDS_TAB,
  GET_DOWNLOAD_URL,
} from "../graphql";
import jwtDecode from "jwt-decode";
// import { GET_COMPATIBILITY, GET_SPECIALITIES } from "./graphql";
import MemberTable from "../../../components/memberTable";
import { getColumnSearchProps } from "../../../utils/table";
import { getColumnDateSearchProps } from "../../../utils/dateSearch";
import {
  DownloadOutlined,
  FilterOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import whatsappIcon from "../../../assets/Icon/whatsapp.svg";
import ColumnEditor from "../../../components/ColumnEditor";

const { TabPane } = Tabs;
const ViewPhysician = ({ data, activity }) => {
  const [role, setRole] = useState(null);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Row>
      <Col span={18} className="pl-36">
        <Card bodyStyle={{ minHeight: "80vh" }}>
          <Tabs defaultActiveKey="1" size="large">
            <TabPane tab="Contact details" key="1">
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Primary email</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.email && (
                    <p>
                      {role === "manager"
                        ? `${data?.user_by_pk?.email.substring(0, 3)}XXX@${data?.user_by_pk?.email.split("@")[1]
                        }`
                        : data?.user_by_pk?.email}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Secondary email</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.email2 ? (
                    <p>
                      {role === "manager"
                        ? `${data?.user_by_pk?.meta_date?.email2.substring(
                          0,
                          3
                        )}XXX@${data?.user_by_pk?.meta_date?.email2.split("@")[1]
                        }`
                        : data?.user_by_pk?.meta_date?.email2}
                    </p>
                  ) : (
                    <span>Not updated</span>
                  )}
                </Col>
              </Row>

              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Mobile number 1</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.phone_number ? (
                    <div className="flex gap-2">
                      {data?.user_by_pk?.meta_date?.mobile1Whatsapp === true ? (
                        <img src={whatsappIcon} alt="arrowIcon" />
                      ) : (
                        ""
                      )}
                      {role === "manager"
                        ? `${data?.user_by_pk?.phone_number.substring(
                          0,
                          3
                        )}XXXXXXX`
                        : data?.user_by_pk?.phone_number}
                    </div>
                  ) : (
                    <span>Not updated</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Mobile number 2</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.mobile2 ? (
                    <div className="flex gap-2">
                      {data?.user_by_pk?.meta_date?.mobile2Whatsapp === true ? (
                        <img src={whatsappIcon} alt="arrowIcon" />
                      ) : (
                        ""
                      )}
                      {role === "manager"
                        ? `${data?.user_by_pk?.meta_date?.mobile2.substring(
                          0,
                          3
                        )}XXXXXXX`
                        : data?.user_by_pk?.meta_date?.mobile2}
                    </div>
                  ) : (
                    <span>Not updated</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Phone number</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.phone ? (
                    <p>
                      {role === "manager"
                        ? `${data?.user_by_pk?.meta_date?.phone.substring(
                          0,
                          3
                        )}XXXXXXX`
                        : data?.user_by_pk?.meta_date?.phone}
                    </p>
                  ) : (
                    <span>Not updated</span>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>State</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.state}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>City</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.city}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Pincode</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.pincode ? (
                    <p>{data?.user_by_pk?.pincode}</p>
                  ) : (
                    <p> Not updated </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>City category</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.cityCategory}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Zone</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.zone}</p>
                </Col>
              </Row>
            </TabPane>
            <TabPane tab="Member details" key="2">
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Respondent ID</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.respondent_id ? (
                    <p>{data?.user_by_pk?.respondent_id}</p>
                  ) : (
                    <p> Not Updated </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Title</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.title ? (
                    <p>{data?.user_by_pk?.title}</p>
                  ) : (
                    <p> Not updated </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>First name</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.f_name}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Last name</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.l_name}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Gender</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.gender === "M" ? (
                    <p>Male</p>
                  ) : data?.user_by_pk?.gender === "F" ? (
                    <p>Female</p>
                  ) : null}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Year of birth</p>
                </Col>
                <Col span={6}>
                  <p>
                    {data?.user_by_pk?.year_of_birth
                      ? moment(data?.user_by_pk?.year_of_birth).format("yyyy")
                      : "Not Furnished"}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Primary speciality</p>
                </Col>
                <Col span={6}>
                  <p>
                    {data?.user_by_pk?.primary_specialties?.speciality_name}
                  </p>
                </Col>
              </Row>
              {data?.user_by_pk?.primary_specialties?.speciality_name === 'Others' && (
                <Row className="mb-2">
                  <Col span={6}>
                    <p style={{ color: "#828282" }}>Primary speciality details</p>
                  </Col>
                  <Col span={6}>
                    <p>
                      {data?.user_by_pk?.meta_date?.primaryDetails}
                    </p>
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Super speciality</p>
                </Col>
                <Col span={6}>
                  <p>
                    {data?.user_by_pk?.super_specialty?.name}
                  </p>
                </Col>
              </Row>
              {data?.user_by_pk?.super_specialty?.name === 'Others' && (
                <Row className="mb-2">
                  <Col span={6}>
                    <p style={{ color: "#828282" }}>Super speciality details</p>
                  </Col>
                  <Col span={6}>
                    <p>
                      {data?.user_by_pk?.meta_date?.superSpecialityDetails}
                    </p>
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Secondary speciality</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.secondary_specialties?.speciality_name ? (
                    <p>
                      {data?.user_by_pk?.secondary_specialties?.speciality_name}
                    </p>
                  ) : (
                    <p> Not updated </p>
                  )}
                </Col>
              </Row>
              {data?.user_by_pk?.secondary_specialties?.speciality_name === 'Others' && (
                <Row className="mb-2">
                  <Col span={6}>
                    <p style={{ color: "#828282" }}>Secondary speciality details</p>
                  </Col>
                  <Col span={6}>
                    <p>
                      {data?.user_by_pk?.meta_date?.secondaryDetails}
                    </p>
                  </Col>
                </Row>
              )}
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Details</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.details ? (
                    <p>{data?.user_by_pk?.meta_date?.details}</p>
                  ) : (
                    <p> Not updated </p>
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Hospital type</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.hospitalType}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Hospital name</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.hospital}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Consulting Hospital 1</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.hospital1 ? (
                    <p>{data?.user_by_pk?.meta_date?.hospital1}</p>
                  ) : (
                    "Not updated"
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Consulting Hospital 2</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.hospital2 ? (
                    <p>{data?.user_by_pk?.meta_date?.hospital2}</p>
                  ) : (
                    "Not updated"
                  )}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Project compatibility</p>
                </Col>
                <Col span={6}>
                  <p>
                    {data?.user_by_pk?.project_compatibilitys?.length > 0
                      ? data?.user_by_pk?.project_compatibilitys?.join(",")
                      : "Not updated"}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>No of beds</p>
                </Col>
                <Col span={6}>
                  <p>
                    {data?.user_by_pk?.meta_date?.noOfBeds ? (
                      <p>{data?.user_by_pk?.meta_date?.noOfBeds}</p>
                    ) : (
                      "Not updated"
                    )}
                  </p>
                </Col>
              </Row>
              {data?.user_by_pk?.additional_data?.length > 0 &&
                data?.user_by_pk?.additional_data?.map((data) => {
                  return (
                    <Row className="mb-2">
                      <Col span={6}>
                        <p style={{ color: "#828282" }}>{data?.label}</p>
                      </Col>
                      <Col span={6}>
                        <p>{data?.info}</p>
                      </Col>
                    </Row>
                  );
                })}
            </TabPane>
            <TabPane tab="Work details" key="3">
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>
                    Started practicing (post Residency)
                  </p>
                </Col>
                <Col span={6}>
                  <p>
                    {moment(data?.user_by_pk?.meta_date?.yearOfPractice).format(
                      "yyyy"
                    )}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>
                    Years of experience
                  </p>
                </Col>
                <Col span={6}>
                  <p>
                    {moment().format("yyyy") - moment(data?.user_by_pk?.meta_date?.yearOfPractice).format(
                      "yyyy"
                    )}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Total incentives processed</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.totalIncentive}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>
                    Previously participated studies
                  </p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.meta_date?.previousStudies}</p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Mode of payment</p>
                </Col>
                <Col span={6}>
                  {data?.user_by_pk?.meta_date?.payment?.map((info) => {
                    return <Tag>{info.label}</Tag>;
                  })}
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Validated date</p>
                </Col>
                <Col span={6}>
                  <p>
                    {moment(data?.user_by_pk?.meta_date?.yearOfPractice).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col span={6}>
                  <p style={{ color: "#828282" }}>Status</p>
                </Col>
                <Col span={6}>
                  <p>{data?.user_by_pk?.status}</p>
                </Col>
              </Row>
              {!!data?.user_by_pk?.additional_data?.length && (
                <div className="mb-2">
                  <p style={{ color: "#828282" }}>Additional Fields</p>
                  {data?.user_by_pk?.additional_data?.map(
                    (additionalFields, additionalFieldIndex) => {
                      return (
                        <Row
                          className="mb-2 ml-3"
                          key={`additional-${additionalFieldIndex}`}
                        >
                          <Col span={6}>
                            <p style={{ color: "#828282" }}>
                              {additionalFields?.label}
                            </p>
                          </Col>
                          <Col span={6}>
                            <p>{additionalFields?.info}</p>
                          </Col>
                        </Row>
                      );
                    }
                  )}
                </div>
              )}
            </TabPane>
            <TabPane tab="Surveys" key="4">
              <AssingedSurveys />
            </TabPane>
            <TabPane tab="Rewards" key="5">
              <RewardsTab />
            </TabPane>
          </Tabs>
        </Card>
      </Col>
      <Col span={6}>
        <Card title="Activity"
        // bodyStyle={{ maxHeight: "100vh" }}
        >
          {activity?.approval?.map((copy) => {
            let approval = JSON.parse(JSON.stringify(copy));
            approval.old_info.user = {
              ...approval.old_info.user,
              ...approval.old_info.user.meta_date,
              ...approval.old_info.user.additional_data,
            };
            approval.updated_info.user = {
              ...approval.updated_info.user,
              ...approval.updated_info.user.meta_date,
              ...approval.updated_info.user.additional_data,
            };
            const processedChanges = [];
            // eslint-disable-next-line
            return Object.keys(approval?.old_info?.user).map((key) => {
              let oldTitle = approval?.old_info?.user[key];
              let newTitle = approval?.updated_info?.user[key];

              oldTitle = oldTitle !== null && oldTitle !== undefined ? oldTitle.toString() : 'null';
              newTitle = newTitle !== null && newTitle !== undefined ? newTitle.toString() : 'null';

              if (oldTitle !== newTitle && oldTitle !== "null" && newTitle !== "null") {
                const changeKey = `${key}:${oldTitle}:${newTitle}`;
                if (!processedChanges.includes(changeKey)) {
                  processedChanges.push(changeKey);

                  if (key === "city" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        City changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "f_name" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        First name changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "l_name" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        First name changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "title" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Title changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "state" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        State changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "email" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Email changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "phone_number" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Phone number changed from{" "}
                        {oldTitle || "null" || "null"} to{" "}
                        {newTitle || "null" || "null"}
                      </p>
                    );
                  } else if (key === "gender" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Gender changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "pincode" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Pincode changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "year_of_birth" && (oldTitle || newTitle)) {
                    if (
                      // eslint-disable-next-line
                      moment(oldTitle || "null")?.format("yyyy") !==
                      moment(oldTitle || "null")?.format("yyyy")
                    ) {
                      return (
                        <p>
                          Year of birth changed from{" "}
                          {moment(oldTitle || "null").format("yyyy")}{" "}
                          to{" "}
                          {moment(newTitle || "null").format(
                            "yyyy"
                          )}
                        </p>
                      );
                    }
                  } else if (key === "access" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Access to mobile app changed from{" "}
                        {oldTitle || "null" === true ? (
                          <span className="mr-1">Yes</span>
                        ) : (
                          <span className="mr-1">No</span>
                        )}
                        to
                        {newTitle || "null" === true ? (
                          <span className="ml-1">Yes</span>
                        ) : (
                          <span className="ml-1">No</span>
                        )}
                      </p>
                    );
                  } else if (key === "status" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Status changed from {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "mobile1" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Mobile 1 changed from{" "}
                        {oldTitle || "null" || "null"} to{" "}
                        {newTitle || "null" || "null"}
                      </p>
                    );
                  } else if (key === "mobile2" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Mobile 2 changed from{" "}
                        {oldTitle || "null" || "null"} to{" "}
                        {newTitle || "null" || "null"}
                      </p>
                    );
                  } else if (key === "hospital" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Hospital name changed from {oldTitle || "null"}{" "}
                        to {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "hospital1" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Consulting hospital 1 changed from{" "}
                        {oldTitle || "null" || "null"} to{" "}
                        {newTitle || "null" || "null"}
                      </p>
                    );
                  } else if (key === "hospital2" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Consulting hospital 2 changed from{" "}
                        {oldTitle || "null" || "null"} to{" "}
                        {newTitle || "null" || "null"}
                      </p>
                    );
                  } else if (key === "validatedDate" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Validated date changed from{" "}
                        {moment(oldTitle || "null").format(
                          "DD/MM/YYYY"
                        )}{" "}
                        to{" "}
                        {moment(newTitle || "null").format(
                          "DD/MM/YYYY"
                        )}
                      </p>
                    );
                  } else if (key === "totalIncentive" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Total incentive processed changed from{" "}
                        {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "yearOfPractice" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Year of practice changed from{" "}
                        {moment(oldTitle || "null").format("YYYY")} to{" "}
                        {moment(newTitle || "null").format("YYYY")}
                      </p>
                    );
                  } else if (key === "previousStudies" && (oldTitle || newTitle)) {
                    return (
                      <p>
                        Previously participated studies changed from{" "}
                        {oldTitle || "null"} to{" "}
                        {newTitle || "null"}
                      </p>
                    );
                  } else if (key === "primary_specialty" && (oldTitle || newTitle)) {
                    return <p>Primary speciality changed</p>;
                  } else if (key === "secondary_specialty" && (oldTitle || newTitle)) {
                    return <p>Secondary speciality changed</p>;
                  } else if (key === "project_compatibility" && (oldTitle || newTitle)) {
                    return <p>Project compatibility changed</p>;
                  }
                }
              }
            });
          })}
        </Card>
      </Col>
    </Row>
  );
};

const AssingedSurveys = () => {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const { data, loading } = useQuery(GET_ASSIGNED_PROJECTS, {
    variables: {
      id: parseInt(id),
    },
  });
  const savedviewPhysicianAssingedSurveys = localStorage.getItem('viewPhysicianAssingedSurveys');
  const viewPhysicianAssingedSurveysinitial = savedviewPhysicianAssingedSurveys
    ? JSON.parse(savedviewPhysicianAssingedSurveys)
    : {
      name: { enabled: true, label: "Name" },
      client: { enabled: true, label: "Client" },
      category: { enabled: true, label: "Category" },
      manager: { enabled: true, label: "Assigned To" },
      startDate: { enabled: true, label: "Start Date" },
      endDate: { enabled: true, label: "End Date" },
      //status: { enabled: true, label: "Status" },
      projectStatus: { enabled: true, label: "Project status" },
      responseStatus: { enabled: true, label: "Response status" },
      clientLink: { enabled: false, label: "Client Link" },
      kreaLink: { enabled: false, label: "Krea Link" },
      interviewerName: { enabled: false, label: "Interviewer" },
      callStatus: { enabled: false, label: "Call status" },
    }
  const [columnStatus, setStatus] = useState(viewPhysicianAssingedSurveysinitial);

  useEffect(() => {
    localStorage.setItem('viewPhysicianAssingedSurveys', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let copyData = [],
      copyObject = {};
    if (data) {
      data?.project.forEach((project) => {
        copyObject.name = project?.surveys[0]?.name;
        copyObject.client =
          (project?.client?.f_name || "") + " " + (project?.client?.l_name || "");
        copyObject.category = project?.projectTypeByProjectType.name;
        copyObject.manager =
          project?.manager_x_projects[0]?.user?.f_name +
          " " +
          project?.manager_x_projects[0]?.user?.l_name;
        copyObject.startDate = project?.surveys[0]?.start_date;
        copyObject.endDate = project?.surveys[0]?.end_date;
        copyObject.status = project?.surveys[0]?.status;
        copyObject.responseStatus = project?.surveys[0].survey_x_members[0]?.state;
        copyObject.member =
          project?.member_types !== null
            ? project?.member_types[0] === 1
              ? "Physician"
              : project?.member_types[0] === 2
                ? "Technician"
                : project?.member_types[0] === 3
                  ? "Patient"
                  : "Consumer"
            : "";
        copyObject.projectStatus = project?.status;
        if (
          project?.projectTypeByProjectType?.name === "Online" ||
          (project?.is_online_or_assisted &&
            project?.projectTypeByProjectType?.name === "Assisted")
        ) {
          copyObject.clientLink =
            project?.survey_type === "Third_Party_survey"
              ? project?.surveys[0]?.links_status
              : project?.surveys[0]?.survey_x_members[0]?.survey_url;
          copyObject.kreaLink = `${baseURL}/survey/${project?.surveys[0]?.id}/member/${project?.surveys[0]?.survey_x_members[0]?.member_id}`;
        }
        if (
          project?.surveys[0]?.survey_x_members[0]
            ?.survey_x_member_x_interviewers.length > 0
        ) {
          copyObject.interviewerName =
            project?.surveys[0]?.survey_x_members[0]
              ?.survey_x_member_x_interviewers[0].interviewer.l_name !== null
              ? project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.f_name +
              " " +
              project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.l_name
              : project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.f_name;
          copyObject.callStatus =
            project?.surveys[0]?.survey_x_members[0]?.survey_x_member_x_interviewers[0]?.status;
          copyObject.comments =
            project?.surveys[0]?.survey_x_members[0]?.survey_x_member_x_interviewers[0]?.comments?.description;
        }
        copyData.push(copyObject);
        copyObject = {};
      });
      setTableData(copyData);
    }
  }, [data]);

  const columns = [
    {
      title: "SURVEY NAME",
      key: "name",
      dataIndex: "name",
      width: 300,
      ...getColumnSearchProps("name", "Survey Name"),
    },
    {
      title: "CLIENT",
      key: "client",
      dataIndex: "client",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("client", "Client"),
    },
    {
      title: "CATEGORY",
      key: "category",
      // dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("category", "Category"),
      render: (record) => {
        return (
          <div>
            <div>
              <div className="font-medium text-textDark text-sm">
                {record.category}
              </div>
              <div className="text-xs text-textLight font-normal">
                {record.member}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "ASSIGNED TO",
      key: "manager",
      dataIndex: "manager",
      className: columnStatus?.manager?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("manager", "Manager"),
    },
    {
      title: "START DATE",
      key: "startDate",
      dataIndex: "startDate",
      className: columnStatus?.startDate?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("startDate"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "endDate",
      dataIndex: "endDate",
      className: columnStatus?.endDate?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("endDate"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    /*{
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.status === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.status === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.status === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },*/
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      isShow: columnStatus?.clientLink?.enabled,
      className: columnStatus?.clientLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("clientLink"),
      width: 200,
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "kreaLink",
      isShow: columnStatus?.kreaLink?.enabled,
      className: columnStatus?.kreaLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("kreaLink"),
      width: 200,
    },
    {
      title: "INTERVIEWER",
      dataIndex: "interviewerName",
      isShow: columnStatus?.interviewerName?.enabled,
      className: columnStatus?.interviewerName?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnSearchProps("interviewerName"),
    },
    {
      title: "CALL STATUS",
      dataIndex: "callStatus",
      width: 200,
      isShow: columnStatus?.callStatus?.enabled,
      className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Screened",
          value: "Screened",
        },
        {
          text: "Terminated",
          value: "Terminated",
        },
        {
          text: "Call Later",
          value: "Call Later",
        },
        {
          text: "Not Interested",
          value: "Not Interested",
        },
        {
          text: "Appointment Fixed",
          value: "Appointment Fixed",
        },
        {
          text: "Busy",
          value: "Busy",
        },
        {
          text: "Not Reachable / Out of Coverage",
          value: "Not Reachable / Out of Coverage",
        },
        {
          text: "Call Waiting",
          value: "Call Waiting",
        },
        {
          text: "No Response",
          value: "No Response",
        },
        {
          text: "Person Not Available",
          value: "Person Not Available",
        },
        {
          text: "Wrong Number",
          value: "Wrong Number",
        },
        {
          text: "Invalid Number",
          value: "Invalid Number",
        },
        {
          text: "Language Problem",
          value: "Language Problem",
        },
        {
          text: "Call Declined",
          value: "Call Declined",
        },
        {
          text: "Others",
          value: "Others",
        },
      ],
      onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (value, item, index) => {
        return (
          <div>
            <span className="capitalize">{value}</span>
            {item.comments && (
              <Tooltip placement="topLeft" title={item.comments}>
                <InfoCircleOutlined className=" ml-1" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "PROJECT STATUS",
      key: "projectStatus",
      dataIndex: "projectStatus",
      className: columnStatus?.projectStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.projectStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.projectStatus === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.projectStatus === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.projectStatus === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "RESPONSE STATUS",
      dataIndex: "responseStatus",
      className: columnStatus?.responseStatus?.enabled ? "table-cell" : "hidden",
      render: (text) => (
        <>
          {text === "screened" && (
            <span className={"text-blue text-base"}>Screened</span>
          )}
          {(text === "completed" || text === "approved") && (
            <span className={"text-green text-base"}>Completed</span>
          )}
          {text === "cancelled" && (
            <span className={"text-red text-base"}>Terminated</span>
          )}
          {text === "quotaFull" && (
            <span className={"text-red text-base"}>Quota Full</span>
          )}
          {text === "active" && (
            <span className={"text-textExtraLight text-base"}>
              Status pending
            </span>
          )}
          {text === "in-progress" && (
            <span className={"text-textExtraLight text-base"}>In progress</span>
          )}
        </>
      ),
      filters: [
        {
          text: "Screened",
          value: "screened",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Terminated",
          value: "cancelled",
        },
        {
          text: "Status pending",
          value: "active",
        },
        {
          text: "In progress",
          value: "in-progress",
        },
        {
          text: "Quota full",
          value: "quotaFull",
        },
      ],
      onFilter: (value, record) => record.responseStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
  ];
  return (
    <Spin spinning={loading}>
      <div className=" pb-5">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      {/* <Table
        columns={columns}
        className="shadow rounded mt-10"
        dataSource={tableData}
      /> */}
      <MemberTable columns={columns} tableData={tableData}></MemberTable>
    </Spin>
  );
};

const RewardsTab = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [dataObject, setDataObject] = useState(null);
  const { data } = useQuery(REWARDS_TAB, {
    variables: {
      member_id: parseInt(id),
    },
  });
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  // const [columnStatus, setStatus] = useState({
  //   completedOn: { enabled: true, label: "Completed On" },
  //   rewardStatus: { enabled: true, label: "Reward Status" },
  //   rewardedBy: { enabled: true, label: "Rewarded By" },
  // });
  const savedviewPhysicianRewardsTab = localStorage.getItem('viewPhysicianRewardsTab');
  const viewPhysicianRewardsTabinitial = savedviewPhysicianRewardsTab
    ? JSON.parse(savedviewPhysicianRewardsTab)
    : {
      completedOn: { enabled: true, label: "Completed On" },
      rewardStatus: { enabled: true, label: "Reward Status" },
      rewardedBy: { enabled: true, label: "Rewarded By" },
    }

  const [columnStatus, setStatus] = useState(viewPhysicianRewardsTabinitial);

  useEffect(() => {
    localStorage.setItem('viewPhysicianRewardsTab', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let tempData = [],
      tempObject = {};
    if (data) {
      data?.survey?.forEach((survey) => {
        if (survey.survey_x_members.length > 0) {
          tempObject.id = survey.id;
          tempObject.name = survey.name;
          tempObject.completedOn = moment(
            survey?.survey_x_members[0]?.updated_at
          ).format("DD,MMM,YYYY h:mm a");
          tempObject.rewardStatus =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]?.status
              : "Yet to reward";
          tempObject.rewardedBy =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]?.created_user?.f_name +
              " " +
              survey?.survey_x_members[0]?.rewards[0]?.created_user?.l_name
              : "";
          tempObject.reward =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]
              : null;
          tempData.push(tempObject);
          tempObject = {};
        }
      });
      setTableData(tempData);
    }
  }, [data]);

  const detailFooter = (
    <Row>
      <div className="w-full h-8 my-2">
        <div className="text-textMedium float-right pt-1 pr-6">
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              setDetailsVisible(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );

  const columns = [
    {
      title: "NAME",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("namee", "Project Name"),
    },
    {
      title: "COMPLETED ON",
      key: "date",
      dataIndex: "completedOn",
      className: columnStatus?.completedOn?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("completedOn"),
    },
    {
      title: "REWARD STATUS",
      key: "rewardStatus",
      dataIndex: "rewardStatus",
      className: columnStatus?.rewardStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Yet to reward",
          value: "Yet to reward",
        },
        {
          text: "Rewarded",
          value: "Rewarded",
        },
        {
          text: "Approved",
          value: "Approved",
        },
      ],
      onFilter: (value, record) => record.rewardStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => {
        return text === "Rewarded" ? (
          <span style={{ color: "#6BC342" }}>{text}</span>
        ) : text === "Yet to reward" ? (
          <span style={{ color: "#FFA63E" }}>{text}</span>
        ) : (
          <span style={{ color: "#C74B5E" }}>{text}</span>
        );
      },
    },
    {
      title: "REWARDED BY",
      key: "by",
      dataIndex: "rewardedBy",
      className: columnStatus?.rewardedBy?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("rewardedBy", "Rewarded By"),
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => {
        // eslint-disable-next-line
        {
          return (
            <Button
              type="link"
              onClick={() => {
                setDetailsVisible(true);
                setDataObject(record);
              }}
            >
              View details
            </Button>
          );
        }
      },
    },
  ];

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <Col
              span={12}
              className="px-6 text-xl font-semibold"
              style={{ color: "#4F4F4F" }}
            >
              Completed surveys -{" "}
              {data?.survey_x_member_aggregate?.aggregate?.count || 0}
            </Col>
            <div>
              <Button
                // size="large"
                className="rounded mr-5 px-3"
                style={{ color: "#E08036", borderColor: "#E08036" }}
              >
                <DownloadOutlined style={{ fontSize: "1.5rem" }} />
              </Button>
              <ColumnEditor
                columns={columnStatus}
                onSave={(column) => {
                  setStatus(column);
                }}
              />
            </div>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <Table columns={columns} dataSource={tableData} />
        </Col>
      </Row>
      <Drawer
        title="Rewards"
        footer={detailFooter}
        width={600}
        visible={detailsVisible}
        onClose={() => {
          setDetailsVisible(false);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          colon={false}
        >
          <Form.Item label="Disbursal note">
            {dataObject?.reward?.comments}
          </Form.Item>
          <Form.Item label="File">
            {dataObject?.reward?.file_url.split(",")?.map((files) => {
              return (
                <Row
                  span={24}
                  align="middle"
                  justify="space-between"
                  key={files}
                >
                  <Col>{files?.split("/")[files?.split("/")?.length - 1]}</Col>
                  <Col>
                    <Button
                      size="large"
                      className="rounded float-right"
                      style={{ color: "#E08036", borderColor: "#E08036" }}
                      onClick={() => handleDownload(files)}
                    >
                      <DownloadOutlined style={{ fontSize: "1.5rem" }} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ViewPhysician;
