import React, { useState, useEffect, useContext, useMemo } from "react";
import { Button, Table, notification } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_MEMBERS_LIST, RESEND_INVITE } from "../graphql";
import ColumnEditor from "../../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../components/memberColumns";
import { RoleContext } from "../../../context/RoleContext";
import SpecialityModalPop from "../../../utils/specialitySearch";
import MemberTable from "../../../components/memberTable";
function MemberListTab(props) {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID } = useParams();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const { role } = useContext(RoleContext);
  const [resendInvite] = useMutation(RESEND_INVITE);
  let searchInput;
  const savedviewSurveyOnlineClientList = localStorage.getItem('viewSurveyOnlineClientList');
  const viewSurveyOnlineClientListinitial = savedviewSurveyOnlineClientList
    ? JSON.parse(savedviewSurveyOnlineClientList)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(viewSurveyOnlineClientListinitial);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState("");
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState("");
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  useEffect(() => {
    localStorage.setItem('viewSurveyOnlineClientList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let tempCol = {
      ...columnStatus,
      status: { enabled: true, label: "Status" },
    };
    if (props.projectType === "Assisted") {
      delete tempCol.status;
    }
    setStatus(tempCol);
  }, [props.projectType]);

  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members, (item) => {
        item.clientLink =
          window.location.pathname.split("-")[1] === "Third_Party_survey"
            ? item?.survey_x_members[0]?.survey?.links_status
            : item?.survey_x_members[0]?.survey_url;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;
        item.status =
          item?.survey_x_members[0]?.state === "active" ||
            item?.survey_x_members[0]?.state === "in-progress"
            ? "Not responded"
            : "Responded";
        return item;
      });
      setTableData(object);
    }
  }, [members, baseURL, surveyID]);
  console.log(tableData);
  const handleInvite = (record) => {
    resendInvite({
      variables: {
        user_id: record?.id,
        surveyID: parseInt(surveyID),
      },
    })
      .then(() => {
        notification.success({
          message: "Sent!",
          description: "Invitation has been sent to member",
        });
      })
      .catch(() => {
        notification.error({
          message: "Error",
          description: "An error occurred while sending invitation.",
        });
      });
  };

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(
      filteredDataSource.map((item) => item[columnName])
    );
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec("");
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(
    new Set(tableData?.map((item) => item[selectedColumnNameSpec]))
  ).filter((data) =>
    data?.toLowerCase().includes(searchQuerySpec.toLowerCase())
  );

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const columns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
      ...[
        {
          title: "STATUS",
          dataIndex: "status",
          isShow: columnStatus?.status?.enabled,
          className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
          width: 200,
          filters: [
            {
              text: "Not responded",
              value: "Not responded",
            },
            {
              text: "Responded",
              value: "Responded",
            },
          ],
          onFilter: (value, record) => record.status.indexOf(value) === 0,
          filterIcon: () => <FilterOutlined />,
          render: (text, record) => (
            <>
              {text === "Not responded" ? (
                <>
                  <p className={"text-red text-h8"}>Not responded</p>
                  <Button
                    type="link"
                    className="text-h8 p-0"
                    onClick={() => handleInvite(record)}
                  >
                    Resend Invite
                  </Button>
                </>
              ) : (
                <span className={"text-green text-h8"}>Responded</span>
              )}
            </>
          ),
        },
      ],
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  return (
    <div className="membersTab w-full ">
      <div className=" pb-5 float-right">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      <MemberTable
        style={{ overflow: "auto" }}
        columns={columns}
        dataSource={tableData}
        rowKey={(record) => record.id}
        rowClassName={(record, index) =>
          "text-sm text-textMedium border-t border-l border-r border-textLight"
        }
      />
      <SpecialityModalPop
        titleSpec={`Selected ${selectedColumnNameSpec}`}
        visibleSpec={isModalVisibleSpec}
        onOkSpec={handleModalOkSpec}
        onCancelSpec={handleModalCloseSpec}
        searchQuerySpec={searchQuerySpec}
        handleSearchSpec={handleSearchSpec}
        handleCheckboxChangeSpec={handleCheckboxChangeSpec}
        filteredCheckboxDataSpec={filteredCheckboxDataSpec}
        selectedDataSpec={selectedDataSpec}
        initialSelectedDataSpec={initialSelectedDataSpec}
        isSubmitDisabledSpec={isSubmitDisabledSpec}
        handleResetSpec={handleResetSpec}
      />
    </div>
  );
}
export default MemberListTab;
