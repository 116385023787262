import { Drawer, Form, Button, Checkbox, Input } from "antd";
import { useState } from "react";
import FileUpload from "./fileUploadValidation";

function EmailValidation(props) {
  const [visible, setVisible] = useState(props.visible);
  const [value, setValue] = useState(
    props?.validationObject[props.questionNumber]
  );
  const [uploadOption, setUploadOption] = useState(
    props?.validationObject[props.questionNumber]
      ? props?.validationObject[props.questionNumber].addFileOption
      : false
  );

  const [form] = Form.useForm();

  const onClose = () => {
    if (form.getFieldValue("noOfEmails") > 0) {
      setVisible(false);

      let validationObject = {
        noOfEmails: form.getFieldValue("noOfEmails")
          ? form.getFieldValue("noOfEmails")
          : null,
        questionMandatory: form.getFieldValue("questionMandatory")
          ? form.getFieldValue("questionMandatory")
          : false,
        addFileOption: form.getFieldValue("addFileOption")
          ? form.getFieldValue("addFileOption")
          : null,
        fileUploadDescription: form.getFieldValue("addFileOption")
          ? form.getFieldValue("fileUploadDescription")
          : null,
        fileTypesAllowed: form.getFieldValue("addFileOption")
          ? form.getFieldValue("fileTypesAllowed")
          : null,
        fileSize: form.getFieldValue("addFileOption")
          ? form.getFieldValue("fileSize")
          : null,
        fileNumbers: form.getFieldValue("addFileOption")
          ? form.getFieldValue("fileNumbers")
          : null,
        fileLimit: form.getFieldValue("addFileOption")
          ? form.getFieldValue("fileNumbers") &&
            form.getFieldValue("fileNumbers")[0] === "Limit to"
            ? form.getFieldValue("fileLimit")
            : null
          : null,
      };
      props.handleValidationValues(
        validationObject,
        visible,
        props.questionNumber
      );
    }
  };

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
    props.handleValidationValues(
      form.getFieldsValue(),
      visible,
      props.questionNumber
    );
  };
  const onReset = () => {
    form.setFieldsValue({ addFileOption: false });
    // form.setFieldsValue({ optionsCount: null });
    form.setFieldsValue({ fileUploadDescription: "" });
    form.setFieldsValue({ fileTypesAllowed: [] });
    form.setFieldsValue({ fileSize: null });
    form.setFieldsValue({ fileNumbers: [] });
    form.setFieldsValue({ fileLimit: null });
    form.setFieldsValue({ noOfEmails: null });
    setValue(null);
    setUploadOption(false);
  };
  const initialValue =
    props?.validationObject &&
    props?.validationObject?.length !== 0 &&
    props?.validationObject[props.questionNumber] !== null
      ? props?.validationObject[props.questionNumber]
      : {
          questionMandatory: true,
        };
  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Validation</span>}
        width={720}
        onClose={onCancel}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
        maskClosable={false}
        destroyOnClose={true}
        footer={
          <>
            <div style={{ float: "left", width: "70%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
            <div style={{ float: "left", width: "30%" }}>
              <Button
                type="link"
                className="text-base"
                htmlType="button"
                style={{ marginRight: 8 }}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                onClick={onClose}
                type="primary"
                className="text-base rounded-sm w-28"
              >
                Add
              </Button>
            </div>
          </>
        }
      >
        <Form form={form} layout="vertical" initialValues={initialValue}>
          <Form.Item
            name="noOfEmails"
            label={
              <span className="text-textMedium text-sm">
                Maximum number of emails
              </span>
            }
            className="w-3/4"
            rules={[
              () => ({
                validator(_, value) {
                  let regx = /^\d*$/g;
                  if (value && !regx.test(value)) {
                    return Promise.reject("Please enter a valid number");
                  } else if (!value || parseInt(value) > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject("Please enter a valid number");
                },
              }),
            ]}
          >
            <Input type="number" placeholder="Enter the limit of entries" />
          </Form.Item>
          <Form.Item
            name="questionMandatory"
            value="questionMandatory"
            valuePropName="checked"
          >
            <Checkbox className="text-textMedium text-sm">
              This question is mandatory
            </Checkbox>
          </Form.Item>
          <Form.Item
            name="addFileOption"
            value="addFileOption"
            valuePropName="checked"
          >
            <Checkbox
              className="text-textMedium text-sm"
              onChange={(e) => setUploadOption(e.target.checked)}
            >
              Add upload file option
            </Checkbox>
          </Form.Item>
          <Form.Item value="fileDetails">
            {uploadOption && (
              <FileUpload
                checked={true}
                textInput={value !== null ? value?.fileLimit : null}
                fileNumbers={
                  value !== null && value?.fileNumbers
                    ? value?.fileNumbers[0]
                    : null
                }
              />
            )}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default EmailValidation;
