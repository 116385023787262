import React from 'react';
import AllMembersList from './allMembers';
import ExportMembers from './exportMembers';
import VerifyMembers from './verifyMembers';
import RejectionList from './rejectionList';
import { AppContext } from '../../context/membersContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import jwtDecode from 'jwt-decode';

function Index(props) {
  const [role, setRole] = useState(null);
  const { isExportVisible,
    isVerifyVisible,
    isRejectVisible,
    setIsExportVisible,
    setIsVerifyVisible,
    setIsRejectVisible
  } = useContext(AppContext);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    const storedIsExportVisible = JSON.parse(localStorage.getItem('isExportVisible'));
    const storedIsVerifyVisible = JSON.parse(localStorage.getItem('isVerifyVisible'));
    const storedIsRejectVisible = JSON.parse(localStorage.getItem('isRejectVisible'));
    setIsExportVisible(storedIsExportVisible);
    setIsVerifyVisible(storedIsVerifyVisible);
    setIsRejectVisible(storedIsRejectVisible);
  }, [setIsExportVisible, setIsVerifyVisible, setIsRejectVisible]);


  useEffect(() => {
    if (role === 'manager') {
      setIsVerifyVisible(false);
    }
  }, [role, setIsVerifyVisible]);

  return (
    <>
      {isExportVisible && <ExportMembers />}
      {isVerifyVisible && <VerifyMembers />}
      {isRejectVisible && <RejectionList />} 
      {!isExportVisible && !isVerifyVisible && !isRejectVisible && <AllMembersList />}
    </>
  );
}

export default Index;