import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Table, Space, Input, Tooltip } from "antd";
import { useHistory, useParams } from "react-router";
import {
  SearchOutlined,
  FilterOutlined,
  DownloadOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { formatDateTime } from "../../../utils/dateTime";
import { GET_MEMBERS_RESPONSES_EXTERNAL } from "../graphql";
import moment from "moment";
import { downloadCSVFile } from "../../../utils/downloadCSVFile";
import ColumnEditor from "../../../components/ColumnEditor";
import exclamationCircle from "../../../assets/Icon/exclamationCircle.svg";
import { RoleContext } from "../../../context/RoleContext";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../../components/memberColumns"
import MemberTable from "../../../components/memberTable";

function ResponsesTab() {
  const { surveyID, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  let searchInput;
  const todayDate = moment().format("DD/MMM/YYYY");
  const { data: membersResponses } = useQuery(GET_MEMBERS_RESPONSES_EXTERNAL, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });
  const { role } = useContext(RoleContext);
  const savedviewProjectExternal = localStorage.getItem('viewProjectExternal');
  const viewProjectExternalinitial = savedviewProjectExternal
    ? JSON.parse(savedviewProjectExternal)
    : {
      ...memberColumnStatus,
      "dateTime": { enabled: true, label: "Data and Time" },
      "interviewerName": { enabled: true, label: "Interviewer" },
      "callStatus": { enabled: true, label: "Call Status" },
      "status": { enabled: true, label: "Status" },
    }

  const [columnStatus, setStatus] = useState(viewProjectExternalinitial);
  delete columnStatus.interviewer;
  useEffect(() => {
    localStorage.setItem('viewProjectExternal', JSON.stringify(columnStatus));
  }, [columnStatus]);
  /*useEffect(() => {
    let newArray = [];
    if (membersResponses) {
      let object = JSON.parse(
        JSON.stringify(membersResponses?.survey_x_member)
      );
      object.forEach((item) => {
        item.memberName = item.user.f_name + " " + item.user.l_name;
        item.email = item.user.email;
        item.phone_number = item.user.phone_number;
        if (item.priority.length !== 0) {
          item.interviewerName =
            item.priority[0].interviewer.l_name !== null
              ? item.priority[0].interviewer.f_name +
              " " +
              item.priority[0].interviewer.l_name
              : item.priority[0].interviewer.f_name;

          item.dateTime = formatDateTime(item.priority[0].created_at);
          item.callStatus =
            item.priority[0].status === "active"
              ? "Status pending"
              : item.priority[0].status;
          item.status =
            item.state === "Completed"
              ? "Completed"
              : item.state === "Approved"
                ? "Approved"
                : "Pending";
          item.comments = item.priority[0]?.comments?.description || "";
          newArray.push(item);
        }
      });
      setTableData(newArray);
    }
  }, [membersResponses]);*/

  useEffect(() => {
    if (membersResponses) {
      if (
        membersResponses?.survey_x_member &&
        membersResponses?.survey_x_member?.length > 0
      ) {
        let object,
          newObject = [];
        object = JSON.parse(JSON.stringify(membersResponses?.survey_x_member));
        object.forEach((item, index) => {
          newObject.push(userMemberData(item?.user, (data) => {
            if (item.priority.length !== 0) {
              data.interviewerName = item.priority[0].interviewer.l_name !== null
                ? item.priority[0].interviewer.f_name +
                " " + item.priority[0].interviewer.l_name
                : item.priority[0].interviewer.f_name;
              data.dateTime = formatDateTime(item.priority[0].created_at);
              data.callStatus = item.priority[0].status === "active" ? "Status pending" : item.priority[0].status;
              data.status = item.state === "Completed" ? "Completed"
                : item.state === "Approved"
                  ? "Approved"
                  : "Pending";
              data.comments = item.priority[0]?.comments?.description || "";
            }
          }));
        });
        setTableData(newObject);
      }
    }
  }, [membersResponses]);

  // function handleDownloadExcel() {
  //   const header = [
  //     "Name",
  //     "Email",
  //     "Phone_number",
  //     "Interviewer",
  //     "Date_and_Time",
  //     "Status",
  //   ];
  //   const body = [];
  //   const todayDate = moment().format("DD/MMM/YYYY");
  //   tableData.map((item) => {
  //     return body.push({
  //       Name: item.memberName,
  //       Email: item.email,
  //       Phone_number: item.phone_number,
  //       Interviewer: item.interviewerName,
  //       Date_and_Time: formatDateTime(item.priority[0].created_at),
  //       Status: item.status,
  //     });
  //   });
  //   downloadExcel({
  //     fileName: `${membersResponses?.survey_x_member[0]?.survey?.project?.name}-${todayDate}`,
  //     sheet: `${membersResponses?.survey_x_member[0]?.survey?.project?.name}-${todayDate}`,
  //     tablePayload: {
  //       header,
  //       body: body,
  //     },
  //   });
  // }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 150,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "DATE AND TIME",
      dataIndex: "dateTime",
      key: 'dateTime',
      className: columnStatus?.dateTime?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("dateTime"),
    },
    {
      title: "INTERVIEWER",
      dataIndex: "interviewerName",
      className: columnStatus?.interviewerName?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("interviewerName"),
    },
    {
      title: "CALL STATUS",
      dataIndex: "callStatus",
      width: 200,
      className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Screened",
          value: "Screened",
        },
        {
          text: "Terminated",
          value: "Terminated",
        },
        {
          text: "Call Later",
          value: "Call Later",
        },
        {
          text: "Not Interested",
          value: "Not Interested",
        },
        {
          text: "Appointment Fixed",
          value: "Appointment Fixed",
        },
        {
          text: "Busy",
          value: "Busy",
        },
        {
          text: "Not Reachable / Out of Coverage",
          value: "Not Reachable / Out of Coverage",
        },
        {
          text: "Call Waiting",
          value: "Call Waiting",
        },
        {
          text: "No Response",
          value: "No Response",
        },
        {
          text: "Person Not Available",
          value: "Person Not Available",
        },
        {
          text: "Wrong Number",
          value: "Wrong Number",
        },
        {
          text: "Invalid Number",
          value: "Invalid Number",
        },
        {
          text: "Language Problem",
          value: "Language Problem",
        },
        {
          text: "Call Declined",
          value: "Call Declined",
        },
        {
          text: "Others",
          value: "Others",
        },
      ],
      onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (value, item, index) => {
        return <div>
          {value}       {item.comments && <Tooltip placement="topLeft" title={item.comments} >
            <InfoCircleOutlined className=" ml-1" />
          </Tooltip>}
        </div>
      }
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      render: (value, item, index) => {
        return (
          <>
            {value === "Completed" ? (
              <span className="text-green h-8">Completed</span>
            ) : value === "Approved" ? (
              <span className="text-green h-8">Approved</span>
            ) : (
              <span className="text-red h-8">Pending</span>
            )}
          </>
        );
      },
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: (
        <span>
          ACTION
          <Tooltip placement="top" title="Dowload Data">
            <Button
              type="link"
              className="float-right p-0"
              onClick={() => {
                columns.pop();
                downloadCSVFile(
                  tableData,
                  columns,
                  `${membersResponses?.survey_x_member[0]?.survey?.project?.name}-${todayDate}`
                );
              }}
            >
              <DownloadOutlined style={{ fontSize: "1.5rem" }} />
            </Button>
          </Tooltip>
        </span>
      ),
      dataIndex: "action",
      render: (value, record, index) => {
        return (
          <>
            {(tableData[index].status === "Completed" ||
              tableData[index].status === "Approved") && (
                <Button
                  type="link"
                  className="h-6 p-0"
                  onClick={() => {
                    history.push(
                      `/viewSurvey/responses/project/${projectID}/survey/${surveyID}/External/viewResponseAnswers/member/${record.user.id}/interviewer/${record.priority[0].interviewer.id}`
                    );
                  }}
                >
                  View responses
                </Button>
              )}
          </>
        );
      },
    },
  ];*/

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),
    ...[
      {
        title: "DATE AND TIME",
        dataIndex: "dateTime",
        key: 'dateTime',
        isShow: columnStatus?.dateTime?.enabled,
        className: columnStatus?.dateTime?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("dateTime"),
      },
      {
        title: "INTERVIEWER",
        dataIndex: "interviewerName",
        isShow: columnStatus?.interviewerName?.enabled,
        className: columnStatus?.interviewerName?.enabled ? "table-cell" : "hidden",
        ...getColumnSearchProps("interviewerName"),
      },
      {
        title: "CALL STATUS",
        dataIndex: "callStatus",
        width: 200,
        isShow: columnStatus?.callStatus?.enabled,
        className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
        filters: [
          {
            text: "Completed",
            value: "Completed",
          },
          {
            text: "Screened",
            value: "Screened",
          },
          {
            text: "Terminated",
            value: "Terminated",
          },
          {
            text: "Call Later",
            value: "Call Later",
          },
          {
            text: "Not Interested",
            value: "Not Interested",
          },
          {
            text: "Appointment Fixed",
            value: "Appointment Fixed",
          },
          {
            text: "Busy",
            value: "Busy",
          },
          {
            text: "Not Reachable / Out of Coverage",
            value: "Not Reachable / Out of Coverage",
          },
          {
            text: "Call Waiting",
            value: "Call Waiting",
          },
          {
            text: "No Response",
            value: "No Response",
          },
          {
            text: "Person Not Available",
            value: "Person Not Available",
          },
          {
            text: "Wrong Number",
            value: "Wrong Number",
          },
          {
            text: "Invalid Number",
            value: "Invalid Number",
          },
          {
            text: "Language Problem",
            value: "Language Problem",
          },
          {
            text: "Call Declined",
            value: "Call Declined",
          },
          {
            text: "Others",
            value: "Others",
          },
        ],
        onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
        filterIcon: () => <FilterOutlined />,
        render: (value, item, index) => {
          return <div>
            {value}       {item.comments && <Tooltip placement="topLeft" title={item.comments} >
              <InfoCircleOutlined className=" ml-1" />
            </Tooltip>}
          </div>
        }
      },
      {
        title: "STATUS",
        dataIndex: "status",
        isShow: columnStatus?.status?.enabled,
        className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
        filters: [
          {
            text: "Approved",
            value: "Approved",
          },
          {
            text: "Pending",
            value: "Pending",
          },
          {
            text: "Completed",
            value: "Completed",
          },
        ],
        render: (value, item, index) => {
          return (
            <>
              {value === "Completed" ? (
                <span className="text-green h-8">Completed</span>
              ) : value === "Approved" ? (
                <span className="text-green h-8">Approved</span>
              ) : (
                <span className="text-red h-8">Pending</span>
              )}
            </>
          );
        },
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        filterIcon: () => <FilterOutlined />,
      },
      {
        title: (
          <span>
            ACTION
            <Tooltip placement="top" title="Dowload Data">
              <Button
                type="link"
                className="float-right p-0"
                onClick={() => {
                  memColumns.pop();
                  downloadCSVFile(
                    tableData,
                    memColumns,
                    `${membersResponses?.survey_x_member[0]?.survey?.project?.name}-${todayDate}`
                  );
                }}
              >
                <DownloadOutlined style={{ fontSize: "1.5rem" }} />
              </Button>
            </Tooltip>
          </span>
        ),
        dataIndex: "action",
        render: (value, record, index) => {
          return (
            <>
              {(tableData[index].status === "Completed" ||
                tableData[index].status === "Approved") && (
                  <Button
                    type="link"
                    className="h-6 p-0"
                    onClick={() => {
                      history.push(
                        `/viewSurvey/responses/project/${projectID}/survey/${surveyID}/External/viewResponseAnswers/member/${record.user.id}/interviewer/${record.priority[0].interviewer.id}`
                      );
                    }}
                  >
                    View responses
                  </Button>
                )}
            </>
          );
        },
      },
    ]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus, tableData])

  return (
    <div className="membersTab w-full ">
      <div className=" float-right mb-5">
        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setStatus(column)
        }} />
      </div>
      <MemberTable
        columns={memColumns}
        tableData={tableData}
        pagination={false}
        rowClassName={(record, index) => "text-sm text-textMedium"}

      />
    </div>
  );
}
export default ResponsesTab;
