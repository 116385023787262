import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Button,
  Table,
  Form,
  Space,
  Input,
  Divider,
  DatePicker,
  notification,
  Modal,
} from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { projectForm } from "../../../../../atoms";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import "./selectMembers.css";
import moment from "moment";
import { formatDateTime } from "../../../../../utils/dateTime";
import {
  UPDATE_SURVEY_DETAILS,
  DELETE_SURVEY_X_MEMBERS,
  INSERT_INTERVIEWER,
  PROJECT_DATES,
  GET_ASSIGNED_MEMBERS,
  GET_MEMBERS,
} from "../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import AssignInterviewer from "./assignInterviewer";
import { useHistory, useParams } from "react-router";
import { RoleContext } from "../../../../../context/RoleContext";
import ColumnEditor from "../../../../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../../../../components/memberColumns";
import MemberTable from "../../../../../components/memberTable";
import { NOTIFY_NEW_MEMBER } from "../../alchemerSurvey/graphql";
import { sendMailToMembers } from "../../clientSurvey/commonUtils";
import SpecialityModalPop from "../../../../../utils/specialitySearch";


function MemberList(props) {
  const { surveyID, projectID } = useParams();
  const memberListDetails = useRecoilValue(projectForm);
  const location = useLocation();
  const [isEnabled, setIsEnabled] = useState(false);
  const [enableDrawer, setEnableDrawer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [startDate, setStartDate] = useState();
  const [dueDate, setDueDate] = useState();
  const [memberIndex, setMemberIndex] = useState();
  const [interviewerLeaveList, setInterviewerLeaveList] = useState([]);
  const [interviewerList, setInterviewerList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [insertmembers] = useMutation(SURVEYxMEMBERS);
  const [deleteMembers] = useMutation(DELETE_SURVEY_X_MEMBERS);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_DETAILS);
  const [insertInterviewer] = useMutation(INSERT_INTERVIEWER);
  const [notifyMembers] = useMutation(NOTIFY_NEW_MEMBER);
  const [selectedMembers, setSelectedMembers] = useState([])
  const [selectedMemberKeys, setSelectedMemberKeys] = useState([])
  const [showInterviewerAlert, setShowInterviewerAlert] = useState(false)
  const savedassitedKrea = localStorage.getItem('assitedKrea');
  const assitedKreainitial = savedassitedKrea
    ? JSON.parse(savedassitedKrea)
    : {
      ...memberColumnStatus,
      action: { enabled: true, label: "Action" }
    }
  const [columnStatus, setStatus] = useState(assitedKreainitial);
  const { role } = useContext(RoleContext);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  const { data: assignedMembers, refetch } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "no-cache",
  });
  const { data: projectDates } = useQuery(PROJECT_DATES, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",
  });

  const history = useHistory();
  let searchInput;

  useEffect(() => {
    localStorage.setItem('assitedKrea', JSON.stringify(columnStatus));
  }, [columnStatus]);
  
  useEffect(() => {
    refetch();
    let newList = [...interviewerList];
    let leavearrayList = [...leaveList];
    if (assignedMembers?.survey && assignedMembers?.survey?.length !== 0) {
      let time =
        moment().isSameOrAfter(
          formatDateTime(assignedMembers?.survey[0]?.start_date)
        ) && props.status !== "draft";
      setIsEnabled(time);
      setStartDate(
        assignedMembers?.survey[0]?.start_date !== null
          ? moment(assignedMembers?.survey[0]?.start_date)
          : null
      );
      setDueDate(
        assignedMembers?.survey[0]?.end_date !== null
          ? moment(assignedMembers?.survey[0]?.end_date)
          : null
      );
    }
    let obj = {};
    if (
      assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member?.length > 0
    ) {
      assignedMembers?.survey_x_member
        // eslint-disable-next-line
        ?.map((el) => {
          let id = el.user.id;
          let status =
            el.survey_x_member_x_interviewers.length > 0
              ? el.survey_x_member_x_interviewers[0].status
              : "active";
          obj[id] = { status };
          return el.user.id;
        });

      let object,
        newObject = [];
      object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      object.forEach((item) => {
        let array = [];
        let leavearray = [];
        item.user.category = item.user.member_type.name;
        item.user.survey_url =
          item?.user?.survey_x_members[0]?.survey_url || null;
        item.user.memberName =
          item?.user?.l_name !== null
            ? item?.user?.f_name + " " + item?.user?.l_name
            : item?.user?.f_name;
        item.user.genderNew =
          item?.user?.gender === null ? "Not updated" : item?.user?.gender;
        item.survey_x_member_x_interviewers
          // eslint-disable-next-line
          .map((interviewID, index) => {
            leavearray.push(interviewID.interviewer_on_leave);
            array.push(interviewID.interviewer_id);
          });
        item.user.leavearray = leavearray;
        item.user.action = array;
        item.user.genderNew =
          item?.user?.gender === null ? "Not updated" : item?.user?.gender;
      });
      object.forEach((item) => {
        newObject.push(userMemberData(item?.user));
      });
      newObject.forEach((item, index) => {
        if (item.leavearray?.length !== 0) {
          item.leavearray[0].length > 1
            ? (leavearrayList[index] = item.leavearray[0])
            : (leavearrayList[index] = item.leavearray);
        } else {
          leavearrayList[index] = [];
        }
      });
      newObject.forEach((item, index) => {
        if (item.action.length !== 0) {
          item.action[0].length > 1
            ? (newList[index] = item.action[0])
            : (newList[index] = item.action);
        } else {
          newList[index] = [];
        }
      });
      setLeaveList(leavearrayList);
      setTableData(newObject);
      setInterviewerList(newList);
    }
    // eslint-disable-next-line
  }, [memberListDetails, assignedMembers, location]);

  // useEffect(() => {
  //   if (members) {
  //     let array = [];
  //     let leavearray = [];
  //     let object = parseMemberData(members, (item) => {
  //       item.survey_url =
  //         item?.survey_x_members[0]?.survey_url || null;
  //       // eslint-disable-next-line
  //       item.survey_x_member_x_interviewers?.map((interviewID, index) => {
  //         leavearray.push(interviewID.interviewer_on_leave);
  //         array.push(interviewID.interviewer_id);
  //       });
  //       item.leavearray = leavearray;
  //       item.action = array;
  //     });
  //     setTableData(object);
  //   }
  // }, [members]);

  const handleClick = () => {
    props.handleToggle(false);
    history.goBack();
  };

  const handleValidationValues = (value, membersIndex, leaveList) => {
    let newArray = [...interviewerList];
    let newLeaveList = [...interviewerLeaveList];
    if (memberIndex === undefined) {
      let selectedMemberId = selectedMembers.map(member => member.id)
      tableData.map((data, dataIndex) => {
        if (selectedMemberId.includes(data.id)) {
          newArray[dataIndex] = value;
          newLeaveList[dataIndex] = leaveList;
        }
      })
      setSelectedMemberKeys([])
    } else {
      newArray[membersIndex] = value;
      newLeaveList[membersIndex] = leaveList;
    }
    setInterviewerList(newArray);
    setInterviewerLeaveList(newLeaveList);
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const handleFormValues = (buttonAction) => {
    let payload = [];
    let interviewerID = [];
    let surveymemberID = [];
    let interviewerError = interviewerList.some((el) => {
      return el.length === 0;
    });
    if (!startDate) {
      setErrorMessage("Please select the starting date of the survey");
    } else if (!dueDate) {
      setErrorMessage("Please select the due date of the survey");
    } else if (moment(startDate).isSameOrAfter(moment(dueDate))) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (formatDateTime(startDate) === formatDateTime(dueDate)) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (
      !isEnabled &&
      moment(startDate).isSameOrBefore(moment()) &&
      moment(startDate).date() === moment().date()
    ) {
      setErrorMessage("Select a Go-live time greater than current time");
    } else if (interviewerError) {
      setErrorMessage("Please select interviewer for all members");
    } else {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            let id = el.user.survey_x_members[0].id;
            surveymemberID.push(id);
          });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member?.forEach((key, index) => {
          payload.push({
            member_id: key.user.id,
            quota: 1,
            state: key.user.survey_x_members[0].state,
            survey_id: parseInt(surveyID),
          });
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el) => {
            el.survey_x_member_x_interviewers &&
              el.survey_x_member_x_interviewers
                // eslint-disable-next-line
                .map((key) => {
                  interviewerID.push(key.id);
                });
          });

      deleteMembers({
        variables: {
          array: surveymemberID,
          Interviewerarray: interviewerID,
          object: payload,
        },
      }).then((res) => {
        let object = [];
        res.data.insert_survey_x_member.returning
          // eslint-disable-next-line
          .map((key, index) => {
            interviewerList &&
              interviewerList.length !== 0 &&
              interviewerList[index]
                // eslint-disable-next-line
                .map((interviewer, i) => {
                  object.push({
                    survey_x_member_id: key.id,
                    status: key.state,
                    interviewer_id: interviewer,
                    interviewer_on_leave:
                      interviewerLeaveList.length === 0
                        ? leaveList[index][i] === undefined
                          ? false
                          : leaveList[index][i]
                        : interviewerLeaveList[index] === undefined
                          ? leaveList[index][i] === undefined
                            ? false
                            : leaveList[index][i]
                          : interviewerLeaveList[index][interviewer] ===
                            "On Leave"
                            ? true
                            : false,
                    priority: i + 1,
                  });
                });
          });
        insertInterviewer({
          variables: {
            objects: object,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              startDate: startDate,
              endDate: dueDate,
              status: buttonAction,
            },
          }).then(() => {
            if (projectDates?.project[0]?.is_online_or_assisted) {
              let memberList = sendMailToMembers(assignedMembers?.survey_x_member, parseInt(surveyID));

              notifyMembers({
                variables: {
                  survey_id: parseInt(surveyID),
                  member_id: memberList.map((member => member.member_id)),
                },
              }).then(() => {
                // notification.success({
                //   message: "Success!",
                //   description: "Mail Notification created!",
                // });
              });

            }
            // resetMemberDetails();
            if (buttonAction === "active") {
              notification.success({
                message: "Success!",
                description: "Survey created!",
              });
              history.push("/projectList/active");
            } else {
              notification.success({
                message: "Success!",
                description: "Saved as draft!",
              });
              history.push("/projectList/active");
            }
          });
        });
      });
    }
  };

  const onComplete = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  /*const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "Category",
      dataIndex: "category",
      filters: [
        {
          text: "Technician",
          value: "Technician",
        },
        {
          text: "Patient",
          value: "Patient",
        },
        {
          text: "Physicians",
          value: "Physicians",
        },
        {
          text: "Consumers",
          value: "Consumers",
        },
      ],
      onFilter: (value, record) => record.category.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "Gender",
      dataIndex: "genderNew",
      filters: [
        {
          text: "Male",
          value: "M",
        },
        {
          text: "Female",
          value: "F",
        },
        {
          text: "Not updated",
          value: "Not updated",
        },
      ],

      onFilter: (value, record) => record.genderNew.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (value, item, index) => {
        return (
          <>
            {interviewerList &&
              interviewerList.length !== 0 &&
              interviewerList[index].length !== 0 ? (
              <Button
                type="link"
                className="text-base p-0 text-left"
                onClick={() => {
                  setEnableDrawer(true);
                  setMemberIndex(index);
                }}
              >
                <span className="w-24">
                  {interviewerList[index].length === 1
                    ? "Interviewer"
                    : "Interviewers"}
                </span>
                <Input
                  value={
                    interviewerList[index]
                      ? interviewerList[index].length
                      : value.length
                  }
                  readOnly
                  className="w-8 rounded-xl ml-2"
                />
              </Button>
            ) : (
              <Button
                type="link"
                className="text-base p-0 text-left"
                onClick={() => {
                  setEnableDrawer(true);
                  setMemberIndex(index);
                }}
              >
                Assign
              </Button>
            )}
          </>
        );
      },
    },
  ];*/



  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus, handleIconClick), ...roleBasedColumns(role, columnStatus),
    ...[{
      title: "Action",
      dataIndex: "action",
      isShow: columnStatus?.action?.enabled,
      className: columnStatus?.action?.enabled ? "table-cell" : "hidden",
      render: (value, item, index) => {
        return (
          <>
            {interviewerList &&
              interviewerList.length !== 0 &&
              interviewerList[index]?.length !== 0 ? (
              <Button
                type="link"
                className="text-base p-0 text-left"
                onClick={() => {
                  setEnableDrawer(true);
                  setMemberIndex(index);
                }}
              >
                <span className="w-24">
                  {interviewerList[index]?.length === 1
                    ? "Interviewer"
                    : "Interviewers"}
                </span>
                <Input
                  value={
                    interviewerList[index]
                      ? interviewerList[index]?.length
                      : value?.length
                  }
                  readOnly
                  className="w-8 rounded-xl ml-2"
                />
              </Button>
            ) : (
              <Button
                type="link"
                className="text-base p-0 text-left"
                onClick={() => {
                  setEnableDrawer(true);
                  setMemberIndex(index);
                }}
              >
                Assign
              </Button>
            )}
          </>
        );
      }
    }]
    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus, interviewerList])

  function disabledDate(current) {
    if (moment().isSameOrAfter(moment(projectDates?.project[0]?.start_date))) {
      return (
        current < moment().startOf("day") ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    } else {
      return (
        current < moment(projectDates?.project[0]?.start_date) ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    }
  }

  function disabledDuebyDate(current) {
    return (
      current < moment(projectDates?.project[0]?.start_date) ||
      current > moment(projectDates?.project[0]?.end_date)
    );
  }

  // function disabledDateTime() {
  //   return {
  //     disabledHours: () => range(0, 24).splice(20, 20),
  //     disabledMinutes: () => range(60, 60),
  //     disabledSeconds: () => [55, 56],
  //   };
  // }
  return (
    <>
      <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div>
      <Form>
        <div className="selectMembers px-10 py-4 w-full">
          <p className="text-textMedium text-lg float-left w-1/2">
            Assign interviewers
          </p>
          <span>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>
          <Form.Item className="float-right">
            <button
              className="text-orange text-base mr-5"
              size="middle"
              type="link"
              onClick={handleClick}
            >
              Edit list
            </button>
            {!!selectedMembers?.length &&
              <Button
                type="primary"
                className="px-8 rounded-md ml-5"
                size="large"
                onClick={() => {
                  setMemberIndex();
                  setEnableDrawer(true);
                }}
              >
                Assign
              </Button>}
          </Form.Item>
          <Form.Item className="block" name="members">
            {/*<Table
              columns={columns}
              dataSource={tableData}
              rowSelection={{
                selectedRowKeys: selectedMemberKeys,
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows, info) => {
                  setSelectedMemberKeys(selectedRowKeys);
                  setSelectedMembers(selectedRows)
                },
                onSelect: (record, selected) => {
                  if (selected) {
                    tableData.map((data, dataIndex) => {
                      if (record.id === (data.id)) {
                        if (interviewerList &&
                          interviewerList.length !== 0 &&
                          interviewerList[dataIndex].length !== 0) {
                          setShowInterviewerAlert(true)
                        }
                      }
                    })
                  }
                }
              }}
              rowClassName={(record, index) => "text-sm text-textMedium"}
              pagination={false}
              rowKey={(record) => record.id}
            />*/}
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              pagination={false}
              rowSelection={{
                selectedRowKeys: selectedMemberKeys,
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows, info) => {
                  setSelectedMemberKeys(selectedRowKeys);
                  setSelectedMembers(selectedRows)
                },
                onSelect: (record, selected) => {
                  if (selected) {
                    tableData.map((data, dataIndex) => {
                      if (record.id === (data.id)) {
                        if (interviewerList &&
                          interviewerList.length !== 0 &&
                          interviewerList[dataIndex].length !== 0) {
                          setShowInterviewerAlert(true)
                        }
                      }
                    })
                  }
                }
              }}
            ></MemberTable>
            <SpecialityModalPop
              titleSpec={`Selected ${selectedColumnNameSpec}`}
              visibleSpec={isModalVisibleSpec}
              onOkSpec={handleModalOkSpec}
              onCancelSpec={handleModalCloseSpec}
              searchQuerySpec={searchQuerySpec}
              handleSearchSpec={handleSearchSpec}
              handleCheckboxChangeSpec={handleCheckboxChangeSpec}
              filteredCheckboxDataSpec={filteredCheckboxDataSpec}
              selectedDataSpec={selectedDataSpec}
              initialSelectedDataSpec={initialSelectedDataSpec}
              isSubmitDisabledSpec={isSubmitDisabledSpec}
              handleResetSpec={handleResetSpec}
            />
          </Form.Item>
          <Divider />
          <p className="text-lg text-textDark">Schedule Survey</p>
          <Form.Item className="text-textLight text-sm w-3/5 float-left">
            <span>GO-LIVE</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                showNow={false}
                // disabledTime={disabledDateTime}
                disabled={isEnabled}
                value={startDate}
                showTime
                className="w-40"
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </span>
            <span className="ml-4">DUE BY</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDuebyDate}
                showNow={false}
                // disabledTime={disabledDateTime}
                disabled={
                  startDate === null || startDate === undefined ? true : false
                }
                value={dueDate}
                showTime
                onChange={(date) => {
                  setDueDate(date);
                }}
              />
            </span>
          </Form.Item>
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={onComplete}
            >
              Schedule
            </Button>
          </Form.Item>
        </div>
      </Form>
      {enableDrawer === true && (
        <AssignInterviewer
          visible={enableDrawer}
          setVisible={setEnableDrawer}
          handleValidationValues={handleValidationValues}
          memberIndex={memberIndex !== undefined ? memberIndex : 0}
          interviewerList={memberIndex !== undefined ? interviewerList[memberIndex] : []}
          selectedMember={memberIndex === undefined ? selectedMembers : []}
          member={memberIndex !== undefined ? tableData[memberIndex] : {}}



        />
      )}
      <Modal
        title={<b>Assign interviewers</b>}
        visible={showInterviewerAlert}
        // okText={<span>Update</span>}
        onOk={() => { }}
        onCancel={() => {
          setShowInterviewerAlert(false)
        }}
        width={300}
        footer={[
          <Button type="primary" key="back" className=" px-8 rounded-md" onClick={() => {
            setShowInterviewerAlert(false)
          }}>
            Ok
          </Button>
        ]}
      >
        <div className="flex justify-start items-center">
          Assigning to previously assigned members will override the interviewers
        </div>
      </Modal>
    </>
  );
}
export default MemberList;
