import { gql } from "@apollo/client";

export const GET_COMPATIBILITY = gql`
  query GET_COMPATIBILITY {
    project_compatibility {
      id
      project_compatibility_text
    }
  }
`;

export const GET_SPECIALITIES = gql`
  query SPECIALITIES {
    specialties {
      id
      speciality_name
      super_specialties{
        id
        name
      }
    }
  }
`;

export const GET_SUPER_SPECIALITIES = gql`
  query SUPER_SPECIALITIES{
    super_specialty(order_by: {specialty_id: asc}){
      id
      name
      specialty_id
    }
  }
`;

export const CREATE_PHYSICIAN = gql`
  mutation PHYSICIAN(
    $respondent_id: bigint
    $title: String
    $firstName: String
    $lastName: String
    $status: String
    $dnc_count: Int
    $primary: Int
    $superSpeciality: Int
    $secondary: Int
    $phone: String
    $email: String
    $password: String
    $meta_date: jsonb
    $state: String
    $city: String
    $pincode: Int
    $gender: String
    $year_of_birth: timestamptz
    $compatibility: jsonb
    $additional_fields: jsonb
    $is_verified: Boolean
  ) {
    insert_user_one(
      object: {
        respondent_id:$respondent_id
        title: $title
        f_name: $firstName
        l_name: $lastName
        status: $status
        dnc_count: $dnc_count
        primary_specialty: $primary
        secondary_specialty: $secondary
        super_specialty_id: $superSpeciality
        phone_number: $phone
        email: $email
        password: $password
        role_id: 6
        meta_date: $meta_date
        gender: $gender
        pincode: $pincode
        year_of_birth: $year_of_birth
        city: $city
        state: $state
        member_type_id: 1
        project_compatibilitys: $compatibility
        additional_data: $additional_fields
        is_verified: $is_verified
      }
    ) {
      id
      meta_date
    }
  }
`;

export const UPDATE_PHYSICIAN = gql`
  mutation UPDATE_PHYSICIAN($object: user_set_input, $id: Int!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      is_active
    }
  }
`;

export const ADD_APPROVAL = gql`
  mutation MyMutation(
    $old_info: jsonb
    $updated_info: jsonb
    $updated_by: Int
    $user_id: Int
  ) {
    insert_approval(
      objects: {
        old_info: $old_info
        updated_info: $updated_info
        updated_by: $updated_by
        user_id: $user_id
      }
    ) {
      affected_rows
    }
  }
`;
