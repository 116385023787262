import {
  // ArrowDownOutlined,
  // ArrowUpOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { Dropdown, Menu, notification, Spin, Table } from "antd";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorPage from "../../components/errorPage";
import { getColumnSearchProps } from "../../utils/table";
import { GET_POC_DASHBOARD } from "./graphql";
import ColumnEditor from "../../components/ColumnEditor";

const PocDashboard = (props) => {
  const history = useHistory();

  const [liveProjects, setLiveProjects] = React.useState([]);
  // eslint-disable-next-line
  const [pocUserId, setPocUserId] = useState(null);
  const savedpocDashboard = localStorage.getItem('pocDashboard');
  const pocDashboardinitial = savedpocDashboard
    ? JSON.parse(savedpocDashboard)
    : {
      "category": { enabled: true, label: "Manager Name" },
      "sample": { enabled: true, label: "Type" },
      "start_date": { enabled: true, label: "Assigned Members" },
      "end_date": { enabled: true, label: "Start Date" },
      "completed": { enabled: true, label: "End Date" },
      "screened": { enabled: true, label: "Terminated" },
      "terminated": { enabled: true, label: "Terminated" },
    }
  const [columnStatus, setStatus] = useState(pocDashboardinitial);

  useEffect(() => {
    localStorage.setItem('pocDashboard', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let user_id = jwtDecode(localStorage.getItem("token"))[
      "https://hasura.io/jwt/claims"
    ]["x-hasura-user-id"];
    if (user_id) {
      setPocUserId(user_id);
    }
  }, []);

  const { data, loading, error } = useQuery(GET_POC_DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      // setLiveProjects(data?.project || []);
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  useEffect(() => {
    let newObj = [];
    if (data) {
      let object = JSON.parse(JSON.stringify(data?.project));
      object.forEach((item) => {
        let val =
          item?.survey_type !== null && item?.survey_type !== ""
            ? item?.survey_type === "Third_Party_survey"
              ? "- " + item?.third_party_survey_name
              : "- " + item?.survey_type
            : "";
        item.category = item?.projectTypeByProjectType?.name + " " + val;
        item.completed = item?.surveys[0]?.completed?.aggregate?.count || 0;
        item.screened = item?.surveys[0]?.screened?.aggregate?.count || 0;

        item.terminated = item?.surveys[0]?.quotaFull?.aggregate?.count || 0;
        newObj.push(item);
      });
      setLiveProjects(newObj);
    }
  }, [data]);

  const columns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
    },
    {
      title: "CATEGORY",
      key: "projectTypeByProjectType.name",
      dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("category", "Category"),
      render: (text, record, index) => (
        <>
          <span> {text}</span>
          <div>
            {record.member_types !== null && record.member_types[0] === 1 && (
              <span className="text-xs text-textLight font-normal">
                Physician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 2 && (
              <span className="text-xs text-textLight font-normal">
                Technician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 3 && (
              <span className="text-xs text-textLight font-normal">
                Patient
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 4 && (
              <span className="text-xs text-textLight font-normal">
                Consumer
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "SAMPLE",
      key: "sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (text, record, index) => {
        // let avgPerDay = Math.ceil(
        //   record.sample /
        //     moment(record?.end_date).diff(moment(record?.start_date), "days")
        // );
        // let days = moment().diff(moment(record?.start_date), "days");
        return (
          <div className="text-textMedium text-h8">
            <span>
              {record?.surveys?.reduce(
                (arr, cur) =>
                  arr +
                  (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                0
              )}{" "}
              / {record.sample}
            </span>
            {/* <span>
              {days > 0 &&
              avgPerDay * days >
                record?.surveys?.reduce(
                  (arr, cur) =>
                    arr +
                    (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                  0
                ) ? (
                <ArrowUpOutlined style={{ color: "green" }} className="ml-2" />
              ) : null}
            </span> */}
            {/* <span>
              {days > 0 &&
              avgPerDay * days <
                record?.surveys?.reduce(
                  (arr, cur) =>
                    arr +
                    (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                  0
                ) ? (
                <ArrowDownOutlined style={{ color: "red" }} className="ml-2" />
              ) : null}
            </span> */}
          </div>
        );
      },
    },
    {
      title: "START DATE",
      key: "start_date",
      dataIndex: "start_date",
      className: columnStatus?.start_date?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      dataIndex: "end_date",
      className: columnStatus?.end_date?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "COMPLETED",
      key: "completed",
      dataIndex: "completed",
      className: columnStatus?.completed?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("completed", "Completed"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "SCREENED",
      key: "screened",
      dataIndex: "screened",
      className: columnStatus?.screened?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("screened", "screened"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "TERMINATED",
      key: "terminated",
      dataIndex: "terminated",
      className: columnStatus?.terminated?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("terminated", "terminated"),
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{text}</div>
      ),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  if (record?.surveys.length) {
                    history.push(
                      `/pocs/project/${record.id}/project-details/survey/${record.surveys[0].id}`
                    );
                  } else {
                    history.push(`/pocs/project/${record.id}/project-details`);
                  }
                }}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];

  if (error) {
    return <ErrorPage />;
  }

  return (
    <Spin spinning={loading}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-textMedium text-h4 font-semibold">
            Projects
          </span>
          <span>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>

        </div>
        <Table
          columns={columns}
          dataSource={liveProjects}
          className="bg-white shadow"
          pagination={
            loading
              ? false
              : {
                showTotal: (total, range) => {
                  return (
                    <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                  );
                },
              }
          }
        />
      </div>
    </Spin>
  );
};

export default PocDashboard;
