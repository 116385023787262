import { useQuery } from "@apollo/client";
import { Layout, Menu, Tabs } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import LocationHierarchy from "../../../../components/locationHierarchy";
import { GET_QUESTIONS } from "../../prf/questionnaire/graphql";
import { SURVEY_DATES } from "./graphql";
import AssignMembers from "./assignMembers";
import EmailTemplate from "./email-template";
import Overview from "./overview/overview";
import Questionnaire from "./questionnaire/index";

const { Content } = Layout;
const { TabPane } = Tabs;
const MenuContainer = styled.div`
  .ant-menu-item {
    color: #c4c4c4;
    &.ant-menu-item-selected {
      color: #e08036;
    }
  }
  .ant-tabs-ink-bar-animated {
    max-height: 42px;
  }

  .ant-tabs-tab-active .ant-menu-item-group-title div {
    color: #e08036 !important;
  }
  .ant-menu-item-selected {
    background: transparent !important;
  }
`;
function CreateSurvey() {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tabPane = [
    "overview",
    "questionnaire",
    "assignmembers",
    "emailtemplate",
  ];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [question, setQuestion] = useState([]);
  const [surveyData, setSurveyData] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState();
  // eslint-disable-next-line
  const { dataSource1, refetch: questionRefetch } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: surveyID ? parseInt(surveyID) : 0 },
    onCompleted(data) {
      setQuestion(data.questions);
    },
  });
  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );
  const [enableSendTo, setEnableSendTo] = useState(false);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setStatus(surveyDates?.survey[0]?.status);
    }
  }, [surveyDates]);

  useEffect(() => {
    if (location.pathname.split("/")[5] === "questionnaire") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[5] === "assignmembers" || location.pathname.split("/")[5] === "assigninterviwers") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[5] === "overview") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[5] === "emailtemplate") {
      setActiveTabPane("4");
    }

    if (question.length !== 0) {
      setEnableSendTo(true);
    }
  }, [location.pathname, question]);

  useEffect(() => {
    questionRefetch();
    surveyDatesRefetch();
    // eslint-disable-next-line
  }, []);

  const handleEnable = (value) => {
    setEnableSendTo(value);
  };
  const handleActiveTabPane = (key) => {
    if (parseInt(key) === 2) {
      if (moment().isSameOrAfter(startDate)) {
        location.pathname.split("/")[3] === "create-survey"
          ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/${tabPane[key - 1]
            }/survey/${surveyID}/preview`
          )
          : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/${tabPane[key - 1]
            }/survey/${surveyID}/preview`
          );
      } else {
        location.pathname.split("/")[3] === "create-survey"
          ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/${tabPane[key - 1]
            }/survey/${surveyID}`
          )
          : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/${tabPane[key - 1]
            }/survey/${surveyID}`
          );
      }
    } else {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
          `/project/${projectID}/create-survey/${surveyType}/${tabPane[key - 1]
          }/survey/${surveyID}`
        )
        : history.push(
          `/project/${projectID}/edit-survey/${surveyType}/${tabPane[key - 1]
          }/survey/${surveyID}`
        );
    }

    setActiveTabPane(key);
  };

  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10">
        <LocationHierarchy id={projectID} />
        <div className="absolute text-base text-gray-700 pt-2">
          {location.pathname.split("/")[3] === "create-survey" && (
            <p>Create survey</p>
          )}
          {location.pathname.split("/")[3] === "edit-survey" && (
            <p>Edit survey</p>
          )}
        </div>

        <MenuContainer
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            tabPosition="left"
            size="large"
            className="text-textExtraLight"
            tabBarStyle={{ width: "200px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane tab="Overview" key="1">
              <Overview
                startDate={startDate}
                status={status}
                onSurveyData={(data) => {
                  setSurveyData(data);
                }}
              />
            </TabPane>
            <TabPane
              tab="Questionnaire"
              key="2"
              disabled={surveyID ? false : true}
            >
              <Questionnaire
                handleEnable={handleEnable}
                startDate={startDate}
                status={status}
              />
            </TabPane>
            <TabPane
              className="w-full customMenu pt-0"
              disabled={surveyID ? false : true}
              tab={
                <Menu
                  className="text-base border-r-0 pl-0"
                  disabled={surveyID ? false : true}
                  selectedKeys={
                    location.pathname.includes("emailtemplate/inviteemail")
                      ? ["inviteEmail"]
                      : location.pathname.includes(
                        "emailtemplate/reminderemail"
                      )
                        ? ["reminderEmail"]
                        : []
                  }
                >
                  <Menu.ItemGroup
                    key="g2"
                    className="text-sm"
                    title={
                      <div className="text-base -ml-4 text-textExtraLight">
                        Email templates
                      </div>
                    }
                  >
                    <Menu.Item
                      key="inviteEmail"
                      className="flex items-center"
                      onClick={() => {
                        history.push(
                          `/project/${projectID}/${location.pathname.split("/")[3]
                          }/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
                        );
                      }}
                    >
                      <div className="text-sm  ">Survey invite email</div>
                    </Menu.Item>
                    <Menu.Item
                      key="reminderEmail"
                      className="flex items-center"
                      onClick={() => {
                        history.push(
                          `/project/${projectID}/${location.pathname.split("/")[3]
                          }/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                        );
                      }}
                    >
                      <div className="text-sm  ">Reminder email</div>
                    </Menu.Item>
                  </Menu.ItemGroup>
                </Menu>
              }
              key="4"
            >
              <EmailTemplate
                surveyId={surveyID}
                surveyData={surveyData}
                status={status}
                startDate={startDate}
              />
            </TabPane>
            <TabPane
              tab="Send to"
              key="3"
              className="mt-0"
              disabled={surveyID ? (enableSendTo ? false : true) : true}
            >
              <AssignMembers status={status} />
            </TabPane>
          </Tabs>
        </MenuContainer>
      </Content>
    </Layout>
  );
}

export default CreateSurvey;
