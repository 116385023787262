import {
  CaretDownOutlined,
  CaretUpOutlined,
  StarOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  DatePicker,
  Form,
  Input,
  List,
  notification,
  Rate,
  Select,
  Slider,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import dragIcon from "../../../../../assets/dragIcon.svg";
import enterText from "../../../../../assets/enterText.svg";
import jumpTo from "../../../../../assets/jumpTo.svg";
import screened from "../../../../../assets/screened.svg";
import { UPDATE_SURVEY_STATUS } from "../graphql";
import { GET_QUESTIONS } from "./graphql";
import moment from "moment";

const { Option } = Select;

function Preview(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const [isvalidation, setIsValidation] = useState([]);
  const [label, setLabel] = useState([]);
  const [expand, setExpand] = useState(true);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  // eslint-disable-next-line
  const { dataSource1 } = useQuery(GET_QUESTIONS, {
    skip: !surveyID,
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setData(data.questions);
    },
  });

  useEffect(() => {
    let newCopy = [...isvalidation];

    data &&
      data.forEach((val, index) => {
        if (val.validation !== null) {
          for (let [key, value] of Object.entries(val.validation)) {
            if (key !== "questionMandatory" && value !== null) {
              newCopy[index] = true;
              break;
            } else {
              newCopy[index] = false;
            }
          }
        } else {
          newCopy[index] = false;
        }
        setIsValidation(newCopy);
      });
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    let newarray = [...label];
    data &&
      // eslint-disable-next-line
      data.map((option, i) => {
        // eslint-disable-next-line
        let array = [];
        // eslint-disable-next-line
        option.questions_options.map((item, index) => {
          let copy = [];
          if (
            item.options.rating_labels !== undefined &&
            item.options.rating_labels !== null
          ) {
            // eslint-disable-next-line
            for (let [key, value] of Object.entries(
              item.options.rating_labels
            )) {
              copy.push(value);
            }
          }
          array[index] = copy;
        });
        newarray[i] = array;

        setLabel(newarray);
      });
    // eslint-disable-next-line
  }, [data]);
  const handleFormValues = (buttonAction) => {
    updateSurvey({
      variables: {
        id: parseInt(surveyID),
        status:
          buttonAction === "draft"
            ? "draft"
            : moment().isSameOrAfter(props.startDate)
            ? props.status === "draft"
              ? "draft"
              : "active"
            : props.status === "active"
            ? "active"
            : "draft",
      },
    }).then(() => {
      if (buttonAction === "active") {
        location.pathname.split("/")[3] === "edit-survey"
          ? history.push(
              `/project/${projectID}/edit-survey/${surveyType}/assignmembers/survey/${surveyID}`
            )
          : history.push(
              `/project/${projectID}/create-survey/${surveyType}/assignmembers/survey/${surveyID}`
            );
      } else {
        notification.success({
          message: "Success!",
          description: "Saved as draft!",
        });
        history.push(`/projectList/active`);
      }
    });
  };

  const handleNext = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const handleClick = () => {
    if (!props.isStarted) {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
          );
    } else {
      location.pathname.split("/")[3] === "create-survey"
        ? history.push(
            `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/addLogic`
          )
        : history.push(
            `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/addLogic`
          );
    }
  };
  const Icons = (prop) => (
    <>
      {prop.answer.more_info !== null && (
        <img
          src={enterText}
          alt="enterText"
          className="float-left pt-1 w-5 ml-4"
        />
      )}
      {prop.answer.logic && prop.answer.logic.jumpToQuestion !== null && (
        <img src={jumpTo} alt="jumpTo" className="float-left pt-1 w-5 ml-4" />
      )}
      {prop.answer.logic && prop.answer.logic.screenExit === true && (
        <img
          src={screened}
          alt="screened"
          className="float-left pt-1 w-5 ml-4"
        />
      )}
    </>
  );
  const Multipleanswer = (props) => {
    return (
      <div>
        {props.item.questions_options.map((answer) => (
          <div
            className="py-4 pl-9 pr-5 mb-3"
            style={{ width: "100%", height: "3rem" }}
          >
            <Input
              className="w-3/4 float-left"
              prefix={answer.label}
              readOnly
            />
            <Icons answer={answer} />
          </div>
        ))}
      </div>
    );
  };
  const Dropdownanswer = (props) => {
    return (
      <div>
        {props.item.questions_options.map((answer) => (
          <div
            className="py-4 pl-9 pr-5 mb-3"
            style={{ width: "100%", height: "3rem" }}
          >
            <p className="w-1/4 float-left">{answer.label}</p>
            {props.item.question_type.name === "Dropdown" && (
              <Select className="w-2/4 float-left" placeholder="Select">
                {answer.options.dropdownValue !== undefined &&
                  answer.options.dropdownValue.map((dropdownValues) => (
                    <Option>{dropdownValues}</Option>
                  ))}
              </Select>
            )}

            {props.item.question_type.name === "Text area" && (
              <Input.TextArea className="w-2/4" readOnly />
            )}
            {(props.item.question_type.name === "Text box - text" ||
              props.item.question_type.name === "Text box - numerical") && (
              <Input
                type={
                  props.item.question_type.name === "Text box - text"
                    ? "text"
                    : "number"
                }
                className="w-2/4 float-left"
                readOnly
              />
            )}
            <Icons answer={answer} />
          </div>
        ))}
      </div>
    );
  };
  const Rankinganswer = (props) => {
    return (
      <div>
        {props.item.questions_options.map((answer, index) => (
          <div
            className="py-4 pl-9 pr-5 mb-3"
            style={{ width: "100%", height: "3rem" }}
          >
            <span className="w-1/4 float-left text-textLight ">
              {index + 1}
            </span>
            <Input
              prefix={
                <span>
                  <img
                    src={dragIcon}
                    alt="drag"
                    className="float-left pt-1 mr-4 w-5"
                  />
                  {answer.label}
                </span>
              }
              className="w-2/4 float-left"
              readOnly
            />
          </div>
        ))}
      </div>
    );
  };
  const Starratinganswer = (props) => {
    return (
      <div>
        {label.length !== 0 &&
          props.item.questions_options.map((answer, i) => (
            <div
              className="py-4 pl-9 pr-5 mb-3"
              style={{ width: "100%", height: "3rem" }}
            >
              <p className="w-1/4 float-left">{answer.label}</p>
              <Rate
                tooltips={label[props.item.index - 1][i]}
                className="w-2/4"
                disabled
                character={<StarOutlined className="text-textMedium px-4" />}
              />
            </div>
          ))}
      </div>
    );
  };
  let steps;
  const Slideranswer = (props) => {
    let markValue = [];
    // eslint-disable-next-line
    props?.item?.questions_options.map((ele, i) => {
      // eslint-disable-next-line
      let obj = {};
      let length =
        ele.options.sliderValue.sliderText !== null &&
        ele.options.sliderValue.sliderText.length;
      steps = 100 / (length - 1);
      let point = 0;

      ele.options.sliderValue.sliderText !== null &&
        ele.options.sliderValue.sliderText.forEach((value, index) => {
          if (index + 1 === length) {
            point = 100;
          } else if (index === 0) {
            point = 0;
          } else {
            point += steps;
          }
          obj = { ...obj, [point]: value };
        });
      markValue[i] = obj;
    });

    // function formatter(value) {
    //   return `${value / 100}`;
    // }

    return (
      <div>
        {props.item.questions_options.map((answer, i) => (
          <div className="py-4 pl-9 pr-5 w-3/4">
            <h4>{answer.label}</h4>
            {answer.options.sliderValue.sliderType === "numerical" ? (
              <Slider
                tipFormatter={null}
                marks={{
                  0: {
                    label: (
                      <span>{answer.options.sliderValue.sliderMinimum}</span>
                    ),
                  },

                  100: {
                    label: (
                      <span>{answer.options.sliderValue.sliderMaximum}</span>
                    ),
                  },
                }}
              />
            ) : (
              <Slider marks={markValue[i]} tipFormatter={null} />
            )}
          </div>
        ))}
      </div>
    );
  };

  const GetFields = () => {
    return (
      <Form className="w-full">
        <Form.Item className="block">
          <List
            itemLayout="horizontal"
            dataSource={data}
            rowKey={(item) => item?.id}
            renderItem={(item, index) => (
              <div className="border border-borderGray rounded-md my-4 p-4">
                <div>
                  <span className="float-left">Q{index + 1}</span>
                  <span
                    style={{ width: "71.15%" }}
                    className="inline-block ml-4 float-left "
                  >
                    {item.question}
                  </span>
                  {isvalidation[index] === true && (
                    <img
                      src={enterText}
                      alt="enterText"
                      style={{ marginLeft: "1rem" }}
                      className="float-left pt-1 w-5 "
                    />
                  )}
                </div>
                {item.question_type && (
                  <div className="clear-both">
                    {item.question_type.name === "Multiple Choice" && (
                      <Multipleanswer item={item} />
                    )}
                    {item.question_type.name === "Dropdown" && (
                      <Dropdownanswer item={item} />
                    )}
                    {(item.question_type.name === "Text box - numerical" ||
                      item.question_type.name === "Text box - text" ||
                      item.question_type.name === "Text area") && (
                      <Dropdownanswer item={item} />
                    )}
                    {item.question_type.name === "Ranking" && (
                      <Rankinganswer item={item} />
                    )}
                    {item.question_type.name === "Star rating" && (
                      <Starratinganswer item={item} />
                    )}
                    {item.question_type.name === "Slider" && (
                      <Slideranswer item={item} />
                    )}
                    {item.question_type.name === "Percentage" && (
                      <div>
                        {item.questions_options.map((answer) => (
                          <div
                            className="py-4 pl-9 pr-5 mb-3"
                            style={{ width: "100%", height: "3rem" }}
                          >
                            <p className="w-1/4 float-left">{answer.label}</p>
                            <Input
                              className="w-1/4 float-left"
                              placeholder="Enter Percentage"
                              readOnly
                            />
                            <Icons answer={answer} />
                          </div>
                        ))}
                      </div>
                    )}
                    {item.question_type.name === "Date & Time" && (
                      <>
                        {item.questions_options.map((answer) => (
                          <div
                            className="py-4 pl-9 pr-5 mb-3"
                            style={{ width: "100%", height: "3rem" }}
                          >
                            <p className="w-1/4 float-left">
                              Date
                              {answer.options.includeTime === true ? (
                                <span className="ml-2 text-sm text-textLight">
                                  (Time Included)
                                </span>
                              ) : null}
                            </p>
                            <DatePicker
                              className="w-1/4 float-left"
                              showTime={answer.options.includeTime}
                              showNow
                            />
                            <Icons answer={answer} />
                          </div>
                        ))}
                      </>
                    )}
                    {item.question_type.name === "File upload" && (
                      <div className="py-4 px-9">
                        <Upload>
                          <Button>Upload Document</Button>
                        </Upload>
                      </div>
                    )}
                    {item.question_type.name === "Email" && (
                      <div className="py-4 px-9">
                        <Input readOnly className="w-3/4" />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          />
        </Form.Item>
      </Form>
    );
  };

  return (
    <Form name="create survey">
      {props.viewcollapse === true && (
        <div span={24} className="text-base w-full ml-0 mr-0 float-left">
          <Button
            type="link"
            size="large"
            onClick={() => {
              setExpand(!expand);
            }}
          >
            {expand ? (
              <div>
                <span className="w-40 float-left">
                  {" "}
                  Collapse all questions{" "}
                </span>
                <CaretUpOutlined />
              </div>
            ) : (
              <div>
                <span className="w-40 float-left">Expand all questions</span>
                <CaretDownOutlined />
              </div>
            )}
          </Button>
        </div>
      )}
      {expand === true && <GetFields />}
      {props.enableButton === true && (
        <Form.Item className="float-right mt-8">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={handleClick}
          >
            Back
          </Button>
          <Button
            htmlType="button"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
              props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-28"
            onClick={handleNext}
          >
            Next
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
export default Preview;
