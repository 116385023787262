import React from "react";
import { Form } from "antd";
import { formatDateTOString } from "../../utils/dateTime";

const ProjectDetail = ({ data }) => {

  const convertHTML = (html) => {
    var temp = document.createElement("span");
    temp.innerHTML = html;
    return temp.textContent;
  };
  return (
    <div className="px-3">
      <Form
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 16 }}
        labelAlign="left"
        colon={false}
      >
        <Form.Item label="Client" className="p-2">
          {data?.project_by_pk?.client?.f_name}{" "}
          {data?.project_by_pk?.client?.l_name}
        </Form.Item>
        <Form.Item label="Member type" className="p-2">
          {data?.project_by_pk?.member_type?.name}
        </Form.Item>
        <Form.Item label="Project type" className="p-2">
          {(data?.project_by_pk?.projectTypeByProjectType?.name === 'Assisted' || data?.project_by_pk?.projectTypeByProjectType?.name === 'Online') && data?.project_by_pk?.is_online_or_assisted ? data?.project_by_pk?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : data?.project_by_pk?.projectTypeByProjectType?.name}
        </Form.Item>
        <Form.Item label="Start date" className="p-2">
          {formatDateTOString(data?.project_by_pk?.start_date)}
        </Form.Item>
        <Form.Item label="End date" className="p-2">
          {formatDateTOString(data?.project_by_pk?.end_date)}
        </Form.Item>
        <Form.Item label="Description" className="p-2">
          {convertHTML(data?.project_by_pk?.description)}
        </Form.Item>
        <Form.Item
          label="Total budget"
          style={{ background: "#FAFAFA" }}
          className="p-2"
        >
          {data?.project_by_pk?.total_budget}
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProjectDetail;
