import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Space,
  Input,
  Modal,
  Tag,
  Select,
  Tooltip,
} from "antd";
import { useHistory, useParams, useLocation } from "react-router";
import {
  SearchOutlined,
  FilterOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import { useMutation, useQuery } from "@apollo/client";
import verticalDots from "../../../assets/verticalDots.svg";
import { GET_MEMBERS_RESPONSES_ONLINE, GET_MEMBERS_RESPONSES_ONLINE_KREA, UPDATE_STATUS_ONLINE } from "../graphql";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { downloadCSVFile } from "../../../utils/downloadCSVFile";
import ColumnEditor from "../../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, parseMemberData, userMemberData } from "../../../components/memberColumns"
import MemberTable from "../../../components/memberTable";
const { Option } = Select;

function ResponsesTab() {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID, projectID, surveyType } = useParams();
  const [tableData, setTableData] = useState([]);
  const [status, setStatus] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // eslint-disable-next-line
  const [role, setRole] = useState(null);
  const history = useHistory();
  const location = useLocation();
  let searchInput;
  const todayDate = moment().format("DD/MMM/YYYY");
  const savedviewProjectOnline = localStorage.getItem('viewProjectOnline');
  const viewProjectOnlineinitial = savedviewProjectOnline
    ? JSON.parse(savedviewProjectOnline)
    : {
      ...memberColumnStatus,
      "interviewerName": { enabled: true, label: "Interviewer Name" },
      "callStatus": { enabled: true, label: "Call Status" },
      "status": { enabled: true, label: "Status" },
    }
  const [columnStatus, setColStatus] = useState(viewProjectOnlineinitial);
  delete columnStatus.interviewer;
  useEffect(() => {
    localStorage.setItem('viewProjectOnline', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const { data: membersResponses } = useQuery(GET_MEMBERS_RESPONSES_ONLINE_KREA, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  const [updateStatus] = useMutation(UPDATE_STATUS_ONLINE, {
    refetchQueries: [
      {
        query: GET_MEMBERS_RESPONSES_ONLINE_KREA,
        variables: {
          id: parseInt(surveyID),
          // member_id: parseInt(memberID),
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  /*useEffect(() => {
    let copy = [];
    if (membersResponses) {
      let object = JSON.parse(
        JSON.stringify(membersResponses?.survey_x_member)
      );
      object.forEach((item, i) => {
        item.memberName =
          item?.user?.l_name !== null
            ? item?.user?.f_name + " " + item?.user?.l_name
            : item?.user?.f_name;
        item.email = item.user.email;
        item.phone_number = item.user.phone_number;
        item.surveyLink = `${baseURL}/survey/${surveyID}/member/${item.user.id}`;
        item.status = item.state;
        item.index = i;
        if (
          item.priority.length !== 0 &&
          location.pathname.split("/")[7] === "Assisted-Krea"
        ) {
          item.interviewerName =
            item.priority[0].interviewer.l_name !== null
              ? item.priority[0].interviewer.f_name +
              " " +
              item.priority[0].interviewer.l_name
              : item.priority[0].interviewer.f_name;
          item.callStatus =
            item.priority[0].status === "active"
              ? "Status pending"
              : item.priority[0].status;
        }
        item.comments = item.priority[0]?.comments?.description || "";
        copy[i] = item.state;
      });
      setStatus(copy);
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [membersResponses]);*/

  useEffect(() => {
    let copy = [], newObject = [];
    if (membersResponses) {
      let object = JSON.parse(
        JSON.stringify(membersResponses?.survey_x_member)
      );
      object.forEach((item, i) => {
        newObject.push(userMemberData(item?.user, (data) => {
          data.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.user.id}`;
          data.userId = data.id;
          data.id = item.id;
          data.status = item.state;
          data.index = i;
          if (
            item.priority.length !== 0
            // &&
            // (location.pathname.split("/")[7] === "Assisted-Krea" ||
            //   location.pathname.split("/")[7] === "Online-Krea" ||
            //   location.pathname.split("/")[7] === "Online" ||
            //   location.pathname.split("/")[7] === "Online-Client")
          ) {
            data.interviewerName =
              item.priority[0].interviewer.l_name !== null
                ? item.priority[0].interviewer.f_name +
                " " +
                item.priority[0].interviewer.l_name
                : item.priority[0].interviewer.f_name;
            data.callStatus = item.priority[0].status === "active" ? "Status pending" : item.priority[0].status;
          }
          data.comments = item.priority[0]?.comments?.description || "";
          copy[i] = item.state;
        }));
      });
      setStatus(copy);
      setTableData(newObject);
    }
  }, [membersResponses]);

  // function handleDownloadExcel() {
  //   const header = [
  //     "Name",
  //     "Email",
  //     "Phone_number",
  //     "Krea_Survey_Link",
  //     "Status",
  //   ];
  //   const body = [];
  //   const todayDate = moment().format("DD/MMM/YYYY");
  //   tableData.map((item) => {
  //     return body.push({
  //       Name: item.memberName,
  //       Email:
  //         role === "manager" && item.email !== null && item.email !== undefined
  //           ? `${item.email.substring(0, 3)}XXX@${item.email.split("@")[1]}`
  //           : item.email,
  //       Phone_number:
  //         role === "manager" &&
  //         item.phone_number !== null &&
  //         item.phone_number !== undefined
  //           ? item.phone_number === "Not updated"
  //             ? "Not updated"
  //             : `${item.phone_number.substring(0, 3)}XXXXXXX`
  //           : item.phone_number,
  //       // Client_Link:
  //       Krea_Survey_Link: `${baseURL}/survey/${surveyID}/member/${item.user.id}`,
  //       Status:
  //         item.state === "active" || item.state === "in-progress"
  //           ? "status pending"
  //           : item.state,
  //     });
  //   });
  //   downloadExcel({
  //     fileName: `${membersResponses?.survey[0].project?.name}-${todayDate}`,
  //     sheet: `${membersResponses?.survey[0].project?.name}-${todayDate}`,
  //     tablePayload: {
  //       header,
  //       body: body,
  //     },
  //   });
  // }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = (id, index) => {
    updateStatus({
      variables: {
        id: id,
        status: status[index],
      },
    });
    setIsModalVisible(false);
  };

  /*const columns = [
    {
      title: "NAME",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      className: columnStatus?.email?.enabled ? "table-cell" : "hidden",
      key: "email",
      width: 200,
      ...getColumnSearchProps("email", "email"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone_number",
      className: columnStatus?.phone_number?.enabled ? "table-cell" : "hidden",
      key: "phone",
      ...getColumnSearchProps("phone_number", "phone"),
      width: 200,
      render: (text) => (
        <div className="flex flex-col items-start justify-start">{text}</div>
      ),
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "surveyLink",
      className: columnStatus?.surveyLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("surveyLink"),
    },
    {
      title: "INTERVIEWER",
      dataIndex: "interviewerName",
      className: columnStatus?.interviewerName?.enabled ? "table-cell" : "hidden",
      hidden:
        location.pathname.split("/")[7] === "Assisted-Krea" ? false : true,
      ...getColumnSearchProps("interviewerName"),
    },
    {
      title: "CALL STATUS",
      dataIndex: "callStatus",
      className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
      hidden:
        location.pathname.split("/")[7] === "Assisted-Krea" ? false : true,
      filters: [
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Screened",
          value: "Screened",
        },
        {
          text: "Terminated",
          value: "Terminated",
        },
        {
          text: "Call Later",
          value: "Call Later",
        },
        {
          text: "Not Interested",
          value: "Not Interested",
        },
        {
          text: "Appointment Fixed",
          value: "Appointment Fixed",
        },
        {
          text: "Busy",
          value: "Busy",
        },
        {
          text: "Not Reachable / Out of Coverage",
          value: "Not Reachable / Out of Coverage",
        },
        {
          text: "Call Waiting",
          value: "Call Waiting",
        },
        {
          text: "No Response",
          value: "No Response",
        },
        {
          text: "Person Not Available",
          value: "Person Not Available",
        },
        {
          text: "Wrong Number",
          value: "Wrong Number",
        },
        {
          text: "Invalid Number",
          value: "Invalid Number",
        },
        {
          text: "Language Problem",
          value: "Language Problem",
        },
        {
          text: "Call Declined",
          value: "Call Declined",
        },
        {
          text: "Others",
          value: "Others",
        },
      ],
      onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      width: 200,
      render: (value, item, index) => {
        return <div>
          {value}       {item.comments && <Tooltip placement="topLeft" title={item.comments} >
            <InfoCircleOutlined className=" ml-1" />
          </Tooltip>}
        </div>
      }
    },

    {
      title: "STATUS",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      render: (text) => (
        <>
          {text === "screened" && (
            <span className={"text-blue text-base"}>Screened</span>
          )}
          {(text === "completed" || text === "Approved") && (
            <span className={"text-green text-base"}>Completed</span>
          )}
          {text === "cancelled" && (
            <span className={"text-red text-base"}>Terminated</span>
          )}
          {text === "quotaFull" && (
            <span className={"text-red text-base"}>Quota Full</span>
          )}
          {text === "active" && (
            <span className={"text-textExtraLight text-base"}>
              Status pending
            </span>
          )}
          {text === "in-progress" && (
            <span className={"text-textExtraLight text-base"}>In progress</span>
          )}
        </>
      ),
      filters: [
        {
          text: "Screened",
          value: "screened",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Terminated",
          value: "cancelled",
        },
        {
          text: "Status pending",
          value: "active",
        },
        {
          text: "In progress",
          value: "in-progress",
        },
        {
          text: "Quota full",
          value: "quotaFull",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
    {
      title: (
        <span>
          ACTION
          <Tooltip placement="top" title="Dowload Data">
            <Button
              type="link"
              className="float-right p-0"
              onClick={() => {
                columns.pop();
                downloadCSVFile(
                  tableData,
                  columns,
                  `${membersResponses?.survey[0].project?.name}-${todayDate}`
                );
              }}
            >
              <DownloadOutlined style={{ fontSize: "1.5rem" }} />
            </Button>
          </Tooltip>
        </span>
      ),
      dataIndex: "action",
      render: (text, record, index) => (
        <>
          <Dropdown
            overlay={menu({
              index: index,
              record: record,
              handleCancel: handleCancel,
              handleOk: handleOk,
              showModal: showModal,
              isModalVisible: isModalVisible,
            })}
            placement="bottomRight"
            disabled={record.state === "active" ? true : false}
            trigger={["click"]}
          >
            <Button type="link" size="large">
              <img src={verticalDots} alt="vertical_Dots" />
            </Button>
          </Dropdown>
        </>
      ),
    },
  ].filter((item) => !item.hidden);*/

  const memColumns = useMemo(() => {
    // return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),
    const customColumns = [
      {
        title: "INTERVIEWER",
        dataIndex: "interviewerName",
        isShow: columnStatus?.interviewerName.enabled,
        className: columnStatus?.interviewerName?.enabled ? "table-cell" : "hidden",
        hidden:
          location.pathname.split("/")[7] === "Assisted-Krea" ? false : true,
        ...getColumnSearchProps("interviewerName"),
      },
      {
        title: "CALL STATUS",
        dataIndex: "callStatus",
        isShow: columnStatus?.callStatus.enabled,
        className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
        hidden:
          location.pathname.split("/")[7] === "Assisted-Krea" ? false : true,
        filters: [
          {
            text: "Completed",
            value: "Completed",
          },
          {
            text: "Screened",
            value: "Screened",
          },
          {
            text: "Terminated",
            value: "Terminated",
          },
          {
            text: "Call Later",
            value: "Call Later",
          },
          {
            text: "Not Interested",
            value: "Not Interested",
          },
          {
            text: "Appointment Fixed",
            value: "Appointment Fixed",
          },
          {
            text: "Busy",
            value: "Busy",
          },
          {
            text: "Not Reachable / Out of Coverage",
            value: "Not Reachable / Out of Coverage",
          },
          {
            text: "Call Waiting",
            value: "Call Waiting",
          },
          {
            text: "No Response",
            value: "No Response",
          },
          {
            text: "Person Not Available",
            value: "Person Not Available",
          },
          {
            text: "Wrong Number",
            value: "Wrong Number",
          },
          {
            text: "Invalid Number",
            value: "Invalid Number",
          },
          {
            text: "Language Problem",
            value: "Language Problem",
          },
          {
            text: "Call Declined",
            value: "Call Declined",
          },
          {
            text: "Others",
            value: "Others",
          },
        ],
        onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
        filterIcon: () => <FilterOutlined />,
        width: 200,
        render: (value, item, index) => {
          return <div>
            {value}       {item.comments && <Tooltip placement="topLeft" title={item.comments} >
              <InfoCircleOutlined className=" ml-1" />
            </Tooltip>}
          </div>
        }
      },

      {
        title: "STATUS",
        dataIndex: "status",
        isShow: columnStatus?.status.enabled,
        className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
        render: (text) => (
          <>
            {text === "screened" && (
              <span className={"text-blue text-base"}>Screened</span>
            )}
            {(text === "completed" || text === "Approved") && (
              <span className={"text-green text-base"}>Completed</span>
            )}
            {text === "cancelled" && (
              <span className={"text-red text-base"}>Terminated</span>
            )}
            {text === "quotaFull" && (
              <span className={"text-red text-base"}>Quota Full</span>
            )}
            {text === "active" && (
              <span className={"text-textExtraLight text-base"}>
                Status pending
              </span>
            )}
            {text === "in-progress" && (
              <span className={"text-textExtraLight text-base"}>In progress</span>
            )}
          </>
        ),
        filters: [
          {
            text: "Screened",
            value: "screened",
          },
          {
            text: "Completed",
            value: "completed",
          },
          {
            text: "Terminated",
            value: "cancelled",
          },
          {
            text: "Status pending",
            value: "active",
          },
          {
            text: "In progress",
            value: "in-progress",
          },
          {
            text: "Quota full",
            value: "quotaFull",
          },
        ],
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        filterIcon: () => <FilterOutlined />,
      },
      {
        title: (
          <span>
            ACTION
            <Tooltip placement="top" title="Dowload Data">
              <Button
                type="link"
                className="float-right p-0"
                onClick={() => {
                  memColumns.pop();
                  downloadCSVFile(
                    tableData,
                    memColumns,
                    `${membersResponses?.survey[0].project?.name}-${todayDate}`
                  );
                }}
              >
                <DownloadOutlined style={{ fontSize: "1.5rem" }} />
              </Button>
            </Tooltip>
          </span>
        ),
        dataIndex: "action",
        render: (text, record, index) => (
          <>
            <Dropdown
              overlay={menu({
                index: index,
                record: record,
                handleCancel: handleCancel,
                handleOk: handleOk,
                showModal: showModal,
                isModalVisible: isModalVisible,
              })}
              placement="bottomRight"
              disabled={record.status === "active" ? true : false}
              trigger={["click"]}
            >
              <Button type="link" size="large">
                <img src={verticalDots} alt="vertical_Dots" />
              </Button>
            </Dropdown>
          </>
        ),
      }
    ]
    return [
      ...memberColumns(columnStatus),
      ...roleBasedColumns(role, columnStatus),
      ...customColumns.filter(data => (data.isShow === undefined || data.isShow)),
    ];
  }, [role, columnStatus, tableData, isModalVisible, status]);

  const menu = (props) => (
    <>
      <Modal
        title="Edit status"
        visible={props.isModalVisible}
        okText={<span>Update</span>}
        onOk={() => props.handleOk(props.record.id, props.record.index)}
        onCancel={props.handleCancel}
      >
        <div className="flex justify-start items-center">
          <div className="mr-2">Current status</div>
          <div className="ml-2 w-1/2">
            <Select
              className="w-full"
              value={status[props.record.index]}
              onChange={(e) => {
                let copy = [...status];
                copy[props.record.index] = e;
                setStatus(copy);
              }}
            >
              <Option key="completed">
                <Tag color="green">Completed</Tag>
              </Option>
              <Option key="screened">
                <Tag color="blue">Screened</Tag>
              </Option>
              <Option key="cancelled">
                <Tag color="red">Terminated</Tag>
              </Option>
            </Select>
          </div>
        </div>
      </Modal>
      <Menu className="rounded-md bg-gray">
        <Menu.Item key="1" onClick={props.showModal}>
          <span
            style={
              props.record.status === "active"
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
            className="text-textLight hover:text-orange"
          >
            Edit status
          </span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2">
          <span
            className="text-textLight hover:text-orange"
            style={
              props.record.status === "active"
                ? { pointerEvents: "none", opacity: "0.4" }
                : {}
            }
            onClick={() => {
              history.push(
                `/viewSurvey/members/project/${projectID}/survey/${surveyID}/${surveyType}/viewResponseAnswers/member/${props.record.userId}`
              );
            }}
          >
            View response
          </span>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <div className="membersTab w-full">
      <div className=" pb-5 float-right">
        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setColStatus(column)
        }} />
      </div>
      <MemberTable
        columns={memColumns}
        tableData={tableData}
        pagination={false}
        rowClassName={(record, index) =>
          "text-sm text-textMedium border-t border-l border-r border-textLight"
        }
      />
    </div>
  );
}

export default ResponsesTab;
