export const multipleChoiceValidation = async (surveyDetails) => {
  let status = true;
  let message = "";
  let screened = false;
  let jumpToquestion;
  let jumpToStatus = false;

  const getOptionCount = async (option, answer, i) => {
    const query = {
      query: `query MyQuery($questionId: Int!, $option: String!, $answerId: Int!) {
    answers_aggregate(
      where: {
        answer: { _like: $option }
        question_id: { _eq: $questionId }
        _not: { id: { _eq: $answerId } }
      }
    ) {
      aggregate {
        count
      }
    }
  }`,
      variables: {
        option: `%${option.label}%`,
        questionId: option.questions_id,
        answerId:
          answer.exisitingAnswer !== undefined
            ? answer.exisitingAnswer?.questions[0]?.answers.length === 0
              ? 0
              : answer.exisitingAnswer?.questions[0]?.answers[0].id
            : 0,
      },
    };
    try {
      return fetch(`${process.env.REACT_APP_API}`, {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (res.status === 200) {
          return res.json();
        }
      });
    } catch (err) {}
  };
  let i = 0;
  for (const option of surveyDetails.current_question.questions_options) {
    const ans = await getOptionCount(option, surveyDetails, i);
    const count = ans.data.answers_aggregate.aggregate.count;
    let currentAnswer;
    let copy = surveyDetails.current_answer[i];
    // eslint-disable-next-line
    for (let [key, value] of Object.entries(copy)) {
      if (key !== "type") {
        currentAnswer = key;
      }
    }
    if (currentAnswer === option.label) {
      if (option.logic && option.logic.quotaForChoice !== null) {
        if (option.logic.screenExit) {
          if (count >= option.logic.quotaForChoice) {
            status = true;
            screened = true;
          }
        } else if (option.logic && !option.logic.screenExit) {
          if (option.logic && count >= option.logic.quotaForChoice) {
            status = true;
            jumpToquestion = option.logic.jumpToQuestion;
            jumpToStatus = true;
          }
        }
      } else {
        if (option.logic && option.logic.screenExit) {
          status = true;
          screened = true;
        } else if (option.logic && option.logic.jumpToQuestion !== null) {
          status = true;
          jumpToquestion = option.logic.jumpToQuestion;
          jumpToStatus = true;
        }
      }
    }
    i++;
  }

  return {
    status,
    message,
    jumpToquestion,
    screened,
    jumpToStatus,
  };
};
