import React, { useEffect, useState } from "react";
import { Form, Input, Button, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import {
  UPDATE_LINK_STATUS,
  GET_LINK_STATUS,
  GET_THIRD_PARTY_URL,
} from "../graphql";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { redirectURL } from "../../../../../utils/redirectURL";

function QuestionCreation(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [url, setUrl] = useState();

  const [updateSurvey] = useMutation(UPDATE_LINK_STATUS);

  const { data: getLink, refetch: linkrefetch } = useQuery(GET_LINK_STATUS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "network-only",
  });

  const { data: getURL, refetch: urlrefetch } = useQuery(GET_THIRD_PARTY_URL, {
    variables: {
      project_id: parseInt(projectID),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    linkrefetch();
    urlrefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getLink) {
      form.setFieldsValue({
        surveyURL: getLink?.survey_by_pk?.links_status,
      });
    }
    if (getURL) {
      setUrl(getURL?.project_by_pk?.third_party_survey_url);
    }
    // eslint-disable-next-line
  }, [getLink, getURL]);

  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      updateSurvey({
        variables: {
          id: parseInt(surveyID),
          status: moment().isSameOrAfter(props.startDate)
            ? props.status === "draft"
              ? "draft"
              : "active"
            : props.status === "active"
            ? "active"
            : "draft",
          links_status: form.getFieldValue("surveyURL"),
        },
      }).then(() => {
        if (buttonAction === "active") {
          location.pathname.split("/")[3] === "edit-survey"
            ? history.push(
                `/project/${projectID}/edit-survey/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
              )
            : history.push(
                `/project/${projectID}/create-survey/${surveyType}/emailtemplate/inviteemail/survey/${surveyID}/preview`
              );
        } else {
          notification.success({
            message: "Success!",
            description: "Saved as draft!",
          });
          history.push(`/projectList/active`);
        }
      });
    });
  };

  const onFinish = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  const GetFields = () => {
    return (
      <>
        <Form
          name="dynamic_form_nest_item"
          form={form}
          autoComplete="off"
          requiredMark={false}
          preserve={true}
          layout="vertical"
        >
          <Form.Item>
            <p className="text-h6 text-orange w-9/12 font-semibold mt-2 mb-0">
              To load a third-party survey application in Krea - please follow
              the below steps
            </p>
            <p className="text-h6 w-9/12 mt-2">
              This survey uses 3rd party survey application -{" "}
              {getURL?.project_by_pk?.third_party_survey_name}
            </p>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-h6 text-orange">
                Step 1: Login to the third-party survey application
              </span>
            }
          >
            <p className="text-h6 w-9/12">
              <span className="float-left text-textMedium">
                Use your account details to login and create your survey
              </span>
              <Button
                className="float-right bg-lightButton text-orange border-0"
                onClick={() => {
                  redirectURL(url);
                }}
              >
                Proceed to Login
              </Button>
            </p>
          </Form.Item>
          <Form.Item
            label={
              <span className="text-h6 text-orange ">
                Step 2: Copy the Survey URL
              </span>
            }
          >
            <p className="text-h6 text-textMedium w-9/12">
              Once you have created the survey, copy the survey URL
            </p>
          </Form.Item>
          <Form.Item className="mb-0 w-9/12">
            <p className="text-h6 text-orange w-9/12">
              Step 3: Paste the Survey URL
            </p>
          </Form.Item>
          <Form.Item
            name="surveyURL"
            label={
              <span className="text-textLight">
                Paste the survey URL in the textbox below to proceed further
              </span>
            }
            rules={[{ required: true, message: "Please enter the survey URL" }]}
          >
            <Input className="w-9/12" />
          </Form.Item>
          <Form.Item className="w-9/12 mb-24">
            <span className="float-right h-11 text-textExtraLight">
              Click on 'Next' once done
            </span>
          </Form.Item>
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={() => {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                      `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
                    )
                  : history.push(
                      `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
                    );
              }}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-sm w-28"
              onClick={onFinish}
            >
              Next
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  };
  return (
    <div name="create survey">
      <GetFields />
    </div>
  );
}
export default QuestionCreation;
