import { gql } from "@apollo/client";

export const GET_PROJECT_BY_STATUS = gql`
  query GET_PROJECT_BY_STATUS {
    completed: rewards_aggregate(where: { status: { _eq: "completed" } }) {
      aggregate {
        count
      }
    }
    pending: rewards_aggregate(where: { status: { _eq: "pending" } }) {
      aggregate {
        count
      }
    }
    completed_projects: project_aggregate(
      where: { status: { _eq: "completed" } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const REWARDS_TABLE_DATA = gql`
  query REWARDS_TABLE_DATA {
    project(where: { status: { _eq: "completed" } }) {
      id
      client {
        f_name
        l_name
      }
      name
      member_types
      survey_type
      third_party_survey_name
      projectTypeByProjectType {
        id
        name
      }
      member_type_id
      surveys {
        id
        rewards {
          created_at
          created_by
          status
          survey_id
          survey_member_id
        }
        survey_x_members {
          user {
            f_name
            l_name
          }
          rewards {
            created_at
            created_by
            status
            survey_id
            survey_member_id
          }
        }
        survey_x_members_aggregate {
          aggregate {
            sum {
              completed
            }
          }
        }
        rewarded_survey_members: survey_x_members_aggregate(
          where: { rewards: { status: { _in: ["Rewarded", "Approved"] } } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`;
