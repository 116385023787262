import {
  Card,
  Col,
  Row,
  Breadcrumb,
  Tabs,
  Tag,
  Table,
  Spin,
  Button,
  Divider,
  Drawer,
  Form,
  Tooltip,
} from "antd";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ONE_USER,
  GET_ASSIGNED_PROJECTS,
  VERIFY_USER,
  REJECT_USER,
  REWARDS_TAB,
  GET_DOWNLOAD_URL,
  GET_ACTIVITY,
  ONBOARD_USER,
} from "./graphql";
import moment from "moment";
import ViewPhysician from "./Physician/viewPhysician";
import jwtDecode from "jwt-decode";
import { useHistory } from "react-router";
import {
  DownloadOutlined,
  FilterOutlined,
  LeftOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { getColumnSearchProps } from "../../utils/table";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import whatsappIcon from "../../assets/Icon/whatsapp.svg";
import ColumnEditor from "../../components/ColumnEditor";
import MemberStatsData from "../../components/MemberStatsData";
import MemberTable from "../../components/memberTable";

const { TabPane } = Tabs;
const ViewMembers = () => {
  const { id } = useParams();
  const [role, setRole] = useState(null);
  const history = useHistory();
  const { data, loading } = useQuery(GET_ONE_USER, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  const { data: activity } = useQuery(GET_ACTIVITY, {
    variables: {
      id: parseInt(id),
    },
  });

  const [verifyUser] = useMutation(VERIFY_USER);
  const [rejectUser] = useMutation(REJECT_USER);
  const [onboardUser] = useMutation(ONBOARD_USER);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // useEffect(() => {
  //   let copy = [...compatibility];
  //   if (data?.user_by_pk) {
  //     if (projectCompatibility) {
  //       data?.user_by_pk?.project_compatibilitys?.forEach((id) => {
  //         projectCompatibility?.project_compatibility?.forEach((element) => {
  //           console.log(element.id);
  //           if (id === element.id) {
  //             copy.push(element.project_compatibility_text);
  //             return;
  //           }
  //         });
  //       });
  //     }
  //   }
  //   setCompatibility(copy);
  // }, [projectCompatibility]);
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={24} className="px-8 py-5">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Button
                type="link"
                onClick={() => history.goBack()}
                className="p-0 text-gray-600"
              >
                <span style={{ color: "#828282" }}>Members</span>
              </Button>
            </Breadcrumb.Item>
            <Breadcrumb.Item>ID #{id}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col span={12} className=" px-36 mb-3">
          <div
            className=" flex items-center cursor-pointer"
            onClick={() => {
              history.goBack();
            }}
          >
            <LeftOutlined style={{ color: "orange" }} className="float-left" />
            <span style={{ color: "orange" }} className=" ml-2">
              Back
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="px-36">
          ID #{id}
        </Col>
        <Col span={12} className="px-36">
          {data?.user_by_pk?.is_verified === true ? (
            <Button
              type="primary"
              htmlType="button"
              className="rounded float-right"
              onClick={() => {
                history.push(`/members/${id}/edit`);
              }}
            >
              Edit
            </Button>
          ) : role === "super_admin" ||
            (role === "sub_admin" &&
              data?.user_by_pk?.created_by_user?.role_id === 3) ||
            (data?.user_by_pk?.created_by === null &&
              (role === "super_admin" || role === "sub_admin")) ? (
            <>
              <Button
                type="primary"
                htmlType="button"
                className="rounded float-right"
                onClick={async () => {
                  try {
                    await verifyUser({
                      variables: {
                        id: parseInt(id),
                        status: true,
                      },
                    });
                    if (data?.user_by_pk?.email) {
                      await onboardUser({
                        variables: {
                          user_id: parseInt(id),
                        },
                      });
                    }
                    setTimeout(() => {
                      window.location.reload();
                    }, 800);
                  } catch (error) {
                    console.error("Error during verification:", error);
                  }
                }}
              >
                Verify
              </Button>
              <Button
                type="link"
                htmlType="button"
                className="rounded float-right"
                onClick={async () => {
                  try {
                    await rejectUser({
                      variables: {
                        id: parseInt(id),
                      },
                    });
                    history.goBack();
                  } catch (error) {
                    console.error("Error during rejection:", error);
                  }
                }}
              >
                Reject
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col span={24} className="px-8">
          <p className="font-semibold text-lg">
            {data?.user_by_pk?.l_name !== null
              ? data?.user_by_pk?.f_name + " " + data?.user_by_pk?.l_name
              : data?.user_by_pk?.f_name}
            <span
              className="font-semibold text-lg ml-5"
              style={{ color: "#828282" }}
            >
              {data?.user_by_pk?.member_type?.description}
            </span>
          </p>
        </Col>
      </Row>
      <Row className=" w-full mt-2 mb-5">
        <Col span={18} className="pl-36">
          <div className=" flex gap-6">
            <div className=" shadow-lg bg-white rounded-md flex justify-between gap-10">
              <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow rounded-l-lg p-6">
                <MemberStatsData
                  label={"Links sent"}
                  totalCount={
                    data?.project_aggregate?.aggregate
                      ?.count
                  }
                />
              </div>
              <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6">
                <MemberStatsData
                  label={"Completed"}
                  totalCount={data?.user_by_pk?.completed?.aggregate?.count}
                  totalPercentage={
                    data?.user_by_pk?.survey_x_members_aggregate?.aggregate
                      ?.count
                  }
                />
              </div>
              <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow p-6">
                <MemberStatsData
                  label={"In progress"}
                  totalCount={data?.user_by_pk?.inprogress?.aggregate?.count}
                  totalPercentage={
                    data?.user_by_pk?.survey_x_members_aggregate?.aggregate
                      ?.count
                  }
                />
              </div>
              <div className="flex flex-col hover:bg-bgMedium whitespace-nowrap flex-grow rounded-r-lg p-6">
                <MemberStatsData
                  label={"Screened"}
                  totalCount={data?.user_by_pk?.screened?.aggregate?.count}
                  totalPercentage={
                    data?.user_by_pk?.survey_x_members_aggregate?.aggregate
                      ?.count
                  }
                />
              </div>
            </div>
            <div className=" w-40 shadow-lg bg-white rounded-md p-6 flex-shrink-0">
              <MemberStatsData
                label={"Response rate"}
                totalCount={(
                  (data?.user_by_pk?.completed?.aggregate?.count +
                    data?.user_by_pk?.inprogress?.aggregate?.count +
                    data?.user_by_pk?.screened?.aggregate?.count) /
                  data?.user_by_pk?.survey_x_members_aggregate?.aggregate
                    ?.count || 0
                ).toFixed(2)}
              />
            </div>
            <div className=" w-40  shadow-lg bg-white rounded-md p-6 flex-shrink-0">
              <MemberStatsData
                label={"Timeout"}
                totalCount={(
                  data?.user_by_pk?.inprogress?.aggregate?.count /
                  (data?.user_by_pk?.completed?.aggregate?.count +
                    data?.user_by_pk?.inprogress?.aggregate?.count +
                    data?.user_by_pk?.screened?.aggregate?.count) || 0
                ).toFixed(2)}
              />
            </div>
          </div>
        </Col>
      </Row>
      {data?.user_by_pk?.member_type?.description === "Physician" ? (
        <ViewPhysician data={data} activity={activity} />
      ) : (
        <Row>
          <Col span={18} className="pl-36">
            <Card bodyStyle={{ maxHeight: "150vh" }}>
              <Tabs defaultActiveKey="1" size="large">
                <TabPane tab="Contact details" key="1">
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Primary email</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.email && (
                        <p>
                          {role === "manager"
                            ? `${data?.user_by_pk?.email.substring(0, 3)}XXX@${data?.user_by_pk?.email.split("@")[1]
                            }`
                            : data?.user_by_pk?.email}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Secondary email</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.meta_date?.email2 ? (
                        <p>
                          {role === "manager"
                            ? `${data?.user_by_pk?.meta_date?.email2.substring(
                              0,
                              3
                            )}XXX@${data?.user_by_pk?.meta_date?.email2.split(
                              "@"
                            )[1]
                            }`
                            : data?.user_by_pk?.meta_date?.email2}
                        </p>
                      ) : (
                        <span>Not updated</span>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Mobile number 1</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.phone_number ? (
                        <div className="flex gap-2">
                          {data?.user_by_pk?.meta_date?.mobile1Whatsapp ===
                            true ? (
                            <img src={whatsappIcon} alt="arrowIcon" />
                          ) : (
                            ""
                          )}
                          {role === "manager"
                            ? `${data?.user_by_pk?.phone_number.substring(
                              0,
                              3
                            )}XXXXXXX`
                            : data?.user_by_pk?.phone_number}
                        </div>
                      ) : (
                        <span>Not updated</span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Mobile number 2</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.meta_date?.mobile2 ? (
                        <div className="flex gap-2">
                          {data?.user_by_pk?.meta_date?.mobile2Whatsapp ===
                            true ? (
                            <img src={whatsappIcon} alt="arrowIcon" />
                          ) : (
                            ""
                          )}
                          {role === "manager"
                            ? `${data?.user_by_pk?.meta_date?.mobile2.substring(
                              0,
                              3
                            )}XXXXXXX`
                            : data?.user_by_pk?.meta_date?.mobile2}
                        </div>
                      ) : (
                        <span>Not updated</span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Phone number</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.meta_date?.phone ? (
                        <p>
                          {role === "manager"
                            ? `${data?.user_by_pk?.meta_date?.phone.substring(
                              0,
                              3
                            )}XXXXXXX`
                            : data?.user_by_pk?.meta_date?.phone}
                        </p>
                      ) : (
                        <span>Not updated</span>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>State</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.state}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>City</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.city}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Pincode</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.pincode ? (
                        <p>{data?.user_by_pk?.pincode}</p>
                      ) : (
                        <p> Not updated </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>City category</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.meta_date?.cityCategory}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Zone</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.meta_date?.zone}</p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Member details" key="2">
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Title</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.title}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>First name</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.f_name}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Last name</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.l_name}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Gender</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.gender === "M" ? (
                        <p>Male</p>
                      ) : data?.user_by_pk?.gender === "F" ? (
                        <p>Female</p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Year of birth</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        {data?.user_by_pk?.year_of_birth
                          ? moment(data?.user_by_pk?.year_of_birth).format(
                            "yyyy"
                          )
                          : "Not Furnished"}
                      </p>
                    </Col>
                  </Row>
                  {data?.user_by_pk?.member_type?.name === "Technicians" && (
                    <Row className="mb-2">
                      <Col span={6}>
                        <p style={{ color: "#828282" }}>Profession</p>
                      </Col>
                      <Col span={6}>
                        <p>{data?.user_by_pk?.meta_date?.profession}</p>
                      </Col>
                    </Row>
                  )}
                  {data?.user_by_pk?.member_type?.name === "Technicians" && (
                    <>
                      <Row className="mb-2">
                        <Col span={6}>
                          <p style={{ color: "#828282" }}>Sub-category</p>
                        </Col>
                        <Col span={6}>
                          <p>{data?.user_by_pk?.meta_date?.sub}</p>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col span={6}>
                          <p style={{ color: "#828282" }}>Sub-category 2</p>
                        </Col>
                        <Col span={6}>
                          <p>{data?.user_by_pk?.meta_date?.sub2}</p>
                        </Col>
                      </Row>
                    </>
                  )}
                  {data?.user_by_pk?.member_type?.name === "Technicians" && (
                    <Row className="mb-2">
                      <Col span={6}>
                        <p style={{ color: "#828282" }}>Organisation name</p>
                      </Col>
                      <Col span={6}>
                        <p>{data?.user_by_pk?.meta_date?.organisation}</p>
                      </Col>
                    </Row>
                  )}
                  {data?.user_by_pk?.member_type?.name === "Patient" && (
                    <Row className="mb-2">
                      <Col span={6}>
                        <p style={{ color: "#828282" }}>Hospital name</p>
                      </Col>
                      <Col span={6}>
                        <p>{data?.user_by_pk?.meta_date?.hospital}</p>
                      </Col>
                    </Row>
                  )}
                  {(data?.user_by_pk?.member_type?.name === "Patient" ||
                    data?.user_by_pk?.member_type?.name === "Technicians") && (
                      <Row className="mb-2">
                        <Col span={6}>
                          <p style={{ color: "#828282" }}>Hospital type</p>
                        </Col>
                        <Col span={6}>
                          <p>{data?.user_by_pk?.meta_date?.hospitalType}</p>
                        </Col>
                      </Row>
                    )}
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>No of beds</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.meta_date?.noOfBeds ? (
                        <p>{data?.user_by_pk?.meta_date?.noOfBeds}</p>
                      ) : (
                        <p>Not updated</p>
                      )}
                    </Col>
                  </Row>
                  {data?.user_by_pk?.member_type?.name === "Patient" && (
                    <Row className="mb-2">
                      <Col span={6}>
                        <p style={{ color: "#828282" }}>Ailments</p>
                      </Col>
                      <Col span={6}>
                        {data?.user_by_pk?.meta_date?.ailments.length > 0 ? (
                          <div>
                            {data?.user_by_pk?.meta_date?.ailments?.map(
                              (item, index) => {
                                return (
                                  <Tag
                                    key={index}
                                    style={{
                                      borderRadius: "30px",
                                      padding: "8px",
                                    }}
                                  >
                                    {item}
                                  </Tag>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <p> Not updated </p>
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Access to mobile app</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        {data?.user_by_pk?.meta_date?.access === true
                          ? "true"
                          : "false"}
                      </p>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Work details" key="3">
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>
                        Started practicing (post Residency)
                      </p>
                    </Col>
                    <Col span={6}>
                      <p>
                        {moment(
                          data?.user_by_pk?.meta_date?.yearOfPractice
                        ).format("yyyy")}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Years of experience</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        {moment().format("yyyy") -
                          moment(
                            data?.user_by_pk?.meta_date?.yearOfPractice
                          ).format("yyyy")}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>
                        Total incentives processed
                      </p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.meta_date?.totalIncentive}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>
                        Previously participated studies
                      </p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.meta_date?.previousStudies}</p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Mode of payment</p>
                    </Col>
                    <Col span={6}>
                      {data?.user_by_pk?.meta_date?.payment?.map((info) => {
                        return <Tag>{info.label}</Tag>;
                      })}
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Validated date</p>
                    </Col>
                    <Col span={6}>
                      <p>
                        {moment(
                          data?.user_by_pk?.meta_date?.validatedDate
                        ).format("DD-MM-YYYY")}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col span={6}>
                      <p style={{ color: "#828282" }}>Status</p>
                    </Col>
                    <Col span={6}>
                      <p>{data?.user_by_pk?.status}</p>
                    </Col>
                  </Row>
                  {!!data?.user_by_pk?.additional_data?.length && (
                    <div className="mb-2">
                      <p style={{ color: "#828282" }}>Additional Fields</p>
                      {data?.user_by_pk?.additional_data?.map(
                        (additionalFields, additionalFieldIndex) => {
                          return (
                            <Row
                              className="mb-2 ml-3"
                              key={`additional-${additionalFieldIndex}`}
                            >
                              <Col span={6}>
                                <p style={{ color: "#828282" }}>
                                  {additionalFields?.label}
                                </p>
                              </Col>
                              <Col span={6}>
                                <p>{additionalFields?.info}</p>
                              </Col>
                            </Row>
                          );
                        }
                      )}
                    </div>
                  )}
                </TabPane>
                <TabPane tab="Surveys" key="4">
                  <AssingedSurveys />
                </TabPane>
                <TabPane tab="Rewards" key="5">
                  <RewardsTab />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Activity"  >
              {activity?.approval?.map((copy) => {
                let approval = JSON.parse(JSON.stringify(copy));
                approval.old_info.user = {
                  ...approval.old_info.user,
                  ...approval.old_info.user.meta_date,
                  ...approval.old_info.user.additional_data,
                };
                approval.updated_info.user = {
                  ...approval.updated_info.user,
                  ...approval.updated_info.user.meta_date,
                  ...approval.updated_info.user.additional_data,
                };
                const processedChanges = [];
                // eslint-disable-next-line
                return Object.keys(approval?.old_info?.user).map((key) => {
                  let oldTitle = approval?.old_info?.user[key];
                  let newTitle = approval?.updated_info?.user[key];

                  oldTitle = oldTitle !== null && oldTitle !== undefined ? oldTitle.toString() : 'null';
                  newTitle = newTitle !== null && newTitle !== undefined ? newTitle.toString() : 'null';

                  if (oldTitle !== newTitle && oldTitle !== "null" && newTitle !== "null") {
                    const changeKey = `${key}:${oldTitle}:${newTitle}`;
                    if (!processedChanges.includes(changeKey)) {
                      processedChanges.push(changeKey);

                      if (key === "city" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            City changed from {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "f_name" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            First name changed from{" "}
                            {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "title" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Title changed from {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "state" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            State changed from {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "email" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Email changed from {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "phone_number" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Phone number changed from{" "}
                            {oldTitle || "null" || "null"} to{" "}
                            {newTitle || "null" || "null"}
                          </p>
                        );
                      } else if (key === "gender" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Gender changed from {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "pincode" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Pincode changed from {oldTitle || "null"}{" "}
                            to {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "year_of_birth" && (oldTitle || newTitle)) {
                        if (
                          // eslint-disable-next-line
                          moment(oldTitle || "null")?.format(
                            "yyyy"
                          ) !==
                          moment(oldTitle || "null")?.format("yyyy")
                        ) {
                          return (
                            <p>
                              Year of birth changed from{" "}
                              {moment(oldTitle || "null").format(
                                "yyyy"
                              )}{" "}
                              to{" "}
                              {moment(newTitle || "null").format(
                                "yyyy"
                              )}
                            </p>
                          );
                        }
                      } else if (key === "access" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Access to mobile app changed from{" "}
                            {oldTitle || "null" === true ? (
                              <span className="mr-1">Yes</span>
                            ) : (
                              <span className="mr-1">No</span>
                            )}
                            to
                            {newTitle || "null" === true ? (
                              <span className="ml-1">Yes</span>
                            ) : (
                              <span className="ml-1">No</span>
                            )}
                          </p>
                        );
                      } else if (key === "ailments" && (oldTitle || newTitle)) {
                        return <p>Ailments list changed</p>;
                      } else if (key === "organisation") {
                        return (
                          <p>
                            Organisation changed from{" "}
                            {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "sub" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Sub category changed from{" "}
                            {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "profession" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Profession changed from{" "}
                            {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      } else if (key === "experience" && (oldTitle || newTitle)) {
                        return (
                          <p>
                            Experience changed from{" "}
                            {oldTitle || "null"} to{" "}
                            {newTitle || "null"}
                          </p>
                        );
                      }
                    }
                  }
                });
              })}
            </Card>
          </Col>
        </Row>
      )}
    </Spin>
  );
};

const AssingedSurveys = () => {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const { data, loading } = useQuery(GET_ASSIGNED_PROJECTS, {
    variables: {
      id: parseInt(id),
    },
  });
  const savedviewMembers = localStorage.getItem('viewMembers');
  const viewMembersinitial = savedviewMembers
    ? JSON.parse(savedviewMembers)
    : {
      responseStatus: { enabled: true, label: "Response status" },
      name: { enabled: true, label: "Name" },
      client: { enabled: true, label: "Client" },
      category: { enabled: true, label: "Category" },
      manager: { enabled: true, label: "Assigned To" },
      startDate: { enabled: true, label: "Start Date" },
      endDate: { enabled: true, label: "End Date" },
      //status: { enabled: true, label: "Status" },
      projectStatus: { enabled: true, label: "Project status" },
      responseStatus: { enabled: true, label: "Response status" },
      clientLink: { enabled: false, label: "Client Link" },
      kreaLink: { enabled: false, label: "Krea Link" },
      interviewerName: { enabled: false, label: "Interviewer" },
      callStatus: { enabled: false, label: "Call status" },
    }
  const [columnStatus, setStatus] = useState(viewMembersinitial);

  useEffect(() => {
    localStorage.setItem('viewMembers', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let copyData = [],
      copyObject = {};
    if (data) {
      data?.project.forEach((project) => {
        copyObject.name = project?.surveys[0]?.name;
        copyObject.client =
          (project?.client?.f_name || "") +
          " " +
          (project?.client?.l_name || "");
        copyObject.category = project?.projectTypeByProjectType.name;
        copyObject.manager =
          project?.manager_x_projects[0]?.user?.l_name !== null
            ? project?.manager_x_projects[0]?.user?.f_name +
            " " +
            project?.manager_x_projects[0]?.user?.l_name
            : project?.manager_x_projects[0]?.user?.f_name;
        copyObject.startDate = project?.surveys[0]?.start_date;
        copyObject.endDate = project?.surveys[0]?.end_date;
        copyObject.status = project?.surveys[0]?.status;
        copyObject.responseStatus = project?.surveys[0].survey_x_members[0]?.state;
        copyObject.member =
          project?.member_types !== null
            ? project?.member_types[0] === 1
              ? "Physician"
              : project?.member_types[0] === 2
                ? "Technician"
                : project?.member_types[0] === 3
                  ? "Patient"
                  : "Consumer"
            : "";
        copyObject.projectStatus = project?.status;
        if (
          project?.projectTypeByProjectType?.name === "Online" ||
          (project?.is_online_or_assisted &&
            project?.projectTypeByProjectType?.name === "Assisted")
        ) {
          copyObject.clientLink =
            project?.survey_type === "Third_Party_survey"
              ? project?.surveys[0]?.links_status
              : project?.surveys[0]?.survey_x_members[0]?.survey_url;
          copyObject.kreaLink = `${baseURL}/survey/${project?.surveys[0]?.id}/member/${project?.surveys[0]?.survey_x_members[0]?.member_id}`;
        }
        if (
          project?.surveys[0]?.survey_x_members[0]
            ?.survey_x_member_x_interviewers.length > 0
        ) {
          copyObject.interviewerName =
            project?.surveys[0]?.survey_x_members[0]
              ?.survey_x_member_x_interviewers[0].interviewer.l_name !== null
              ? project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.f_name +
              " " +
              project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.l_name
              : project?.surveys[0]?.survey_x_members[0]
                ?.survey_x_member_x_interviewers[0].interviewer.f_name;
          copyObject.callStatus =
            project?.surveys[0]?.survey_x_members[0]?.survey_x_member_x_interviewers[0]?.status;
          copyObject.comments =
            project?.surveys[0]?.survey_x_members[0]?.survey_x_member_x_interviewers[0]?.comments?.description;
        }
        copyData.push(copyObject);

        copyObject = {};
      });
      setTableData(copyData);
    }
  }, [data]);

  const columns = [
    {
      title: "SURVEY NAME",
      key: "name",
      dataIndex: "name",
      width: 300,
      ...getColumnSearchProps("name", "Survey Name"),
    },
    {
      title: "CLIENT",
      key: "client",
      dataIndex: "client",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("client", "Client"),
    },
    {
      title: "CATEGORY",
      key: "category",
      // dataIndex: "category",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("category", "Category"),
      render: (record) => {
        return (
          <div>
            <div>
              <div className="font-medium text-textDark text-sm">
                {record.category}
              </div>
              <div className="text-xs text-textLight font-normal">
                {record.member}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "ASSIGNED TO",
      key: "manager",
      dataIndex: "manager",
      className: columnStatus?.manager?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("manager", "Manager"),
    },
    {
      title: "START DATE",
      key: "startDate",
      dataIndex: "startDate",
      className: columnStatus?.startDate?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("startDate"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {text ? moment(text).format("MMM DD, YYYY") : ""}
          </div>
          <div className="text-h8 text-textLight">
            {text ? moment(text).format("kk[h] mm[m]") : ""}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "endDate",
      dataIndex: "endDate",
      className: columnStatus?.endDate?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("endDate"),
      render: (text) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {text ? moment(text).format("MMM DD, YYYY") : ""}
          </div>
          <div className="text-h8 text-textLight">
            {text ? moment(text).format("kk[h] mm[m]") : ""}
          </div>
        </div>
      ),
    },

    /*{
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.status === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.status === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.status === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },*/
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      isShow: columnStatus?.clientLink?.enabled,
      className: columnStatus?.clientLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("clientLink"),
      width: 200,
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "kreaLink",
      isShow: columnStatus?.kreaLink?.enabled,
      className: columnStatus?.kreaLink?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("kreaLink"),
      width: 200,
    },
    {
      title: "INTERVIEWER",
      dataIndex: "interviewerName",
      isShow: columnStatus?.interviewerName?.enabled,
      className: columnStatus?.interviewerName?.enabled
        ? "table-cell"
        : "hidden",
      ...getColumnSearchProps("interviewerName"),
    },
    {
      title: "CALL STATUS",
      dataIndex: "callStatus",
      width: 200,
      isShow: columnStatus?.callStatus?.enabled,
      className: columnStatus?.callStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Completed",
          value: "Completed",
        },
        {
          text: "Screened",
          value: "Screened",
        },
        {
          text: "Terminated",
          value: "Terminated",
        },
        {
          text: "Call Later",
          value: "Call Later",
        },
        {
          text: "Not Interested",
          value: "Not Interested",
        },
        {
          text: "Appointment Fixed",
          value: "Appointment Fixed",
        },
        {
          text: "Busy",
          value: "Busy",
        },
        {
          text: "Not Reachable / Out of Coverage",
          value: "Not Reachable / Out of Coverage",
        },
        {
          text: "Call Waiting",
          value: "Call Waiting",
        },
        {
          text: "No Response",
          value: "No Response",
        },
        {
          text: "Person Not Available",
          value: "Person Not Available",
        },
        {
          text: "Wrong Number",
          value: "Wrong Number",
        },
        {
          text: "Invalid Number",
          value: "Invalid Number",
        },
        {
          text: "Language Problem",
          value: "Language Problem",
        },
        {
          text: "Call Declined",
          value: "Call Declined",
        },
        {
          text: "Others",
          value: "Others",
        },
      ],
      onFilter: (value, record) => record.callStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (value, item, index) => {
        return (
          <div>
            <span className="capitalize">{value}</span>
            {item.comments && (
              <Tooltip placement="topLeft" title={item.comments}>
                <InfoCircleOutlined className=" ml-1" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "PROJECT STATUS",
      key: "projectStatus",
      dataIndex: "projectStatus",
      className: columnStatus?.projectStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.projectStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.projectStatus === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.projectStatus === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.projectStatus === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "RESPONSE STATUS",
      dataIndex: "responseStatus",
      className: columnStatus?.responseStatus?.enabled ? "table-cell" : "hidden",
      render: (text) => (
        <>
          {text === "screened" && (
            <span className={"text-blue text-base"}>Screened</span>
          )}
          {(text === "completed" || text === "approved") && (
            <span className={"text-green text-base"}>Completed</span>
          )}
          {text === "cancelled" && (
            <span className={"text-red text-base"}>Terminated</span>
          )}
          {text === "quotaFull" && (
            <span className={"text-red text-base"}>Quota Full</span>
          )}
          {text === "active" && (
            <span className={"text-textExtraLight text-base"}>
              Status pending
            </span>
          )}
          {text === "in-progress" && (
            <span className={"text-textExtraLight text-base"}>In progress</span>
          )}
        </>
      ),
      filters: [
        {
          text: "Screened",
          value: "screened",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Terminated",
          value: "cancelled",
        },
        {
          text: "Status pending",
          value: "active",
        },
        {
          text: "In progress",
          value: "in-progress",
        },
        {
          text: "Quota full",
          value: "quotaFull",
        },
      ],
      onFilter: (value, record) => record.responseStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
    },
  ];
  return (
    <Spin spinning={loading}>
      <div className=" pb-5 float-right">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setStatus(column);
          }}
        />
      </div>
      {/* <Table
        columns={columns}
        className="shadow rounded"
        dataSource={tableData}
      /> */}
      <MemberTable columns={columns} tableData={tableData}></MemberTable>
    </Spin>
  );
};

const RewardsTab = () => {
  const { id } = useParams();
  const [tableData, setTableData] = useState([]);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [dataObject, setDataObject] = useState(null);
  const { data } = useQuery(REWARDS_TAB, {
    variables: {
      member_id: parseInt(id),
    },
  });
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  // const [columnStatus, setStatus] = useState({
  //   completedOn: { enabled: true, label: "Completed On" },
  //   rewardStatus: { enabled: true, label: "Reward Status" },
  //   rewardedBy: { enabled: true, label: "Rewarded By" },
  // });
  const savedrewardsTab = localStorage.getItem('rewardsTab');
  const rewardsTabinitial = savedrewardsTab
    ? JSON.parse(savedrewardsTab)
    : {
      completedOn: { enabled: true, label: "Completed On" },
      rewardStatus: { enabled: true, label: "Reward Status" },
      rewardedBy: { enabled: true, label: "Rewarded By" },
    }

  const [columnStatus, setStatus] = useState(rewardsTabinitial);

  useEffect(() => {
    localStorage.setItem('rewardsTab', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    let tempData = [],
      tempObject = {};
    if (data) {
      data?.survey?.forEach((survey) => {
        if (survey.survey_x_members.length > 0) {
          tempObject.id = survey.id;
          tempObject.name = survey.name;
          tempObject.completedOn = moment(
            survey?.survey_x_members[0]?.updated_at
          ).format("DD,MMM,YYYY h:mm a");
          tempObject.rewardStatus =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]?.status
              : "Yet to reward";
          tempObject.rewardedBy =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]?.created_user?.f_name +
              " " +
              survey?.survey_x_members[0]?.rewards[0]?.created_user?.l_name
              : "";
          tempObject.reward =
            survey?.survey_x_members[0]?.rewards?.length > 0
              ? survey?.survey_x_members[0]?.rewards[0]
              : null;
          tempData.push(tempObject);
          tempObject = {};
        }
      });
      setTableData(tempData);
    }
  }, [data]);

  const detailFooter = (
    <Row>
      <div className="w-full h-8 my-2">
        <div className="text-textMedium float-right pt-1 pr-6">
          <Button
            type="link"
            htmlType="button"
            onClick={() => {
              setDetailsVisible(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Row>
  );

  const columns = [
    {
      title: "NAME",
      key: "name",
      dataIndex: "name",
      ...getColumnSearchProps("namee", "Project Name"),
    },
    {
      title: "COMPLETED ON",
      key: "date",
      dataIndex: "completedOn",
      className: columnStatus?.completedOn?.enabled ? "table-cell" : "hidden",
      ...getColumnDateSearchProps("completedOn"),
    },
    {
      title: "REWARD STATUS",
      key: "rewardStatus",
      dataIndex: "rewardStatus",
      className: columnStatus?.rewardStatus?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Yet to reward",
          value: "Yet to reward",
        },
        {
          text: "Rewarded",
          value: "Rewarded",
        },
        {
          text: "Approved",
          value: "Approved",
        },
      ],
      onFilter: (value, record) => record.rewardStatus.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => {
        return text === "Rewarded" ? (
          <span style={{ color: "#6BC342" }}>{text}</span>
        ) : text === "Yet to reward" ? (
          <span style={{ color: "#FFA63E" }}>{text}</span>
        ) : (
          <span style={{ color: "#C74B5E" }}>{text}</span>
        );
      },
    },
    {
      title: "REWARDED BY",
      key: "by",
      dataIndex: "rewardedBy",
      className: columnStatus?.rewardedBy?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("rewardedBy", "Rewarded By"),
    },
    {
      title: "ACTION",
      key: "action",
      render: (_, record) => {
        // eslint-disable-next-line
        {
          return (
            <Button
              type="link"
              onClick={() => {
                setDetailsVisible(true);
                setDataObject(record);
              }}
            >
              View details
            </Button>
          );
        }
      },
    },
  ];

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="space-between">
            <div
              // span={12}
              className="px-6 text-xl font-semibold"
              style={{ color: "#4F4F4F" }}
            >
              Completed surveys -{" "}
              {data?.survey_x_member_aggregate?.aggregate?.count || 0}
            </div>
            <div span={12}>
              <Button
                // size="large"
                className="rounded mr-5 px-3"
                style={{ color: "#E08036", borderColor: "#E08036" }}
              >
                <DownloadOutlined className=" text-lg" />
              </Button>
              <ColumnEditor
                columns={columnStatus}
                onSave={(column) => {
                  setStatus(column);
                }}
              />
            </div>
          </Row>
        </Col>
        <Divider />
        <Col span={24}>
          <Table columns={columns} dataSource={tableData} />
        </Col>
      </Row>
      <Drawer
        title="Rewards"
        footer={detailFooter}
        width={600}
        visible={detailsVisible}
        onClose={() => {
          setDetailsVisible(false);
        }}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          labelAlign="left"
          colon={false}
        >
          <Form.Item label="Disbursal note">
            {dataObject?.reward?.comments}
          </Form.Item>
          <Form.Item label="File">
            {dataObject?.reward?.file_url.split(",")?.map((files) => {
              return (
                <Row
                  span={24}
                  align="middle"
                  justify="space-between"
                  key={files}
                >
                  <Col>{files?.split("/")[files?.split("/")?.length - 1]}</Col>
                  <Col>
                    <Button
                      size="large"
                      className="rounded float-right"
                      style={{ color: "#E08036", borderColor: "#E08036" }}
                      onClick={() => handleDownload(files)}
                    >
                      <DownloadOutlined style={{ fontSize: "1.5rem" }} />
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ViewMembers;
