import moment from "moment";

export const getISOstringFrom = (date) => {
  return `${moment(date).format("YYYY-MM-DDThh:mm:ss.000Z")}`;
};
export const getISOstringTo = (date) => {
  return `${moment(date).format("YYYY-MM-DDT23:59:59.000Z")}`;
};

export const appointment_date = (date) => {
  return `${moment(date).format("YYYY-MM-DDT00:00:00.000Z")}`;
};

export const formatDate = (date) => {
  return `${moment(date).format("DD-MM-YYYY")}`;
};
export const formatDateTimeLogic = (date) => {
  return `${moment(date).format("DD/MM/YYYY HH:mm")}`;
};
export const formatDateTime = (date) => {
  return `${moment(date).format("DD MMM YYYY h:mm A")}`;
};

export const formatDateTimeAppointment = (date) => {
  return `${moment(date).format("DD MMM YYYY h:mm A")}`;
};

export const formatDateTOString = (date) => {
  return `${moment(date).format("MMM DD, YYYY")}`;
};

export const formatTime = (time) => {
  return `${moment(time).format("h:mm A")}`;
};

export const disableDateYearOfBirth = (current) => current && (current >= moment().endOf('day') || parseInt(moment(current).format("YYYY")) < parseInt(moment().format("YYYY")) - 100)