import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  Divider,
  Radio,
  notification,
  Upload,
  message,
  Modal,
  DatePicker,
} from "antd";
import { useHistory, useParams } from "react-router";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import {
  GET_MEMBERS_LIST,
  GET_ASSIGNED_MEMBERS_LINKS,
  UPDATE_SURVEY_X_MEMEBER,
  UPDATE_LINK_STATUS,
  GET_SURVEY_DETAILS,
  SURVEY_DATES,
  GET_UPLOAD_URL,
  NOTIFY_NEW_MEMBER,
  GET_DOWNLOAD_URL,
  GET_ASSIGNED_MEMBERS,
} from "../graphql";
import moment from "moment";

function SurveyLink(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const [singleURL, setSingleURL] = useState(null);
  const [multipleURL, setMultipleURL] = useState(null);
  const [value, setValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [newMembers, setNewMembers] = useState([]);
  const [checkcsv, setCheckcsv] = useState(false);
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [updateSurvey] = useMutation(UPDATE_LINK_STATUS);
  const [updateMembers] = useMutation(UPDATE_SURVEY_X_MEMEBER);
  const [notifyMembers] = useMutation(NOTIFY_NEW_MEMBER);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  const [getDownloadURL] = useMutation(GET_DOWNLOAD_URL);
  const { data: assignedMembers, refetch: memberList } = useQuery(
    GET_ASSIGNED_MEMBERS_LINKS,
    {
      variables: {
        survey_id: parseInt(surveyID),
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: assignedMemberscsv, refetch: memberListcsv } = useQuery(
    GET_ASSIGNED_MEMBERS,
    {
      variables: {
        survey_id: parseInt(surveyID),
      },
      fetchPolicy: "no-cache",
    }
  );

  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  const { data: survey, refetch: surveydetails } = useQuery(
    GET_SURVEY_DETAILS,
    {
      fetchPolicy: "network-only",
      onCompleted(data) {
        if (data) {
          surveyID &&
            data.survey.forEach((survey) => {
              if (parseInt(surveyID) === survey.id) {
                setValue(survey.links_status);
              }
            });
        }
      },
    }
  );

  useEffect(() => {
    if (survey) {
      surveyID &&
        survey.survey.forEach((survey) => {
          if (parseInt(surveyID) === survey.id) {
            setValue(survey.links_status);
          }
        });
    }
    // eslint-disable-next-line
  }, [survey]);

  useEffect(() => {
    let url = [];
    if (value === "single") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el, i) => {
          url[i] = el.user.survey_x_members[0].survey_url;
        });
      setSingleURL(url[0]);
    } else if (value === "multiple") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el, i) => {
          url[i] = el.user.survey_x_members[0].survey_url;
        });
      let length = url.filter((d) => d !== null).length;
      length === 0 ? setMultipleURL("") : setMultipleURL(url.join(","));
    }
    // eslint-disable-next-line
  }, [value, assignedMembers]);

  useEffect(() => {
    memberList();
    surveydetails();
    memberListcsv();
    surveyDatesRefetch();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setDueDate(moment(surveyDates?.survey[0]?.end_date));
    }
    // eslint-disable-next-line
  }, [surveyDates]);

  useEffect(() => {
    let url = [];
    let arrayCopy = [];
    assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member?.length > 0 &&
      // eslint-disable-next-line
      assignedMembers?.survey_x_member?.map((el, i) => {
        if (el.user.survey_x_members[0].survey_url === null) {
          arrayCopy.push(el.user.id);
        }
      });
    setNewMembers(arrayCopy);
    if (value === "single") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el, i) => {
          url[i] = el.user.survey_x_members[0].survey_url;
        });
      let filtered = url.filter(function (el) {
        return el != null;
      });
      setSingleURL(filtered[0]);
    } else if (value === "multiple") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el, i) => {
          url[i] = el.user.survey_x_members[0].survey_url;
        });
      let filtered = url.filter(function (el) {
        return el != null;
      });
      let length = filtered.filter((d) => d !== null).length;
      length === 0 ? setMultipleURL("") : setMultipleURL(filtered.join(","));
    }
    // eslint-disable-next-line
  }, [assignedMembers, survey, value, location]);

  // eslint-disable-next-line
  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    let payload = [];

    if (value === "single") {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          payload.push(singleURL);
        });

      let update =
        assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member
          // eslint-disable-next-line
          ?.map((el, i) => {
            let id = el.user.survey_x_members[0].id;
            updateMembers({
              variables: {
                id: id,
                survey_url: payload[i],
              },
            }).then(() => {
              updateSurvey({
                variables: {
                  id: parseInt(surveyID),
                  status: props.status === "draft" ? "draft" : "active",
                  links_status: value,
                },
              });
            });
            return true;
          })
          .filter((data) => data);
      if (update.length === assignedMembers?.survey_x_member?.length) {
        notifyMembers({
          variables: {
            survey_id: parseInt(surveyID),
            member_id: newMembers,
          },
        }).then(() => {
          notification.success({
            message: "Success!",
            description: "Survey created!",
          });
          history.push("/projectList/active");
        });
      }
    } else {
      const links = multipleURL.replace(/ /g, "");
      let result = links?.split(",");
      if (!checkcsv) {
        let update =
          assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          assignedMembers?.survey_x_member
            ?.map((el, i) => {
              let id = el.user.survey_x_members[0].id;
              updateMembers({
                variables: {
                  id: id,
                  survey_url: result[i],
                },
              }).then(() => {
                updateSurvey({
                  variables: {
                    id: parseInt(surveyID),
                    status: props.status === "draft" ? "draft" : "active",
                    links_status: value,
                  },
                });
              });
              return true;
            })
            .filter((data) => data);
        if (update.length === assignedMembers?.survey_x_member?.length) {
          notifyMembers({
            variables: {
              survey_id: parseInt(surveyID),
              member_id: newMembers,
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Survey created!",
            });
            history.push("/projectList/active");
          });
        }
      } else {
        let update =
          assignedMemberscsv?.survey_x_member &&
          assignedMemberscsv?.survey_x_member?.length > 0 &&
          assignedMemberscsv?.survey_x_member
            ?.map((el, i) => {
              let id = el.user.survey_x_members[0].id;
              updateMembers({
                variables: {
                  id: id,
                  survey_url: result[i],
                },
              }).then(() => {
                updateSurvey({
                  variables: {
                    id: parseInt(surveyID),
                    status: props.status === "draft" ? "draft" : "active",
                    links_status: value,
                  },
                });
              });
              return true;
            })
            .filter((data) => data);
        if (update.length === assignedMembers?.survey_x_member?.length) {
          notifyMembers({
            variables: {
              survey_id: parseInt(surveyID),
              member_id: newMembers,
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Survey created!",
            });
            history.push("/projectList/active");
          });
        }
      }
    }
  };

  const disabledDuebyDate = (current) => {
    return current && current < startDate;
  };

  const handleDownload = (name) => {
    const fileDetails = name.split("/");
    const fileKey = fileDetails[fileDetails.length - 1];
    getDownloadURL({
      variables: {
        name: fileKey,
      },
    }).then((res) => {
      const url = res.data.get_view_url.url;
      let link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleFormValues = (buttonAction) => {
    let payload = [];

    if (value === null) {
      setErrorMessage("Please select one option");
    } else if (
      value === "single" &&
      (singleURL === "" || singleURL === null || singleURL === undefined)
    ) {
      setErrorMessage("Please enter single URL");
    } else if (
      value === "multiple" &&
      (multipleURL === "" || multipleURL === null || multipleURL === undefined)
    ) {
      setErrorMessage("Please enter multiple URL");
    } else {
      if (value === "single") {
        assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          // eslint-disable-next-line
          assignedMembers?.survey_x_member?.map((el) => {
            payload.push(singleURL);
          });
        assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          // eslint-disable-next-line
          assignedMembers?.survey_x_member?.map((el, i) => {
            let id = el.user.survey_x_members[0].id;
            updateMembers({
              variables: {
                id: id,
                survey_url: payload[i],
              },
            }).then(() => {
              updateSurvey({
                variables: {
                  id: parseInt(surveyID),
                  status:
                    buttonAction === "draft"
                      ? "draft"
                      : moment().isSameOrAfter(props.startDate)
                      ? props.status === "draft"
                        ? "draft"
                        : "active"
                      : props.status === "active"
                      ? "active"
                      : "draft",
                  links_status: value,
                },
              }).then(() => {
                if (buttonAction === "active") {
                  let editOrCreate = location.pathname.split("/")[3];
                  history.push(
                    `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                  );
                } else {
                  notification.success({
                    message: "Success!",
                    description: "Saved as draft!",
                  });
                  history.push("/projectList/active");
                }
              });
            });
          });
      } else {
        const links = multipleURL.replace(/ /g, "");
        let result = links?.split(",");
        let length = result.filter((d) => d !== "").length;
        if (!checkcsv) {
          assignedMembers?.survey_x_member &&
          assignedMembers?.survey_x_member?.length > 0 &&
          assignedMembers?.survey_x_member?.length === length
            ? // eslint-disable-next-line
              assignedMembers?.survey_x_member?.map((el, i) => {
                let id = el.user.survey_x_members[0].id;
                updateMembers({
                  variables: {
                    id: id,
                    survey_url: result[i],
                  },
                }).then(() => {
                  updateSurvey({
                    variables: {
                      id: parseInt(surveyID),
                      status:
                        buttonAction === "draft"
                          ? "draft"
                          : moment().isSameOrAfter(props.startDate)
                          ? props.status === "draft"
                            ? "draft"
                            : "active"
                          : props.status === "active"
                          ? "active"
                          : "draft",
                      links_status: value,
                    },
                  }).then(() => {
                    if (buttonAction === "active") {
                      let editOrCreate = location.pathname.split("/")[3];
                      history.push(
                        `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                      );
                    } else {
                      notification.success({
                        message: "Success!",
                        description: "Saved as draft!",
                      });
                      history.push("/projectList/active");
                    }
                  });
                });
              })
            : setErrorMessage(
                `You have selected ${assignedMembers?.survey_x_member?.length} members. Please match the number of URLs added`
              );
        } else {
          assignedMemberscsv?.survey_x_member &&
          assignedMemberscsv?.survey_x_member?.length > 0 &&
          assignedMemberscsv?.survey_x_member?.length === length
            ? // eslint-disable-next-line
              assignedMemberscsv?.survey_x_member?.map((el, i) => {
                let id = el.user.survey_x_members[0].id;
                updateMembers({
                  variables: {
                    id: id,
                    survey_url: result[i],
                  },
                }).then(() => {
                  updateSurvey({
                    variables: {
                      id: parseInt(surveyID),
                      status:
                        buttonAction === "draft"
                          ? "draft"
                          : moment().isSameOrAfter(props.startDate)
                          ? props.status === "draft"
                            ? "draft"
                            : "active"
                          : props.status === "active"
                          ? "active"
                          : "draft",
                      links_status: value,
                    },
                  }).then(() => {
                    if (buttonAction === "active") {
                      let editOrCreate = location.pathname.split("/")[3];
                      history.push(
                        `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}/schedule`
                      );
                    } else {
                      notification.success({
                        message: "Success!",
                        description: "Saved as draft!",
                      });
                      history.push("/projectList/active");
                    }
                  });
                });
              })
            : setErrorMessage(
                `You have selected ${assignedMembers?.survey_x_member?.length} members. Please match the number of URLs added`
              );
        }
      }
    }
  };

  const handleDraft = () => {
    handleFormValues("draft");
  };

  const handleClick = () => {
    handleFormValues("active");
  };

  const handleMultipleURL = (e) => {
    const text = e.target.value;
    setMultipleURL(text);
  };

  const handleonChange = (e) => {
    setErrorMessage(null);
    setValue(e.target.value);
  };
  const handleSingleURL = (e) => {
    const text = e.target.value;
    if (text.includes(" ") || text.includes(",")) {
      setSingleURL(text);
      setErrorMessage("Enter only one URL");
      e.preventDefault();
      e.stopPropagation();
    } else {
      setErrorMessage(null);
      setSingleURL(text);
    }
  };

  return (
    <>
      <Form className="w-full" layout="vertical" form={form}>
        <Form.Item>
          <p className="text-h5 text-textMedium">Survey URLs</p>
        </Form.Item>
        <Form.Item>
          <Radio.Group
            name="surveyURL"
            onChange={handleonChange}
            className="w-full"
            value={value}
          >
            <Radio value="multiple">Upload multiple links</Radio>
            <p className="text-h11 text-textLight ml-6">
              Enter individual survey links for each member
            </p>
            {value === "multiple" && (
              <div>
                <p className="text-h8 ml-6 mb-0">Survey Links</p>
                <p className="text-h11 text-textLight ml-6">
                  Please make sure to add any new links at the end of the
                  current list only
                </p>
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  style={{ width: "97%" }}
                  className="ml-6 mb-6"
                  placeholder="Enter multiple emails seperated by commas"
                  onChange={handleMultipleURL}
                  value={multipleURL}
                />

                <Upload
                  name="file"
                  method="PUT"
                  className="float-left w-72"
                  action={(file) => {
                    let result = [];
                    var reader = new FileReader();
                    reader.readAsText(file);
                    reader.onload = function (e) {
                      result = e.target.result;
                      result = result.split("\r\n");
                      result.shift();
                      // tempData.push(result);
                    };
                    return new Promise((resolve) => {
                      // setMultipleURL(result.join(","));

                      uploadURL({
                        variables: {
                          fileName: file.name,
                          contentType: file.type,
                        },
                      }).then((res) => {
                        if (res.data.get_upload_url.message === "Success") {
                          setCheckcsv(true);
                          setMultipleURL(result.join(","));
                          resolve(res.data.get_upload_url.url);
                        }
                      });
                    });
                  }}
                  onChange={(info) => {
                    const { status } = info.file;
                    if (status === "done") {
                      message.success(
                        `${info.file.name} file uploaded successfully.`
                      );
                    } else if (status === "error") {
                      message.error(`${info.file.name} file upload failed.`);
                    }
                  }}
                >
                  <Button
                    htmlType="button"
                    className="text-base mx-5 rounded-md text-orange"
                  >
                    Upload (CSV)
                  </Button>
                </Upload>

                <Button
                  type="link"
                  className="text-base"
                  onClick={() => {
                    handleDownload("267614Client_survey_linksSheet.csv");
                  }}
                >
                  Download sample CSV file
                </Button>
              </div>
            )}
            <Divider className="mt-12" />
            <Radio value="single">Upload single URL</Radio>
            <p className="text-h11 text-textLight ml-6">
              Enter single URL to generate multiple Krea links
            </p>
            {value === "single" && (
              <>
                <Form.Item label={<p className="text-h8 ml-6">Survey Link</p>}>
                  <Input
                    value={singleURL}
                    style={{ width: "97%" }}
                    className="ml-6"
                    // onKeyPress={this.handleKeyPress}
                    onChange={handleSingleURL}
                  />
                </Form.Item>
              </>
            )}
          </Radio.Group>
        </Form.Item>
        <div style={{ color: "red" }}>
          <p className="ml-6">{errorMessage}</p>
        </div>
        <Divider />
        <Form.Item className="float-right mt-4">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => {
              location.pathname.split("/")[3] === "create-survey"
                ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                  )
                : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/emailtemplate/reminderemail/survey/${surveyID}/preview`
                  );
            }}
          >
            Back
          </Button>
          <Button
            htmlType="button"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
              props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-40"
            onClick={() => {
              if (
                moment().isSameOrAfter(startDate) &&
                props.status === "active"
              ) {
                if (value === null) {
                  setErrorMessage("Please select one option");
                } else if (
                  value === "single" &&
                  (singleURL === "" ||
                    singleURL === null ||
                    singleURL === undefined)
                ) {
                  setErrorMessage("Please enter single URL");
                } else if (
                  value === "multiple" &&
                  (multipleURL === "" || multipleURL === null)
                ) {
                  setErrorMessage("Please enter multiple URL");
                } else if (
                  value === "multiple" &&
                  (multipleURL !== "" || multipleURL !== null)
                ) {
                  const links = multipleURL.replace(/ /g, "");
                  let result = links?.split(",");
                  let length = result.filter((d) => d !== "").length;
                  assignedMembers?.survey_x_member &&
                  assignedMembers?.survey_x_member?.length > 0 &&
                  assignedMembers?.survey_x_member?.length !== length
                    ? setErrorMessage(
                        `You have selected ${assignedMembers?.survey_x_member?.length} members. Please match the number of URLs added`
                      )
                    : setIsModalVisible(true);
                } else {
                  setIsModalVisible(true);
                }
              } else {
                handleClick();
              }
            }}
          >
            Generate links
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title="Schedule"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        okText="Schedule"
      >
        {/* <div style={{ color: "red" }}>
          <p>{errorMessage}</p>
        </div> */}
        <div className="flex justify-start items-center mb-5">
          <span>
            <p className="text-gray-100">
              The newly added members will be sent the survey invitation email.
              Please tap on Schedule to proceed.
            </p>
          </span>
        </div>
        <div className="flex justify-start items-center mb-5">
          <span className="w-20">
            <p className="text-gray-100">DUE BY</p>
          </span>
          <span className="ml-5">
            <DatePicker
              format="YYYY-MM-DD HH:MM"
              disabledDate={disabledDuebyDate}
              showTime
              value={dueDate}
              showNow={false}
              className="w-40"
              onChange={(e) => {
                setDueDate(e);
              }}
            />
          </span>
        </div>
      </Modal>
    </>
  );
}
export default SurveyLink;
