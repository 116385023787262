import { LoadingOutlined } from "@ant-design/icons";
import React from "react";
export default function Loading() {
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex justify-center align-center z-50 bg-black bg-opacity-10 items-center">
      <div>
        <LoadingOutlined style={{ fontSize: 30 }} className="text-orange" />
      </div>
    </div>
  );
}
