import { useLazyQuery, useMutation } from "@apollo/client";
import { Col, Row, Card, Form, Input, Button, notification, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import logo from "../../assets/logo.svg";
import { CREATE_PASSWORD, GET_USER_INFO } from "./graphql";

const ResetPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { passkey } = useParams();
  const [verifyingToken, setVerifyingToken] = useState(false);
  const location = useLocation();

  const _redirectToHome = (message = "Something went wrong") => {
    notification.error({ message: message });
    history.push("/");
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    form.setFieldsValue({
      email: queryParams.get("email"),
      password: "",
      confirm: "",
    });
    // eslint-disable-next-line
  }, [location.search]);

  // eslint-disable-next-line
  const [fetchUserInfo] = useLazyQuery(GET_USER_INFO, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.user && data.user.length === 1) {
        if (!data.user[0].is_active) {
          _redirectToHome("User is not active.");
        } else {
          form.setFieldsValue({
            email: data.user[0].email,
            password: "",
            confirm: "",
          });
          setVerifyingToken(false);
        }
      } else if (data.user.length > 1) {
        _redirectToHome("Multiple users found for email");
      } else {
        _redirectToHome("Email not found");
      }
    },
    onError: (error) => {
      _redirectToHome(error.message);
    },
  });

  // eslint-disable-next-line
  useEffect(async () => {
    if (!passkey) {
      notification.error({ message: "Invalid passkey" });
      history.push("/");
    }
    // eslint-disable-next-line
  }, [passkey]);

  const [resetPassword] = useMutation(CREATE_PASSWORD, {
    onCompleted: (data) => {
      if (data.add_password) {
        notification.success({ message: data.add_password.message });
        if (data.add_password.role_id === 6) {
          history.push("/mobile-landing-page");
        } else {
          history.push("/");
        }
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  return (
    <Spin spinning={verifyingToken}>
      <Row align="middle" className="h-screen">
        <Col
          md={{ span: 8, offset: 8 }}
          sm={{ span: 24, offset: 0 }}
          xs={{ span: 24, offset: 0 }}
        >
          <Card
            className="shadow-lg"
            style={{
              padding: "65px 20px",
            }}
          >
            <div className="h-12 flex justify-center items-center mb-8">
              <img src={logo} alt="krea" />
            </div>
            <Form
              name="resetpwd_form"
              colon={false}
              layout="vertical"
              form={form}
              onFinish={(values) => {
                if (values.password !== values.confirm) {
                  notification.warning({ message: "Passwords do not match" });
                  return;
                }
                resetPassword({
                  variables: {
                    pass_key: passkey,
                    password: values.password,
                  },
                });
              }}
            >
              <Form.Item name="email" label="Email">
                <Input disabled size="large" className="rounded" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 8) {
                        return Promise.reject(
                          "Please enter minimum 8 characters"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password size="large" className="rounded" />
              </Form.Item>
              <Form.Item
                name="confirm"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter the new password again",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 8) {
                        return Promise.reject(
                          "Please enter minimum 8 characters"
                        );
                      } else if (value !== form.getFieldValue("password")) {
                        return Promise.reject("Password mismatch");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password size="large" className="rounded" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button text-base mt-5 rounded"
                  block
                  size="large"
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default ResetPassword;
