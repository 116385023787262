import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Divider,
  Button,
  Drawer,
  Spin,
  Select,
  DatePicker,
  notification,
} from "antd";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_USER_DETAILS,
  UPDATE_USER,
  UPDATE_PASSWORD,
  UPDATE_INTERVIEWER_STATUS,
  UPDATE_MANAGER_LEAVE,
} from "./graphql";
import jwtDecode from "jwt-decode";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;
const Settings = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [leaveMessage, setLeaveMessage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false);
  const [form] = useForm();
  const [passwordForm] = useForm();
  let mobile = /^[0-9]{10}$/;
  // eslint-disable-next-line
  const [id, setId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-user-id"
    ]
  );
  const [role, setRole] = useState(null);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const { data: userData, loading: userLoading } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });

  const [updateUser] = useMutation(UPDATE_USER);
  const [updatePasword] = useMutation(UPDATE_PASSWORD);
  const [updateInterviewerStatus] = useMutation(UPDATE_INTERVIEWER_STATUS);
  const [updateManagerLeave] = useMutation(UPDATE_MANAGER_LEAVE);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (userData) {
      if (
        userData?.user[0]?.meta_date?.availability === "On Leave" ||
        userData?.user[0]?.meta_date === null
      ) {
        setShow(true);
        setLeaveMessage(
          "Please note the all 'On leave' changes will get applied within 30 mins from start time"
        );
      }
      if (
        userData?.user[0]?.meta_date &&
        userData?.user[0]?.meta_date?.date_from !== null
      ) {
        form.setFieldsValue({
          firstName: userData?.user[0]?.f_name,
          lastName: userData?.user[0]?.l_name,
          email: userData?.user[0]?.email,
          phone: userData?.user[0]?.phone_number,
          status: userData?.user[0]?.meta_date?.availability,
          date: [
            moment(userData?.user[0]?.meta_date?.date_from),
            moment(userData?.user[0]?.meta_date?.date_to),
          ],
        });
      } else {
        form.setFieldsValue({
          firstName: userData?.user[0]?.f_name,
          lastName: userData?.user[0]?.l_name,
          email: userData?.user[0]?.email,
          phone: userData?.user[0]?.phone_number,
          status:
            userData?.user[0]?.meta_date === null
              ? "Available"
              : userData?.user[0]?.meta_date?.availability,
          date: [],
        });
      }
    }
    // eslint-disable-next-line
  }, [userData]);

  function disabledDate(current) {
    return current && current < moment().add(-1, "day");
  }

  const onFinish = (values) => {
    const after30 = moment().add(30, "m");
    if (values.date && moment(values.date[0]).isSameOrBefore(moment(after30))) {
      setErrorMessage(
        "Time should be minimum 30 minutes greater than current time"
      );
    } else {
      setErrorMessage(null);
      updateUser({
        variables: {
          id: parseInt(id),
          object: {
            f_name: values.firstName,
            l_name: values.lastName,
            phone_number: values.phone,
            meta_date: {
              availability: "Available",
              date_from: values?.date?.length > 0 ? values.date[0] : null,
              date_to: values?.date?.length > 0 ? values.date[1] : null,
            },
          },
        },
      })
        .then(() => {
          if (values.date === undefined) {
            if (userData?.user[0]?.role_id === 4) {
              updateInterviewerStatus({
                variables: {
                  id: parseInt(id),
                },
              }).then(() => {
                notification["success"]({
                  message: "Details updated",
                  description: "Details have been updated for this user",
                });
              });
            } else if (userData?.user[0]?.role_id === 3) {
              updateManagerLeave({
                variables: {
                  id: parseInt(id),
                },
              }).then(() => {
                notification["success"]({
                  message: "Details updated",
                  description: "Details have been updated for this user",
                });
              });
            }
          } else {
            notification["success"]({
              message: "Details updated",
              description: "Details have been updated for this user",
            });
          }
        })
        .catch(() => {
          notification["error"]({
            message: "Error",
            description: "An error occurred while updating the details.",
          });
        });
    }
  };

  const onPasswordFinish = (values) => {
    updatePasword({
      variables: {
        id: parseInt(id),
        old: values.old,
        new: values.new,
      },
    })
      .then((res) => {
        if (res?.data?.update_password?.message === "Password Updated") {
          passwordForm.resetFields(["old", "new", "confirm"]);
          openNotificationWithIcon();
          setVisible(false);
        }
      })
      .catch((res) => {
        notification["error"]({
          message: "Error",
          description: res?.data?.update_password?.message,
        });
      });
  };

  const openNotificationWithIcon = () => {
    notification["success"]({
      message: "Password updated",
      description: "Password has been updated for this user",
    });
  };
  return (
    <Spin spinning={userLoading}>
      <Row>
        <Col
          span={24}
          className="p-8 text-2xl font-bold"
          style={{ color: "#4f4f4f" }}
        >
          Settings
        </Col>
      </Row>
      <Row>
        <Col span={24} className="px-8">
          <Card
            style={{
              minHeight: "60vh",
              boxShadow: "0px 2px 5px rgba(33, 33, 33, 0.1)",
              borderRadius: "5px",
            }}
          >
            <Row justify="start">
              <Col
                span={10}
                className="p-4 text-lg font-bold"
                style={{ color: "#4f4f4f" }}
              >
                General Information
              </Col>
              <Col span={10}>
                <Button
                  type="link"
                  size="large"
                  className="float-right"
                  htmlType="submit"
                  onClick={form.submit}
                >
                  Save changes
                </Button>
              </Col>
            </Row>
            <Form
              colon={false}
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              onFinish={onFinish}
              form={form}
              requiredMark={false}
            >
              <Row justify="space-between" align="middle">
                <Col span={20}>
                  <Row>
                    <Col span={12} className="p-4">
                      <Form.Item
                        name="firstName"
                        label="FIRST NAME"
                        rules={[
                          { required: true, message: "First name is required" },
                        ]}
                      >
                        <Input size="large" className="rounded" />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="p-4">
                      <Form.Item
                        name="lastName"
                        label="LAST NAME"
                        rules={[
                          { required: true, message: "Last name is required" },
                        ]}
                      >
                        <Input size="large" className="rounded" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} className="p-4">
                      <Form.Item name="email" label="EMAIL">
                        <Input size="large" disabled className="rounded" />
                      </Form.Item>
                    </Col>
                    <Col span={12} className="p-4">
                      <Form.Item
                        name="phone"
                        label="PHONE"
                        rules={[
                          {
                            required: true,
                            message: "Phone number is required",
                          },
                          () => ({
                            validator(_, value) {
                              if (!mobile.test(value)) {
                                return Promise.reject(
                                  "Please enter a valid phone number"
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input size="large" className="rounded" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider style={{ width: "70%" }} />
              {role !== "poc" && role !== "client" && (
                <>
                  <Row>
                    <Col
                      span={20}
                      className="p-4 text-lg font-bold"
                      style={{ color: "#4f4f4f" }}
                    >
                      Availability
                    </Col>
                  </Row>
                  <Row justify="space-between" align="middle">
                    <Col span={20}>
                      <Row>
                        <Col span={12} className="p-4">
                          <Form.Item
                            name="status"
                            label="STATUS"
                            rules={[
                              {
                                required: true,
                                message: "Availability is required",
                              },
                            ]}
                          >
                            <Select
                              size="large"
                              className="rounded"
                              onChange={(e) => {
                                if (e === "On Leave") {
                                  setShow(true);
                                  setLeaveMessage(
                                    "Please note the all 'On leave' changes will get applied within 30 mins from start time"
                                  );
                                } else {
                                  setShow(false);
                                  setLeaveMessage(null);
                                }
                              }}
                            >
                              <Option value="On Leave">On Leave</Option>
                              <Option value="Available">Available</Option>
                            </Select>
                          </Form.Item>
                          {show && (
                            <div style={{ color: "blue", marginLeft: "7rem" }}>
                              <p>{leaveMessage}</p>
                            </div>
                          )}
                        </Col>
                        {show && (
                          <Col span={12} className="p-4">
                            <Form.Item
                              name="date"
                              label="DATE AND TIME"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select dates",
                                },
                              ]}
                            >
                              <RangePicker
                                size="large"
                                className="w-full rounded ml-2"
                                disabledDate={disabledDate}
                                showTime={{
                                  defaultValue: [
                                    moment("00:00:00", "HH:mm:ss"),
                                    moment("11:59:59", "HH:mm:ss"),
                                  ],
                                }}
                                format="YYYY-MM-DD HH:mm"
                              />
                            </Form.Item>
                            <div style={{ color: "red", marginLeft: "7.5rem" }}>
                              <p>{errorMessage}</p>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Divider style={{ width: "70%" }} />
                </>
              )}
            </Form>
            <Row>
              <Col span={24} className="p-4">
                <Button
                  type="link"
                  className="text-base p-0"
                  onClick={() => showDrawer()}
                >
                  Change password
                </Button>
              </Col>
              <Col span={24} className="px-4">
                <a href="mailto:ask@krea.in" className="text-base">
                  Get support
                </a>
                {/* <Button
                  type="link"
                  className="text-base"
                  onclick="location.href='ask@krea.in';"
                >
                  Get support
                </Button> */}
              </Col>
            </Row>
          </Card>
          <Drawer
            title="Change password"
            placement="right"
            onClose={onClose}
            visible={visible}
            width="40%"
          >
            <Form
              name="password form"
              colon={false}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              labelAlign="left"
              form={passwordForm}
              onFinish={onPasswordFinish}
              requiredMark={false}
            >
              <Form.Item
                label="Old password"
                name="old"
                className="mb-4 rounded"
                rules={[
                  {
                    required: true,
                    message: "Please enter old password",
                  },
                ]}
              >
                <Input type="password" size="large" />
              </Form.Item>
              <Form.Item
                label="New password"
                name="new"
                className="mb-4 rounded"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 8) {
                        return Promise.reject(
                          "Please enter minimum 8 characters"
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="password" size="large" />
              </Form.Item>
              <Form.Item
                label="Confirm password"
                name="confirm"
                className="mb-4 rounded"
                rules={[
                  {
                    required: true,
                    message: "Please enter the new password again",
                  },
                  () => ({
                    validator(_, value) {
                      if (value && value.length < 8) {
                        return Promise.reject(
                          "Please enter minimum 8 characters"
                        );
                      } else if (value !== passwordForm.getFieldValue("new")) {
                        return Promise.reject("Password mismatch");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input type="password" size="large" />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item
                    labelCol={{ span: 0, offset: 0 }}
                    wrapperCol={{ span: 24, offset: 0 }}
                  >
                    <div className="h-16 mt-4">
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="float-right text-base rounded-md px-6"
                      >
                        Save
                      </Button>

                      <div className="text-textMedium float-right pt-1 pr-6">
                        <Button
                          type="link"
                          htmlType="button"
                          onClick={() => {
                            passwordForm.resetFields(["old", "new", "confirm"]);
                            setVisible(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </Col>
      </Row>
    </Spin>
  );
};

export default Settings;
