import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Spin,
    Card,
    Button,
    Table,
} from "antd";
import { GET_REJECTION_LIST } from "./graphql";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
import jwtDecode from "jwt-decode";

function RejectionList() {
    const [tableData, setTableData] = useState([]);
    const [id, setId] = useState(null);

    const { data, loading } = useQuery(GET_REJECTION_LIST, {
        variables: { rejected_by: id },
        fetchPolicy: "network-only",
    });
    useEffect(() => {
        try {
            let decodedId = jwtDecode(localStorage.getItem("token"))[
                "https://hasura.io/jwt/claims"
            ]["X-Hasura-User-Id"];
            setId(decodedId);
        } catch (error) {
            console.log(error);
        }
    }, []);

    useEffect(() => {
        if (data && data.user_rejected) {
            const updatedData = data.user_rejected.map((user) => {
                if (Array.isArray(user.meta_data) && user.meta_data.length > 0) {
                    console.log(user.meta_data, 'user.meta_data');
                    const metaDataArray = user.meta_data.map((metaData) => {
                        console.log(metaData, 'metaData')
                        const columns = {
                            "respondent id": metaData['respondent id'],
                            "title": metaData['title'],
                            "first name": metaData['first name'],
                            "last name": metaData['last name'],
                            "gender": metaData['gender'],
                            "year of birth": metaData['year of birth'],
                            "primary email": metaData['primary email'],
                            "secondary email": metaData['secondary email'],
                            "mobile number 1": metaData['mobile number 1'],
                            "mark mobile 1 as whatsapp": metaData['mark mobile 1 as whatsapp'],
                            "mobile number 2": metaData['mobile number 2'],
                            "mark mobile 2 as whatsapp": metaData['mark mobile 2 as whatsapp'],
                            "phone": metaData['phone'],
                            "state": metaData['state'],
                            "state details": metaData['state details'],
                            "city": metaData['city'],
                            "city details": metaData['city details'],
                            "city category": metaData['city category'],
                            "zone": metaData['zone'],
                            "pincode": metaData['pincode'],
                            ...(user?.member_type === 'Physician' && {
                                "primary speciality": metaData['primary speciality'],
                                "primary details": metaData['primary details'],
                                "super speciality": metaData['super speciality'],
                                "super speciality details": metaData['super speciality details'],
                                "secondary speciality": metaData['secondary speciality'],
                                "secondary details": metaData['secondary details'],
                            }),
                            ...(user?.member_type === 'Technician' && {
                                "years of experience": metaData['years of experience'],
                                "profession": metaData['profession'],
                                "profession details": metaData['profession details'],
                                "sub-category1": metaData['sub-category1'],
                                "sub-category1 details": metaData['sub-category1 details'],
                                "sub-category2": metaData['sub-category2'],
                                "sub-category2 details": metaData['sub-category2 details'],
                            }),
                            ...((user?.member_type === 'Physician' || user?.member_type === 'Patient') && {
                                "name of the hospital": metaData['name of the hospital'],
                            }),
                            ...(user?.member_type !== 'Consumer' && {
                                "hospital type": metaData['hospital type'],
                            }),
                            "no of beds": metaData['no of beds'],
                            ...(user?.member_type === 'Patient' && {
                                "ailment list": metaData['ailment list'],
                            }),
                            ...(user?.member_type === 'Physician' && {
                                "consulting hospital 1": metaData['consulting hospital 1'],
                                "consulting hospital 2": metaData['consulting hospital 2'],
                                "project compatibility": metaData['project compatibility'],
                                "practice start year post residency": metaData['practice start year post residency'],
                            }),
                            ...(user?.member_type !== 'Physician' && {
                                "Which Year did you start practicing (post Residency)":
                                    metaData['practice start year post residency'],
                            }),
                            "total incentive processed": metaData['total incentive processed'],
                            "previously participated studies": metaData['previously participated studies'],
                            "mode of payment": metaData['mode of payment'],
                            "payment details": metaData['payment details'],
                            "validated date": metaData['validated date'],
                            "status": metaData['status'],
                            "access to mobile app": metaData['access to mobile app'],
                        };

                        return columns;
                    });
                    return {
                        member_type: user.member_type || "",
                        updatedOn: moment(user.created_at).format("MMM DD, yyyy"),
                        updatedTime: moment(user.created_at).format("HH:mm a"),
                        metaData: metaDataArray,
                    };
                } else {
                    return {
                        member_type: user.member_type || "",
                        updatedOn: moment(user.created_at).format("MMM DD, yyyy"),
                        updatedTime: moment(user.created_at).format("HH:mm a"),
                        metaData: {},
                    };
                }
            });

            setTableData(updatedData);
        }
    }, [data]);

    useEffect(() => {
    }, [tableData]);

    const customButtonStyle = {
        border: "none",
        color: "#E08036",
        display: "flex",
        alignItems: "center",
    };

    const RejectionColumns = [
        {
            title: "S.No",
            dataIndex: "serialNumber",
            key: "serialNumber",
            width: 80,
            render: (_, __, index) => index + 1,
        },
        {
            title: "MEMBER TYPE",
            dataIndex: "member_type",
            key: "member_type",
            width: 250,
        },
        {
            title: "UPLOADED ON",
            dataIndex: "updatedOn",
            key: "updatedOn",
            width: 100,
            render: (_, record) => (
                <div>
                    <div>
                        <div className="font-medium text-textDark text-base">
                            {record.updatedOn}
                        </div>
                        <div className=" text-textLight text-sm font-normal">
                            {record.updatedTime}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "",
            dataIndex: "csv",
            key: "csv",
            width: 200,
            render: (text, record) => {
                return (
                    <>
                        <Button
                            type="text"
                            icon={<DownloadOutlined />}
                            onClick={() => handleDownload(record.metaData, ` ${record.member_type}.csv`)}
                            style={customButtonStyle}
                        >
                            Download CSV
                        </Button>
                    </>
                )

            }
        },
    ]
    const handleDownload = (metaData, filename) => {
        if (!metaData || metaData.length === 0) {
            return;
        }
        if (!metaData[0]) {
            console.error('Metadata is empty.');
            return;
        }

        const headers = Object.keys(metaData[0]);
        const csvContentArray = [];

        csvContentArray.push(headers.join(','));
        metaData.forEach((item) => {
            const values = headers.map((header) => item[header]);
            csvContentArray.push(values.join(','));
        });

        const csvContent = csvContentArray.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = filename;
        anchor.click();
    };

    return (
        <Spin spinning={loading}>
            <Row className="px-9 py-4">
                <div className="w-full flex justify-between">
                    <p className="text-textMedium text-2xl w-60 float-left mb-0">
                        Reject List
                    </p>
                </div>
            </Row>
            <Row className="px-10 py-2">
                <Col span={24}>
                    <Card className="px-0 py-0" bodyStyle={{ padding: 0 }}>
                        <Table
                            columns={RejectionColumns}
                            dataSource={tableData}
                            scroll={false}
                        ></Table>
                    </Card>
                </Col>
            </Row>
        </Spin >
    );
}

export default RejectionList;