import { gql } from "@apollo/client";

export const CREATE_ADMIN = gql`
  mutation ADMIN(
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $additional_fields: jsonb
    $meta_date: jsonb
  ) {
    insert_user_one(
      object: {
        f_name: $firstName
        l_name: $lastName
        phone_number: $phone
        email: $email
        password: $password
        role_id: 2
        is_verified: true
        is_active: true
        additional_data: $additional_fields
        meta_date: $meta_date
      }
    ) {
      id
    }
  }
`;

export const CREATE_MANAGER = gql`
  mutation MANAGER(
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $additional_fields: jsonb
    $is_verified: Boolean
    $is_active: Boolean
    $meta_date: jsonb
  ) {
    insert_user_one(
      object: {
        f_name: $firstName
        l_name: $lastName
        phone_number: $phone
        email: $email
        password: $password
        role_id: 3
        additional_data: $additional_fields
        is_verified: $is_verified
        is_active: $is_active
        meta_date: $meta_date
      }
    ) {
      id
    }
  }
`;

export const CREATE_INTERVIEWER = gql`
  mutation INTERVIEWER(
    $firstName: String
    $lastName: String
    $phone: String
    $email: String
    $password: String
    $additional_fields: jsonb
    $title: String
    $language: jsonb
    $int_privileges: jsonb
    $wpm: Int
    $compatibility: Int
    $is_verified: Boolean
    $is_active: Boolean
    $meta_date: jsonb
  ) {
    insert_user_one(
      object: {
        f_name: $firstName
        l_name: $lastName
        phone_number: $phone
        email: $email
        password: $password
        role_id: 4
        additional_data: $additional_fields
        title: $title
        language: $language
        int_privileges: $int_privileges
        words_per_min: $wpm
        project_compatibility: $compatibility
        is_verified: $is_verified
        is_active: $is_active
        meta_date: $meta_date
      }
    ) {
      id
    }
  }
`;

export const GET_USERS = gql`
  query GET_USERS {
    user(order_by: { id: asc }, where: { role_id: { _nin: [1, 5, 6, 7] } }) {
      id
      email
      f_name
      l_name
      phone_number
      role {
        id
        name
        description
      }
      is_active
      additional_data
      title
      language
      words_per_min
      project_compatibility
      is_verified
      int_privileges
    }
  }
`;

export const GET_EMAIL_PHONE = gql`
  query GET_EMAIL_PHONE {
    user {
      email
      phone_number
    }
  }
`;

export const DEACTIVATE_ACTIVATE_USERS = gql`
  mutation DEACTIVATE_ACTIVATE_USERS($id: Int!, $status: Boolean) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_active: $status }) {
      id
      is_active
    }
  }
`;

export const EDIT_USERS = gql`
  mutation EDIT_USERS($object: user_set_input, $id: Int!) {
    update_user_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      is_active
    }
  }
`;

export const GET_LANGUAGES = gql`
  query GET_LANGUAGES {
    language {
      id
      language_name
    }
  }
`;

export const GET_COMPATIBILITY = gql`
  query GET_COMPATIBILITY {
    project_compatibility {
      id
      project_compatibility_text
    }
  }
`;

export const VERIFY_USER = gql`
  mutation VERIFY_USER($id: Int!, $status: Boolean) {
    update_user_by_pk(pk_columns: { id: $id }, _set: { is_verified: $status }) {
      id
      is_verified
    }
  }
`;

export const REJECT_USER = gql`
  mutation REMOVE_USER($id: Int) {
    delete_user(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
