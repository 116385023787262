import { gql } from "graphql-tag";

export const GET_ADMIN_DASHBOARD = gql`
  query dashboard {
    total_projects: project_aggregate {
      aggregate {
        count
      }
    }
    active_projects: project_aggregate(where: { status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
    completed_projects: project_aggregate(
      where: { status: { _eq: "completed" } }
    ) {
      aggregate {
        count
      }
    }
    drafts_projects: project_aggregate(where: { status: { _eq: "draft" } }) {
      aggregate {
        count
      }
    }
    admins: user_aggregate(
      where: { role_id: { _eq: 2 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    managers: user_aggregate(
      where: { role_id: { _eq: 3 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    interviewers: user_aggregate(
      where: { role_id: { _eq: 4 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    clients: user_aggregate(
      where: { role_id: { _eq: 5 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    members: user_aggregate(
      where: { role_id: { _eq: 6 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    physician: user_aggregate(
      where: { member_type_id: { _eq: 1 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    technician: user_aggregate(
      where: { member_type_id: { _eq: 2 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    patient: user_aggregate(
      where: { member_type_id: { _eq: 3 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    consumer: user_aggregate(
      where: { member_type_id: { _eq: 4 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    cardiology: user_aggregate(
      where: { primary_specialty: { _eq: 10 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    oncology: user_aggregate(
      where: { primary_specialty: { _eq: 65 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    neurology: user_aggregate(
      where: { primary_specialty: { _eq: 57 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    others: user_aggregate(
      where: {
        primary_specialty: { _nin: [10, 57, 65] }
        is_active: { _eq: true }
      }
    ) {
      aggregate {
        count
      }
    }
    live_projects: project(limit: 5, order_by: { id: desc }) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      currency
      cost_per_interview
      incentive
      estimated_ir
      budget
      total_budget
      third_party_survey_name
      third_party_survey_url
      survey_type
      member_types
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      leaveManager: manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_MANAGER_DASHBOARD = gql`
  query manager_dashboard {
    assigned_projects: project_aggregate {
      aggregate {
        count
      }
    }
    active_surveys: survey_aggregate(where: { status: { _eq: "active" } }) {
      aggregate {
        count
      }
    }
    closed_surveys: survey_aggregate(where: { status: { _eq: "completed" } }) {
      aggregate {
        count
      }
    }
    physician: user_aggregate(
      where: { member_type_id: { _eq: 1 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    technician: user_aggregate(
      where: { member_type_id: { _eq: 2 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    patient: user_aggregate(
      where: { member_type_id: { _eq: 3 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    consumer: user_aggregate(
      where: { member_type_id: { _eq: 4 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    interviewers: user_aggregate(
      where: { role_id: { _eq: 4 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    interviewers_available: user_aggregate(
      where: { role_id: { _eq: 4 }, is_active: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
    live_projects: project(
      where: { manager_x_projects: { manager_on_leave: { _eq: false } } }
      order_by: { id: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      total_budget
      third_party_survey_name
      third_party_survey_url
      survey_type
      member_types
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: false } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        name
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
        created_by_user {
          id
        }
      }
    }
    leave_projects: project(
      where: { status: { _in: ["active", "re-opened", "completed"] } }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      total_budget
      third_party_survey_name
      third_party_survey_url
      survey_type
      member_types
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(
        order_by: { priority: asc }
        limit: 1
        where: { manager_on_leave: { _eq: true } }
      ) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        name
        status
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
        created_by_user {
          id
        }
      }
      manager_x_projects_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

export const GET_INTERVIEWER_DASHBOARD = gql`
  query manager_dashboard($interviewer_id: Int) {
    total_projects: project_aggregate(
      where: {
        surveys: {
          survey_x_members: {
            survey_x_member_x_interviewers: {
              interviewer_on_leave: { _eq: false }
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    active_projects: project_aggregate(
      where: {
        _and: [
          {
            status: { _eq: "active" }
            surveys: {
              survey_x_members: {
                survey_x_member_x_interviewers: {
                  interviewer_on_leave: { _eq: false }
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    assigned_members: survey_x_member_aggregate(
      distinct_on: member_id
      where: {
        survey_x_member_x_interviewers: { interviewer_on_leave: { _eq: false } }
      }
    ) {
      aggregate {
        count(columns: member_id)
      }
    }
    pending: survey_x_member_x_interviewer_aggregate(
      where: {
        _and: [
          { status: { _nin: ["completed", "approved", "Approved"] } }
          { interviewer_id: { _eq: $interviewer_id } }
          { interviewer_on_leave: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    completed: survey_x_member_aggregate(
      where: {
        _and: [
          {
            survey_x_member_x_interviewers: {
              status: { _in: ["completed", "approved", "Approved"] }
            }
          }
          {
            survey_x_member_x_interviewers: {
              interviewer_on_leave: { _eq: false }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    completed_projects: project_aggregate(
      where: {
        _and: [
          { status: { _eq: "completed" } }
          {
            surveys: {
              survey_x_members: {
                survey_x_member_x_interviewers: {
                  interviewer_on_leave: { _eq: false }
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    pending_projects_list: project(
      where: {
        status: { _eq: "active" }
        surveys: {
          survey_x_members: {
            survey_x_member_x_interviewers: {
              interviewer_on_leave: { _eq: false }
            }
          }
        }
      }
      limit: 5
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      member_types
      survey_type
      surveys {
        id
        survey_x_members_aggregate(where: { state: { _eq: "active" } }) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
    completed_project_list: project(
      where: { status: { _eq: "completed" } }
      limit: 5
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        survey_x_members_aggregate(where: { state: { _eq: "active" } }) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
  }
`;

export const GET_CLIENT_DASHBOARD = gql`
  query manager_dashboard {
    total_projects: project_aggregate(
      where: {
        surveys: {
          survey_x_members: {
            survey_x_member_x_interviewers: {
              interviewer_on_leave: { _eq: false }
            }
          }
        }
      }
    ) {
      aggregate {
        count
      }
    }
    active_projects: project_aggregate(
      where: {
        _and: [
          {
            status: { _eq: "active" }
            surveys: {
              survey_x_members: {
                survey_x_member_x_interviewers: {
                  interviewer_on_leave: { _eq: false }
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    assigned_members: survey_x_member_aggregate(
      distinct_on: member_id
      where: {
        survey_x_member_x_interviewers: { interviewer_on_leave: { _eq: false } }
      }
    ) {
      aggregate {
        count(columns: member_id)
      }
    }
    pending: survey_x_member_x_interviewer_aggregate(
      where: {
        _and: [
          { status: { _nin: ["completed", "approved", "Approved"] } }
          { interviewer_on_leave: { _eq: false } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    completed: survey_x_member_aggregate(
      where: {
        _and: [
          {
            survey_x_member_x_interviewers: {
              status: { _in: ["completed", "approved", "Approved"] }
            }
          }
          {
            survey_x_member_x_interviewers: {
              interviewer_on_leave: { _eq: false }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    completed_projects: project_aggregate(
      where: {
        _and: [
          { status: { _eq: "completed" } }
          {
            surveys: {
              survey_x_members: {
                survey_x_member_x_interviewers: {
                  interviewer_on_leave: { _eq: false }
                }
              }
            }
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    pending_projects_list: project(
      where: { status: { _eq: "active" } }
      limit: 5
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      member_types
      survey_type
      surveys {
        id
        completed: survey_x_members_aggregate(
          where: {
            _and: {
              _and: { state: { _in: ["completed", "approved", "Approved"] } }
            }
          }
        ) {
          aggregate {
            count
          }
        }
        screened: survey_x_members_aggregate(
          where: { _and: { state: { _eq: "screened" } } }
        ) {
          aggregate {
            count
          }
        }
        quotaFull: survey_x_members_aggregate(
          where: { _and: { state: { _eq: "quotaFull" } } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "approved", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
    completed_project_list: project(
      where: { status: { _eq: "completed" } }
      limit: 5
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      surveys {
        id
        survey_x_members_aggregate(where: { state: { _eq: "active" } }) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
        completed: survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        screened: survey_x_members_aggregate(
          where: { state: { _eq: "screened" } }
        ) {
          aggregate {
            count
          }
        }
        quotaFull: survey_x_members_aggregate(
          where: { state: { _eq: "quotaFull" } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
      }
    }
  }
`;

export const GET_POC_DASHBOARD = gql`
  query poc_dashboard {
    project(
      where: {
        status: { _eq: "active" }
        # project_x_pocs: { poc_id: { _eq: $poc_id } }
      }
      order_by: { updated_at: desc }
    ) {
      id
      name
      status
      start_date
      end_date
      project_type
      survey_type
      member_types
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      surveys {
        id
        survey_x_members_aggregate(
          where: { state: { _in: ["completed", "approved", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        survey_x_members {
          id
          state
        }
        completed: survey_x_members_aggregate(
          where: { state: { _in: ["completed", "Approved"] } }
        ) {
          aggregate {
            count
          }
        }
        screened: survey_x_members_aggregate(
          where: { state: { _eq: "screened" } }
        ) {
          aggregate {
            count
          }
        }
        quotaFull: survey_x_members_aggregate(
          where: { state: { _eq: "quotaFull" } }
        ) {
          aggregate {
            count
          }
        }
      }
      id
      sample
    }
  }
`;

export const GET_ON_LEAVE_PROJECTS = gql`
  query GET_PRIORITY1_PROJECTS {
    priorityProjects: project(order_by: { id: desc }) {
      id
      name
      status
      start_date
      end_date
      status
      client {
        f_name
        l_name
        gender
        id
        email
      }
      project_type
      third_party_survey_name
      third_party_survey_url
      projectTypeByProjectType {
        name
        id
      }
      manager_x_projects(where: { priority: { _eq: 1 } }) {
        user {
          f_name
          l_name
          gender
          id
          email
          l_name
        }
        priority
      }
      sample
      member_types
      survey_type
      active: surveys {
        name
        survey_x_members(
          order_by: { member_id: asc }
          distinct_on: [member_id]
        ) {
          id
          state
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: false } }
          ) {
            interviewer_id
            interviewer_on_leave
            priority
            status
          }
        }
      }
      leave: surveys {
        name
        survey_x_members(
          order_by: { member_id: asc }
          distinct_on: [member_id]
        ) {
          id
          survey_x_member_x_interviewers_aggregate {
            aggregate {
              count
            }
          }
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: true } }
          ) {
            interviewer_id
            interviewer_on_leave
            priority
            status
          }
        }
      }
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS($id: Int) {
    user(where: { id: { _eq: $id } }) {
      id
      meta_date
    }
  }
`;
