import React, { useState, useEffect, useMemo, useContext } from "react";
import {
  Button,
  Form,
  Space,
  Input,
  Divider,
  Switch,
  DatePicker,
  notification,
} from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./selectMembers.css";
import moment from "moment";
import { formatDateTime } from "../../../../../utils/dateTime";
import {
  // SURVEYxMEMBERS,
  UPDATE_SURVEY_DETAILS,
  DELETE_SURVEY_X_MEMBERS,
  PROJECT_DATES,
  GET_ASSIGNED_MEMBERS,
} from "../graphql";
import { useMutation, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import MemberTable from "../../../../../components/memberTable";
import ColumnEditor from "../../../../../components/ColumnEditor";
import { memberColumns, columnStatus as memberColumnStatus, roleBasedColumns, userMemberData } from "../../../../../components/memberColumns";
import { RoleContext } from "../../../../../context/RoleContext";
function MemberList(props) {
  const { surveyID, projectID } = useParams();
  // const memberListDetails = useRecoilValue(projectForm);
  const [isEnabled, setIsEnabled] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  let searchInput;
  const history = useHistory();
  const location = useLocation();
  const { role } = useContext(RoleContext);
  const savedonlineMemberKrea = localStorage.getItem('onlineMemberKrea');
  const onlineMemberKreainitial = savedonlineMemberKrea
    ? JSON.parse(savedonlineMemberKrea)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(onlineMemberKreainitial);
  const [deleteMembers] = useMutation(DELETE_SURVEY_X_MEMBERS);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_DETAILS);
  const { data: assignedMembers, refetch } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "no-cache",
  });
  const { data: projectDates } = useQuery(PROJECT_DATES, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    localStorage.setItem('onlineMemberKrea', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    refetch();
    if (assignedMembers?.survey && assignedMembers?.survey?.length !== 0) {
      let time =
        moment().isSameOrAfter(
          formatDateTime(assignedMembers?.survey[0]?.start_date)
        ) && props.status !== "draft";
      setIsEnabled(time);
      setStartDate(
        assignedMembers?.survey[0]?.start_date !== null
          ? moment(assignedMembers?.survey[0]?.start_date)
          : null
      );
      setDueDate(
        assignedMembers?.survey[0]?.end_date !== null
          ? moment(assignedMembers?.survey[0]?.end_date)
          : null
      );
      setSwitchChecked(
        assignedMembers?.survey[0]?.display_questions_on_survey_cards
      );
    }
    if (
      assignedMembers?.survey_x_member &&
      assignedMembers?.survey_x_member?.length > 0
    ) {

      let object,
        newObject = [];
      object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
      object.forEach((item, index) => {
        newObject.push(userMemberData(item?.user));
      });

      setTableData(newObject);
    }
    // eslint-disable-next-line
  }, [assignedMembers, projectDates, location]);

  const memColumns = useMemo(() => {
    return [...memberColumns(columnStatus), ...roleBasedColumns(role, columnStatus),

    ]
      .filter(data => data.isShow === undefined || data.isShow)
  }, [role, columnStatus])

  const handleClick = () => {
    props.handleToggle(false);
    history.goBack();
  };

  const switchOnChange = (checked) => {
    setSwitchChecked(checked);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const handleFormValues = (buttonAction) => {
    let payload = [];
    let surveymemberID = [];

    if (!startDate) {
      setErrorMessage("Please select the starting date of the survey");
    } else if (!dueDate) {
      setErrorMessage("Please select the due date of the survey");
    } else if (moment(startDate).isSameOrAfter(moment(dueDate))) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (formatDateTime(startDate) === formatDateTime(dueDate)) {
      setErrorMessage("Due by time should be greater than Go-live time");
    } else if (
      !isEnabled &&
      moment(startDate).isSameOrBefore(moment()) &&
      moment(startDate).date() === moment().date()
    ) {
      setErrorMessage("Select a Go-live time greater than current time");
    } else {
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        assignedMembers?.survey_x_member?.forEach((key, index) => {
          payload.push({
            member_id: key.user.id,
            quota: 1,
            state: key.user.survey_x_members[0].state,
            survey_id: parseInt(surveyID),
          });
        });
      // deleteMembers({
      //   variables: {
      //     array: surveymemberID,
      //     Interviewerarray: [],
      //     object: payload,
      //   },
      // }).then(() => {
      updateSurvey({
        variables: {
          id: parseInt(surveyID),
          startDate: startDate,
          endDate: dueDate,
          show: switchChecked,
          status: buttonAction,
        },
      }).then(() => {
        if (buttonAction === "active") {
          notification.success({
            message: "Success!",
            description: "Survey created!",
          });
          history.push("/projectList/active");
        } else {
          notification.success({
            message: "Success!",
            description: "Saved as draft!",
          });
          history.push("/projectList/active");
        }
      });
      // });
    }
  };
  const onComplete = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });
  // eslint-disable-next-line

  function disabledDate(current) {
    if (moment().isSameOrAfter(moment(projectDates?.project[0]?.start_date))) {
      return (
        current < moment().startOf("day") ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    } else {
      return (
        current < moment(projectDates?.project[0]?.start_date) ||
        current > moment(projectDates?.project[0]?.end_date)
      );
    }
  }

  function disabledDuebyDate(current) {
    return (
      current < moment(projectDates?.project[0]?.start_date) ||
      current > moment(projectDates?.project[0]?.end_date)
    );
  }

  // function disabledDateTime() {
  //   return {
  //     disabledHours: () => range(0, 24).splice(4, 20),
  //     disabledMinutes: () => range(30, 60),
  //     disabledSeconds: () => [55, 56],
  //   };
  // }
  return (
    <>
      <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div>
      <Form>
        <div className="selectMembers px-10 py-4 w-full">
          <p className="text-textMedium text-lg float-left w-1/2">
            Select members
          </p>
          <span className=" float-right ml-3">
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>
          <Form.Item className="float-right">
            <button
              className="text-orange text-base"
              size="middle"
              type="link"
              onClick={handleClick}
            >
              Edit list
            </button>
          </Form.Item>
          <Form.Item className="block" name="members">
            <MemberTable
              columns={memColumns}
              tableData={tableData}
              pagination={false}
              rowClassName={(record, index) => "text-sm text-textMedium"}

            />

          </Form.Item>
          <Form.Item>
            <span className="text-lg text-textDark">
              Display the number of questions on survey card
            </span>
            <Switch
              checked={switchChecked}
              onChange={switchOnChange}
              className="float-right ml-4"
            ></Switch>
            <span className="text-base text-textLight float-right">
              {switchChecked === true ? "YES" : "NO"}
            </span>
          </Form.Item>
          <Divider />
          <p className="text-lg text-textDark">Schedule Survey</p>
          <Form.Item className="text-textLight text-sm w-3/5 float-left">
            <span>GO-LIVE</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                // disabledTime={disabledDateTime}
                showNow={false}
                disabled={isEnabled}
                value={startDate}
                showTime
                className="w-40"
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </span>
            <span className="ml-4">DUE BY</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDuebyDate}
                showNow={false}
                disabled={
                  startDate === null || startDate === undefined ? true : false
                }
                value={dueDate}
                showTime
                onChange={(date) => {
                  setDueDate(date);
                }}
              />
            </span>
          </Form.Item>
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={onComplete}
            >
              Schedule
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
export default MemberList;
