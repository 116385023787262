import { useMutation, useQuery } from "@apollo/client";
import { Button, notification } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {
  GET_SURVEY_EMAIL_TEMPLATE,
  UPDATE_SURVEY_EMAIL_TEMPLATE,
} from "../../../../survey/graphql";
import { MailData as ReminderMailTemplate } from "./contents/reminderMail";
import { MailData as SurveyMailTemplate } from "./contents/surveyMail";
import CreateEmailTemplate from "./editEmail";
import Preview from "./preview";

let initialSurveyValue = {
  buttonText: "Start the survey",
  placement: "middle",
  aboveContent: `<p class="mail-text">Greetings from Krea! <br><br>​ You have been exclusively invited to attend the survey 'SURVEY_NAME'. We value your insight and request you to spare a few minutes to attend the survey. To start the survey, please click on the button below:</p>`,
  belowContent: `<p class="mail-text">Please note, you can attend the survey till 'END_DATE'.<br>Thank you.</p>
  <p class="mail-text">Sincerely,<br>Krea Team</p>`,
};

let initialRemainderValue = {
  buttonText: "Start the survey",
  placement: "middle",
  aboveContent: `<p class="mail-text"><span style="font-weight: 500;">Greetings from Krea!</span><br><br>​ You were recently invited to participate in the survey -<span style="font-weight: 500;">'survey_title_insert_here'</span> and we noticed that you have not attended it yet. We value your insight and request you to spare a few minutes to attend the survey. To start the survey, please click on the button below:</p>`,
  belowContent: `<p class="mail-text">Please note, you can attend the survey till<span style="font-weight: 500;">'survey_end_date_time'.</span><br>Thank you.</p><p class="mail-text">Sincerely,<br>Krea Team</p>`,
};

const generateEmail = (data) => {
  let mailData = ``;
  if (data.placement === "top") {
    mailData = `
      <div class="mail-text">
        <p>
          ${data.aboveContent}
        </p>
        <div class="bg-secondary">
          <a href="URL">
            <input type="button" class="btn-primary" value="${data.buttonText}" />
          </a>
        </div>
      </div>
    `;
  }
  if (data.placement === "middle") {
    mailData = `
      <div class="mail-text">
        <p>
          ${data.aboveContent}
        </p>
        <div class="bg-secondary">
          <a href="URL">
            <input type="button" class="btn-primary" value="${data.buttonText}" />
          </a>
        </div>
        <p class="mail-text">
          ${data.belowContent}
        </p>
      </div>
    `;
  }
  if (data.placement === "bottom") {
    mailData = `
      <div class="mail-text">
        <div class="bg-secondary">
          <a href="URL">
            <input type="button" class="btn-primary" value="${data.buttonText}" />
          </a>
        </div>
        <p class="mail-text">
          ${data.aboveContent}
        </p>
      </div>
    `;
  }
  return mailData;
};

export default function EmailTemplate(props) {
  const history = useHistory();
  const location = useLocation();
  const [isPreview, setIsPreview] = useState(false);
  const [emailType, setemailType] = useState("");
  const [emailData, setEmailData] = useState(
    location.pathname.includes("inviteemail")
      ? initialSurveyValue
      : initialRemainderValue
  );
  const [emailTemplate, setEmailTemplate] = useState(undefined);
  // eslint-disable-next-line
  const { data, refetch: refetchTemplate } = useQuery(
    GET_SURVEY_EMAIL_TEMPLATE,
    {
      skip: !props.surveyId,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        surveyId: parseInt(props.surveyId),
      },
      onCompleted: (data) => {
        if (data.survey_by_pk) {
          if (emailType === "inviteemail" && data.survey_by_pk.mail_data) {
            setEmailData(data.survey_by_pk.mail_data);
            setEmailTemplate(data.survey_by_pk.mail_template);
          } else if (
            emailType === "reminderemail" &&
            data.survey_by_pk.reminder_mail_data
          ) {
            setEmailData(data.survey_by_pk.reminder_mail_data);
            setEmailTemplate(data.survey_by_pk.reminder_mail_template);
          } else {
            setEmailData(
              location.pathname.includes("inviteemail")
                ? initialSurveyValue
                : initialRemainderValue
            );
            setEmailTemplate(undefined);
          }
        }
      },
    }
  );

  const [updateEmailTemplate] = useMutation(UPDATE_SURVEY_EMAIL_TEMPLATE, {
    onCompleted: (data) => {
      if (data.update_survey_by_pk) {
        notification.success({ message: "Template saved successfully" });
        refetchTemplate();
      }
    },
    onError: (error) => {
      notification.error({ message: error.message });
    },
  });

  React.useEffect(() => {
    if (location.pathname.includes("preview")) {
      setIsPreview(true);
    } else {
      setIsPreview(false);
    }
    setemailType(location.pathname.split("/")[6]);
    refetchTemplate();
    // eslint-disable-next-line
  }, [location.pathname]);

  const handleBack = () => {
    if (location.pathname.includes("inviteemail")) {
      history.push(
        `${location.pathname
          .split("/")
          .slice(0, 5)
          .join("/")}/questionnaire/survey/${props.surveyId}`
      );
    } else {
      history.push(
        `${location.pathname
          .split("/")
          .slice(0, 5)
          .join("/")}/emailtemplate/inviteemail/survey/${
          props.surveyId
        }/preview`
      );
    }
  };
  const handleNext = () => {
    updateSurveyEmailTemplate();
    if (location.pathname.includes("inviteemail")) {
      history.push(
        `${location.pathname
          .split("/")
          .slice(0, 5)
          .join("/")}/emailtemplate/reminderemail/survey/${
          props.surveyId
        }/preview`
      );
    } else {
      history.push(
        `${location.pathname
          .split("/")
          .slice(0, 5)
          .join("/")}/assignmembers/survey/${props.surveyId}`
      );
    }
  };

  const updateSurveyEmailTemplate = () => {
    let objData = {};
    if (emailType === "inviteemail") {
      objData = {
        mail_data: emailData,
        mail_template:
          emailTemplate ||
          SurveyMailTemplate(generateEmail(initialSurveyValue)),
      };
    } else if (emailType === "reminderemail") {
      objData = {
        reminder_mail_data: emailData,
        reminder_mail_template:
          emailTemplate ||
          ReminderMailTemplate(generateEmail(initialRemainderValue)),
      };
    }
    updateEmailTemplate({
      variables: {
        id: parseInt(props.surveyId),
        object: objData,
      },
    });
  };
  const handleEdit = () => {
    if (isPreview) {
      history.push(location.pathname.replace("/preview", "/edit"));
    } else {
      updateSurveyEmailTemplate();
      let pathname = location.pathname.split("/");
      pathname.splice(-1);
      history.push(`${pathname.join("/")}/preview`);
    }
  };
  return (
    <div className="relative">
      <div>
        <div>
          {!isPreview && (
            <CreateEmailTemplate
              data={emailData}
              onTemplateChange={(data, template) => {
                setEmailData(data);
                if (emailType === "inviteemail") {
                  setEmailTemplate(SurveyMailTemplate(template));
                } else if (emailType === "reminderemail") {
                  setEmailTemplate(ReminderMailTemplate(template));
                }
              }}
            />
          )}
        </div>

        <div>{isPreview && <Preview template={emailTemplate} />}</div>
      </div>
      <div className="sticky bottom-0 px-8 py-5 text-right w-full flex justify-end shadow-md items-center">
        <Button
          type="link"
          className="text-base"
          htmlType="button"
          onClick={handleBack}
        >
          Back
        </Button>
        {((props?.surveyData?.start_date &&
          moment().diff(props?.surveyData?.start_date, "days") < 0) ||
          !props?.surveyData?.start_date) && (
          <Button
            size="large"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleEdit}
          >
            {!isPreview ? "Save and preview" : "Edit"}
          </Button>
        )}
        <Button
          type="primary"
          size="large"
          className="text-base rounded-md w-28"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
