import { Tabs, Layout } from "antd";
import { useQuery } from "@apollo/client";
import LocationHierarchy from "../../../../components/locationHierarchy";
import Overview from "./overview/overview";
import AssignMembers from "./assignMembers/selectMembers";
import GenerateLinks from "./generateLinks/index";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import AssignInterviewer from "./assignInterviewer/memberList";
import { SURVEY_DATES } from "../../prf/questionnaire/graphql";
import moment from "moment";

const { Content } = Layout;
const { TabPane } = Tabs;

function CreateSurvey() {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tabPane = [
    "overview",
    "assignmembers",
    "generate-links",
    "assign-interviewers",
  ];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [startDate, setStartDate] = useState(null);
  const [status, setStatus] = useState();
  const { data: surveyDates, refetch: surveyDatesRefetch } = useQuery(
    SURVEY_DATES,
    {
      variables: {
        id: parseInt(surveyID),
      },
    }
  );

  useEffect(() => {
    surveyDatesRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (surveyDates?.survey && surveyDates?.survey?.length !== 0) {
      setStartDate(moment(surveyDates?.survey[0]?.start_date));
      setStatus(surveyDates?.survey[0]?.status);
    }
  }, [surveyDates]);

  useEffect(() => {
    if (location.pathname.split("/")[5] === "assignmembers") {
      setActiveTabPane("2");
    } else if (location.pathname.split("/")[5] === "generate-links") {
      setActiveTabPane("3");
    } else if (location.pathname.split("/")[5] === "overview") {
      setActiveTabPane("1");
    } else if (location.pathname.split("/")[5] === "assign-interviewers") {
      setActiveTabPane("4");
    }
  }, [location.pathname]);

  const handleActiveTabPane = (key) => {
    location.pathname.split("/")[3] === "create-survey"
      ? history.push(
          `/project/${projectID}/create-survey/${surveyType}/${
            tabPane[key - 1]
          }/survey/${surveyID}`
        )
      : history.push(
          `/project/${projectID}/edit-survey/${surveyType}/${
            tabPane[key - 1]
          }/survey/${surveyID}`
        );
    setActiveTabPane(key);
  };
  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10">
        <LocationHierarchy id={projectID} />
        <div className="absolute text-base text-gray-700 pt-2">
          {location.pathname.split("/")[3] === "create-survey" && (
            <p>Create survey</p>
          )}
          {location.pathname.split("/")[3] === "edit-survey" && (
            <p>Edit survey</p>
          )}
        </div>

        <div
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            tabPosition="left"
            size="large"
            className="text-textExtraLight"
            tabBarStyle={{ width: "200px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane tab="Overview" key="1">
              <Overview startDate={startDate} status={status} />
            </TabPane>
            <TabPane tab="Members" key="2" disabled={surveyID ? false : true}>
              <AssignMembers startDate={startDate} status={status} />
            </TabPane>
            <TabPane
              tab="Survey links"
              key="3"
              disabled={surveyID ? false : true}
            >
              <GenerateLinks startDate={startDate} status={status} />
            </TabPane>
            <TabPane
              tab="Assign interviewers"
              key="4"
              disabled={surveyID ? false : true}
            >
              <AssignInterviewer startDate={startDate} status={status} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}

export default CreateSurvey;
