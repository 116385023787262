import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Input,
  Select,
  Spin,
  notification,
} from "antd";
import { useQuery, useMutation } from "@apollo/client";
import { GET_EMAILS, GET_ROLES, GET_SPECIALITIES, SIGNUP } from "./graphql";
import { useForm } from "antd/lib/form/Form";

const { Option } = Select;

const SignUp = () => {
  const { data, loading } = useQuery(GET_EMAILS);
  const { data: specialities } = useQuery(GET_SPECIALITIES);
  const { data: roles } = useQuery(GET_ROLES);
  const [signUp] = useMutation(SIGNUP);
  const [form] = useForm();
  const [emails, setEmails] = useState(null);
  const [show, setShow] = useState(false);
  let mobile = /^[0-9]{10}$/;
  useEffect(() => {
    if (data) {
      let temp = [];
      data?.user?.forEach((user) => {
        temp.push(user?.email);
      });
      setEmails(temp);
    }
  }, [data]);

  const onFinish = (values) => {
    signUp({
      variables: {
        ...values,
      },
    }).then(() => {
      form.resetFields();
      notification.success({
        message: "Member created",
        description: "A member has been created.",
      });
    });
  };
  return (
    <Spin spinning={loading}>
      <Row align="middle">
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 16, offset: 4 }}
          className="py-12 text-xl font-semibold"
          style={{ color: "#4F4F4F" }}
        >
          Sign up for Krea
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
          xl={{ span: 16, offset: 4 }}
        >
          <Card className="shadow rounded p-4">
            <Form
              layout="vertical"
              colon={false}
              requiredMark={false}
              onFinish={onFinish}
              form={form}
            >
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[
                      {
                        required: true,
                        message: "First name is required",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[
                      {
                        required: true,
                        message: "Last name is required",
                      },
                    ]}
                  >
                    <Input size="large" className="rounded" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        required: true,
                        message: "Email is required",
                      },
                      {
                        type: "email",
                        message: "Please enter an valid email address",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || !emails.includes(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            "Email ID already used. Please use a different one."
                          );
                        },
                      }),
                    ]}
                  >
                    <Input size="large" className="rounded" />
                  </Form.Item>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    name="phone"
                    label="Phone number"
                    rules={[
                      { required: true, message: "Phone number is required" },
                      () => ({
                        validator(_, value) {
                          if (!mobile.test(value)) {
                            return Promise.reject(
                              "Please enter a valid phone number"
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input size="large" className="rounded" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}
                  xl={{ span: 12 }}
                >
                  <Form.Item
                    name="role"
                    label="Role"
                    rules={[
                      {
                        required: true,
                        message: "Please select a role",
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      className="rounded"
                      onChange={(e) => {
                        if (e === 1) {
                          setShow(true);
                        } else {
                          setShow(false);
                        }
                      }}
                    >
                      {roles?.member_type?.map((role) => {
                        return <Option value={role.id}>{role.name}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {show && (
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                  >
                    <Form.Item
                      name="speciality"
                      label="Speciality"
                      rules={[
                        {
                          required: true,
                          message: "Please select your speciality",
                        },
                      ]}
                    >
                      <Select size="large" className="rounded">
                        {specialities?.specialties?.map((speciality) => {
                          return (
                            <Option value={speciality.id}>
                              {speciality.speciality_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={16}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}
                  xl={{ span: 24 }}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="float-right rounded mt-4"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </Spin>
  );
};

export default SignUp;
