import { Button, Card, Input, Form } from "antd";
import logo from "../../assets/logo.svg";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import jwtDecode from "jwt-decode";

function LoginPage() {
  const history = useHistory();
  const [error, setError] = useState(null);

  // eslint-disable-next-line
  const [role, setRole] = useState(null);
  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
      history.push("/dashboard");
    } catch (error) {
      console.log(error);
    } // eslint-disable-next-line
  }, [role]);

  const onFinish = (values) => {
    const query = {
      query: `
        mutation Login($email: String!, $password: String!, $is_device: Boolean) {
          login(email: $email, password: $password, is_device: $is_device) {
            token
          }
        }
      `,
      variables: {
        email: values.email,
        password: values.password,
        is_device: false,
      },
    };
    try {
      fetch(`${process.env.REACT_APP_API}`, {
        method: "POST",
        body: JSON.stringify(query),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
        })
        .then((json) => {
          if (json?.data?.login?.token) {
            localStorage.setItem("token", json?.data?.login?.token);
            window.location.href = "/dashboard";
          } else if (json?.errors) {
            setError("Email or password is invalid");
          }
        });
    } catch (err) {
      setError("Email or password is invalid");
    }
  };

  return (
    <div className="flex justify-center items-center  h-screen">
      <Card
        className="shadow-lg"
        style={{
          width: "508px",
          padding: "65px 20px",
        }}
      >
        <div className="h-12 flex justify-center items-center mb-8">
          <img src={logo} alt="krea" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          requiredMark={false}
          onFinish={onFinish}
        >
          {error ? <p className="text-red text-center">{error}</p> : null}
          <Form.Item
            label={<span className="text-base">Email</span>}
            name="email"
            rules={[
              { message: "Enter your email", required: true },
              { type: "email", message: "Enter a valid email!" },
            ]}
          >
            <Input size="large" type="text" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item
            label={<span className="text-base">Password</span>}
            name="password"
            rules={[{ message: "Enter your password", required: true }]}
            className="mt-5"
          >
            <Input
              size="large"
              type="password"
              placeholder="Enter your password"
            />
          </Form.Item>
          <Form.Item className="text-right">
            <Button
              type="link"
              size="large"
              onClick={() => {
                history.push("/forgot-password");
              }}
            >
              Forgot password?
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button text-base"
              block
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default LoginPage;
