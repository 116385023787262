import { gql } from "@apollo/client";

export const QUOTA_FULL = gql`
  mutation changeSurveyStatusToQuotaFull($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "quotaFull" }
    ) {
      affected_rows
    }
  }
`;

export const COMPLETE_SURVEY = gql`
  mutation changeSurveyStatusToComplete($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "completed", completed: 1 }
    ) {
      affected_rows
    }
  }
`;

export const SCREEN_SURVEY = gql`
  mutation changeSurveyStatusToScreened($member_id: Int!, $survey_id: Int!) {
    update_survey_x_member(
      where: { survey_id: { _eq: $survey_id }, member_id: { _eq: $member_id } }
      _set: { state: "screened", completed: 1 }
    ) {
      affected_rows
    }
  }
`;

export const GET_SURVEY = gql`
  query GET_SURVEY($project_id: Int) {
    project(where: { id: { _eq: $project_id } }) {
      surveys {
        name
      }
    }
  }
`;

export const UPDATE_INTERVIEWER_STATUS = gql`
  mutation UPDATE_STATUS(
    $member_id: Int
    $interviewer_id: Int
    $status: String
    $survey_id: Int
  ) {
    update_survey_x_member_x_interviewer(
      where: {
        _and: [
          {
            survey_x_member: {
              _and: [
                { member_id: { _eq: $member_id } }
                { survey_id: { _eq: $survey_id } }
              ]
            }
          }
          { interviewer_id: { _eq: $interviewer_id } }
        ]
      }
      _set: { status: $status }
    ) {
      affected_rows
    }
  }
`;
