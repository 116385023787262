import React, { useState, useEffect } from "react";
import { Button, Table, Divider } from "antd";
import { useHistory, useParams } from "react-router";
import { REMOVE_USER, VIEW_PROJECT_DETAILS } from "./graphql";
import { getColumnSearchProps } from "../../utils/table";
import { FilterOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import ColumnEditor from "../../components/ColumnEditor";

function UserView(props) {
  const { projectID, surveyID } = useParams();
  const [tableData, setTableData] = useState([]);
  const history = useHistory();
  const saveduserViewPages = localStorage.getItem('userViewPages');
  const userViewPagesinitial = saveduserViewPages
    ? JSON.parse(saveduserViewPages)
    : {
      "role": { enabled: true, label: "Role" },
      "status": { enabled: true, label: "Status" },
    }
  const [columnStatus, setStatus] = useState(userViewPagesinitial);

  useEffect(() => {
    localStorage.setItem('userViewPages', JSON.stringify(columnStatus));
  }, [columnStatus]);

  const [removeUser] = useMutation(REMOVE_USER, {
    refetchQueries: [
      {
        query: VIEW_PROJECT_DETAILS,
        variables: {
          project_id: parseInt(projectID),
          survey_id: surveyID ? parseInt(surveyID) : 0,
        },
        fetchPolicy: "network-only",
      },
    ],
  });

  useEffect(() => {
    if (props.userDetails) {
      let object = JSON.parse(JSON.stringify(props.userDetails));

      object.forEach((item) => {
        item.memberName = item.user.f_name + " " + item.user.l_name;
        item.role = item.user.role.name;
        item.status =
          item.user.meta_date === null
            ? "Available"
            : item.user.meta_date.availability;
      });

      setTableData(object);
    }
  }, [props]);

  const columns = [
    {
      title: "USER",
      dataIndex: "memberName",
      ...getColumnSearchProps("memberName", "Name"),
    },
    {
      title: "ROLE",
      dataIndex: "role",
      className: `${columnStatus?.role?.enabled ? "table-cell" : "hidden"} text-textDark`,
      ...getColumnSearchProps("role", "Role"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "Available",
          value: "Available",
        },
        {
          text: "On Leave",
          value: "On Leave",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => {
        return (
          <>
            <span>{text}</span>
          </>
        );
      },
    },
    {
      title: "ACTION",
      dataIndex: "action",
      render: (text, record) => {
        return (
          <>
            <Button
              type="link"
              className="text-orange text-h8"
              onClick={() => {
                history.push(
                  `/project/${projectID}/edit-project/project_details`
                );
              }}
            >
              Edit
            </Button>
            <Divider type="vertical" />
            <Button
              type="link"
              disabled={props.userDetails.length <= 1 ? true : false}
              className="text-red text-h8"
              onClick={() => {
                removeUser({
                  variables: {
                    id: record.id,
                  },
                });
              }}
            >
              Remove
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className="membersTab w-full ">
      <div className=" float-right mb-5">
        <ColumnEditor columns={columnStatus} onSave={(column) => {
          setStatus(column)
        }} />
      </div>
      <Table
        columns={columns}
        style={{ overflow: "auto" }}
        dataSource={tableData}
        rowKey={(record) => record.id}
        rowClassName={(record, index) =>
          "text-sm text-textMedium border-t border-l border-r border-textLight"
        }
      />
    </div>
  );
}
export default UserView;
