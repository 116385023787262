import { BellOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Menu, Modal, Badge, Button, Space, Typography } from "antd";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../assets/logo.svg";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useSubscription } from "@apollo/client";
import bell from "../assets/Icon/bell.png";
// import { wsLink } from "../App";
import moment from "moment";
import { UserOutlined, DownOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { AppContext } from "../context/membersContext";

function Nav() {
  const location = useLocation();
  const history = useHistory();
  const [role, setRole] = useState(null);
  const [name, setName] = useState("");
  const [id, setId] = useState();

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-user-id"];
      setId(decode);
    } catch (error) {
      console.log(error);
    } // eslint-disable-next-line
  }, []);

  const query = gql`
    query MyQuery($id: Int) {
      user(where: { id: { _eq: $id } }) {
        l_name
        f_name
      }
    }
  `;

  const GET_NEW_SURVEY = gql`
    subscription NEW_SURVEY {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: {
          read: { _eq: false }
          survey: { status: { _in: ["active", "completed"] } }
        }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          survey {
            name
            id
            status
            updated_at
            created_at
            created_by_user {
              f_name
              l_name
              role {
                description
              }
            }
          }
        }
      }
    }
  `;

  const GET_NEW_PROJECT = gql`
    subscription NEW_PROJECT {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: {
          read: { _eq: false }
          project: { status: { _in: ["active", "completed"] } }
        }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          project {
            name
            status
            created {
              f_name
              l_name
              role {
                description
              }
            }
            updated_at
            created_at
          }
        }
      }
    }
  `;

  const NEW_PROJECT_MANAGER = gql`
    subscription MANAGER_NOTIFICATION {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: {
          read: { _eq: false }
          project: { status: { _in: ["active", "completed"] } }
        }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          notifications
          project {
            name
            status
            created {
              f_name
              l_name
              role {
                description
              }
            }
            updated_at
            created_at
          }
        }
      }
    }
  `;
  const GET_INTERVIEWER_SURVEY = gql`
    subscription GET_INTERVIEWER_SURVEY {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: { read: { _eq: false } }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          notifications
          survey {
            name
            id
            status
            updated_at
            created_at
            created_by_user {
              f_name
              l_name
            }
            survey_x_members {
              survey_x_member_x_interviewers {
                appointment_date
                survey_x_member {
                  user {
                    f_name
                    l_name
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

  const GET_CLIENTS = gql`
    subscription GET_CLIENTS {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: { read: { _eq: false }, survey: { status: { _in: ["active"] } } }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          survey {
            name
            id
            status
            updated_at
            created_at
            created_by_user {
              f_name
              l_name
            }
          }
        }
      }
    }
  `;

  const GET_POC_PROJECT = gql`
    subscription GET_CLIENTS {
      notifications_aggregate(
        limit: 5
        order_by: { updated_at: desc }
        where: { read: { _eq: false }, survey: { status: { _in: ["active"] } } }
      ) {
        aggregate {
          count
        }
        nodes {
          created_at
          survey {
            name
            id
            status
            updated_at
            created_at
            created_by_user {
              f_name
              l_name
            }
          }
        }
      }
    }
  `;

  const UPDATE_READ = gql`
    mutation UPDATE_READ($user_id: Int) {
      update_notifications(
        where: { to_user: { _eq: $user_id } }
        _set: { read: true }
      ) {
        affected_rows
      }
    }
  `;

  const [updateRead] = useMutation(UPDATE_READ);
  const { data: newSurvey } = useSubscription(GET_NEW_SURVEY);
  const { data: newProject } = useSubscription(GET_NEW_PROJECT);
  const { data: clientSurvey } = useSubscription(GET_CLIENTS);
  const { data: pocSurvey } = useSubscription(GET_POC_PROJECT);
  const { data: newProjectManager } = useSubscription(NEW_PROJECT_MANAGER);
  const { data: interviewerSurvey } = useSubscription(GET_INTERVIEWER_SURVEY);

  const [surveyData, setSurveyData] = useState();
  const [count, setCount] = useState();

  useEffect(() => {
    let newObject = [];
    let list = [];
    if (
      (role === "super_admin" || role === "sub_admin") &&
      newSurvey &&
      newProject
    ) {
      let surveyObject = JSON.parse(
        JSON.stringify(newSurvey?.notifications_aggregate?.nodes)
      );
      let projectObject = JSON.parse(
        JSON.stringify(newProject?.notifications_aggregate?.nodes)
      );
      surveyObject.length !== 0 &&
        surveyObject.forEach((item) => {
          item.memberName =
            item?.survey?.created_by_user?.l_name !== null
              ? item?.survey?.created_by_user?.f_name +
              " " +
              item?.survey?.created_by_user?.l_name
              : item?.survey?.created_by_user?.f_name;
          item.type = "survey";
          item.state =
            item.survey.status === "active"
              ? "created"
              : item.survey.status === "completed"
                ? "closed"
                : "";
          item.name = item?.survey?.name;
          // eslint-disable-next-line
          item.created_at = item?.created_at;
          item.role = item?.survey?.created_by_user?.role?.description;
        });
      projectObject.length !== 0 &&
        projectObject.forEach((item) => {
          item.memberName =
            item?.project?.created?.l_name !== null
              ? item?.project?.created?.f_name +
              " " +
              item?.project?.created?.l_name
              : item?.project?.created?.f_name;
          item.type = "project";
          item.state =
            item.project.status === "active"
              ? "created"
              : item.project.status === "completed"
                ? "closed"
                : "";
          item.name = item?.project?.name;
          // eslint-disable-next-line
          item.created_at = item?.created_at;
          item.role = item?.project?.created?.role?.description;
        });
      newObject = [...surveyObject, ...projectObject];
      newObject.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      list = newObject.slice(0, 5);
      setCount(
        newSurvey?.notifications_aggregate?.aggregate?.count +
        newProject?.notifications_aggregate?.aggregate?.count
      );
      setSurveyData(list);
    } else if (role === "manager" && newProjectManager) {
      let projectObject = JSON.parse(
        JSON.stringify(newProjectManager?.notifications_aggregate?.nodes)
      );
      projectObject.forEach((item) => {
        item.memberName =
          item?.project?.created?.l_name !== null
            ? item?.project?.created?.f_name +
            " " +
            item?.project?.created?.l_name
            : item?.project?.created?.f_name;
        item.type = "project";
        item.state =
          item?.project?.status === "active"
            ? "created"
            : item?.project?.status === "completed"
              ? "closed"
              : "";
        item.name = item?.project?.name;
        // eslint-disable-next-line
        item.created_at = item?.created_at;
        item.role = item?.project?.created?.role?.description;
      });
      newObject = [...projectObject];
      newObject.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      list = newObject.slice(0, 5);
      setCount(newProjectManager?.notifications_aggregate?.aggregate?.count);
      setSurveyData(list);
    } else if (role === "interviewer" && interviewerSurvey) {
      let interviewerObject = JSON.parse(
        JSON.stringify(interviewerSurvey?.notifications_aggregate?.nodes)
      );
      interviewerObject.forEach((item) => {
        if (item.notifications === "Survey created") {
          item.surveyName = item?.survey?.name;
          // eslint-disable-next-line
          item.created_at = item?.created_at;
          item.appointment = false;
        } else {
          item.appointment = true;
          // eslint-disable-next-line
          item.notifications = item?.notifications;
          // eslint-disable-next-line
          item.created_at = item?.created_at;
        }
      });

      newObject = [...interviewerObject];

      newObject.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      list = newObject.slice(0, 5);
      setCount(interviewerSurvey?.notifications_aggregate?.aggregate?.count);
      setSurveyData(list);
    } else if (role === "client" && clientSurvey) {
      // let surveyObj = [];
      let clientObject = JSON.parse(
        JSON.stringify(clientSurvey?.notifications_aggregate?.nodes)
      );
      clientObject.forEach((item) => {
        item.name = item?.survey?.name;
        // eslint-disable-next-line
        item.created_at = item?.created_at;
      });
      newObject = [...clientObject];
      newObject.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      list = newObject.slice(0, 5);
      setCount(clientSurvey?.notifications_aggregate?.aggregate?.count);
      setSurveyData(list);
    } else if (role === "poc" && pocSurvey) {
      // let surveyObj = [];
      let clientObject = JSON.parse(
        JSON.stringify(clientSurvey?.notifications_aggregate?.nodes)
      );
      clientObject.forEach((item) => {
        item.name = item?.survey?.name;
        // eslint-disable-next-line
        item.created_at = item?.created_at;
      });
      newObject = [...clientObject];
      newObject.sort((a, b) =>
        moment.utc(b.created_at).diff(moment.utc(a.created_at))
      );
      list = newObject.slice(0, 5);
      setCount(clientSurvey?.notifications_aggregate?.aggregate?.count);
      setSurveyData(list);
    }
    // eslint-disable-next-line
  }, [
    newSurvey,
    newProject,
    interviewerSurvey,
    newProjectManager,
    clientSurvey,
    // interviewerAppointmentDate,
    pocSurvey,
  ]);

  useEffect(() => {
    try {
      let decode = jwtDecode(localStorage.getItem("token"))[
        "https://hasura.io/jwt/claims"
      ]["x-hasura-default-role"];
      setRole(decode);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);
  const { data } = useQuery(query, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      if (data?.user[0]?.l_name) {
        setName(`${data?.user[0]?.f_name} ${data?.user[0]?.l_name}`);
      } else {
        setName(`${data?.user[0]?.f_name}`);
      }
    }
  }, [data]);

  const [currentTab, setCurrentTab] = useState("home");
  const menuItem = [
    "home",
    "clientList",
    "members",
    "users",
    "projectList",
    "approvals",
    "rewards",
    "interviewerAssignment",
    "memberValidation"
  ];
  useEffect(() => {
    if (
      location.pathname === "/clientList" ||
      location.pathname === "/create_client/client_details" ||
      location.pathname === "/create_client/add_poc"
    ) {
      setCurrentTab("2");
    } else if (location.pathname.split("/")[1] === "members") {
      setCurrentTab("3");
    } else if (location.pathname === "/users") {
      setCurrentTab("4");
    } else if (location.pathname === "/projectList/active") {
      setCurrentTab("5");
    } else if (location.pathname === "/approvals") {
      setCurrentTab("6");
    } else if (location.pathname === "/rewards") {
      setCurrentTab("7");
    } else if (location.pathname === "/interviewerAssignment") {
      setCurrentTab("8");
    } else if (location.pathname === "/memberValidation") {
      setCurrentTab("9");
    } else if (
      location.pathname === "/dashboard" ||
      location.pathname === "/create_survey/PRF/overview" ||
      location.pathname === "/create_survey/PRF/questionnaire" ||
      location.pathname === "/create_survey/PRF/assignmembers" ||
      location.pathname === "/view_project" ||
      location.pathname === "/create_project/project_details" ||
      location.pathname === "/create_project/assign_members" ||
      location.pathname === "/create_project/financial_details" ||
      location.pathname.split("/")[2] === "viewSurvey"
    ) {
      setCurrentTab("1");
    }
  }, [location.pathname]);

  const handleClick = (e) => {
    if (menuItem[e.key - 1] === "home") {
      history.push(`/dashboard`);
    } else {
      menuItem[e.key - 1] === "projectList"
        ? history.push(`/${menuItem[e.key - 1]}/active`)
        : menuItem[e.key - 1] === "members"
          ? history.push(`/${menuItem[e.key - 1]}/physician/1`)
          : history.push(`/${menuItem[e.key - 1]}`);
    }
    setCurrentTab(e.key);
  };

  const logout = () => {
    localStorage.clear();
    history.push("/");
  };
  const menu = (
    <Menu className="w-50">
      <Menu.Item className="bg-gray h-16" key="role">
        {name}
      </Menu.Item>
      <Menu.Item
        key="settings"
        onClick={() => {
          history.push("/settings");
        }}
      >
        Settings
      </Menu.Item>
      <Menu.Item key="logout" onClick={() => showModal()}>
        Logout
      </Menu.Item>
    </Menu>
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    logout();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const NotificationDropdown = (surveyData) => {
    return (
      <div
        style={{ width: "30rem", minHeight: "10rem", overflow: "auto" }}
        className="bg-white shadow-2xl mt-4"
      >
        <p className="p-3 mb-0">
          <span className="text-textMedium text-h5">Notifications</span>
          <Button
            type="link"
            className="float-right"
            onClick={() =>
              updateRead({
                variables: {
                  user_id: parseInt(id),
                },
              })
            }
          >
            Mark all as read
          </Button>
        </p>
        {surveyData && surveyData.length === 0 && (
          <div className="pt-4 flex flex-col items-center justify-center text-h8">
            No new notifications
          </div>
        )}
        {surveyData &&
          surveyData.length !== 0 &&
          surveyData.map((item) => {
            return (
              <>
                {(role === "super_admin" ||
                  role === "sub_admin" ||
                  role === "manager") && (
                    <>
                      {item.created_by_user !== null && (
                        <>
                          <Divider type="horizontal" className="m-0" />
                          <p className="text-textMedium text-h8 p-2 mb-0 text-bold bg-white w-full">
                            <p className="float-left w-8">
                              <img src={bell} alt="bell" className="float-left" />
                            </p>
                            <p className="float-left w-11/12">
                              <span className="text-textDark">
                                {item.memberName}({item.role})
                              </span>{" "}
                              {item.state} a {item.type} - "{item.name}"
                              <p className="text-textLight">
                                {moment(item.created_at).fromNow()}
                              </p>
                            </p>
                          </p>
                        </>
                      )}
                    </>
                  )}
                {role === "interviewer" && (
                  <>
                    {item.appointment === false && (
                      <>
                        <Divider type="horizontal" className="m-0" />
                        <p className="text-textMedium text-h8 p-2 mb-0 text-bold bg-white w-full">
                          <p className="float-left w-8">
                            <img src={bell} alt="bell" className="float-left" />
                          </p>
                          <p className="float-left w-11/12">
                            <span className="text-textMedium">
                              A new member has been assigned to you for
                              interview for the survey - "{item.surveyName}"
                            </span>

                            <p className="text-textLight">
                              {moment(item.created_at).fromNow()}
                            </p>
                          </p>
                        </p>
                      </>
                    )}
                    {item.appointment === true && (
                      <>
                        <Divider type="horizontal" className="m-0" />
                        <p className="text-textMedium text-h8 p-2 mb-0 text-bold bg-white w-full">
                          <p className="float-left w-8">
                            <img src={bell} alt="bell" className="float-left" />
                          </p>
                          <p className="float-left w-11/12">
                            <span className="text-textMedium">
                              {item.notifications}
                            </span>

                            <p className="text-textLight">
                              {moment(item.created_at).fromNow()}
                            </p>
                          </p>
                        </p>
                      </>
                    )}
                  </>
                )}
                {(role === "client" || role === "poc") && (
                  <>
                    <Divider type="horizontal" className="m-0" />
                    <p className="text-textMedium text-h8 p-2 mb-0 text-bold bg-white w-full">
                      <p className="float-left w-8">
                        <img src={bell} alt="bell" className="float-left" />
                      </p>
                      <p className="float-left w-11/12">
                        <span className="text-textMedium">
                          A new survey - "{item.name}" has been created
                        </span>

                        <p className="text-textLight">
                          {moment(item.created_at).fromNow()}
                        </p>
                      </p>
                    </p>
                  </>
                )}
              </>
            );
          })}
      </div>
    );
  };

  const { isMenuItemActive, handleMenuClick } = useContext(AppContext);
  const items = (
    <Menu className="w-50">
      <Menu.Item key="members"
        className={(isMenuItemActive('export') || isMenuItemActive('verify')|| isMenuItemActive('reject')) ? '' : 'text-orange'}
        onClick={() => {
          history.push("/members/physician/1");
          handleMenuClick({ key: 'members' });
        }}
      >
        All members
      </Menu.Item>
      <Menu.Item
        key="export"
        className={isMenuItemActive('export') ? 'text-orange' : ''}
        onClick={() => {
          history.push("/members/physician/1");
          handleMenuClick({ key: 'export' });
        }}
      >
        Export
      </Menu.Item>
      {role === 'manager' ? "" :
        <Menu.Item key="verify"
          className={isMenuItemActive('verify') ? 'text-orange' : ''}
          onClick={() => {
            history.push("/members/physician/1");
            handleMenuClick({ key: 'verify' });
          }}
        >
          Verify
        </Menu.Item>
      }
      <Menu.Item
        key="reject"
        className={isMenuItemActive('reject') ? 'text-orange' : ''}
        onClick={() => {
          history.push("/members/physician/1");
          handleMenuClick({ key: 'reject' });
        }}
      >
        Reject List
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="w-full bg-white h-20 p-8 shadow-lg z-50 sticky top-0">
      <div className="logo w-24 float-left">
        <NavLink to="/dashboard">
          <img src={logo} alt="krea" />
        </NavLink>
      </div>
      <Menu
        onClick={handleClick}
        selectedKeys={[currentTab]}
        mode="horizontal"
        className="float-left w-3/5 bg-white border-b-0 "
      >
        {role !== "client" && role !== "poc" && (
          <Menu.Item key="1">Home</Menu.Item>
        )}
        {role !== "client" &&
          role !== "poc" &&
          role !== "interviewer" &&
          role !== "manager" && <Menu.Item key="2">Clients</Menu.Item>}

        {role !== "client" && role !== "poc" && role !== "interviewer" && (
          <Menu.Item key="3">
            <Dropdown
              overlay={items}
              trigger={["click"]}
              className=" cursor-pointer"
              defaultSelectedKeys={['members']}
            >
              <div class="flex items-center cursor-pointer">Members <DownOutlined /></div>
            </Dropdown>
          </Menu.Item>
        )}
        {role !== "client" &&
          role !== "poc" &&
          role !== "interviewer" &&
          role !== "manager" && <Menu.Item key="4">Users</Menu.Item>}
        {role !== "client" && role !== "poc" && role !== "interviewer" && (
          <Menu.Item key="5">Projects List</Menu.Item>
        )}
        {role !== "client" &&
          role !== "poc" &&
          role !== "interviewer" && <Menu.Item key="6">Approvals</Menu.Item>}
        {role !== "client" &&
          role !== "poc" &&
          role !== "interviewer" &&
          role !== "manager" && <Menu.Item key="7">Rewards</Menu.Item>}
        {role === "manager" && <Menu.Item key="8">Interviewer Assignment</Menu.Item>}
        {role === "interviewer" && <Menu.Item key="9">Member Validation</Menu.Item>}
      </Menu>
      <div className="w-auto float-right">
        <Dropdown
          overlay={NotificationDropdown(surveyData)}
          trigger={["click"]}
          className="w-8 float-left "
          placement="bottomLeft"
          overlayStyle={{ position: "fixed" }}
        >
          <Badge
            // status="error"
            size="small"
            className="float-left mt-1"
            count={count >= 5 ? 5 : count}
          >
            <BellOutlined className="cursor-pointer float-right" />
          </Badge>
        </Dropdown>
        <Divider
          type="vertical"
          className="float-left ml-4"
          style={{ height: "1.5rem" }}
        />
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          className=" float-right w-52 cursor-pointer"
          overlayStyle={{ position: "fixed" }}
        >
          <div class="flex items-center cursor-pointer">
            <span>
              <UserOutlined style={{ fontSize: "1rem", color: "#828282" }} />
            </span>

            <span className="mx-2">{name}</span>
            <span>
              <DownOutlined
                style={{ fontSize: "0.8rem", color: "#828282" }}
                className="ml-4"
              />
            </span>
            <br />
            <span className="mx-6 mt-4 text-xs" style={{ color: "#828282" }}>
              {role === "super_admin"
                ? "Super admin"
                : role === "manager"
                  ? "Manager"
                  : role === "interviewer"
                    ? "Interviewer"
                    : role === "client"
                      ? "Client"
                      : role === "poc"
                        ? "POC"
                        : role === "sub_admin"
                          ? "Admin"
                          : null}
            </span>
          </div>
        </Dropdown>
      </div>
      <Modal
        title="Log Out"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Log Out"
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  );
}

export default Nav;
