import React, { useState, useEffect } from "react";
import { Dropdown, Menu, Spin, Table, Tag, Checkbox, Row, Col } from "antd";
import { useQuery } from "@apollo/client";
import { GET_MANAGER_DASHBOARD, GET_USER_DETAILS } from "./graphql";
import { getColumnSearchProps } from "../../utils/table";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { MoreOutlined, FilterOutlined } from "@ant-design/icons";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import ErrorPage from "../../components/errorPage";
import { useHistory } from "react-router-dom";
import ChartCard from "../../components/Cards/ChartCard";
import CountCard from "../../components/Cards/countCard";
import greenCircle from "../../assets/Icon/greenCircle.svg";
import exclamationCircle from "../../assets/Icon/exclamationCircle.svg";
import ColumnEditor from "../../components/ColumnEditor";

function ManagerDashboard() {
  const history = useHistory();
  // eslint-disable-next-line
  const [id, setId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
    "x-hasura-user-id"
    ]
  );
  const [projectStats, setProjectStats] = React.useState([]);
  const [memberStats, setMemberStats] = React.useState([]);
  const [interViewerStats, setInterViewerStats] = React.useState([]);
  const [liveProjects, setLiveProjects] = React.useState([]);
  const [activeList, setActiveList] = React.useState([]);
  const savedmanagerDashboard = localStorage.getItem('managerDashboard');
  const managerDashboardinitial = savedmanagerDashboard
    ? JSON.parse(savedmanagerDashboard)
    : {
      client: { enabled: true, label: "Client" },
      category: { enabled: true, label: "Category" },
      assigned: { enabled: true, label: "Assigned to" },
      sample: { enabled: true, label: "Sample" },
      start: { enabled: true, label: "Start date" },
      end: { enabled: true, label: "End date" },
      status: { enabled: true, label: "Status" },
      total_budget: { enabled: false, label: "Total budget" },
    }
  const [columnStatus, setStatus] = useState(managerDashboardinitial);

  useEffect(() => {
    localStorage.setItem('managerDashboard', JSON.stringify(columnStatus));
  }, [columnStatus]);
  const { data: userData } = useQuery(GET_USER_DETAILS, {
    variables: {
      id: parseInt(id),
    },
    fetchPolicy: "network-only",
  });
  const { data, loading, error } = useQuery(GET_MANAGER_DASHBOARD, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      // setLiveProjects(data?.live_projects || []);
      setProjectStats([
        {
          title: "Assigned projects",
          count: data?.assigned_projects?.aggregate?.count || 0,
          color: "#FFA63E",
        },
        {
          title: "Active surveys",
          count: data?.active_surveys?.aggregate?.count || 0,
          color: "#E08036",
        },
        {
          title: "Closed surveys",
          count: data?.closed_surveys?.aggregate?.count || 0,
          color: "#F3CCAF",
        },
      ]);
      setMemberStats([
        {
          title: "Technician",
          count: data?.technician?.aggregate?.count || 0,
          color: "#FFA63E",
        },
        {
          title: "Physician",
          count: data?.physician?.aggregate?.count || 0,
          color: "#E08036",
        },
        {
          title: "Patient",
          count: data?.patient?.aggregate?.count || 0,
          color: "#ECB386",
        },
        {
          title: "Consumer",
          count: data?.consumer?.aggregate?.count || 0,
          color: "#F3CCAF",
        },
      ]);
      setInterViewerStats([
        {
          title: "Available",
          count: data?.interviewers_available?.aggregate?.count || 0,
          color: "#E08036",
        },
        {
          title: "On leave",
          count: data?.interviewers_not_available?.aggregate?.count || 0,
          color: "#ECB386",
        },
      ]);
    },
  });

  useEffect(() => {
    let newObj = [];
    if (
      data &&
      (userData?.user[0]?.meta_date?.availability === "Available" ||
        userData?.user[0]?.meta_date === null)
    ) {
      let object = JSON.parse(JSON.stringify(data?.live_projects));
      object.forEach((item) => {
        if (
          (item?.surveys?.length !== 0 &&
            item?.surveys[0]?.status === "active") ||
          item?.surveys?.length === 0
        ) {
          item.state =
            item?.surveys?.length !== 0 ? item?.surveys[0]?.status : "active";
          let val =
            item?.survey_type !== null && item?.survey_type !== ""
              ? item?.survey_type === "Third_Party_survey"
                ? "- " + item?.third_party_survey_name
                : "- " + item?.survey_type
              : "";
          item.category = item?.projectTypeByProjectType?.name + " " + val;
          // const surveyType = category.replace(/ /g, "");

          item.clientName =
            item?.client?.l_name !== null
              ? item?.client?.f_name + " " + item?.client?.l_name
              : item?.client?.f_name;
          item.managerName =
            item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
          newObj.push(item);
        } else if (
          item?.surveys?.length !== 0 &&
          item?.surveys[0]?.created_by_user?.id === parseInt(id)
        ) {
          item.state = item?.surveys[0]?.status;
          let val =
            item?.survey_type !== null && item?.survey_type !== ""
              ? item?.survey_type === "Third_Party_survey"
                ? "- " + item?.third_party_survey_name
                : "- " + item?.survey_type
              : "";
          item.category = item?.projectTypeByProjectType?.name + " " + val;
          // const surveyType = category.replace(/ /g, "");

          item.clientName =
            item?.client?.l_name !== null
              ? item?.client?.f_name + " " + item?.client?.l_name
              : item?.client?.f_name;
          item.managerName =
            item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
          newObj.push(item);
        }
      });
      setLiveProjects(newObj);
    } else if (data) {
      let object = JSON.parse(JSON.stringify(data.leave_projects));
      object.forEach((item) => {
        if (item?.manager_x_projects_aggregate?.aggregate?.count === 1) {
          item.state =
            item?.surveys?.length !== 0 ? item?.surveys[0]?.status : "active";
          let val =
            item?.survey_type !== null && item?.survey_type !== ""
              ? item?.survey_type === "Third_Party_survey"
                ? "- " + item?.third_party_survey_name
                : "- " + item?.survey_type
              : "";
          item.category = item?.projectTypeByProjectType?.name + " " + val;
          // const surveyType = category.replace(/ /g, "");

          item.clientName =
            item?.client?.l_name !== null
              ? item?.client?.f_name + " " + item?.client?.l_name
              : item?.client?.f_name;
          item.managerName =
            item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
          newObj.push(item);
        } else if (
          (item?.surveys?.length > 0 && item?.surveys[0]?.status === "draft") ||
          (item?.surveys?.length > 0 &&
            item?.surveys[0]?.status === "completed")
        ) {
          if (item?.surveys[0]?.created_by_user?.id === parseInt(id)) {
            item.state = item?.surveys[0]?.status;
            let val =
              item?.survey_type !== null && item?.survey_type !== ""
                ? item?.survey_type === "Third_Party_survey"
                  ? "- " + item?.third_party_survey_name
                  : "- " + item?.survey_type
                : "";
            item.category = item?.projectTypeByProjectType?.name + " " + val;
            // const surveyType = category.replace(/ /g, "");

            item.clientName =
              item?.client?.l_name !== null
                ? item?.client?.f_name + " " + item?.client?.l_name
                : item?.client?.f_name;
            item.managerName =
              item?.manager_x_projects[0]?.user?.l_name !== null
                ? item?.manager_x_projects[0]?.user?.f_name +
                " " +
                item?.manager_x_projects[0]?.user?.l_name
                : item?.manager_x_projects[0]?.user?.f_name;
            newObj.push(item);
          }
        }
      });
      setLiveProjects(newObj);
    }
    // eslint-disable-next-line
  }, [data, userData]);

  useEffect(() => {
    // eslint-disable-next-line
    let result = liveProjects.filter((record) => {
      if (record.manager_x_projects.length !== 0) {
        return parseInt(id) === record.manager_x_projects[0].user.id;
      }
    });
    setActiveList(result.splice(0, 5));
    // eslint-disable-next-line
  }, [liveProjects]);

  const columns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
      fixed: "left",
    },
    {
      title: "CLIENT",
      key: "client.f_name",
      dataIndex: "client.f_name",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{`${record.client.f_name}`}</div>
      ),
      ...getColumnSearchProps("client.f_name", "Client Name"),
    },
    {
      title: "CATEGORY",
      key: "projectTypeByProjectType.name",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      dataIndex: "category",
      ...getColumnSearchProps("category", "Category"),
      render: (text, record, index) => (
        <>
          <span> {text}</span>
          <div>
            {record.member_types !== null && record.member_types[0] === 1 && (
              <span className="text-xs text-textLight font-normal">
                Physician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 2 && (
              <span className="text-xs text-textLight font-normal">
                Technician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 3 && (
              <span className="text-xs text-textLight font-normal">
                Patient
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 4 && (
              <span className="text-xs text-textLight font-normal">
                Consumer
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "ASSIGNED TO",
      key: "manager_x_projects",
      dataIndex: "managerName",
      className: columnStatus?.assigned?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("managerName", "Assigned Managers"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          {record.manager_x_projects.map((manager, i) => (
            <div className="text-h8 text-textMedium">{`${manager.user.f_name} ${manager.user.l_name}`}</div>
          ))}
        </div>
      ),
    },
    {
      title: "SAMPLE",
      key: "sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (text, record, index) => {
        let avgPerDay = Math.ceil(
          record.sample /
          moment(record?.end_date).diff(moment(record?.start_date), "days")
        );
        let days = moment().diff(moment(record?.start_date), "days");
        return (
          <div className="text-textMedium text-h8 w-20">
            <span className="float-left">
              {record?.surveys?.reduce(
                (arr, cur) =>
                  arr +
                  (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                0
              )}{" "}
              / {record.sample}
            </span>
            <span className="float-right">
              {days >= 1 &&
                record?.surveys?.reduce(
                  (arr, cur) =>
                    arr +
                    (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                  0
                ) >= avgPerDay ? (
                <img src={greenCircle} className="ml-2" alt="greencircle" />
              ) : // <ArrowUpOutlined style={{ color: "green" }} className="ml-2" />
                null}
            </span>
            <span className="float-right">
              {days >= 1 &&
                record?.surveys?.reduce(
                  (arr, cur) =>
                    arr +
                    (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                  0
                ) < avgPerDay ? (
                <img
                  src={exclamationCircle}
                  className="ml-2"
                  alt="exclaimationcircle"
                />
              ) : // <ArrowDownOutlined style={{ color: "red" }} className="ml-2" />
                null}
            </span>
          </div>
        );
      },
    },
    {
      title: "START DATE",
      key: "start_date",
      className: columnStatus?.start?.enabled ? "table-cell" : "hidden",
      dataIndex: "start_date",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      className: columnStatus?.end?.enabled ? "table-cell" : "hidden",
      dataIndex: "end_date",
      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      dataIndex: "state",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.state.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.state === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.state === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.state === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "TOTAL BUDGET",
      key: "total_budget",
      dataIndex: "total_budget",
      className: columnStatus?.total_budget?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("total_budget", "Total budget"),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  let val =
                    record?.survey_type !== null && record?.survey_type !== ""
                      ? "- " + record?.survey_type
                      : "";
                  let category =
                    record?.projectTypeByProjectType?.name + " " + val;
                  const surveyType = category.replace(/ /g, "");

                  record?.surveys.length !== 0
                    ? history.push(
                      `/viewSurvey/projectDetails/project/${record?.id}/survey/${record?.surveys[0]?.id}/${surveyType}`
                    )
                    : history.push(
                      `/viewSurvey/projectDetails/project/${record?.id}/${surveyType}`
                    );
                }}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];



  if (error) {
    return <ErrorPage />;
  }

  return (
    <Spin spinning={loading}>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-textMedium text-h4 font-semibold">
            Overview
          </span>
        </div>
        <div className="grid grid-cols-12	gap-4">
          <div className="flex justify-between col-span-4 rounded shadow">
            <CountCard
              count={data?.assigned_projects?.aggregate?.count || 0}
              description="Assigned projects"
              className="flex-grow rounded-l-lg"
              onClick={() => {
                history.push("/projectList/active");
              }}
            />
            <CountCard
              count={data?.active_surveys?.aggregate?.count || 0}
              description="Active surveys"
              className="flex-grow"
              onClick={() => {
                history.push("/projectList/active");
              }}
            />
            <CountCard
              count={data?.closed_surveys?.aggregate?.count || 0}
              description="Closed surveys"
              className="flex-grow rounded-r-lg"
              onClick={() => {
                history.push("/projectList/completed");
              }}
            />
          </div>
          <div className="flex justify-between col-span-8 rounded shadow">
            <CountCard
              count={data?.technician?.aggregate?.count || 0}
              description="Technician"
              className="flex-grow rounded-l-lg"
              onClick={() => {
                history.push("/members/technician/1");
              }}
            />
            <CountCard
              count={data?.physician?.aggregate?.count || 0}
              description="Physician"
              className="flex-grow"
              onClick={() => {
                history.push("/members/physician/1");
              }}
            />
            <CountCard
              count={data?.patient?.aggregate?.count || 0}
              description="Patient"
              className="flex-grow"
              onClick={() => {
                history.push("/members/patient/1");
              }}
            />
            <CountCard
              count={data?.consumer?.aggregate?.count || 0}
              description="Consumer"
              className="flex-grow"
              onClick={() => {
                history.push("/members/consumer/1");
              }}
            />
            <CountCard
              count={data?.interviewers?.aggregate?.count || 0}
              description="Interviewers"
              className="flex-grow rounded-r-lg"
            />
          </div>
        </div>
        <div className="grid grid-cols-12	gap-4 mt-4">
          <div className="flex justify-between col-span-4 rounded shadow">
            <ChartCard
              count={projectStats.reduce((acc, crr) => acc + crr.count, 0)}
              title="Project Stats"
              className="flex-grow rounded"
              data={projectStats}
              topLabel="Total"
              bottomLabel="Projects"
            />
          </div>
          <div className="flex justify-between col-span-4 rounded shadow">
            <ChartCard
              count={memberStats.reduce((acc, crr) => acc + crr.count, 0)}
              title="Member registration Stats"
              className="flex-grow rounded"
              data={memberStats}
              topLabel="Total"
            />
          </div>
          <div className="flex justify-between col-span-4 rounded shadow">
            <ChartCard
              count={interViewerStats.reduce((acc, crr) => acc + crr.count, 0)}
              title="Interviewers stats"
              className="flex-grow rounded"
              data={interViewerStats}
              topLabel="Total"
            />
          </div>
        </div>
        <div className="my-4 text-h5 text-textMedium font-semibold">
          Assigned projects
          <span>
            <ColumnEditor columns={columnStatus} onSave={(column) => {
              setStatus(column)
            }} />
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={activeList}
          style={{ overflow: "auto" }}
          pagination={false}
          className="shadow rounded"
        />
      </div>
    </Spin>
  );
}

export default ManagerDashboard;
