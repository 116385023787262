import { Space, DatePicker, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import moment from "moment";

const { RangePicker } = DatePicker;

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};
const getColumnDateSearchProps = (dataIndex, picker = "date") => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <RangePicker
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e ? [e] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        className="mr-2"
        picker={picker}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
  ),
  onFilter: (value, record) => {
    let getRecord = _.get(record, dataIndex);
    return moment(getRecord).isBetween(value[0], value[1], "days", []);
  },
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      // setTimeout(() => searchInput.select(), 100);
    }
  },
});

export { getColumnDateSearchProps };
