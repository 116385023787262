import { Drawer } from "antd";
import { useState } from "react";
import Preview from "./preview";

function ReferQuestions(props) {
  const [visible, setVisible] = useState(props.visible);

  const onClose = () => {
    props.handleReferQuestions(setVisible(false));
    setVisible(false);
  };

  return (
    <>
      <Drawer
        title={<span className="text-textMedium text-lg">Questions</span>}
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Preview enableButton={false} />
      </Drawer>
    </>
  );
}

export default ReferQuestions;
