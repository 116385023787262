import { Form, Input, Button, Divider, notification } from "antd";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import dragIcon from "../../../../assets/dragIcon.svg";
import FileUpload from "./fileUpload";
import QuestionEdit from "./editQuestion";
import { useQuery, useMutation } from "@apollo/client";
import { GET_QUESTION_TYPES, INSERT_QUESTION, GET_QUESTIONS } from "./graphql";
import { UPDATE_SURVEY_STATUS } from "../graphql";
import { useLocation } from "react-router-dom";
import moment from "moment";

function QuestionCreation(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const history = useHistory();
  const location = useLocation();
  const [questionData, setQuestionData] = useState([]);
  // eslint-disable-next-line
  const { data: types } = useQuery(GET_QUESTION_TYPES, {
    fetchPolicy: "network-only",
  });
  // eslint-disable-next-line
  const { dataSource1 } = useQuery(GET_QUESTIONS, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
    onCompleted(data) {
      setQuestionData(data.questions);
    },
  });
  const [insertQuestion] = useMutation(INSERT_QUESTION);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  const [form] = Form.useForm();

  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      const formValues = form.getFieldsValue();
      let optionValue = [];
      let questionValues = [];
      formValues.questions.map((formValues, index) => {
        let fileUploadValue = {
          fileTypesAllowed: formValues.fileTypesAllowed
            ? formValues.fileTypesAllowed
            : null,
          fileSize: formValues.fileSize ? formValues.fileSize : null,
        };
        optionValue = {
          label: formValues.option ? formValues.option : null,
          index: index + 1,
          options: {
            fileUploadValue: fileUploadValue ? fileUploadValue : null,
          },
        };
        return questionValues.push({
          question: formValues.question,
          is_mandatory: true,
          index: index + 1,
          survey_id: parseInt(surveyID),
          questions_options: { data: optionValue },
        });
      });
      insertQuestion({
        variables: {
          object: questionValues,
        },
      }).then(() => {
        updateSurvey({
          variables: {
            id: parseInt(surveyID),
            status: "draft",
          },
        }).then(() => {
          props.handleEnable(true);
          if (buttonAction === "active") {
            location.pathname.split("/")[3] === "create-survey"
              ? history.push(
                  `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                )
              : history.push(
                  `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                );
          } else {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push(`/projectList/active`);
          }
        });
      });
    });
  };

  const onFinish = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  const GetFields = () => {
    return (
      <>
        {questionData.length === 0 ? (
          <Form
            name="dynamic_form_nest_item"
            form={form}
            autoComplete="off"
            requiredMark={false}
            // onFinish={onFinish}
            preserve={true}
          >
            <Form.List initialValue={["survey"]} name="questions">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <>
                        <Form.Item
                          className="text-base w-full mt-4 ml-0 mr-0 p-5 float-left border border-borderGray"
                          {...field}
                          name={[field.name, "survey"]}
                          fieldKey={[field.fieldKey, "survey"]}
                        >
                          <Form.Item
                            name={[field.name, "dragDelete"]}
                            fieldKey={[field.fieldKey, "dragDelete"]}
                          >
                            <div className="h-12">
                              <img
                                src={dragIcon}
                                className="float-left"
                                alt="drag"
                              />
                              {fields.length > 1 ? (
                                <Button
                                  type="link"
                                  className="float-right text-red"
                                  onClick={() => {
                                    remove(index);
                                  }}
                                >
                                  Delete
                                </Button>
                              ) : (
                                <Button
                                  type="link"
                                  className="float-right text-red"
                                  disabled
                                >
                                  Delete
                                </Button>
                              )}
                            </div>
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "question"]}
                            fieldKey={[field.fieldKey, "question"]}
                            style={{ width: "65%" }}
                            className="float-left"
                            label={<span>Q{index + 1}</span>}
                            colon={false}
                            rules={[
                              {
                                required: true,
                                message: "Enter the question sentence",
                              },
                            ]}
                          >
                            <Input className="ml-4" />
                          </Form.Item>

                          <Form.Item
                            name={[field.name, "optionList"]}
                            fieldKey={[field.fieldKey, "optionList"]}
                            className=" w-11/12 block float-left"
                          >
                            <FileUpload option={field} />
                          </Form.Item>

                          <Divider />
                          <Form.Item className="h-10 mb-0 block">
                            <Button
                              className="text-base text-orange float-right "
                              onClick={() => {
                                add(field.name, index + 1);
                              }}
                            >
                              Add question
                            </Button>
                          </Form.Item>
                        </Form.Item>
                      </>
                    ))}
                  </div>
                );
              }}
            </Form.List>
            {questionData.length === 0 && (
              <Form.Item className="float-right">
                <Button
                  type="link"
                  className="text-base"
                  htmlType="button"
                  onClick={() => {
                    location.pathname.split("/")[3] === "create-survey"
                      ? history.push(
                          `/project/${projectID}/create-survey/${surveyType}/overview/survey/${surveyID}`
                        )
                      : history.push(
                          `/project/${projectID}/edit-survey/${surveyType}/overview/survey/${surveyID}`
                        );
                  }}
                >
                  Back
                </Button>
                <Button
                  htmlType="submit"
                  className="text-base mx-5 rounded-md text-orange"
                  onClick={handleDraft}
                  disabled={
                    moment().isSameOrAfter(props.startDate) &&
                    props.status !== "draft"
                      ? true
                      : false
                  }
                >
                  Save as draft
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  className="text-base rounded-sm w-28"
                  onClick={onFinish}
                >
                  Next
                </Button>
              </Form.Item>
            )}
          </Form>
        ) : (
          <QuestionEdit
            handleEnable={props.handleEnable}
            startDate={props.startDate}
            status={props.status}
          />
        )}
      </>
    );
  };
  return (
    <div name="create survey">
      <GetFields />
    </div>
  );
}
export default QuestionCreation;
