import { gql } from "@apollo/client";

export const GET_PROJECT_DETAIL = gql`
  query GET_PROJECT_DETAILS($id: Int!) {
    project_by_pk(id: $id) {
      name
      survey_type
      status
      is_online_or_assisted
      client {
        id
        f_name
        l_name
        member_type {
          name
          id
        }
      }
      projectTypeByProjectType {
        name
      }
      start_date
      end_date
      description
      total_budget
      currency
      active: surveys {
        id
        consent
        name
        questions {
          answers {
            answer
            file_url
          }
        }
        survey_x_members(
          order_by: {member_id: asc}, distinct_on: [member_id], where: {survey_url: {_neq: "null"}}
        ) {
          survey_url
        survey {
          links_status
        }
          state
          user {
            title
            status
            respondent_id
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
          }
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: false } }
          ) {
            status
            interviewer_id
            appointment_date
            comments
          }
        }
        created_by_user {
          f_name
          l_name
        }
      }
      surveys {
        id
        consent
        name

        created_by_user {
          f_name
          l_name
        }
      }
      leave: surveys {
        id
        consent
        name
        questions {
          answers {
            answer
            file_url
          }
        }
        survey_x_members (
          order_by: { member_id: asc },
          distinct_on: [member_id]
        ){
          state
          user {
            title
            respondent_id
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
          }
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: true } }
          ) {
            status
            interviewer_id
            appointment_date
          }
          survey_x_member_x_interviewers_aggregate {
            aggregate {
              count
            }
          }
        }
        created_by_user {
          f_name
          l_name
        }
      }
      member_type {
        id
        name
      }
    }
  }
`;
export const GET_PROJECT_DETAIL_KREA = gql`
  query GET_PROJECT_DETAILS($id: Int!) {
    project_by_pk(id: $id) {
      name
      survey_type
      status
      is_online_or_assisted
      client {
        id
        f_name
        l_name
        member_type {
          name
          id
        }
      }
      projectTypeByProjectType {
        name
      }
      start_date
      end_date
      description
      total_budget
      currency
      active: surveys {
        id
        consent
        name
        questions {
          answers {
            answer
            file_url
          }
        }
        survey_x_members(
          order_by: {member_id: asc}, distinct_on: [member_id]
        ) {
          survey_url
        survey {
          links_status
        }
          state
          user {
            respondent_id
            title
            status
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
          }
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: false } }
          ) {
            status
            interviewer_id
            appointment_date
            comments
          }
        }
        created_by_user {
          f_name
          l_name
        }
      }
      surveys {
        id
        consent
        name

        created_by_user {
          f_name
          l_name
        }
      }
      leave: surveys {
        id
        consent
        name
        questions {
          answers {
            answer
            file_url
          }
        }
        survey_x_members (
          order_by: { member_id: asc },
          distinct_on: [member_id]
        ){
          state
          user {
            title
      f_name
      l_name
      id
      gender
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
          }
          survey_x_member_x_interviewers(
            order_by: { priority: asc }
            limit: 1
            where: { interviewer_on_leave: { _eq: true } }
          ) {
            status
            interviewer_id
            appointment_date
          }
          survey_x_member_x_interviewers_aggregate {
            aggregate {
              count
            }
          }
        }
        created_by_user {
          f_name
          l_name
        }
      }
      member_type {
        id
        name
      }
    }
  }
`;
export const GET_SURVEY = gql`
  query GET_SURVEY($id: Int!, $member_id: Int, $interviewer_id: Int) {
    survey_by_pk(id: $id) {
      name
      survey_x_members(where: { member_id: { _eq: $member_id } }) {
        user {
          f_name
          l_name
        }
        survey_x_member_x_interviewers {
          status
        }
      }
      questions {
        id
        question
        answers(where: { interviewer_id: { _eq: $interviewer_id } }) {
          answer
          file_url
        }
        questions_options {
          more_info
          options
        }
      }
    }
  }
`;

export const GET_UPLOAD_URL = gql`
  mutation GET_UPLOAD_URL($contentType: String!, $fileName: String!) {
    get_upload_url(content_type: $contentType, file_name: $fileName) {
      message
      url
    }
  }
`;

export const INSERT_ANSWERS = gql`
  mutation INSERT_ANSWERS($objects: [answers_insert_input!]!) {
    insert_answers(objects: $objects) {
      affected_rows
    }
  }
`;

export const UPDATE_STATUS = gql`
  mutation UPDATE_STATUS(
    $member_id: Int
    $interviewer_id: Int
    $status: String
    $survey_id: Int
    $appointment_date: timestamptz
    $comments: jsonb
  ) {
    update_survey_x_member_x_interviewer(
      where: {
        _and: [
          {
            survey_x_member: {
              _and: [
                { member_id: { _eq: $member_id } }
                { survey_id: { _eq: $survey_id } }
              ]
            }
          }
          { interviewer_id: { _eq: $interviewer_id } }
        ]
      }
      _set: {
        status: $status
        appointment_date: $appointment_date
        comments: $comments
      }
    ) {
      affected_rows
    }
  }
`;

export const GET_DOWNLOAD_URL = gql`
  mutation GET_VIEW_URL($name: String!) {
    get_view_url(file_name: $name) {
      message
      url
    }
  }
`;

export const DELETE_RESPONSES = gql`
  mutation DELETE_RESPONSES($id: [Int!]) {
    delete_answers(where: { question_id: { _in: $id } }) {
      affected_rows
    }
  }
`;

export const GET_USER_DETAILS = gql`
  query GET_USER_DETAILS($id: Int) {
    user(where: { id: { _eq: $id } }) {
      id
      meta_date
    }
  }
`;
