import React, { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { getColumnSearchProps } from "../../utils/table";
import {
  Dropdown,
  Menu,
  Drawer,
  Button,
  Form,
  Select,
  Row,
  DatePicker,
  Input,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { UPDATE_STATUS } from "./graphql";
import { useMutation } from "@apollo/client";
import moment from "moment";
import { redirectURL } from "../../utils/redirectURL";
import ColumnEditor from "../../components/ColumnEditor";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  userMemberData,
} from "../../components/memberColumns";
import MemberTable from "../../components/memberTable";
import { RoleContext } from "../../context/RoleContext";
import SpecialityModalPop from "../../utils/specialitySearch"

const MemberDetail = ({ data, id, interviewerID, userData }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [status, setStatus] = useState();
  const [updateStatus] = useMutation(UPDATE_STATUS);
  const [row, setRow] = useState(null);
  const { role } = useContext(RoleContext);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);
  const savedmemberDetails = localStorage.getItem('memberDetails');
  const memberDetailsinitial = savedmemberDetails
    ? JSON.parse(savedmemberDetails)
    : {
      ...memberColumnStatus,
      comments: { enabled: true, label: "Comments" },
      kreaLink: { enabled: true, label: "Krea Link" },
      speciality: { enabled: true, label: "Primary speciality" },
    }
  const [columnStatus, setColStatus] = useState(memberDetailsinitial);

  useEffect(() => {
    localStorage.setItem('memberDetails', JSON.stringify(columnStatus));
  }, [columnStatus]);
  
  delete columnStatus.interviewer; // member interviewer column not needed inside interviewers
  useEffect(() => {
    if (
      userData?.user[0]?.meta_date?.availability === "Available" ||
      userData?.user[0]?.meta_date === null
    ) {
      let copyData = JSON.parse(JSON.stringify(data));
      let tableCopy = [];
      let visitedUserIDs = new Set();

      let filteredObject =
        copyData.project_by_pk &&
        copyData.project_by_pk.surveys !== null &&
        copyData.project_by_pk.active[0].survey_x_members.filter((item) => {
          return (
            item.survey_x_member_x_interviewers[0].interviewer_id ===
            parseInt(interviewerID)
          );
        });
      copyData.project_by_pk.active[0].survey_x_members = [...filteredObject];

      copyData?.project_by_pk?.active[0]?.survey_x_members?.forEach(
        (element) => {
          const userID = element.user.id;
          const clientLink = history.location.pathname.split("-")[1] === "Third_Party_survey"
            ? element?.survey?.links_status
            : element?.survey_url;

          if (!visitedUserIDs.has(userID)) {
            visitedUserIDs.add(userID);

            tableCopy.push(
              userMemberData(element.user, (data) => {
                data.appointment_date =
                  element?.survey_x_member_x_interviewers[0]?.appointment_date;
                data.comments =
                  element?.survey_x_member_x_interviewers[0]?.comments?.description;
                data.status = element.survey_x_member_x_interviewers[0].status;
                data.survey_id = copyData?.project_by_pk?.active[0]?.id;
                data.interviewer_id =
                  element.survey_x_member_x_interviewers[0]?.interviewer_id;
                data.clientLink = clientLink;
              })
            );
          }
        }
      );

      tableCopy.length > 0 && setStatus(tableCopy[0].status);
      setTableData(tableCopy);
    } else {
      let copyData = JSON.parse(JSON.stringify(data));
      let tableCopy = [];
      let visitedUserIDs = new Set();

      let filteredObject;
      if (copyData.project_by_pk.status === "completed") {
        filteredObject =
          copyData.project_by_pk &&
          copyData.project_by_pk.surveys !== null &&
          copyData.project_by_pk.leave[0].survey_x_members.filter((item) => {
            return (
              item.survey_x_member_x_interviewers[0].interviewer_id ===
              parseInt(interviewerID)
            );
          });
      } else {
        filteredObject =
          copyData.project_by_pk &&
          copyData.project_by_pk.surveys !== null &&
          copyData.project_by_pk.leave[0].survey_x_members.filter((item) => {
            return (
              item.survey_x_member_x_interviewers.length !== 0 &&
              item.survey_x_member_x_interviewers_aggregate.aggregate.count === 1
            );
          });
      }
      copyData.project_by_pk.leave[0].survey_x_members = [...filteredObject];

      copyData?.project_by_pk?.leave[0]?.survey_x_members?.forEach((element) => {
        const userID = element.user.id;
        const clientLink = history.location.pathname.split("-")[1] === "Third_Party_survey"
          ? element?.survey?.links_status
          : element?.survey_url;

        if (!visitedUserIDs.has(userID)) {
          visitedUserIDs.add(userID);

          tableCopy.push(
            userMemberData(element.user, (data) => {
              data.appointment_date =
                element?.survey_x_member_x_interviewers[0]?.appointment_date;
              data.comments =
                element?.survey_x_member_x_interviewers[0]?.comments?.description;
              data.status = element.survey_x_member_x_interviewers[0].status;
              data.survey_id = copyData?.project_by_pk?.leave[0]?.id;
              data.interviewer_id =
                element.survey_x_member_x_interviewers[0]?.interviewer_id;
              data.clientLink = clientLink;
            })
          );
        }
      });

      tableCopy.length > 0 && setStatus(tableCopy[0].status);
      setTableData(tableCopy);
    }
    // eslint-disable-next-line
  }, [data, userData]);

  const onFinish = useCallback(
    (values) => {
      updateStatus({
        variables: {
          member_id: parseInt(row?.id),
          interviewer_id: parseInt(row?.interviewer_id),
          status: values.status,
          survey_id: parseInt(row?.survey_id),
          appointment_date:
            moment(values.appointment_date).format(
              "YYYY-MM-DDThh:mm:ss.000Z"
            ) || moment().format("YYYY-MM-DDThh:mm:ss.000Z"),
          comments: values.description
            ? { description: values.description }
            : null,
        },
      }).then(() => {
        setStatus(null);
        setIsVisible(false);
        window.location.reload();
      });
    },
    [row, updateStatus]
  );

  const footer = useMemo(
    () => (
      <Row>
        <div className="w-full h-16 mt-4">
          <Button
            type="primary"
            htmlType="submit"
            className="float-right text-base rounded-md"
            onClick={form.submit}
          >
            Set status
          </Button>

          <div className="text-textMedium float-right pt-1 pr-6">
            <Button
              type="link"
              onClick={() => {
                form.resetFields();
                // setStatus(null);
                setIsVisible(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Row>
    ),
    [form]
  );
  function disabledDate(current) {
    return current && current < moment().endOf("day");
  }

  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const columns = useMemo(
    () =>
      [
        ...memberColumns(columnStatus, handleIconClick),
        ...roleBasedColumns(role, columnStatus),
        ...[
          {
            title: "COMMENTS",
            dataIndex: "comments",
            className: columnStatus?.comments?.enabled
              ? "table-cell"
              : "hidden",
            isShow: columnStatus?.comments?.enabled,
            key: "comments",
            ...getColumnSearchProps("comments", "comments"),
            width: 200,
            render: (text) => (
              <div className="flex flex-col items-start justify-start">
                {text?.length > 20 ? `${text.substring(0, 20)}...` : text}
              </div>
            ),
          },
          {
            title: "KREA LINK",
            dataIndex: "survey link",
            isShow: columnStatus?.kreaLink?.enabled,
            className: columnStatus?.kreaLink?.enabled
              ? "table-cell"
              : "hidden",
            key: "survey link",
            width: 200,
            render: (text, record) => (
              <div className="flex justify-center w-full cursor-pointer">
                <Button
                  type="link"
                  onClick={() => {
                    // redirectURL(`/survey/${record.survey_id}/member/${record.id}`);
                    // window.open(`/survey/${record.survey_id}/member/${record.id}`,"_blank");
                    if (
                      // data?.project_by_pk?.projectTypeByProjectType?.name === "Assisted" ||
                      // data?.project_by_pk?.survey_type === "Krea"
                      (data?.project_by_pk?.projectTypeByProjectType?.name === 'Assisted' || data?.project_by_pk?.projectTypeByProjectType?.name === 'Online') && data?.project_by_pk?.is_online_or_assisted ? data?.project_by_pk?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : data?.project_by_pk?.projectTypeByProjectType?.name) {
                      localStorage.setItem(
                        "interviewerID",
                        parseInt(record.interviewer_id)
                      );
                      localStorage.setItem(
                        "surveyID",
                        parseInt(record.survey_id)
                      );
                      localStorage.setItem("projectType", "Assisted");
                      localStorage.setItem("memberID", parseInt(record.id));
                      redirectURL(
                        `/survey/${record.survey_id}/member/${record.id}`
                      );
                    } else if (
                      record?.status === "Completed" ||
                      record?.status === "Approved"
                    ) {
                      history.push(
                        `/project/${id}/viewresponses/${record.survey_id}/member/${record.id}/interviewer/${record.interviewer_id}`
                      );
                    } else {
                      history.push(
                        `/project/${id}/attendsurvey/${record.survey_id}/member/${record.id}/interviewer/${record.interviewer_id}`
                      );
                    }
                  }}
                >
                  View survey
                </Button>
              </div>
            ),
          },
          {
            title: "Action",
            key: "action",
            width: 100,
            render: (text, record, index) => (
              <>
                <Dropdown
                  key={index}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="reponse"
                        onClick={() => {
                          if (
                            // data?.project_by_pk?.projectTypeByProjectType?.name === "Assisted" ||
                            // data?.project_by_pk?.survey_type === "Krea"
                            (data?.project_by_pk?.projectTypeByProjectType?.name === 'Assisted' || data?.project_by_pk?.projectTypeByProjectType?.name === 'Online') && data?.project_by_pk?.is_online_or_assisted ? data?.project_by_pk?.projectTypeByProjectType.name === 'Assisted' ? "Assisted / Online" : "Online / Assisted" : data?.project_by_pk?.projectTypeByProjectType?.name
                          ) {
                            localStorage.setItem(
                              "interviewerID",
                              parseInt(record.interviewer_id)
                            );
                            localStorage.setItem(
                              "surveyID",
                              parseInt(record.survey_id)
                            );
                            localStorage.setItem("projectType", "Assisted");
                            localStorage.setItem(
                              "memberID",
                              parseInt(record.id)
                            );
                            redirectURL(
                              `/survey/${record.survey_id}/member/${record.id}`
                            );
                          } else if (
                            record?.status === "Completed" ||
                            record?.status === "Approved"
                          ) {
                            history.push(
                              `/project/${id}/viewresponses/${record.survey_id}/member/${record.id}/interviewer/${record.interviewer_id}`
                            );
                          } else {
                            history.push(
                              `/project/${id}/attendsurvey/${record.survey_id}/member/${record.id}/interviewer/${record.interviewer_id}`
                            );
                          }
                        }}
                      >
                        Update response
                      </Menu.Item>

                      <Menu.Item
                        key="status"
                        onClick={() => {
                          setStatus(record.status);
                          setRow(record);
                          if (record.status !== "active")
                            form.setFieldsValue({
                              status: record.status,
                              description: record.comments,
                            });
                          if (record.status === "Appointment Fixed") {
                            form.setFieldsValue({
                              appointment_date: record.appointment_date,
                            });
                          }
                          setIsVisible(true);
                        }}
                        disabled={
                          record?.status === "Approved" ||
                          record?.status === "approved"
                        }
                      >
                        Update call status
                      </Menu.Item>
                      {record.comments && (
                        <Menu.Item
                          key="comment"
                          onClick={() => {
                            setStatus(record.status);
                            setRow(record);
                            if (record.status !== "active")
                              form.setFieldsValue({
                                status: record.status,
                                description: record.comments,
                              });
                            if (record.status === "Appointment Fixed") {
                              form.setFieldsValue({
                                appointment_date: record.appointment_date,
                              });
                            }
                            setIsVisible(true);
                          }}
                        >
                          View comment
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <MoreOutlined className="text-2xl font-bold cursor-pointer" />
                </Dropdown >
                <Drawer
                  title="Interview status"
                  width={500}
                  onClose={() => {
                    form.setFieldsValue({
                      status: "",
                      description: "",
                    });
                    setStatus(null);
                    setIsVisible(false);
                  }}
                  visible={isVisible}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={footer}
                >
                  <Form
                    name="admin"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    autoComplete="off"
                    requiredMark={false}
                    colon={false}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                  >
                    <Form.Item
                      label="Status"
                      name="status"
                      rules={[
                        { required: true, message: "Please select a status!" },
                      ]}
                      className="mt-2"
                    >
                      <Select
                        className="mt-4"
                        placeholder="Select a status"
                        size="large"
                        onChange={(e) => {
                          setStatus(e);
                        }}
                        showSearch
                        optionFilterProp="children"
                      >
                        <Select.Option value="completed">
                          Completed{" "}
                        </Select.Option>
                        <Select.Option value="Screened">
                          Screened{" "}
                        </Select.Option>
                        <Select.Option value="Terminated">
                          Terminated
                        </Select.Option>
                        {data?.project_by_pk?.projectTypeByProjectType?.name ===
                          "Assisted" && (
                            <Select.Option value="Survey Link Sent">
                              Survey Link Sent
                            </Select.Option>
                          )}
                        <Select.Option value="Call later">
                          Call Later{" "}
                        </Select.Option>
                        <Select.Option value="Not Interested">
                          Not Interested
                        </Select.Option>
                        <Select.Option value="Appointment Fixed">
                          Appointment Fixed
                        </Select.Option>
                        <Select.Option value="Busy">Busy</Select.Option>
                        <Select.Option value="Not Reachable / Out of Coverage">
                          Not Reachable / Out of Coverage
                        </Select.Option>
                        <Select.Option value="Call Waiting">
                          Call Waiting
                        </Select.Option>
                        <Select.Option value="No Response">
                          No Response
                        </Select.Option>
                        <Select.Option value="Person Not Available">
                          Person Not Available
                        </Select.Option>
                        <Select.Option value="Wrong Number">
                          Wrong Number
                        </Select.Option>
                        <Select.Option value="Invalid Number">
                          Invalid Number
                        </Select.Option>
                        <Select.Option value="Language Problem">
                          Language Problem
                        </Select.Option>
                        <Select.Option value="Call Declined">
                          Call Declined
                        </Select.Option>
                        <Select.Option value="Others">Others</Select.Option>
                      </Select>
                    </Form.Item>
                    {status === "Appointment Fixed" && (
                      <Form.Item
                        label="Date and time"
                        name="appointment_date"
                        rules={[
                          { required: true, message: "Please select a date!" },
                        ]}
                      >
                        <DatePicker
                          size="large"
                          className="w-full"
                          showTime
                          disabledDate={disabledDate}
                        />
                      </Form.Item>
                    )}
                    {status && (
                      <Form.Item name="description">
                        <Input.TextArea
                          showCount
                          maxLength={500}
                          className="rounded-md"
                          autoSize={{ minRows: 4, maxRows: 6 }}
                          placeholder="Type your text here"
                        />
                      </Form.Item>
                    )}
                  </Form>
                </Drawer>
              </>
            ),
          },
        ].filter((data) => data.isShow === undefined || data.isShow),
      ].filter((column) => column.key !== "kreaLink" && column.key !== "interviewer") // Exclude the specific column
    ,
    [columnStatus, footer, form, history, id, isVisible, onFinish, data, status]
  );

  return (
    <div>
      <div className=" float-right mb-5 mr-5">
        <ColumnEditor
          columns={columnStatus}
          onSave={(column) => {
            setColStatus(column);
          }}
        />
      </div>
      <MemberTable
        dataSource={tableData}
        columns={columns}
        rowKey={(record) => record.id}
      />
      <SpecialityModalPop
        titleSpec={`Selected ${selectedColumnNameSpec}`}
        visibleSpec={isModalVisibleSpec}
        onOkSpec={handleModalOkSpec}
        onCancelSpec={handleModalCloseSpec}
        searchQuerySpec={searchQuerySpec}
        handleSearchSpec={handleSearchSpec}
        handleCheckboxChangeSpec={handleCheckboxChangeSpec}
        filteredCheckboxDataSpec={filteredCheckboxDataSpec}
        selectedDataSpec={selectedDataSpec}
        initialSelectedDataSpec={initialSelectedDataSpec}
        isSubmitDisabledSpec={isSubmitDisabledSpec}
        handleResetSpec={handleResetSpec}
      />
    </div>
  );
};

export default MemberDetail;
