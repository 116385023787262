import moment from "moment";

export const dateTimeValidation = (surveyDetails) => {
  let status = true;
  let message = "";
  let screened = false;
  let jumpToquestion;
  let jumpToStatus = false;
  surveyDetails?.current_question?.validation?.dateAndTimeRange.some(
    // eslint-disable-next-line
    (validation, i) => {
      let answer;
      let copy = surveyDetails.current_answer[i];
      for (let [key, value] of Object.entries(copy)) {
        if (key !== "type") {
          answer = value;
        }
      }
      if (validation.dateRange) {
        if (
          !moment(answer, "DD/MM/YYYY").isBetween(
            moment(validation.dateRange.dateRange[0]),
            moment(validation.dateRange.dateRange[1])
          )
        ) {
          status = false;
          message = `Enter a valid date for ${validation.label}`;
          return true;
        }
      }
      if (validation.timeRange && validation.timeRange.timeRange !== "") {
        if (
          !moment(
            moment(answer, "DD/MM/YYYY hh:mm a").format("hh:mm A"),
            "hh:mm A"
          ).isBetween(
            moment(validation.timeRange.startTime, "hh:mm A"),
            moment(validation.timeRange.endTime, "hh:mm A")
          )
        ) {
          status = false;
          message = `Enter a valid time for ${validation.label}`;
          return true;
        }
      }
    }
  );

  if (status === true) {
    // eslint-disable-next-line
    surveyDetails.current_question.questions_options.some((option, i) => {
      let answer;
      let copy = surveyDetails.current_answer[i];
      for (let [key, value] of Object.entries(copy)) {
        if (key !== "type") {
          answer = value;
        }
      }
      let dateValueMoment =
        option.logic !== null && moment(option.logic.dateValueMoment);
      // eslint-disable-next-line
      switch (option.logic !== null && option.logic.dateOperator) {
        case "greaterEqual":
          if (
            moment(answer, "DD/MM/YYYY hh:mm a").isSameOrAfter(dateValueMoment)
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "lesserEqual":
          if (
            moment(answer, "DD/MM/YYYY hh:mm a").isSameOrBefore(dateValueMoment)
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "greater":
          if (moment(answer, "DD/MM/YYYY hh:mm a").isAfter(dateValueMoment)) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "less":
          if (moment(answer, "DD/MM/YYYY hh:mm a").isBefore(dateValueMoment)) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
        case "between":
          if (
            moment(answer, "DD/MM/YYYY hh:mm a").isBetween(
              moment(option.logic.dateValueArrayMoment[0]),
              moment(option.logic.dateValueArrayMoment[1])
            )
          ) {
            if (option.logic && option.logic.screenExit) {
              screened = true;
              status = true;
            } else {
              status = true;
              jumpToquestion = option.logic.jumpToQuestion;
              jumpToStatus = true;
            }
          }
          break;
      }
    });
  }
  return {
    status,
    message,
    jumpToquestion,
    screened,
    jumpToStatus,
  };
};
