import React from "react";
import { Button, Card, Input, Form, notification } from "antd";
import logo from "../../assets/logo.svg";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "./graphql";
import { useHistory } from "react-router";

export const ForgotPassword = () => {
  const history = useHistory();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => {
      if (data.forgotpassword) {
        notification.success({ message: "Email sent" });
      }
      history.push("/");
    },
    onError: (error) => {
      notification.error({
        message: error.message,
      });
    },
  });

  return (
    <div className="flex justify-center items-center  h-screen">
      <Card
        className="shadow-lg"
        style={{
          width: "508px",
          padding: "65px 20px",
        }}
      >
        <div className="h-12 flex justify-center items-center mb-8">
          <img src={logo} alt="krea" />
        </div>
        <Form
          name="normal_login"
          className="login-form"
          layout="vertical"
          requiredMark={false}
          onFinish={(values) => {
            forgotPassword({
              variables: {
                email: values.email,
              },
            });
          }}
        >
          {/* {error ? <p className="text-red text-center">{error}</p> : null} */}
          <Form.Item
            label={<span className="text-base">Email</span>}
            name="email"
            rules={[
              { message: "Enter your email", required: true },
              { type: "email", message: "Enter a valid email!" },
            ]}
          >
            <Input size="large" type="text" placeholder="Enter your email" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button text-base"
              block
              size="large"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
