import React from "react";
import { useQuery } from "@apollo/client";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router";
import SurveyCompleted from "./completed";
import SurveyScreened from "./screened";
import SurveyQuotaFull from "./quotaFull";
import { GET_SURVEY } from "./graphql";
import ErrorPage from "../../components/errorPage";

const EndLink = (props) => {
  const params = useParams();
  const location = useLocation();
  const { data, loading, error } = useQuery(GET_SURVEY, {
    variables: {
      project_id: Number(params.projectID),
    },
  });
  if (error) {
    return <ErrorPage />;
  }

  return (
    <Spin spinning={loading}>
      <div>
        {/* <SurveyNav /> */}
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="hidden md:block text-h5 font-semibold text-textDark capitalize">
              {data?.project?.surveys?.name || ""}
            </div>
          </div>

          {location.pathname.split("/")[3] === "Complete" && (
            <SurveyCompleted />
          )}

          {location.pathname.split("/")[3] === "QuotaFull" && (
            <SurveyQuotaFull />
          )}
          {location.pathname.split("/")[3] === "EarlyScreenOut" && (
            <SurveyScreened />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default EndLink;
