import React, { useState } from "react";
import { Select } from "antd";
import { useQuery } from "@apollo/client";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

const { Option } = Select;
const Dropdown = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  const surveyDetails = useRecoilValue(surveyState);
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [answer, setAnswer] = useState([]);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData([...props.questions_options]);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let copy = [];
    answers?.questions[0]?.answers[0]?.more_info.forEach((item) => {
      for (let [key, value] of Object.entries(item)) {
        if (key !== "type") {
          copy.push(value);
        }
      }
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer:
        answers?.questions[0]?.answers[0]?.more_info !== undefined
          ? answers?.questions[0]?.answers[0]?.more_info
          : [],
    }));
    setAnswer(copy);
    // eslint-disable-next-line
  }, [answers]);

  React.useEffect(() => {
    setItems(
      data &&
        data.map((option) => ({
          id: option.id,
          label: option.label,
          options: option.options,
        }))
    );
  }, [data]);
  const handleOnChange = (value, label, i) => {
    let current_answer_copy = [...surveyDetails.current_answer];
    let ans_copy = [...answer];
    ans_copy[i] = value;
    let copy = {};
    copy[label] = value;
    copy["type"] = props.question.question_type.name;
    current_answer_copy[i] = copy;

    setAnswer(ans_copy);
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer_copy,
    }));
  };

  return (
    <div className="mt-6">
      {items &&
        items.map((option, i) => (
          <div className="flex flex-col justify-start items-start lg:grid lg:grid-cols-3 lg:gap-4 mb-4">
            {option.label && (
              <span className="mb-4 lg:mb-0 text-h8 text-textMedium mr-8 font-semibold">
                {option.label}
              </span>
            )}

            <Select
              size="large"
              style={{ width: 300 }}
              placeholder="Select"
              value={answer[i]}
              onChange={(value) => {
                handleOnChange(value, option.label, i);
              }}
            >
              {option.options.dropdownValue.map((value, i) => {
                return (
                  <Option value={value} key={`question-dropdownValue-${i}`}>
                    {value}
                  </Option>
                );
              })}
            </Select>
          </div>
        ))}
    </div>
  );
};

export default Dropdown;
