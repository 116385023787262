import { Space, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { get } from "lodash";

const getColumnSearchProps = (dataIndex, placeholder) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${placeholder}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
        autoFocus={true}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          className="flex items-center justify-center"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        {/*<Button*/}
        {/*	type="link"*/}
        {/*	size="small"*/}
        {/*	onClick={() => {*/}
        {/*		confirm({ closeDropdown: false });*/}
        {/*	}}*/}
        {/*>*/}
        {/*	Filter*/}
        {/*</Button>*/}
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1DA57A" : undefined }} />
  ),
  onFilter: (value, record) =>
    get(record, dataIndex)
      ? get(record, dataIndex)
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase())
      : "",
});

export { getColumnSearchProps };
