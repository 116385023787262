import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Form, Input, Button, message, notification } from "antd";
import { useHistory, useParams } from "react-router";
import RichTextbox from "../../../../../components/richTextbox";
import { useLocation } from "react-router-dom";
import { projectForm } from "../../../../../atoms";
import { useSetRecoilState } from "recoil";
import ReactHtmlParser from "react-html-parser";
import { CREATE_SURVEY, GET_SURVEY_DETAILS, UPDATE_SURVEY } from "../graphql";
import moment from "moment";

function Overview(props) {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID, projectID, surveyType } = useParams();
  const setValues = useSetRecoilState(projectForm);
  const array = { name: "", description: "", consent: "" };
  const history = useHistory();
  const [content, setContent] = useState("");
  const [surveyData, setSurveyData] = useState(array);
  const [textLength, setTextLength] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  // eslint-disable-next-line
  const { data } = useQuery(GET_SURVEY_DETAILS, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      if (data) {
        surveyID &&
          data.survey.forEach((survey) => {
            if (parseInt(surveyID) === survey.id) {
              let dataobj = {
                name: survey.name ? survey.name : "",
                description: survey.description ? survey.description : "",
                consent: survey.consent ? survey.consent : "",
                start_date: survey.start_date ? survey.start_date : "",
                end_date: survey.end_date ? survey.end_date : "",
                display_questions_on_survey_cards:
                  survey.display_questions_on_survey_cards
                    ? survey.display_questions_on_survey_cards
                    : "",
              };

              setSurveyData(dataobj);
            }
          });
      } else {
        let dataobj = {
          name: "",
          description: "",
          consent: "<p></p>",
        };

        setSurveyData(dataobj);
      }
    },
  });

  useEffect(() => {
    form.setFieldsValue({
      surveyDescription: surveyData.description ? surveyData.description : "",
      surveyName: surveyData.name ? surveyData.name : "",
    });
    if (surveyData.start_date) {
      setValues((oldValues) => ({
        ...oldValues,
        surveyDetails: {
          start_date: surveyData.start_date ? surveyData.start_date : "",
          end_date: surveyData.end_date ? surveyData.end_date : "",
          display: surveyData.display_questions_on_survey_cards
            ? surveyData.display_questions_on_survey_cards
            : false,
        },
      }));
    }
    // eslint-disable-next-line
  }, [surveyData]);

  const [createSurvey] = useMutation(CREATE_SURVEY, {
    onCompleted(data) {
      history.push(
        `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${data?.insert_survey?.returning[0]?.id}`
      );
    },
  });

  const [updateSurvey] = useMutation(UPDATE_SURVEY);
  const charCount = (txtLength, content) => {
    setTextLength(txtLength);
    setContent(content);
  };

  const [form] = Form.useForm();
  const handleFormValues = async (buttonAction) => {
    form.validateFields().then(() => {
      const formValues = form.getFieldsValue();
      if (formValues.surveyName.trim() === "") {
        setErrorMessage("Please enter valid text for Title name");
      } else if (formValues.surveyDescription.trim() === "") {
        setErrorMessage("Please enter valid text for Description");
      } else if (
        textLength !== 0 &&
        ReactHtmlParser(content)[0].props.children[0].trim() === ""
      ) {
        setErrorMessage("Please enter valid text for Consent");
      } else {
        setErrorMessage("");
        surveyID
          ? updateSurvey({
              variables: {
                id: parseInt(surveyID),
                object: {
                  name: formValues.surveyName,
                  description: formValues.surveyDescription,
                  consent: content ? content : surveyData.consent,
                  project_id: parseInt(projectID),
                  status:
                    buttonAction === "draft"
                      ? "draft"
                      : moment().isSameOrAfter(props.startDate)
                      ? props.status === "draft"
                        ? "draft"
                        : "active"
                      : props.status === "active"
                      ? "active"
                      : "draft",
                },
              },
            }).then((res) => {
              if (buttonAction === "active") {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                      `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${res.data.update_survey.returning[0].id}`
                    )
                  : history.push(
                      `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${res.data.update_survey.returning[0].id}`
                    );
              } else {
                notification.success({
                  message: "Success!",
                  description: "Saved as draft!",
                });
                history.push(`/projectList/active`);
              }
            })
          : createSurvey({
              variables: {
                objects: {
                  name: formValues.surveyName,
                  description: formValues.surveyDescription,
                  consent: content ? content : "<p></p>",
                  project_id: parseInt(projectID),
                  status: "draft",
                },
              },
            }).then((res) => {
              if (buttonAction === "active") {
                history.push(
                  `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${res.data.insert_survey.returning[0].id}`
                );
              } else {
                notification.success({
                  message: "Success!",
                  description: "Saved as draft!",
                });
                history.push(`/projectList/active`);
              }
            });
      }
    });
  };

  const handleCopyLink = async (value) => {
    var copyText = document.getElementById(`input${value}`);
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
    message.success("Link copied!");
  };

  const onFinish = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  return (
    <Form
      name="overview_form"
      layout="vertical"
      requiredMark={false}
      form={form}
    >
      <Form.Item
        label={<span className="text-base text-textLight">Survey Title</span>}
        name="surveyName"
        rules={[{ required: true, message: "Enter the survey name" }]}
      >
        <Input.TextArea
          showCount
          maxLength={100}
          className="rounded-md"
          autoSize={{ maxRows: 1 }}
        />
      </Form.Item>
      <Form.Item
        label={
          <span className="text-base text-textLight">Survey Description</span>
        }
        name="surveyDescription"
        rules={[{ required: true, message: "Enter the survey description" }]}
      >
        <Input.TextArea
          showCount
          maxLength={500}
          className="rounded-md"
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      </Form.Item>
      <Form.Item
        label={<span className="text-base text-textLight">Consent</span>}
        name="consent"
      >
        {(surveyData.consent || surveyID === undefined) && (
          <>
            <RichTextbox
              charCount={charCount}
              placeholder="Enter consent or skip by clicking next"
              content={surveyData.consent ? surveyData.consent : "<p></p>"}
            />
            <span className="text-textLight float-right">
              {textLength} / 500
            </span>
          </>
        )}
      </Form.Item>
      <Form.Item>
        <p className="text-h6 text-textMedium">End screens</p>
        <div className="mb-4">
          <p className="text-h8 text-textLight">Screened</p>
          <p>
            <Input
              className="w-5/12 bg-lightButton border-0 text-orange text-h8"
              value={`${baseURL}/project/${projectID}/EarlyScreenOut`}
              id="input1"
            />
            <Button
              htmlType="button"
              className="text-base mx-5 rounded-md text-orange"
              onClick={() => handleCopyLink(1)}
            >
              Copy Link
            </Button>
          </p>
        </div>
        <div className="mb-4">
          <p className="text-h8 text-textLight">Completed</p>
          <p>
            <Input
              className="w-5/12 bg-lightButton border-0 text-orange text-h8"
              value={`${baseURL}/project/${projectID}/Complete`}
              id="input2"
            />
            <Button
              htmlType="button"
              className="text-base mx-5 rounded-md text-orange"
              onClick={() => handleCopyLink(2)}
            >
              Copy Link
            </Button>
          </p>
        </div>
        <div className="mb-4">
          <p className="text-h8 text-textLight">Quota full</p>
          <p>
            <Input
              className="w-5/12 bg-lightButton border-0 text-orange text-h8"
              value={`${baseURL}/project/${projectID}/QuotaFull`}
              id="input3"
            />
            <Button
              htmlType="button"
              className="text-base mx-5 rounded-md text-orange"
              onClick={() => handleCopyLink(3)}
            >
              Copy Link
            </Button>
          </p>
        </div>
      </Form.Item>
      <Form.Item>
        <div style={{ color: "red" }}>
          <span>{errorMessage}</span>
        </div>
      </Form.Item>
      <Form.Item className="float-right mt-10">
        <Button
          type="link"
          className="text-base"
          htmlType="button"
          onClick={() => history.push("/projectList/active")}
        >
          Back
        </Button>
        <Button
          htmlType="submit"
          className="text-base mx-5 rounded-md text-orange"
          onClick={handleDraft}
          disabled={
            moment().isSameOrAfter(props.startDate) && props.status !== "draft"
              ? true
              : false
          }
        >
          Save as draft
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          className="text-base rounded-sm w-28"
          onClick={onFinish}
        >
          Next
        </Button>
      </Form.Item>
    </Form>
  );
}
export default Overview;
