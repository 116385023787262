import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  Dropdown,
  Menu,
  Spin,
  Table,
  Tag,
  Button,
  Checkbox,
  Row,
  Tabs,
  Col,
} from "antd";
import { MoreOutlined, PlusOutlined, FilterOutlined } from "@ant-design/icons";
import { getColumnDateSearchProps } from "../../utils/dateSearch";
import { getColumnSearchProps } from "../../utils/table";
import ErrorPage from "../../components/errorPage";
import { PROJECTLIST } from "./graphql";
import greenCircle from "../../assets/Icon/greenCircle.svg";
import exclamationCircle from "../../assets/Icon/exclamationCircle.svg";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../components/ColumnEditor";

const { TabPane } = Tabs;

function SuperadminList() {
  const history = useHistory();
  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState("1");
  const location = useLocation();
  const [draft, setDraftProjects] = React.useState([]);
  const [completed, setCompletedProjects] = React.useState([]);
  const [activeTabPane, setActiveTabPane] = useState("1");
  const tabPane = ["active", "drafts", "completed"];
  const [liveProjects, setLiveProjects] = React.useState([]);
  const savedsuperAdminList = localStorage.getItem('superAdminList');
  const superAdminListinitial = savedsuperAdminList
    ? JSON.parse(savedsuperAdminList)
    : {
      client: { enabled: true, label: "Client" },
      category: { enabled: true, label: "Category" },
      assigned: { enabled: true, label: "Assigned to" },
      sample: { enabled: true, label: "Sample" },
      start: { enabled: true, label: "Start date" },
      end: { enabled: true, label: "End date" },
      status: { enabled: true, label: "Status" },
      currency: { enabled: false, label: "Currency" },
      cpi: { enabled: false, label: "Cost per interview" },
      incentive: { enabled: false, label: "Incentive" },
      ir: { enabled: false, label: "Estimated IR" },
      budget: { enabled: false, label: "Project budget" },
      total_budget: { enabled: false, label: "Total budget" },
    }
  const [columnStatus, setStatus] = useState(superAdminListinitial);

  useEffect(() => {
    localStorage.setItem('superAdminList', JSON.stringify(columnStatus));
  }, [columnStatus]);
  useEffect(() => {
    if (location.pathname === "/projectList/active") {
      setActiveTabPane("1");
    } else if (location.pathname === "/projectList/drafts") {
      setActiveTabPane("2");
    } else if (location.pathname === "/projectList/completed") {
      setActiveTabPane("3");
    }
  }, [location.pathname]);
  const columns = () => [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h11 text-textLight mb-2">{`ID #${record.id}`}</div>
          <div className="text-h8 text-textDark capitalize">{record.name}</div>
        </div>
      ),
      ...getColumnSearchProps("name", "Project Name"),
      fixed: "left",
    },
    {
      title: "CLIENT",
      key: "client.f_name",
      dataIndex: "clientName",
      className: columnStatus?.client?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="text-h8 text-textMedium">{`${text}`}</div>
      ),
      ...getColumnSearchProps("clientName", "Client Name"),
    },
    {
      title: "CATEGORY",
      key: "projectTypeByProjectType.name",
      className: columnStatus?.category?.enabled ? "table-cell" : "hidden",
      dataIndex: "category",
      ...getColumnSearchProps("category", "Category"),
      render: (text, record, index) => (
        <>
          <span> {text}</span>
          <div>
            {record.member_types !== null && record.member_types[0] === 1 && (
              <span className="text-xs text-textLight font-normal">
                Physician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 2 && (
              <span className="text-xs text-textLight font-normal">
                Technician
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 3 && (
              <span className="text-xs text-textLight font-normal">
                Patient
              </span>
            )}
            {record.member_types !== null && record.member_types[0] === 4 && (
              <span className="text-xs text-textLight font-normal">
                Consumer
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      title: "ASSIGNED TO",
      key: "managerName",
      dataIndex: "managerName",
      className: columnStatus?.assigned?.enabled ? "table-cell" : "hidden",
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          {text === "undefined undefined" ? (
            <div className="text-h8 text-textMedium"></div>
          ) : (
            <div className="text-h8 text-textMedium">{`${text}`}</div>
          )}
        </div>
      ),
      ...getColumnSearchProps("managerName", "Assigned Managers"),
    },
    {
      title: "SAMPLE",
      key: "sample",
      dataIndex: "sample",
      className: columnStatus?.sample?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("sample", "Sample size"),
      render: (text, record, index) => {
        let avgPerDay = Math.ceil(
          record.sample /
          moment(record?.end_date).diff(moment(record?.start_date), "days")
        );
        let days = moment().diff(moment(record?.start_date), "days");
        return (
          <div className="text-textMedium text-h8 w-20">
            <span className="float-left">
              {record?.surveys?.reduce(
                (arr, cur) =>
                  arr +
                  (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                0
              )}{" "}
              / {record.sample}
            </span>
            {location.pathname.split("/")[2] === "active" && (
              <>
                <span className="float-right">
                  {activeTab === "1" &&
                    days >= 1 &&
                    record?.surveys?.reduce(
                      (arr, cur) =>
                        arr +
                        (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                      0
                    ) >= avgPerDay ? (
                    <img src={greenCircle} className="ml-2" alt="greencircle" />
                  ) : // <ArrowUpOutlined style={{ color: "green" }} className="ml-2" />
                    null}
                </span>
                <span className="float-right">
                  {activeTab === "1" &&
                    days >= 1 &&
                    record?.surveys?.reduce(
                      (arr, cur) =>
                        arr +
                        (cur?.survey_x_members_aggregate?.aggregate?.count || 0),
                      0
                    ) < avgPerDay ? (
                    <img
                      src={exclamationCircle}
                      className="ml-2"
                      alt="exclamation"
                    />
                  ) : // <ArrowDownOutlined style={{ color: "red" }} className="ml-2" />
                    null}
                </span>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "START DATE",
      key: "start_date",
      className: columnStatus?.start?.enabled ? "table-cell" : "hidden",
      dataIndex: "start_date",
      ...getColumnDateSearchProps("start_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "END DATE",
      key: "end_date",
      className: columnStatus?.end?.enabled ? "table-cell" : "hidden",
      dataIndex: "end_date",
      ...getColumnDateSearchProps("end_date"),
      render: (text, record, index) => (
        <div className="flex flex-col items-start justify-start">
          <div className="text-h8 text-textMedium mb-1 font-semibold">
            {moment(text).format("MMM DD, YYYY")}
          </div>
          <div className="text-h8 text-textLight">
            {moment(text).format("kk[h] mm[m]")}
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      className: columnStatus?.status?.enabled ? "table-cell" : "hidden",
      dataIndex: "status",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Draft",
          value: "draft",
        },
        {
          text: "Completed",
          value: "completed",
        },
        {
          text: "Re-opened",
          value: "re-opened",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text, record, index) => (
        <div className="flex items-end justify-center">
          {record.status === "active" && (
            <Tag className="capitalize" color="blue">
              {text}
            </Tag>
          )}
          {record.status === "draft" && (
            <Tag className="capitalize" color="gold">
              {text}
            </Tag>
          )}
          {record.status === "completed" && (
            <Tag className="capitalize" color="green">
              {text}
            </Tag>
          )}
          {record.status === "re-opened" && (
            <Tag className="capitalize" color="magenta">
              {text}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "CURRENCY",
      key: "currency",
      dataIndex: "currency",
      className: columnStatus?.currency?.enabled ? "table-cell" : "hidden",
      filters: [
        {
          text: "INR",
          value: "inr",
        },
        {
          text: "USD",
          value: "usd",
        },
      ],
      onFilter: (value, record) => record.currency.indexOf(value) === 0,
      filterIcon: () => <FilterOutlined />,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "COST PER INTERVIEW",
      key: "cpi",
      dataIndex: "cost_per_interview",
      className: columnStatus?.cpi?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("cost_per_interview", "CPI"),
    },
    {
      title: "INCENTIVE",
      key: "incentive",
      dataIndex: "incentive",
      className: columnStatus?.incentive?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("incentive", "Incentive"),
    },
    {
      title: "ESTIMATED IR",
      key: "ir",
      dataIndex: "estimated_ir",
      className: columnStatus?.ir?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("estimated_ir", "Estimated IR"),
    },
    {
      title: "PROJECT BUDGET",
      key: "project_budget",
      dataIndex: "budget",
      className: columnStatus?.budget?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("budget", "Budget"),
    },
    {
      title: "TOTAL BUDGET",
      key: "total_budget",
      dataIndex: "total_budget",
      className: columnStatus?.total_budget?.enabled ? "table-cell" : "hidden",
      ...getColumnSearchProps("total_budget", "Total budget"),
    },
    {
      title: "ACTION",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (text, record, index) => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                onClick={() => {
                  let val =
                    record?.survey_type !== null && record?.survey_type !== ""
                      ? "- " + record?.survey_type
                      : "";
                  let category =
                    record?.projectTypeByProjectType?.name + " " + val;
                  const surveyType = category.replace(/ /g, "");

                  record?.surveys.length !== 0
                    ? history.push(
                      `/viewProject/projectDetails/project/${record?.id}/survey/${record?.surveys[0]?.id}/${surveyType}`
                    )
                    : history.push(
                      `/viewProject/projectDetails/project/${record?.id}/${surveyType}`
                    );
                }}
              >
                View Project
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined className="text-2xl font-bold" />
        </Dropdown>
      ),
    },
  ];
  // eslint-disable-next-line
  const { data, loading, error } = useQuery(PROJECTLIST, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      // setLiveProjects(data?.live_projects || []);
      // setDraftProjects(data?.draft_projects || []);
      // setCompletedProjects(data?.completed_projects || []);
    },
  });

  useEffect(() => {
    let newObj = [];
    let obj2 = [];
    let obj3 = [];
    if (data) {
      let object = JSON.parse(JSON.stringify(data));
      object?.live_projects.forEach((item) => {
        let val =
          item?.survey_type !== null && item?.survey_type !== ""
            ? item?.survey_type === "Third_Party_survey"
              ? "- " + item?.third_party_survey_name
              : "- " + item?.survey_type
            : "";
        item.category = item?.projectTypeByProjectType?.name + " " + val;
        // const surveyType = category.replace(/ /g, "");

        item.clientName =
          item?.client?.l_name !== null
            ? item?.client?.f_name + " " + item?.client?.l_name
            : item?.client?.f_name;
        item.managerName =
          item?.manager_x_projects_aggregate?.aggregate?.count === 1 &&
            item?.manager_x_projects?.length === 0
            ? item?.leaveManager[0]?.user?.l_name !== null
              ? item?.leaveManager[0]?.user?.f_name +
              " " +
              item?.leaveManager[0]?.user?.l_name
              : item?.leaveManager[0]?.user?.f_name
            : item?.manager_x_projects[0]?.user?.l_name !== null
              ? item?.manager_x_projects[0]?.user?.f_name +
              " " +
              item?.manager_x_projects[0]?.user?.l_name
              : item?.manager_x_projects[0]?.user?.f_name;
        newObj.push(item);
      });
      object?.draft_projects.forEach((item) => {
        let val =
          item?.survey_type !== null && item?.survey_type !== ""
            ? item?.survey_type === "Third_Party_survey"
              ? "- " + item?.third_party_survey_name
              : "- " + item?.survey_type
            : "";
        item.category = item?.projectTypeByProjectType?.name + " " + val;
        // const surveyType = category.replace(/ /g, "");

        item.clientName =
          item?.client?.l_name !== null
            ? item?.client?.f_name + " " + item?.client?.l_name
            : item?.client?.f_name;
        item.managerName =
          item?.manager_x_projects[0]?.user?.l_name !== null
            ? item?.manager_x_projects[0]?.user?.f_name +
            " " +
            item?.manager_x_projects[0]?.user?.l_name
            : item?.manager_x_projects[0]?.user?.f_name;
        obj2.push(item);
      });
      object?.completed_projects.forEach((item) => {
        let val =
          item?.survey_type !== null && item?.survey_type !== ""
            ? item?.survey_type === "Third_Party_survey"
              ? "- " + item?.third_party_survey_name
              : "- " + item?.survey_type
            : "";
        item.category = item?.projectTypeByProjectType?.name + " " + val;
        // const surveyType = category.replace(/ /g, "");

        item.clientName =
          item?.client?.l_name !== null
            ? item?.client?.f_name + " " + item?.client?.l_name
            : item?.client?.f_name;
        item.managerName =
          item?.manager_x_projects[0]?.user?.l_name !== null
            ? item?.manager_x_projects[0]?.user?.f_name +
            " " +
            item?.manager_x_projects[0]?.user?.l_name
            : item?.manager_x_projects[0]?.user?.f_name;
        obj3.push(item);
      });
      setCompletedProjects(obj3);
      setDraftProjects(obj2);
      setLiveProjects(newObj);
    }
  }, [data]);

  if (error) {
    return <ErrorPage />;
  }
  const onTabChange = (e) => {
    history.push(`/projectList/${tabPane[e - 1]}`);
    setActiveTabPane(e);
  };


  return (
    <Spin spinning={loading}>
      <div className="projectList p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-textMedium text-h4 font-semibold">
            Projects
          </span>
          <div className=" flex items-center">

            <Button
              type="primary"
              size="large"
              className="float-right text-base rounded-md flex items-center"
              icon={<PlusOutlined />}
              onClick={() => history.push("/create_project/project_details")}
            >
              Create a new Project
            </Button>
            <div className=" ml-5 my-4 text-h5 text-textMedium font-semibold">
              <span>
                <ColumnEditor columns={columnStatus} onSave={(column) => {
                  setStatus(column)
                }} />
              </span>
            </div>
          </div>
        </div>

        <Tabs
          className="w-full bg-white"
          activeKey={activeTabPane}
          tabBarGutter="5"
          onChange={onTabChange}
        >
          <TabPane tab="Active" key="1">
            <Table
              columns={columns()}
              dataSource={liveProjects}
              className="shadow rounded"
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
          <TabPane tab="Drafts" key="2">
            <Table
              columns={columns()}
              dataSource={draft}
              className="shadow rounded"
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
          <TabPane tab="Completed" key="3">
            <Table
              columns={columns()}
              dataSource={completed}
              className="shadow rounded"
              scrollToFirstRowOnChange={true}
              pagination={
                loading
                  ? false
                  : {
                    showTotal: (total, range) => {
                      return (
                        <div>{`Showing ${range[0]} to ${range[1]} of ${total} Entries`}</div>
                      );
                    },
                  }
              }
            />
          </TabPane>
        </Tabs>
      </div>
    </Spin>
  );
}

export default SuperadminList;
