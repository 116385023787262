import { Breadcrumb, Button } from "antd";
import { useHistory, useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { useResetMemberDetails } from "../atoms";

function LocationHierarchy(props) {
  const { surveyID, projectID } = useParams();
  const history = useHistory();
  const resetMemberDetails = useResetMemberDetails();
  const location = useLocation();
  return (
    <div className="w-full h-10">
      <Breadcrumb style={{ margin: "16px 0" }} className="w-1/2 float-left">
        {location.pathname.split("/")[1] !== "viewProject" ? (
          <Breadcrumb.Item className="text-textLight">
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item className="text-textLight">
            <Link to="/projectList/active">Projects list</Link>
          </Breadcrumb.Item>
        )}
        {location.pathname.split("/")[1] !== "create_project" && (
          <Breadcrumb.Item className="text-textLight">
            ID#{props.id}
          </Breadcrumb.Item>
        )}
        {location.pathname.split("/")[1] === "create_project" && (
          <Breadcrumb.Item className="text-textLight">
            Create a new project
          </Breadcrumb.Item>
        )}
        {location.pathname.split("/")[3] === "edit-project" && (
          <Breadcrumb.Item className="text-textLight">
            Edit project
          </Breadcrumb.Item>
        )}
        {location.pathname.split("/")[2] !== "viewSurvey" &&
          location.pathname.split("/")[3] === "create-survey" && (
            <Breadcrumb.Item className="text-textLight">
              Create survey
            </Breadcrumb.Item>
          )}
        {location.pathname.split("/")[2] !== "viewSurvey" &&
          location.pathname.split("/")[3] === "edit-survey" &&
          props.status !== "completed" && (
            <Breadcrumb.Item className="text-textLight">
              Edit survey
            </Breadcrumb.Item>
          )}
      </Breadcrumb>
      <div>
        {location.pathname.split("/")[1] === "viewSurvey" &&
          surveyID === undefined && (
            <Button
              type="primary"
              htmlType="submit"
              className="float-right text-base w-40 rounded-md my-4"
              onClick={() => {
                resetMemberDetails();
                history.push(
                  `/project/${projectID}/create-survey/${props.projectType}/overview`
                );
              }}
            >
              Create Survey
            </Button>
          )}
        {location.pathname.split("/")[1] === "viewSurvey" &&
          surveyID !== undefined && (
            <>
              {props.completionCount === props.sampleSize && (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-right text-base w-40 rounded-md my-4 ml-4"
                  // onClick={() =>
                  //   history.push(
                  //     `/project/${projectID}/edit-survey/${props.projectType}/overview`
                  //   )
                  // }
                >
                  Close Survey
                </Button>
              )}
              {location.pathname.split("/")[8] !== "viewResponseAnswers" &&
                props.status !== "completed" && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="float-right text-base w-40 rounded-md my-4 "
                    onClick={() => {
                      resetMemberDetails();
                      history.push(
                        `/project/${projectID}/edit-survey/${props.projectType}/overview/survey/${surveyID}`
                      );
                    }}
                  >
                    Edit Survey
                  </Button>
                )}
            </>
          )}
        {location.pathname.split("/")[1] === "viewProject" && (
          <>
            {props.status === "completed" && (
              <Button
                htmlType="submit"
                className="float-right text-base w-40 rounded-md my-4 text-orange"
                onClick={() => {
                  resetMemberDetails();
                  history.push(
                    `/project/${projectID}/edit-project/project_details/re-opened`
                  );
                }}
              >
                Reopen Project
              </Button>
            )}
            {location.pathname.split("/")[8] !== "viewResponseAnswers" &&
              props.status !== "completed" && (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="float-right text-base w-40 rounded-md my-4 "
                  onClick={() => {
                    resetMemberDetails();
                    props.status === "re-opened"
                      ? history.push(
                          `/project/${projectID}/edit-project/project_details/re-opened`
                        )
                      : history.push(
                          `/project/${projectID}/edit-project/project_details`
                        );
                  }}
                >
                  Edit project
                </Button>
              )}
          </>
        )}
      </div>
    </div>
  );
}

export default LocationHierarchy;
