import React, { useState, useEffect, useMemo, useContext } from "react";
import { Button, Table, Form, Space, Input, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import _ from "lodash";
import "./selectMembers.css";
import { projectForm } from "../../../../atoms";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  GET_MEMBERS,
  GET_ASSIGNED_MEMBERS,
  SURVEYxMEMBERS,
  UPDATE_SURVEY_STATUS,
  DELETE_SURVEY_X_MEMBERS,
  PROJECT_DETAILS,
} from "../graphql";
import { useLocation } from "react-router-dom";
import ColumnEditor from "../../../../components/ColumnEditor";
import { RoleContext } from "../../../../context/RoleContext";
import {
  memberColumns,
  columnStatus as memberColumnStatus,
  roleBasedColumns,
  parseMemberData,
} from "../../../../components/memberColumns";
import MemberTable from "../../../../components/memberTable";
import ViewActiveButton from "../../../../components/viewActiveButton";
import SpecialityModalPop from "../../../../utils/specialitySearch";

function SelectMembers(props) {
  const { surveyID, projectID, surveyType } = useParams();
  const location = useLocation();
  const currentTime = moment().format();
  const surveyDetails = useRecoilValue(projectForm);
  const setValues = useSetRecoilState(projectForm);
  const memberListDetails = useRecoilValue(projectForm);
  const [tableData, setTableData] = useState([]);
  const [selectedKeyRow, setSelectedKeys] = useState([]);
  const [showError, setShowError] = useState(false);
  const history = useHistory();
  const [insertmembers] = useMutation(SURVEYxMEMBERS);
  const [deleteMembers] = useMutation(DELETE_SURVEY_X_MEMBERS);
  const [updateSurvey] = useMutation(UPDATE_SURVEY_STATUS);
  const savedprfAssign = localStorage.getItem('prfAssign');
  const prfAssigninitial = savedprfAssign
    ? JSON.parse(savedprfAssign)
    : {
      ...memberColumnStatus,
    }
  const [columnStatus, setStatus] = useState(prfAssigninitial);
  const [memberType, setMemberType] = useState([]);
  const { role } = useContext(RoleContext);
  const [isModalVisibleSpec, setIsModalVisibleSpec] = useState(false);
  const [selectedDataSpec, setSelectedDataSpec] = useState([]);
  const [selectedColumnNameSpec, setSelectedColumnNameSpec] = useState('');
  const [filteredDataSource, setFilteredDataSource] = useState(tableData);
  const [searchQuerySpec, setSearchQuerySpec] = useState('');
  const [initialSelectedDataSpec, setInitialSelectedDataSpec] = useState([]);

  let searchInput;

  const {
    data: assignedMembers,
    loading,
    refetch,
  } = useQuery(GET_ASSIGNED_MEMBERS, {
    variables: {
      survey_id: parseInt(surveyID),
    },
    fetchPolicy: "no-cache",
  });

  const { data: members } = useQuery(GET_MEMBERS, {
    fetchPolicy: "network-only",
  });

  const { data: { project } = {} } = useQuery(PROJECT_DETAILS, {
    variables: {
      id: parseInt(projectID),
    },
    fetchPolicy: "network-only",

    onCompleted: (res) => {
      let obj = {};
      let newarray = [];
      res.member_type.map((item) => {
        obj[item.id] = item.name;
      });
      res.project[0].member_types.map((type) => {
        newarray.push(obj[type]);
      });
      setMemberType(newarray);
    },
  });
  useEffect(() => {
    localStorage.setItem('prfAssign', JSON.stringify(columnStatus));
  }, [columnStatus]);

  useEffect(() => {
    if (members) {
      let object = parseMemberData(members);
      let newList = object.filter((item) => {
        return memberType.includes(item.category);
      });

      setTableData(newList);
    }
  }, [members, memberType]);

  useEffect(() => {
    let tempCol = { ...columnStatus };
    delete tempCol.status;
    setStatus(tempCol);
  }, []);

  useEffect(() => {
    refetch();
    let obj = {};
    if (!loading) {
      if (memberListDetails.memberListDetails) {
        setSelectedKeys(
          memberListDetails?.memberListDetails?.selectedRowKeysSTORE
        );
      } else if (
        assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0
      ) {
        // let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
        //   let id = el.user.id;
        //   let status = el.quota;
        //   obj[id] = { status };
        //   return el.user.id;
        // });

        let selectedArray = assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.id;
          let status = el.user.survey_x_members[0].state;
          obj[id] = { status };
          return el.user.id;
        });
        let object,
          newObject = [];

        object = JSON.parse(JSON.stringify(assignedMembers?.survey_x_member));
        object.forEach((item) => {
          item.user.genderNew =
            item?.user?.gender === null ? "Not updated" : item?.user?.gender;
          item.user.category = item.user.member_type.name;
          item.user.memberName =
            item?.user?.l_name !== null
              ? item?.user?.f_name + " " + item?.user?.l_name
              : item?.user?.f_name;
          item.user.quota = item?.quota || 0;
          item.user.completed = item?.completed || 0;
        });
        object.forEach((item) => {
          newObject.push(item.user);
        });
        setSelectedKeys(selectedArray);
        setValues((oldValues) => ({
          ...oldValues,
          memberListDetails: {
            selectedRowKeysSTORE: selectedArray,
            selectedRowsSTORE: newObject,
          },
          updateMembersDetails: {
            updateMembers: true,
            memberList: assignedMembers,
            status: obj,
          },
        }));
      }
    }
    // eslint-disable-next-line
  }, [memberListDetails, assignedMembers, location]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleClick = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      let quotaCopy = [];
      let completedCopy = [];
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          // eslint-disable-next-line
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      memberListDetails?.memberListDetails?.selectedRowsSTORE.forEach(
        (member, index) => {
          if (member?.quota) {
            quotaCopy[index] = member?.quota;
            completedCopy[index] = member?.completed;
          } else {
            quotaCopy[index] = 0;
            completedCopy[index] = 0;
          }
        }
      );
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          payload.push({
            member_id: key,
            state: "active",
            survey_id: parseInt(surveyID),
            quota: quotaCopy[index],
            completed: completedCopy[index],
          });
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: moment().isSameOrAfter(props.startDate)
                ? props.status === "draft"
                  ? "draft"
                  : "active"
                : props.status === "active"
                  ? "active"
                  : "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            // props.handleToggle(true);
            let editOrCreate = location.pathname.split("/")[3];

            history.push(
              `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`
            );
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            setValues((oldValues) => ({
              ...oldValues,
              updateMembersDetails: {
                updateMembers: true,
              },
            }));
            let editOrCreate = location.pathname.split("/")[3];

            history.push(
              `/project/${projectID}/${editOrCreate}/${surveyType}/assignmembers/survey/${surveyID}/schedule`
            );
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleDraft = () => {
    if (selectedKeyRow.length > 0) {
      let payload = [];
      let surveymemberID = [];
      // memberListDetails?.updateMembersDetails &&
      //   memberListDetails?.updateMembersDetails?.memberList?.survey_x_member?.forEach(
      //     (item, index) => {
      //       surveymemberID.push(item.user.survey_x_members[0].id);
      //     }
      //   );
      assignedMembers?.survey_x_member &&
        assignedMembers?.survey_x_member?.length > 0 &&
        // eslint-disable-next-line
        assignedMembers?.survey_x_member?.map((el) => {
          let id = el.user.survey_x_members[0].id;
          surveymemberID.push(id);
        });
      memberListDetails?.memberListDetails?.selectedRowKeysSTORE?.forEach(
        (key, index) => {
          payload.push({
            member_id: key,
            state: "active",
            survey_id: parseInt(surveyID),
          });
        }
      );
      if (memberListDetails?.updateMembersDetails?.updateMembers) {
        deleteMembers({
          variables: {
            array: surveymemberID,
            object: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push(`/projectList/active`);
          });
        });
      } else {
        insertmembers({
          variables: {
            objects: payload,
          },
        }).then(() => {
          updateSurvey({
            variables: {
              id: parseInt(surveyID),
              status: "draft",
            },
          }).then(() => {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push(`/projectList/active`);
          });
        });
      }
    } else {
      setShowError(true);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });


  const handleIconClick = (columnName) => {
    setSelectedColumnNameSpec(columnName);
    setInitialSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setSelectedDataSpec(filteredDataSource.map((item) => item[columnName]));
    setIsModalVisibleSpec(true);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
    setTableData(tableData);
  };

  const handleModalCloseSpec = () => {
    setIsModalVisibleSpec(false);
  };

  const handleModalOkSpec = () => {
    const filteredData = tableData?.filter((item) =>
      selectedDataSpec.includes(item[selectedColumnNameSpec])
    );
    setFilteredDataSource(filteredData);
    setTableData(filteredData);
    setIsModalVisibleSpec(false);
  };

  const handleSearchSpec = (event) => {
    setSearchQuerySpec(event.target.value);
  };

  const handleCheckboxChangeSpec = (checkedValues) => {
    setSelectedDataSpec(checkedValues);
  };

  const handleResetSpec = () => {
    setSelectedDataSpec(initialSelectedDataSpec);
    setSearchQuerySpec('');
    setFilteredDataSource(tableData);
  };

  const filteredCheckboxDataSpec = Array.from(new Set(tableData?.map((item) => item[selectedColumnNameSpec])))
    .filter((data) => data?.toLowerCase().includes(searchQuerySpec.toLowerCase()));

  const isSubmitDisabledSpec = selectedDataSpec.length === 0;

  const memColumns = useMemo(() => {
    return [
      ...memberColumns(columnStatus, handleIconClick),
      ...roleBasedColumns(role, columnStatus),
    ].filter((data) => data.isShow === undefined || data.isShow);
  }, [role, columnStatus]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const object = assignedMembers?.survey_x_member;
      const rowCopy = selectedRows.map(row => {
        // console.log(row,"row selection")
        const foundItem = object.find(item => item?.user?.id === row?.id);
        if (foundItem) {
          return {
            ...row,
            quota: foundItem.quota,
            completed: foundItem.completed
          };
        }
        return row;
      });

      setSelectedKeys(selectedRowKeys);
      setValues(oldValues => ({
        ...oldValues,
        memberListDetails: {
          selectedRowKeysSTORE: selectedRowKeys,
          selectedRowsSTORE: rowCopy,
        },
      }));
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
    selectedRowKeys: selectedKeyRow,
  };
  return (
    <div className="selectMembers px-10 py-4 w-full">
      <div className="my-4 text-h5 text-textMedium flex justify-between ">
        Select members
        <div className=" flex">
          {!!selectedKeyRow?.length && (
            <ViewActiveButton memberId={selectedKeyRow} />
          )}

          <ColumnEditor
            columns={columnStatus}
            onSave={(column) => {
              setStatus(column);
            }}
          />
        </div>
      </div>
      <Form>
        <MemberTable
          columns={memColumns}
          tableData={tableData}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={false}
        ></MemberTable>

        <SpecialityModalPop
          titleSpec={`Selected ${selectedColumnNameSpec}`}
          visibleSpec={isModalVisibleSpec}
          onOkSpec={handleModalOkSpec}
          onCancelSpec={handleModalCloseSpec}
          searchQuerySpec={searchQuerySpec}
          handleSearchSpec={handleSearchSpec}
          handleCheckboxChangeSpec={handleCheckboxChangeSpec}
          filteredCheckboxDataSpec={filteredCheckboxDataSpec}
          selectedDataSpec={selectedDataSpec}
          initialSelectedDataSpec={initialSelectedDataSpec}
          isSubmitDisabledSpec={isSubmitDisabledSpec}
          handleResetSpec={handleResetSpec}
        />
        <Form.Item className="float-right mt-20">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => {
              const surveyStartDate =
                surveyDetails.surveyDetails &&
                surveyDetails.surveyDetails.start_date;
              if (moment(surveyStartDate).isSameOrAfter(currentTime)) {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}`
                  )
                  : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}`
                  );
              } else {
                location.pathname.split("/")[3] === "create-survey"
                  ? history.push(
                    `/project/${projectID}/create-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                  )
                  : history.push(
                    `/project/${projectID}/edit-survey/${surveyType}/questionnaire/survey/${surveyID}/preview`
                  );
              }
            }}
          >
            Back
          </Button>
          <Button
            htmlType="button"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              moment().isSameOrAfter(props.startDate) &&
                props.status !== "draft"
                ? true
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-md w-28"
            onClick={handleClick}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
      {showError ? (
        <p style={{ color: "red" }}>Please select atleast one member</p>
      ) : null}
    </div>
  );
}
export default SelectMembers;
