import { gql } from "graphql-tag";

export const GET_APPROVALS = gql`
  query GET_APPROVALS($roles: [Int]) {
    approval(where: { updated_by_user: { role: { id: { _in: $roles } } } } order_by: { updated_at: desc }) {
      id
      old_info
      status
      updated_info
      updated_by_user {
        f_name
        l_name
        id
      }
      updated_at  
      approved_at
      userApprovedBy {
        f_name
        id
        l_name
        role {
          id
          name
        }
      }    
      userRejectedBy {
        f_name
        id
        l_name
        role {
          id
          name
        }
      }      
      rejected_at
      rejected_by
      user {
        id
        f_name
        l_name
        role {
          description
        }
      }
    }
  }
`;

export const UPDATE_APPROVALS = gql`
  mutation UPDATE_APPROVALS($status: String!, $id: [Int!], $approvedBy_id: Int!, $approved_at: timestamptz!) {
    approvals(approval_id: $id, status: $status,, approvedBy_id: $approvedBy_id, approved_at: $approved_at) {
      message
    }
  }
`;
export const REJECTED_APPROVALS = gql`
mutation REJECTED_APPROVALS($status: String!, $id: [Int!], $rejected_by_id: Int!, $rejected_at: timestamptz!) {
  approvals(approval_id: $id, status: $status,, rejected_by_id: $rejected_by_id, rejected_at: $rejected_at) {
    message
  }
}
`;

export const GET_COUNTRY = gql`
  query GET_COUNTRY {
    country {
      id
      name
    }
  }
`;

export const GET_SPECIALITIES = gql`
  query GET_SPECIALITIES {
    specialties {
      id
      speciality_name
    }
  }
`;

export const GET_COMPATIBILITY = gql`
  query GET_COMPATIBILITY {
    project_compatibility {
      id
      project_compatibility_text
    }
  }
`;

export const GET_BILLING_CYCLES = gql`
  query GET_BILLING_CYCLES {
    billing_cycle {
      id
      values
    }
  }
`;

export const GET_TYPES = gql`
  query GET_TYPES {
    client_category {
      id
      name
    }
  }
`;
export const EDIT_USERS = gql`
mutation EDIT_USERS($object: Boolean!, $id: [Int!]) {
  update_user(where: {id: {_in: $id}}, _set: {ass_interviewer_status: $object}) {
    returning {
      id
      respondent_id
      ass_interviewer_id
      ass_interviewer_status
    }
  }
}
`;
