import { gql } from "@apollo/client";

export const CREATE_SURVEY = gql`
  mutation createSurvey($objects: [survey_insert_input!]!) {
    insert_survey(objects: $objects) {
      affected_rows
      returning {
        id
        description
        name
        consent
        status
      }
    }
  }
`;
export const GET_MEMBERS = gql`
  query GetMembers {
    user(where: { role_id: { _eq: 6 },is_verified: {_eq: true}, status: { _nin: ["raw", "Prohibited"] } }, order_by: { response_rate: desc }) {
      title
      status
      respondent_id
      f_name
      l_name
      id
      gender
      response_rate
      member_type {
        name
      }
      is_verified
      city
      additional_data
      meta_date
      email
      phone_number
      primary_specialty
      secondary_specialty
      secondary_specialties {
        speciality_name
      }
      primary_specialties {
        speciality_name
      }
      super_specialty {
        id
        name
      }
      member_type_id
      year_of_birth
      state
      pincode
      project_compatibilitys
    }
  }
`;

export const GET_ASSIGNED_MEMBERS = gql`
  query GET_ASSIGNED_MEMBERS($survey_id: Int) {
    survey_x_member(where: { survey_id: { _eq: $survey_id } }) {
      user {
        title
        status
        respondent_id
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
        survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
          state
          id
        }
      }
      quota
      completed
    }
    survey(where: { id: { _eq: $survey_id } }) {
      display_questions_on_survey_cards
      end_date
      start_date
    }
  }
`;

export const DELETE_SURVEY_X_MEMBERS = gql`
  mutation DELETE_SURVEY_X_MEMBERS(
    $array: [Int!]
    $object: [survey_x_member_insert_input!]!
  ) {
    delete_survey_x_member(where: { id: { _in: $array } }) {
      affected_rows
    }
    insert_survey_x_member(objects: $object) {
      affected_rows
    }
  }
`;
export const SURVEYxMEMBERS = gql`
  mutation ($objects: [survey_x_member_insert_input!]!) {
    insert_survey_x_member(objects: $objects) {
      affected_rows
    }
  }
`;
export const GET_SURVEY_DETAILS = gql`
  query getSurvey {
    survey {
      name
      description
      id
      consent
      start_date
      end_date
      status
      display_questions_on_survey_cards
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UPDATE_SURVEY($id: Int, $object: survey_set_input) {
    update_survey(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        description
        name
        id
        consent
      }
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_DETAILS = gql`
  mutation UPDATE_SURVEY(
    $id: Int
    $startDate: timestamptz
    $endDate: timestamptz
    $show: Boolean
    $status: String
  ) {
    update_survey(
      where: { id: { _eq: $id } }
      _set: {
        start_date: $startDate
        end_date: $endDate
        display_questions_on_survey_cards: $show
        status: $status
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_STATUS = gql`
  mutation UPDATE_SURVEY($id: Int, $status: String) {
    update_survey(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const PROJECT_DATES = gql`
  query PROJECT_DATES($id: Int) {
    project(where: { id: { _eq: $id } }) {
      end_date
      start_date
    }
  }
`;

export const PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($id: Int) {
    project(where: { id: { _eq: $id } }) {
      id
      is_online_or_assisted
      member_types
    }
    member_type {
      id
      name
    }
  }
`;
