import { gql } from "@apollo/client";

export const CREATE_SURVEY = gql`
  mutation createSurvey($objects: [survey_insert_input!]!) {
    insert_survey(objects: $objects) {
      affected_rows
      returning {
        id
        description
        name
        consent
        status
      }
    }
  }
`;
export const GET_MEMBERS = gql`
  query GetMembers($survey_id: Int) {
    user(where: { role_id: { _eq: 6 },is_verified: {_eq: true},
          status: { _nin: ["raw", "Prohibited"] } }, order_by: { response_rate: desc }) {
      title
      status
      respondent_id
      f_name
      l_name
      id
      gender
      email
      response_rate
      phone_number
      primary_specialty
      secondary_specialty
      primary_specialties {
        speciality_name
        id
      }
      secondary_specialties {
        speciality_name
      }
      super_specialty {
        id
        name
      }
      is_active
      member_type_id
      year_of_birth
      gender
      additional_data
      meta_date
      is_verified
      state
      city
      pincode
      project_compatibilitys
      member_type {
        name
      }
      survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
        quota
        state
        survey_url
        survey {
          links_status
        }
        survey_x_member_x_interviewers {
          id
          interviewer_id
          interviewer_on_leave
        }
        active: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: false } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }
        leave: survey_x_member_x_interviewers(
          order_by: { priority: asc }
          limit: 1
          where: { interviewer_on_leave: { _eq: true } }
        ) {
          interviewer_id
          interviewer {
            f_name
            l_name
          }
        }

        survey_x_member_x_interviewers_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export const GET_ASSIGNED_MEMBERS = gql`
  query GET_ASSIGNED_MEMBERS($survey_id: Int) {
    survey_x_member(where: { survey_id: { _eq: $survey_id }, user: {} }) {
      user {
        title
        respondent_id
        status
        f_name
        l_name
        id
        gender
        member_type {
          name
        }
        is_verified
        city
        additional_data
        meta_date
        email
        phone_number
        primary_specialty
        secondary_specialty
        secondary_specialties {
          speciality_name
        }
        primary_specialties {
          speciality_name
        }
        member_type_id
        year_of_birth
        state
        pincode
        project_compatibilitys
        survey_x_members(where: { survey_id: { _eq: $survey_id } }) {
          id
          state
        }
      }
      survey_x_member_x_interviewers {
        id
        priority
        interviewer_id
        status
        interviewer_on_leave
      }
    }
    survey(where: { id: { _eq: $survey_id } }) {
      display_questions_on_survey_cards
      end_date
      start_date
    }
  }
`;

export const DELETE_SURVEY_X_MEMBERS = gql`
  mutation DELETE_SURVEY_X_MEMBERS(
    $array: [Int!]
    $Interviewerarray: [Int!]
    $object: [survey_x_member_insert_input!]!
  ) {
    delete_survey_x_member_x_interviewer(
      where: { id: { _in: $Interviewerarray } }
    ) {
      affected_rows
    }
    delete_survey_x_member(where: { id: { _in: $array } }) {
      affected_rows
    }

    insert_survey_x_member(objects: $object) {
      affected_rows
      returning {
        id
        state
      }
    }
  }
`;
export const SURVEYxMEMBERS = gql`
  mutation ($objects: [survey_x_member_insert_input!]!) {
    insert_survey_x_member(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export const INSERT_INTERVIEWER = gql`
  mutation ($objects: [survey_x_member_x_interviewer_insert_input!]!) {
    insert_survey_x_member_x_interviewer(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_SURVEY_X_MEMBER_X_INTERVIEWER = gql`
  mutation DELETE_SURVEY_X_MEMBER_X_INTERVIEWER(
    $array: [Int!]
    $object: [survey_x_member_x_interviewer_insert_input!]!
  ) {
    delete_survey_x_member_x_interviewer(where: { id: { _in: $array } }) {
      affected_rows
    }
    insert_survey_x_member_x_interviewer(objects: $object) {
      affected_rows
    }
  }
`;

export const GET_SURVEY_DETAILS = gql`
  query getSurvey {
    survey {
      name
      description
      id
      consent
      start_date
      end_date
      display_questions_on_survey_cards
    }
  }
`;

export const UPDATE_SURVEY = gql`
  mutation UPDATE_SURVEY($id: Int, $object: survey_set_input) {
    update_survey(where: { id: { _eq: $id } }, _set: $object) {
      returning {
        description
        name
        id
        consent
      }
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_DETAILS = gql`
  mutation UPDATE_SURVEY(
    $id: Int
    $startDate: timestamptz
    $endDate: timestamptz
    $show: Boolean
    $status: String
  ) {
    update_survey(
      where: { id: { _eq: $id } }
      _set: {
        start_date: $startDate
        end_date: $endDate
        display_questions_on_survey_cards: $show
        status: $status
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_SURVEY_STATUS = gql`
  mutation UPDATE_SURVEY($id: Int, $status: String) {
    update_survey(where: { id: { _eq: $id } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

export const GET_INTERVIEWER = gql`
  query GET_INTERVIEWER {
    user(where: { role_id: { _eq: 4 } }) {
      f_name
      l_name
      id
      meta_date
    }
  }
`;

export const PROJECT_DATES = gql`
  query PROJECT_DATES($id: Int) {
    project(where: { id: { _eq: $id } }) {
      end_date
      start_date
    }
  }
`;

export const PROJECT_DETAILS = gql`
  query PROJECT_DETAILS($id: Int) {
    project(where: { id: { _eq: $id } }) {
      id
      is_online_or_assisted
      member_types
    }
    member_type {
      id
      name
    }
  }
`;
