import React, { useState, useEffect } from "react";
import { Form, Select, Button, Divider, List, notification } from "antd";
import { useHistory, useParams } from "react-router";
import { MinusCircleOutlined } from "@ant-design/icons";
import { useQuery, useMutation } from "@apollo/client";
import { GET_MANAGER } from "../graphql";
import {
  GET_PROJECT_DETAILS,
  UPDATE_MANAGER,
  UPDATE_PROJECT,
  MANAGERxPROJECT,
  UPDATE_SURVEY_STATUS_IN_PROJECT,
  GET_SURVEY_STATUS,
} from "../graphql";
import { useLocation } from "react-router-dom";
import moment from "moment";
import jwtDecode from "jwt-decode";

const { Option } = Select;

function AssignUsers(props) {
  const history = useHistory();
  const location = useLocation();
  const { projectID } = useParams();
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [leavearray, setLeavearray] = useState([]);
  // eslint-disable-next-line
  const [id, setId] = useState(
    jwtDecode(localStorage.getItem("token"))["https://hasura.io/jwt/claims"][
      "x-hasura-user-id"
    ]
  );
  const { data: managerData } = useQuery(GET_MANAGER, {
    fetchPolicy: "network-only",
  });

  const { data: assignedUsers } = useQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      project_id: projectID ? parseInt(projectID) : 0,
    },
  });

  const [updateSurveyStatus] = useMutation(UPDATE_SURVEY_STATUS_IN_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [updateManager] = useMutation(UPDATE_MANAGER);
  const [managerxproject] = useMutation(MANAGERxPROJECT);
  const { data: surveyStatus } = useQuery(GET_SURVEY_STATUS, {
    fetchPolicy: "network-only",
    variables: {
      projectID: projectID ? parseInt(projectID) : 0,
    },
  });
  const [managerList, setManagerList] = useState([]);
  const [leaveList, setLeaveList] = useState([]);
  const [copy, setCopy] = useState([]);

  const handleChange = (e) => {
    let newList = [];
    let leaveList = {};
    managerData?.user?.forEach((manager) => {
      e.forEach((el) => {
        if (manager.id === el) {
          newList.push(manager);
          leaveList = {
            ...leaveList,
            [manager.id]:
              manager.meta_date === null
                ? "Available"
                : manager.meta_date.availability,
          };
        }
      });
    });
    setCopy(newList);
    setManagerList(e);
    setLeavearray(leaveList);
  };

  useEffect(() => {
    let managerArray = [];
    let listCopy = [];
    let obj = {};
    if (assignedUsers && assignedUsers?.project.length !== 0) {
      assignedUsers.project[0]?.manager_x_projects
        // eslint-disable-next-line
        .map((manager) => {
          let status = manager?.manager_on_leave;
          listCopy.push(manager.user);
          managerArray.push(manager.user.id);
          obj[manager.user.id] = { status };
        });
      setManagerList(managerArray);
      setLeaveList(obj);
      setCopy(listCopy);
    }
  }, [assignedUsers]);

  const handleFormValues = (buttonAction) => {
    if (managerList.length !== 0) {
      if (
        assignedUsers &&
        assignedUsers.project[0]?.manager_x_projects?.length !== 0
      ) {
        let managers = managerList;
        const payload = {
          status:
            buttonAction === "draft"
              ? "draft"
              : moment().isSameOrAfter(props.startDate)
              ? assignedUsers?.project[0]?.status === "draft"
                ? "draft"
                : "active"
              : assignedUsers?.project[0]?.status === "active"
              ? "active"
              : "draft",
        };
        let payloadObject = [];
        let object = {};
        managers.forEach((manager, index) => {
          object.manager_id = manager;
          object.project_id = parseInt(projectID);
          object.priority = index + 1;
          object.status = "active";
          object.manager_on_leave =
            leaveList[manager] === undefined
              ? false
              : leaveList[manager].status;
          payloadObject[index] = object;
          object = {};
        });
        updateManager({
          variables: {
            object: payloadObject,
            id: parseInt(projectID),
          },
        }).then(() => {
          updateProject({
            variables: {
              object: payload,
              id: parseInt(projectID),
            },
          }).then(() => {
            if (surveyStatus.survey.length === 1) {
              updateSurveyStatus({
                variables: {
                  id: surveyStatus.survey[0].id,
                  status:
                    buttonAction === "draft"
                      ? "draft"
                      : surveyStatus.survey[0].status,
                },
              });
            }
            if (buttonAction === "active") {
              location.pathname.split("/")[1] === "create_project" &&
                history.push(`/create_project/${projectID}/financial_details`);
              location.pathname.split("/")[3] === "edit-project" &&
                history.push(
                  `/project/${projectID}/edit-project/financial_details`
                );
              location.pathname.split("/")[3] === "re-opened" &&
                history.push(
                  `/project/${projectID}/edit-project/financial_details/re-opened`
                );
            } else {
              notification.success({
                message: "Success!",
                description: "Saved as draft!",
              });
              history.push("/projectList/active");
            }
          });
        });
      } else {
        let managers = managerList;
        const payload = {
          status: "draft",
        };
        let payloadObject = [];
        let object = {};
        managers.forEach((manager, index) => {
          object.manager_id = manager;
          object.project_id = parseInt(projectID);
          object.priority = index + 1;
          object.status = "active";
          object.manager_on_leave =
            leavearray[manager] === "Available" ? false : true;
          payloadObject[index] = object;
          object = {};
        });
        managerxproject({
          variables: {
            objects: payloadObject,
          },
        }).then(() => {
          updateProject({
            variables: {
              object: payload,
              id: parseInt(projectID),
            },
          }).then(() => {
            if (surveyStatus.survey.length === 1) {
              updateSurveyStatus({
                variables: {
                  id: surveyStatus.survey[0].id,
                  status:
                    buttonAction === "draft"
                      ? "draft"
                      : surveyStatus.survey[0].status,
                },
              });
            }
            if (buttonAction === "active") {
              props.handleEnable(true);
              location.pathname.split("/")[1] === "create_project" &&
                history.push(`/create_project/${projectID}/financial_details`);
              location.pathname.split("/")[3] === "edit-project" &&
                history.push(
                  `/project/${projectID}/edit-project/financial_details`
                );
              location.pathname.split("/")[5] === "re-opened" &&
                history.push(
                  `/project/${projectID}/edit-project/financial_details/re-opened`
                );
            } else {
              props.handleEnable(true);
              history.push("/projectList/active");
            }
          });
        });
      }
    } else {
      setErrorMessage("Please select atleast one manager");
    }
  };

  const addUser = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };

  const removeItem = (value) => {
    const newList = managerList.filter((item) => item !== value);
    const copyList = copy.filter((item) => item.id !== value);
    setCopy(copyList);
    setManagerList(newList);
  };

  return (
    <div className=" py-4 w-full">
      <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div>
      <Form
        name="assignUsers_form"
        layout="vertical"
        form={form}
        preserve={true}
      >
        <Form.Item
          label={<span className="text-base text-textLight">Add Manager</span>}
          name="AddManager"
        >
          <Select
            placeholder="Select"
            mode="multiple"
            showArrow
            style={{ width: "49%" }}
            onChange={(e) => handleChange(e)}
            value={managerList}
            showSearch
            optionFilterProp="children"
          >
            {managerData?.user?.map((manager) => {
              return (
                <Option key={manager.id} value={manager.id}>
                  {manager?.f_name} {manager?.l_name}
                </Option>
              );
            })}
          </Select>
          <List
            size="large"
            bordered
            dataSource={copy}
            pagination={false}
            renderItem={(item) => (
              <List.Item
                extra={
                  <MinusCircleOutlined
                    style={{ color: "red" }}
                    onClick={() => removeItem(item.id)}
                  />
                }
              >
                {item.f_name + " " + item.l_name}
              </List.Item>
            )}
            style={{ minHeight: "450px", width: "49%", border: 0 }}
          />
        </Form.Item>
        <Divider />
        <Form.Item className="float-right">
          <Button
            type="link"
            className="text-base"
            htmlType="button"
            onClick={() => {
              location.pathname.split("/")[1] === "create_project" &&
                history.push(`/create_project/${projectID}/project_details`);
              location.pathname.split("/")[3] === "edit-project" &&
                history.push(
                  `/project/${projectID}/edit-project/project_details`
                );
              location.pathname.split("/")[5] === "re-opened" &&
                history.push(
                  `/project/${projectID}/edit-project/project_details/re-opened`
                );
            }}
          >
            Back
          </Button>
          <Button
            htmlType="submit"
            className="text-base mx-5 rounded-md text-orange"
            onClick={handleDraft}
            disabled={
              location.pathname.split("/")[1] !== "create_project"
                ? moment().isSameOrAfter(props.startDate) &&
                  assignedUsers?.project[0]?.status === "active"
                  ? true
                  : false
                : false
            }
          >
            Save as draft
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="text-base rounded-sm w-28"
            onClick={addUser}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AssignUsers;
