import React, { useEffect, useState } from "react";
import {
  Col,
  Layout,
  Row,
  Form,
  Card,
  Upload,
  Divider,
  Button,
  message,
  Spin,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useParams, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_SURVEY,
  GET_UPLOAD_URL,
  INSERT_ANSWERS,
  UPDATE_STATUS,
  DELETE_RESPONSES,
} from "./graphql";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const { Dragger } = Upload;

const { Content } = Layout;
const AttendSurvey = () => {
  const { surveyID, memberID, interviewerID, id } = useParams();
  const [questionData, setQuestionData] = useState(null);
  const [form] = Form.useForm();
  const { data, loading, error } = useQuery(GET_SURVEY, {
    variables: {
      id: parseInt(surveyID),
      member_id: parseInt(memberID),
      interviewer_id: parseInt(interviewerID),
    },
    fetchPolicy: "network-only",
  });
  const [uploadURL] = useMutation(GET_UPLOAD_URL);
  const [insertAnswers] = useMutation(INSERT_ANSWERS);
  const [deleteResponses] = useMutation(DELETE_RESPONSES);
  const [updateStatus] = useMutation(UPDATE_STATUS);
  useEffect(() => {
    if (data) {
      let copy = JSON.parse(JSON.stringify(data));
      copy?.survey_by_pk?.questions?.forEach((question) => {
        question.fileSize =
          question?.questions_options[0]?.options?.fileUploadValue?.fileSize ||
          null;
        let fileTypesAllowed = "";
        question?.questions_options[0]?.options?.fileUploadValue?.fileTypesAllowed.forEach(
          (type) => {
            const array = type.split("/");
            array.forEach((item) => {
              if (item === "video") {
                fileTypesAllowed = fileTypesAllowed + ".mp4,.3gp,";
              } else if (item === "audio") {
                fileTypesAllowed = fileTypesAllowed + ".mp3,.wav,";
              } else {
                fileTypesAllowed = fileTypesAllowed + `.${item},`;
              }
              question.fileTypesAllowed = fileTypesAllowed;
            });
          }
        );
      });
      setQuestionData(copy);
    }
  }, [data]);
  const history = useHistory();
  const onFinish = (values) => {
    let payload = [],
      payloadObject = {},
      value;
    for (value in values) {
      payloadObject.question_id = parseInt(value);
      payloadObject.answer = values[value].file.name;
      const fileURL = values[value].file.xhr.responseURL.split("?");
      payloadObject.file_url = fileURL[0];
      payloadObject.member_id = parseInt(memberID);
      payloadObject.interviewer_id = parseInt(interviewerID);
      payload.push(payloadObject);
      payloadObject = {};
    }

    if (questionData?.survey_by_pk?.questions[0]?.answers.length > 1) {
      let question_id = [];
      payload.forEach((obj) => {
        question_id.push(obj.question_id);
      });

      deleteResponses({
        variables: {
          id: question_id,
        },
      });
    }
    insertAnswers({
      variables: {
        objects: payload,
      },
    }).then(() => {
      updateStatus({
        variables: {
          member_id: parseInt(memberID),
          interviewer_id: parseInt(interviewerID),
          status: "Completed",
          appointment_date: moment(),
          survey_id: parseInt(surveyID),
        },
      }).then(() => {
        history.push(`/project/${id}/members`);
      });
    });
  };
  if (loading) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div style={{ textAlign: "center" }}>
        <Spin indicator={antIcon} style={{ textAlign: "center" }} />
      </div>
    );
  }
  if (error) {
    return <p>Error fetching data {error}</p>;
  }
  return (
    <Layout className="w-full">
      <Content className="px-8 py-10 h-screen">
        <Row justify="space-between">
          <Col>
            <p className="text-lg">{questionData?.survey_by_pk?.name}</p>
          </Col>
          <Col>
            <p>
              Interview for :{" "}
              {questionData?.survey_by_pk?.survey_x_members[0]?.user?.f_name}{" "}
              {questionData?.survey_by_pk?.survey_x_members[0]?.user?.l_name}
            </p>
          </Col>
        </Row>
        <Form
          name="questionnaire"
          layout={"vertical"}
          requiredMark={false}
          onFinish={onFinish}
          form={form}
        >
          {questionData?.survey_by_pk?.questions?.map((question, index) => {
            return (
              <Card className="mb-2" key={index}>
                <Form.Item
                  label={`Q${index + 1} ${question.question}`}
                  name={question.id}
                  rules={[{ required: true, message: "Please upload a file!" }]}
                >
                  <Dragger
                    name="file"
                    multiple={false}
                    method="PUT"
                    action={(file) => {
                      return new Promise((resolve) => {
                        uploadURL({
                          variables: {
                            fileName: file.name,
                            contentType: file.type,
                          },
                        }).then((res) => {
                          if (res.data.get_upload_url.message === "Success") {
                            resolve(res.data.get_upload_url.url);
                          }
                        });
                      });
                    }}
                    onChange={(info) => {
                      const { status } = info.file;

                      if (status === "done") {
                        message.success(
                          `${info.file.name} file uploaded successfully.`
                        );
                      } else if (status === "error") {
                        message.error(`${info.file.name} file upload failed.`);
                      }
                    }}
                    accept={question.fileTypesAllowed}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      <span className="text-primary">Upload a file</span> or
                      drag and drop
                    </p>
                    <p className="ant-upload-hint">
                      Allowed file types {question.fileTypesAllowed}
                    </p>
                  </Dragger>
                </Form.Item>
              </Card>
            );
          })}
          <Divider />
          <Row
            justify="end"
            style={{
              position: "fixed",
              width: "95.5%",
              bottom: 0,
              marginBottom: "25px",
            }}
          >
            <Button
              size="large"
              type="secondary"
              htmlType="button"
              className="rounded-md mr-5 px-10"
              style={{ color: "#E08036" }}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              className="rounded-md"
            >
              Send for approval
            </Button>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
};

export default AttendSurvey;
