import React, { useState, useEffect } from "react";
import { Button, Table, Form, Space, Input, Divider, notification } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import _ from "lodash";
// import { formatDateTime } from "../../../../../utils/dateTime";
import moment from "moment";
import {
  GET_MEMBERS_LIST,
  UPDATE_LINK_STATUS,
  GET_LINK_STATUS,
} from "../graphql";
// import { projectForm, useResetMemberDetails } from "../../../../../atoms";
// import { useRecoilValue } from "recoil";
import { useQuery, useMutation } from "@apollo/client";
import { useHistory, useParams } from "react-router";

function MemberList(props) {
  const baseURL = process.env.REACT_APP_ONLINE_URL;
  const { surveyID, surveyType, projectID } = useParams();
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  let searchInput;
  const history = useHistory();

  const { data: members } = useQuery(GET_MEMBERS_LIST, {
    fetchPolicy: "network-only",
    variables: { id: parseInt(surveyID) },
  });

  const { data: link, refetch } = useQuery(GET_LINK_STATUS, {
    fetchPolicy: "network-only",
    variables: { survey_id: parseInt(surveyID) },
  });

  const [updateSurvey] = useMutation(UPDATE_LINK_STATUS);

  useEffect(() => {
    refetch();
    if (members) {
      let object = JSON.parse(JSON.stringify(members?.user));
      object.forEach((item) => {
        item.category = item.member_type.name;
        item.memberName =
          item?.l_name !== null
            ? item?.f_name + " " + item?.l_name
            : item?.f_name;
        item.kreaLink = `${baseURL}/survey/${surveyID}/member/${item.id}`;
        item.clientLink = item.survey_x_members[0].survey_url;
      });
      setTableData(object);
    }
    // eslint-disable-next-line
  }, [members]);

  const handleClick = () => {
    let editOrCreate = location.pathname.split("/")[3];
    history.push(
      `/project/${projectID}/${editOrCreate}/${surveyType}/generate-links/survey/${surveyID}`
    );
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const handleDraft = () => {
    updateSurvey({
      variables: {
        id: parseInt(surveyID),
        status: "draft",
        links_status: link.survey_by_pk.links_status,
      },
    }).then(() => {
      notification.success({
        message: "Success!",
        description: "Saved as draft!",
      });
      history.push("/projectList/active");
    });
  };

  const handleNext = () => {
    updateSurvey({
      variables: {
        id: parseInt(surveyID),
        status: moment().isSameOrAfter(props.startDate)
          ? props.status === "draft"
            ? "draft"
            : "active"
          : props.status === "active"
            ? "active"
            : "draft",
        links_status: link.survey_by_pk.links_status,
      },
    }).then(() => {
      let editOrCreate = location.pathname.split("/")[3];
      history.push(
        `/project/${projectID}/${editOrCreate}/${surveyType}/assign-interviewers/survey/${surveyID}`
      );
    });
  };

  const handleEditList = () => {
    const surveyMode = location.pathname.split("/")[3];
    if (surveyMode === "edit-survey") {
      history.push(
        `/project/${projectID}/edit-survey/${surveyType}/assignmembers/survey/${surveyID}`
      );
      props.handleToggle(false);
    } else {
      history.push(
        `/project/${projectID}/create-survey/${surveyType}/assignmembers/survey/${surveyID}`
      );
      props.handleToggle(false);
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined style={{ color: "#828282 !important" }} />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#D14660" : undefined }} />
    ),
    onFilter: (value, record) => {
      let getRecord = _.get(record, dataIndex);
      return getRecord
        ? getRecord.toString().toLowerCase().includes(value.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "memberName",
      className: "text-textDark",
      ...getColumnSearchProps("memberName"),
    },
    {
      title: "CLIENT LINK",
      dataIndex: "clientLink",
      ...getColumnSearchProps("clientLink"),
    },
    {
      title: "KREA SURVEY LINK",
      dataIndex: "kreaLink",
      ...getColumnSearchProps("kreaLink"),
    },
  ];

  // function disabledDate(current) {
  //   return current && current < moment().endOf("day");
  // }

  // function disabledDuebyDate(current) {
  //   return current && current <= startDate;
  // }

  return (
    <>
      {/* <div style={{ color: "red" }}>
        <p>{errorMessage}</p>
      </div> */}
      <Form>
        <div className="selectMembers px-10 py-4 w-full">
          <p className="text-textMedium text-lg float-left w-1/2">
            Survey links generated
          </p>
          <Form.Item className="float-right">
            <Button
              className="text-orange text-base"
              size="middle"
              type="link"
              onClick={handleClick}
            >
              Add URL
            </Button>
            <Divider type="vertical" />
            <Button
              className="text-orange text-base"
              size="middle"
              type="link"
              onClick={handleEditList}
            >
              Edit member list
            </Button>
          </Form.Item>
          <Form.Item className="block" name="members">
            <Table
              columns={columns}
              dataSource={tableData}
              rowClassName={(record, index) => "text-sm text-textMedium"}
              pagination={false}
              rowKey={(record) => record.id}
            />
          </Form.Item>
          {/* <Form.Item>
            <span className="text-lg text-textDark">
              Display the number of questions on survey card
            </span>
            <Switch
              checked={switchChecked}
              onChange={switchOnChange}
              className="float-right ml-4"
            ></Switch>
            <span className="text-base text-textLight float-right">
              {switchChecked === true ? "YES" : "NO"}
            </span>
          </Form.Item> */}
          {/* <Divider /> */}
          {/* <p className="text-lg text-textDark">Schedule Survey</p> */}
          {/* <Form.Item className="text-textLight text-sm w-3/5 float-left">
            <span>GO-LIVE</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                disabled={isEnabled}
                value={startDate}
                showTime
                className="w-40"
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </span>
            <span className="ml-4">DUE BY</span>
            <span className="ml-4">
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDuebyDate}
                value={dueDate}
                showTime
                onChange={(date) => {
                  setDueDate(date);
                }}
              />
            </span>
          </Form.Item> */}
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={handleClick}
            >
              Back
            </Button>
            <Button
              htmlType="button"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                moment().isSameOrAfter(props.startDate) &&
                  props.status !== "draft"
                  ? true
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-md w-28"
              onClick={handleNext}
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  );
}
export default MemberList;
