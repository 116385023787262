import React, { useState, useEffect } from "react";
import { Form, Select, Button, Divider, Input, Spin, notification } from "antd";
import { useHistory, useParams } from "react-router";
import {
  GET_PROJECT_DETAILS,
  UPDATE_PROJECT,
  UPDATE_SURVEY_STATUS_IN_PROJECT,
  GET_SURVEY_STATUS,
} from "../graphql";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import moment from "moment";

const { Option } = Select;

function FinancialDetails(props) {
  const { projectID } = useParams();
  const location = useLocation();
  const [updateProject, { loading: updateLoading }] =
    useMutation(UPDATE_PROJECT);
  const [cost, setCost] = useState(0);
  const [incentive, setIncentive] = useState(0);
  const [sampleSize, setSampleSize] = useState();
  const [budget, setBudget] = useState();
  const history = useHistory();
  const [currencyType, setCurrencyType] = useState("inr");
  const [errorMessage, setErrorMessage] = useState(null);
  const changeCurrencyType = (value) => {
    setCurrencyType(value);
  };
  const [updateSurveyStatus] = useMutation(UPDATE_SURVEY_STATUS_IN_PROJECT);
  const { data: surveyStatus } = useQuery(GET_SURVEY_STATUS, {
    fetchPolicy: "network-only",
    variables: {
      projectID: projectID ? parseInt(projectID) : 0,
    },
  });

  const {
    data: financialDetails,
    loading: financialDetailsLoading,
    refetch,
  } = useQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      project_id: projectID ? parseInt(projectID) : 0,
    },
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (financialDetails?.project.length > 0) {
      form.setFieldsValue({
        currency:
          financialDetails?.project[0]?.currency === null
            ? "inr"
            : financialDetails?.project[0]?.currency,
        cost: financialDetails?.project[0]?.cost_per_interview,
        incentive: financialDetails?.project[0]?.incentive,
        estimatedIR: financialDetails?.project[0]?.estimated_ir,
        budget: financialDetails?.project[0]?.budget,
      });
      setCurrencyType(
        financialDetails?.project[0]?.currency !== null
          ? financialDetails?.project[0]?.currency
          : "inr"
      );
      setBudget(
        parseInt(financialDetails?.project[0]?.sample) *
          (financialDetails?.project[0]?.cost_per_interview +
            financialDetails?.project[0]?.incentive)
      );
      setCost(financialDetails?.project[0]?.cost_per_interview);
      setIncentive(financialDetails?.project[0]?.incentive);
      setSampleSize(parseInt(financialDetails?.project[0]?.sample));
    }
    // eslint-disable-next-line
  }, [financialDetails]);

  const [form] = Form.useForm();
  const handleFormValues = (buttonAction) => {
    form.validateFields().then((values) => {
      if (budget < form.getFieldValue("budget")) {
        setErrorMessage("Project budget should be less than Total budget");
      } else {
        setErrorMessage("");
        const payload = {
          budget: form.getFieldValue("budget"),
          cost_per_interview: form.getFieldValue("cost"),
          currency: form.getFieldValue("currency")
            ? form.getFieldValue("currency")
            : currencyType,
          estimated_ir: form.getFieldValue("estimatedIR"),
          incentive: form.getFieldValue("incentive"),
          total_budget: budget,
          status:
            buttonAction === "draft"
              ? "draft"
              : location.pathname.split("/")[5] === undefined
              ? "active"
              : location.pathname.split("/")[5],
        };
        updateProject({
          variables: {
            object: payload,
            id: parseInt(projectID),
          },
        }).then((res) => {
          if (surveyStatus.survey.length === 1) {
            updateSurveyStatus({
              variables: {
                id: surveyStatus.survey[0].id,
                status:
                  buttonAction === "draft"
                    ? "draft"
                    : surveyStatus.survey[0].status,
              },
            });
          }
          if (buttonAction === "active") {
            notification.success({
              message: "Success!",
              description: "Project created!",
            });
            history.push("/projectList/active");
          } else {
            notification.success({
              message: "Success!",
              description: "Saved as draft!",
            });
            history.push("/projectList/active");
          }
        });
      }
    });
  };

  const addValues = () => {
    handleFormValues("active");
  };
  const handleDraft = () => {
    handleFormValues("draft");
  };
  const onChangeCPI = ({ target: { value } }) => {
    setCost(parseFloat(value));
    setBudget(sampleSize * (parseFloat(incentive) + parseFloat(value)));
  };
  const onChangeIncentive = ({ target: { value } }) => {
    setIncentive(parseFloat(value));
    setBudget(sampleSize * (parseFloat(value) + parseFloat(cost)));
  };
  return (
    <Spin spinning={updateLoading || financialDetailsLoading}>
      <div className="py-4 w-full">
        <Form
          name="assignUsers_form"
          layout="vertical"
          form={form}
          // onFinish={addValues}
          preserve={true}
        >
          <Form.Item
            label={<span className="text-base text-textLight">Currency</span>}
            name="currency"
          >
            <Select
              placeholder="Select"
              style={{ width: "49%" }}
              onChange={changeCurrencyType}
              value={currencyType === null ? "inr" : currencyType}
              defaultValue={currencyType}
            >
              <Option value="inr">INR</Option>
              <Option value="usd">USD</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Form.Item
              label={
                <span className="text-base text-textLight ">
                  Cost per interview
                </span>
              }
              name="cost"
              rules={[
                {
                  required: true,
                  message: "Please enter cost",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a valid number");
                  },
                }),
              ]}
              style={{ width: "49%" }}
              className="float-left"
            >
              <Input
                onChange={onChangeCPI}
                prefix={
                  currencyType === "inr" || currencyType === null ? `₹` : `$`
                }
                placeholder="0"
              />
            </Form.Item>

            <Form.Item
              label={
                <span className="text-base text-textLight">Incentive</span>
              }
              name="incentive"
              rules={[
                {
                  required: true,
                  message: "Please enter Incentive",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a valid number");
                  },
                }),
              ]}
              style={{ width: "49%" }}
              className="float-left ml-4"
            >
              <Input
                onChange={onChangeIncentive}
                style={{ width: "100%" }}
                prefix={
                  currencyType === "inr" || currencyType === null ? `₹` : `$`
                }
                placeholder="0"
              />
            </Form.Item>
          </Form.Item>
          <Form.Item>
            <Form.Item
              label={
                <span className="text-base text-textLight">Estimated iR</span>
              }
              name="estimatedIR"
              rules={[
                {
                  required: true,
                  message: "Please enter iR",
                },
                () => ({
                  validator(_, value) {
                    let regx = /^\d*(\.\d{0,2})?$/;
                    if (
                      (value && !regx.test(value)) ||
                      parseFloat(value) > 100
                    ) {
                      return Promise.reject("Please enter a valid number");
                    } else if (!value || parseInt(value) > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a valid number");
                  },
                }),
              ]}
              className="float-left"
              style={{ width: "49%" }}
            >
              <Input style={{ width: "100%" }} suffix="%" />
            </Form.Item>
            <Form.Item
              label={
                <span className="text-base text-textLight">Project budget</span>
              }
              name="budget"
              rules={[
                {
                  required: true,
                  message: "Please enter budget",
                },
                () => ({
                  validator(_, value) {
                    if (!value || value > 0) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Please enter a valid number");
                  },
                }),
              ]}
              className="float-left ml-4"
              style={{ width: "49%" }}
            >
              <Input
                style={{ width: "100%" }}
                value={
                  sampleSize *
                  (form.getFieldValue("cost") + form.getFieldValue("incentive"))
                }
                prefix={
                  currencyType === "inr" || currencyType === null ? `₹` : `$`
                }
              />
            </Form.Item>
          </Form.Item>
          <Form.Item
            className="block h-24 p-5 mt-40 bg-gray"
            name="totalbudget"
          >
            <div className="w-56 float-left">
              <p className="text-lg -mb-0">Total budget</p>
              <span className="text-textLight">
                Sample size x (CPI + Incentive)
              </span>
            </div>
            <div
              className=" text-3xl text-textLight float-right "
              style={{ minWidth: 224 }}
            >
              <span className="float-right align-middle">
                {currencyType === "inr" ? "₹" : "$"}
                {budget !== null ? (
                  <>{isNaN(budget) ? "0.00" : budget.toFixed(2)}</>
                ) : (
                  ""
                )}
              </span>
            </div>
          </Form.Item>
          <Form.Item>
            <div style={{ color: "red" }}>
              <p>{errorMessage}</p>
            </div>
          </Form.Item>
          <Divider />
          <Form.Item className="float-right">
            <Button
              type="link"
              className="text-base"
              htmlType="button"
              onClick={() => {
                location.pathname.split("/")[1] === "create_project" &&
                  history.push(`/create_project/${projectID}/assign_members`);
                location.pathname.split("/")[3] === "edit-project" &&
                  history.push(
                    `/project/${projectID}/edit-project/assign_members`
                  );
                location.pathname.split("/")[5] === "re-opened" &&
                  history.push(
                    `/project/${projectID}/edit-project/assign_members/re-opened`
                  );
              }}
            >
              Back
            </Button>
            <Button
              htmlType="submit"
              className="text-base mx-5 rounded-md text-orange"
              onClick={handleDraft}
              disabled={
                location.pathname.split("/")[1] !== "create_project"
                  ? moment().isSameOrAfter(props.startDate) &&
                    financialDetails?.project[0]?.status === "active"
                    ? true
                    : false
                  : false
              }
            >
              Save as draft
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="text-base rounded-sm w-28"
              onClick={addValues}
            >
              Launch
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
}
export default FinancialDetails;
