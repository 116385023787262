import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { MenuOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { useSetRecoilState } from "recoil";
import { useParams } from "react-router-dom";
import { surveyState } from "../../../atoms";
import { FETCH_ANSWER } from "../../../pages/survey/graphql";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: 500,
});

const MultipleChoice = (props) => {
  const params = useParams();
  const setSurveyState = useSetRecoilState(surveyState);
  // const surveyDetails = useRecoilValue(surveyState);
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  // const [answer, setAnswer] = useState([]);

  const { data: answers, refetch } = useQuery(FETCH_ANSWER, {
    variables: {
      surveyID: Number(params.surveyID),
      memberID: Number(params.memberID),
      questionID: Number(params.questionID),
    },
  });

  React.useEffect(() => {
    refetch();
    setData(props.questions_options);
    // eslint-disable-next-line
  }, [props]);

  React.useEffect(() => {
    let items_copy;
    if (answers?.questions[0]?.answers[0]?.answer !== undefined) {
      let current_answer = answers?.questions[0]?.answers[0]?.answer.split(",");
      items_copy =
        current_answer !== undefined &&
        current_answer.map((item, i) => ({
          id: `id-${i}`,
          label: item,
        }));
      setItems(items_copy);
    } else {
      items_copy = data.map((option) => ({
        id: `id-${option.id}`,
        label: option.label,
      }));
      setItems(items_copy);
    }
    let current_answer = {};
    items_copy.forEach((item, i) => {
      current_answer[item.label] = i;
    });

    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer,
    }));
    // eslint-disable-next-line
  }, [data, answers]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const quotes = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(quotes);
    let current_answer = {};
    quotes.forEach((item, i) => {
      current_answer[item.label] = i;
    });
    setSurveyState((oldSurveyState) => ({
      ...oldSurveyState,
      current_answer: current_answer,
    }));
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <span className="text-h11 font-italic px-2 border-bgDark border-b-2 mr-4 float-left mt-4">
                      Rank {index + 1}
                    </span>
                    <div className="flex items-center justify-start border border-bgLite rounded p-4 flex-1">
                      <MenuOutlined className="text-xl mr-4 text-textExtraLight" />
                      <span className="text-textMedium text-h6 ">
                        {item.label}
                      </span>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default MultipleChoice;
