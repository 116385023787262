import { Collapse, Card } from "antd";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { formatDateTime } from "../../utils/dateTime";

const { Panel } = Collapse;
function ViewSurveyTab(props) {
  const location = useLocation();
  return (
    <>
      <div className="viewSurvey w-3/4 pl-6 float-left min-h-full ">
        {location.pathname.split("-")[1] !== "Third_Party_survey" && (
          <p className="text-lg text-textMedium">
            {props?.surveyData[0]?.name}
          </p>
        )}
        <div>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Go Live
            </span>
            <span className="text-base text-textDark">
              {props?.surveyData[0]?.start_date !== null
                ? formatDateTime(props?.surveyData[0]?.start_date)
                : "Not yet set"}
            </span>
          </p>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Due by
            </span>
            <span className="text-base text-textDark ">
              {props?.surveyData[0]?.end_date !== null
                ? formatDateTime(props?.surveyData[0]?.end_date)
                : "Not yet set"}
            </span>
          </p>
          <p>
            <span className="text-sm text-textLight w-40 float-left">
              Completion
            </span>
            <span className="text-base text-textDark">
              {
                props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                  ?.count
              }
              /{props.sampleSize}
            </span>
            <span className="text-sm text-textLight ml-4">
              {props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                ?.count === parseInt(props.sampleSize) ||
              props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                ?.count > parseInt(props.sampleSize)
                ? "Reached survey limit"
                : ""}
            </span>
          </p>
        </div>
        {location.pathname.split("-")[1] !== "Third_Party_survey" && (
          <Collapse
            className="bg-white"
            bordered={false}
            defaultActiveKey={["1"]}
            expandIconPosition="right"
          >
            <Panel
              className="pl-0"
              header={
                <span className="text-base text-textDark">Description</span>
              }
              key="1"
            >
              {props?.surveyData[0]?.description}
            </Panel>
            <Panel
              header={<span className="text-base text-textDark">Consent</span>}
              key="2"
            >
              {props?.surveyData[0]?.consent &&
                parse(props?.surveyData[0]?.consent)}
            </Panel>
          </Collapse>
        )}
      </div>
      {location.pathname.split("-")[1] !== "Third_Party_survey" &&
        (props.projectType === "Online" ||
          props.projectType === "Assisted") && (
          <div className="surveyStats text-base text-textMedium float-left pl-4 border-borderColorGray border-l-2">
            <p>Survey stats</p>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {
                  props?.surveyData[0]?.survey_x_members_aggregate?.aggregate
                    ?.count
                }
              </p>
              <p className="text-green text-h8">Completed</p>
            </Card>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {props?.surveyData[0]?.screened?.aggregate?.count}
              </p>
              <p className="text-blue text-h8">Screened</p>
            </Card>
            <Card style={{ width: 300, marginTop: 16, padding: 0 }}>
              <p className="text-h4 mb-0">
                {props?.surveyData[0]?.quotaFull?.aggregate?.count}
              </p>
              <p className="text-orange text-h8">Quota full</p>
            </Card>
          </div>
        )}
    </>
  );
}
export default ViewSurveyTab;
