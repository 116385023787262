import { Tabs, Layout } from "antd";
import { useState, useEffect } from "react";
import LocationHierarchy from "../../components/locationHierarchy";
import ProjectDetails from "./projectDetails/projectDetails";
import AssignUsers from "./assignUsers/assignUsers";
import FinancialDetails from "./financialDetails/financialDetails";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_PROJECT_DETAILS, PROJECT_DATES, MANAGER_LIST } from "./graphql";
import moment from "moment";

const { Content } = Layout;
const { TabPane } = Tabs;

function CreateProject() {
  const { surveyID, projectID } = useParams();
  const history = useHistory();
  const location = useLocation();
  const tabPane = ["project_details", "assign_members", "financial_details"];
  const [activeTabPane, setActiveTabPane] = useState("1");
  const [startDate, setStartDate] = useState(null);
  const [enableSendTo, setEnableSendTo] = useState(false);

  const { data } = useQuery(GET_PROJECT_DETAILS, {
    fetchPolicy: "network-only",
    variables: {
      project_id: projectID ? parseInt(projectID) : 0,
      survey_id: surveyID ? parseInt(surveyID) : 0,
    },
  });

  const { data: managerList, refetch: managerRefetch } = useQuery(
    MANAGER_LIST,
    {
      fetchPolicy: "network-only",
      variables: {
        id: projectID ? parseInt(projectID) : 0,
      },
    }
  );

  const { data: projectDates, refetch: projectDatesRefetch } = useQuery(
    PROJECT_DATES,
    {
      variables: {
        id: projectID ? parseInt(projectID) : 0,
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    projectDatesRefetch();
    managerRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectDates && projectDates?.project?.length !== 0) {
      setStartDate(moment(projectDates?.project[0]?.start_date));
    }
    if (
      managerList &&
      managerList.project.length !== 0 &&
      managerList.project[0].manager_x_projects.length !== 0
    ) {
      setEnableSendTo(true);
    }
  }, [projectDates, managerList]);

  useEffect(() => {
    if (
      location.pathname.split("/")[4] === "assign_members" ||
      location.pathname.split("/")[3] === "assign_members"
    ) {
      setActiveTabPane("2");
    } else if (
      location.pathname.split("/")[4] === "financial_details" ||
      location.pathname.split("/")[3] === "financial_details"
    ) {
      setActiveTabPane("3");
    } else if (
      location.pathname.split("/")[4] === "project_details" ||
      location.pathname.split("/")[2] === "project_details" ||
      location.pathname.split("/")[3] === "project_details"
    ) {
      setActiveTabPane("1");
    }
  }, [location.pathname]);

  const handleActiveTabPane = (key) => {
    projectID
      ? history.push(`/project/${projectID}/edit-project/${tabPane[key - 1]}`)
      : history.push(`/create_project/${projectID}/${tabPane[key - 1]}`);
    setActiveTabPane(key);
  };

  const handleEnable = (value) => {
    setEnableSendTo(value);
  };

  return (
    <Layout className="w-full">
      <Content className="px-10 pb-10 ">
        <LocationHierarchy id={data?.project[0]?.id} />
        <div className="absolute text-base text-gray-700 pt-2"></div>
        <div
          style={{ padding: "24px" }}
          className="bg-white float-left border-r border-textExtraLight w-full mt-12 shadow-lg rounded-md"
        >
          <Tabs
            defaultActiveKey={"1"}
            tabPosition="left"
            size="large"
            className="createProject text-textExtraLight"
            tabBarStyle={{ width: "229px" }}
            activeKey={activeTabPane}
            onChange={handleActiveTabPane}
          >
            <TabPane tab="Project Details" key="1">
              <ProjectDetails startDate={startDate} />
            </TabPane>
            <TabPane
              tab="Assign Users"
              key="2"
              disabled={projectID ? false : true}
            >
              <AssignUsers startDate={startDate} handleEnable={handleEnable} />
            </TabPane>
            <TabPane
              tab="Financial Details"
              key="3"
              disabled={projectID ? (enableSendTo ? false : true) : true}
            >
              <FinancialDetails startDate={startDate} />
            </TabPane>
          </Tabs>
        </div>
      </Content>
    </Layout>
  );
}

export default CreateProject;
