import React from "react";
import PropTypes from "prop-types";

const CountCard = (props) => {
  return (
    <div
      className={`p-6 flex flex-col bg-white hover:bg-bgMedium ${props.className}`}
      onClick={props.onClick}
      style={{ cursor: "pointer" }}
    >
      <div className="text-h1 text-orange mb-2">{props.count}</div>
      <div className="text-h7 text-textLight">{props.description}</div>
    </div>
  );
};

CountCard.defaultProps = {
  count: 0,
  description: "",
  className: "",
};

CountCard.propTypes = {
  count: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CountCard;
